import {
  InputHTMLAttributes,
  memo,
  useRef,
  useState,
  ChangeEvent,
  useImperativeHandle,
  forwardRef,
  useMemo,
  useEffect
} from 'react';
import './index.style.scss';
import { ReactComponent as RadioChecked } from '@assets/images/radio-checked.svg';
import { ReactComponent as RadioCheckedDisabled } from '@assets/images/radio-checked-disable.svg';
import { ReactComponent as RadioUnchecked } from '@assets/images/radio-unchecked.svg';

const Radio = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  const { checked, defaultChecked, className, ...otherProps } = props;
  const [radioState, setRadioState] = useState(!!defaultChecked);
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => inputRef.current!, []);

  useEffect(() => {
    const { checked } = props;
    if (checked !== undefined) setRadioState(!!checked);
  }, [props.checked]);

  const renderIcon = useMemo(() => {
    const { disabled } = props;
    if (disabled)
      return radioState ? (
        <RadioCheckedDisabled className="radio-icon" />
      ) : (
        <RadioUnchecked className="radio-icon" />
      );
    return radioState ? (
      <RadioChecked className="radio-icon" />
    ) : (
      <RadioUnchecked className="radio-icon" />
    );
  }, [props.disabled, props.checked, radioState]);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, checked } = props;
    const radioStatus = event.currentTarget.checked;
    onChange?.(event);
    if (checked !== undefined) return;
    setRadioState(radioStatus);
  };

  return (
    <span className="radio-button-icon">
      <input
        {...otherProps}
        type="radio"
        ref={inputRef}
        checked={radioState}
        className="radio-input"
        onChange={handleRadioChange}
      />
      <span className="radio-ripple"></span>
      {renderIcon}
    </span>
  );
});

export default memo(Radio);
