import * as yup from 'yup';
import { paymentTerm } from './add-payment.schema';

const nullableNumberTransform = (value: any, originalValue: any) => {
  if (originalValue === '' || originalValue === null) {
    return null;
  }
  return value;
};

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

export const typeOfOrderSchema = yup.object().shape({
  supply_type: selectSchema.required('Order type is required'),
  readable_order_id: yup.string().notRequired()
});

export const customerSchema = yup.object().shape({
  customer: yup.mixed().required('Customer name is required'),
  customer_poc: yup.mixed().required('Customer poc is required'),
  customer_address: yup.mixed().required('Customer address is required')
});

export const containerSchema = yup.object().shape({
  container_type: selectSchema.required('Container type is required'),
  no_of_container: yup
    .number()
    .typeError('Number should be in number')
    .positive('Should be greater than 0 (zero)')
    .min(1, 'Minimum value should be 1')
    .required('Number of container is required')
});

export const orderDetailSchema = yup.object().shape({
  transportation_mode: selectSchema.required('Transportation mode is required'),
  inco_terms: selectSchema.required('Inco terms is required'),
  country_of_origin: selectSchema.required('Country of origin is required'),
  country_of_final_destination: selectSchema.required('Country of final destination is required'),
  port_of_loading: selectSchema.required('Port of loading is required'),
  port_of_discharge: selectSchema.required('Port of discharge is required'),
  place_of_delivery: yup.string().trim().required('Place of delivery is required'),
  is_qc_test: yup.bool(),
  place_of_quality_test: selectSchema.when('is_qc_test', {
    is: (value: boolean) => value,
    then(schema) {
      return schema.required('Place of quality is required');
    },
    otherwise(schema) {
      return schema.notRequired();
    }
  }),
  palletization: yup.bool(),
  payment_terms: yup.array().of(paymentTerm),
  container: yup.array().when('transportation_mode', {
    is: (mode: any) => mode.value !== 'AIR',
    then: () => yup.array().of(containerSchema).required('Container is required'),
    otherwise: (schema) => schema.notRequired()
  })
});

export const consigneeSchema = yup.object().shape({
  elchemy_entity: yup.mixed().required('Entity is required'),
  consignee_same_as_customer: yup.bool().optional(),
  consignee_name: yup.string().trim().required('Consignee name is required'),
  consignee_phone_number: yup.string().trim().required('Consignee phone number is required'),
  consignee_landline: yup.string().trim().optional(),
  consignee_email_id: yup.string().trim().required('Consignee email id is required'),
  consignee_office_address: yup.string().trim().required('Consignee office address is required')
});

export const packageSchema = yup.object().shape({
  type: selectSchema.required('Package type is required'),
  no_of_package: yup
    .number()
    .typeError('Must be a number')
    .positive('Must be greater than 0 (zero)')
    .integer('Must be an integer')
    .required('Number of package is required'),
  weight: yup
    .number()
    .typeError('Must be a number')
    .positive('Must be greater than 0 (zero)')
    .integer('Must be an integer')
    .required('Weight is required'),
  unit: selectSchema.required('Unit is required')
});

const itemSchema = yup.object().shape({
  name: yup.mixed().required('Item name is required'),
  quantity: yup
    .number()
    .typeError('Quantity should be in number')
    .moreThan(0, 'Should be greater than 0')
    .positive('Should be greater than 0 (zero)')
    .test('decimal', 'Should be upto 4 decimal places', (value) => {
      if (value && !isNaN(value)) {
        const data = value.toString().split('.');
        if (data[1]) return data[1].length <= 4 ? true : false;
        return true;
      }
      return false;
    })
    .required('Item quantity is required'),
  rate_per_unit: yup
    .number()
    .typeError('Rate should be in number')
    .moreThan(0, 'Should be greater than 0')
    .positive('Should be greater than 0 (zero)')
    .max(99999999999999, "Can't be more than 14 digits")
    .required('Item rate is required'),
  product_grade: yup.mixed().required('Grade is required'),
  description: yup.string().trim().notRequired(),
  supplier: yup.mixed().optional().nullable(),
  hs_code: yup.string().trim().required('HS Code is required'),
  country_of_origin: yup.mixed().optional().nullable(),
  package: yup.array().of(packageSchema)
});

export const orderItemSchema = yup.object().shape({
  currency: selectSchema.required('Currency is required'),
  unit_of_weight: selectSchema.required('Unit of Weight is required'),
  items: yup.array().of(itemSchema)
});

export const bankInfoSchema = yup.object().shape({
  elchemy_bank: yup.mixed().required('Bank is required'),
  additional_remarks: yup.string().optional()
});

export const customItemSchema = yup.object().shape({
  custom_item_name: yup.string().trim().required('Name is required'),
  custom_item_description: yup.string().trim().notRequired(),
  custom_item_rate: yup
    .number()
    .typeError('Rate should be in number')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .optional(),
  custom_item_quantity: yup
    .number()
    .typeError('Quantity should be in number')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .optional(),

  custom_item_amount: yup
    .number()
    .required('Amount is required')
    .typeError('Amount should be in number')
    .moreThan(0, 'Should be greater than 0')
    .positive('Should be greater than 0 (zero)')
    .max(99999999, "Can't be more than 8 digits")
    .test('decimal', 'Should be upto 2 decimal places', (value) => {
      if (value && !isNaN(value)) {
        const data = value.toString().split('.');
        if (data[1]) return data[1].length <= 2 ? true : false;
        return true;
      }
      return false;
    })
});

export const piItemSchema = yup.object().shape({
  order_item_description_pi: yup.string().trim().notRequired(),
  rate_pi: yup
    .number()
    .typeError('Rate should be in number')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Rate is required'),
  quantity_pi: yup
    .number()
    .typeError('Quantity should be in number')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Quantity is required'),
  order_item_id: yup.string().optional().nullable(),
  country_of_origin: yup.string().optional().nullable()
});

export const ciItemSchema = yup.object().shape({
  order_item_description_ci: yup.string().trim().notRequired(),
  rate_ci: yup
    .number()
    .typeError('Rate should be in number')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Rate is required'),
  // quantity_ci: yup
  //   .number()
  //   .transform((curr, orig) => (orig === '' ? null : curr))
  //   .required('Quantity is required'),
  order_item_id: yup.string().optional().nullable()
});

export const customItemsArraySchema = yup.object().shape({
  custom_items: yup.array().of(customItemSchema),
  order_items: yup.array().of(piItemSchema)
});

export const CIItemDetailSchema = yup.object().shape({
  custom_items: yup.array().of(customItemSchema),
  order_items: yup.array().of(ciItemSchema)
});

const other_remarks_schema = yup.object().shape({
  remarks: yup.string().required('Field must not be empty')
});

export const remarksSchema = yup.object().shape({
  field_free_days_port: yup
    .number()
    .typeError('Field should be in number')
    .moreThan(0, 'Days should be greater than 0 (zero)')
    .positive('Should be greater than 0 (zero)')
    .max(99999, "Can't be more than 5 digits"),
  field_percentage_payment: yup
    .number()
    .typeError('Field should be in number')
    .positive('Should be greater than 0 (zero)')
    .test('decimal', 'Should be upto 2 decimal places', (value) => {
      if (value && !isNaN(value)) {
        const data = value.toString().split('.');
        if (data[1]) return data[1].length <= 2 ? true : false;
        return true;
      }
      return false;
    }),
  field_days_deviation: yup
    .number()
    .typeError('Field should be in number')
    .moreThan(0, 'Days should be greater than 0 (zero)')
    .positive('Should be greater than 0 (zero)')
    .max(99999, "Can't be more than 5 digits"),
  field_bl_port: yup.string().trim(),

  field_payment_due: yup.date().required('Field must not me empty'),
  field_inspection_cost: yup.string(),
  Remarks: yup.array().of(other_remarks_schema)
});

export const cancelOrderSchema = yup.object().shape({
  cancel_remarks: yup.string().optional().nullable()
});

const newCustomItemSchema = yup.object().shape({
  name: yup.string().trim().required('Name is required'),
  description: yup.string().trim().notRequired(),
  rate: yup
    .number()
    .typeError('Rate should be in number')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .optional(),
  quantity: yup
    .number()
    .typeError('Quantity should be in number')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .optional(),

  amount: yup
    .number()
    .required('Amount is required')
    .typeError('Amount should be in number')
    .moreThan(0, 'Should be greater than 0')
    .positive('Should be greater than 0 (zero)')
    .max(99999999, "Can't be more than 8 digits")
    .test('decimal', 'Should be upto 2 decimal places', (value) => {
      if (value && !isNaN(value)) {
        const data = value.toString().split('.');
        if (data[1]) return data[1].length <= 2 ? true : false;
        return true;
      }
      return false;
    })
});

export const editOrderSchema = yup.object().shape(
  {
    // elchemy_entity: yup.mixed().required('Entity is required'),
    consignee_same_as_customer: yup.bool().optional(),
    consignee_name: yup.string().trim().required('Consignee name is required'),
    consignee_phone_number: yup.string().trim().required('Consignee phone number is required'),
    consignee_landline: yup.string().trim().optional(),
    consignee_email_id: yup.string().trim().required('Consignee email id is required'),
    consignee_office_address: yup.string().trim().required('Consignee office address is required'),
    order_item: yup.array().of(
      yup.object().shape({
        packages: yup.array().of(packageSchema),
        product_name: yup.string().required('Product name is required'),
        order_item_id: yup.string().required('Order item id is required'),
        selling_price: yup.mixed().when('selling_price_editable', (val: any, schema) => {
          if (val && val[0]) {
            return yup
              .number()
              .required('Selling price is required')
              .typeError('Selling price should be in number')
              .min(0.0001, 'Should be greater than 0');
          }
          return schema.notRequired().nullable();
        }),
        buying_price: yup.mixed().when('buying_price_editable', (val: any, schema) => {
          if (val && val[0]) {
            return yup
              .number()
              .required('Buying price is required')
              .typeError('Buying price should be in number')
              .min(0.0001, 'Should be greater than 0');
          }
          return schema.notRequired().nullable();
        }),
        buying_price_editable: yup.bool(),
        selling_price_editable: yup.bool()
      })
    ),
    port_of_loading: selectSchema.required('Port of loading is required'),
    port_of_discharge: selectSchema.required('Port of discharge is required'),
    place_of_delivery: yup.string().trim().required('Place of delivery is required'),
    containers_count_data: yup.array().when('container_count_editable', (val: any, schema) => {
      if (!(val && val[0])) {
        return yup.array().of(containerSchema);
      }
      return schema.notRequired();
    }),
    containers: yup.array().when('container_editable', (val: any, schema) => {
      if (val && val[0]) {
        return yup.array().of(
          yup.object().shape({
            container_id: yup.string().required('Container id is required'),
            container_type: yup.string().required('Container type is required'),
            container_number: yup.string().required('Container number is required')
          })
        );
      }
      return schema.notRequired();
    }),
    container_editable: yup.bool(),
    custom_items: yup.array().of(newCustomItemSchema),
    bl_date: yup
      .date()
      .nullable()
      .when('bl_date', {
        is: (value: any) => value !== null,
        then: (schema) =>
          schema
            .required('BL Date is required when provided')
            .test(
              'bl-date-lte-lower-limit',
              `BL Date must be on or after Export Clearance Date`,
              function (value) {
                const { bl_lower_limit } = this.parent;
                if (!bl_lower_limit) return true; // If bl_lower_limit is not set, don't perform this validation
                return value >= bl_lower_limit;
              }
            ),
        // .test(
        //   'bl-date-lte-upper-limit',
        //   `BL Date must be on or before arrival at POD date or Order completion date`,
        //   function (value) {
        //     const { bl_upper_limit } = this.parent;
        //     if (!bl_upper_limit) return true; // If bl_upper_limit is not set, don't perform this validation
        //     return value <= bl_upper_limit;
        //   }
        // ),
        otherwise: (schema) => schema.nullable()
      }),
    awb_date: yup
      .date()
      .nullable()
      .when('awb_date', {
        is: (value: any) => value !== null,
        then: (schema) =>
          schema
            .required('AWB Date is required when provided')
            .test(
              'awb-date-lte-lower-limit',
              `AWB Date must be on or after Export Clearance Date`,
              function (value) {
                const { bl_lower_limit } = this.parent;
                if (!bl_lower_limit) return true; // If bl_lower_limit is not set, don't perform this validation
                return value >= bl_lower_limit;
              }
            ),
        // .test(
        //   'bl-date-lte-upper-limit',
        //   `BL Date must be on or before arrival at POD date or Order completion date`,
        //   function (value) {
        //     const { bl_upper_limit } = this.parent;
        //     if (!bl_upper_limit) return true; // If bl_upper_limit is not set, don't perform this validation
        //     return value <= bl_upper_limit;
        //   }
        // ),
        otherwise: (schema) => schema.nullable()
      }),
    bl_lower_limit: yup.date().nullable(),
    bl_upper_limit: yup.date().nullable()
  },
  [
    ['bl_date', 'bl_date'],
    ['awb_date', 'awb_date']
  ]
);

export const editAdhocDetailsSchema = yup.object().shape({
  rodtep_currency: selectSchema.optional().nullable(),
  rodtep_amount: yup
    .number()
    .transform(nullableNumberTransform)
    .nullable()
    .typeError('Amount should be in number'),
  rodtep_credit_date: yup.mixed().nullable(),
  rodtep_percentage_claimed: yup
    .number()
    .transform(nullableNumberTransform)
    .nullable()
    .typeError('Percentage should be in number')
    .min(0, 'Percentage should be greater than 0')
    .max(100, 'Percentage should be less than 100'),
  drawback_currency: selectSchema.optional().nullable(),
  drawback_amount: yup
    .number()
    .transform(nullableNumberTransform)
    .nullable()
    .typeError('Amount should be in number'),
  drawback_credit_date: yup.mixed().nullable()
});
