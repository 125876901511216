import { memo, ReactNode, ElementType, DOMAttributes } from 'react';
import './index.style.scss';
import PropTypes from 'prop-types';
import { typographyVariantsMapping } from '@helpers/constants';
import { typographyVariantType } from '@helpers/types';

interface TypographyProps extends DOMAttributes<HTMLHeadingElement> {
  variant?: typographyVariantType;
  children: ReactNode;
  className?: string;
}

function Typography(props: TypographyProps) {
  const { variant, children, className, ...otherProps } = props;
  const Component = (variant ? typographyVariantsMapping[variant] : 'p') as ElementType;
  return (
    <Component {...otherProps} className={`typography--variant-${variant} ${className}`}>
      {children}
    </Component>
  );
}

Typography.defaultProps = {
  variant: 'body'
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subheading1',
    'subheading2',
    'body',
    'span',
    'p',
    'ptext',
    'pdoc',
    'label',
    'inherit'
  ])
};

export default memo(Typography);
