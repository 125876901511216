import { MAX_FILE_SIZE, SUPPORTED_FORMATS, MAX_ZIP_CODE_LENGTH } from '@helpers/constants';
import * as yup from 'yup';

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

const pocSchema = yup.object().shape({
  // uid: yup.string().optional(),
  // email: yup.string().trim().email('Enter valid e-mail address').required('Email is required'),
  // given_name: yup.string().trim().required('Name is required'),
  // family_name: yup.string().trim().required('Last name is required'),
  // phone_number: yup
  //   .string()
  //   .trim()
  //   .min(8, 'Phone Number must be at least 8 digits long')
  //   .max(20, 'Phone Number can be at most 20 digits long')
  //   .required('Phone Number is required')
  user_id: yup.mixed().required('POC is required')
});

const addressSchema = yup.object().shape(
  {
    address_id: yup.string().trim().optional(),
    address_line1: yup.string().trim().required('Address Line 1 is required'),
    address_line2: yup.string().trim().optional().nullable(),
    address_type: yup.string().trim().optional(),
    zip_code: yup
      .string()
      .trim()
      .when('zip_code', (val, schema) => {
        if (val?.length && val[0]) {
          return yup
            .string()
            .trim()
            .max(
              MAX_ZIP_CODE_LENGTH,
              `Zip Code can be at most ${MAX_ZIP_CODE_LENGTH} characters long`
            );
        } else {
          return yup.string().optional();
        }
      }),
    country: selectSchema.required('Country is required'),
    // state: selectSchema.notRequired(),
    city: selectSchema.required('City is required'),
    address_suffix: yup.string().optional().nullable()
  },
  [['zip_code', 'zip_code']]
);

const customerSchema = yup.object().shape(
  {
    customer_id: yup.string().trim().optional(),
    name: yup.string().trim().required('Customer name is required'),
    number: yup
      .string()
      .trim()
      .min(8, 'Phone Number must be at least 8 digits long')
      .max(20, 'Phone Number can be at most 20 digits long')
      .required('Phone Number is required'),
    email: yup.string().trim().email('Enter valid e-mail address').required('Email is required'),
    website: yup
      .string()
      .trim()
      .when('website', (val, schema) => {
        if (val?.length && val[0]) {
          return yup
            .string()
            .trim()
            .matches(
              /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
              'Enter valid URL'
            );
        } else {
          return yup.string().optional();
        }
      }),
    business_type: selectSchema.required('Business type is required'),
    // customer_country: selectSchema.required('Country is required'),
    remarks: yup.string().trim().optional(),
    same_as_office: yup.bool(),
    addresses: yup.array().of(addressSchema).required()
    // pocs: yup.array().of(pocSchema)
  },
  [['website', 'website']]
);

const addCustomerSchema = customerSchema.concat(addressSchema);

export { addCustomerSchema, pocSchema };
