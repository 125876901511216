import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import './index.style.scss';
import PropTypes from 'prop-types';
import { ReactComponent as CheckedIcon } from '@assets/images/checkbox-checked.svg';
import { ReactComponent as DisableCheckedIcon } from '@assets/images/checkbox-checked-disable.svg';
import { ReactComponent as UncheckedIcon } from '@assets/images/checkbox-unchecked.svg';
import { ReactComponent as DisableUncheckedIcon } from '@assets/images/checkbox-unchecked-disable.svg';
import { ReactComponent as CheckboxIdeal } from '@assets/images/checkbox-ideal.svg';
import { ReactComponent as DisableCheckboxIdeal } from '@assets/images/checkbox-ideal-disable.svg';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { indeterminate = false, checked, defaultChecked, className, ...otherProps } = props;

  const [checkboxState, setCheckboxState] = useState(!!defaultChecked);
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => inputRef.current!, []);

  useEffect(() => {
    const { checked } = props;
    if (checked !== undefined) setCheckboxState(!!checked);
  }, [props.checked]);

  const renderCheckbox = useMemo(() => {
    const { disabled } = props;
    return disabled ? (
      indeterminate ? (
        <DisableCheckboxIdeal className="checkbox-svg" />
      ) : checkboxState ? (
        <DisableCheckedIcon className="checkbox-svg" />
      ) : (
        <DisableUncheckedIcon className="checkbox-svg" />
      )
    ) : indeterminate ? (
      <CheckboxIdeal className="checkbox-svg" />
    ) : checkboxState ? (
      <CheckedIcon className="checkbox-svg" />
    ) : (
      <UncheckedIcon className="checkbox-svg" />
    );
  }, [props.indeterminate, checkboxState, props.disabled]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, checked } = props;
    onChange?.(event);
    if (checked !== undefined) return;
    setCheckboxState(event.currentTarget.checked);
  };

  return (
    <span className={`checkbox-input-wrapper ${className}`}>
      <input
        {...otherProps}
        ref={inputRef}
        checked={checkboxState}
        type="checkbox"
        className="input-checkbox"
        onChange={handleChange}
      />
      <span className="checkbox-ripple"></span>
      {renderCheckbox}
    </span>
  );
});

Checkbox.defaultProps = {
  indeterminate: false,
  disabled: false
};

Checkbox.propTypes = {
  indeterminate: PropTypes.bool
};

export type { CheckboxProps };
export default memo(Checkbox);
