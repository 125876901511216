import { getToken } from '@helpers/auth-helper';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { userReducer } from './slices/user.slice';

const rootReducer = combineReducers({
  user: userReducer
});

const store = configureStore({
  reducer: rootReducer,
  preloadedState: {
    user: {
      accessToken: getToken('access'),
      refreshToken: getToken('refresh')
    }
  },
  devTools: process.env.NODE_ENV !== 'development' ? false : true
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch: () => AppDispatch = useDispatch;

export { useAppSelector, useAppDispatch };
export default store;
