import { Typography } from '@components/base';
import moment from 'moment';
import css from './index.module.scss';

const InvoicePreview = (props: any) => {
  const { purchaseOrderData } = props;
  return (
    <div className={css.invoicePreview}>
      <Typography variant="h3">Purchase Order Date</Typography>
      <div className={css.invoiceDate}>
        {`${moment(purchaseOrderData?.document_date).format('DD-MM-YYYY')}`}
      </div>
    </div>
  );
};

export default InvoicePreview;
