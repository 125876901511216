import { memo } from 'react';
import css from './index.module.scss';
import { Typography } from '@components/base';
import { BoxContainer } from '@components/common';

interface OrderPreviewContainerProps {
  orderType: string;
  orderNumber: string;
}

const OrderPreviewContainer = (props: OrderPreviewContainerProps) => {
  const { orderType, orderNumber } = props;
  return (
    <BoxContainer title="Order Preview" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <Typography variant="pdoc">Type of Order</Typography>
        <div className={css.rowWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="ptext">Order Type</Typography>
            <Typography variant="p">{orderType}</Typography>
          </div>
          <div className={css.columnWrapper}>
            <Typography variant="ptext">Order Number</Typography>
            <Typography variant="p">
              {orderNumber ? orderNumber : 'This will be assigned once order is created'}
            </Typography>
          </div>
        </div>
      </div>
    </BoxContainer>
  );
};

export default memo(OrderPreviewContainer);
