import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { CLIENT_ROUTES } from './routes';
import { useAppSelector } from '@store/index';

function ProtectedRoute() {
  const location = useLocation();
  const { from } = location.state || {};
  const reduxUserState = useAppSelector((state) => state.user);
  const { accessToken } = reduxUserState;
  if (accessToken) {
    const redirectURL = from?.pathname ? from.pathname : CLIENT_ROUTES.order;
    return <Navigate to={redirectURL} replace />;
  }
  return <Outlet />;
}
export default ProtectedRoute;
