import { LoginLayout, MainLayout } from '@components/layouts';
import { AddCustomer, CustomerHome, ViewCustomer } from '@pages/customer';
import { GenerateBoe, GenerateCRL, Home } from '@pages/finance';
import {
  AccountSetup,
  AcknowledgeView,
  ForgotPassword,
  ResetPasswordForm,
  SignIn
} from '@pages/login';
import {
  AddOrder,
  GenerateProformaInvoice,
  GeneratePO,
  GenerateShipmentInstructions,
  OrderHome,
  OrderIndividual
} from '@pages/order';
import GenerateCommercialInvoice from '@pages/order/generate-commercial-invoice';
import ApproveProformaInvoiceReview from '@pages/order/review-performa-invoice/preview-tab';

import { AddProduct, ProductHome, ProductIndividual } from '@pages/product';
import { SupplierAdd, SupplierHome, SupplierIndividual } from '@pages/supplier';
import { AddTransportDetail, TaskHome } from '@pages/task';
import { RouteObject } from 'react-router-dom';
import { ReviewOrder } from '@pages/buisness-head-tasks';
import PurchaseOrderReview from '@pages/order/review-purchase-order/review-performa-invoice/preview-tab';
import Tracking from '@pages/order/order-individual/components/tracking';
import GenerateCOA from '@pages/order/generate-coa';
import Rectify from '@pages/order/packing-details/rectify';
import Review from '@pages/order/packing-details/review';
import AddBatchNumber from '@pages/order/add-batch-number';
import GeneratePackingList from '@pages/order/generate-packing-list';
import RouteGuard from '@authorization/route-guard';
import { MODULES } from '@authorization/module-mapping';
import Compose from '@pages/email/compose';
import ContactUs from '@pages/customer/contact-us';
import EnquiryHome from '@pages/enquiry/enquiry-home';
import OrderEdit from '@pages/order/order-edit';
import GenerateLabels from '@pages/order/order-individual/components/task-tab/generate-label';
import MakePayment from '@pages/finance/make-payment';

export const CLIENT_ROUTES = {
  login: '/',
  forgotPassword: 'recover',
  resetPassword: 'password-reset',
  acknowledge: 'password-updated',
  passwordSetup: 'password-setup',
  error: 'error',
  supplier: 'supplier',
  addSupplier: 'add',
  supplierIndividual: ':id',
  order: 'order',
  orderIndividual: ':id',
  addOrder: 'add',
  task: 'task',
  buisnessHeadTasks: 'buisness-head-tasks',
  reviewOrder: ':id',
  product: 'product',
  addProduct: 'add',
  productIndividual: ':id',
  customer: 'customer',
  addCustomer: 'add',
  viewCustomer: ':id',
  finance: 'finance',
  generateBoe: 'generate-bill-of-exchange/:id/:taskid',
  generateCrl: 'customer-request-letters/:id/:taskid',
  crlEdit: 'customer-request-letters/:id/:taskid/edit',
  generatePo: 'generate-purchase-order/:id/:supplierId/:taskId',
  generateCi: 'generate-commercial-invoice/:id',
  transportDetail: 'transport-detail/:taskid/:supplierid',
  rectifyOrder: 'rectify-order/:orderid/:taskid',
  generatePi: 'generate-proforma-invoice/:id',
  reviewPi: 'proforma-invoice-review/:id',
  approveOrder: 'approve-order/:orderid/:taskid',
  rectifyPi: 'rectify-performa-invoice/:id',
  reviewPo: 'purchase-order-review/:id/:supplierid',
  rectifyPo: 'rectify-purchase-order/:id/:supplierId/:taskId',
  tracking: 'tracking/:id',
  GenerateCOA: 'generate-coa/:id/:taskid',
  packaging: {
    rectify: 'packaging-details/:id/rectify',
    review: 'packaging-details/:id/review'
  },
  addBatchNumber: 'add-batch-number/:id',
  generatePackingList: 'generate-packing-list/:id',
  generateSI: 'generate-shipping-instructions/:orderid/:taskid',
  email: {
    root: 'email',
    compose: 'compose/:orderId/:nudgeId'
  },
  contactUs: 'contact-us',
  enquiry: 'enquiry',
  edit: ':id/edit',
  generateLabels: 'generate-labels/:orderid',
  requestPayment: 'request-payment',
  makePayment: 'make-payment'
};

const publicRoutes = [
  {
    path: CLIENT_ROUTES.error,
    element: (
      <RouteGuard moduleData={MODULES.supplier}>
        <SupplierHome />
      </RouteGuard>
    )
  }
] as RouteObject[];

const privateRoutes = [
  {
    path: CLIENT_ROUTES.supplier,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <RouteGuard moduleData={MODULES.supplier}>
            <SupplierHome />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.addSupplier,
        element: (
          <RouteGuard moduleData={MODULES.supplier}>
            <SupplierAdd />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.supplierIndividual,
        element: (
          <RouteGuard moduleData={MODULES.supplier}>
            <SupplierIndividual />
          </RouteGuard>
        )
      }
    ]
  },
  {
    path: CLIENT_ROUTES.customer,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <RouteGuard moduleData={MODULES.customer}>
            <CustomerHome />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.addCustomer,
        element: (
          <RouteGuard moduleData={MODULES.customer}>
            <AddCustomer />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.viewCustomer,
        element: (
          <RouteGuard moduleData={MODULES.customer}>
            <ViewCustomer />
          </RouteGuard>
        )
      }
    ]
  },
  {
    path: CLIENT_ROUTES.enquiry,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <RouteGuard moduleData={MODULES.enquiry}>
            <EnquiryHome />
          </RouteGuard>
        )
      }
    ]
  },
  {
    path: CLIENT_ROUTES.product,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <RouteGuard moduleData={MODULES.product}>
            <ProductHome />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.addCustomer,
        element: (
          <RouteGuard moduleData={MODULES.product}>
            <AddProduct />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.viewCustomer,
        element: (
          <RouteGuard moduleData={MODULES.product}>
            <ProductIndividual />
          </RouteGuard>
        )
      }
    ]
  },
  {
    path: CLIENT_ROUTES.finance,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <RouteGuard moduleData={MODULES.finance}>
            <Home />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.makePayment,
        element: (
          <RouteGuard moduleData={MODULES.finance}>
            <MakePayment />
          </RouteGuard>
        )
      }
      // {
      //   path: CLIENT_ROUTES.generateBoe,
      //   element: (
      //     <RouteGuard moduleData={MODULES.task}>
      //       <GenerateBoe />
      //     </RouteGuard>
      //   )
      // },
      // {
      //   path: CLIENT_ROUTES.generateCrl,
      //   element: (
      //     <RouteGuard moduleData={MODULES.task}>
      //       <GenerateCRL />
      //     </RouteGuard>
      //   )
      // },
      // {
      //   path: CLIENT_ROUTES.crlEdit,
      //   element: (
      //     <RouteGuard moduleData={MODULES.task}>
      //       <GenerateCRL />
      //     </RouteGuard>
      //   )
      // }
    ]
  },
  {
    path: CLIENT_ROUTES.order,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <RouteGuard moduleData={MODULES.orderList}>
            <OrderHome />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.orderIndividual,
        element: (
          <RouteGuard moduleData={MODULES.order}>
            <OrderIndividual />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.addOrder,
        element: (
          <RouteGuard moduleData={MODULES.order}>
            <AddOrder />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.generatePo,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <GeneratePO />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.generatePi,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <GenerateProformaInvoice />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.rectifyPi,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <GenerateProformaInvoice />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.generateCi,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <GenerateCommercialInvoice />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.rectifyOrder,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <AddOrder />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.reviewPi,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <ApproveProformaInvoiceReview />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.approveOrder,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <ReviewOrder />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.reviewPo,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <PurchaseOrderReview />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.rectifyPo,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <GeneratePO />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.transportDetail,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <AddTransportDetail />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.transportDetail,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <AddTransportDetail />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.GenerateCOA,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <GenerateCOA />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.packaging.rectify,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <Rectify />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.packaging.review,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <Review />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.addBatchNumber,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <AddBatchNumber />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.generatePackingList,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <GeneratePackingList />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.generateSI,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <GenerateShipmentInstructions />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.edit,
        element: (
          <RouteGuard moduleData={MODULES.order}>
            <OrderEdit />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.generateLabels,
        element: (
          <RouteGuard moduleData={MODULES.order}>
            <GenerateLabels />
          </RouteGuard>
        )
      }
    ]
  },
  // {
  //   path: CLIENT_ROUTES.buisnessHeadTasks,
  //   element: <MainLayout />,
  //   children: [
  //     {
  //       index: true,
  //       element: <OrderApprovalHome />
  //     },
  //     {
  //       path: CLIENT_ROUTES.reviewOrder,
  //       element: <ReviewOrder />
  //     }
  //   ]
  // },
  {
    path: CLIENT_ROUTES.task,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <TaskHome />
          </RouteGuard>
        )
      },
      {
        path: CLIENT_ROUTES.transportDetail,
        element: (
          <RouteGuard moduleData={MODULES.task}>
            <AddTransportDetail />
          </RouteGuard>
        )
      }
    ]
  },
  {
    path: CLIENT_ROUTES.email.root,
    element: <MainLayout />,
    children: [
      {
        path: CLIENT_ROUTES.email.compose,
        element: (
          <RouteGuard moduleData={MODULES.email}>
            <Compose />
          </RouteGuard>
        )
      }
    ]
  },
  {
    path: CLIENT_ROUTES.contactUs,
    element: <ContactUs />
  }
] as RouteObject[];

const protectedRoutes = [
  {
    path: CLIENT_ROUTES.login,
    element: <LoginLayout />,
    children: [
      {
        index: true,
        element: <SignIn />
      },
      {
        path: CLIENT_ROUTES.forgotPassword,
        element: <ForgotPassword />
      },
      {
        path: CLIENT_ROUTES.resetPassword,
        element: <ResetPasswordForm />
      },
      {
        path: CLIENT_ROUTES.passwordSetup,
        element: <AccountSetup />
      },
      {
        path: CLIENT_ROUTES.acknowledge,
        element: <AcknowledgeView />
      }
    ]
  }
] as RouteObject[];

const routes = {
  public: publicRoutes,
  private: privateRoutes,
  protected: protectedRoutes
};

export default routes;
