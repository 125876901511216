/* eslint-disable @typescript-eslint/no-empty-function */
import Images from '@assets/images';
import { Button, Checkbox, CheckboxLabel, IconNode, Typography } from '@components/base';
import { BoxContainer, InputDatePicker, SelectLabel, TextField } from '@components/common';
import { ICustomerInfoTypes, IOrderDetailsTypes, IRemarksProps } from '@helpers/types/pi';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import css from './index.module.scss';

interface IRemarkCheckbox {
  payment_transfers: boolean;
  pss_confirmation: boolean;
  free_days_at_port: boolean;
  neutral_packing: boolean;
  consignment_elchemy: boolean;
  percent_advance_payment: boolean;
  deviation_quality_days: boolean;
  bl_switchable_port: boolean;
  payment_due_date: boolean;
  inspection_cost: boolean;
  other_remarks: boolean;
}

interface IRemark {
  orderDetails: IOrderDetailsTypes;
  onFormSubmit: (data: Partial<IRemarksProps>) => void;
  onBackClick: () => void;
  handleCancelClick: () => void;
  remarkCheckboxTabs: IRemarkCheckbox;
  setRemarkCheckboxTabs: (remarkCheckboxTabs: IRemarkCheckbox) => void;
  fieldsData: ICustomerInfoTypes;
}

const RemarkTab = (props: IRemark) => {
  const {
    onFormSubmit,
    onBackClick,
    remarkCheckboxTabs,
    setRemarkCheckboxTabs,
    fieldsData,
    handleCancelClick,
    orderDetails
  } = props;

  const { handleSubmit, control } = useFormContext();
  const isBlOrAwb = orderDetails?.transportation_mode === 'AIR' ? 'AWB' : 'BL';

  const { fields, append, remove } = useFieldArray({
    name: 'Remarks',
    control
  });

  const handleAddField = () => {
    append({
      remark: ''
    });
  };

  return (
    <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
      <BoxContainer title="Remarks">
        <div className={css.boxWrapper}>
          <div className={css.fieldWrapper}>
            <Checkbox
              checked={remarkCheckboxTabs.payment_transfers}
              value={`${remarkCheckboxTabs.payment_transfers}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  payment_transfers: !remarkCheckboxTabs.payment_transfers
                })
              }
            />
            <Typography>
              All payment transfer charges outside India to be borne by Consignee
            </Typography>
            <SelectLabel
              required
              isDisabled
              label=""
              placeholder="Select customer name"
              value={{
                label: fieldsData?.name,
                value: fieldsData?.name
              }}
            />
          </div>
          {/* <div className={css.fieldWrapper}>
            <Checkbox
              checked={remarkCheckboxTabs.pss_confirmation}
              value={`${remarkCheckboxTabs.pss_confirmation}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  pss_confirmation: !remarkCheckboxTabs.pss_confirmation
                })
              }
            />
            <Typography>
              Consignment dispatch against PSS confirmation (if order is on PSS basis)
            </Typography>
          </div> */}
          <div className={css.fieldWrapper}>
            <Checkbox
              checked={remarkCheckboxTabs.free_days_at_port}
              value={`${remarkCheckboxTabs.free_days_at_port}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  free_days_at_port: !remarkCheckboxTabs.free_days_at_port
                })
              }
            />
            <div className={css.textField}>
              <Controller
                name="field_free_days_port"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={!remarkCheckboxTabs.free_days_at_port}
                    required
                    label=""
                    placeholder="select no. of days"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                  />
                )}
              />
            </div>
            <Typography>days free days at port of discharge (if applicable)</Typography>
          </div>
          <div className={css.fieldWrapper}>
            <CheckboxLabel
              label=""
              checked={remarkCheckboxTabs.neutral_packing}
              value={`${remarkCheckboxTabs.neutral_packing}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  neutral_packing: !remarkCheckboxTabs.neutral_packing
                })
              }
            />
            <Typography>Packing will be neutral (if packing is neutral)</Typography>
          </div>
          <div className={css.fieldWrapper}>
            <CheckboxLabel
              label=""
              checked={remarkCheckboxTabs.consignment_elchemy}
              value={`${remarkCheckboxTabs.consignment_elchemy}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  consignment_elchemy: !remarkCheckboxTabs.consignment_elchemy
                })
              }
            />
            <Typography>Consignment will be on Elchemy branding (if applicable)</Typography>
          </div>

          <div className={css.fieldWrapper}>
            <Checkbox
              checked={remarkCheckboxTabs.percent_advance_payment}
              value={`${remarkCheckboxTabs.percent_advance_payment}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  percent_advance_payment: !remarkCheckboxTabs.percent_advance_payment
                })
              }
            />
            <div className={css.textField}>
              <Controller
                name="field_percentage_payment"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={!remarkCheckboxTabs.percent_advance_payment}
                    required
                    type="text"
                    inputMode="text"
                    enterKeyHint="next"
                    label=""
                    placeholder="enter percentage"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                  />
                )}
              />
            </div>
            <Typography>of payment is done (if advance payment is done)</Typography>
          </div>

          <div className={css.fieldWrapper}>
            <CheckboxLabel
              label=""
              checked={remarkCheckboxTabs.deviation_quality_days}
              value={`${remarkCheckboxTabs.deviation_quality_days}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  deviation_quality_days: !remarkCheckboxTabs.deviation_quality_days
                })
              }
            />
            <Typography>Any deviation in quality should be reported within</Typography>
            <Controller
              name="field_days_deviation"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled={!remarkCheckboxTabs.deviation_quality_days}
                  required
                  label=""
                  placeholder="select no. of days"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                />
              )}
            />
            <Typography>days of receiving cargo</Typography>
          </div>
          <div className={css.fieldWrapper}>
            <CheckboxLabel
              label=""
              checked={remarkCheckboxTabs.bl_switchable_port}
              value={`${remarkCheckboxTabs.bl_switchable_port}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  bl_switchable_port: !remarkCheckboxTabs.bl_switchable_port
                })
              }
            />
            <Typography>{isBlOrAwb} will be switchable at</Typography>
            <div className={css.textField}>
              <Controller
                name="field_bl_port"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={!remarkCheckboxTabs.bl_switchable_port}
                    required
                    label=""
                    placeholder="select port"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                  />
                )}
              />
            </div>
            <Typography>(if applicable)</Typography>
          </div>
          <div className={css.fieldWrapper}>
            <CheckboxLabel
              label=""
              checked={remarkCheckboxTabs.payment_due_date}
              value={`${remarkCheckboxTabs.payment_due_date}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  payment_due_date: !remarkCheckboxTabs.payment_due_date
                })
              }
            />
            <Typography>Payment due date is</Typography>
            <div className={css.textField}>
              <Controller
                name="field_payment_due"
                control={control}
                render={({ field, fieldState }) => (
                  <InputDatePicker
                    {...field}
                    required
                    disabled={!remarkCheckboxTabs.payment_due_date}
                    label=""
                    value={new Date(`${field?.value ?? ''}`) ?? ''}
                    placeholder="selct date"
                    onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing}
                  />
                )}
              />
            </div>
            <Typography>(if applicable)</Typography>
          </div>

          <div className={css.fieldWrapper}>
            <CheckboxLabel
              label=""
              checked={remarkCheckboxTabs.inspection_cost}
              value={`${remarkCheckboxTabs.inspection_cost}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  inspection_cost: !remarkCheckboxTabs.inspection_cost
                })
              }
            />
            <Typography>Inspection Cost by</Typography>
            <Controller
              name="field_inspection_cost"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled={!remarkCheckboxTabs.inspection_cost}
                  required
                  label=""
                  placeholder="select"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                />
              )}
            />
            <Typography>to be borne by Consignee (if applicable).</Typography>
          </div>

          <div>
            <CheckboxLabel
              label="Other Remarks"
              checked={remarkCheckboxTabs.other_remarks}
              value={`${remarkCheckboxTabs.other_remarks}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  other_remarks: !remarkCheckboxTabs.other_remarks
                })
              }
            />
            <div className={css.remarkBoxContainer}>
              {fields?.map((item, index) => {
                const handleDeleteClick = () => remove(index);
                return (
                  <div className={css.remarks} key={index}>
                    <Typography>{index + 1}. </Typography>
                    <Controller
                      key={item.id}
                      name={`Remarks.${index}.remark`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          label=""
                          disabled={!remarkCheckboxTabs.other_remarks}
                          placeholder="Enter remark"
                          error={fieldState.invalid}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="Error Icon" />
                            )
                          }
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Button
                      disabled={!remarkCheckboxTabs.other_remarks}
                      variant="text"
                      title="Delete Item"
                      onClick={handleDeleteClick}
                      startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}>
                      Delete
                    </Button>
                  </div>
                );
              })}
              <div>
                <Button
                  variant="text"
                  disabled={!remarkCheckboxTabs.other_remarks}
                  onClick={handleAddField}
                  startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
                  Add Remark
                </Button>
              </div>
            </div>
          </div>
        </div>
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button onClick={onBackClick} variant="outlined-secondary">
            Back
          </Button>
          <Button type="submit">Save and Proceed</Button>
        </div>
      </div>
    </form>
  );
};

export default RemarkTab;
