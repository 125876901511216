import { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import css from './index.module.scss';
import { IconNode } from '@components/base';
import { BoxContainer, SelectLabel, TextField } from '@components/common';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Images from '@assets/images';
import { ISupplierFilterOptions } from '@helpers/types/supplier';
import { ISelect } from '@helpers/types';

interface InfoFieldsProps {
  showVendorId?: boolean;
  disabledSupplierName?: boolean;
  filterOptions: ISupplierFilterOptions;
  type?: number;
}

const InfoFields = (props: InfoFieldsProps) => {
  const { showVendorId, disabledSupplierName, filterOptions, type } = props;
  const { control, setValue, getValues } = useFormContext();
  const [requiredGSTNumber, setRequiredGSTNumber] = useState<boolean>(true);
  const [businessTypeOptions, setBusinessTypeOptions] = useState<ISelect[]>([]);
  const isFirstRender = useRef(true);

  const watchField = useWatch({
    name: ['supplier_type', 'type'],
    control
  });

  useEffect(() => {
    if (watchField[0]?.value === 'FOREIGN') {
      setRequiredGSTNumber(false);
    } else {
      setRequiredGSTNumber(true);
    }
  }, [watchField[0]]);

  useEffect(() => {
    if (watchField[1]?.value == 1) {
      setBusinessTypeOptions(
        filterOptions?.business_type_filter_options?.filter((item: any) => item.value != 'NA')
      );
      if (!isFirstRender.current) setValue('business_type', null);
    } else {
      setBusinessTypeOptions(filterOptions?.business_type_filter_options);
      setValue(
        'business_type',
        filterOptions?.business_type_filter_options?.find((item: any) => item.value == 'NA')
      );
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [watchField[1]]);

  return (
    <BoxContainer title="Supplier Information">
      <div className={css.supplierInfoWrapper}>
        <div className={css.supplierTextFieldWrapper}>
          <Controller
            name="supplier_name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                disabled={disabledSupplierName}
                required
                autoFocus={!disabledSupplierName}
                type="text"
                inputMode="text"
                enterKeyHint="next"
                autoComplete="organization"
                label="Supplier Name"
                placeholder="Enter company name"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="gst_number"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={requiredGSTNumber}
                type="text"
                inputMode="text"
                enterKeyHint="next"
                label="GST Number"
                placeholder="Enter GST number"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.supplierTextFieldWrapper}>
          <Controller
            name="type"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required
                autoFocus={disabledSupplierName}
                label="Type"
                isDisabled={disabledSupplierName && type == 1}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                options={
                  filterOptions?.supplier_type_filter_options.map((item: any) => {
                    const obj = { ...item };
                    if (disabledSupplierName && type && type > 1 && item?.value == 1) {
                      obj.isDisabled = true;
                    } else if (disabledSupplierName && type && type == 1 && item?.value != 1) {
                      obj.isDisabled = true;
                    }
                    return obj;
                  }) || []
                }
              />
            )}
          />
          <Controller
            name="supplier_type"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required
                label="Origin"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                options={filterOptions?.supplier_origin_filter_options || []}
              />
            )}
          />
        </div>
        <div className={css.supplierTextFieldWrapper}>
          <Controller
            name="msme_no"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="text"
                inputMode="text"
                enterKeyHint="next"
                label="MSME Number"
                placeholder="Enter MSME Number"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="business_type"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required
                label="Business Type"
                error={fieldState.invalid}
                isDisabled={watchField[1] && watchField[1]?.value != 1}
                helperText={fieldState.error?.message}
                options={businessTypeOptions || []}
              />
            )}
          />
        </div>
        <div className={css.supplierTextFieldWrapper}>
          <Controller
            name="supplier_email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                type="email"
                inputMode="email"
                enterKeyHint="next"
                autoComplete="email"
                label="Email"
                placeholder="Enter email address"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="supplier_website"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="url"
                inputMode="url"
                enterKeyHint="next"
                autoComplete="url"
                name="website"
                label="Website"
                placeholder="Enter website address"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.supplierTextFieldWrapper}>
          <Controller
            name="supplier_phone_number"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                type="tel"
                inputMode="tel"
                enterKeyHint="next"
                autoComplete="tel"
                label="Phone Number"
                placeholder="Enter phone number"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="supplier_landline"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="tel"
                inputMode="tel"
                enterKeyHint="next"
                autoComplete="tel"
                label="Landline"
                placeholder="Enter landline number"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        {showVendorId && (
          <div className={css.supplierTextFieldWrapper}>
            <Controller
              name="vendor_id"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  disabled
                  type="text"
                  inputMode="text"
                  label="Vendor ID"
                  placeholder="Enter vendor ID"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <div aria-hidden="true" className={css.supplierBlankField}></div>
          </div>
        )}
      </div>
    </BoxContainer>
  );
};

InfoFields.propTypes = {
  showVendorId: PropTypes.bool,
  disabledSupplierName: PropTypes.bool
};

InfoFields.defaultProps = {
  showVendorId: true,
  disabledSupplierName: false
};

export default memo(InfoFields);
