import Images from '@assets/images';
import { Button, Chip, IconNode, ToolTip, Typography } from '@components/base';
import { Avatar } from '@components/common';
import css from './index.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { DOCUMENT_GENERATION_STATUS } from '@helpers/constants';
import { IProduct } from '@helpers/types/label';
import { openDocumentSignedLink } from '@helpers/utils';

interface IGenerateLabelCardProps {
  orderId: string;
  labelType: number;
  product?: IProduct;
}

const GenerateLabelCard = (props: IGenerateLabelCardProps) => {
  const navigate = useNavigate();
  const { alertSuccessFaint } = Images;
  const { orderId, product, labelType } = props;
  const pdfURL = product?.label_data[0]?.pdf_document_display_url;
  const docxURL = product?.label_data[0]?.docx_document_display_url;
  const docGenerationStatus = useMemo(() => {
    if (product?.generation_status) {
      return DOCUMENT_GENERATION_STATUS.find((i) => i.value === product.generation_status)?.label;
    }
    return null;
  }, [product?.generation_status]);

  const actionStatus = useMemo(() => {
    if (pdfURL && docxURL) {
      return 'generated';
    } else if (
      docGenerationStatus === 'DOC_GENERATION_PROCESSING' ||
      docGenerationStatus === 'DOC_GENERATION_REQUESTED'
    ) {
      return 'inProcess';
    } else if (docGenerationStatus === 'DOC_GENERATION_FAILED') {
      return 'generationFailed';
    }
    return 'notGenerated';
  }, [product, docGenerationStatus]);

  const getButtonText = () => {
    switch (actionStatus) {
      case 'generated':
        return 'Regenerate';
      case 'inProcess':
        return 'Label generation in process';
      case 'generationFailed':
        return 'Label generation Failed';
      default:
        return 'Generate';
    }
  };

  return (
    <>
      <div className={css.mainWrapper}>
        <div className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {product?.label_data.length ? (
                <IconNode src={alertSuccessFaint} className={css.successIcon} />
              ) : (
                <div className={css.generateLabelIcon}>
                  <Avatar src={Images.generateLabel} alt="" style={{ width: '10', height: '10' }} />
                </div>
              )}
              <Link to={`/order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  Generate Label - {labelType === 3 ? 'All Products' : product?.product_name}
                </Typography>
              </Link>
            </div>
            <div className={css.pocWrapper}>
              {product && product?.label_data.length > 0 && (
                <>
                  {pdfURL && (
                    <ToolTip title="Download PDF">
                      <div>
                        <IconNode
                          src={Images.colouredPdf}
                          alt="PDF Download"
                          onClick={() => {
                            openDocumentSignedLink(pdfURL);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </ToolTip>
                  )}
                  {docxURL && (
                    <ToolTip title="Download Document">
                      <div>
                        <IconNode
                          src={Images.colouredWord}
                          alt="Document Download"
                          onClick={() => {
                            openDocumentSignedLink(docxURL);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </ToolTip>
                  )}
                </>
              )}
              <Button
                variant="text"
                onClick={() => {
                  navigate(
                    `/order/generate-labels/${orderId as string}${
                      labelType !== 3 ? `?productId=${product?.order_item_id}` : ''
                    }`
                  );
                }}
                disabled={actionStatus === 'inProcess'}
                className={css.assignButton}>
                {getButtonText()}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateLabelCard;
