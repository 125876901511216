import httpClient, { httpClient2 } from './index.service';
import { CUSTOMER_SERVER_CONFIG, SERVER_CONFIG } from '@helpers/constants';

async function createCustomer(reqBody: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.createCustomer}`, reqBody);
    return response;
  } catch (error: any) {
    return error;
  }
}

async function fetchAllCustomers(pageNumber?: number, searchText?: string) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.listCustomers}`, {
      params: {
        ...(pageNumber && { page: pageNumber }),
        ...(searchText && { name: searchText })
      }
    });
    return response;
  } catch (error: any) {
    return error;
  }
}

async function updateCustomerInfo(customerId: string, reqBody: any, is_add_poc?: number) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.updateCustomer}${customerId}/?${
        is_add_poc ? `is_add_poc=${is_add_poc}` : ``
      }`,
      reqBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function fetchCustomerDetail(customerId: string) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.fetchCustomer}${customerId}/`);
    return response;
  } catch (error: any) {
    return error;
  }
}

async function documentUpload(customerId: string, reqBody: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.createCustomerDocument}${customerId}/`,
      reqBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function updateDocument(document_id: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.updateCustomerDocument}${document_id}/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function deleteDocument(document_id: string) {
  try {
    const response = await httpClient.delete(
      `${SERVER_CONFIG.deleteCustomerDocument}${document_id}/delete/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function fetchCustomerPaymentDetail(customerId: string) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.fetchCustomer}${customerId}/payments/`);
    return response;
  } catch (error: any) {
    return error;
  }
}

async function sendDocumentsToCustomerViaDoms(orderId: string, body: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.orderDocument}${SERVER_CONFIG.shareDocumentsDirectly}/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function unshareDocumentToCustomerViaDoms(orderId: string, document_id: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.unshareDocument}${document_id}/${SERVER_CONFIG.unshareDocumentDirectly}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function getCustomerAllOrderDocZip(orderId: string, documentIds: string[] | number[]) {
  try {
    const response = await httpClient2.get(
      `${CUSTOMER_SERVER_CONFIG.main}/${CUSTOMER_SERVER_CONFIG.order}/${orderId}/${
        SERVER_CONFIG.getOrderDocZip
      }${SERVER_CONFIG.download}?document_ids=${documentIds.join(',')}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function createCustomerEnquiry(params: FormData) {
  try {
    const response = await httpClient.post(
      `${CUSTOMER_SERVER_CONFIG.main}/${SERVER_CONFIG.rfqs}`,
      params
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export {
  createCustomer,
  fetchAllCustomers,
  updateCustomerInfo,
  fetchCustomerDetail,
  documentUpload,
  updateDocument,
  deleteDocument,
  fetchCustomerPaymentDetail,
  sendDocumentsToCustomerViaDoms,
  unshareDocumentToCustomerViaDoms,
  getCustomerAllOrderDocZip,
  createCustomerEnquiry
};
