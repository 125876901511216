import css from './index.module.scss';
import { Button } from '@components/base';
import OrderPreviewContainer from './order-preview-container';
import PartiesContainer from './parties-container';
import SupplierContainer from './supplier-container';
import RemarkContainer from './remark-container';
import EntityBankDetailContainer from './entity-bank-detail-container';
import OrderDetailContainer from './order-detail-container';
import ItemDetailContainer from './item-detail-container';
import ContainerDetail from './container-detail';
import {
  IOrderCustomerForm,
  ITypeOfOrder,
  IExportConsigneeForm,
  IOrderItemForm,
  IOrderDetailForm,
  IOrderBankDetailForm
} from '@helpers/types/add-order';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { RectifyRemarksContainer } from '@components/common';
import { getReadablePaymentTerm } from '@helpers/utils';

interface PreviewFormProps {
  orderInfo: ITypeOfOrder;
  customerInfo: IOrderCustomerForm;
  consigneeInfo: IExportConsigneeForm;
  itemInfo: IOrderItemForm;
  orderDetail: IOrderDetailForm;
  entityBankDetail: IOrderBankDetailForm;
  onBackClick?: () => void;
  onCancelClick?: () => void;
  onFormSubmit: () => void;
  onEditClick?: (value: number) => void;
  previewForm?: boolean;
  rectifyRemarks?: string;
}

const PreviewForm = (props: PreviewFormProps) => {
  const {
    orderInfo,
    customerInfo,
    consigneeInfo,
    entityBankDetail,
    itemInfo,
    orderDetail,
    onBackClick,
    onCancelClick,
    onFormSubmit,
    previewForm = true,
    rectifyRemarks
  } = props;

  useBeforeUnloadAndNavigate();

  // const partiesAddress = useMemo(() => {
  //   const address = customerInfo?.customer_address;
  //   return `${address?.address_line1}, ${address?.address_line2}, ${address?.city}, ${address?.state}, ${address?.country} - ${address?.zip_code}`;
  // }, [props.customerInfo]);

  const handleEditClick = (value: number) => () => {
    const { onEditClick } = props;
    onEditClick?.(value);
  };

  return (
    <div className={css.formWrapper}>
      {rectifyRemarks ? (
        <RectifyRemarksContainer rectifyRemarks={rectifyRemarks} module="Order" />
      ) : (
        <></>
      )}
      <OrderPreviewContainer
        orderType={orderInfo.supply_type!.label}
        orderNumber={orderInfo.readable_order_id}
      />
      <PartiesContainer
        onEditClick={handleEditClick(1)}
        exporter={`${consigneeInfo.elchemy_entity?.name}`}
        exporterAddress={`${consigneeInfo.elchemy_entity?.address}`}
        exporterGSTNumber={`${consigneeInfo.elchemy_entity?.gst_number}`}
        name={`${customerInfo.customer?.name}`}
        country={`${customerInfo.customer?.country ? customerInfo.customer?.country : '-'}`}
        address={customerInfo?.customer_address ? customerInfo?.customer_address : '-'}
        pocName={`${customerInfo.customer_poc?.given_name} ${customerInfo.customer_poc?.family_name}`}
        pocEmail={`${customerInfo.customer_poc?.email}`}
        pocPhoneNumber={`${customerInfo.customer_poc?.phone_number}`}
        previewForm={previewForm}
      />
      <ItemDetailContainer
        items={itemInfo}
        onEditClick={handleEditClick(3)}
        previewForm={previewForm}
      />
      <OrderDetailContainer
        onEditClick={handleEditClick(4)}
        modeOfTransportation={`${orderDetail.transportation_mode?.value}`}
        incoTerm={`${orderDetail.inco_terms?.label}`}
        countryOfOrigin={`${orderDetail.country_of_origin?.label}`}
        countryOfFinalDestination={`${orderDetail.country_of_final_destination?.label}`}
        portOfLoading={`${orderDetail.port_of_loading?.label}`}
        portOfDischarge={`${orderDetail.port_of_discharge?.label}`}
        placeOfDelivery={`${orderDetail.place_of_delivery}`}
        paymentTerm={getReadablePaymentTerm(orderDetail.payment_terms)}
        deliveryTerm={`${orderDetail.inco_terms?.label}`}
        qualityTesting={`${orderDetail.place_of_quality_test?.label ?? ''}`}
        palletization={orderDetail.palletization}
        previewForm={previewForm}
      />
      {orderDetail && orderDetail?.transportation_mode?.value !== 'AIR' && (
        <ContainerDetail
          containerData={orderDetail.container}
          previewForm={previewForm}
          onEditClick={handleEditClick(4)}
        />
      )}
      <EntityBankDetailContainer
        onEditClick={handleEditClick(5)}
        bankName={`${entityBankDetail.elchemy_bank?.bank_name}`}
        branchName={`${entityBankDetail.elchemy_bank?.branch_name}`}
        accountHolderName={`${entityBankDetail.elchemy_bank?.bank_account_holder_name}`}
        accountNumber={`${entityBankDetail.elchemy_bank?.account_number}`}
        ifscCode={`${entityBankDetail.elchemy_bank?.ifsc_code}`}
        swiftCode={`${entityBankDetail.elchemy_bank?.swift_code}`}
        countryOfOrigin={`${entityBankDetail.elchemy_bank?.address_line1}`}
        previewForm={previewForm}
      />
      <RemarkContainer
        onEditClick={handleEditClick(5)}
        remark={`${entityBankDetail.additional_remarks}`}
        previewForm={previewForm}
      />
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div>
          <Button variant="outlined-secondary" onClick={onBackClick} className={css.nextBtn}>
            {previewForm ? `Back` : `Reject`}
          </Button>
          <Button type="submit" onClick={onFormSubmit}>
            {previewForm ? `Save and send for approval` : `Approve`}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PreviewForm;
