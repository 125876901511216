import Images from '@assets/images';
import { Button, CheckboxLabel, Divider, IconNode, Typography } from '@components/base';
import { BoxContainer, AddressFields } from '@components/common';
import { ICustomerDetailForm } from '@helpers/types/customer';
import { ChangeEvent, Fragment, useCallback, useEffect, useRef } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import css from './index.module.scss';

const AddressContainer = () => {
  const firstMount = useRef(true);
  const { control, getValues, resetField, setValue } = useFormContext<ICustomerDetailForm>();
  const watchFields = useWatch({
    name: [
      'same_as_office',
      'address_line1',
      'address_line2',
      'zip_code',
      'city',
      'state',
      'country',
      'address_suffix'
    ],
    control
  });

  const { fields, append, remove, update } = useFieldArray({
    name: 'addresses',
    control
  });

  useEffect(() => {
    if (watchFields[0] && !firstMount.current) {
      const firstAddressId = getValues('addresses.0').address_id;
      resetField('addresses.0', {
        defaultValue: {
          address_id: firstAddressId,
          address_type: 'FAC',
          address_line1: watchFields[1] || '',
          address_line2: watchFields[2] || '',
          zip_code: watchFields[3] || '',
          city: watchFields[4] ?? null,
          state: watchFields[5] ?? null,
          country: watchFields[6] ?? null,
          address_suffix: watchFields[7] ?? null
        },
        keepDirty: false,
        keepError: false,
        keepTouched: false
      }); // resetField is used intentionally here to prevent dirty changes while "form EDIT"
      setValue('addresses.0.city', watchFields[4] ?? null, { shouldDirty: false });
      setValue('addresses.0.state', watchFields[5] ?? null, { shouldDirty: false });
      setValue('addresses.0.country', watchFields[6] ?? null, { shouldDirty: false });
    }
    return () => {
      firstMount.current = false; //used intentionally to prevent firstMount update
    };
  }, [watchFields]);

  const handleAddFactoryAddress = useCallback(() => {
    append({
      address_id: '',
      address_type: 'FAC',
      address_line1: '',
      address_line2: '',
      zip_code: '',
      city: null,
      state: null,
      country: null,
      address_suffix: null
    });
  }, [fields]);

  const handleCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.currentTarget.checked;
    if (!checked) {
      const firstAddressId = getValues('addresses.0').address_id;
      update(0, {
        address_id: firstAddressId,
        address_type: 'FAC',
        address_line1: '',
        address_line2: '',
        zip_code: '',
        city: null,
        state: null,
        country: null,
        address_suffix: null
      });
    }
    setValue('same_as_office', !!checked);
  }, []);

  return (
    <BoxContainer title="Address" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <Typography variant="h4">Customer Address</Typography>
        <div className={css.addressWrapper}>
          <AddressFields
            firstAddressLine="address_line1"
            secondAddressLine="address_line2"
            zipCode="zip_code"
            city="city"
            state="state"
            country="country"
            suffix="address_suffix"
          />
        </div>
        <Divider className={css.divider} />
        <div className={css.shippingAddressHeader}>
          <Typography variant="h4">Shipping Address</Typography>
          <Controller
            name="same_as_office"
            control={control}
            render={({ field }) => (
              <CheckboxLabel
                {...field}
                label="Same as Customer Address"
                value={`${field.value}`}
                checked={field.value}
                onChange={handleCheckboxChange}
              />
            )}
          />
        </div>
        {fields.map((item, index) => {
          const handleDelete = () => remove(index);
          return (
            <Fragment key={item.id}>
              <div className={css.addressHeader}>
                <Typography variant="h4">{`Address ${index + 1}`}</Typography>
                {index > 0 && (
                  <Button
                    variant="text"
                    onClick={handleDelete}
                    startIcon={<IconNode src={Images.deleteRed} alt="error icon" />}>
                    Delete
                  </Button>
                )}
              </div>
              <div className={css.addressWrapper}>
                <AddressFields
                  firstAddressLine={`addresses.${index}.address_line1`}
                  secondAddressLine={`addresses.${index}.address_line2`}
                  zipCode={`addresses.${index}.zip_code`}
                  city={`addresses.${index}.city`}
                  state={`addresses.${index}.state`}
                  country={`addresses.${index}.country`}
                  disabled={index === 0 && watchFields[0]}
                  suffix={`addresses.${index}.address_suffix`}
                />
              </div>
              <Divider className={css.divider} />
            </Fragment>
          );
        })}
        <Button
          variant="text"
          className={css.plusShippingBtn}
          onClick={handleAddFactoryAddress}
          startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
          Shipping Address
        </Button>
      </div>
    </BoxContainer>
  );
};

export default AddressContainer;
