import { useEffect } from 'react';
import css from './index.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, IconNode, Typography, Image, Loader } from '@components/base';
import { LinkButton, Seo, TextField } from '@components/common';
import { useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordSchema } from '@helpers/yup';
import Images from '@assets/images';
import { IPasswordSetup } from '@helpers/types';
import { patchPasswordReset } from '@services/login.service';
import { CLIENT_ROUTES } from '@router/routes';
import notify from '@helpers/toastify-helper';

const AccountSetup = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: ''
    },
    resolver: yupResolver(resetPasswordSchema)
  });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { state } = location;
    const { email, uidb64, token } = state || {};
    if (email && uidb64 && token) setValue('email', email);
    else navigate(`/${CLIENT_ROUTES.order}`, { replace: true });
  }, [location.state]);

  const handleFormSubmit: SubmitHandler<IPasswordSetup> = async (data) => {
    const { state } = location;
    const { uidb64, token } = state || {};
    const credential = {
      uidb64,
      token,
      password: data.password
    };
    const response = await patchPasswordReset(credential);
    if (response.success) {
      navigate(`/${CLIENT_ROUTES.acknowledge}`, {
        replace: true,
        state: { email: data.email, password: data.password }
      });
    } else {
      notify({ message: response.error ?? 'Something went wrong.', severity: 'error' });
      navigate(`${CLIENT_ROUTES.login}`, {
        replace: true
      });
    }
  };

  return (
    <main className={css.accountSetup}>
      <Seo title="Register" />
      <LinkButton to={`${CLIENT_ROUTES.login}`} className={css.backButton} replace>
        <Image src={Images.backArrow} alt="Back button" />
        <Typography variant="p">Back to Login</Typography>
      </LinkButton>
      <Typography variant="h1">Set New Password</Typography>
      <form
        className={css.passwordFormWrapper}
        onSubmit={handleSubmit(handleFormSubmit)}
        noValidate>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              type="email"
              inputMode="email"
              enterKeyHint="next"
              autoComplete="email"
              label="Email"
              disabled={!!field.value.length}
              placeholder="Enter your email"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              autoFocus
              type="password"
              enterKeyHint="next"
              autoComplete="new-password"
              label="Set Password"
              placeholder="Enter your new password"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              type="password"
              enterKeyHint="done"
              autoComplete="new-password"
              label="Confirm Password"
              placeholder="Confirm your new password"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
            />
          )}
        />
        <Button type="submit" disabled={isSubmitting}>
          Save Password
        </Button>
      </form>
      <Loader open={isSubmitting} />
    </main>
  );
};

export default AccountSetup;
