import {
  ButtonHTMLAttributes,
  ReactNode,
  forwardRef,
  memo,
  useRef,
  useImperativeHandle
} from 'react';
import './index.style.scss';
import PropTypes from 'prop-types';
import { buttonVariantType } from '@helpers/types';
import { pushEventTracking } from '@helpers/utils';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: buttonVariantType;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  eventTrackingName?: string;
  onClick?: any;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    variant,
    children,
    disabled,
    className,
    startIcon,
    endIcon,
    eventTrackingName,
    ...otherProps
  } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => buttonRef.current!, []);

  return (
    <button
      onClick={() => {
        if (eventTrackingName) {
          pushEventTracking(eventTrackingName);
        }
        props.onClick && props.onClick();
      }}
      {...otherProps}
      ref={buttonRef}
      className={`button-root button-${variant} ${className}`}
      disabled={disabled}>
      {startIcon}
      {children}
      {endIcon}
    </button>
  );
});

Button.propTypes = {
  variant: PropTypes.oneOf([
    'contained',
    'outlined',
    'outlined-secondary',
    'text',
    'outlined-warning'
  ])
};

Button.defaultProps = {
  variant: 'contained',
  type: 'button'
};

export default memo(Button);
