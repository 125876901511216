import css from './index.module.scss';
import { Button } from '@components/base';
import { useFormContext } from 'react-hook-form';
import BankDetailConatiner from './bank-detail-container';
import RemarkContainer from './remark-container';
import { IOrderBankDetailForm } from '@helpers/types/add-order';
import { EntityBankDetails } from '@helpers/types/entity';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { RectifyRemarksContainer } from '@components/common';

interface EntityBankDetailRemarkFormProps {
  bankOptions: EntityBankDetails[];
  onFormSubmit: (data: IOrderBankDetailForm) => void;
  onCancelClick?: () => void;
  onBackClick?: () => void;
  rectifyRemarks?: string;
}

const EntityBankDetailRemarkForm = (props: EntityBankDetailRemarkFormProps) => {
  const { onFormSubmit, onBackClick, onCancelClick, bankOptions, rectifyRemarks } = props;

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext<IOrderBankDetailForm>();

  useBeforeUnloadAndNavigate();

  return (
    <>
      {' '}
      <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
        {rectifyRemarks ? (
          <RectifyRemarksContainer rectifyRemarks={rectifyRemarks} module="Order" />
        ) : (
          <></>
        )}
        <BankDetailConatiner options={bankOptions} />
        <RemarkContainer />
        <div className={css.actionBtnWrapper}>
          <Button variant="text" onClick={onCancelClick}>
            Cancel
          </Button>
          <div>
            <Button variant="outlined-secondary" onClick={onBackClick} className={css.nextBtn}>
              Back
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Save and Proceed
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default EntityBankDetailRemarkForm;
