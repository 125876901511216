import { useCallback, useState, useEffect } from 'react';
import css from './index.module.scss';
import { Button, IconNode, Typography } from '@components/base';
import Images from '@assets/images';
import POCAdd from './poc-add';
import POCDetailCard from '../poc-detail-card';
import { IPointOfContact, Poc } from '@helpers/types/point-of-contact';
import { updateSupplierInfo } from '@services/supplier.service';
import notify from '@helpers/toastify-helper';
import { fetchAllUsers } from '@services/user.service';

interface POCDetailProps {
  supplierId: string;
  pocs: Poc[];
  onChange?: (data: any) => void;
}

const POCDetail = (props: POCDetailProps) => {
  const { pocs } = props;
  const [pocState, setPocState] = useState(false);
  const [userList, setUserList] = useState([]);

  const handleAddPoc = useCallback(() => setPocState((prevState) => !prevState), []);

  const handleCancelClick = useCallback(() => {
    handleAddPoc();
  }, [handleAddPoc]);

  useEffect(() => {
    if (pocState) {
      fetchUsersForPocList();
    }
  }, [pocState]);

  const handleFormSubmit = async (data: Omit<IPointOfContact, 'uid'>) => {
    const { onChange, supplierId } = props;
    const reqBody = {
      supplier_pocs: [data.user_id]
    };
    const response = await updateSupplierInfo(supplierId, reqBody, 1);
    if (response.success) {
      const { data } = response;
      notify({ message: 'Point of contact added successfully' });
      handleAddPoc();
      onChange?.(data);
    } else {
      notify({
        message: response.error ?? 'Unable to add Point of contact, try again',
        severity: 'error',
        dismissible: true
      });
    }
  };

  const fetchUsersForPocList = async () => {
    const userList = await fetchAllUsers({
      role: 'external',
      unassigned: true
    });
    if (userList?.success) {
      setUserList(userList.data.users);
    }
  };

  return (
    <div className={css.mainWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="h4">Point of Contact</Typography>
        <Button
          variant="text"
          onClick={handleAddPoc}
          startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
          Add POC
        </Button>
      </div>
      <div className={css.pocWrapper}>
        {pocs.map((poc, index) => (
          <POCDetailCard
            key={index}
            name={`${poc.given_name} ${poc.family_name}`}
            email={poc.email}
            phoneNumber={poc.phone_number}
          />
        ))}
      </div>
      {pocState && (
        <POCAdd onCancel={handleCancelClick} onSubmit={handleFormSubmit} userList={userList} />
      )}
    </div>
  );
};

export default POCDetail;
