import { memo, useMemo, useEffect, useState } from 'react';
import css from './index.module.scss';
import {
  BoxContainer,
  SelectLabel,
  TextField,
  RectifyRemarksContainer,
  AutoComplete
} from '@components/common';
import { ICustomerInfo } from '@helpers/types/customer';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { Poc } from '@helpers/types/point-of-contact';
import { IOrderCustomerForm } from '@helpers/types/add-order';
import { fetchAllCustomers } from '@services/customer.service';
import notify from '@helpers/toastify-helper';

interface CustomerContainerProps {
  options: any;
  rectifyRemarks?: string;
  handleTextChange?: any;
}

const CustomerContainer = (props: CustomerContainerProps) => {
  const { rectifyRemarks, handleTextChange } = props;
  const { control, setValue } = useFormContext<IOrderCustomerForm>();
  const [customerStates, setCustomerStates] = useState<any>({
    options: props?.options || [] ,
    isLoading: false,
    isOpen: false,
    productQuery: null
  });

  const { options, isLoading, isOpen, productQuery } = customerStates;

  const watchField = useWatch({
    name: ['customer', 'customer_poc'],
    control
  });

  useEffect(() => {
    if (watchField?.length && watchField[0]?.name) {
      setCustomerStates((prevState: any) => ({
        ...prevState,

        productQuery: watchField[0]
      }));
    }
    }, []);

  const address = useMemo(() => {
    if (watchField[0]) {
      const { addresses } = watchField[0];

      /**
       * Filter out duplicate addresses
       */
      const mymap = new Map();
      const unique = addresses.filter((el: any) => {
        const val = mymap.get(el.readable_address);
        if (val) {
          if (el.id < val) {
            mymap.delete(el.readable_address);
            mymap.set(el.readable_address, el.id);
            return true;
          } else {
            return false;
          }
        }
        mymap.set(el.readable_address, el.address_id);
        return true;
      });
      return unique;
    }
    return [];
  }, [watchField[0]]);

  // useEffect(() => {
  //   if (address?.length) {
  //     setValue('customer_address', address[0]);
  //   }
  // }, [address]);

  const handleChange = async (event: any, param: any) => {
    setCustomerStates((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));
    const customerList = await fetchAllCustomers(undefined, param);
    if (customerList?.success) {
      setCustomerStates((prevState: any) => ({
        ...prevState,
        isLoading: false,
        options: customerList?.data?.results ? customerList?.data?.results : [],
        isOpen: true
      }));
    } else {
      setCustomerStates((prevState: any) => ({
        ...prevState,
        isLoading: false,

        isOpen: true
      }));
      notify({
        message: customerList?.error ?? 'Unable to fetch customer list ',
        severity: 'error'
      });
    }
  };

  const optionSelectedHandler = (event: any, param: any) => {
    if (param) {
      const newValue = param as any;
      setValue('customer', newValue);
      setValue('customer_poc', newValue?.pocs?.length ? newValue.pocs[0] : null);
      setValue('customer_address', newValue?.addresses?.length ? newValue?.addresses[0] : null);
      setCustomerStates((prevState: any) => ({
        ...prevState,
        isLoading: false,
        productQuery: newValue,
        isOpen: false
      }));
    } else setValue('customer', null, { shouldValidate: true });
    setCustomerStates((prevState: any) => ({ ...prevState, isOpen: false, isLoading: false }));
  };

  const handlePocChange = (newValue: any) => {
    if (newValue) {
      const data = newValue;
      setValue('customer_poc', data, { shouldValidate: true });
    } else setValue('customer_poc', null, { shouldValidate: true });
  };

  const handleAddressChange = (newValue: any) => {
    if (newValue) {
      const data = newValue;
      setValue('customer_address', data, { shouldValidate: true });
    } else setValue('customer_address', null, { shouldValidate: true });
  };

  const handleOutsideClick = () => {
    setCustomerStates((prevState: any) => ({ ...prevState, isOpen: false, isLoading: false }));
  };
  return (
    <>
      {rectifyRemarks ? (
        <RectifyRemarksContainer rectifyRemarks={rectifyRemarks} module="Order" />
      ) : (
        <></>
      )}
      <BoxContainer title="Customer" className={css.mainWrapper}>
        <div className={css.boxWrapper}>
          <div className={css.rowWrapper}>
            <Controller
              name="customer"
              control={control}
              render={({ field, fieldState }) => (
                // <SelectLabel
                //   {...field}
                //   autoFocus
                //   required
                //   isSearchable
                //   options={options}
                //   getOptionLabel={(option: any) => option.name}
                //   getOptionValue={(option: any) => option.customer_id}
                //   label="Customer Name"
                //   placeholder="Select customer"
                //   onChange={handleChange}
                //   rootClassName={css.fieldWrapper}
                //   error={fieldState.invalid}
                //   helperText={fieldState.error?.message}
                // />
                <AutoComplete
                  {...field}
                  autoFocus
                  required
                  label="Customer Names"
                  placeholder="Start typing"
                  onClickAway={handleOutsideClick}
                  options={options}
                  onInputChange={handleChange}
                  onInputSelection={optionSelectedHandler}
                  isOpen={isOpen}
                  isLoading={isLoading}
                  keyOption="name"
                  value={productQuery}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldWrapper}
                />
              )}
            />
            {/* <SelectLabel
              isDisabled
              value={{ label: watchField[0]?.country, value: watchField[0]?.country }}
              label="Country"
              placeholder="Select Country"
            /> */}
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name="customer_poc"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  required
                  options={watchField[0]?.pocs ?? []}
                  getOptionLabel={(option: any) => `${option.given_name} ${option.family_name}`}
                  getOptionValue={(option: any) => option.user_id}
                  label="Point of Contact Name"
                  placeholder="Select POC name"
                  onChange={handlePocChange}
                  rootClassName={css.fieldWrapper}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <TextField
              disabled
              value={watchField[1]?.phone_number ?? ''}
              label="POC  Contact Number"
              placeholder="Enter POC Contact Number"
            />
          </div>
          <div className={css.rowWrapper}>
            <TextField
              disabled
              value={watchField[1]?.email ?? ''}
              label="POC Email ID"
              placeholder="Enter POC Email ID"
            />
          </div>

          <div className={css.rowWrapper}>
            <TextField
              disabled
              value={watchField[0]?.description ?? 'Not Available'}
              label="Additional Remarks/Requirements"
              placeholder="Enter Customer Additional Remarks/Requirements"
            />
          </div>

          <div className={css.rowWrapper}>
            <Controller
              name="customer_address"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  required
                  options={address}
                  defaultValue={watchField[0]?.addresses[0] ?? ''}
                  getOptionLabel={(option: any) => option.readable_address}
                  getOptionValue={(option: any) => option.address_id}
                  label="Customer Address"
                  placeholder="Enter Customer Address"
                  onChange={handleAddressChange}
                  rootClassName={css.fieldWrapper}
                  error={fieldState.invalid}
                  styles={{
                    container: (provided: any) => ({
                      ...provided,
                      width: 875
                    })
                  }}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
        </div>
      </BoxContainer>
    </>
  );
};

export default memo(CustomerContainer);
