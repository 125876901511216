import { memo, useMemo, useEffect, useState } from 'react';
import css from './index.module.scss';
import { Divider, IconNode, Typography } from '@components/base';
import { DataGrid, DocumentContainer } from '@components/common';
import Images from '@assets/images';
import { IOrderItem, IOrderItemForm } from '@helpers/types/add-order';
import { generateReadablePackingDetails, getCommaSepratedValue } from '@helpers/utils';
import { fetchAmountInWords } from '@services/util.service';

/* eslint-disable */
const writtenNumber = require('written-number');

interface ItemContainerProps {
  items: IOrderItemForm;
  onEditClick?: () => void;
  previewForm?: boolean;
}

const ItemContainer = (props: ItemContainerProps) => {
  const {
    onEditClick,
    previewForm = true,
    items: { items }
  } = props;
  const { currency, unit_of_weight } = props.items;

  const [totalAmountInWords, setTotalAmountInWords] = useState('');

  const totalAmountOfAllItems = useMemo(() => {
    let totalAmount = 0;
    items.forEach((item) => {
      totalAmount += Number(item.quantity) * Number(item.rate_per_unit);
    });
    return totalAmount;
  }, []);

  useEffect(() => {
    (async () => {
      if (totalAmountOfAllItems > 0) {
        const response = await fetchAmountInWords({
          amount: totalAmountOfAllItems.toString(),
          currency_type: currency?.value
        });
        if (response?.success) {
          setTotalAmountInWords(response.data.amount_in_words);
        }
      }
    })();
  }, [totalAmountOfAllItems]);

  return (
    <div className={css.outlineWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="pdoc">Item Details</Typography>
        {previewForm ? (
          <IconNode
            src={Images.editRed}
            alt="edit icon"
            className={css.editButton}
            component="button"
            onClick={onEditClick}
          />
        ) : (
          <></>
        )}
      </div>
      <ItemDetailContainer
        items={items}
        currency={currency?.value}
        unit_of_weight={unit_of_weight?.value}
      />
      <div>
        <div>
          <div className={css.rowWrapper}>
            <div className={css.columnWrapper}>
              <Typography variant="ptext">Invoice Amount (in Words)</Typography>
              <br />

              <Typography variant="pdoc" className="text-capitalize">
                {totalAmountInWords}
              </Typography>
            </div>
          </div>
          <br />
          <div className={css.rowWrapper}>
            <div className={css.columnWrapper}>
              <Typography variant="ptext">Total Amount</Typography>
              <br />
              <Typography variant="pdoc">
                {currency?.value} {getCommaSepratedValue(totalAmountOfAllItems.toString())}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ItemDetailContainerProps {
  items: IOrderItem[];
  unit_of_weight?: string | null;
  currency?: string;
}

const ItemDetailContainer = (props: ItemDetailContainerProps) => {
  const { items, unit_of_weight, currency } = props;

  const [itemColumns, itemData] = useMemo(() => {
    const columns: any = [
      { Header: 'Sr No', accessor: 'index' },
      { Header: 'Item Details', accessor: 'item' },
      { Header: 'HS Code', accessor: 'hs_code' },
      { Header: `Qty. in ${unit_of_weight}`, accessor: 'quantity' },
      { Header: 'Price/Unit', accessor: 'price' },
      { Header: 'Amount', accessor: 'totalAmount' }
    ];

    const rows = items.map((item: any, index: number) => ({
      index: index + 1,
      item: (
        <div className={css.itemDetails}>
          <Typography variant="p">{item.name?.name}</Typography>
          {item?.description && <Typography variant="label">{item.description}</Typography>}
          {item?.country_of_origin && (
            <>
              <br />
              <Typography variant="label">
                Country of Origin: {item.country_of_origin?.label}
              </Typography>
            </>
          )}
          {item?.package?.length ? (
            <>
              <br />
              <Typography variant="label">
                Packaging Details: {generateReadablePackingDetails(item.package).join(', ')}
              </Typography>
            </>
          ) : (
            <>
              <br />
              <Typography variant="label">Packaging details not available</Typography>
            </>
          )}
          <>
            <br />
            <Typography variant="label">
              Pre-Shipment Sample Required: {item.is_pre_shipment_sample_required ? 'Yes' : 'No'}
            </Typography>
          </>
        </div>
      ),
      hs_code: item.hs_code,
      quantity: item.quantity,
      price: `${currency} ${getCommaSepratedValue(item.rate_per_unit)}`,
      totalAmount: `${currency} ${getCommaSepratedValue(
        (Number(item.quantity) * Number(item.rate_per_unit)).toString()
      )}`
    }));
    return [columns, rows];
  }, [props.items]);

  return (
    <div className={css.itemWrapper}>
      <DataGrid columns={itemColumns} data={itemData} />
      {/* <div className={css.rowWrapper}>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Invoice Amount (in Words)</Typography>
          <Typography variant="pdoc" className="text-capitalize">
            USD {writtenNumber(Number(item.quantity) * Number(item.rate_per_unit))} only
          </Typography>
        </div>
      </div>
      <div className={css.rowWrapper}>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Total Amount</Typography>
          <Typography variant="pdoc">
            USD{' '}
            {getCommaSepratedValue((Number(item.quantity) * Number(item.rate_per_unit)).toString())}
          </Typography>
        </div>
      </div> */}
      {/* <div className={css.rowWrapper}>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Item Documents</Typography>
          <DocumentContainer headers={['Document Name', 'Proofs']} headerClass={css.documentHeader}>
            <DocumentContainer.Row name="Certificate of Analysis (CoA)" documentObject="" />
            <DocumentContainer.Row
              name="Material Safety Data Sheet (MSDS)"
              documentObject={`${item.product_grade?.msds_doc}`}
            />
            {item.supplier?.documents?.map((data, index) => {
              if (data.document_type === 'IIP')
                return (
                  <DocumentContainer.Row
                    key={index}
                    name="IIP Certificate"
                    documentObject={`${data.document_object}`}
                  />
                );
            })}
          </DocumentContainer>
        </div>
      </div> */}
      <Divider className={css.divider} />
    </div>
  );
};

export default memo(ItemContainer);
