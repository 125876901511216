import Images from '@assets/images';
import css from './index.module.scss';
import { Typography, Button, Image } from '@components/base';
import { memo } from 'react';

interface NoDocumentPlaceholderProps {
  onClick?: () => void;
  disabled?: boolean;
  title?: string;
  supportingText?: string;
  buttonText?: string;
  image?: string;
}

const NoDocumentPlaceholder = (props: NoDocumentPlaceholderProps) => {
  const {
    onClick,
    disabled,
    title = 'No Documents Added Yet',
    supportingText = 'When documents are added, they will show up here.',
    buttonText = 'Add Documents',
    image
  } = props;
  return (
    <div className={css.documentPlaceholder}>
      {image ? (
        <Image src={image} height={400} width={400} />
      ) : (
        <Image
          src={Images.listingPlaceholder}
          alt="Placeholder"
          height={200}
          style={{ marginBottom: 10 }}
        />
      )}
      <Typography variant="h4">{title}</Typography>
      <Typography variant="span" className={css.placeholderLabel}>
        {supportingText}
      </Typography>
      {onClick ? (
        <Button onClick={onClick} className={css.button} disabled={disabled}>
          {buttonText}
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default memo(NoDocumentPlaceholder);
