import { Divider, Typography } from '@components/base';
import css from '../index.module.scss';
import { ITaskItemProps } from '@helpers/types/task-tabs';
interface IPartiesProps {
  data: ITaskItemProps;
}

const Parties = (props: IPartiesProps) => {
  const { data } = props;
  return (
    <div className={css.boxWrapper}>
      <div className={css.partiesContainer}>
        <div className={css.fieldWrapper}>
          <div className={css.partyContainer}>
            <div className={css.fieldTitle}>
              <Typography variant="pdoc">Shipper</Typography>
            </div>
            <div className={css.wrapper}>
              <Typography variant="span">{data.shipper}</Typography>
            </div>
          </div>
          <Divider className={css.divider} />
          <div className={css.partyContainer}>
            <div className={css.fieldTitle}>
              <Typography variant="pdoc">Consignee</Typography>
            </div>
            <div className={css.wrapper}>
              <Typography variant="span">{data.consignee}</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parties;
