import { memo } from 'react';
import css from './index.module.scss';
import { IconNode, Typography } from '@components/base';
import Images from '@assets/images';

interface OrderDetailContainerProps {
  onEditClick?: () => void;
  modeOfTransportation: string;
  incoTerm: string;
  countryOfOrigin: string;
  countryOfFinalDestination: string;
  portOfLoading: string;
  portOfDischarge: string;
  placeOfDelivery: string;
  paymentTerm: Array<string>;
  deliveryTerm: string;
  qualityTesting: string;
  previewForm?: boolean;
  palletization: boolean;
}

const OrderDetailContainer = (props: OrderDetailContainerProps) => {
  const {
    onEditClick,
    modeOfTransportation,
    incoTerm,
    countryOfOrigin,
    countryOfFinalDestination,
    portOfLoading,
    portOfDischarge,
    placeOfDelivery,
    paymentTerm,
    deliveryTerm,
    qualityTesting,
    previewForm = true,
    palletization = false
  } = props;

  return (
    <div className={css.outlineWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="pdoc">Order Details</Typography>
        {previewForm ? (
          <IconNode
            src={Images.editRed}
            alt="edit icon"
            className={css.editButton}
            component="button"
            onClick={onEditClick}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={css.rowWrapper}>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Mode of Transportation</Typography>
          <Typography variant="pdoc">{modeOfTransportation}</Typography>
        </div>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Inco Terms</Typography>
          <Typography variant="pdoc">{incoTerm}</Typography>
        </div>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Item to be palletized</Typography>
          <Typography variant="pdoc">{palletization ? 'Yes' : 'No'}</Typography>
        </div>
      </div>
      <div className={css.rowWrapper}>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Country of Origin</Typography>
          <Typography variant="pdoc">{countryOfOrigin}</Typography>
        </div>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Country of Final Destination</Typography>
          <Typography variant="pdoc">{countryOfFinalDestination}</Typography>
        </div>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Quality Testing</Typography>
          <Typography variant="pdoc">{qualityTesting ? qualityTesting : '-'}</Typography>
        </div>
      </div>
      <div className={css.rowWrapper}>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Port of Loading</Typography>
          <Typography variant="pdoc">{portOfLoading}</Typography>
        </div>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Port of Discharge</Typography>
          <Typography variant="pdoc">{portOfDischarge}</Typography>
        </div>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Place of Delivery</Typography>
          <Typography variant="pdoc">{placeOfDelivery}</Typography>
        </div>
      </div>
      <div className={css.rowWrapper}>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Delivery Terms</Typography>
          <Typography variant="pdoc">{deliveryTerm}</Typography>
        </div>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Payment Terms</Typography>
          {paymentTerm?.map((item: any, index: number) => {
            return (
              <Typography variant="pdoc" key={index}>
                {item}
              </Typography>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(OrderDetailContainer);
