import css from './index.module.scss';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectLabel, TextField } from '@components/common';
import { Button, IconNode } from '@components/base';
import Images from '@assets/images';
import { getPackagingListFilterOptions } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { IPackageSchema } from '@helpers/types/packing';
import { ISelect } from '@helpers/types';

interface PackingDataFieldsProps {
  fields: any;
  handleAddField: () => void;
  remove: (index: number) => void;
  unitOfWeightWatch?: any;
  itemIndex: number;
}

interface PackingDataState {
  packageTypes: ISelect[];
  unitOfWeights: ISelect[];
}

const PackingDataFields = (props: PackingDataFieldsProps) => {
  const [filterData, setFilterData] = useState<PackingDataState>({
    packageTypes: [],
    unitOfWeights: []
  });
  const { packageTypes, unitOfWeights } = filterData;
  const { fields, handleAddField, remove, unitOfWeightWatch, itemIndex } = props;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await getPackagingListFilterOptions();
    if (response?.success) {
      setFilterData({
        packageTypes: response?.data?.filter_options?.package_types,
        unitOfWeights: response?.data?.filter_options?.unit_of_weights
      });
    } else {
      notify({
        severity: 'error',
        title: 'Error',
        message: response?.message ?? 'Something went wrong!'
      });
    }
  };

  return (
    <div className={css.boxWrapper}>
      {fields.map((item: IPackageSchema, index: number) => {
        const handleDeleteClick = () => remove(index);
        return (
          <PackagingField
            item={item}
            type={`items.${itemIndex}.package.${index}.type`}
            no_of_package={`items.${itemIndex}.package.${index}.no_of_package`}
            weight={`items.${itemIndex}.package.${index}.weight`}
            unit={`items.${itemIndex}.package.${index}.unit`}
            onDeleteClick={handleDeleteClick}
            key={item.id}
            index={index}
            showDelete={fields.length > 1}
            packageTypes={packageTypes}
            unitOfWeights={unitOfWeights}
            unitOfWeightWatch={unitOfWeightWatch}
          />
        );
      })}
      <Button
        variant="text"
        onClick={handleAddField}
        startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
        Add More Packaging and size
      </Button>
    </div>
  );
};

interface PackagingFieldProps {
  item: IPackageSchema;
  type: string;
  no_of_package: string;
  weight: string;
  unit: string;
  showDelete: boolean;
  onDeleteClick: () => void;
  packageTypes: ISelect[];
  unitOfWeights: ISelect[];
  unitOfWeightWatch?: string;
  index: number;
}

const PackagingField = (props: PackagingFieldProps) => {
  const {
    item,
    type,
    no_of_package,
    weight,
    unit,
    showDelete,
    onDeleteClick,
    packageTypes,
    unitOfWeights,
    unitOfWeightWatch
  } = props;
  const { control, setValue } = useFormContext();

  // useEffect(() => {
  //   if (unitOfWeightWatch) {
  //     setValue(unit, unitOfWeightWatch);
  //   }
  // }, [unitOfWeightWatch]);

  return (
    <div className={css.rowWrapper}>
      <Controller
        name={no_of_package}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required
            type="text"
            label="No. of Package"
            placeholder="Enter number"
            rootClassName={`${css.fieldWrapper}`}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
          />
        )}
      />
      <Controller
        name={weight}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required
            inputMode="decimal"
            type="text"
            label="Packaging Size"
            placeholder="Enter size"
            rootClassName={`${css.fieldWrapper1}`}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
          />
        )}
      />
      <Controller
        name={unit}
        control={control}
        render={({ field, fieldState }) => (
          <SelectLabel
            {...field}
            required
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            options={unitOfWeights}
            label="Packaging unit"
            placeholder="Select unit"
            rootClassName={css.fieldWrapper2}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            isSearchable
          />
        )}
      />
      <Controller
        name={type}
        control={control}
        render={({ field, fieldState }) => (
          <SelectLabel
            {...field}
            required
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            options={packageTypes}
            label="Packaging type"
            placeholder="Select type"
            rootClassName={css.fieldWrapper3}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            isSearchable
          />
        )}
      />
      {showDelete && (
        <Button
          variant="text"
          title="Delete Item"
          onClick={onDeleteClick}
          startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}></Button>
      )}
    </div>
  );
};

export default PackingDataFields;
