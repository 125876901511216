import React, { useState, useRef, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import css from './index.module.scss';
import { ILocationProperties } from '@components/common/popover-button';
interface PopoverProps {
  children: React.ReactNode;
  visible?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
  rect?: ILocationProperties;
}

const Popover = ({
  children,
  visible = false,
  position = 'top',
  rect,
  ...otherProps
}: PopoverProps) => {
  const [isOpen, setIsOpen] = useState(visible);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  useEffect(() => {
    if (!popoverRef.current || !isOpen) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (popoverRef.current && popoverRef.current.offsetWidth !== width) {
        setWidth(popoverRef.current.offsetWidth);
      }
      if (popoverRef.current && popoverRef.current.offsetHeight !== height) {
        setHeight(popoverRef.current.offsetHeight);
      }
    });
    resizeObserver.observe(popoverRef.current);
    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(visible);
  }, [visible]);

  const popoverClasses = classNames(css.popover, {
    [css.visible]: isOpen,
    [css['position-' + position]]: position
  });

  const myStyle = useMemo(() => {
    return {
      left: `calc(0vw + ${rect?.left}px - ${width}px)`,
      top: `calc(0vw + ${rect?.top}px - ${height}px)`
    };
  }, [width, rect]);

  return (
    <div className={css.container}>
      {isOpen && (
        <div ref={popoverRef} style={myStyle} className={`${popoverClasses}`} {...otherProps}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Popover;
