/* eslint-disable @typescript-eslint/no-empty-function */
import { Button } from '@components/base';
import { BoxContainer } from '@components/common';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import ConsigneeContainer from './components/consignee-container';
import CustomerForm from './components/customer-form';
import ExporterContainer from './components/exporter-container';
import css from './index.module.scss';

interface IPartiesProps {
  data: PerformaInvoiceStates;
  handlePartiesSubmit: () => void;
  handleCancelClick: () => void;
}

const PartiesForm = (props: IPartiesProps) => {
  const { data, handlePartiesSubmit, handleCancelClick } = props;
  const { entityList, customerInfo } = data;

  return (
    <form noValidate className={css.purchaseOrderFormWrapper}>
      <BoxContainer title="Parties">
        <div className={css.partiesWrapper}>
          <ExporterContainer exporterData={entityList} />
          <CustomerForm customerData={customerInfo} />
          <ConsigneeContainer consigneeData={data} />
          <div className={css.formActionWrapper}>
            <Button variant="text" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button onClick={handlePartiesSubmit}>Save and Proceed</Button>
          </div>
        </div>
      </BoxContainer>
    </form>
  );
};

export default PartiesForm;
