import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { AppContext } from '@helpers/hooks/AppContext';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PIDetails from './components/PI-details';
import css from './index.module.scss';
import { countries, getLabelFromValue } from '@helpers/constants';
import moment from 'moment';
import { getFormattedDate } from '@helpers/utils';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

interface TaskCardProps {
  taskData: Array<Partial<ITaskTabProps>>;
}

const ReviewApprovePICard = (props: TaskCardProps) => {
  const { appDispatch } = useContext(AppContext);
  const params = useParams();
  const { taskData } = props;
  const taskItem = taskData[0];
  const dateString = getFormattedDate(taskItem.date_of_order);
  const navigate = useNavigate();

  const handleReviewClick = () => {
    appDispatch({
      type: 'setPIData',
      payload: { task_id: taskItem?.task_id, order_id: taskItem?.order }
    });
    navigate(`/order/proforma-invoice-review/${taskItem?.order ?? (params.id as string)}`);
  };

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskItem?.is_completed ? (
                <Avatar src={Images.illustrationEdit} alt="" />
              ) : (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              )}

              <Link to={`/order/${taskItem?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  #{taskItem?.readable_task_id} - Review Proforma Invoice
                </Typography>
              </Link>
            </div>

            <div className={css.pocWrapper}>
              {!taskItem?.is_completed && (
                <div className={css.beforeApproval}>
                  <Button
                    variant="outlined"
                    className={css.approveButton}
                    onClick={handleReviewClick}>
                    Review and Approve
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordionContent}>
          <div className={css.orderCardWrapper}>
            <div className={css.orderCardFirstWrapper}>
              <PIDetails
                primaryTitle="Customer"
                secondaryTitle={taskItem?.customer_name as string}
                showPrimaryIcon={false}
                showInfoIcon={false}
              />
              <PIDetails
                primaryTitle="Product"
                secondaryTitle={taskItem?.product_names?.[0] as string}
                showPrimaryIcon={false}
                showInfoIcon={false}
                style={{ borderRight: '1px solid var(--color-grey-1)' }}
              />
              <PIDetails
                primaryTitle="Date Of Order"
                secondaryTitle={dateString as string}
                showPrimaryIcon={false}
                showInfoIcon={false}
                style={{ paddingLeft: '24px' }}
              />
              <PIDetails
                primaryTitle="Country"
                secondaryTitle={getLabelFromValue(taskItem?.country as string, countries)}
                showPrimaryIcon={false}
                showInfoIcon={false}
              />
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskItem?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskItem?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskItem?.updated_at)?.diff(taskItem.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};

export default ReviewApprovePICard;
