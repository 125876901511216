import { Button } from '@components/base';
import css from './index.module.scss';
import ShipperContainer from './components/shipper-container';
import ConsigneeContainer from './components/consignee-container';
import OrderForm from './components/order-form';
import { ITaskItemProps } from '@helpers/types/task-tabs';

interface IOrderDetailsProps {
  data: ITaskItemProps;
  handleOrderDetailSubmit: () => void;
  handleCancelClick: () => void;
}

const OrderDetails = (props: IOrderDetailsProps) => {
  const { data, handleOrderDetailSubmit, handleCancelClick } = props;
  return (
    <div className={css.orderDetailsWrapper}>
      <ShipperContainer data={data} />
      <ConsigneeContainer data={data} />
      <OrderForm data={data} />
      <div className={css.formActionWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button type="submit" onClick={handleOrderDetailSubmit}>
          Save and Proceed
        </Button>
      </div>
    </div>
  );
};

export default OrderDetails;
