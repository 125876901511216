import { getCommaSepratedValue, getFormattedDate, sanitizeValue } from '@helpers/utils';
import css from './index.module.scss';
import { Button, Chip, Divider, Typography } from '@components/base';
import { Enquiry, IRFQProduct } from '@helpers/types/enquiry';

interface ICustomerEnquiryCardProps {
  data: Enquiry;
  handleClick: (id: string) => void;
}

const CustomerEnquiryCard = (props: ICustomerEnquiryCardProps) => {
  const { data, handleClick } = props;

  return (
    <div className={css.customerEnquiryCardContainer}>
      <div className={css.customerEnquiryCardHeader}>
        <div className={css.customerEnquiryHeaderIdDate}>
          <Typography variant="body">#{data.readable_id}</Typography>
          <Divider className={css.enquiryDivider} />
          <Typography variant="label">
            {sanitizeValue(getFormattedDate(data.created_at), 'Date Not Available')}
          </Typography>
        </div>
      </div>
      <div className={css.customerEnquiryCardProductsWrapper}>
        <div className={css.customerEnquiryCardProducts}>
          <div>
            <Typography variant="label">Place Of Delivery</Typography>
            <Typography variant="body">{sanitizeValue(data.place_of_delivery)}</Typography>
          </div>
          <div>
            <Typography variant="label">Status</Typography>
            <Chip
              label={sanitizeValue(data.status_display_value)}
              variant={data.is_completed ? 'filled-green' : 'filled-blue'}
            />
          </div>
        </div>
        {data?.products.map((product: IRFQProduct, index: number) => (
          <div key={index} className={css.customerEnquiryCardProductsDetails}>
            <div className={css.customerEnquiryCardProductsDetailsName}>
              <Typography variant="label">Chemical Name</Typography>
              <Typography variant="body">{sanitizeValue(product?.name)}</Typography>
            </div>
            <div>
              <Typography variant="label">Price</Typography>
              <Typography variant="body">
                {product?.currency} {sanitizeValue(getCommaSepratedValue(product?.target_price))}
              </Typography>
            </div>
            <div>
              <Typography variant="label">Quantity</Typography>
              <Typography variant="body">
                {product?.quantity} {sanitizeValue(product?.unit)}
              </Typography>
            </div>
          </div>
        ))}
      </div>
      <div className={css.customerEnquiryViewEnquiry}>
        <Button
          className={css.customerEnquiryCardViewEnquiryButton}
          variant="outlined-secondary"
          onClick={() => handleClick(data.id)}>
          View
        </Button>
      </div>
    </div>
  );
};

export default CustomerEnquiryCard;
