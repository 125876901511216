import * as yup from 'yup';
import { MAX_ZIP_CODE_LENGTH } from '@helpers/constants';

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

const supplierAddressSchema = yup.object().shape(
  {
    address_id: yup.string().trim().optional(),
    address_line1: yup.string().trim().required('Address Line 1 is required'),
    address_line2: yup.string().trim().optional().nullable(),
    address_type: yup.string().trim().optional(),
    zip_code: yup
      .string()
      .trim()
      .when('zip_code', (val, schema) => {
        if (val?.length && val[0]) {
          return yup
            .string()
            .trim()
            .max(
              MAX_ZIP_CODE_LENGTH,
              `Zip Code can be at most ${MAX_ZIP_CODE_LENGTH} characters long`
            );
        } else {
          return yup.string().optional();
        }
      }),
    country: selectSchema.required('Country is required'),
    // state: selectSchema.optional().nullable(),
    city: selectSchema.required('City is required'),
    address_suffix: yup.string().optional().nullable()
  },
  [['zip_code', 'zip_code']]
);

const supplierDetailSchema = yup.object().shape(
  {
    supplier_id: yup.string().trim().optional(),
    supplier_name: yup.string().trim().required('Supplier name is required'),
    vendor_id: yup.string().trim(),
    supplier_email: yup
      .string()
      .trim()
      .email('Enter valid e-mail address')
      .required('Email is required'),
    supplier_website: yup
      .string()
      .trim()
      .when('supplier_website', (val, schema) => {
        if (val?.length && val[0]) {
          return yup
            .string()
            .trim()
            .matches(
              /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
              'Enter valid URL'
            );
        } else {
          return yup.string().optional();
        }
      }),
    gst_number: yup
      .string()
      .trim()
      .when('supplier_type', (val, schema) => {
        if (val?.length && val[0].value !== 'FOREIGN') {
          return yup
            .string()
            .trim()
            .required('GST Number is required')
            .matches(
              /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
              'Enter valid GST number'
            );
        } else {
          return yup.string().optional();
        }
      }),
    supplier_phone_number: yup
      .string()
      .trim()
      .min(8, 'Phone Number must be at least 8 digits long')
      .max(20, 'Phone Number can be at most 20 digits long')
      .required('Phone Number is required'),
    supplier_landline: yup
      .string()
      .trim()
      .when('supplier_landline', (val, schema) => {
        if (val?.length && val[0]) {
          return yup
            .string()
            .trim()
            .min(4, 'Landline Number must be at least 4 digits long')
            .max(20, 'Landline Number can be at most 20 digits long');
        } else {
          return yup.string().optional();
        }
      }),
    supplier_type: selectSchema.required('Supplier type is required'),
    business_type: selectSchema.required('Business type is required'),
    type: selectSchema.required('Type is required'),
    same_as_office: yup.bool(),
    addresses: yup.array().of(supplierAddressSchema).required()
  },
  [
    ['supplier_website', 'supplier_website'],
    ['supplier_landline', 'supplier_landline']
  ]
);

const addSupplierSchema = supplierDetailSchema.concat(supplierAddressSchema);

const basicBankDetailSchema = yup.object().shape({
  bank_id: yup.string().trim().optional(),
  bank_name: yup
    .string()
    .trim()
    .max(128, 'Bank name can be at most 128 characters long')
    .required('Bank Name is required'),
  branch_name: yup.string().trim().required('Branch Name is required'),
  bank_account_holder_name: yup.string().trim().required('Accountholder Name is required'),
  account_number: yup
    .string()
    .trim()
    .max(64, 'Account Number can be at most 64 characters long')
    .required('Account Number is required'),
  ifsc_code: yup
    .string()
    .trim()
    .max(11, 'IFSC Code can be at most 11 characters long')
    .required('IFSC Code is required'),
  swift_code_required: yup.boolean(),
  swift_code: yup
    .string()
    .trim()
    .max(16, 'Swift Code can be at most 16 characters long')
    .when('swift_code_required', ([swift_code_required], schema) => {
      return !swift_code_required
        ? schema.notRequired()
        : schema.required('Swift Code is required');
    }),
  msme_no: yup.string().trim().optional()
});

const bankDetailSchema = basicBankDetailSchema.concat(supplierAddressSchema);

const addBankDetailSchema = yup.object().shape({
  bankdetails: yup.array().of(bankDetailSchema)
});

export { addSupplierSchema, addBankDetailSchema };
