import httpClient from './index.service';
import { SERVER_CONFIG } from '@helpers/constants';

export async function fetchAmountInWords(params?: any) {
  const paramList: any = [];
  if (params) {
    Object.entries(params).forEach((param: any) => {
      const [key, value] = param;
      if (value) {
        paramList.push(`${key}=${value}`);
      }
    });
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.amountInWords}?${paramList?.length ? paramList.join('&') : ``}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchCountryList(params?: any) {
  const paramList: any = [];
  if (params) {
    Object.entries(params).forEach((param: any) => {
      const [key, value] = param;
      if (value) {
        paramList.push(`${key}=${value}`);
      }
    });
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.fetchCountryList}?${paramList?.length ? paramList.join('&') : ``}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchStateList(params: any) {
  const paramList: any = [];
  if (params) {
    Object.entries(params).forEach((param: any) => {
      const [key, value] = param;
      if (value) {
        paramList.push(`${key}=${value}`);
      }
    });
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.fetchStateList}?${paramList?.length ? paramList.join('&') : ``}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchCityList(params: any) {
  const paramList: any = [];
  if (params) {
    Object.entries(params).forEach((param: any) => {
      const [key, value] = param;
      if (value) {
        paramList.push(`${key}=${value}`);
      }
    });
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.fetchCityList}?${paramList?.length ? paramList.join('&') : ``}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchPortList(params?: any) {
  const paramList: any = [];
  if (params) {
    Object.entries(params).forEach((param: any) => {
      const [key, value] = param;
      if (value) {
        paramList.push(`${key}=${value}`);
      }
    });
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.fetchPortList}?${paramList?.length ? paramList.join('&') : ``}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getDocumentLink(slug: string) {
  const body = {
    key: slug
  };
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.getDocumentLink}`, body);
    return response;
  } catch (error: any) {
    return error;
  }
}
