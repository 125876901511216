/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Loader, Typography } from '@components/base';
import { SideBar } from '@components/common';
import { CUSTOM_ITEMS, booleanSelectFields } from '@helpers/constants';
import { AppContext } from '@helpers/hooks/AppContext';
import notify from '@helpers/toastify-helper';
import {
  IItemDetail,
  IItemDetailsInfo,
  IPurchaseOrderDetailsProps,
  IRemarkCheckbox,
  IRemarksProps,
  PerformaInvoiceStates
} from '@helpers/types/pi';
import { isValidDate } from '@helpers/utils';
import { customItemsArraySchema } from '@helpers/yup/add-order.schema';
import { purchaseOrderDetailsScheme } from '@helpers/yup/purchase-order-details.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { CLIENT_ROUTES } from '@router/routes';
import {
  fetchOrderInfo,
  fetchReviewById,
  getPaymentTermSuffixFilterOptions,
  postPerformaInvoice,
  rectifyPerformaInvoice
} from '@services/order.service';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ItemDetailForm from './components/item-detail-form';
import OrderBankDetailsTab from './components/order-bank-details-tab';
import PartiesForm from './components/parties-tab';
import PIReview from './components/preview-tab';
import PurchaseOrderDetailsTab from './components/purchase-order-detail';
import RemarkTab from './components/remarks-tab';
import { remarkFunction } from './components/remarks-tab/remarks';
import css from './index.module.scss';
import { useScrollToTop } from '@helpers/hooks';
import { orderBankDetailsSchema } from '@helpers/yup/order-bank-details-schema';
const moment = require('moment');
const writtenNumber = require('written-number');

const GenerateProformaInvoice = () => {
  const location = useLocation();
  const isRectify = location.pathname.split('/')[2] === 'rectify-performa-invoice';
  const { appState } = useContext(AppContext);
  const { PIData, RectifyData } = appState;
  const params = useParams();
  const navigate = useNavigate();
  const [paymentTermSuffixList, setPaymentTermSuffixList] = useState([]);

  const [remarkCheckboxTabs, setRemarkCheckboxTabs] = useState<IRemarkCheckbox>({
    payment_transfers: false,
    pss_confirmation: false,
    free_days_at_port: false,
    neutral_packing: false,
    consignment_elchemy: false,
    percent_advance_payment: false,
    deviation_quality_days: false,
    bl_switchable_port: false,
    payment_due_date: false,
    inspection_cost: false,
    late_fee: false,
    other_remarks: false
  });

  const [orderState, setOrderState] = useState<PerformaInvoiceStates>({
    isLoading: false,
    activeStep: 0,
    itemDetailsInfo: null,
    purchaseOrder: null,
    order_total_amount: '',
    remarksInfo: {
      string: '',
      data: {},
      checkboxStates: {}
    },
    rectify_remarks: '',
    remarks: '',
    orderInfo: {
      invoice_number: '',
      order_type: '',
      order_number: ''
    },
    entityList: {
      name: '',
      gst_number: '',
      address: ''
    },
    customerInfo: {
      name: '',
      country: null,
      address: '',
      customer_poc: {}
    },
    consigneeInfo: {
      isSameAsCustomer: false,
      consignee_name: '',
      consignee_phone_number: '',
      consignee_email_id: '',
      consignee_office_address: ''
    },
    itemInfo: {
      items: [],
      totalAmount: '',
      supplier: [],
      currency: '',
      unit_of_weight: '',
      order_item_description_pi: ''
    },
    orderDetails: {
      transportation_mode: '',
      inco_terms: '',
      country_of_origin: '',
      country_of_final_destination: '',
      port_of_loading: '',
      port_of_loading_display_value: '',
      port_of_loading_country_display_value: '',
      port_of_discharge: '',
      port_of_discharge_display_value: '',
      port_of_discharge_country_display_value: '',
      place_of_delivery: '',
      payment_terms: '',
      is_qc_test: false,
      delivery_term: '',
      place_of_quality_test: '',
      payment_term_suffix: null
    },
    bankDetails: {
      bank_name: '',
      branch_name: '',
      bank_account_holder_name: '',
      account_number: '',
      ifsc_code: '',
      swift_code: '',
      address: ''
    }
  });

  const [postTotalAmount, setPostTotalAmount] = useState('');

  const {
    isLoading,
    order_total_amount,
    activeStep,
    orderDetails,
    itemInfo,
    customerInfo,
    purchaseOrder,
    itemDetailsInfo,
    remarksInfo,
    remarks
  } = orderState;
  const { items, currency, unit_of_weight } = itemInfo;

  const handleSidebarClick = (value: number) => {
    handleNavigation(value);
  };

  const handleSidebarEnterKey = (value: number) => {
    handleNavigation(value);
  };

  const itemsCount = items.reduce(
    (prevValue: number, item: any) => Number(item?.total_amount) + prevValue,
    0
  );

  const totalAmount = itemDetailsInfo?.custom_items?.reduce(
    (previousValue: number, item: IItemDetail) => Number(item.custom_item_amount) + previousValue,
    0
  );

  const topRef = useRef(null);

  const itemDetailForm = useForm<IItemDetailsInfo>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(customItemsArraySchema),
    defaultValues: {
      custom_items: CUSTOM_ITEMS,
      order_items: [
        {
          rate_pi: '',
          quantity_pi: '',
          order_item_id: '',
          country_of_origin: null,
          order_item_description_pi: '',
          include_grade_name_in_docs: false,
          is_pre_shipment_sample_required: false
        }
      ]
    }
  });
  const { setValue: setItemDetailValue } = itemDetailForm;

  const orderDetailForm = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(orderBankDetailsSchema),
    defaultValues: {
      payment_term_suffix: null
    }
  });

  const remarksForm = useForm<IRemarksProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // resolver: yupResolver(remarksSchema),
    defaultValues: {
      field_free_days_port: '',
      field_percentage_payment: '',
      field_days_deviation: '',
      field_bl_port: '',
      field_payment_due: null,
      field_inspection_cost: '',
      Remarks: []
    }
  });

  const { setValue: setRemarkFormValue } = remarksForm;

  const purchaseOrderForm = useForm<IPurchaseOrderDetailsProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(purchaseOrderDetailsScheme),
    defaultValues: {
      po_reference_number: '',
      document_date: null,
      notify_party: ''
    }
  });
  const { setValue: setPurchaseOrderValue } = purchaseOrderForm;

  const paymentTermSuffixOptions = async () => {
    const response = await getPaymentTermSuffixFilterOptions();
    if (response.success) {
      setPaymentTermSuffixList(response.data.results);
    } else {
      notify({
        message: response.error ?? 'Unable to fetch data',
        severity: 'error',
        dismissible: true
      });
    }
  };

  useEffect(() => {
    if (items && items.length > 0) {
      const updatedOrderItems = items.map((item: any) => ({
        order_item_id: item?.order_item_id,
        quantity_pi: item?.quantity,
        rate_pi: item?.selling_price,
        order_item_description_pi: item?.order_item_description_pi?.length
          ? item?.order_item_description_pi
          : item?.order_item_description,
        include_grade_name_in_docs: item?.include_grade_name_in_docs
          ? item?.include_grade_name_in_docs
          : booleanSelectFields[0],
        is_pre_shipment_sample_required: item?.is_pre_shipment_sample_required
      }));
      setItemDetailValue('order_items', updatedOrderItems);
    }
    setPostTotalAmount(String(itemsCount + (totalAmount as number)));
  }, [items]);

  useEffect(() => {
    if (isRectify) {
      (async () => {
        setOrderState((prevState: any) => ({ ...prevState, isLoading: !prevState.isLoading }));
        const response = await fetchReviewById(params?.id as string);
        if (response.success) {
          const { data: orderinfo } = response;
          if (orderinfo?.order?.order_item) {
            orderinfo.order.order_item = orderinfo?.order?.order_item.map((item: any) => {
              item.quantity_mt = item.quantity_pi ? item.quantity_pi : item.quantity_mt;
              item.rate_mt_usd = item?.selling_price;
              item.total_amount = item.total_amount_pi ? item.total_amount_pi : item.total_amount;
              item.include_grade_name_in_docs = booleanSelectFields.find(
                (opt) => opt.value === item.include_grade_name_in_docs
              );
              return item;
            });
          }
          setOrderState((prevState: any) => ({
            ...prevState,
            isLoading: !prevState.isLoading,
            order_total_amount: orderinfo?.order?.total,
            orderInfo: {
              invoice_number: orderinfo?.document_number,
              order_type: orderinfo?.order?.supply_type,
              order_number: orderinfo?.order?.readable_order_id
            },
            entityList: {
              name: orderinfo?.order?.elchemy_entity?.name,
              gst_number: orderinfo?.order?.elchemy_entity?.gst_number,
              address: orderinfo?.order?.elchemy_entity?.address
            },
            customerInfo: {
              name: orderinfo?.order?.customer?.name,
              country: orderinfo?.order?.customer_address?.country_obj,
              address: `${orderinfo?.order?.customer_address?.address_line1 ?? ''} ${
                orderinfo?.order?.customer_address?.address_line2 ?? ''
              } ${orderinfo?.order?.customer_address?.city ?? ''} ${
                orderinfo?.order?.customer_address?.state ?? ''
              } ${orderinfo?.order?.customer_address?.country ?? ''}`,
              customer_poc: orderinfo?.order?.customer_poc
            },
            consigneeInfo: {
              isSameAsCustomer: orderinfo?.order?.consignee_same_as_customer,
              consignee_name: orderinfo?.order?.consignee_name,
              consignee_phone_number: orderinfo?.order?.consignee_phone_number,
              consignee_email_id: orderinfo?.order?.consignee_email_id,
              consignee_office_address: orderinfo?.order?.consignee_office_address,
              address: `${orderinfo?.order?.customer_address?.address_line1 ?? ''} ${
                orderinfo?.order?.customer_address?.address_line2 ?? ''
              } ${orderinfo?.order?.customer_address?.city ?? ''} ${
                orderinfo?.order?.customer_address?.state ?? ''
              } ${orderinfo?.order?.customer_address?.country ?? ''}`,
              customer_poc: orderinfo?.order?.customer_poc
            },
            itemInfo: {
              items: orderinfo?.order?.order_item,
              totalAmount: orderinfo?.total_amount,
              supplier: orderinfo?.suppliers,
              totalAmountInWords: orderinfo?.total_amount_of_all_items_in_words,
              currency: orderinfo?.currency,
              unit_of_weight: orderinfo?.unit_of_weight
            },
            bankDetails: {
              bank_name: orderinfo?.order?.elchemy_bank?.bank_name,
              branch_name: orderinfo?.order?.elchemy_bank?.branch_name,
              bank_account_holder_name: orderinfo?.order?.elchemy_bank?.bank_account_holder_name,
              account_number: orderinfo?.order?.elchemy_bank?.account_number,
              ifsc_code: orderinfo?.order?.elchemy_bank?.ifsc_code,
              swift_code: orderinfo?.order?.elchemy_bank?.swift_code,
              address: `${orderinfo?.order?.elchemy_bank?.address_line1 ?? ''} ${
                orderinfo?.elchemy_bank?.address_line2 ?? ''
              } ${orderinfo?.order?.elchemy_bank?.city ?? ''} ${
                orderinfo?.order?.elchemy_bank?.state ?? ''
              }`
            },
            orderDetails: {
              transportation_mode: orderinfo?.order?.transportation_mode,
              inco_terms: orderinfo?.order?.inco_terms,
              country_of_origin: orderinfo?.order?.country_of_origin,
              country_of_final_destination: orderinfo?.order?.country_of_final_destination,
              port_of_loading: orderinfo?.order?.port_of_loading,
              port_of_loading_display_value: orderinfo?.order?.port_of_loading_display_value,
              port_of_loading_country_display_value:
                orderinfo?.order?.port_of_loading_country_display_value,
              port_of_discharge_display_value: orderinfo?.order?.port_of_discharge_display_value,
              port_of_discharge_country_display_value:
                orderinfo?.order?.port_of_discharge_country_display_value,
              port_of_discharge: orderinfo?.order?.port_of_discharge,
              place_of_delivery: orderinfo?.order?.place_of_delivery,
              payment_terms: orderinfo?.payment_term_display_value_without_suffix,
              payment_term_suffix: orderinfo?.pi_payment_term_suffix_label
                ? {
                    value: orderinfo?.pi_payment_term_suffix_value,
                    name: orderinfo?.pi_payment_term_suffix_label
                  }
                : null,
              is_qc_test: orderinfo?.order?.is_qc_test,
              place_of_quality_test: orderinfo?.order?.place_of_quality_test,
              delivery_term: orderinfo?.order?.inco_terms
            },
            itemDetailsInfo: {
              custom_items:
                orderinfo?.custom_items?.map((item: any) => {
                  return {
                    custom_item_name: item?.name ?? '',
                    custom_item_description: item?.value ?? '',
                    custom_item_rate: item?.rate ?? '',
                    custom_item_quantity: item?.quantity ?? '',
                    custom_item_amount: item?.amount ?? ''
                  };
                }) ?? []
            },
            purchaseOrder: {
              document_date: orderinfo?.po_reference_date ?? null,
              po_reference_number: orderinfo?.po_reference_no,
              notify_party: orderinfo?.notify_party
            },
            rectify_remarks: orderinfo?.rectify_remarks,
            activeStep: 5,
            remarksInfo: {
              string: JSON.parse(orderinfo?.remarks)?.string,
              data: JSON.parse(orderinfo?.remarks)?.data
            }
          }));
          setRemarkCheckboxTabs(() => ({
            ...JSON.parse(orderinfo?.remarks)?.checkboxStates
          }));
          itemDetailForm.reset({
            custom_items: orderinfo?.custom_items?.map((item: any) => {
              return {
                custom_item_name: item?.name ?? '',
                custom_item_description: item?.description ?? '',
                custom_item_rate: item?.rate ?? '',
                custom_item_quantity: item?.quantity ?? '',
                custom_item_amount: item?.amount ?? ''
              };
            }),
            order_items: orderinfo?.order?.order_item?.map((item: any) => {
              return {
                ...item,
                rate_pi: item.selling_price
              };
            })
          });
          purchaseOrderForm.reset({
            document_date: orderinfo?.po_reference_date ? new Date(orderinfo?.po_reference_date) : null,
            po_reference_number: orderinfo?.po_reference_no,
            notify_party: orderinfo?.notify_party
          });
          orderDetailForm.reset({
            payment_term_suffix: orderinfo?.pi_payment_term_suffix_value
              ? {
                  value: orderinfo?.pi_payment_term_suffix_value,
                  name: orderinfo?.pi_payment_term_suffix_label
                }
              : null
          });
        } else {
          setOrderState((prevState: any) => ({ ...prevState, isLoading: !prevState.isLoading }));
          notify({
            message: response.error ?? 'Unable to fetch data',
            severity: 'error',
            dismissible: true
          });
        }
      })();
    } else {
      (async () => {
        setOrderState((prevState: any) => ({ ...prevState, isLoading: !prevState.isLoading }));
        const response = await fetchOrderInfo((params.id as string) ?? (PIData.order_id as string));
        if (response.success) {
          const { data: orderinfo } = response;
          setOrderState((prevState: any) => ({
            ...prevState,
            isLoading: !prevState.isLoading,
            order_total_amount: orderinfo?.total,
            orderInfo: {
              invoice_number: '',
              order_type: orderinfo?.supply_type,
              order_number: orderinfo?.readable_order_id
            },
            entityList: {
              name: orderinfo?.elchemy_entity?.name,
              gst_number: orderinfo?.elchemy_entity?.gst_number,
              address: orderinfo?.elchemy_entity?.address
            },
            customerInfo: {
              name: orderinfo?.customer?.name,
              country: orderinfo?.customer_address?.country_obj,
              address: `${orderinfo?.customer_address?.address_line1 ?? ''} ${
                orderinfo?.customer_address?.address_line2 ?? ''
              } ${orderinfo?.customer_address?.city ?? ''} ${
                orderinfo?.customer_address?.state ?? ''
              } ${orderinfo?.customer_address?.country ?? ''}`,
              customer_poc: orderinfo?.customer_poc
            },
            consigneeInfo: {
              isSameAsCustomer: orderinfo?.consignee_same_as_customer,
              consignee_name: orderinfo?.consignee_name,
              consignee_phone_number: orderinfo?.consignee_phone_number,
              consignee_email_id: orderinfo?.consignee_email_id,
              consignee_office_address: orderinfo?.consignee_office_address,
              address: `${orderinfo?.customer_address?.address_line1 ?? ''} ${
                orderinfo?.customer_address?.address_line2 ?? ''
              } ${orderinfo?.customer_address?.city ?? ''} ${
                orderinfo?.customer_address?.state ?? ''
              } ${orderinfo?.customer_address?.country ?? ''}`,
              customer_poc: orderinfo?.customer_poc
            },
            itemInfo: {
              items: orderinfo?.order_item,
              totalAmount: orderinfo?.total,
              supplier: orderinfo?.suppliers,
              currency: orderinfo?.currency,
              unit_of_weight: orderinfo?.unit_of_weight,
              order_item_description_pi:
                orderinfo?.order_item_description_pi ?? orderinfo?.order_item_description
            },
            bankDetails: {
              bank_name: orderinfo?.elchemy_bank?.bank_name,
              branch_name: orderinfo?.elchemy_bank?.branch_name,
              bank_account_holder_name: orderinfo?.elchemy_bank?.bank_account_holder_name,
              account_number: orderinfo?.elchemy_bank?.account_number,
              ifsc_code: orderinfo?.elchemy_bank?.ifsc_code,
              swift_code: orderinfo?.elchemy_bank?.swift_code,
              address: orderinfo?.elchemy_bank?.readable_address
            },
            orderDetails: {
              transportation_mode: orderinfo?.transportation_mode,
              inco_terms: orderinfo?.inco_terms,
              country_of_origin: orderinfo?.country_of_origin,
              country_of_final_destination: orderinfo?.country_of_final_destination,
              port_of_loading: orderinfo?.port_of_loading,
              port_of_loading_display_value: orderinfo?.port_of_loading_display_value,
              port_of_loading_country_display_value:
                orderinfo?.port_of_loading_country_display_value,
              port_of_discharge_display_value: orderinfo?.port_of_discharge_display_value,
              port_of_discharge_country_display_value:
                orderinfo?.port_of_discharge_country_display_value,
              port_of_discharge: orderinfo?.port_of_discharge,
              place_of_delivery: orderinfo?.place_of_delivery,
              payment_terms: orderinfo?.payment_terms_display_value,
              is_qc_test: orderinfo?.is_qc_test,
              place_of_quality_test: orderinfo?.place_of_quality_test,
              delivery_term: orderinfo?.inco_terms
            },
            itemDetailsInfo: {
              order_items: orderinfo?.order_item.map((item: any) => ({
                order_item_id: item.order_item_id,
                quantity_pi: item.quantity,
                rate_pi: item.selling_price,
                order_item_description_pi: item.order_item_description_pi?.length
                  ? item.order_item_description_pi
                  : item.order_item_description,
                include_grade_name_in_docs: item.include_grade_name_in_docs
                  ? item.include_grade_name_in_docs
                  : booleanSelectFields[0],
                is_pre_shipment_sample_required: item.is_pre_shipment_sample_required
              }))
            }
          }));
          itemDetailForm.reset({
            custom_items: orderinfo?.custom_items?.map((item: any) => {
              return {
                custom_item_name: item?.name ?? '',
                custom_item_description: item?.description ?? '',
                custom_item_rate: item?.rate ?? '',
                custom_item_quantity: item?.quantity ?? '',
                custom_item_amount: item?.amount ?? ''
              };
            }),

            order_items: orderinfo?.order_item
          });
          purchaseOrderForm.reset({
            document_date: orderinfo?.po_reference_date ?  new Date(orderinfo?.po_reference_date) : null,
            po_reference_number: orderinfo?.po_reference_no,
            notify_party: orderinfo?.notify_party
          });
        } else {
          setOrderState((prevState: any) => ({ ...prevState, isLoading: !prevState.isLoading }));
          notify({
            message: response.error ?? 'Unable to fetch data',
            severity: 'error',
            dismissible: true
          });
        }
      })();
    }
    paymentTermSuffixOptions();
  }, []);

  useEffect(() => {
    setItemDetailValue('custom_items', itemDetailsInfo?.custom_items || CUSTOM_ITEMS);
  }, [itemDetailsInfo?.custom_items]);

  useEffect(() => {
    setPurchaseOrderValue('po_reference_number', purchaseOrder?.po_reference_number || '');
    setPurchaseOrderValue('document_date', purchaseOrder?.document_date ?  new Date(purchaseOrder?.document_date as string) : null);
    setPurchaseOrderValue('notify_party', purchaseOrder?.notify_party || '');
  }, [purchaseOrder]);

  useEffect(() => {
    const { data } = remarksInfo;
    setRemarkFormValue('field_bl_port', (data?.field_bl_port as string) || '');
    setRemarkFormValue('field_days_deviation', (data?.field_days_deviation as string) || '');
    setRemarkFormValue('field_free_days_port', (data?.field_free_days_port as string) || '');
    setRemarkFormValue('field_inspection_cost', (data?.field_inspection_cost as string) || '');
    setRemarkFormValue(
      'field_payment_due',
      isValidDate(data?.field_payment_due as string)
        ? new Date(data?.field_payment_due as string)
        : new Date('')
    );
    setRemarkFormValue(
      'field_percentage_payment',
      (data?.field_percentage_payment as string) || ''
    );
    setRemarkFormValue('Remarks', data?.Remarks);
  }, [remarksInfo]);

  const handlePurchaseOrderSubmit: SubmitHandler<IPurchaseOrderDetailsProps> = (data) => {
    const document_date = data.document_date ?? null;
    if(document_date){
      const newDate = `${moment(document_date).format('YYYY-MM-DD')}`;
      const newData = { ...data, document_date: newDate };
      setOrderState((prevState: any) => ({ ...prevState, purchaseOrder: newData, activeStep: 4 }));

    } else {
      const newData = { ...data, document_date: null };
      setOrderState((prevState: any) => ({ ...prevState, purchaseOrder: newData, activeStep: 4 }));
    }
   
  };

  const handleItemDetailFormSubmit: SubmitHandler<IItemDetailsInfo> = (data) => {
    const itemCopy = orderState.itemInfo.items;
    itemCopy.forEach((item: any) => {
      data?.order_items?.forEach((updatedItem: any) => {
        if (updatedItem.order_item_id === item.order_item_id) {
          if (updatedItem.quantity_pi) {
            item.quantity_mt = updatedItem.quantity_pi;
            updatedItem.quantity_mt = updatedItem.quantity_pi;
          }
          if (updatedItem.rate_pi) {
            item.rate_mt_usd = updatedItem.rate_pi;
            updatedItem.rate_mt_usd = updatedItem.rate_pi;
          }

          item.total_amount = Number(item.quantity_mt) * Number(item.rate_mt_usd);
          item.order_item_description_pi = updatedItem.order_item_description_pi;
          updatedItem.include_grade_name_in_docs = updatedItem.include_grade_name_in_docs?.value;
          item.is_pre_shipment_sample_required = updatedItem.is_pre_shipment_sample_required;
        }
      });
    });
    const customItemTotalAmount = Number(
      data?.custom_items?.reduce((total: any, item: any) => total + item.custom_item_amount, 0)
    ).toFixed(2);
    const orderItemTotalAmount = Number(
      itemCopy.reduce((total: any, item: any) => total + item.total_amount, 0)
    ).toFixed(2);

    const totalOrderAmonunt = Number(order_total_amount).toFixed(2);

    if (
      parseFloat(customItemTotalAmount) + parseFloat(orderItemTotalAmount) !=
      parseFloat(totalOrderAmonunt)
    ) {
      notify({
        message: `Total amount of custom items and order items should be equal to ${totalOrderAmonunt}`,
        severity: 'error',
        dismissible: true
      });
      return;
    }
    //This is used to prefill PSS remark checkbox
    const isPssCheckBoxMarked = data?.order_items?.some(
      (item: any) => item.is_pre_shipment_sample_required
    );

    setRemarkCheckboxTabs((prevState: any) => ({
      ...prevState,
      pss_confirmation: isPssCheckBoxMarked
    }));

    setOrderState((prevState: any) => ({
      ...prevState,
      itemDetailsInfo: data,
      activeStep: 2
    }));
  };

  const handleRemarkSubmit: SubmitHandler<Partial<IRemarksProps>> = (data) => {
    const due_date = data.field_payment_due;
    const changedDate = `${moment(due_date).format('YYYY-MM-DD')}`;
    const newData = { ...data, field_payment_due: changedDate };
    const remarkFieldData = { ...newData };
    const fnRemarkData = { ...newData, ...remarkCheckboxTabs, ...customerInfo };
    const remarkData = remarkFunction(fnRemarkData);
    setOrderState((prevState: any) => ({
      ...prevState,
      remarksInfo: {
        string: remarkData as string,
        data: remarkFieldData,
        checkboxStates: remarkCheckboxTabs
      },
      activeStep: 5
    }));
  };

  const handleCancelClick = () => {
    navigate(`/${CLIENT_ROUTES.order}/${params?.id as string}`);
  };

  const handleFormSubmit = async () => {
    const current_date = new Date();
    const document_date = `${moment(current_date).format('YYYY-MM-DD')}`;
    itemDetailsInfo?.order_items?.forEach((item: any) => {
      item['country_of_origin'] = null;
    });
    const postFormData = {
      document_date: document_date,
      total_amount: postTotalAmount,
      total_amount_of_all_items_in_words: writtenNumber(postTotalAmount),
      po_reference_no: purchaseOrder?.po_reference_number,
      po_reference_date: purchaseOrder?.document_date,
      notify_party: purchaseOrder?.notify_party,
      custom_items: itemDetailsInfo?.custom_items?.map((item: any) => {
        return {
          custom_item_id: item.custom_item_id,
          amount: item.custom_item_amount,
          quantity: item.custom_item_quantity,
          description: item.custom_item_description,
          rate: item.custom_item_rate,
          name: item.custom_item_name
        };
      }),
      order_items: itemDetailsInfo?.order_items?.map((item: any) => {
        const updatedItem = {
          ...item,
          selling_price: item?.rate_pi
        };

        delete updatedItem?.rate_mt_usd;
        delete updatedItem?.rate_pi;
        return updatedItem;
      }),
      remarks: JSON.stringify(remarksInfo),
      pi_payment_term_suffix: orderDetails?.payment_term_suffix
        ? { id: (orderDetails.payment_term_suffix as any).value }
        : null
    };
    setOrderState((prevState: any) => ({ ...prevState, isLoading: !prevState.isLoading }));
    const response: any = isRectify
      ? await rectifyPerformaInvoice(RectifyData?.task_id, postFormData)
      : await postPerformaInvoice(PIData?.task_id, postFormData);
    if (response.success) {
      setOrderState((prevState: any) => ({ ...prevState, isLoading: !prevState.isLoading }));
      notify({ message: response?.data?.message, severity: 'success', dismissible: true });
      navigate(`/${CLIENT_ROUTES.order}/${params?.id as string}`);
    } else {
      setOrderState((prevState: any) => ({ ...prevState, isLoading: !prevState.isLoading }));
      notify({
        message: response.error ?? 'Something went wrong!',
        severity: 'error',
        dismissible: true
      });
    }
  };

  const handleNavigation = (currentStep: number) => {
    setOrderState((prevState: any) => ({
      ...prevState,
      activeStep: currentStep
    }));
  };

  const handleOrderBankSubmit = (data: any) => {
    setOrderState((prevState: any) => ({
      ...prevState,
      orderDetails: {
        ...prevState.orderDetails,
        payment_term_suffix: data.payment_term_suffix
      }
    }));
    handleNavigation(3);
  };

  useScrollToTop({ topRef, dependencyArray: [activeStep] });

  return (
    <main className={css.purchaseOrderWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">{isRectify ? 'Rectify' : 'Generate'} Proforma Invoice</Typography>
        <Typography variant="subheading1">0{activeStep + 1} of 06</Typography>
      </div>
      <div className={css.purchaseOrderContainer} ref={topRef}>
        <div className={css.sideBarWrapper}>
          <SideBar
            activeStep={activeStep}
            onClick={handleSidebarClick}
            onEnter={handleSidebarEnterKey}>
            <SideBar.Item label="Parties" value={0} />
            <SideBar.Item label="Item Details" value={1} disabled={activeStep! <= 1} />
            <SideBar.Item
              label="Order and Entity Bank Details "
              value={2}
              disabled={activeStep! <= 2}
            />
            <SideBar.Item label="Purchase Order Details" value={3} disabled={activeStep! <= 3} />
            <SideBar.Item label="Standard Remarks" value={4} disabled={activeStep! <= 4} />
            <SideBar.Item label="Preview" value={5} disabled={activeStep! <= 5} />
          </SideBar>
        </div>
        {activeStep === 0 && (
          <PartiesForm
            data={orderState}
            handleCancelClick={handleCancelClick}
            handlePartiesSubmit={() => handleNavigation(1)}
          />
        )}
        <FormProvider {...itemDetailForm}>
          {activeStep === 1 && (
            <ItemDetailForm
              productList={itemInfo}
              onFormSubmit={handleItemDetailFormSubmit}
              onCancelClick={handleCancelClick}
              onBackClick={() => handleNavigation(0)}
              setTotalAmount={setPostTotalAmount}
              postTotalAmount={postTotalAmount}
              currency={currency}
              unitOfWeight={unit_of_weight}
            />
          )}
        </FormProvider>
        <FormProvider {...orderDetailForm}>
          {activeStep === 2 && (
            <OrderBankDetailsTab
              orderBankData={orderState}
              onFormSubmit={handleOrderBankSubmit}
              handleCancelClick={handleCancelClick}
              onBackClick={() => handleNavigation(1)}
              paymentTermSuffixList={paymentTermSuffixList}
            />
          )}
        </FormProvider>
        <FormProvider {...purchaseOrderForm}>
          {activeStep === 3 && (
            <PurchaseOrderDetailsTab
              purchaseOrderDetails={orderDetails}
              handleCancelClick={handleCancelClick}
              onFormSubmit={handlePurchaseOrderSubmit}
              onBackClick={() => handleNavigation(2)}
            />
          )}
        </FormProvider>
        <FormProvider {...remarksForm}>
          {activeStep === 4 && (
            <RemarkTab
              onBackClick={() => handleNavigation(3)}
              onFormSubmit={handleRemarkSubmit}
              remarkCheckboxTabs={remarkCheckboxTabs}
              setRemarkCheckboxTabs={setRemarkCheckboxTabs}
              fieldsData={customerInfo}
              handleCancelClick={handleCancelClick}
            />
          )}
        </FormProvider>
        {activeStep === 5 && (
          <PIReview
            previewData={orderState}
            handleFormSubmit={handleFormSubmit}
            handleCancelClick={handleCancelClick}
            handleBackClick={() => handleNavigation(4)}
            setOrderState={setOrderState}
            isRectify={isRectify}
          />
        )}
      </div>
      <Loader open={isLoading} />
    </main>
  );
};

export default GenerateProformaInvoice;
