import { Fragment, useEffect, useState } from 'react';
import css from './index.module.scss';
import { Accordion, Button, CheckboxLabel, Typography } from '@components/base';
import { SelectLabel } from '@components/common';
import { ISupplier } from '@helpers/types/supplier';
import sortBy from 'lodash.sortby';
import { countries } from '@helpers/constants';
import { getSupplierList, getSupplierTypeOptions } from '@services/supplier.service';
import { fetchAllProducts } from '@services/product.service';
import notify from '@helpers/toastify-helper';
import { ProductInfo } from '@helpers/types/product';
import { useForm, Controller, SubmitHandler, useWatch } from 'react-hook-form';
import { ISelect } from '@helpers/types';

interface FilterDrawerProps {
  onClose?: () => void;
  onApply?: (data: FormType) => void;
  value?: FormType | null;
}

interface FilterDrawerState {
  supplierList: ISupplier[];
  productList: ProductInfo[];
  supplierTypeList: ISelect[];
  supplierTypeOriginList: ISelect[];
  businessTypeList: ISelect[];
  isLoading: boolean;
}

export interface FormType {
  supplier: ISupplier[];
  product: ProductInfo[];
  country: ISelect[];
  supplier_type: ISelect[];
  supplier_type_origin: ISelect[];
  business_type: ISelect[];
}

const FilterDrawer = (props: FilterDrawerProps) => {
  const { onClose, value } = props;
  const [drawerState, setDrawerState] = useState<FilterDrawerState>({
    supplierList: [],
    productList: [],
    supplierTypeList: [],
    supplierTypeOriginList: [],
    businessTypeList: [],
    isLoading: false
  });

  const {
    supplierList,
    productList,
    isLoading,
    supplierTypeList,
    supplierTypeOriginList,
    businessTypeList
  } = drawerState;

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<FormType>({
    defaultValues: {
      supplier_type: [],
      supplier: [],
      product: [],
      country: [],
      supplier_type_origin: [],
      business_type: []
    }
  });

  const supplierTypeWatch = useWatch({ control, name: 'supplier_type' });
  useEffect(() => {
    const { value } = props;
    if (value) {
      reset(value);
    }
    fetchList();
  }, []);

  const fetchList = async () => {
    setDrawerState((prevState) => ({ ...prevState, isLoading: true }));
    const supplierResponse = await getSupplierList();
    const productResponse = await fetchAllProducts();
    const filterOptions = await getSupplierTypeOptions();
    if (supplierResponse.success && productResponse.success && filterOptions.success) {
      const { data: supplier_list } = supplierResponse;
      const { data: product_list } = productResponse;
      const { data: filter_options } = filterOptions;
      const sortedSupplierList = sortBy(supplier_list.results, (supplier: any) =>
        supplier.supplier_name.toLowerCase()
      );
      const sortedProductList = sortBy(product_list.results, (product: any) =>
        product.name.toLowerCase()
      );
      setDrawerState((prevState) => ({
        ...prevState,
        supplierList: sortedSupplierList,
        productList: sortedProductList,
        supplierTypeList: filter_options.supplier_type_filter_options,
        supplierTypeOriginList: filter_options.supplier_origin_filter_options,
        businessTypeList: filter_options.business_type_filter_options,
        isLoading: false
      }));
    } else {
      setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
      notify({ message: 'Unable to fetch list', severity: 'error' });
    }
  };

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const { onApply } = props;
    onApply?.(data);
  };

  return (
    <Fragment>
      <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h3">Filter</Typography>
          <div className={css.actionWrapper}>
            <Button type="submit" disabled={value ? !isDirty : false}>
              Apply Filters
            </Button>
            <Button variant="outlined-secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
        <div className={css.mainSection}>
          <Controller
            name="supplier"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isLoading={isLoading}
                label="Supplier"
                options={supplierList}
                getOptionLabel={(option: any) => option.supplier_name}
                getOptionValue={(option: any) => option.supplier_id}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name="product"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isLoading={isLoading}
                label="Product"
                options={productList}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.product_id}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                label="Country"
                options={countries}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name="supplier_type"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isLoading={isLoading}
                label="Supplier Type"
                options={supplierTypeList}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name="supplier_type_origin"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isLoading={isLoading}
                label="Supplier Origin"
                options={supplierTypeOriginList}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name="business_type"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isLoading={isLoading}
                label="Business Type"
                options={businessTypeList}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                rootClassName={css.selectWrapper}
              />
            )}
          />
        </div>
      </form>
    </Fragment>
  );
};

export default FilterDrawer;
