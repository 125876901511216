import React, { useState } from 'react';
import css from './index.module.scss';
import UploadEBRCConfirmation from './components';
import Footer from '../components/footer';
import Images from '@assets/images';
import { Accordion, Chip, Typography } from '@components/base';
import { Avatar } from '@components/common';
import { Link } from 'react-router-dom';

const EBRCConfirmation = (props: any) => {
  const { taskData } = props;
  const taskItem = taskData;
  const [openModal, setopenModal] = useState(false);
  const { dotGrey, alertSuccessFaint } = Images;
  const getCompleteStatus = props.taskData[0]?.is_completed;
  const [taskIsCompleted, setTaskIsCompleted] = useState<boolean | undefined>(getCompleteStatus);

  const handleStatusFromModal = (data: boolean) => {
    setTaskIsCompleted(data);
  };

  return (
    <React.Fragment>
      <div className={css.accordionContent}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordianHeaderParent}>
            <div className={css.accordionHeader}>
              <div className={css.header}>
                <div className={css.wrapper}>
                  {getCompleteStatus || taskIsCompleted ? (
                    <Avatar src={alertSuccessFaint} alt="ebrc-status" />
                  ) : (
                    <Avatar src={dotGrey} alt="ebrc-status" />
                  )}

                  <Link to={`/order/${taskItem?.order}`} style={{ textDecoration: 'none' }}>
                    <Typography variant="h5" className={css.underlineOnHover}>
                      #{taskItem?.readable_task_id} - Upload EBRC Confirmation
                    </Typography>
                  </Link>
                </div>
                <div className={css.pocWrapper}>
                  {!(getCompleteStatus || taskIsCompleted) && (
                    <Chip
                      label="Upload Details"
                      variant="outlined"
                      clickable={true}
                      className={css.uploadButton}
                      onClick={() => setopenModal(true)}
                    />
                  )}
                </div>
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        <Footer
          createdDate={taskItem?.created_at as Date}
          updatedDate={taskItem?.updated_at as Date}
        />
      </div>

      {openModal && (
        <UploadEBRCConfirmation
          open={true}
          onClose={() => setopenModal(false)}
          sendStatusToCard={handleStatusFromModal}
          taskId={taskItem?.task_id as string}
          onComplete={props.onComplete}
        />
      )}
    </React.Fragment>
  );
};

export default EBRCConfirmation;
