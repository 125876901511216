import { HTMLAttributes, memo, ReactNode, MouseEvent } from 'react';
import { ReactComponent as Icon } from '@assets/images/cross-black.svg';
import Typography from '../typography';
import PropTypes from 'prop-types';
import './index.style.scss';

interface TagsProps extends HTMLAttributes<HTMLDivElement> {
  label: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  showCloseIcon?: boolean;
  onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
  iconProps?: HTMLAttributes<HTMLButtonElement>;
  className?: string;
}

function Tags(props: TagsProps) {
  const { label, startIcon, endIcon, onClose, showCloseIcon, iconProps, className, ...otherProps } =
    props;
  return (
    <div className={`tag-root ${className}`} {...otherProps}>
      {startIcon}
      <Typography variant="span" className="tag-label">
        {label}
      </Typography>
      {endIcon}
      {showCloseIcon && (
        <button {...iconProps} className="tag-close-wrapper" onClick={onClose}>
          <Icon className="tag-close-icon" />
        </button>
      )}
    </div>
  );
}

Tags.propTypes = {
  showCloseIcon: PropTypes.bool
};

Tags.defaultProps = {
  showCloseIcon: true
};

export default memo(Tags);
