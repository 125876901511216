import { memo } from 'react';
import css from './index.module.scss';
import { Button, Typography, Image } from '@components/base';
import { LinkButton } from '@components/common';
import { To } from 'react-router-dom';
import Images from '@assets/images';
interface ListPlaceholderProps {
  title: string;
  supportingText: string;
  buttonText?: string;
  to?: To;
  image?: string;
  handleButtonClick?: () => void;
}

const ListPlaceholder = (props: ListPlaceholderProps) => {
  const { to, title, supportingText, buttonText = '', image, handleButtonClick } = props;
  return (
    <div className={css.supplierEmptyWrapper}>
      <div className={css.supplierEmptyTextWrapper}>
        {image ? (
          <Image src={image} height={400} width={400} />
        ) : (
          <Image
            src={Images.listingPlaceholder}
            alt="Placeholder"
            height={200}
            style={{ marginBottom: 10 }}
          />
        )}
        <Typography variant="span" className={css.noSupplierText}>
          {title}
        </Typography>
        <Typography variant="span" className={css.noSupplierSecondText}>
          {supportingText}
        </Typography>
      </div>
      {to && (buttonText && buttonText.length > 0) ? (
        <LinkButton to={to} variant="contained" className={css.addSupplierButton}>
          {buttonText}
        </LinkButton>
      ) : (
        <></>
      )}
      {!to && buttonText?.length > 0 && handleButtonClick && (
        <Button onClick={handleButtonClick} className={css.addSupplierButton}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default memo(ListPlaceholder);
