import { Divider, Typography } from '@components/base';
import { IOrderInfoTypes, PerformaInvoiceStates } from '@helpers/types/pi';
import css from '../index.module.scss';

interface IOrderInfo {
  orderInfoData: IOrderInfoTypes;
  setOrderState: (orderState: PerformaInvoiceStates) => void;
}

const Invoice = (props: IOrderInfo) => {
  const { orderInfoData } = props;
  const newDate = new Date();
  const renderField = (label: string, value: string) => {
    return (
      <div>
        <Typography variant="pdoc">{label}</Typography>
        <Typography variant="h4">{value}</Typography>
      </div>
    );
  };
  return (
    <div className={css.boxWrapper}>
      <div className={css.invoiceContainer}>
        <div className={css.fieldWrapper}>
          {renderField('Invoice Number', orderInfoData?.invoice_number)}
          {renderField('Invoice Date', `${newDate.toDateString()}`)}
        </div>
        <Divider className={css.invoiceDivider} />
        <div className={css.fieldWrapper}>
          {renderField('Order Type', orderInfoData?.order_type)}
          {renderField('Order Number', orderInfoData?.order_number)}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
