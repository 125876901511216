import { DayPicker, DayPickerProps } from 'react-day-picker';
import './index.style.scss';

const DatePicker = (props: DayPickerProps) => {
  return <DayPicker {...props} />;
};

DatePicker.defaultProps = {
  mode: 'single',
  showOutsideDays: true,
  fixedWeeks: true
};

export default DatePicker;
