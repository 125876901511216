import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import css from './index.module.scss';
import { BreadCrumb, Skeleton, Tab, Tabs } from '@components/base';
import { Seo } from '@components/common';
import { ProductInfo } from '@helpers/types/product';
import { CLIENT_ROUTES } from '@router/routes';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ProductDetailCard from './components/product-detail-card';
import DetailsTab from './components/details-tab';
import DocumentsTab from './components/documents-tab';
import { fetchProductInfo } from '@services/product.service';
import SupplierTab from './components/supplier-tab';
import AccessWrapper from '@authorization/access-wrapper';
import { MODULE_NAMES } from '@helpers/constants';
import { useScrollToTop } from '@helpers/hooks';

interface ProductIndividualStates {
  tabState: number;
  productInfo: ProductInfo | null;
  isLoading: boolean;
  editable: boolean;
}

const PRODUCT_MAPPING = [
  {
    value: 0,
    key: 'details',
    default: true
  },
  {
    value: 1,
    key: 'documents'
  },
  {
    value: 2,
    key: 'supplier'
  }
];

const getDefaultTab = () => {
  const defaultTab = PRODUCT_MAPPING.find((tab) => tab.default);
  return defaultTab ? defaultTab : PRODUCT_MAPPING[0];
};
const defaultTab = getDefaultTab();

const ProductIndividual = (props: any) => {
  const { modulePermissions, otherModulePermissions } = props;
  const { actions = {} } = modulePermissions;
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabParam = searchParams.get('activeTab');
  const topRef = useRef<HTMLDivElement>(null);

  const initialProductState = useMemo(() => {
    return PRODUCT_MAPPING.find((item) => item.key === activeTabParam)?.key || defaultTab.key;
  }, [activeTabParam]);

  useEffect(() => {
    window.addEventListener('popstate', () => {
      navigate(0);
    });
  }, [activeTabParam]);

  const supplierActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.supplier)?.actions,
    [otherModulePermissions]
  );

  function getValueByKey(key: string) {
    const state = PRODUCT_MAPPING.find((item) => item.key === key);
    return state ? state.value : defaultTab.value;
  }

  const [productState, setProductState] = useState<ProductIndividualStates>({
    tabState: getValueByKey(initialProductState),
    productInfo: null,
    isLoading: false,
    editable: false
  });

  const { tabState, productInfo, isLoading, editable } = productState;
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getProductInfo();
  }, []);

  const getProductInfo = async () => {
    const { id } = params;
    if (id) {
      setProductState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await fetchProductInfo(id);
      setProductState((prevState) => ({ ...prevState, isLoading: false }));
      if (response.success) {
        const { data } = response;
        setProductState((prevState) => ({ ...prevState, productInfo: data }));
      } else {
        navigate('/404');
      }
    } else {
      navigate(`/${CLIENT_ROUTES.product}`);
    }
  };

  const handleTabChange = useCallback((newValue: number) => {
    const queryKey = PRODUCT_MAPPING.find((state) => state.value === newValue);
    setProductState((prevState) => ({ ...prevState, tabState: newValue }));
    setSearchParams({
      ...Object.fromEntries(searchParams),
      activeTab: queryKey?.key ?? defaultTab.key
    });
  }, []);

  const handleProductDetailChange = (param: ProductInfo) => {
    setProductState((prevState) => ({ ...prevState, productInfo: { ...param } }));
  };

  const handleEditClick = () => {
    setProductState((prevState) => ({ ...prevState, tabState: 0, editable: !prevState.editable }));
  };

  useScrollToTop({ topRef, dependencyArray: [tabState] });

  return (
    <AccessWrapper show={actions?.read} showUnauthorised>
      {isLoading ? (
        // <div className={css.productWrapper}>
        <ProductIndividualSkeleton />
      ) : (
        // </div>
        <main className={css.productWrapper}>
          {productInfo && <Seo title={productInfo.name} />}
          {productInfo && (
            <BreadCrumb>
              <BreadCrumb.Item value={0} to={`/${CLIENT_ROUTES.product}`}>
                Product List
              </BreadCrumb.Item>
              <BreadCrumb.Item value={1} to={`/${CLIENT_ROUTES.product}/${params.id}`}>
                {productInfo.name}
              </BreadCrumb.Item>
            </BreadCrumb>
          )}
          {productInfo && (
            <ProductDetailCard
              productName={productInfo.name}
              CASNumber={productInfo.CAS_number}
              onEditClick={handleEditClick}
              actions={actions}
              productReadableId={productInfo?.product_readable_id}
            />
          )}
          <main ref={topRef} className={css.productTabContainer}>
            {productInfo && (
              <Tabs value={tabState}>
                <Tab label="Details" value={0} onClick={() => handleTabChange(0)} />
                <Tab label="Documents" value={1} onClick={() => handleTabChange(1)} />
                <AccessWrapper show={supplierActions?.read}>
                  <Tab label="Suppliers" value={2} onClick={() => handleTabChange(2)} />
                </AccessWrapper>
              </Tabs>
            )}
            {tabState === 0 && productInfo && (
              <DetailsTab
                productInfo={productInfo}
                enableEdit={editable}
                onChange={handleProductDetailChange}
                actions={actions}
              />
            )}
            {tabState === 1 && productInfo && (
              <DocumentsTab productInfo={productInfo} onChange={handleProductDetailChange} />
            )}
            {tabState === 2 && productInfo && <SupplierTab productInfo={productInfo} />}
          </main>
        </main>
      )}
    </AccessWrapper>
  );
};

export default ProductIndividual;

const ProductIndividualSkeleton = () => {
  return (
    <div className={css.productIndividualSkeletonWrapper}>
      <div className={css.productIndividualSkeletonCard}>
        <div className={css.productIndividualSkeletonCardLeft}>
          <Skeleton variant="circular" width={80} height={80} />
          <div className={css.productIndividualSkeletonCardLeftDetails}>
            <Skeleton variant="text" width={400} height={30} />
            <Skeleton variant="text" width={200} height={20} />
            <Skeleton variant="text" width={200} height={20} />
          </div>
        </div>
        <div className={css.productIndividualSkeletonCardRight}>
          <Skeleton variant="text" width={200} height={20} />
          <Skeleton variant="text" width={200} height={20} />
        </div>
      </div>
      <div className={css.productIndividualSkeletonFooter}>
        <div className={css.productIndividualSkeletonFooterTabs}>
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" width={150} height={30} />
        </div>
        <div className={css.productIndividualSkeletonFooterDetailsTab}>
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" width={100} height={30} />
        </div>
        <div className={css.productIndividualSkeletonFooterDetails}>
          <div className={css.productIndividualSkeletonFooterSidebar}>
            <Skeleton variant="text" width={200} height={30} />
            <Skeleton variant="text" width={200} height={30} />
            <Skeleton variant="text" width={200} height={30} />
            <Skeleton variant="text" width={200} height={30} />
            <Skeleton variant="text" width={200} height={30} />
            <Skeleton variant="text" width={200} height={30} />
            <Skeleton variant="text" width={200} height={30} />
            <Skeleton variant="text" width={200} height={30} />
            <Skeleton variant="text" width={200} height={30} />
          </div>
          <div className={css.productIndividualSkeletonFooterData}>
            <Skeleton variant="text" width={300} height={30} />
            <div className={css.productDetails}>
              <div className={css.productDetailsObject}>
                <Skeleton variant="text" width={300} height={30} />
                <Skeleton variant="text" width={300} height={30} />
              </div>
              <div>
                <Skeleton variant="text" width={300} height={30} />
                <Skeleton variant="text" width={300} height={30} />
              </div>
            </div>
            <div className={css.productDetails}>
              <div className={css.productDetailsObject}>
                <Skeleton variant="text" width={300} height={30} />
                <Skeleton variant="text" width={300} height={30} />
              </div>
              <div>
                <Skeleton variant="text" width={300} height={30} />
                <Skeleton variant="text" width={300} height={30} />
              </div>
            </div>
            <Skeleton variant="text" width={300} height={30} />
          </div>
        </div>
      </div>
    </div>
  );
};
