import React, { Fragment, memo } from 'react';
import { MainLayout } from '@components/layouts';
import { Button, Image } from '@components/base';
import Images from '@assets/images';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';

import css from './index.module.scss';

const Error = () => {
  const navigate = useNavigate();
  return (
    <div>
      <MainLayout />
      <div className={css.errorWrapper}>
        <Image className={css.illustration} src={Images.pageNotFound} alt="404-not-found" />
        <p>The page you are looking does not exist</p>
        <Button onClick={() => navigate(`/${CLIENT_ROUTES.order}/`)}>
          Return to Home
        </Button>
      </div>
    </div>
  );
};

export default Error;
