import Images from '@assets/images';
import { Accordion, Button, Divider, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { AppContext } from '@helpers/hooks/AppContext';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import css from './index.module.scss';
import UserCard from './user-card';
import { DOCUMENT_GENERATION_STATUS } from '@helpers/constants';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

interface RectifyPOProps {
  taskData: Array<Partial<ITaskTabProps>>;
}

const RectifyPOCard = (props: RectifyPOProps) => {
  const { appDispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useParams();
  const { taskData } = props;
  const taskItem = taskData[0];

  const productList = taskItem?.supplier_product_names?.join(', ');

  const docGenerationStatus = useMemo(() => {
    if (taskItem?.purchase_order_generation_status as number) {
      return DOCUMENT_GENERATION_STATUS.find(
        (i) => i.value === taskItem?.purchase_order_generation_status
      )?.label;
    }
    return null;
  }, [taskItem]);

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskItem?.is_completed ? (
                <Avatar src={Images.illustrationEdit} alt="" />
              ) : (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              )}
              <Link to={`/order/${taskItem?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  {`#${taskItem?.readable_task_id}`} - Rectify Purchase Order{' '}
                </Typography>
              </Link>
            </div>
            <div className={css.pocWrapper}>
              {taskItem?.is_completed && taskItem?.purchase_order_pdf && (
                <div className={css.textWrapperNew}>
                  <div className={css.uploadDocWrapper}>
                    <DocumentRow.View document={taskItem?.purchase_order_pdf ?? ''} title="" />
                    <DocumentRow.ViewWord document={taskItem?.purchase_order_docx ?? ''} title="" />
                  </div>
                </div>
              )}
              {!taskItem?.is_completed &&
                (!docGenerationStatus ||
                  (docGenerationStatus == 'DOC_GENERATION_SUCCESS' && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        appDispatch({
                          type: 'setRectifyData',
                          payload: {
                            isRectify: true,
                            task_id: taskItem?.task_id,
                            order_id: taskItem?.order
                          }
                        });
                        navigate(
                          `/order/rectify-purchase-order/${taskItem?.order as string}/${
                            taskItem?.supplier_id as string
                          }/${taskItem?.task_id as string}`
                        );
                      }}
                      className={css.rectifyButton}>
                      Rectify PO
                    </Button>
                  )))}
              {!taskItem?.is_completed &&
                (docGenerationStatus == 'DOC_GENERATION_PROCESSING' ||
                  docGenerationStatus == 'DOC_GENERATION_REQUESTED') && (
                  <Button variant="text" disabled className={css.assignButton}>
                    Document generation in process
                  </Button>
                )}
              {!taskItem?.is_completed && docGenerationStatus == 'DOC_GENERATION_FAILED' && (
                <Button variant="text" disabled className={css.assignButton}>
                  Document Generation Failed
                </Button>
              )}
              {!taskItem?.is_completed && <Divider className={css.divider} />}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordionContent}>
          <div className={css.orderCardFirstWrapper}>
            <UserCard
              primaryTitle="Supplier"
              secondaryTitle={String(taskItem?.supplier_name)}
              showPrimaryIcon={false}
              showInfoIcon={false}
            />
            <UserCard
              primaryTitle="Product"
              secondaryTitle={String(productList)}
              showPrimaryIcon={false}
              showInfoIcon={false}
              style={{ borderRight: '1px solid var(--color-grey-1)' }}
            />
          </div>
        </Accordion.Content>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskData[0]?.updated_at)?.diff(taskData[0]?.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};

export default RectifyPOCard;
