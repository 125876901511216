import { IUserSliceState } from '@helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setToken, clearToken } from '@helpers/auth-helper';

const initialState: IUserSliceState = {
  accessToken: null,
  refreshToken: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addToken: (state, action: PayloadAction<IUserSliceState>) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    removeToken: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    }
  }
});

export const { addToken, removeToken } = userSlice.actions;
export const userReducer = userSlice.reducer;

export default userSlice;
