import httpClient from './index.service';
import { SERVER_CONFIG } from '@helpers/constants';

export async function fetchAllUsers(params?: any) {
  const paramList: any = [];
  if (params) {
    Object.entries(params).forEach((param: any) => {
      const [key, value] = param;
      paramList.push(`${key}=${value}`);
    });
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.listUsers}?${paramList?.length ? paramList.join('&') : ``}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}
