import Modal, { ModalProps } from '../modal';
import './index.style.scss';
import Spinner from './spinner';

export interface LoaderProps extends Omit<ModalProps, 'children'> {
  open: boolean;
  hideSpinner?: boolean;
}

const Loader = (props: LoaderProps) => {
  const { hideSpinner, ...otherProps } = props;
  return (
    <Modal {...otherProps}>
      <div className="loader-wrapper">{!hideSpinner && <Spinner className="loader-spinner" />}</div>
    </Modal>
  );
};

Loader.defaultProps = {
  hideSpinner: false
};

export default Loader;
export { Spinner };
