import { memo, useMemo } from 'react';
import css from './index.module.scss';
import { IconNode, Typography } from '@components/base';
import { DataGrid } from '@components/common';
import { IContainer } from '@helpers/types/add-order';
import Images from '@assets/images';

interface OrderDetailContainerProps {
  onEditClick?: () => void;
  previewForm?: boolean;
  containerData: IContainer[];
}

interface DocumentGrid {
  index: number;
  no_of_container: number;
  container_type: string;
}

const ContainerDetail = (props: OrderDetailContainerProps) => {
  const { onEditClick, containerData, previewForm = true } = props;
  const [containerDataColumn, containerDataRow] = useMemo(() => {
    const columns: any = [
      {
        Header: 'Sr No',
        accessor: 'index'
      },
      {
        Header: 'Number of Container',
        accessor: 'no_of_container'
      },
      {
        Header: 'Container type',
        accessor: 'container_type'
      }
    ];
    const rows: DocumentGrid[] = containerData?.length
      ? containerData?.map((doc: any, index: number) => ({
          index: ++index,
          no_of_container: doc.no_of_container,
          container_type: doc.container_type?.label
            ? doc.container_type?.label
            : doc.container_type?.length
            ? doc.container_type
            : '-'
        }))
      : [];
    return [columns, rows];
  }, [containerData]);
  return (
    <div className={css.outlineWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="pdoc">Container Details</Typography>
        {previewForm ? (
          <IconNode
            src={Images.editRed}
            alt="edit icon"
            className={css.editButton}
            component="button"
            onClick={onEditClick}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={css.rowWrapper}>
        <DataGrid
          tableStyle={{ width: '100%' }}
          columns={containerDataColumn}
          data={containerDataRow}
        />
      </div>
    </div>
  );
};

export default memo(ContainerDetail);
