import { BoxContainer } from '@components/common';
import css from './index.module.scss';
import Parties from './components/parties';
import Order from './components/order';
import HAZDetails from './components/haz-preview';
import Remarks from './components/remarks';
import { Button } from '@components/base';
import OrderItemPreview from './components/order-item-preview';
import ShippingInstructionPreview from './components/shipping-instruction-preview';
import { IRemarksFormData, IShippingFormData } from '@helpers/types/si';
import { IOrderItem, ITaskItemProps } from '@helpers/types/task-tabs';

interface SIPreviewProps {
  shippingFormData: IShippingFormData;
  data: ITaskItemProps;
  remarksFormData: IRemarksFormData[];
  onBackClick: () => void;
  handleCancelClick: () => void;
  handleFormSubmit: () => void;
}

const SIPreview = (props: SIPreviewProps) => {
  const {
    shippingFormData,
    data,
    remarksFormData,
    onBackClick,
    handleCancelClick,
    handleFormSubmit
  } = props;
  return (
    <div className={css.mainWrapper}>
      <BoxContainer title="Preview">
        <Parties data={data} />
        <Order data={data} />
        <OrderItemPreview data={data} />
        {data?.order_items.map((item: IOrderItem, index: number) => {
          if (item.haz) {
            return (
              <HAZDetails
                key={index}
                data={item}
                shippingFormData={shippingFormData}
                index={index}
              />
            );
          }
          return null;
        })}
        <ShippingInstructionPreview shippingFormData={shippingFormData} />
        <Remarks remarksFormData={remarksFormData} />
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button variant="outlined-secondary" onClick={onBackClick}>
            Back
          </Button>
          <Button type="submit" onClick={handleFormSubmit}>
            Generate SI
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SIPreview;
