import { Pagination } from '@mui/material';
import { useEffect } from 'react';

interface PaginationScrollerProps {
  color?: 'primary' | 'secondary' | 'standard' | undefined;
  count: number;
  defaultPage?: number;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
  hideNextButton?: boolean;
  hidePrevButton?: boolean;
  page: number;
  size?: 'large' | 'small' | 'medium' | undefined;
  variant?: 'text' | 'outlined' | undefined;
  rootClassname?: string;
  pageLimit?: number;
  onIntersect?: (page: number) => void;
}

function PaginationScroller(props: PaginationScrollerProps) {
  const {
    color,
    count,
    defaultPage = 1,
    disabled,
    onChange,
    hideNextButton,
    hidePrevButton,
    page,
    size = 'large',
    variant,
    rootClassname,
    pageLimit = 10,
    onIntersect,
    ...otherProps
  } = props;

  const styles = {
    marginTop: '4px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiPaginationItem-root': {
      color: color,
      fontFamily: "'Mukta', sans-serif"
    }
  };

  useEffect(() => {
    onIntersect?.(page);
  }, [page]);

  return (
    <Pagination
      color={color}
      count={Math.ceil(count / pageLimit)}
      defaultPage={defaultPage}
      disabled={disabled}
      onChange={onChange}
      hideNextButton={hideNextButton}
      hidePrevButton={hidePrevButton}
      page={page}
      sx={styles}
      className={rootClassname}
      size={size}
      variant={variant}
      {...otherProps}
    />
  );
}

export default PaginationScroller;
