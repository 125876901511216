import { memo } from 'react';
import css from './index.module.scss';
import { IconNode, Typography } from '@components/base';
import Images from '@assets/images';

interface EntityBankDetailContainerProps {
  onEditClick?: () => void;
  bankName: string;
  branchName: string;
  accountHolderName: string;
  accountNumber: string;
  ifscCode: string;
  swiftCode: string;
  countryOfOrigin: string;
  previewForm?: boolean;
}

const EntityBankDetailContainer = (props: EntityBankDetailContainerProps) => {
  const {
    onEditClick,
    bankName,
    branchName,
    accountHolderName,
    accountNumber,
    ifscCode,
    swiftCode,
    countryOfOrigin,
    previewForm = true
  } = props;
  return (
    <div className={css.outlineWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="pdoc">Entity Bank Details</Typography>
        {previewForm ? (
          <IconNode
            src={Images.editRed}
            alt="edit icon"
            className={css.editButton}
            component="button"
            onClick={onEditClick}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={css.rowWrapper}>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Bank Name</Typography>
          <Typography variant="pdoc">{bankName}</Typography>
        </div>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Branch</Typography>
          <Typography variant="pdoc">{branchName}</Typography>
        </div>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Account holder Name</Typography>
          <Typography variant="pdoc">{accountHolderName}</Typography>
        </div>
      </div>
      <div className={css.rowWrapper}>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Bank Account Number</Typography>
          <Typography variant="pdoc">{accountNumber}</Typography>
        </div>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">IFSC Code</Typography>
          <Typography variant="pdoc">{ifscCode}</Typography>
        </div>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">SWIFT Code</Typography>
          <Typography variant="pdoc">{swiftCode}</Typography>
        </div>
      </div>
      <div className={css.rowWrapper}>
        <div className={css.columnWrapper}>
          <Typography variant="ptext">Bank Details</Typography>
          <Typography variant="pdoc">{countryOfOrigin}</Typography>
        </div>
      </div>
    </div>
  );
};

export default memo(EntityBankDetailContainer);
