import { Typography, Modal, IconNode, Button } from '@components/base';
import { TextField, InputDatePicker, DataGrid } from '@components/common';
import { useFormContext, Controller } from 'react-hook-form';
import Images from '@assets/images';
import { addBLDetails } from '@services/task.service';
import notify from '@helpers/toastify-helper';
import moment from 'moment';
import { useContext, useMemo, useState } from 'react';

import css from './index.module.scss';
import RadioGroup from '@components/base/radio-group';
import { booleanSelectFields } from '@helpers/constants';
import { verifyPackagingDetails } from '@services/task.service';
import { CLIENT_ROUTES } from '@router/routes';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@helpers/hooks/AppContext';

interface VerifyModalPropType {
  open: boolean;
  onClose: () => void;
  taskData: any;
  onComplete?: () => void;
}

interface VerifyModalStateType {
  isSubmitting: boolean;
  value: any;
}

const VerifyModal = (props: VerifyModalPropType) => {
  const { open, onClose, taskData, onComplete } = props;
  const { task_id, order } = taskData;
  const navigate = useNavigate();
  const { appDispatch } = useContext(AppContext);

  const [modalState, setModalState] = useState<VerifyModalStateType>({
    isSubmitting: false,
    value: null
  });

  const { isSubmitting, value } = modalState;

  const onFormSubmit = async () => {
    if (!value)
      return notify({
        severity: 'error',
        message: 'Please select an option'
      });

    const body = {
      is_verified: !value?.value
    };

    if (value?.value == false) {
      setModalState((prevState: any) => ({ ...prevState, isSubmitting: true }));
      const response = await verifyPackagingDetails(task_id, body);
      if (response?.success) {
        notify({
          severity: 'success',
          message: 'Packaging details verified successfully'
        });
        onClose();
        if (onComplete) {
          onComplete();
        }
      } else {
        notify({
          severity: 'error',
          message: response?.error ?? 'Something went wrong'
        });
      }
      setModalState((prevState: any) => ({ ...prevState, isSubmitting: false }));
    } else {
      appDispatch({
        type: 'setPackageData',
        payload: {
          task_id: taskData?.task_id,
          order_id: taskData?.order,
          supplier_id: taskData?.supplier_id
        }
      });
      navigate(`/${CLIENT_ROUTES.order}/packaging-details/${order}/rectify`, { replace: true });
      onClose();
    }
  };

  const [documentColumn, documentData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Sr. No.',
        accessor: 'index'
      },
      {
        Header: 'Item Name',
        accessor: 'package_display_name'
      }
    ];
    const data: Array<any> = [];
    taskData?.packaging_details?.map((item: any, index: number) => {
      data.push({
        index: index + 1,
        package_display_name: (
          <>
            <Typography variant="p">{item.order_item_name}</Typography>
            <div className={css.modalProductTable}>
              <Typography variant="label">
                {`Packing Details: ${item.packaging_data_display_names.join(', ')}`}
              </Typography>
            </div>
          </>
        )
      });
    });
    return [column, data];
  }, []);
  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Verify Packaging Details</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>

        <div className={css.modalContent}>
          <Typography variant="p">Has the packing details for the order changed?</Typography>
          <div className={css.radioGroupWrapper}>
            <RadioGroup
              options={booleanSelectFields}
              onChange={(e: any, data: any) =>
                setModalState((prevState: any) => ({ ...prevState, value: data }))
              }
            />
          </div>
        </div>
        <div className={css.datagrid}>
          <DataGrid
            tableStyle={{
              width: '100%',
              padding: '16px'
            }}
            columns={documentColumn}
            data={documentData}
          />
        </div>
        <section className={css.modalFooter}>
          <Button variant="outlined-secondary" onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={onFormSubmit} disabled={isSubmitting}>
            Save
          </Button>
        </section>
      </div>
    </Modal>
  );
};

export default VerifyModal;
