import { Typography, Modal, IconNode, Button } from '@components/base';
import { TextField, InputDatePicker, SelectLabel } from '@components/common';
import { useFormContext, Controller } from 'react-hook-form';
import Images from '@assets/images';
import { addBLDetails } from '@services/task.service';
import notify from '@helpers/toastify-helper';
import moment from 'moment';

import css from './index.module.scss';
import { useState } from 'react';
import { BL_TYPES } from '@helpers/constants';
import { getDiffBetweenTwoDates, getFormattedDate } from '@helpers/utils';

interface BLDetailsModalProps {
  open: boolean;
  onClose: () => void;
  taskData: any;
  onComplete?: () => void;
  taskName: string;
}

const BLDetailsModal = (props: BLDetailsModalProps) => {
  const { open, onClose, taskData, onComplete, taskName } = props;
  const { task_id, export_clearance_obtained_actual_date } = taskData;
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting }
  } = useFormContext();

  const onFormSubmit = async (data: any) => {
    const dateDiff =
      taskName === 'AWB'
        ? getDiffBetweenTwoDates(data.awb_date, export_clearance_obtained_actual_date)
        : getDiffBetweenTwoDates(data.bl_date, export_clearance_obtained_actual_date);
    if (dateDiff < 0) {
      taskName === 'AWB'
        ? setError('awb_date', {
            message:
              'AWB Date cannot be before Export Clearance Obtained Date, Contact Tech Team to Backdate'
          })
        : setError('bl_date', {
            message:
              'BL Date cannot be before Export Clearance Obtained Date, Contact Tech Team to Backdate'
          });
      return;
    }
    const BLbody = {
      bl_date: `${moment(data.bl_date).format('YYYY-MM-DD')}`,
      bl_number: data.bl_number,
      bl_type: data.bl_type?.value
    };
    const AWBBody = {
      awb_service_name: data?.awb_service_name,
      awb_date: `${moment(data.awb_date).format('YYYY-MM-DD')}`,
      awb_number: data.awb_number
    };
    const body = taskName === 'AWB' ? AWBBody : BLbody;
    const response = await addBLDetails(task_id, body);
    if (response.success) {
      notify({
        message: `${taskName} details added successfully`
      });
      if (onComplete) {
        onComplete();
      }
      onClose();
    } else {
      notify({ message: response.error ?? 'Something went wrong!', severity: 'error' });
    }
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen((prevState: boolean) => !prevState);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={`${css.modalWrapper} ${isDatePickerOpen ? css.modalWrapperOpen : ''}`}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Add {taskName} Details</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>

        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
          <section className={css.modalContent}>
            {taskName === 'AWB' ? (
              <>
                <Controller
                  name="awb_service_name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      enterKeyHint="next"
                      label="AWB Service Name"
                      placeholder="Enter AWB service name"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      rootClassName={css.awbServiceName}
                    />
                  )}
                />
                <Controller
                  name="awb_date"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputDatePicker
                      {...field}
                      required
                      setDatePickerOpen={setIsDatePickerOpen}
                      value={field.value}
                      placeholder="Select date"
                      label={`${taskName} Date`}
                      onSelect={(day: Date | undefined) => {
                        field.onChange(day ?? null);
                        toggleDatePicker();
                      }}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      rootClassName={css.fieldSpacing}
                    />
                  )}
                />
                <Controller
                  name="awb_number"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      enterKeyHint="next"
                      label={`${taskName} Number`}
                      placeholder={`Enter ${taskName} number`}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      rootClassName={
                        isDatePickerOpen ? css.fieldWrapperOpenModal : css.fieldWrapper
                      }
                    />
                  )}
                />
              </>
            ) : (
              <>
                <Controller
                  name="bl_type"
                  control={control}
                  render={({ field, fieldState }) => (
                    <SelectLabel
                      {...field}
                      required
                      isSearchable
                      label={`${taskName} Type`}
                      options={BL_TYPES}
                      placeholder="Select one"
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                      rootClassName={css.blTypeFieldWrapper}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  name="bl_date"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputDatePicker
                      {...field}
                      required
                      setDatePickerOpen={setIsDatePickerOpen}
                      value={field.value}
                      placeholder="Select date"
                      label={`${taskName} Date`}
                      onSelect={(day: Date | undefined) => {
                        field.onChange(day ?? null);
                        toggleDatePicker();
                      }}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      rootClassName={css.fieldSpacing}
                    />
                  )}
                />

                <Controller
                  name="bl_number"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      enterKeyHint="next"
                      label={`${taskName} Number`}
                      placeholder={`Enter ${taskName} number`}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      rootClassName={
                        isDatePickerOpen ? css.fieldWrapperOpenModal : css.fieldWrapper
                      }
                    />
                  )}
                />
              </>
            )}
          </section>
          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </section>
        </form>
      </div>
    </Modal>
  );
};

export default BLDetailsModal;
