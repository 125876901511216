import { PerformaInvoiceStates } from '@helpers/types/pi';
import PIReview from './components/preview-tab';
import css from './index.module.scss';

interface IPreview {
  previewData: PerformaInvoiceStates;
  handleFormSubmit: () => void;
  handleCancelClick: () => void;
  handleBackClick: () => void;
  setOrderState: (orderState: PerformaInvoiceStates) => void;
  isRectify: boolean;
}

const GenerateProformaInvoiceReview = (props: IPreview) => {
  const {
    previewData,
    handleFormSubmit,
    handleCancelClick,
    handleBackClick,
    setOrderState,
    isRectify
  } = props;

  return (
    <main className={css.purchaseOrderWrapper}>
      <div className={css.purchaseOrderContainer}>
        <PIReview
          previewData={previewData}
          handleFormSubmit={handleFormSubmit}
          handleCancelClick={handleCancelClick}
          handleBackClick={handleBackClick}
          setOrderState={setOrderState}
          isRectify={isRectify}
        />
      </div>
    </main>
  );
};

export default GenerateProformaInvoiceReview;
