import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader, Typography } from '@components/base';
import { useBeforeUnloadAndNavigate, useScrollToTop } from '@helpers/hooks';
import { SideBar } from '@components/common';
import { CLIENT_ROUTES } from '@router/routes';
import notify from '@helpers/toastify-helper';
import css from './index.module.scss';
import OrderDetails from './components/orderDetails';
import OrderItemDetails from './components/orderItemDetails';
import { yupResolver } from '@hookform/resolvers/yup';
import ShippingInstructionForm from './components/shippingInstructionDetails';
import {
  shipmentInstructionFormSchema,
  remarksFormSchema
} from '@helpers/yup/generate-shipping-instructions.schema';
import RemarkTab from './components/remarks-tab';
import PreviewTab from './components/preview-tab';
import { createShippingInstructions } from '@services/order.service';
import { convertDateString } from '@helpers/constants';
import { IRemarksFormData, IShippingFormData } from '@helpers/types/si';
import { getShipmentInstructions } from '@services/task.service';
import { ITaskItemProps } from '@helpers/types/task-tabs';

const GenerateShipmentInstructions = () => {
  const params = useParams();
  const orderId = params?.orderid;
  const taskId = params?.taskid;
  const navigate = useNavigate();
  const [taskItem, setTaskItem] = useState<ITaskItemProps>({
    order_id: '',
    order_item: [],
    shipper: '',
    consignee: '',
    order_flow_D: null,
    notify_party_1: '',
    notify_party_2: '',
    customer: '',
    elchemy_header: '',
    consignee_same_as_customer: null,
    vessel_name: '',
    voyage_number: '',
    port_of_loading: '',
    port_of_discharge: '',
    container_nos: '',
    batch_nos: '',
    container_details: [],
    total_no_of_packages: 0,
    palletization: null,
    net_weight: 0,
    gross_weight: 0,
    transportation_mode: '',
    order_items: []
  });
  const [formState, setFormState] = useState({
    isLoading: false,
    activeStep: 0
  });

  const getTaskData = async () => {
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await getShipmentInstructions(orderId ?? "");
    if (response?.success) {
      setTaskItem(response.data);
    } else {
      notify({
        message: response.error ?? 'Error fetching data!',
        severity: 'error',
        dismissible: true
      });
    }
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: false
    }));
  };

  useEffect(() => {
    getTaskData();
  }, []);

  const [remarksFormData, setRemarksFormData] = useState<IRemarksFormData[]>([]);

  

  const [shippingFormData, setShippingFormData] = useState<IShippingFormData>({
    blType: null,
    placeOfReceipt: '',
    portOfFinalDestination: null,
    sealNumber: [],
    customSealNumber: [],
    shippingBillNumber: '',
    shippingBillDate: null,
    freight: null,
    switch_bl_case: false,
    net_weight: 0,
    gross_weight: 0,
    notify_party_1: '',
    notify_party_2: '',
    order_items: []
  });

  const shipmentInstructionForm = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(shipmentInstructionFormSchema),
    defaultValues: {
      blType: null,
      placeOfReceipt: '',
      portOfFinalDestination: null,
      sealNumber: [],
      customSealNumber: [],
      shippingBillNumber: '',
      shippingBillDate: '',
      freight: null,
      switch_bl_case: false,
      notify_party_1: '',
      notify_party_2: '',
      order_items: []
    }
  });

  const { setValue } = shipmentInstructionForm;

  const { isLoading, activeStep } = formState;

  const remarkForm = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(remarksFormSchema),
    defaultValues: {
      remarks: []
    }
  });

  const handleShippingInstructionSubmit = (data: IShippingFormData) => {
    const modifiedCustomSealNumbers: string[] = [];
    const modifiedSealNumbers: string[] = [];
    data.customSealNumber.forEach((item) => {
      if (item.customSealNumber !== '') {
        modifiedCustomSealNumbers.push('k' + item.customSealNumber);
      }
    });
    data.sealNumber.forEach((item) => {
      if (item.sealNumber !== '') {
        modifiedSealNumbers.push(item.sealNumber);
      }
    });

    const updatedShippingFormData: IShippingFormData = {
      ...data,
      sealNumber: modifiedSealNumbers.map((sealNumber) => ({ sealNumber })),
      customSealNumber: modifiedCustomSealNumbers.map((customSealNumber) => ({ customSealNumber }))
    };
    setShippingFormData(updatedShippingFormData);
    handleNavigation(3);
  };

  const { getValues } = remarkForm;

  const handleRemarksSubmit = () => {
    const data = getValues('remarks');
    setRemarksFormData(data);
    handleNavigation(4);
  };

  const handleFormSubmit = async () => {
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));
    let remarks = '';
    remarksFormData.forEach((remark: IRemarksFormData, index: number) => {
      remarks += remark;
      if (index < remarksFormData.length - 1) {
        remarks += '\n';
      }
    });
    const formattedData = {
      bl_type: shippingFormData?.blType?.value,
      place_of_receipt: shippingFormData.placeOfReceipt,
      port_of_final_destination: shippingFormData?.portOfFinalDestination?.value,
      seal_numbers: (shippingFormData.sealNumber as { sealNumber: string }[])
        .map((item) => item.sealNumber)
        .join(', '),
      custom_seal_numbers: (shippingFormData.customSealNumber as { customSealNumber: string }[])
        .map((item) => item.customSealNumber)
        .join(', '),
      shipping_bill_number: shippingFormData.shippingBillNumber,
      shipping_bill_date: convertDateString(shippingFormData.shippingBillDate),
      freight: shippingFormData?.freight?.value,
      remarks: JSON.stringify({
        string: remarks
      }),
      is_this_switch_BL_case: shippingFormData.switch_bl_case,
      notify_party_1: shippingFormData.notify_party_1,
      notify_party_2: shippingFormData.notify_party_2,
      order_items: shippingFormData.order_items.map((item: any) => ({
        order_item_id: item.order_item_id,
        un_no: item.un_number,
        haz_class: item.class,
        ems: item.ems,
        packing_group: item?.packing_group?.value
      }))
    };
    const response = await createShippingInstructions(taskId, formattedData);
    if (response?.success) {
      notify({
        message: 'Shipping Instructions created successfully!',
        severity: 'success',
        dismissible: true
      });
      navigate(`/${CLIENT_ROUTES.order}/${orderId}`);
    } else {
      notify({
        message: response.error ?? 'Error creating Shipping Instructions!',
        severity: 'error',
        dismissible: true
      });
    }
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: false
    }));
    return response;
  };
  const handleNavigation = (currentStep: number) => {
    setFormState((prevState: any) => ({
      ...prevState,
      activeStep: currentStep
    }));
  };

  const handleCancelClick = () => {
    navigate(`/${CLIENT_ROUTES.order}/${orderId}`);
  };

  const handleSidebarClick = (value: number) => {
    handleNavigation(value);
  };

  const handleSidebarEnterKey = (value: number) => {
    handleNavigation(value);
  };

  const topRef = useRef(null);
  useBeforeUnloadAndNavigate();
  useScrollToTop({ topRef, dependencyArray: [activeStep] });

  return (
    <main className={css.siWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Shipping Instructions</Typography>
        <Typography variant="subheading1">0{activeStep + 1} of 05</Typography>
      </div>
      <div className={css.siContainer} ref={topRef}>
        <div className={css.sideBarWrapper}>
          <SideBar
            activeStep={activeStep}
            onClick={handleSidebarClick}
            onEnter={handleSidebarEnterKey}>
            <SideBar.Item label="Order Details" value={0} />
            <SideBar.Item label="Item Details" value={1} disabled={activeStep! <= 1} />
            <SideBar.Item
              label="Shipping Instructions Details"
              value={2}
              disabled={activeStep! <= 2}
            />
            <SideBar.Item label="Standard Remarks" value={3} disabled={activeStep! <= 3} />
            <SideBar.Item label="Preview" value={4} disabled={activeStep! <= 4} />
          </SideBar>
        </div>
        {activeStep === 0 && (
          <OrderDetails
            data={taskItem}
            handleCancelClick={handleCancelClick}
            handleOrderDetailSubmit={() => handleNavigation(1)}
          />
        )}
        {activeStep === 1 && (
          <OrderItemDetails
            data={taskItem}
            handleCancelClick={handleCancelClick}
            handleOrderItemSubmit={() => handleNavigation(2)}
          />
        )}
        <FormProvider {...shipmentInstructionForm}>
          {activeStep === 2 && (
            <ShippingInstructionForm
              data={taskItem}
              shippingFormData={shippingFormData}
              handleCancelClick={handleCancelClick}
              handleShippingInstructionSubmit={handleShippingInstructionSubmit}
            />
          )}
        </FormProvider>
        <FormProvider {...remarkForm}>
          {activeStep === 3 && (
            <RemarkTab
              handleCancelClick={handleCancelClick}
              onBackClick={() => handleNavigation(2)}
              shippingFormData={shippingFormData}
              handleRemarksSubmit={handleRemarksSubmit}
            />
          )}
        </FormProvider>
        {activeStep === 4 && (
          <PreviewTab
            data={taskItem}
            shippingFormData={shippingFormData}
            remarksFormData={remarksFormData}
            handleCancelClick={handleCancelClick}
            onBackClick={() => handleNavigation(3)}
            handleFormSubmit={handleFormSubmit}
          />
        )}
      </div>
      <Loader open={isLoading} />
    </main>
  );
};

export default GenerateShipmentInstructions;
