import React, { useMemo } from 'react';
import { BoxContainer, TextField } from '@components/common';
import { Button, IconNode } from '@components/base';
import { Controller, useFormContext } from 'react-hook-form';
import Images from '@assets/images';

import css from './index.module.scss';
import { FieldValue } from '@helpers/types/coa';

interface FieldInputPropTypes {
  data: FieldValue[];
  remove: () => void;
  handleAddField: () => void;
}

const FieldInput = (props: FieldInputPropTypes) => {
  const { data, remove, handleAddField } = props;
  return (
    <BoxContainer title="Table Row Details" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.fieldDataWrapper}>
          {data.map((item: any, index: number) => (
            <FieldDataGroup
              data={item}
              key={item.id}
              parentIndex={index}
              showDelete={data?.length > 1}
              onDeleteClick={remove}
            />
          ))}

          <Button
            variant="text"
            onClick={handleAddField}
            startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
            Add More Fields
          </Button>
        </div>
      </div>
    </BoxContainer>
  );
};

interface FieldDataGroupPropTypes {
  data: FieldValue;
  parentIndex: number;
  showDelete: boolean;
  onDeleteClick: () => void;
}

const FieldDataGroup = (props: FieldDataGroupPropTypes) => {
  const { data, parentIndex, showDelete, onDeleteClick } = props;
  const { control } = useFormContext<any>();
  const keys = useMemo(() => Object.keys(data).filter((item: any) => item != 'id'), [data]);
  return (
    <BoxContainer title={`Row ${parentIndex + 1}`} className={css.mainWrapper}>
      <div className={css.boxWrapper1}>
        <div>
          {keys.map((item: any, index: number) => (
            <Controller
              name={`item_details.${parentIndex}.${item}`}
              control={control}
              key={index}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  inputMode="text"
                  enterKeyHint="next"
                  label={item}
                  placeholder={`Enter ${item}`}
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                  rootClassName={css.inputWrapper}
                />
              )}
            />
          ))}
        </div>

        <div>
          <Button
            variant="text"
            title="Delete Item"
            onClick={onDeleteClick}
            disabled={!showDelete}
            startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}>
            Delete Row
          </Button>
        </div>
      </div>
    </BoxContainer>
  );
};

export default FieldInput;
