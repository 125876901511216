export interface IRemarksProps {
  dispatch_date: boolean;
  amount: boolean;
  proof_of_export: boolean;
  lifting_will_be_subject: boolean;
  product_should_be_from: boolean;
  palletization_detail: boolean;
  fumigation_certificate: boolean;
  standard_switch_bl_mark: boolean;
  shipment_to_be_processed: boolean;
  shipping_mark_in_the_name_of_elchemy: boolean;
  other_remarks: boolean;
  dispatch_date_info: string;
  fcl: string;
  amount_info: string;
  supplier_name: string;
  palletization_details: string;
  shipping_lines: string;
  text_remarks: string;
}

export const remarkFunction = (data: any) => {
  const remarkArr = [];
  if (data?.dispatch_date) {
    remarkArr.push(`Dispatch Date: ${data.dispatch_date_info} FCLs on before ${data.fcl}`);
  }

  if (data?.amount) {
    remarkArr.push(`${data.amount_info} has been paid as advance (if applicable)`);
  }

  if (data?.proof_of_export) {
    remarkArr.push(`Proof of export to be shared with ${data.supplier_name} (if applicable)`);
  }

  if (data?.lifting_will_be_subject) {
    remarkArr.push(`Lifting will be subject to inspection and approval by Elchemy`);
  }

  if (data?.product_should_be_from) {
    remarkArr.push(`Product should be from recently manufactured batch`);
  }

  if (data?.palletization_detail) {
    remarkArr.push(
      `${data.palletization_details} should be new and in clean condition (if applicable)`
    );
  }

  if (data?.fumigation_certificate) {
    remarkArr.push(`Fumigation Certificate required for pallets (if applicable)`);
  }

  if (data?.shipment_to_be_processed) {
    remarkArr.push(
      `Shipment to be processed after Pre-shipment verification by Elchemy's third-party partner`
    );
  }

  if (data?.shipping_mark_in_the_name_of_elchemy) {
    remarkArr.push(`Shipping marks should be in the name of Elchemy`);
  }

  if (data?.other_remarks) {
    remarkArr.push(`${data.text_remarks}`);
  }

  if (data?.bl_switchable) {
    remarkArr.push(`BL should be switchable in Shipping lines ${data.shipping_lines.label}`);
  }

  if (data?.master_bl) {
    remarkArr.push(`Master BL/Liners BL required`);
  }

  if (data?.pss_confirmation) {
    remarkArr.push(`Consignment dispatch against PSS confirmation`);
  }

  if (data?.expiry_remarks) {
    remarkArr.push(`Product expiry date to be atleast 1 year from the date of purchase of goods`);
  }

  return remarkArr.join('\n ');
};
