import { useEffect, useMemo, useState, FC } from 'react';
import { getShipmentTrackingDetails } from '@services/order.service';
import { DataGrid, ListPlaceholder, TableSkeleton } from '@components/common';
import { Chip, Typography } from '@components/base';
import css from './index.module.scss';
import notify from '@helpers/toastify-helper';
import { SUPPORT_EMAIL, getFormattedDate, getPermissionFromLocalStorage } from '@helpers/utils';
import { USER_TYPES } from '@helpers/constants';
import Skeleton from '@components/base/skeleton';

interface TrackingProps {
  orderId: string;
  shipmentType?: string;
}

interface RowGridType {
  actual_date: JSX.Element | string | null;
  delay: JSX.Element;
  is_reached: JSX.Element | string;
  location: string;
  carrier_activity: string | JSX.Element;
  tentative_date: JSX.Element | string | null;
  carrier_name: string | JSX.Element;
}

interface ITrackingTable {
  location: string;
  event?: string;
  airline_name?: string;
  tentative_date: string;
  actual_date: string;
  is_reached: boolean;
  delay: number;
  ship_activity?: string;
  vessel_name?: string;
}

interface TrackingState {
  isLoading: boolean;
  iFrameURL: string;
  trackingTable: Array<ITrackingTable>;
  activeStep: number;
  transportationMode: string;
}

const Tracking = (props: TrackingProps) => {
  const { orderId, shipmentType } = props;
  const [tracking, setTracking] = useState<TrackingState>({
    isLoading: false,
    iFrameURL: '',
    trackingTable: [],
    activeStep: 0,
    transportationMode: ''
  });

  const { isLoading, iFrameURL, trackingTable, activeStep, transportationMode } = tracking;

  useEffect(() => {
    fetchTrackingDetails();
  }, []);

  const userType = useMemo(() => {
    return getPermissionFromLocalStorage()?.user_type;
  }, []);

  const fetchTrackingDetails = async () => {
    setTracking((prevState: TrackingState) => {
      return {
        ...prevState,
        isLoading: true
      };
    });
    const response = await getShipmentTrackingDetails(orderId);
    if (response.success) {
      setTracking((prevState: TrackingState) => {
        return {
          ...prevState,
          iFrameURL: response.data.iframe_url,
          trackingTable: response.data.tracking_table_data,
          // activeStep: response.data.active_step,
          transportationMode: response.data.transportation_mode,
          isLoading: false
        };
      });
      notify({
        message: response?.data?.message,
        severity: 'success'
      });
    } else {
      notify({ message: response.error, severity: 'error' });
      setTracking((prevState: TrackingState) => {
        return {
          ...prevState,
          isLoading: false
        };
      });
    }
  };

  const renderCarrierActivity = (data: ITrackingTable) => {
    if (transportationMode === 'Sea') {
      return data.ship_activity ? (
        !data.is_reached ? (
          <Typography variant="label">{data.ship_activity}</Typography>
        ) : (
          data.ship_activity
        )
      ) : (
        '-'
      );
    }

    if (transportationMode === 'Air') {
      return data.event ? (
        !data.is_reached ? (
          <Typography variant="label">{data.event}</Typography>
        ) : (
          data.event
        )
      ) : (
        '-'
      );
    }

    return '-';
  };

  const renderCarrierName = (data: ITrackingTable) => {
    if (transportationMode === 'Sea') {
      return data.vessel_name ? (
        !data.is_reached ? (
          <Typography variant="label">{data.vessel_name}</Typography>
        ) : (
          data.vessel_name
        )
      ) : (
        '-'
      );
    }

    if (transportationMode === 'Air') {
      return data.airline_name ? (
        !data.is_reached ? (
          <Typography variant="label">{data.airline_name}</Typography>
        ) : (
          data.airline_name
        )
      ) : (
        '-'
      );
    }

    return '-';
  };

  const [documentColumn, documentData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Carrier Name',
        accessor: 'carrier_name'
      },
      {
        Header: 'Carrier Activity',
        accessor: 'carrier_activity'
      },
      {
        Header: 'Location',
        accessor: 'location'
      },
      {
        Header: 'Status',
        accessor: 'delay'
      },
      {
        Header: 'Tentative Date',
        accessor: 'tentative_date'
      },
      {
        Header: 'Actual Date',
        accessor: 'actual_date'
      },
      {
        Header: '',
        accessor: 'is_reached'
      }
    ];

    const row: Array<RowGridType> = trackingTable?.map((prod: any) => ({
      actual_date: prod.actual_date ? (
        !prod.is_reached ? (
          <Typography variant="label">{getFormattedDate(prod.actual_date)}</Typography>
        ) : (
          getFormattedDate(prod.actual_date)
        )
      ) : (
        '-'
      ),
      delay:
        prod.delay == '-' ? (
          <div>{'-'}</div>
        ) : (
          <Chip
            label={(() => {
              let label;
              if (prod.delay < 0) {
                label = `Early by ${Math.abs(prod.delay)} days`;
              } else if (prod.delay > 0) {
                label = `Delayed by ${prod.delay} days`;
              } else if (prod.delay === 0) {
                label = 'On Time';
              } else {
                label = '-';
              }
              return label;
            })()}
            className="chip-center"
            variant={
              prod.delay < 0 ? 'filled-blue' : prod.delay === 0 ? 'filled-green' : 'filled-red'
            }
            clickable={false}
          />
        ),
      is_reached: prod.is_reached ? (
        <Chip label="Reached" className="chip-center" variant="filled-green" clickable={false} />
      ) : (
        ``
      ),
      location: prod.location ? (
        !prod.is_reached ? (
          <Typography variant="label">{prod.location}</Typography>
        ) : (
          prod.location
        )
      ) : (
        '-'
      ),
      carrier_activity: renderCarrierActivity(prod),
      tentative_date: prod.tentative_date ? (
        !prod.is_reached ? (
          <Typography variant="label">{getFormattedDate(prod.tentative_date)}</Typography>
        ) : (
          getFormattedDate(prod.tentative_date)
        )
      ) : (
        '-'
      ),
      carrier_name: renderCarrierName(prod)
    }));

    return [column, row];
  }, [trackingTable]);

  if (isLoading) return <TrackingSkeleton />;

  return (
    <div>
      {trackingTable && trackingTable.length > 0 && (
        <div className={css.taskChipWrapper}>
          <Chip
            label="Map View"
            variant={activeStep === 0 ? 'filled' : 'outlined'}
            onClick={() =>
              setTracking((prevState: any) => {
                return {
                  ...prevState,
                  activeStep: 0
                };
              })
            }
          />
          <Chip
            label="Table View"
            variant={activeStep === 1 ? 'filled' : 'outlined'}
            onClick={() =>
              setTracking((prevState: any) => {
                return {
                  ...prevState,
                  activeStep: 1
                };
              })
            }
          />
        </div>
      )}

      {activeStep === 0 && shipmentType === 'Sea' ? (
        iFrameURL ? (
          <iframe
            src={iFrameURL}
            id="IframeShipsgoLiveMap"
            style={{ height: 500, width: '100%' }}></iframe>
        ) : (
          <ListPlaceholder
            to={``}
            title="Tracking not available for this order yet"
            supportingText={
              userType === USER_TYPES.customer
                ? `Contact ${SUPPORT_EMAIL} incase of any issues with tracking`
                : `If you have BL for this order, share it with tech team so tracking is enabled`
            }
            buttonText=""
          />
        )
      ) : (
        <></>
      )}

      {activeStep === 1 && trackingTable && trackingTable.length > 0 && (
        <DataGrid tableStyle={{ width: '100%' }} columns={documentColumn} data={documentData} />
      )}
    </div>
  );
};

export default Tracking;

const TrackingSkeleton = () => {
  return (
    <div>
      <div className={css.trackingContainer}>
        <Skeleton animation="wave" width="200px" height={30} />
        <Skeleton animation="wave" width="200px" height={30} />
      </div>
      <Skeleton variant="rectangular" width="fitContent" height="750px" />
    </div>
  );
};
