import { memo } from 'react';
import css from './index.module.scss';
import { BoxContainer, TextAreaLabel } from '@components/common';
import { Controller, useFormContext } from 'react-hook-form';

const RemarkContainer = () => {
  const { control } = useFormContext();

  return (
    <BoxContainer title="Additional Remarks" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <Controller
          name="additional_remarks"
          control={control}
          render={({ field, fieldState }) => (
            <TextAreaLabel
              required
              {...field}
              placeholder="Enter remarks"
              rows={5}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </div>
    </BoxContainer>
  );
};

export default memo(RemarkContainer);
