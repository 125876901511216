import { Checkbox, Modal } from '@components/base';
import { Button } from '@components/base';
import css from './index.module.scss';
import { Typography } from '@components/base';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';
import { SelectLabel } from '@components/common';
import { MODE_TO_SEND_DOCUMENTS_TO_CUSTOMER } from '@helpers/constants';
import { useState } from 'react';

interface ISelect {
  label: string;
  value: number;
}
interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: (data: any) => void;
  message?: string;
  disclaimerMessages: string[];
  mode: ISelect | null;
  onChangeMode: (data: any) => void;
}

function ConfirmationModal(props: ConfirmationModalProps) {
  const { open, onClose, onConfirm, onChangeMode, disclaimerMessages, mode } = props;

  const [checkBox, setCheckBox] = useState(false);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Send Document(s) to Customer</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <section className={css.formWrapper}>
          <div className={css.modalContent}>
            <SelectLabel
              label="How would you like to send the document(s)?"
              placeholder="Select one option"
              options={MODE_TO_SEND_DOCUMENTS_TO_CUSTOMER}
              value={mode}
              onChange={onChangeMode}
              isClearable
              required={true}
            />
            {disclaimerMessages.length > 0 && (
              <div className={css.disclaimerContainer}>
                <Typography variant="ptext">Disclaimer: Read Below points carefully</Typography>
                <ul>
                  {disclaimerMessages.map((message: string, index: number) => (
                    <ol key={index}>{message}</ol>
                  ))}
                </ul>
              </div>
            )}

            <div className={css.reviewText}>
              <Checkbox
                checked={checkBox}
                onChange={() => setCheckBox(!checkBox)}
                className={css.checkBox}
              />
              <Typography variant="ptext">
                I have reviewed that the document(s) to be sent <b>DOES NOT</b> have any sensitive
                information (like supplier Name, etc)
              </Typography>
            </div>
          </div>
        </section>
        <section className={css.modalFooter}>
          <Button variant="outlined-secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={!checkBox || !mode} onClick={onConfirm}>
            Share
          </Button>
        </section>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
