import { BoxContainer, SelectLabel, TextField } from '@components/common';
import { memo, useEffect } from 'react';
import css from './index.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { IconNode } from '@components/base';
import Images from '@assets/images';
import { PACKING_GROUP } from '@helpers/constants';
import { IOrderItem, ITaskItemProps } from '@helpers/types/task-tabs';

interface IHAZDetailsProps {
  data: IOrderItem;
  index: number;
}

const HAZDetails = (props: IHAZDetailsProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues
  } = useFormContext();
  const { data, index } = props;
  const { product_name, order_item_id, haz } = data;

  useEffect(() => {
    setValue(`order_items[${index}].order_item_id`, order_item_id);
    setValue(`order_items[${index}].haz`, haz);

    const packingGroupValue = getValues(`order_items[${index}].packing_group`);
    if (packingGroupValue === undefined) {
      setValue(`order_items[${index}].packing_group`, null);
    }
  }, []);

  return (
    <BoxContainer title={`HAZ Details - ${product_name}`} className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <Controller
            name={`order_items[${index}].un_number`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                label="UN Number"
                placeholder="Enter UN Number"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
                rootClassName={css.fieldWrapper}
              />
            )}
          />
          <Controller
            name={`order_items[${index}].class`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                label="Class"
                placeholder="Enter Class"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name={`order_items[${index}].ems`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="E.M.S"
                placeholder="Enter EMS"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
                rootClassName={css.fieldWrapper}
              />
            )}
          />
          <Controller
            name={`order_items[${index}].packing_group`}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                options={PACKING_GROUP}
                required
                label="Packing Group"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
      </div>
    </BoxContainer>
  );
};

export default memo(HAZDetails);
