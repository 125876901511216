import { memo, SyntheticEvent } from 'react';
import Image, { ImageProps } from '../image';
import './index.style.scss';

interface IconNodeProps extends ImageProps {
  component?: 'button' | 'div';
  imageClassName?: string;
  onClick?: (event: SyntheticEvent) => void;
}

const IconNode = (props: IconNodeProps) => {
  const { component = 'div', src, alt, className, onClick, imageClassName, ...otherProps } = props;
  const Component = component;
  return (
    <Component type="button" className={`icon-node-wrapper ${className}`} onClick={onClick}>
      <Image {...otherProps} src={src} alt={alt} className={`icon-node ${imageClassName}`} />
    </Component>
  );
};

export default memo(IconNode);
export type { IconNodeProps };
