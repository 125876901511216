import { Typography } from '@components/base';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import css from '../index.module.scss';

interface IRemark {
  previewData: PerformaInvoiceStates;
}

const Remarks = (props: IRemark) => {
  const { previewData } = props;
  const { remarksInfo } = previewData;

  return (
    <div className={css.boxWrapper}>
      <div className={css.remarkBoxContainer}>
        <div className={css.fieldWrapper}>
          <Typography variant="pdoc">Standard Remarks</Typography>
        </div>
        <div className={css.fieldWrapper}>
          <ol className={remarksInfo?.string?.length ? css.remarkListContainer : css.remarkListContainerNoMargin}>
            {remarksInfo?.string?.length ? remarksInfo?.string?.split('\n ').map((val: string, index: number) => {
              return <li key={index}>{val}</li>;
            }) : `No Additional Remarks Added`}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Remarks;
