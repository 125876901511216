import { memo } from 'react';
import css from './index.module.scss';
import { IconNode, Typography } from '@components/base';
import Images from '@assets/images';

interface RemarkContainerProps {
  onEditClick?: () => void;
  remark: string;
  previewForm?: boolean;
}

const RemarkContainer = (props: RemarkContainerProps) => {
  const { onEditClick, remark, previewForm = true } = props;
  return (
    <div className={css.outlineWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="pdoc">Additional Remark</Typography>
        {previewForm ? (
          <IconNode
            src={Images.editRed}
            alt="edit icon"
            className={css.editButton}
            component="button"
            onClick={onEditClick}
          />
        ) : (
          <></>
        )}
      </div>
      <Typography variant="p" className={css.contentWrapper}>
        {remark}
      </Typography>
    </div>
  );
};

export default memo(RemarkContainer);
