import { memo, useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { Typography } from '@components/base';
import { CancelOrder, MultiplePlaceholder, LinkButton } from '@components/common';
import { OrderItem } from '@helpers/types/order';
import { CLIENT_ROUTES } from '@router/routes';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  checkScreenType,
  getCommaSepratedValue,
  getFormattedDate,
  integerToWords
} from '@helpers/utils';
import { cancelOrderSchema } from '@helpers/yup/add-order.schema';
import { OrderInfo } from '@helpers/types/order';
import StepProgressBar from '@components/common/progress-bar/progress-bar-step';
import { Tooltip } from '@mui/material';
import { EVENT_TRACKING_TYPES, SCREEN_TYPE } from '@helpers/constants';
import RenderChildrenBasedOnScreen from 'src/hoc/screen-conditions';

interface OrderCardProps {
  readableOrderId: string;
  orderId: string;
  items: OrderItem[];
  customerName: string;
  createdBy?: any;
  currentState?: string;
  totalAmount?: string | null;
  orderInfo: OrderInfo;
  actions?: IActions;
}

interface ProgressBardState {
  labelList: any[];
  stepLabels: number[][];
  isCurrent: number;
}

const OrderCardCustomer = (props: OrderCardProps) => {
  const {
    readableOrderId,
    orderId,
    customerName,
    createdBy,
    currentState,
    totalAmount,
    orderInfo,
    actions
  } = props;

  const { currency } = orderInfo;
  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const [progressBarState, setProgressBarState] = useState<ProgressBardState>({
    labelList: [],
    stepLabels: [],
    isCurrent: 0
  });
  const { labelList, stepLabels, isCurrent } = progressBarState;
  const orderCancelForm = useForm<any>({
    resolver: yupResolver(cancelOrderSchema),
    defaultValues: {
      cancel_remarks: null
    },
    shouldUnregister: true
  });

  const itemName = useMemo(() => {
    const { items } = props;
    // const item = items.map((data) => data.supplier_product_grade?.product_grade?.product?.name);
    const item = items.map((data) => data.product_name);
    return <MultiplePlaceholder names={item} />;
  }, [props.items]);

  const supplier_names = useMemo(() => {
    const { items } = props;
    const name = items
      .filter((data) => data?.supplier_name !== null)
      .map((data) => data.supplier_name);
    return name;
  }, [props.items]);

  const supplierName = useMemo(() => {
    if (supplier_names && supplier_names.length > 0) {
      return <MultiplePlaceholder names={supplier_names} />;
    } else {
      return `-`;
    }
  }, [supplier_names]);

  const orderCreatedBy = useMemo(() => {
    if (createdBy?.length) {
      return createdBy;
    } else {
      return `-`;
    }
  }, [createdBy]);

  const cancelStatus = useMemo(() => {
    return {
      is_cancelled: orderInfo?.is_cancelled,
      cancel_remarks: orderInfo?.cancel_remarks,
      last_updated_by: orderInfo?.last_updated_by,
      updated_at: getFormattedDate(orderInfo?.updated_at)
    };
  }, [orderInfo]);

  useEffect(() => {
    if (orderInfo?.customer_state_activity_log) {
      let labels = orderInfo?.customer_state_activity_log.map((item: any) => {
        return {
          label: item.state_name,
          date:
            getFormattedDate(
              item.actual_date ? item.actual_date : item.estimated_date ? item.estimated_date : ''
            ) ?? ''
        };
      });
      let isCurrentState = orderInfo?.is_order_completed
        ? Number.MAX_VALUE
        : orderInfo?.customer_state_activity_log.findIndex(
            (item: any) => item.is_current_state === true
          );
      if (checkScreenType() === SCREEN_TYPE.mobile.label) {
        if (isCurrentState != 0 && isCurrentState != labels.length - 1) {
          labels = [labels[0], labels[isCurrentState], labels[labels.length - 1]];
          isCurrentState = 1;
        } else {
          labels = [labels[0], labels[labels.length - 1]];
          isCurrentState = isCurrentState == 0 ? 0 : 1;
        }
      }

      setProgressBarState({
        labelList: labels,
        stepLabels: stepLabels,
        isCurrent: isCurrentState
      });
    }
  }, [orderInfo]);

  return (
    <>
      <div className={css.mainWrapper}>
        <div>
          {cancelStatus.is_cancelled ? (
            <div className={css.cancelWrapper}>
              <div className={css.columnWrapper}>
                <Typography variant="p" className={css.cancelOrderTitle}>
                  This order has been cancelled on {cancelStatus.updated_at}
                </Typography>
                {/* <Typography variant="p">
                  {cancelStatus.cancel_remarks ?? 'No Cancel Remarks'}
                </Typography> */}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className={`${css.columnWrapper} ${cancelStatus.is_cancelled ? css.disable : ``}`}>
            <Typography variant="span">{orderInfo?.pi_document_no ?? '-'}</Typography>
            <div className={css.headerWrapper}>
              <RenderChildrenBasedOnScreen showOnWeb>
                <Typography variant="h3">
                  {orderInfo?.order_item?.length <= 1 ? orderInfo?.order_item?.join(', ') : <></>}
                  {orderInfo?.order_item?.length > 1 ? (
                    <div className={css.productNameWrapper}>
                      <Typography variant="h3">{orderInfo?.order_item[0]} and </Typography>
                      <Tooltip placement="right" title={orderInfo?.order_item.slice(1).join(', ')}>
                        <div className={css.productNameSecondPart}>
                          <Typography variant="h3">
                            {integerToWords(orderInfo?.order_item.length - 1)} more items
                          </Typography>
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    ''
                  )}
                </Typography>
              </RenderChildrenBasedOnScreen>
              <RenderChildrenBasedOnScreen showOnMobile>
                <Typography variant="h3" className={css.productName}>
                  {orderInfo?.order_item?.length <= 1 ? orderInfo?.order_item?.join(', ') : <></>}
                  {orderInfo?.order_item?.length > 1 ? (
                    <Typography variant="h3">
                      {orderInfo?.order_item[0]} and{' '}
                      {integerToWords(orderInfo?.order_item.length - 1)} more items
                    </Typography>
                  ) : (
                    ''
                  )}
                </Typography>
              </RenderChildrenBasedOnScreen>

              <div className={css.sideWrapper}>
                <div>
                  <Typography variant="ptext">PO Reference Number</Typography>
                  <Typography variant="body">{orderInfo?.po_reference_no ?? '-'}</Typography>
                </div>
                <div className={css.buttonWrapper}>
                  <LinkButton
                    variant="outlined-secondary"
                    to={`/${CLIENT_ROUTES.order}/${orderId}`}
                    title="View Order"
                    eventTrackingName={EVENT_TRACKING_TYPES.ORDER_VIEW_CLICK}>
                    View Order
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
          {cancelStatus.is_cancelled ? (
            <></>
          ) : (
            <>
              <div className={css.rowWrapper}>
                <div className={css.orderDetailWrapper}>
                  <div className={css.itemWrapper}>
                    {/* <IconNode
                      src={Images.personGrey}
                      alt="customer icon"
                      className={css.iconWrapper}
                    /> */}
                    <div className={css.detailWrapper}>
                      <Typography variant="ptext">Payment Terms</Typography>
                      <Typography variant="body">
                        {orderInfo?.payment_terms_display_value ?? '-'}
                      </Typography>
                    </div>
                  </div>

                  <div className={css.itemWrapper}>
                    {/* <IconNode
                      src={Images.personGrey}
                      alt="supplier icon"
                      className={css.iconWrapper}
                    /> */}
                    <div className={css.detailWrapper}>
                      <Typography variant="ptext">Due Payment/Total Payment</Typography>
                      <Typography variant="body">
                        {currency} {getCommaSepratedValue(orderInfo?.amount_due) ?? '-'}/{currency}{' '}
                        {getCommaSepratedValue(orderInfo?.total) ?? '-'}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={css.orderDetailWrapper}>
                  <div className={css.itemWrapper}>
                    <div className={css.detailWrapper}>
                      <Typography variant="ptext">Latest Payment Date</Typography>
                      <Typography variant="body">
                        {getFormattedDate(orderInfo?.latest_payment_date) ?? '-'}
                      </Typography>
                    </div>
                  </div>
                  {orderInfo?.customer_name && (
                    <div className={css.itemWrapper}>
                      <div className={css.detailWrapper}>
                        <Typography variant="ptext">Customer Name</Typography>
                        <Typography variant="body">{orderInfo?.customer_name ?? '-'}</Typography>
                      </div>
                    </div>
                  )}

                  <div className={css.itemWrapper}>
                    <div className={css.detailWrapper}>
                      <Typography variant="ptext">Elchemy Sales POC</Typography>
                      <Typography variant="body">
                        <MultiplePlaceholder names={orderInfo?.sales_reps ?? []} />
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div className={css.progressBarWrapper}>
                <StepProgressBar label={labelList} currentStep={isCurrent} />
              </div>
            </>
          )}
        </div>
      </div>
      <FormProvider {...orderCancelForm}>
        <CancelOrder
          open={cancelOrderModal}
          onClose={() => setCancelOrderModal(false)}
          orderId={orderId}
        />
      </FormProvider>
    </>
  );
};

export default memo(OrderCardCustomer);
