import css from './index.module.scss';
import { Button } from '@components/base';
import TypeOfOrderContainer from './type-of-order-container';
import { useFormContext } from 'react-hook-form';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';

interface TypeOfOrderFormProps {
  onCancelClick?: () => void;
  onFormSubmit: (data: any) => void;
  rectifyRemarks?: string;
}

const TypeOfOrderForm = (props: TypeOfOrderFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext();
  const { onCancelClick, onFormSubmit, rectifyRemarks } = props;
  useBeforeUnloadAndNavigate();

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <TypeOfOrderContainer rectifyRemarks={rectifyRemarks} />
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          Save and Proceed
        </Button>
      </div>
    </form>
  );
};

export default TypeOfOrderForm;
