import Images from '@assets/images';
import { IconNode, Typography } from '@components/base';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import { getFormattedDate } from '@helpers/utils';
import css from '../index.module.scss';

interface IPurchaseOrder {
  purchaseOrderData: PerformaInvoiceStates;
  setOrderState: (orderState: PerformaInvoiceStates) => void;
  po_reference_date: string;
}

const PurchaseDetails = (props: IPurchaseOrder) => {
  const { purchaseOrderData, setOrderState, po_reference_date } = props;
  const { purchaseOrder, orderDetails } = purchaseOrderData;
  const { editRed } = Images;
  return (
    <div className={css.boxWrapper}>
      <div className={css.purchaseBoxContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Purchase Order Details</Typography>
          <IconNode
            className={css.editRed}
            src={editRed}
            onClick={() =>
              setOrderState({
                ...purchaseOrderData,
                activeStep: 3
              })
            }
          />
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">PO Reference Number</Typography>
            <Typography variant="body">{purchaseOrder?.po_reference_number}</Typography>
          </div>
          <div>
            <Typography variant="label">PO Date</Typography>
            <Typography variant="body">{getFormattedDate(purchaseOrder?.document_date)}</Typography>
          </div>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Port of Destination</Typography>
            <Typography variant="body">
              {orderDetails?.port_of_discharge_display_value &&
              orderDetails?.port_of_discharge_country_display_value
                ? `${orderDetails?.port_of_discharge_display_value} - ${orderDetails?.port_of_discharge_country_display_value}`
                : ''}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Place of Delivery</Typography>
            <Typography variant="body">{orderDetails?.place_of_delivery}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseDetails;
