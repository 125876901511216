import css from './index.module.scss';
import Skeleton from '@components/base/skeleton';
import { Divider } from '@components/base';
import StepProgressBar from '../../progress-bar/progress-bar-step';

const OrderCardListing = () => {
  return (
    <>
      {Array.from({ length: 4 }).map((_, index) => (
        <OrderCard key={index} />
      ))}
    </>
  );
};

const OrderCard = () => {
  const labelList = [
    { label: '', date: '' },
    { label: '', date: '' },
    { label: '', date: '' },
    { label: '', date: '' },
    { label: '', date: '' },
    { label: '', date: '' },
    { label: '', date: '' }
  ];
  return (
    <div className={css.orderListingCardSkeletonWrapper}>
      <div className={css.orderListingCardSkeletonHeader}>
        <div className={css.orderListingCardSkeletonHeaderLeft}>
          <Skeleton animation="wave" width={'40%'} height={15} />
          <Skeleton animation="wave" width={'60%'} height={20} />
        </div>
        <div className={css.orderListingCardSkeletonHeaderRight}>
          <Divider className={css.divider} />
          <Skeleton animation="wave" width={'100%'} height={40} />
          <Divider className={css.divider} />
          <Skeleton animation="wave" width={'100%'} height={40} />
        </div>
      </div>
      <div className={css.orderListingCardSkeletonBody}>
        <div className={css.orderListingCardSkeletonBodyRow}>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={40} />
            <Skeleton animation="wave" width={'100%'} height={40} />
          </div>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={40} />
            <Skeleton animation="wave" width={'100%'} height={40} />
          </div>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={40} />
            <Skeleton animation="wave" width={'100%'} height={40} />
          </div>
        </div>
        <div className={css.orderListingCardSkeletonBodyRow}>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={40} />
            <Skeleton animation="wave" width={'100%'} height={40} />
          </div>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={40} />
            <Skeleton animation="wave" width={'100%'} height={40} />
          </div>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={40} />
            <Skeleton animation="wave" width={'100%'} height={40} />
          </div>
        </div>
      </div>
      <div className={css.orderListingCardSkeletonFooter}>
        <StepProgressBar label={labelList} currentStep={-1} />
      </div>
    </div>
  );
};

export default OrderCardListing;
export { OrderCard };
