import { Typography } from '@components/base';
import { PeopleType, USER_TYPES } from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import { IPeopleStates } from '@helpers/types/people';
import { getPocs } from '@services/order.service';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserCard from './components/user-card';
import css from './index.module.scss';
import { LEADERSHIP_EMAIL, SUPPORT_EMAIL, getPermissionFromLocalStorage } from '@helpers/utils';
import _ from 'lodash';
import AccessWrapper from '@authorization/access-wrapper';
import Skeleton from '@components/base/skeleton';
import { Card, CardHeader } from '@mui/material';

const OrderPeopleTab = () => {
  const params = useParams();
  const [peopleState, setPeopleState] = useState<IPeopleStates>({
    peopleList: [],
    isLoading: false
  });

  const { peopleList, isLoading } = peopleState;
  const userType = useMemo(() => getPermissionFromLocalStorage()?.user_type, []);

  useEffect(() => {
    (async () => {
      setPeopleState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
      const response = await getPocs(params.id as string);
      if (response.success) {
        let peopleData: any = [];
        if (userType == USER_TYPES.customer) {
          response?.data?.forEach((val: any) => {
            const sameTeam = peopleData?.find((x: any) => x.internal_team === val.internal_team);
            if (sameTeam) {
              sameTeam.user = _.uniqBy([...sameTeam.user, ...val.user], (u: any) => u.user_id);
            } else {
              peopleData.push(val);
            }
          });
        } else {
          peopleData = response.data;
        }

        setPeopleState((prevState) => ({
          ...prevState,
          peopleList: peopleData
        }));
      } else {
        notify({
          message: response?.error ?? 'Unable to fetch data',
          severity: 'error',
          dismissible: true
        });
      }
      setPeopleState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
    })();
  }, []);

  if (isLoading) return <PeopleSkeleton />;

  return (
    <div className={css.contentWrapper}>
      <AccessWrapper show={true} showTo={[USER_TYPES.customer]} byPassShowWithShowTo>
        <div className={css.message}>
          <Typography variant="span" className={css.messageText}>
            If you face any issues please contact{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`} className={css.emailLink}>
              {SUPPORT_EMAIL}
            </a>
            . Incase of any escalations please reach out to{' '}
            <a href={`mailto:${LEADERSHIP_EMAIL}`} className={css.emailLink}>
              {LEADERSHIP_EMAIL}
            </a>
          </Typography>
        </div>
      </AccessWrapper>

      {peopleList?.map((val, index) => {
        return (
          <React.Fragment key={index}>
            <div className={css.titleWrapper}>
              <Typography variant="h4" className={css.primaryTitle}>
                {val.internal_team}
              </Typography>
              <AccessWrapper show={true} showTo={[USER_TYPES.internal]} byPassShowWithShowTo>
                <Typography variant="p" className={css.secondaryText}>
                  {PeopleType(val?.poc_type)}
                </Typography>
              </AccessWrapper>
            </div>
            <div className={css.textWrapper} key={index}>
              <UserCard userInfo={val?.user} />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default OrderPeopleTab;

const PeopleSkeleton = () => {
  const userType = getPermissionFromLocalStorage()?.user_type;
  const isInternal = userType === USER_TYPES.internal;
  return isInternal ? (
    <div className={css.peopleSkeletonWrapper}>
      <InternalPeopleSkeleton />
      <InternalPeopleSkeleton />
      <InternalPeopleSkeleton />
      <InternalPeopleSkeleton />
    </div>
  ) : (
    <div className={css.peopleSkeletonWrapper}>
      <Skeleton animation="wave" width={'100%'} height={20} />
      <ExternalPeopleSkeleton />
      <ExternalPeopleSkeleton />
      <ExternalPeopleSkeleton />
      <ExternalPeopleSkeleton />
    </div>
  );
};

const InternalPeopleSkeleton = () => {
  return (
    <div className={css.peopleTabContainer}>
      <Skeleton animation="wave" width={'150px'} height={20} />
      <Skeleton animation="wave" width={'200px'} height={20} />
      <div className={css.cardWrapper}>
        <Card sx={{ maxWidth: 345, minWidth: 250 }}>
          <CardHeader
            avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
            title={
              <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
            }
            subheader={<Skeleton animation="wave" height={10} width="40%" />}
          />
        </Card>
        <Card sx={{ maxWidth: 345, minWidth: 250 }}>
          <CardHeader
            avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
            title={
              <Skeleton animation="wave" height={10} width="40%" style={{ marginBottom: 6 }} />
            }
            subheader={<Skeleton animation="wave" height={10} width="80%" />}
          />
        </Card>
      </div>
    </div>
  );
};

const ExternalPeopleSkeleton = () => {
  return (
    <div className={css.peopleTabContainer}>
      <Skeleton animation="wave" width={'150px'} height={20} />
      <div className={css.cardWrapper}>
        <Card sx={{ maxWidth: 345, minWidth: 250 }}>
          <CardHeader
            avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
            title={
              <Skeleton animation="wave" height={10} width="40%" style={{ marginBottom: 6 }} />
            }
            subheader={<Skeleton animation="wave" height={10} width="80%" />}
          />
        </Card>
        <Card sx={{ maxWidth: 345, minWidth: 250 }}>
          <CardHeader
            avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
            title={
              <Skeleton animation="wave" height={10} width="40%" style={{ marginBottom: 6 }} />
            }
            subheader={<Skeleton animation="wave" height={10} width="80%" />}
          />
        </Card>
      </div>
    </div>
  );
};
