import React, { useState, useContext, useRef } from 'react';
import { Typography } from '@components/base';
import { SideBar } from '@components/common';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import OrderDetails from './components/order-details';
import ItemDetails from './components/item-details';
import AdditionalData from './components/additional-data';
import Preview from './components/preview';
import { orderDetailsSchema, additionalDetailsSchema } from '@helpers/yup/generate-coa.schema';
import {
  COAFormState,
  OrderDetailsForm,
  ItemDetailForm,
  AdditionalDetails
} from '@helpers/types/coa';
import { AppContext } from '@helpers/hooks/AppContext';
import { generateCOA } from '@services/task.service';
import notify from '@helpers/toastify-helper';
import { CLIENT_ROUTES } from '@router/routes';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@components/base';
import css from './index.module.scss';
import { useScrollToTop } from '@helpers/hooks';

const COA = () => {
  const [formState, setFormState] = useState<COAFormState>({
    activeStep: 0,
    isLoading: false,
    orderDetails: [],
    itemDetails: {},
    additionalDetails: {
      notes: [],
      description: '',
      remarks: []
    }
  });

  const { appState } = useContext(AppContext);
  const { COAData } = appState;
  const navigate = useNavigate();
  const topRef = useRef(null);

  const { activeStep, isLoading } = formState;

  const orderDetailsForm = useForm<OrderDetailsForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(orderDetailsSchema),
    defaultValues: {
      order_details: []
    }
  });

  const itemDetailForm = useForm<ItemDetailForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // resolver: yupResolver(orderDetailsSchema),
    defaultValues: {
      item_details: []
    }
  });

  const additionalDetailsForm = useForm<AdditionalDetails>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(additionalDetailsSchema),
    defaultValues: {
      notes: [
        {
          field_value: ''
        }
      ],
      remarks: [
        {
          field_value: ''
        }
      ],
      description: null
    }
  });

  const handleNavigation = (currentStep: number) => {
    setFormState((prevState: any) => ({
      ...prevState,
      activeStep: currentStep
    }));
  };

  const handleSidebarClick = (value: number) => {
    handleNavigation(value);
  };

  const handleSidebarEnterKey = (value: number) => {
    handleNavigation(value);
  };

  const handleOrderDetailsSubmit = (data: OrderDetailsForm) => {
    setFormState((prevState: any) => ({
      ...prevState,
      orderDetails: data.order_details,
      activeStep: prevState.activeStep + 1
    }));
  };

  const handleItemDetailFormSubmit = (data: ItemDetailForm) => {
    setFormState((prevState: any) => ({
      ...prevState,
      itemDetails: data,
      activeStep: prevState.activeStep + 1
    }));
  };

  const handleAdditionalDetailsFormSubmit = (data: AdditionalDetails) => {
    setFormState((prevState: any) => ({
      ...prevState,
      additionalDetails: data,
      activeStep: prevState.activeStep + 1
    }));
  };

  const handleCancelClick = () => {
    navigate(`/${CLIENT_ROUTES.order}/${COAData?.order_id as string}`);
  };

  const coaFormSubmitHandler = async () => {
    const body = {
      order_details: formState.orderDetails.map((item: any, index: number) => {
        return {
          ...item,
          sequence: index
        };
      }),
      table_details: {
        column_data: formState.itemDetails?.item_details_skeleton?.length
          ? formState.itemDetails?.item_details_skeleton?.map((item: any, index: number) => {
              return {
                field_name: item.field_name,
                index
              };
            })
          : null,
        row_data: formState.itemDetails?.item_details?.length
          ? formState.itemDetails?.item_details
          : null
      },
      additional_data: {
        notes:
          formState.additionalDetails?.notes?.length &&
          formState.additionalDetails?.notes[0]?.field_value?.length
            ? {
                bullet_points: formState.additionalDetails?.notes.map(
                  (item: any, index: number) => {
                    return {
                      content: item.field_value,
                      sequence: index
                    };
                  }
                )
              }
            : null,
        description: formState.additionalDetails?.description?.length
          ? formState.additionalDetails?.description
          : null,
        remarks:
          formState.additionalDetails?.remarks?.length &&
          formState.additionalDetails?.remarks[0]?.field_value?.length
            ? {
                bullet_points: formState.additionalDetails?.remarks.map(
                  (item: any, index: number) => {
                    return {
                      content: item.field_value,
                      sequence: index
                    };
                  }
                )
              }
            : null
      },
      order_item_id: COAData?.order_item_details?.order_item_id
    };
    try {
      setFormState((prevState: any) => ({
        ...prevState,
        isLoading: true
      }));
      const response: any = await generateCOA(COAData?.task_id, body);
      setFormState((prevState: any) => ({
        ...prevState,
        isLoading: false
      }));
      if (response?.success) {
        notify({
          message: 'COA generated successfully'
        });
        handleCancelClick();
      } else {
        notify({
          title: 'Error',
          message: response?.error ?? 'Something went wrong!',
          severity: 'error'
        });
      }
    } catch (err) {
      notify({
        title: 'Error',
        message: 'Something went wrong!',
        severity: 'error'
      });
    }
  };

  useScrollToTop({ topRef, dependencyArray: [activeStep] });

  return (
    <main className={css.coaWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Generate Certificate of Analysis</Typography>
        <Typography variant="subheading1">0{activeStep + 1} of 04</Typography>
      </div>
      <div className={css.coaContainer} ref={topRef}>
        <div className={css.sideBarWrapper}>
          <SideBar
            activeStep={activeStep}
            onClick={handleSidebarClick}
            onEnter={handleSidebarEnterKey}>
            <SideBar.Item label="Order Details" value={0} />
            <SideBar.Item label="Item Details" value={1} disabled={activeStep! <= 1} />
            <SideBar.Item label="Additional Details" value={2} disabled={activeStep! <= 2} />
            <SideBar.Item label="Preview" value={3} disabled={activeStep! <= 3} />
          </SideBar>
        </div>
        {activeStep === 0 && (
          <FormProvider {...orderDetailsForm}>
            <OrderDetails
              onFormSubmit={handleOrderDetailsSubmit}
              handleCancelClick={handleCancelClick}
              orderItem={COAData?.order_item_details}
            />
          </FormProvider>
        )}
        {activeStep === 1 && (
          <FormProvider {...itemDetailForm}>
            <ItemDetails
              onFormSubmit={handleItemDetailFormSubmit}
              handleCancelClick={handleCancelClick}
            />
          </FormProvider>
        )}
        {activeStep === 2 && (
          <FormProvider {...additionalDetailsForm}>
            <AdditionalData
              onFormSubmit={handleAdditionalDetailsFormSubmit}
              handleCancelClick={handleCancelClick}
            />
          </FormProvider>
        )}
        {activeStep === 3 && (
          <Preview
            data={formState}
            handleBackClick={handleNavigation}
            onSubmit={coaFormSubmitHandler}
            handleCancelClick={handleCancelClick}
            handleNavigation={handleNavigation}
          />
        )}
      </div>
      <Loader open={isLoading} />
    </main>
  );
};

export default COA;
