import css from './index.module.scss';
import { Button, IconNode, Typography } from '@components/base';
import Images from '@assets/images';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectLabel, TextField } from '@components/common';
import { CURRENCY, UNITS_OF_WEIGHTS } from '@helpers/constants';

interface ICreateEnquiryDataFieldsProps {
  index: number;
  name: string;
  currency: string;
  target_price: string;
  quantity: string;
  unit: string;
  showDelete: boolean;
  onDeleteClick: () => void;
}

const CreateEnquiryDataFields = (props: ICreateEnquiryDataFieldsProps) => {
  const { index, name, currency, target_price, quantity, unit, showDelete, onDeleteClick } = props;

  const { control } = useFormContext();

  return (
    <div className={css.enquiryProductContainer}>
      <div className={css.headerWrapper}>
        <Typography variant="h4">Product {index + 1}</Typography>
        {showDelete && (
          <Button
            variant="text"
            title="Delete Product"
            onClick={onDeleteClick}
            startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}>
            Delete
          </Button>
        )}
      </div>
      <div className={css.enquiryProducts}>
        <div className={css.enquiryProductsName}>
          <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                label={`Product Name`}
                placeholder="Enter Product Name"
                error={fieldState.invalid}
                rootClassName={`${css.productNameFieldSpacing}`}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.enquiryProductsPriceQuantity}>
        <div className={css.enquiryProductsPrice}>
          <Controller
            name={currency}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                label="Currency"
                options={CURRENCY}
                placeholder="Select"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name={target_price}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={`Target Price`}
                placeholder="Enter Target Price"
                error={fieldState.invalid}
                rootClassName={`${css.fieldSpacing}`}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.enquiryProductsQuantity}>
          <Controller
            name={unit}
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                label="Unit"
                options={UNITS_OF_WEIGHTS}
                placeholder="Select"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name={quantity}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Quantity"
                placeholder="Enter Quantity"
                error={fieldState.invalid}
                rootClassName={`${css.fieldSpacing}`}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEnquiryDataFields;
