import { Typography } from '@components/base';
import css from './index.module.scss';
import { IBLDetails, IFilteredValue, IOrderDetails } from '../../../orderOverview';
import { getFormattedDate } from '@helpers/utils';

interface IBLDetailsProps {
  previewData: {
    blDetails: IBLDetails;
    orderDetails: IOrderDetails;
  };
  filteredValue?: IFilteredValue;
}

const BLDetails = (props: IBLDetailsProps) => {
  const { previewData, filteredValue } = props;
  const { blDetails, orderDetails } = previewData;
  const { modeOfTransportation } = orderDetails;

  const addAdditionalClass = (key: string) => {
    return filteredValue?.key == key ? css.filtered : '';
  };

  return (
    <div className={css.detailWrapper}>
      <div className={css.detailsTitle}>
        <Typography variant="h4">BL Details</Typography>
      </div>
      <div className={css.detailContainer}>
        <div className={css.rowWrapper}>
          {modeOfTransportation === 'Air' && (
            <div className={`${css.fieldWrapper} ${addAdditionalClass('awbServiceName')}`}>
              <Typography
                variant="label"
                className={`${css.fieldLabel} ${addAdditionalClass('awbServiceName')}`}>
                {modeOfTransportation === 'Air' && 'AWB Service Name'}
              </Typography>
              {blDetails?.awbServiceName ? (
                <Typography variant="body" className={`${addAdditionalClass('awbServiceName')}`}>
                  {blDetails?.awbServiceName}
                </Typography>
              ) : (
                <Typography
                  variant="label"
                  className={`${css.notAvailable} ${addAdditionalClass('awbServiceName')}`}>
                  Not Available
                </Typography>
              )}
            </div>
          )}
          <div className={`${css.fieldWrapper} ${addAdditionalClass('blNumber')}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass('blNumber')}`}>
              {modeOfTransportation === 'Air' ? 'AWB Number' : 'BL Number'}
            </Typography>
            {blDetails?.blNumber ? (
              <Typography
                variant="body"
                className={`${css.fieldWrapper} ${addAdditionalClass('blNumber')}`}>
                {blDetails?.blNumber}
              </Typography>
            ) : (
              <Typography
                variant="label"
                className={`${css.notAvailable} ${addAdditionalClass('blNumber')}`}>
                Not Available
              </Typography>
            )}
          </div>
          <div className={`${css.fieldWrapper} ${addAdditionalClass('blDate')}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass('blDate')}`}>
              {modeOfTransportation === 'Air' ? 'AWB Date' : 'BL Date'}
            </Typography>
            <div className={css.fieldValueContainer}>
              {blDetails?.blDate ? (
                <Typography variant="body" className={`${addAdditionalClass('blDate')}`}>
                  {getFormattedDate(blDetails?.blDate)}
                </Typography>
              ) : (
                <Typography
                  variant="label"
                  className={`${css.notAvailable} ${addAdditionalClass('blDate')}`}>
                  Not Available
                </Typography>
              )}
            </div>
          </div>
        </div>

        {blDetails?.courier?.serviceName && (
          <>
            <div className={css.rowWrapper}>
              <div className={`${css.fieldWrapper} ${addAdditionalClass('courier.serviceName')}`}>
                <Typography
                  variant="label"
                  className={`${css.fieldLabel} ${addAdditionalClass('courier.serviceName')}`}>
                  Courier Service Name
                </Typography>
                {blDetails?.courier?.serviceName ? (
                  <Typography
                    variant="body"
                    className={`${addAdditionalClass('courier.serviceName')}`}>
                    {blDetails?.courier?.serviceName}
                  </Typography>
                ) : (
                  <Typography
                    variant="label"
                    className={`${css.notAvailable} ${addAdditionalClass('courier.serviceName')}`}>
                    Not Available
                  </Typography>
                )}
              </div>
              <div
                className={`${css.fieldWrapper} ${addAdditionalClass('courier.trackingNumber')}`}>
                <Typography
                  variant="label"
                  className={`${css.fieldLabel} ${addAdditionalClass('courier.trackingNumber')}`}>
                  Courier Tracking Number
                </Typography>
                <div className={css.fieldValueContainer}>
                  {blDetails?.courier?.trackingNumber ? (
                    <Typography
                      variant="body"
                      className={`${addAdditionalClass('courier.trackingNumber')}`}>
                      {blDetails?.courier?.trackingNumber}
                    </Typography>
                  ) : (
                    <Typography
                      variant="label"
                      className={`${css.notAvailable} ${addAdditionalClass(
                        'courier.trackingNumber'
                      )}`}>
                      Not Available
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={`${css.fieldWrapper} ${addAdditionalClass('courier.courierDate')}`}>
                <Typography
                  variant="label"
                  className={`${css.fieldLabel} ${addAdditionalClass('courier.courierDate')}`}>
                  Courier Date
                </Typography>
                {blDetails?.courier?.courierDate ? (
                  <Typography
                    variant="body"
                    className={`${addAdditionalClass('courier.courierDate')}`}>
                    {getFormattedDate(blDetails?.courier?.courierDate)}
                  </Typography>
                ) : (
                  <Typography
                    variant="label"
                    className={`${css.notAvailable} ${addAdditionalClass('courier.courierDate')}`}>
                    Not Available
                  </Typography>
                )}
              </div>
              <div
                className={`${css.fieldWrapper} ${addAdditionalClass('courier.placeOfDelivery')}`}>
                <Typography
                  variant="label"
                  className={`${css.fieldLabel} ${addAdditionalClass('courier.placeOfDelivery')}`}>
                  Courier Place of Delivery
                </Typography>
                <div className={css.fieldValueContainer}>
                  {blDetails?.courier?.placeOfDelivery ? (
                    <Typography
                      variant="body"
                      className={`${addAdditionalClass('courier.placeOfDelivery')}`}>
                      {blDetails?.courier?.placeOfDelivery}
                    </Typography>
                  ) : (
                    <Typography
                      variant="label"
                      className={`${css.notAvailable} ${addAdditionalClass(
                        'courier.placeOfDelivery'
                      )}`}>
                      Not Available
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {blDetails?.telex?.telexMessage && (
          <div className={css.rowWrapper}>
            <div className={`${css.fieldWrapper} ${addAdditionalClass('telex.telexMessage')}`}>
              <Typography
                variant="label"
                className={`${css.fieldLabel} ${addAdditionalClass('telex.telexMessage')}`}>
                Telex Message
              </Typography>
              <div
                className={`${css.fieldValueContainer} ${addAdditionalClass('telex.telexMessage')}`}
                dangerouslySetInnerHTML={{ __html: blDetails.telex.telexMessage }}
              />
            </div>
            {
              <div className={`${css.fieldWrapper} ${addAdditionalClass('telex.telexRemarks')}`}>
                <Typography
                  variant="label"
                  className={`${css.fieldLabel} ${addAdditionalClass('telex.telexRemarks')}`}>
                  Telex Message Remarks
                </Typography>
                {blDetails.telex.telexRemarks ? (
                  <Typography
                    variant="body"
                    className={`${addAdditionalClass('telex.telexRemarks')}`}>
                    {blDetails.telex.telexRemarks}
                  </Typography>
                ) : (
                  <Typography
                    variant="label"
                    className={`${css.notAvailable} ${addAdditionalClass('telex.telexRemarks')}`}>
                    Not Available
                  </Typography>
                )}
              </div>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default BLDetails;
