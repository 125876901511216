import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { DOCUMENT_GENERATION_STATUS } from '@helpers/constants';
import { AppContext } from '@helpers/hooks/AppContext';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer';
import css from './index.module.scss';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { Link } from 'react-router-dom';

interface ISICard {
  taskData: Partial<ITaskTabProps>;
}

const GenerateSICard = (props: ISICard) => {
  const navigate = useNavigate();
  const { appDispatch } = useContext(AppContext);
  const { taskData } = props;
  const docGenerationStatus = useMemo(() => {
    if (taskData?.si_generation_status as any) {
      return DOCUMENT_GENERATION_STATUS.find((i) => i.value === taskData?.si_generation_status)
        ?.label;
    }
    return null;
  }, [taskData]);
  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskData?.is_completed ? (
                <Avatar src={Images.illustrationEdit} alt="" />
              ) : (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              )}

              <Link to={`/order/${taskData?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  #{taskData?.readable_task_id} - Generate Shipping Instructions
                </Typography>
              </Link>
            </div>
            <div className={css.pocWrapper}>
              {!taskData?.is_completed && !docGenerationStatus && (
                <Button
                  variant="text"
                  onClick={() => {
                    appDispatch({
                      type: 'setSIData',
                      payload: { tasksData: taskData }
                    });
                    navigate(`/order/generate-shipping-instructions/${taskData?.order}/${taskData?.task_id}`);
                  }}
                  className={css.assignButton}>
                  Generate Form
                </Button>
              )}
              {!taskData?.is_completed &&
                (docGenerationStatus == 'DOC_GENERATION_PROCESSING' ||
                  docGenerationStatus == 'DOC_GENERATION_REQUESTED') && (
                  <Button variant="text" disabled className={css.assignButton}>
                    Document generation in process
                  </Button>
                )}
              {!taskData?.is_completed && docGenerationStatus == 'DOC_GENERATION_FAILED' && (
                <Button variant="text" disabled className={css.assignButton}>
                  Document Generation Failed
                </Button>
              )}
            </div>
          </div>
        </Accordion.Header>
      </Accordion>
      <Footer createdDate={taskData.created_at as Date} updatedDate={taskData.updated_at as Date} />
    </div>
  );
};

export default GenerateSICard;
