import { Modal } from '@components/base';
import { Button } from '@components/base';
import css from './index.module.scss';
import { Typography } from '@components/base';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';
import { openDocumentSignedLink } from '@helpers/utils';
import { DocToBeRegeneratedObject } from '../..';

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  docToBeRegenerated: DocToBeRegeneratedObject[];
}

function ConfirmationModal(props: ConfirmationModalProps) {
  const { open, onClose, onConfirm, docToBeRegenerated } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          {' '}
          <Typography variant="h4">Edit Order Confirmation</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <section className={css.formWrapper}>
          <div className={css.modalContent}>
            <Typography variant="h5" className={css.confirmHeader}>
              {docToBeRegenerated?.length
                ? `Following Documents will be regenerated. Click on Confirm to Proceed`
                : `Are you sure you want to edit this order?`}
            </Typography>
            <ul className={css.documentList}>
              {docToBeRegenerated.map((doc: any) => (
                <li key={doc?.order_document_id}>
                  <Typography variant="p" key={doc.id}>
                    {doc.document_type_display_value} - {doc.display_name}
                  </Typography>
                  <span onClick={() => openDocumentSignedLink(doc?.document ?? '')}>View</span>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section className={css.modalFooter}>
          <Button variant="outlined-secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" onClick={onConfirm}>
            Confirm
          </Button>
        </section>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
