import { ChangeEvent, useCallback, useRef, useState } from 'react';
import css from './index.module.scss';
import { Button, Divider, IconNode, Typography } from '@components/base';
import { TextField, TextAreaLabel } from '@components/common';
import { Controller, useFormContext } from 'react-hook-form';
import Images from '@assets/images';
import { ProductInfo } from '@helpers/types/product';
import { fetchAllProducts } from '@services/product.service';
import debounce from 'lodash.debounce';
import { GradeInfo } from '@helpers/types/grade';
import notify from '@helpers/toastify-helper';

interface GradeFieldsStates {
  isLoading: boolean;
  isOpen: boolean;
  options: string[];
}

interface GradeFieldsProps {
  gradeId?: string;
  name: string;
  description: string;
  packagingDetails: string;
  remarks: string;
  index: number;
  showDeleteButton: boolean;
  onDelete?: () => void;
}

const GradeFields = (props: GradeFieldsProps) => {
  const {
    gradeId,
    index,
    name,
    description,
    packagingDetails,
    remarks,
    showDeleteButton,
    onDelete
  } = props;

  const [gradeStates, setGradeStates] = useState<GradeFieldsStates>({
    isLoading: false,
    isOpen: false,
    options: []
  });
  const debounceRef = useRef<any>(null);

  const { options, isLoading, isOpen } = gradeStates;

  const { control } = useFormContext();

  const handleChange = async (value: string) => {
    const response = await fetchAllProducts(undefined, value);
    if (response.success) {
      const { data } = response;
      let options: string[] = [];
      data.results.forEach((product: ProductInfo) => {
        options = product.grades.map((grade) => grade.name);
      });
      setGradeStates((prevState) => ({
        ...prevState,
        isLoading: false,
        options: options,
        isOpen: true
      }));
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else {
      notify({ message: 'Unable to fetch product list', severity: 'error' });
    }
  };

  const handleOutSideClick = useCallback(() => {
    setGradeStates((prevState) => ({ ...prevState, isOpen: false, isLoading: false }));
  }, []);

  return (
    <div className={css.mainWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="h4">Grade {index}</Typography>
        {showDeleteButton && (
          <Button
            disabled={!!gradeId}
            variant="text"
            onClick={onDelete}
            startIcon={<IconNode src={Images.deleteRed} alt="add icon" />}>
            Delete
          </Button>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            field.onChange(value);
            setGradeStates((prevState) => ({ ...prevState, isLoading: true }));
            debounceRef.current?.cancel();
            debounceRef.current = debounce(handleChange, 800);
            debounceRef.current(value.trim());
          };

          const handleOptionSelection = (value: string) => {
            field.onChange(value);
            setGradeStates((prevState) => ({ ...prevState, isOpen: false }));
          };

          return (
            <TextField
              {...field}
              required
              autoFocus
              type="text"
              inputMode="text"
              enterKeyHint="next"
              label="Grade"
              placeholder="Enter grade"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          );
        }}
      />
      <Controller
        name={description}
        control={control}
        render={({ field }) => (
          <TextAreaLabel
            {...field}
            inputMode="text"
            rows={5}
            label="Description"
            placeholder="Enter additional remarks/ requirements"
            rootClassName={css.fieldWrapper}
          />
        )}
      />
      <Controller
        name={packagingDetails}
        control={control}
        render={({ field }) => (
          <TextAreaLabel
            {...field}
            inputMode="text"
            rows={5}
            label="Packaging Details"
            placeholder="Enter packaging details"
            rootClassName={css.fieldWrapper}
          />
        )}
      />
      <Controller
        name={remarks}
        control={control}
        render={({ field }) => (
          <TextAreaLabel
            {...field}
            inputMode="text"
            rows={5}
            label="Additional Remarks"
            placeholder="Enter additional remarks"
            rootClassName={css.fieldWrapper}
          />
        )}
      />
      <Divider className={css.divider} />
    </div>
  );
};

export default GradeFields;
