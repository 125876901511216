import Typography from '@components/base/typography';
import css from './index.module.scss';
import { BreadCrumb, Button, Chip, Divider } from '@components/base';
import { getCommaSepratedValue, getFormattedDate, sanitizeValue } from '@helpers/utils';
import RenderChildrenBasedOnScreen from 'src/hoc/screen-conditions';
import { CLIENT_ROUTES } from '@router/routes';
import { Enquiry, IRFQProduct } from '@helpers/types/enquiry';

interface IViewEnquiryProps {
  data: Enquiry;
  handleDocumentDownload: (enquiryId: string) => void;
  handleCancelClick: () => void;
}

const ViewEnquiry = (props: IViewEnquiryProps) => {
  const { data, handleDocumentDownload, handleCancelClick } = props;
  return (
    <div className={css.viewEnquiryContainer}>
      <RenderChildrenBasedOnScreen showOnMobile>
        <BreadCrumb>
          <BreadCrumb.Item to={`/${CLIENT_ROUTES.enquiry}`} value={0} onClick={handleCancelClick}>
            Enquiry List
          </BreadCrumb.Item>
          <BreadCrumb.Item to={`/${CLIENT_ROUTES.enquiry}?activestep=viewEnquiry`} value={1}>
            {data?.readable_id}
          </BreadCrumb.Item>
        </BreadCrumb>
      </RenderChildrenBasedOnScreen>
      <RenderChildrenBasedOnScreen showOnWeb>
        <Typography variant="h3">Product Details</Typography>
      </RenderChildrenBasedOnScreen>
      <div className={css.viewEnquiryWrapper}>
        <div className={css.viewEnquiryIdDateStatus}>
          <div className={css.viewEnquiryIdDate}>
            <Typography variant="body">{sanitizeValue(data?.readable_id)}</Typography>
            <Divider className={css.enquiryDivider} />
            <Typography variant="body">
              {sanitizeValue(getFormattedDate(data.created_at), 'Date Not Available')}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Status</Typography>
            <Chip
              label={sanitizeValue(data.status_display_value)}
              variant={data.is_completed ? 'filled-green' : 'filled-blue'}
            />
          </div>
        </div>
        {data?.products.map((item: IRFQProduct, index: number) => (
          <div key={index} className={css.viewEnquiryProducts}>
            <div>
              <Typography variant="label">Chemical Name</Typography>
              <Typography variant="body">{sanitizeValue(item.name)}</Typography>
            </div>
            <div>
              <Typography variant="label">Price</Typography>
              <Typography variant="body">
                {item.currency} {sanitizeValue(getCommaSepratedValue(item.target_price))}
              </Typography>
            </div>
            <div>
              <Typography variant="label">Quantity</Typography>
              <Typography variant="body">
                {item.quantity} {sanitizeValue(item.unit)}
              </Typography>
            </div>
          </div>
        ))}
        <div className={css.viewEnquiryPlaceOfDelivery}>
          <Typography variant="label">Place Of Delivery</Typography>
          <Typography variant="body">{sanitizeValue(data.place_of_delivery)}</Typography>
        </div>
        {data?.documents && data?.documents.length > 0 && (
          <div className={css.viewEnquiryDownload}>
            <Typography variant="label">
              Click here to download all documents related to this RFQ in zip format
            </Typography>
            <Button
              variant="outlined-secondary"
              className={css.viewEnquiryDownloadButton}
              onClick={() => handleDocumentDownload(data?.id)}>
              Download
            </Button>
          </div>
        )}
        {data?.remarks.length > 0 && (
          <div className={css.viewEnquiryRemarksWrapper}>
            <Typography variant="h4">Additional Comments</Typography>
            <div className={css.viewEnquiryRemarks}>
              <Typography variant="body">{sanitizeValue(data?.remarks)}</Typography>
            </div>
          </div>
        )}
      </div>
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ViewEnquiry;
