import CustomerCard from './customer';
import InternalCard from './internal';
import { USER_TYPES } from '@helpers/constants';
import AccessWrapper from 'src/authorization/access-wrapper';

const OrderCard = (props: any) => {
  const { actions } = props;
  return (
    <>
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.customer]}>
        <CustomerCard {...props} />
      </AccessWrapper>
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]}>
        <InternalCard {...props} />
      </AccessWrapper>
    </>
  );
};

export default OrderCard;
