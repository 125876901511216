import AccessWrapper from '@authorization/access-wrapper';
import css from './index.module.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InfiniteScroll, ListPlaceholder, Seo } from '@components/common';
import { Badge, Button, Typography } from '@components/base';
import { Enquiry, FormType, ICustomerEnquiryHomeProps } from '@helpers/types/enquiry';
import { useSearchParams } from 'react-router-dom';
import CustomerEnquiryCard from './components/enquiry-card/customer';
import ViewEnquiry from './components/viewEnquiry';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import CreateEnquiryForm from './components/createEnquiry';
import Images from '@assets/images';
import { downloadDocuments, getEnquiryList } from '@services/enquiry.service';
import notify from '@helpers/toastify-helper';
import { enquiryDetailsSchema } from '@helpers/yup/add.customer-enquiry.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { createCustomerEnquiry } from '@services/customer.service';
import { MAX_FILES_ALLOWED, USER_TYPES } from '@helpers/constants';
import EnquirySkeleton from '@components/common/skeletons/customer-enquiry';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileSaver = require('file-saver');

const CustomerEnquiry = (props: any) => {
  const { actions } = props.modulePermissions;
  const rootContainer = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialActiveStep = useMemo(() => {
    const activeStepSearchParams = searchParams.get('activestep');
    return activeStepSearchParams === 'placeRFQ' ? 2 : 0;
  }, [searchParams]);
  const [enquiryState, setEnquiryState] = useState<ICustomerEnquiryHomeProps>({
    activeStep: initialActiveStep,
    isLoading: false,
    currentPageNumber: 1,
    totalEnquiryCount: 0,
    data: [],
    viewEnquiryData: {} as Enquiry,
    showEnquiryListing: initialActiveStep === 2 ? false : true,
    hasNext: false,
    retry: false
  });

  const {
    isLoading,
    currentPageNumber,
    totalEnquiryCount,
    data,
    activeStep,
    viewEnquiryData,
    showEnquiryListing,
    hasNext,
    retry
  } = enquiryState;

  const addEnquiryForm = useForm<FormType>({
    resolver: yupResolver(enquiryDetailsSchema),
    defaultValues: {
      place_of_delivery: '',
      document: [],
      remarks: '',
      products: [
        {
          name: '',
          currency: null,
          target_price: '',
          quantity: '',
          unit: null
        }
      ]
    }
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = addEnquiryForm;

  const getAllEnquiries = async (params: number, append = true) => {
    if (isLoading === true) return;
    setEnquiryState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await getEnquiryList({
      page: params ?? 1
    });
    if (response.success) {
      setEnquiryState((prevState) => ({
        ...prevState,
        totalEnquiryCount: response.data.count,
        data: append ? [...prevState.data, ...response.data.results] : response.data.results,
        hasNext: !!response.data.next,
        retry: false,
        currentPageNumber: params,
        isLoading: false
      }));
    } else if (response.error) {
      setEnquiryState((prevState) => ({ ...prevState, retry: true, isLoading: false }));
      notify({
        message: response.error ?? 'Something Went Wrong, Contact Tech Team'
      });
      return;
    }
  };

  useEffect(() => {
    getAllEnquiries(1);
  }, []);

  const handleViewCustomerEnquiry = (enquiryData: string) => {
    setEnquiryState((prevState) => ({
      ...prevState,
      activeStep: 1,
      showEnquiryListing: false,
      viewEnquiryData: data.find((item) => item.id == enquiryData) as Enquiry
    }));
  };

  const handleDocumentZipDownload = async (enquiryId: string) => {
    setEnquiryState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await downloadDocuments(enquiryId);
    setEnquiryState((prevState) => ({ ...prevState, isLoading: false }));

    if (typeof response == 'object' && !response?.error) {
      const blob = new Blob([response], { type: 'application/zip' });
      FileSaver.saveAs(blob, `${enquiryId ?? `ORDER`}.zip`);
    } else {
      notify({
        message: response?.error ?? 'Something Went Wrong',
        severity: 'error'
      });
    }

    if (response.success) {
      notify({
        message: 'Document Downloaded Successfully',
        severity: 'success'
      });
    } else if (response.error) {
      notify({
        message: response.error ?? 'Something Went Wrong',
        severity: 'error'
      });
    }
  };

  const handleCancelClick = () => {
    activeStep === 2 && addEnquiryForm.reset();
    setEnquiryState((prevState) => ({
      ...prevState,
      activeStep: 0,
      showEnquiryListing: true
    }));
    setSearchParams({});
  };

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const transformedProducts = data.products?.map((product: any) => ({
      ...product,
      currency: product.currency ? product.currency.value : null,
      unit: product.unit ? product.unit.value : null
    }));
    const formData = new FormData();
    formData.append('place_of_delivery', data.place_of_delivery);
    formData.append('remarks', data.remarks);
    formData.append('products', JSON.stringify(transformedProducts));
    if (data?.document?.length) {
      data.document.forEach((file: File, index: number) => {
        formData.append(`${index}`, file);
      });
    }
    setEnquiryState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const response = await createCustomerEnquiry(formData);
      if (response.success) {
        notify({
          message: 'Enquiry Created Successfully',
          severity: 'success'
        });
        setEnquiryState((prevState) => ({
          ...prevState,
          activeStep: 0,
          showEnquiryListing: true,
          isLoading: false
        }));
        addEnquiryForm.reset();
        getAllEnquiries(1, false);
      } else if (response.error) {
        setEnquiryState((prevState) => ({ ...prevState, isLoading: false }));
        notify({
          message: response.error ?? 'Something Went Wrong',
          severity: 'error'
        });
      }
    } catch (error) {
      notify({
        message: 'Something Went Wrong, Contact Tech Team',
        severity: 'error'
      });
    }
    setSearchParams({});
  };

  const handleFileDrop = useCallback((files: File[]) => {
    if (files.length) {
      if (files.length > MAX_FILES_ALLOWED.RFQ) {
        notify({
          message: `Maximum of ${MAX_FILES_ALLOWED.RFQ} can be uploaded`,
          severity: 'error'
        });
      } else {
        setValue('document', files.slice(0, MAX_FILES_ALLOWED.RFQ));
      }
    }
  }, []);

  const handlePlaceRFQ = () => {
    setEnquiryState((prevState) => ({
      ...prevState,
      activeStep: 2,
      showEnquiryListing: false
    }));
    setSearchParams({
      ...Object.fromEntries(searchParams),
      activestep: 'placeRFQ'
    });
  };

  if (isLoading) return <EnquirySkeleton />;

  return (
    <main ref={rootContainer} className={css.enquiryWrapper}>
      <Seo title="Enquiry" />
      <div className={css.enquiryHeaderWrapper}>
        <div className={css.enquiryHeader}>
          <Typography variant="h2">Enquires</Typography>
          <Badge>{totalEnquiryCount}</Badge>
        </div>
        <AccessWrapper show={actions?.create}>
          <Button variant="outlined-secondary" onClick={handlePlaceRFQ}>
            Place RFQ
          </Button>
        </AccessWrapper>
      </div>
      <section className={css.customerEnquiryWebContainer}>
        <div className={css.customerEnquiry}>
          {data.length > 0 && !isLoading && (
            <div className={css.customerEnquiryCardWrapper}>
              <InfiniteScroll
                rootRef={rootContainer.current!}
                currentIndex={currentPageNumber}
                hasMore={hasNext}
                hideEndText={data.length <= 10}
                onIntersect={getAllEnquiries}
                retry={retry}>
                {data.map((item: Enquiry, index: number) => {
                  return (
                    <CustomerEnquiryCard
                      data={item}
                      key={index}
                      handleClick={handleViewCustomerEnquiry}
                    />
                  );
                })}
              </InfiniteScroll>
            </div>
          )}
          <div
            className={
              data.length > 0 ? css.customerEnquiryViewCreateEdit : css.createCustomerEnquiry
            }>
            {data.length > 0 && activeStep === 0 && !isLoading && (
              <ListPlaceholder
                title="View Enquiries"
                supportingText="Click on the Enquiry to view in detail."
                image={Images.enquiryIllustration}
              />
            )}
            {activeStep === 1 && (
              <ViewEnquiry
                data={viewEnquiryData}
                handleDocumentDownload={handleDocumentZipDownload}
                handleCancelClick={handleCancelClick}
              />
            )}
            <AccessWrapper show={actions?.create}>
              {activeStep === 2 && (
                <FormProvider {...addEnquiryForm}>
                  <CreateEnquiryForm
                    handleCancelClick={handleCancelClick}
                    handleSubmitEnquiry={handleFormSubmit}
                    handleFileDrop={handleFileDrop}
                  />
                </FormProvider>
              )}
            </AccessWrapper>
          </div>
        </div>
        {data.length <= 0 && showEnquiryListing && !isLoading && (
          <ListPlaceholder
            title="No Enquiry Added Yet"
            supportingText="When Enquiries are added they will show up here."
            buttonText="Add Enquiry"
            image={Images.enquiryIllustration}
            handleButtonClick={handlePlaceRFQ}
          />
        )}
      </section>
      <section className={css.customerEnquiryMobileContainer}>
        {data.length > 0 ? (
          <div className={css.customerMobileWrapper}>
            {showEnquiryListing && (
              <InfiniteScroll
                rootRef={rootContainer.current!}
                currentIndex={currentPageNumber}
                hasMore={hasNext}
                hideEndText={data.length <= 10}
                onIntersect={getAllEnquiries}
                retry={retry}>
                {data?.map((item: Enquiry, index: number) => {
                  return (
                    <CustomerEnquiryCard
                      data={item}
                      key={index}
                      handleClick={handleViewCustomerEnquiry}
                    />
                  );
                })}
              </InfiniteScroll>
            )}
            {activeStep === 1 && (
              <ViewEnquiry
                data={viewEnquiryData}
                handleDocumentDownload={handleDocumentZipDownload}
                handleCancelClick={handleCancelClick}
              />
            )}
          </div>
        ) : (
          showEnquiryListing &&
          !isLoading && (
            <ListPlaceholder
              title="No Enquiry Added Yet"
              supportingText="When Enquiries are added by you, they will show up here."
              buttonText="Add Enquiry"
              handleButtonClick={handlePlaceRFQ}
              image={Images.enquiryIllustration}
            />
          )
        )}
        <AccessWrapper show={actions?.create}>
          {activeStep === 2 && (
            <FormProvider {...addEnquiryForm}>
              <CreateEnquiryForm
                handleCancelClick={handleCancelClick}
                handleSubmitEnquiry={handleFormSubmit}
                handleFileDrop={handleFileDrop}
              />
            </FormProvider>
          )}
        </AccessWrapper>
      </section>
    </main>
  );
};

export default CustomerEnquiry;
