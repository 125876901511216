import React, { useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { IconNode, Typography } from '@components/base';
import { DELIVERY_TERMS } from '@helpers/constants';
import {
  generateReadablePackingDetails,
  getCommaSepratedValue,
  getReadablePaymentTerm,
  sanitizeValue
} from '@helpers/utils';
import Images from '@assets/images';
import { fetchAmountInWords } from '@services/util.service';
import { round } from 'lodash';
import { CellProps, Column } from 'react-table';
import { DataGrid } from '@components/common';

interface OrderTable {
  serial_no: number;
  item_details: string;
  quantity_in_kg: number;
  grades: string;
  price_per_unit: number;
  amount: number;
}

const OrderItemPreview = (props: any) => {
  const { purchaseOrderData, handleTabState } = props;
  const [totalAmountInWords, setTotalAmountInWords] = useState();

  const handleEditClick = () => handleTabState(1);

  const [documentColumn, documentData] = useMemo(() => {
    const column: Column<OrderTable>[] = [
      {
        Header: 'S.No.',
        accessor: 'serial_no',
        Cell: (props: CellProps<OrderTable>) => {
          const {
            row: { values }
          } = props;
          return <div title={values?.serial_no}>{values?.serial_no}</div>;
        }
      },
      {
        Header: 'Item Details',
        accessor: 'item_details',
        Cell: (props: CellProps<OrderTable>) => {
          const {
            row: { values }
          } = props;
          return <div title={values?.item_details}>{values?.item_details}</div>;
        }
      },
      {
        Header: `Qty. in ${purchaseOrderData.unit_of_weight?.value ?? 'MT'}`,
        accessor: 'quantity_in_kg',
        Cell: (props: CellProps<OrderTable>) => {
          const {
            row: { values }
          } = props;
          return <div title={values?.quantity_in_kg}>{values?.quantity_in_kg}</div>;
        }
      },
      {
        Header: `Price/Unit`,
        accessor: 'price_per_unit',
        Cell: (props: CellProps<OrderTable>) => {
          const {
            row: { values }
          } = props;

          return (
            <div title={values?.price_per_unit}>
              {purchaseOrderData?.currency.value} {values?.price_per_unit}
            </div>
          );
        }
      },
      {
        Header: `Amount`,
        accessor: 'amount',
        Cell: (props: CellProps<OrderTable>) => {
          const {
            row: { values }
          } = props;
          return <div title={values?.amount}>{values?.amount}</div>;
        }
      }
    ];

    const row: OrderTable[] = purchaseOrderData.order_item?.map((details: any, index: any) => {
      return {
        serial_no: index + 1,
        item_details: (
          <>
            <Typography variant="p">
              {details?.item_name}
              {details.include_grade_name_in_docs?.value == true &&
                ` - ${details?.item_grade_name}`}
            </Typography>{' '}
            <Typography variant="label">{details?.order_item_description_po}</Typography>
            <div className={css.ItemDetailsPreview}>
              {details?.package && (
                <Typography variant="label">
                  Packaging Details: {generateReadablePackingDetails(details?.package)?.join(', ')}
                </Typography>
              )}
              <Typography variant="label">CAS Number: {details?.CAS_number}</Typography>
              <Typography variant="label">HS Code: {details?.hs_code}</Typography>
            </div>
          </>
        ),
        quantity_in_kg: details?.quantity_po,
        price_per_unit: details?.rate_po,
        amount: `${purchaseOrderData?.currency.value} ${getCommaSepratedValue(details?.amount)}`
      };
    });

    return [column, row];
  }, []);

  const total_gst = purchaseOrderData.order_item.reduce((acc: number, item: any) => {
    acc += round(item.amount);
    return acc;
  }, 0);

  useEffect(() => {
    (async () => {
      if (purchaseOrderData.total_amount > 0) {
        const response = await fetchAmountInWords({
          amount: purchaseOrderData.total_amount.toString(),
          currency_type: purchaseOrderData.currency.value ? purchaseOrderData.currency.value : 'USD'
        });
        if (response?.success) {
          setTotalAmountInWords(response.data.amount_in_words);
        }
      }
    })();
  }, [purchaseOrderData.total_amount]);

  return (
    <div className={css.boxWrapper}>
      <div className={css.orderDetailsContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Order Details</Typography>
          <IconNode src={Images.editRed} onClick={handleEditClick} />
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Vendor ID</Typography>
            <Typography variant="body">{sanitizeValue(purchaseOrderData?.vendor_id)}</Typography>
          </div>
          <div>
            <Typography variant="label">Terms of Delivery</Typography>
            <Typography variant="body">
              {DELIVERY_TERMS.find(
                (item: any) => purchaseOrderData?.po_terms_of_delivery.value == item.value
              )?.label ?? '-'}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Item to Palletized </Typography>
            <Typography variant="body">
              {purchaseOrderData?.is_palletized ? 'Yes' : 'No'}
            </Typography>
          </div>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Country of Origin</Typography>
            <Typography variant="body">
              {sanitizeValue(purchaseOrderData?.po_country_of_origin?.label)}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Payment Terms</Typography>
            {getReadablePaymentTerm(purchaseOrderData?.payment_terms)?.map(
              (item: any, index: number) => {
                return (
                  <Typography variant="body" key={index}>
                    {item} {purchaseOrderData?.payment_term_suffix?.name}
                  </Typography>
                );
              }
            )}
          </div>
        </div>
      </div>
      <DataGrid columns={documentColumn} data={documentData} />
      <div className={css.documentTabWrapper}>
        <div className={css.itemDetailsContainer}>
          <div className={css.fieldTable}>
            {purchaseOrderData.gst_rate.value != 0 && (
              <div className={css.gstDetails}>
                <Typography variant="pdoc">{`GST ${purchaseOrderData?.gst_rate.value}% under Merchant Export`}</Typography>
                <Typography>
                  {purchaseOrderData?.currency?.value}{' '}
                  {getCommaSepratedValue(
                    (total_gst * (purchaseOrderData?.gst_rate.value / 100)).toFixed(2)
                  )}
                </Typography>
              </div>
            )}
            <div className={css.totalContainer}>
              <Typography variant="h4">Total</Typography>
              <Typography variant="h4">
                {`${purchaseOrderData?.currency.value} ${getCommaSepratedValue(
                  purchaseOrderData?.total_amount
                )}`}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={css.bottomDetails}>
        <div className={css.details}>
          <Typography variant="label">Total Amount in Words</Typography>
          <Typography variant="body">{totalAmountInWords}</Typography>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Total Amount</Typography>
            <Typography variant="body">{`${
              purchaseOrderData?.currency.value
            } ${getCommaSepratedValue(purchaseOrderData?.total_amount)}`}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItemPreview;
