import { IconNode, Image, ToolTip, Typography } from '@components/base';
import { DeletePrompt } from '@components/common';
import { Fragment, MouseEvent, useState } from 'react';
import css from './index.module.scss';

import Images from '@assets/images';
import { openDocumentSignedLink } from '@helpers/utils';
interface DocumentTitleProps {
  title: string;
  documentAvailable: boolean;
  required?: boolean;
}

const DocumentTitle = (props: DocumentTitleProps) => {
  const { title, documentAvailable, required } = props;

  return (
    <div className={css.documentNameWrapper}>
      <Typography variant="body">{title}</Typography>
      {required ? (
        <Typography variant="span" className={css.docRequired}>
          *
        </Typography>
      ) : (
        <></>
      )}

      {documentAvailable && <Image src={Images.rightTickGreen} alt="green-tick" />}
    </div>
  );
};

interface DocumentUploadProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  documentAvailable: boolean;
  disabled?: boolean;
}

const DocumentUpload = (props: DocumentUploadProps) => {
  const { onClick, documentAvailable, disabled } = props;
  return (
    <div className={css.reUploadWrapper}>
      <button className={css.reUploadButtonWrapper} onClick={onClick} disabled={disabled}>
        <IconNode src={Images.upload} alt="upload-icon" />
        {!documentAvailable && <Typography variant="p">Upload Document</Typography>}
        {documentAvailable && <Typography variant="p">Reupload</Typography>}
      </button>
    </div>
  );
};

interface DocumentActionProps {
  title: string;
  document: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const DocumentAction = (props: DocumentActionProps) => {
  const { onClick, document, title } = props;
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  return (
    <Fragment>
      {document ? (
        <div className={css.actionButtonWrapper}>
          <a href={document} download={title} target="_blank" rel="noopener noreferrer">
            <IconNode src={Images.eyeRed} alt="upload-icon" className={css.iconButton} />
          </a>
          {onClick ? (
            <IconNode
              src={Images.delete}
              alt="delete-icon"
              component="button"
              className={css.iconButton}
              onClick={() => setOpenDeletePrompt(true)}
            />
          ) : (
            ``
          )}
        </div>
      ) : null}
      <DeletePrompt
        open={openDeletePrompt}
        onClose={() => setOpenDeletePrompt(false)}
        onConfirm={onClick as any}
      />
    </Fragment>
  );
};

interface DocumentInfoProps {
  title: string;
  document: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  isDelete?: boolean;
  showEyeIconWhenDocumentMissing?: boolean;
  openViaSlug?: boolean;
}

const DocumentInfo = (props: DocumentInfoProps) => {
  const {
    onClick,
    document,
    title,
    isDelete = false,
    showEyeIconWhenDocumentMissing = false,
    openViaSlug = false
  } = props;
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  return (
    <Fragment>
      {document ? (
        <div className={css.actionButtonWrapper}>
          {openViaSlug ? (
            <a
              onClick={() => openDocumentSignedLink(document)}
              download={title}
              target="_blank"
              rel="noopener noreferrer">
              <IconNode src={Images.eyeRed} alt="upload-icon" className={css.iconButton} />
            </a>
          ) : (
            <a href={document} download={title} target="_blank" rel="noopener noreferrer">
              <IconNode src={Images.eyeRed} alt="upload-icon" className={css.iconButton} />
            </a>
          )}

          {title?.length ? <Typography variant="body">{title}</Typography> : ``}
          {isDelete && (
            <IconNode
              src={Images.delete}
              alt="delete-icon"
              component="button"
              className={css.iconButton}
              onClick={() => setOpenDeletePrompt(true)}
            />
          )}
        </div>
      ) : showEyeIconWhenDocumentMissing ? (
        <div className={css.actionButtonWrapper}>
          <IconNode src={Images.greyEye} alt="upload-icon" className={css.iconButton} />
        </div>
      ) : null}
      <DeletePrompt
        open={openDeletePrompt}
        onClose={() => setOpenDeletePrompt(false)}
        onConfirm={onClick as any}
      />
    </Fragment>
  );
};

const DocumentWordInfo = (props: DocumentInfoProps) => {
  const { onClick, document, title, isDelete = false } = props;
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  return (
    <Fragment>
      {document ? (
        <div className={css.actionButtonWrapper}>
          <a href={document} download={title} target="_blank" rel="noopener noreferrer">
            <IconNode src={Images.word} alt="upload-icon" className={css.iconWordButton} />
          </a>
          <Typography variant="body">{title}</Typography>
          {isDelete && (
            <IconNode
              src={Images.delete}
              alt="delete-icon"
              component="button"
              className={css.iconButton}
              onClick={() => setOpenDeletePrompt(true)}
            />
          )}
        </div>
      ) : null}
      <DeletePrompt
        open={openDeletePrompt}
        onClose={() => setOpenDeletePrompt(false)}
        onConfirm={onClick as any}
      />
    </Fragment>
  );
};

const DocumentZipInfo = (props: DocumentInfoProps) => {
  const { onClick, document, title = 'Download all files', isDelete = false } = props;
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  return (
    <Fragment>
      {document ? (
        <div className={css.actionButtonWrapper}>
          <ToolTip title={title} placement="left">
            <a href={document} target="_blank" rel="noopener noreferrer">
              <IconNode src={Images.zipFileSvg} alt="zip" />
            </a>
          </ToolTip>

          {isDelete && (
            <IconNode
              src={Images.delete}
              alt="delete-icon"
              component="button"
              className={css.iconButton}
              onClick={() => setOpenDeletePrompt(true)}
            />
          )}
        </div>
      ) : null}
      <DeletePrompt
        open={openDeletePrompt}
        onClose={() => setOpenDeletePrompt(false)}
        onConfirm={onClick as any}
      />
    </Fragment>
  );
};

const DocumentRow = {
  Title: DocumentTitle,
  Upload: DocumentUpload,
  Action: DocumentAction,
  View: DocumentInfo,
  ViewWord: DocumentWordInfo,
  Zip: DocumentZipInfo
};

export default DocumentRow;
