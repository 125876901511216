import css from './index.module.scss';
import { Typography, IconNode } from '@components/base/';
import Images from '@assets/images';

interface DescriptionPropTypes {
  data: string;
  handleNavigation: (index: number) => void;
}

const Description = (props: DescriptionPropTypes) => {
  const { data, handleNavigation } = props;
  return (
    <div className={css.boxWrapper}>
      <div className={css.orderContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="p">Description</Typography>
          <IconNode
            className={css.editIcon}
            src={Images.editRed}
            onClick={() => handleNavigation(2)}
          />
        </div>
        {data?.length ? (
          <Typography variant="p">{data}</Typography>
        ) : (
          <Typography variant="p">-</Typography>
        )}
      </div>
    </div>
  );
};

export default Description;
