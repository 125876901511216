import css from './index.module.scss';
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';

import {
  deleteStuffingPhotos,
  getAllOrderPhotos,
  uploadOrderPhotos
} from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { Button, Loader, ToolTip } from '@components/base';
import { DeletePrompt, InfiniteScroll, ListPlaceholder } from '@components/common';
import AccessWrapper from '@authorization/access-wrapper';
import { SUPPORT_EMAIL, checkScreenType, getPermissionFromLocalStorage } from '@helpers/utils';
import { SCREEN_TYPE, USER_TYPES } from '@helpers/constants';
import Skeleton from '@components/base/skeleton';

interface StuffingPhotosState {
  isLoading: boolean;
  imageData: Array<object>;
  hasNext: boolean;
  currentPageNumber: number;
  retry: boolean;
  count: number;
  deleteFlag: boolean;
}

interface StuffingPhotosProps {
  orderId: string;
  actions: IActions;
}

const StuffingPhotos = (props: StuffingPhotosProps) => {
  const { orderId, actions } = props;
  const inputRef = useRef<any>(null);
  const rootContainer = useRef<HTMLElement>(null);
  const userType = useMemo(() => {
    return getPermissionFromLocalStorage()?.user_type;
  }, []);
  const [selectedPhotoList, setSelectedPhotoList] = useState<any>([]);
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);

  const [stuffingState, setStuffingState] = useState<StuffingPhotosState>({
    isLoading: false,
    imageData: [],
    hasNext: false,
    currentPageNumber: 1,
    retry: false,
    count: 0,
    deleteFlag: false
  });

  const { isLoading, imageData, hasNext, currentPageNumber, retry, deleteFlag } = stuffingState;

  useEffect(() => {
    getImages();
  }, []);

  const handleClick = () => {
    if (inputRef) inputRef?.current?.click();
  };

  const handleSubmit = async (data: any) => {
    setStuffingState((prevState: StuffingPhotosState) => ({ ...prevState, isLoading: true }));
    if (data?.target?.files?.length) {
      const formData = new FormData();
      formData.append('document_type', 'STUFFING');
      for (let i = 0; i < data.target.files.length; i++) {
        formData.append(`files[]`, data.target.files[i]);
      }
      const uploadResponse = await uploadOrderPhotos(orderId, formData);
      setStuffingState((prevState: StuffingPhotosState) => ({
        ...prevState,
        isLoading: false,
        count: uploadResponse.data.count
      }));
      if (uploadResponse?.success) {
        getImages(1, false);
      } else {
        notify({
          title: 'We have little problem',
          message:
            uploadResponse.error ?? 'Unable to get stuffing photos, please try after some time!',
          severity: 'error'
        });
      }
    }
  };

  const getImages = useCallback(async (page = 1, repeat = true) => {
    setStuffingState((prevState: StuffingPhotosState) => ({ ...prevState, isLoading: true }));
    const imageList = await getAllOrderPhotos(orderId, page);
    if (imageList?.data) {
      setStuffingState((prevState: StuffingPhotosState) => ({
        ...prevState,
        imageData: repeat
          ? [...prevState.imageData, ...imageList.data.results]
          : imageList.data.results,
        hasNext: !!imageList.data.next,
        currentPageNumber: page ?? 1,
        retry: false,
        isLoading: false
      }));
    } else if (imageList.error) {
      notify({
        title: 'We have little problem',
        message: imageList.error ?? 'There was an error understanding your request.',
        severity: 'error'
      });
      setStuffingState((prevState: StuffingPhotosState) => ({
        ...prevState,
        isLoading: false,
        retry: true
      }));
    }
  }, []);

  const handleDelete = () => {
    setStuffingState((prevState: StuffingPhotosState) => ({
      ...prevState,
      deleteFlag: !prevState.deleteFlag
    }));
  };

  const handleCancel = () => {
    setSelectedPhotoList([]);
    setStuffingState((prevState: StuffingPhotosState) => ({
      ...prevState,
      deleteFlag: !prevState.deleteFlag
    }));
    setOpenDeletePrompt(false);
  };

  const selectUnselectPhotos = (photo: any) => {
    const copyOfOldSelectedPhotos = [...selectedPhotoList];
    const photoIndex = copyOfOldSelectedPhotos.indexOf(photo);
    if (photoIndex > -1) {
      copyOfOldSelectedPhotos.splice(photoIndex, 1);
    } else {
      copyOfOldSelectedPhotos.push(photo);
    }
    setSelectedPhotoList(copyOfOldSelectedPhotos);
  };

  const deletePhotos = async (photoIds: string[]) => {
    setStuffingState((prevState: StuffingPhotosState) => ({ ...prevState, isLoading: true }));
    const response = await deleteStuffingPhotos(orderId, photoIds);
    setStuffingState((prevState: StuffingPhotosState) => ({ ...prevState, isLoading: false }));
    if (response?.success) {
      notify({
        title: 'Success',
        message: 'Photos Deleted Successfully',
        severity: 'success'
      });
    } else {
      notify({
        title: 'Error',
        message: response.error ?? 'Unable to Delete Photos',
        severity: 'error'
      });
    }
  };

  const handleConfirmDelete = () => {
    setStuffingState((prevState: StuffingPhotosState) => ({ ...prevState, isLoading: true }));
    const stuffingPhotos: string[] = [];
    selectedPhotoList.map((photo: any, index: number) => {
      stuffingPhotos.push(photo.order_image_id);
    });
    deletePhotos(stuffingPhotos);
    const updatedImageData = imageData.filter((item: any) => {
      return !stuffingPhotos.includes(item.order_image_id);
    });
    setStuffingState((prevState: StuffingPhotosState) => ({
      ...prevState,
      deleteFlag: !prevState.deleteFlag,
      imageData: updatedImageData
    }));
    setSelectedPhotoList([]);
    handleDeletePrompt();
    setStuffingState((prevState: StuffingPhotosState) => ({ ...prevState, isLoading: false }));
  };

  const handleDeletePrompt = () => {
    setOpenDeletePrompt(!openDeletePrompt);
  };

  if (isLoading) return <StuffingPhotosSkeleton />;

  return (
    <main ref={rootContainer}>
      <div className={css.headerWrapper}>
        <AccessWrapper show={actions?.create}>
          <Button disabled={deleteFlag} onClick={handleClick}>
            Upload
          </Button>
        </AccessWrapper>
        {imageData.length > 0 && (
          <AccessWrapper show={actions?.delete}>
            <div className={css.delete}>
              <Button
                onClick={!deleteFlag ? handleDelete : handleDeletePrompt}
                disabled={deleteFlag ? selectedPhotoList.length === 0 : false}>
                Delete
              </Button>
              <Button
                variant="outlined-secondary"
                className={deleteFlag ? '' : css.cancelDelete}
                onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </AccessWrapper>
        )}
      </div>
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleSubmit}
        multiple
        accept=".jpg,.jpeg,.png"
      />
      <div className={css.imageWrapper}>
        {imageData?.length ? (
          <InfiniteScroll
            rootRef={rootContainer.current!}
            currentIndex={currentPageNumber}
            hasMore={hasNext}
            hideEndText={imageData.length <= 10}
            onIntersect={(index: number) => getImages(index)}
            retry={retry}>
            <ImageList
              rowHeight={
                deleteFlag ? 300 : checkScreenType() === SCREEN_TYPE.desktop.label ? 550 : 150
              }>
              {imageData.map((item: any, index: number) => (
                <ImageListItem key={item.order_image_id} cols={deleteFlag ? 0.4 : item.cols || 1}>
                  {!deleteFlag ? (
                    <img
                      src={item.image_object}
                      alt={item.document_type}
                      style={{ objectFit: 'cover', width: '100%' }}
                      className={css.images}
                    />
                  ) : (
                    <div className={css.imageContainer}>
                      <input
                        type="checkbox"
                        id={`myCheckbox${index + 1}`}
                        checked={selectedPhotoList.includes(item)}
                        onChange={() => selectUnselectPhotos(item)}
                        style={{ display: 'none' }}
                      />
                      <ToolTip title="Click to select this image for deletion" placement="top">
                        <label htmlFor={`myCheckbox${index + 1}`}>
                          <img
                            src={item.image_object}
                            style={{ objectFit: 'cover', width: '100%' }}
                            alt={item.document_type}
                            className={css.images}
                          />
                          <div className={css.overlay}></div>
                        </label>
                      </ToolTip>
                    </div>
                  )}
                </ImageListItem>
              ))}
            </ImageList>
          </InfiniteScroll>
        ) : (
          !isLoading && (
            <ListPlaceholder
              to={``}
              title="No Stuffing Photos Added Yet"
              supportingText={
                userType === USER_TYPES.customer
                  ? `Contact ${SUPPORT_EMAIL} incase of any issues with stuffing photos`
                  : `When a new stuffing photo is added, you will see it here.`
              }
              buttonText=""
            />
          )
        )}
      </div>

      {openDeletePrompt && (
        <DeletePrompt
          open={openDeletePrompt}
          onClose={() => handleCancel()}
          onConfirm={() => handleConfirmDelete()}
          message="Are you sure you want to delete these Stuffing Photos?"
        />
      )}
    </main>
  );
};

export default StuffingPhotos;

const StuffingPhotosSkeleton = () => {
  return (
    <div>
      <div className={css.stuffingPhotosContainer}>
        <Skeleton animation="wave" width={'200px'} height={30} />
        <Skeleton animation="wave" width={'200px'} height={30} />
      </div>
      <div className={css.stuffingPhotos}>
        <Skeleton variant="rectangular" width={'50%'} height={'550px'} />
        <Skeleton variant="rectangular" width={'50%'} height={'550px'} />
      </div>
      <div className={css.stuffingPhotos}>
        <Skeleton variant="rectangular" width={'50%'} height={'550px'} />
        <Skeleton variant="rectangular" width={'50%'} height={'550px'} />
      </div>
    </div>
  );
};
