import React from 'react';
import { Routes, Route, RouteObject } from 'react-router-dom';
import routes from './routes';
import Error from '@pages/error';
import PrivateRoute from './private-route';
import ProtectedRoute from './protected-routes';

const AppRoutes = () => {
  const generateRoute = (param: any[]) => {
    return param.map((route, index) => {
      if (route.children)
        return (
          <Route key={index} path={route.path} element={route.element} {...route}>
            {generateRoute(route.children)}
          </Route>
        );
      return <Route key={index} path={route.path} element={route.element} {...route} />;
    });
  };

  return (
    <Routes>
      {generateRoute(routes.public)}
      <Route element={<PrivateRoute />}>{generateRoute(routes.private)}</Route>
      <Route element={<ProtectedRoute />}>{generateRoute(routes.protected)}</Route>
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default AppRoutes;
