import { InputHTMLAttributes, memo, forwardRef, Ref } from 'react';
import css from './index.module.scss';
import { ReactComponent as UploadIcon } from '@assets/images/upload-grey.svg';

const UploadButton = forwardRef(
  (props: InputHTMLAttributes<HTMLInputElement>, ref: Ref<HTMLInputElement>) => {
    return (
      <span className={css.btnWrapper}>
        <input
          ref={ref}
          {...props}
          title="Upload Document"
          type="file"
          className={css.inputField}
        />
        <span className={css.btnRipple}></span>
        <UploadIcon />
      </span>
    );
  }
);

UploadButton.defaultProps = {
  accept:
    'application/pdf,.pdf,application/doc,.doc,application/ms-doc,.doc,application/msword,.doc,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx'
};

export default memo(UploadButton);
