import Images from '@assets/images';
import { IconNode, Typography } from '@components/base';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import css from '../index.module.scss';

interface IBankOrder {
  previewData: PerformaInvoiceStates;
  setOrderState: (orderState: PerformaInvoiceStates) => void;
}

const BankDetails = (props: IBankOrder) => {
  const { previewData, setOrderState } = props;
  const { bankDetails } = previewData;
  const { editRed } = Images;
  return (
    <div className={css.boxWrapper}>
      <div className={css.bankDetailsContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Entity Bank Details</Typography>
          <IconNode
            className={css.editIcon}
            src={editRed}
            onClick={() =>
              setOrderState({
                ...previewData,
                activeStep: 2
              })
            }
          />
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Bank Name</Typography>
            <Typography variant="body">{bankDetails?.bank_name}</Typography>
          </div>
          <div>
            <Typography variant="label">Branch</Typography>
            <Typography variant="body">{bankDetails?.branch_name}</Typography>
          </div>
          <div>
            <Typography variant="label">Account Holder Name</Typography>
            <Typography variant="body">{bankDetails?.bank_account_holder_name}</Typography>
          </div>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Bank Account Number</Typography>
            <Typography variant="body">{bankDetails?.account_number}</Typography>
          </div>
          <div>
            <Typography variant="label">IFSC Code</Typography>
            <Typography variant="body">{bankDetails?.ifsc_code}</Typography>
          </div>
          <div>
            <Typography variant="label">SWIFT Code</Typography>
            <Typography variant="body">{bankDetails?.swift_code}</Typography>
          </div>
        </div>
        <div>
          <Typography variant="label">Bank Address</Typography>
          <Typography variant="body">{bankDetails?.address}</Typography>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
