export interface IState {
  PIData: object;
  RectifyData: object;
  POData: object;
  tasksData: object;
}

export type ActionType =
  | 'setPIData'
  | 'setRectifyData'
  | 'setPOData'
  | 'setCOAData'
  | 'setPackageData'
  | 'setReviewData'
  | 'userType'
  | 'setSIData'
  | 'setSendDocToCustomer'
  | 'finance';

export interface IAction {
  type: ActionType;
  payload?: any;
}

export const appReducer = (state: IState, action: IAction) => {
  const { payload } = action;

  switch (action.type) {
    case 'setPIData': {
      return {
        ...state,
        PIData: {
          task_id: payload.task_id,
          order_id: payload.order_id,
          invoiceType: payload.invoiceType
        }
      };
    }

    case 'setRectifyData': {
      return {
        ...state,
        RectifyData: {
          isRectify: payload.isRectify,
          task_id: payload.task_id,
          order_id: payload.order_id
        }
      };
    }

    case 'setPOData': {
      return {
        ...state,
        POData: {
          task_id: payload.task_id,
          order_id: payload.order_id
        }
      };
    }

    case 'setCOAData': {
      return {
        ...state,
        COAData: {
          task_id: payload?.task_id,
          order_id: payload?.order_id,
          order_item_details: payload?.order_item_details
        }
      };
    }
    case 'setPackageData': {
      return {
        ...state,
        setPackageData: {
          task_id: payload?.task_id,
          order_id: payload?.order_id,
          supplier_id: payload?.supplier_id,
          rectifyRemarks: payload?.rectifyRemarks ?? null
        }
      };
    }
    case 'setReviewData': {
      return {
        ...state,
        setReviewData: {
          task_id: payload?.task_id,
          order_id: payload?.order_id,
          supplier_id: payload?.supplier_id
        }
      };
    }
    case 'userType': {
      return {
        ...state,
        userType: payload.userType
      };
    }

    case 'setSIData': {
      return {
        ...state,
        setSIData: payload.tasksData
      };
    }
    case 'setSendDocToCustomer': {
      return {
        ...state,
        sendDocToCustomer: {
          to: payload.to,
          documentsToBeSent: payload.documentsToBeSent
        }
      };
    }
    case 'finance': {
      return {
        ...state,
        finance: payload.data
      };
    }
    default: {
      return state;
    }
  }
};
