import * as yup from 'yup';

export const transportDetailsSchema = yup.object().shape({
  transport_truck_number: yup.string(),
  net_wt_mt: yup.string(),
  gross_wt_mt: yup.string(),
  tare_wt_mt: yup.string(),
  lot_number: yup.string(),
  marks_and_number: yup.string(),
  transportation_costs: yup.number().typeError('Cost should be in number').optional()
});
