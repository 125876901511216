import { Accordion, Button, IconNode, Typography } from '@components/base';
import { Avatar, AssignPeopleModal } from '@components/common';
import css from './index.module.scss';
import Images from '@assets/images';
import UserCard from '../user-card';
import { useState } from 'react';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

interface AssignPeopleModalProps {
  orderInfo?: object;
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete?: () => void;
}

const AssignmentCard = (props: AssignPeopleModalProps) => {
  const [isUser, setIsUser] = useState(false);
  const [assignPeopleModalOpen, setAssignPeopleModalOpen] = useState(false);
  const { alertSuccessFaint, settingGrey, dotGrey } = Images;
  const getCompleteStatus = props.taskData[0]?.is_completed;
  const [taskIsCompleted, setTaskIsCompleted] = useState<boolean | undefined>(getCompleteStatus);

  const handleStatusFromModal = (data: boolean) => {
    setTaskIsCompleted(data);
  };

  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {isUser ? (
                  <>
                    <Avatar
                      src={alertSuccessFaint}
                      className={css.successIcon}
                      alt="assign-sucess-icon"
                    />
                    <Typography variant="h5" className={css.underlineOnHover}>Assign people to the order</Typography>
                  </>
                ) : (
                  <>
                    {getCompleteStatus || taskIsCompleted ? (
                      <IconNode src={alertSuccessFaint} className={css.successIcon} />
                    ) : (
                      <Avatar src={dotGrey} alt="assign-icon" />
                    )}
                    <Link
                      to={`/order/${props.taskData[0]?.order}`}
                      style={{ textDecoration: 'none' }}>
                      <Typography variant="h5" className={css.underlineOnHover}>
                        #{props.taskData[0]?.readable_task_id} - Assign members to the order
                      </Typography>
                    </Link>
                  </>
                )}
              </div>
              <div className={css.pocWrapper}>
                {(!getCompleteStatus || !taskIsCompleted) && (
                  <Button
                    variant="text"
                    onClick={() => setAssignPeopleModalOpen(true)}
                    className={css.assignButton}>
                    Assign
                  </Button>
                )}
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        <Footer
          createdDate={props.taskData[0].created_at as Date}
          updatedDate={props.taskData[0].updated_at as Date}
        />
      </div>
      {assignPeopleModalOpen && (
        <AssignPeopleModal
          open={assignPeopleModalOpen}
          onClose={() => setAssignPeopleModalOpen(false)}
          taskId={props.taskData}
          onComplete={props.onComplete}
          sendStatusToCard={handleStatusFromModal}
        />
      )}
    </>
  );
};

export default AssignmentCard;
