import { CheckboxLabel, Typography } from '@components/base';
import { BoxContainer, TextField } from '@components/common';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import { memo } from 'react';
import CustomerForm from '../customer-form';
import css from './index.module.scss';

interface ConsigneeContainerProps {
  consigneeData: PerformaInvoiceStates;
}

const ConsigneeContainer = (props: ConsigneeContainerProps) => {
  const { consigneeData } = props;
  const { customerInfo, consigneeInfo } = consigneeData;
  const isSameAsCustomer = consigneeInfo?.isSameAsCustomer;
  return (
    <BoxContainer
      className={css.mainWrapper}
      title={
        <div className={css.boxTitleWrapper}>
          <Typography variant="h4">Consignee</Typography>
          {!isSameAsCustomer && (
            <CheckboxLabel
              checked={consigneeInfo?.isSameAsCustomer}
              label="Same as Customer"
              disabled
            />
          )}
        </div>
      }>
      <div className={css.boxWrapper}>
        {!isSameAsCustomer && <CustomerForm customerData={customerInfo} />}
        <div className={css.rowWrapper}>
          <TextField disabled label="Consignee Name" value={consigneeInfo?.consignee_name ?? ''} />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            label="Landline"
            value={consigneeInfo?.consignee_phone_number ?? ''}
            rootClassName={css.fieldWrapper}
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField value={consigneeInfo?.consignee_email_id ?? ''} disabled label="Email ID" />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            label="Office Address"
            placeholder="Enter office address"
            value={consigneeInfo?.consignee_office_address ?? ''}
          />
        </div>
      </div>
    </BoxContainer>
  );
};

export default memo(ConsigneeContainer);
