import { getMakePaymentInvoiceData, intiatePayment } from '@services/finance.service';
import css from './index.module.scss';
import { useContext, useEffect, useState } from 'react';
import notify from '@helpers/toastify-helper';
import { FormProvider, useForm } from 'react-hook-form';
import { Typography } from '@components/base';
import { DeletePrompt, SideBar } from '@components/common';
import { useNavigate } from 'react-router-dom';
import InvoiceDetails from './components/invoice-details';
import { AppContext } from '@helpers/hooks/AppContext';
import { ISelect } from '@helpers/types';
import { makePaymentSchema, paymentSchema } from '@helpers/yup/payment-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import AccessWrapper from '@authorization/access-wrapper';
import PaymentDetails from './components/payment-details';

export interface IMakePaymentState {
  isLoading: boolean;
  invoiceData: IInvoice[] | null;
  activeStep: number;
  openConfirmationModal: boolean;
  invoiceFormData: IMakePaymentForm | null;
  paymentData: any;
  paymentFormData: any;
}

export interface IPaymentInfo {
  id: string | null;
  payment_display_id: string | null;
  amount_requested: string | null;
  due_date: Date | null;
  requester_remarks: string | null;
  payer_remarks: string | null;
  tds_amount: string | null;
  tcs_amount: string | null;
  additional_charges_or_discounts: string | null;
}

export interface IInvoice {
  invoice_info_id: string | null;
  payment_infos: IPaymentInfo[];
  payment_terms: string;
  total_amount_eligible_for_payment: string;
  total_amount_eligible_to_request: string | null;
  document_type_display: string;
  invoice_number: string | null;
  invoice_date: Date | null;
  document: string | null;
  supplier_name: string;
  currency: ISelect | null;
  amount: string;
  gst_amount: string;
  tds_amount: string;
  tcs_amount: string;
  additional_charges_or_discounts: string | null;
  order_ids: ISelect[] | null;
  total_amount_initiated: string;
  total_amount_paid: string;
  total_tds_paid: string;
  total_tcs_paid: string;
  total_tds_initiated: string;
  total_tcs_initiated: string;
  total_child_invoices_gross_amount: string;
}

interface IMakePaymentForm {
  invoice: IInvoice[];
}

const MakePayment = (props: any) => {
  const { actions } = props.modulePermissions;
  const { appState } = useContext(AppContext);
  const payment_ids = appState?.finance?.payment_id;
  const navigate = useNavigate();
  const [makePaymentState, setMakePaymentState] = useState<IMakePaymentState>({
    isLoading: false,
    invoiceData: null,
    paymentData: null,
    activeStep: 0,
    openConfirmationModal: false,
    invoiceFormData: null,
    paymentFormData: null
  });

  const {
    activeStep,
    isLoading,
    invoiceData,
    openConfirmationModal,
    invoiceFormData,
    paymentData,
    paymentFormData
  } = makePaymentState;

  const invoiceDetailsForm = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(makePaymentSchema),
    defaultValues: {
      invoice: [
        {
          invoice_info_id: null,
          payment_infos: [
            {
              id: null
            }
          ],
          payment_terms: '',
          total_amount_eligible_for_payment: '',
          total_amount_eligible_to_request: null,
          document_type_display: '',
          invoice_number: null,
          invoice_date: null,
          document: null,
          supplier_name: '',
          currency: null,
          amount: null,
          gst_amount: null,
          tds_amount: '',
          tcs_amount: '',
          order_ids: null,
          total_amount_initiated: '',
          total_amount_paid: '',
          total_tds_paid: '',
          total_tcs_paid: '',
          total_tds_initiated: '',
          total_tcs_initiated: '',
          total_child_invoices_gross_amount: ''
        }
      ]
    }
  });

  const paymentDetailsForm = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(paymentSchema),
    defaultValues: {
      invoice: [
        {
          invoice_info_id: null,
          payment_infos: [
            {
              id: null,
              payment_display_id: null,
              amount_requested: null,
              due_date: null,
              requester_remarks: null,
              payer_remarks: null,
              tds_amount: null,
              tcs_amount: null,
              additional_charges_or_discounts: null
            }
          ]
        }
      ]
    }
  });

  const getInvoiceDetails = async () => {
    setMakePaymentState((prevState: IMakePaymentState) => ({ ...prevState, isLoading: true }));
    const response = await getMakePaymentInvoiceData(payment_ids);
    if (response?.success) {
      const { data } = response;
      const invoice = data.results?.map((invoice: any) => {
        const orderIds = Array.from(
          new Set(invoice.initiated_payment_infos.map((order: any) => order.order_readable_ids[0]))
        ).map((id) => ({
          label: id,
          value: id
        }));
        return {
          invoice_info_id: invoice.invoice_info_id,
          payment_infos: invoice.initiated_payment_infos.map((paymentInfo: any) => {
            return {
              id: paymentInfo.id
            };
          }),
          payment_terms: invoice.payment_terms_display,
          total_amount_eligible_for_payment: invoice.total_amount_eligible_for_payment,
          total_amount_eligible_to_request: invoice.total_amount_eligible_to_request,
          document_type_display: invoice.document_type_display,
          invoice_number: invoice.invoice_number,
          invoice_date: new Date(invoice.invoice_date),
          document: invoice.invoice_document,
          supplier_name: invoice.supplier_name,
          currency: { label: invoice.currency, value: invoice.currency },
          amount: invoice.amount,
          gst_amount: invoice.gst_amount,
          tds_amount: parseFloat(invoice.tds_amount) == 0 ? null : invoice.tds_amount,
          tcs_amount: parseFloat(invoice.tcs_amount) == 0 ? null : invoice.tcs_amount,
          order_ids: orderIds,
          total_amount_initiated: invoice?.total_amount_initiated,
          total_amount_paid: invoice?.total_amount_paid,
          total_tds_paid: invoice?.total_tds_paid,
          total_tcs_paid: invoice?.total_tcs_paid,
          total_tds_initiated: invoice?.total_tds_initiated,
          total_tcs_initiated: invoice?.total_tcs_initiated,
          total_child_invoices_gross_amount: invoice?.total_child_invoices_gross_amount
        };
      });

      const payment = data.results?.map((invoice: any) => {
        return {
          invoice_info_id: invoice.invoice_info_id,
          payment_infos: invoice.initiated_payment_infos.map((paymentInfo: any) => {
            return {
              id: paymentInfo.id,
              payment_display_id: paymentInfo.payment_id_display,
              amount_requested: paymentInfo.amount_to_pay,
              due_date: new Date(paymentInfo.due_date),
              requester_remarks: paymentInfo.requester_remarks,
              tds_amount: parseFloat(paymentInfo?.tds_amount) == 0 ? null : paymentInfo?.tds_amount,
              tcs_amount: parseFloat(paymentInfo?.tcs_amount) == 0 ? null : paymentInfo?.tcs_amount,
              additional_charges_or_discounts: paymentInfo?.additional_charges_or_discounts ?? null
            };
          })
        };
      });
      setMakePaymentState((prevState: IMakePaymentState) => ({
        ...prevState,
        invoiceData: invoice,
        isLoading: false,
        paymentData: payment
      }));
      invoiceDetailsForm.reset({ invoice });
      paymentDetailsForm.reset({ invoice: payment });
    } else if (response?.error) {
      notify({
        severity: 'error',
        message: response.error.message ?? 'Something went wrong, Contact Tech Team'
      });
      setMakePaymentState((prevState: IMakePaymentState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  const handleSidebarClick = (value: number) => {
    setMakePaymentState((prevState: IMakePaymentState) => ({ ...prevState, activeStep: value }));
  };

  const handleSidebarEnterKey = (event: any) => {
    if (event.key === 'Enter') {
      setMakePaymentState((prevState: IMakePaymentState) => ({
        ...prevState,
        activeStep: activeStep + 1
      }));
    }
  };

  const handleCancel = () => {
    navigate('/finance');
  };

  const handleNext = () => {
    setMakePaymentState((prevState: IMakePaymentState) => ({
      ...prevState,
      activeStep: activeStep + 1,
      invoiceFormData: invoiceDetailsForm.getValues()
    }));
  };

  const handleFormSubmit = () => {
    setMakePaymentState((prevState: IMakePaymentState) => ({
      ...prevState,
      openConfirmationModal: true,
      invoiceFormData: invoiceDetailsForm.getValues(),
      paymentFormData: paymentDetailsForm.getValues()
    }));
  };

  const handleSubmit = async () => {
    setMakePaymentState((prevState: IMakePaymentState) => ({
      ...prevState,
      openConfirmationModal: false,
      isLoading: true
    }));
    const reqBody = {
      invoices:
        invoiceFormData &&
        invoiceFormData?.invoice.map((invoice: IInvoice) => {
          const matchingPayment = paymentFormData.invoice.find(
            (payment: any) => payment.invoice_info_id === invoice.invoice_info_id
          );
          return {
            invoice_info_id: invoice.invoice_info_id,
            amount: parseFloat(invoice.amount ?? '0'),
            gst_amount: parseFloat(invoice.gst_amount ?? '0'),
            tds_amount: parseFloat(invoice.tds_amount ?? '0'),
            tcs_amount: parseFloat(invoice.tcs_amount ?? '0'),
            payment_info: invoice.payment_infos.map((payment: IPaymentInfo, index: number) => {
              const matchingPaymentId = matchingPayment.payment_infos.find(
                (pay: any) => pay.id === payment.id
              );
              return {
                id: payment.id,
                amount_to_pay:
                  parseFloat(matchingPaymentId.amount_requested ?? '0') -
                  parseFloat(matchingPaymentId.tds_amount ?? '0') +
                  parseFloat(matchingPaymentId.tcs_amount ?? '0') +
                  parseFloat(matchingPaymentId.additional_charges_or_discounts ?? '0'),
                payer_remarks: matchingPaymentId.payer_remarks,
                tcs_amount: parseFloat(matchingPaymentId.tcs_amount ?? '0'),
                tds_amount: parseFloat(matchingPaymentId.tds_amount ?? '0'),
                additional_charges_or_discounts: parseFloat(
                  matchingPaymentId.additional_charges_or_discounts ?? '0'
                )
              };
            })
          };
        })
    };
    const response = await intiatePayment(reqBody);
    if (response.success) {
      notify({
        message: 'Payment initiated successfully',
        severity: 'success'
      });
      navigate('/finance?activeSubTab=initiated');
    } else if (response?.error) {
      notify({
        message: response?.error,
        severity: 'error'
      });
    }
    setMakePaymentState((prevState: IMakePaymentState) => ({
      ...prevState,
      isLoading: false,
      openConfirmationModal: false
    }));
  };

  const handleClosePrompt = () => {
    setMakePaymentState((prevState: IMakePaymentState) => ({
      ...prevState,
      openConfirmationModal: false
    }));
  };

  return (
    <AccessWrapper show={actions?.create} showUnauthorised>
      <main className={css.mainWrapper}>
        <div className={css.titleWrapper}>
          <Typography variant="h2">Make Payment</Typography>
          <Typography variant="subheading1">0{activeStep + 1} of 02</Typography>
        </div>
        <div className={css.makePaymentsContainer}>
          <div className={css.sideBarWrapper}>
            <SideBar
              activeStep={activeStep}
              onClick={handleSidebarClick}
              onEnter={handleSidebarEnterKey}>
              <SideBar.Item label="Invoice Details" value={0} />
              <SideBar.Item label="Payment Details" value={1} disabled={activeStep! < 1} />
            </SideBar>
          </div>
          <FormProvider {...invoiceDetailsForm}>
            {activeStep === 0 && (
              <InvoiceDetails
                onFormSubmit={handleNext}
                onCancelClick={handleCancel}
                invoiceData={invoiceData}
                setMakePaymentState={setMakePaymentState}
              />
            )}
          </FormProvider>
          <FormProvider {...paymentDetailsForm}>
            {activeStep === 1 && (
              <PaymentDetails
                onFormSubmit={handleFormSubmit}
                onCancelClick={handleCancel}
                invoiceData={invoiceData}
                paymentData={paymentData}
                setMakePaymentState={setMakePaymentState}
              />
            )}
          </FormProvider>
        </div>
        {openConfirmationModal && (
          <DeletePrompt
            open={openConfirmationModal}
            onClose={() => handleClosePrompt()}
            onConfirm={handleSubmit}
            message="Are you sure you want to you want to submit this Form?"
          />
        )}
      </main>
    </AccessWrapper>
  );
};

export default MakePayment;
