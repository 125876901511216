import * as yup from 'yup';
import { MAX_ZIP_CODE_LENGTH } from '@helpers/constants';

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

const addressSchema = yup.object().shape(
  {
    address_id: yup.string().trim().optional(),
    address_line1: yup.string().trim().required('Address Line 1 is required'),
    address_line2: yup.string().trim().optional().nullable(),
    address_type: yup.string().trim().optional(),
    zip_code: yup
      .string()
      .trim()
      .when('zip_code', (val, schema) => {
        if (val?.length && val[0]) {
          return yup
            .string()
            .trim()
            .max(
              MAX_ZIP_CODE_LENGTH,
              `Zip Code can be at most ${MAX_ZIP_CODE_LENGTH} characters long`
            );
        } else {
          return yup.string().optional();
        }
      }),
    country: selectSchema.required('Country is required'),
    state: selectSchema.required('State is required'),
    city: selectSchema.required('City is required'),
    starting_statement: yup.string().trim().required('Starting statement is required'),
    // closing_statement: yup.string().trim().optional().nullable()
  },
  [['zip_code', 'zip_code']]
);

export const boeCustomerSchema = yup
  .object()
  .shape({
    name: yup.string().trim().required('Customer name is required')
  })
  .concat(addressSchema);

export const commercialSchema = yup.object().shape({
  commercial_invoice_no: yup.string().trim().required('Commercial Invoice number is required'),
  commercial_invoice_date: yup
    .date()
    .typeError('Invalid date format')
    .required('Commercial Invoice date is required'),
  commercial_invoice_due_amount: yup
    .number()
    .typeError('Due amount should be in number')
    .moreThan(0, 'Should be greater than 0')
    .positive('Should be greater than 0 (zero)')
    .max(99999999999999, "Can't be more than 14 digits")
    .test('decimal', 'Should be upto 2 decimal places', (value) => {
      if (value && !isNaN(value)) {
        const data = value.toString().split('.');
        if (data[1]) return data[1].length <= 2 ? true : false;
        return true;
      }
      return false;
    })
    .required('Commercial invoice due amount is required'),
  bl_awb_no: yup.string().trim().required('Number is required'),
  bl_awb_date: yup.date().typeError('Invalid date format').required('Date is required'),
  payment_terms: yup.string(),
  customer_bank: yup.mixed().nullable()
});
