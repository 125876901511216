import { IconNode, Typography } from '@components/base';
import css from './index.module.scss';
import Images from '@assets/images';
import { FieldValue } from '@helpers/types/coa';

interface NotesPropTypes {
  data: FieldValue[];
  type?: string;
  handleNavigation: (index: number) => void;
}

const Notes = (props: NotesPropTypes) => {
  const { data, type = 'Notes', handleNavigation } = props;
  return (
    <div className={css.boxWrapper}>
      <div className={css.orderContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="p">{type}</Typography>
          <IconNode
            className={css.editIcon}
            src={Images.editRed}
            onClick={() => handleNavigation(2)}
          />
        </div>
        <div>
          {data?.length ? (
            <ol className={css.fieldContent}>
              {data.map((item: any, index: number) => (
                <li key={index}>{item}</li>
              ))}
            </ol>
          ) : (
            <Typography variant="p">-</Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notes;
