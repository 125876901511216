import { Accordion, Button, IconNode, Typography } from '@components/base';
import { Avatar } from '@components/common';
import css from './index.module.scss';
import Images from '@assets/images';
import Footer from '../components/footer';
import { useState } from 'react';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { EstimateDatesModal } from '@components/common';
import notify from '@helpers/toastify-helper';
import { addTaskEstimate } from '@services/order.service';
import { convertDateString } from '@helpers/constants';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface AddEstimateProps {
  taskData: Partial<ITaskTabProps>;
  onComplete?: () => void;
}

const AddEstimate = (props: AddEstimateProps) => {
  const { taskData, onComplete } = props;
  const { alertSuccessFaint, illustrationEdit } = Images;
  const [estimateModal, setEstimateModal] = useState(false);

  const onFormSubmit = async (data: any) => {
    if (data?.estimate_data?.length) {
      const body = data?.estimate_data.map((item: any) => {
        return {
          state_type: item.state_type,
          estimated_date: item.estimated_date
            ? moment(item.estimated_date).format('YYYY-MM-DD')
            : null,
          id: item.id
        };
      });

      const response = await addTaskEstimate(taskData?.task_id ?? '', body);
      if (response.success) {
        notify({
          message: 'Task Estimates added successfully'
        });
        if (onComplete) {
          onComplete();
        }
        setEstimateModal(false);
      } else {
        notify({ message: response.error ?? 'Something went wrong!', severity: 'error' });
      }
    }
  };

  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {taskData?.is_completed ? (
                  <IconNode src={alertSuccessFaint} className={css.successIcon} />
                ) : (
                  <Avatar src={illustrationEdit} alt="edit-icon" />
                )}

                <Link to={`/order/${taskData?.order}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="h5" className={css.underlineOnHover}>
                    #{taskData.readable_task_id} - Add Estimate Dates
                  </Typography>
                </Link>
              </div>
              <div className={css.pocWrapper}>
                {!taskData?.is_completed && (
                  <Button
                    variant="text"
                    className={css.addBtn}
                    onClick={() => setEstimateModal(true)}>
                    Add
                  </Button>
                )}
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        <Footer
          createdDate={taskData.created_at as Date}
          updatedDate={taskData.updated_at as Date}
        />
      </div>
      <EstimateDatesModal
        open={estimateModal}
        onClose={() => setEstimateModal(false)}
        taskData={taskData}
        onComplete={onComplete}
        onFormSubmit={onFormSubmit}
      />
    </>
  );
};

export default AddEstimate;
