import * as yup from 'yup';
import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from '@helpers/constants';
import { paymentTerm } from './add-payment.schema';

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

const uploadECGC = yup.object().shape({
  ecgc_approval_start_date: yup
    .date()
    .typeError('Select valid date')
    .required('Start date is required'),
  ecgc_approval_end_date: yup
    .date()
    .typeError('Select valid date')
    .required('End date is required'),
  ecgc_approved_amount: yup
    .number()
    .typeError('Amount should be in number')
    .moreThan(0, 'Should be greater than 0')
    .positive('Should be greater than 0 (zero)')
    .max(999999999, "Can't be moreThan 9 digits")
    .test('decimal', 'Should be up to 2 decimal places', (value) => {
      if (value) {
        const data = value.toString().split('.');
        if (data[1]) return data[1].length <= 2;
        return true;
      }
      return false;
    })
    .required('Amount is required'),
  ecgc_approval_letter: yup
    .mixed()
    .nullable()
    .test('fileSize', 'File Size is too large', (value: any) =>
      value ? value?.size <= MAX_FILE_SIZE : true
    )
    .test('fileType', 'Unsupported File Format', (value: any) =>
      value ? SUPPORTED_FORMATS.includes(value?.type) : true
    ),
  dnb_report: yup
    .mixed()
    .notRequired()
    .test('fileSize', 'File Size is too large', (value: any) =>
      value ? value?.size <= MAX_FILE_SIZE : true
    )
    .test('fileType', 'Unsupported File Format', (value: any) =>
      value ? SUPPORTED_FORMATS.includes(value?.type) : true
    ),
  payment_terms: yup.array().of(paymentTerm)
});

export { uploadECGC };
