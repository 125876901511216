import css from './index.module.scss';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import CreateEnquiryContainer from './createEnquiryContainer';
import { BreadCrumb, Button } from '@components/base';
import RenderChildrenBasedOnScreen from 'src/hoc/screen-conditions';
import { CLIENT_ROUTES } from '@router/routes';
import { FormType } from '@helpers/types/enquiry';

interface ICreateEnquiryFormProps {
  handleCancelClick: () => void;
  handleSubmitEnquiry: SubmitHandler<FormType>;
  handleFileDrop: (files: File[]) => void;
}

const CreateEnquiryForm = (props: ICreateEnquiryFormProps) => {
  const { handleCancelClick, handleSubmitEnquiry, handleFileDrop } = props;

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext<FormType>();

  return (
    <div>
      <RenderChildrenBasedOnScreen showOnMobile>
        <BreadCrumb>
          <BreadCrumb.Item to={`/${CLIENT_ROUTES.enquiry}`} value={0} onClick={handleCancelClick}>
            Enquiry List
          </BreadCrumb.Item>
          <BreadCrumb.Item to={`/${CLIENT_ROUTES.enquiry}?activestep=placeRFQ`} value={1}>
            Place RFQ
          </BreadCrumb.Item>
        </BreadCrumb>
      </RenderChildrenBasedOnScreen>
      <form noValidate onSubmit={handleSubmit(handleSubmitEnquiry)} className={css.formWrapper}>
        <CreateEnquiryContainer handleFileDrop={handleFileDrop} />
        <div className={css.actionBtnWrapper}>
          <Button variant="text" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Place Enquiry
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateEnquiryForm;
