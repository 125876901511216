import Images from '@assets/images';
import css from './index.module.scss';
import { Button, IconNode, Loader, Modal, Typography } from '@components/base';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { InputDatePicker, SelectLabel, TextField } from '@components/common';
import { booleanSelectFields, CURRENCY } from '@helpers/constants';

interface AddRodTepDrawbackConfirmationProps {
  open: boolean;
  onClose: () => void;
  handleFormSubmit: SubmitHandler<any>;
  formType: string;
}

const AddRodTepDrawbackConfirmation = (props: AddRodTepDrawbackConfirmationProps) => {
  const { open, onClose, handleFormSubmit, formType } = props;

  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Add {formType} Confirmation</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="div"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={css.modalContent}>
            <Controller
              name="claim_confirmation"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  options={booleanSelectFields}
                  required
                  label={`${formType} Claim Confirmation`}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="credit_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label={`${formType} Credit Date`}
                  required
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />
            <Controller
              name="currency"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  options={CURRENCY}
                  required
                  label={`${formType} Currency`}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="amount"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={`${formType} Amount`}
                  placeholder="XXX XXXX"
                  autoComplete="off"
                  enterKeyHint="next"
                  required
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            {formType === 'Rodtep' && (
              <Controller
                name="rodtep_percentage_claimed"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Rodtep Percentage Claimed"
                    placeholder="Enter Percentage"
                    autoComplete="off"
                    enterKeyHint="next"
                    required
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                    }
                  />
                )}
              />
            )}
          </div>
          <div className={css.actionButtonWrapper}>
            <Button variant="outlined-secondary" className={css.actionButton} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" className={css.actionButton}>
              Save
            </Button>
          </div>
        </form>
        <Loader open={isSubmitting} />
      </div>
    </Modal>
  );
};

export default AddRodTepDrawbackConfirmation;
