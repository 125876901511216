import { Modal } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import css from './index.module.scss';
import Typography from '@components/base/typography';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';
import Button from '@components/base/button';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Radio from '@components/base/radio';
import { DocumentRow, DragDropUpload, TextField } from '@components/common';
import { Link } from 'react-router-dom';
import { openDocumentSignedLink } from '@helpers/utils';

interface ResolveInconsistencyModalProps {
  open: boolean;
  onClose: () => void;
  onFormSubmit: (data: any) => void;
  currentDoc: any;
}

const ResolveInconsistencyModal = (props: ResolveInconsistencyModalProps) => {
  const { open, onClose, onFormSubmit, currentDoc } = props;
  const {
    handleSubmit,
    setValue,
    control,
    formState: { isSubmitting }
  } = useFormContext();

  const watch = useWatch({
    name: ['valid_document_type', 'valid_document_object'],
    control: control
  });

  const handleDeleteFile = useCallback(() => {
    setValue('valid_document_object', null);
    setValue('reason_for_update', null);
  }, []);

  const handleFileDrop = useCallback((file: any[]) => {
    if (file.length) {
      setValue('valid_document_object', file[0]);
    }
  }, []);

  useEffect(() => {
    handleDeleteFile();
  }, [watch[0]]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Resolve Inconsistency</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <div className={css.modalDescription}>
          {/* <IconNode src={Images.alertWarning} alt="Warning" /> */}
          <Typography variant="ptext">
            A Document is inconsistent when there are both system generated and manually edited
            versions of document, but the data in them might be different
          </Typography>
        </div>

        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
          <section className={css.modalContent}>
            <Typography variant="label">
              Select which version of this document is the latest
            </Typography>
            <div className={css.fieldWrapper}>
              <div className={css.radioWrapper}>
                <Radio
                  label="Manually Uploaded"
                  value={1}
                  checked={watch[0] === 1}
                  onChange={() => setValue('valid_document_type', 1)}
                />

                <Button
                  style={{ marginLeft: 10 }}
                  variant="text"
                  onClick={() =>
                    openDocumentSignedLink(currentDoc?.documents?.manuallyUploadedDocument)
                  }>
                  View
                </Button>
              </div>
            </div>
            <div className={css.fieldWrapper}>
              <div className={css.radioWrapper}>
                <Radio
                  label="System Generated"
                  value={2}
                  checked={watch[0] === 2}
                  onChange={() => setValue('valid_document_type', 2)}
                />
                <Button
                  style={{ marginLeft: 10 }}
                  variant="text"
                  onClick={() =>
                    openDocumentSignedLink(currentDoc?.documents?.systemGeneratedDocument)
                  }>
                  View
                </Button>
              </div>
            </div>
            <div className={css.fieldWrapper}>
              <Radio
                label="Upload a new one"
                value={3}
                checked={watch[0] === 3}
                onChange={() => setValue('valid_document_type', 3)}
              />
            </div>

            {watch[0] === 3 && (
              <>
                <Controller
                  name="valid_document_object"
                  control={control}
                  render={({ field, fieldState }) => (
                    <DragDropUpload
                      onDrop={handleFileDrop}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      acceptImages
                    />
                  )}
                />

                {watch[1] && (
                  <div className={css.fileUploadState}>
                    <Typography variant="p" className={css.fileName}>
                      {watch[1].name}
                    </Typography>
                    <IconNode
                      alt="delete icon"
                      component="button"
                      src={Images.deleteDark}
                      onClick={handleDeleteFile}
                    />
                  </div>
                )}

                <Controller
                  name="reason_for_update"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required={true}
                      label="Reason for Re-Upload"
                      rootClassName={css.fieldWrapper}
                      placeholder="Please provide a reason for re-uploading this document"
                      error={fieldState.invalid}
                      endIcon={
                        fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                      }
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </>
            )}
          </section>
          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Resolve
            </Button>
          </section>
        </form>
      </div>
    </Modal>
  );
};

export default ResolveInconsistencyModal;
