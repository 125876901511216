import { IInternalEnquiryParamsList } from '@helpers/types/enquiry';
import httpClient from './index.service';
import { CUSTOMER_SERVER_CONFIG, SERVER_CONFIG, USER_TYPES } from '@helpers/constants';
import { getPermissionFromLocalStorage } from '@helpers/utils';

export const getEnquiryList = async (params?: IInternalEnquiryParamsList) => {
  const userType = getPermissionFromLocalStorage()?.user_type;
  try {
    const response =
      userType === USER_TYPES.internal
        ? await httpClient.get(`${SERVER_CONFIG.fetchCustomer}${SERVER_CONFIG.rfqs}`, {
            params: params
          })
        : await httpClient.get(`${CUSTOMER_SERVER_CONFIG.main}/${SERVER_CONFIG.rfqs}`, {
            params: params
          });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const downloadDocuments = async (enquiryId: string) => {
  const userType = getPermissionFromLocalStorage()?.user_type;
  try {
    const response =
      userType === USER_TYPES.internal
        ? await httpClient.get(
            `${SERVER_CONFIG.fetchCustomer}${SERVER_CONFIG.rfq}/${enquiryId}/${SERVER_CONFIG.unshareDocument}${SERVER_CONFIG.downloadZip}`
          )
        : await httpClient.get(
            `${CUSTOMER_SERVER_CONFIG.main}/${SERVER_CONFIG.rfq}/${enquiryId}/${SERVER_CONFIG.unshareDocument}${SERVER_CONFIG.downloadZip}`
          );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const internalFilterOptions = async () => {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.fetchCustomer}${SERVER_CONFIG.rfqs}${SERVER_CONFIG.formFilterOptions.index}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const updateInternalAssignTo = async (userId: string, enquiryId: string) => {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.fetchCustomer}${SERVER_CONFIG.rfq}/${enquiryId}/`,
      {
        assigned_to: userId
      }
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const updateInternalEnquiryStatus = async (status: number, enquiryId: string) => {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.fetchCustomer}${SERVER_CONFIG.rfq}/${enquiryId}/`,
      {
        status: status
      }
    );
    return response;
  } catch (error: any) {
    return error;
  }
};
