import React from 'react';
import { BoxContainer } from '@components/common';
import { Button } from '@components/base';
import { useFieldArray, useFormContext } from 'react-hook-form';
import DescriptionField from './components/description';
import Notes from './components/notes';
import Remarks from './components/remarks';
import { FormPropsStates } from '@helpers/types/coa';
import css from './index.module.scss';

const AdditionalData = (props: FormPropsStates) => {
  const { onFormSubmit, handleCancelClick } = props;
  const { control, handleSubmit } = useFormContext<any>();

  const {
    fields: notesFields,
    append: notesAppend,
    remove: notesRemove,
    update: notesUpdate
  } = useFieldArray({
    name: 'notes',
    control
  });

  const {
    fields: remarksFields,
    append: remarksAppend,
    remove: remarksRemove,
    update: remarksUpdate
  } = useFieldArray({
    name: 'remarks',
    control
  });

  const handleNoteAddField = () => {
    notesAppend({
      field_value: ''
    });
  };

  const handleRemarksAddField = () => {
    remarksAppend({
      field_value: ''
    });
  };

  return (
    <form noValidate className={css.coaFormWrapper} onSubmit={handleSubmit(onFormSubmit)}>
      <BoxContainer title="Order Details">
        <div className={css.orderDetailsWrapper}>
          <DescriptionField />
          <Notes data={notesFields} remove={notesRemove} handleAddField={handleNoteAddField} />
          <Remarks
            data={remarksFields}
            remove={remarksRemove}
            handleAddField={handleRemarksAddField}
          />
          <div className={css.formActionWrapper}>
            <Button variant="text" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button type="submit">Save and Proceed</Button>
          </div>
        </div>
      </BoxContainer>
    </form>
  );
};

export default AdditionalData;
