import * as yup from 'yup';
import { selectSchema } from '.';

export const emailSchema = yup.object().shape({
  bcc: yup.array().of(selectSchema).optional().nullable(),
  cc: yup.array().of(selectSchema),
  to: yup.array().of(selectSchema).min(1, 'To is required'),
  body: yup.string().required('Body is required'),
  subject: yup.string().required('Subject is required'),
  reply_to: selectSchema.required('Reply To is required'),
  template_type: selectSchema.required('Template Type is required')
});
