import Images from '@assets/images';
import { Accordion, Chip, Divider, Typography } from '@components/base';
import { Avatar } from '@components/common';
import { useState } from 'react';
import css from './index.module.scss';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';
import { Form, Link } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDrawbackConfirmationValidation } from '@helpers/yup/add-rodtep-drawback.schema';
import notify from '@helpers/toastify-helper';
import { addDrawbackConfirmation } from '@services/order.service';
import AddRodTepDrawbackConfirmation from '@components/common/addRodtepDrawbackConfirmation';
import moment from 'moment';

interface DrawbackConfirmationProps {
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete?: () => void;
}

interface FormType {
  claim_confirmation: {
    label: string;
    value: boolean;
  };
  currency: {
    label: string;
    value: string;
  };
  amount: number | null;
  credit_date: string | null;
}

const AddDrawbackConfirmationCard = (props: DrawbackConfirmationProps) => {
  const [openModal, setopenModal] = useState(false);
  const getCompleteStatus = props.taskData[0].is_completed;
  const [taskIsCompleted, setTaskIsCompleted] = useState<boolean | undefined>(getCompleteStatus);
  const { taskData } = props;

  const handleStatusFromModal = (data: boolean) => {
    setTaskIsCompleted(data);
  };

  const addDrawbackConfirmationForm = useForm<FormType>({
    resolver: yupResolver(addDrawbackConfirmationValidation),
    defaultValues: {
      claim_confirmation: {
        label: 'No',
        value: false
      },
      currency: taskData[0]?.drawback_currency?.length
        ? {
            label: taskData[0]?.drawback_currency,
            value: taskData[0]?.drawback_currency
          }
        : {
            label: 'INR',
            value: 'INR'
          },
      amount: taskData[0]?.drawback_amount ?? null,
      credit_date: taskData[0]?.drawback_credit_date ?? null
    }
  });

  const handleFormSubmit: SubmitHandler<any> = async (data) => {
    const reqBody = {
      drawback_claim_confirmation: data.claim_confirmation.value,
      drawback_currency: data.currency.value,
      drawback_amount: data.amount,
      drawback_credit_date: moment(data.credit_date).format('YYYY-MM-DD') ?? ''
    };

    const response = await addDrawbackConfirmation(taskData[0].task_id as string, reqBody);
    if (response.success) {
      notify({
        message: 'Drawback Confirmation added successfully',
        severity: 'success'
      });
      handleStatusFromModal(true);
      if (props.onComplete) {
        props.onComplete();
      }
    } else if (response?.error) {
      notify({
        message: response?.error ?? 'Something went wrong, Contact Tech Team',
        severity: 'error'
      });
    }
  };

  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {getCompleteStatus || taskIsCompleted ? (
                  <Avatar src={Images.alertSuccessFaint} alt="" />
                ) : (
                  <Avatar src={Images.illustration} alt="" />
                )}

                <Link to={`/order/${taskData[0]?.order}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="h5" className={css.underlineOnHover}>
                    #{taskData[0]?.readable_task_id} - Add Drawback Confirmation
                  </Typography>
                </Link>
              </div>
              <div className={css.pocWrapper}>
                {!getCompleteStatus && taskIsCompleted === false && (
                  <Chip
                    label="Add Details"
                    variant="outlined"
                    clickable={true}
                    className={css.uploadButton}
                    onClick={() => setopenModal(true)}
                  />
                )}
                {(!getCompleteStatus || !taskIsCompleted) && <Divider className={css.divider} />}
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        <Footer
          createdDate={props.taskData[0].created_at as Date}
          updatedDate={props.taskData[0].updated_at as Date}
        />
        {openModal && (
          <FormProvider {...addDrawbackConfirmationForm}>
            <AddRodTepDrawbackConfirmation
              open={openModal}
              onClose={() => setopenModal(false)}
              handleFormSubmit={handleFormSubmit}
              formType="Drawback"
            />
          </FormProvider>
        )}
      </div>
    </>
  );
};

export default AddDrawbackConfirmationCard;
