/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-empty-function */
import Images from '@assets/images';
import { Button, IconNode, Modal, Typography } from '@components/base';
import {
  DragDropUpload,
  InputDatePicker,
  SelectLabel,
  TextEditor,
  TextField
} from '@components/common';
import TextareaLabel from '@components/common/textarea-label';
import notify from '@helpers/toastify-helper';
import { addCourierDetails as addCourierDetailsSchema } from '@helpers/yup/add-courier-details.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { addCourierDetails } from '@services/task.service';
import { useCallback, useEffect } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import css from './index.module.scss';
import { COMMUNICATION_TYPE, CURRENCY, booleanSelectFields } from '@helpers/constants';
import { ISelect } from '@helpers/types';
const moment = require('moment');

interface CourierDetailsModalProps {
  open: boolean;
  onClose?: () => void;
  taskId: string;
  sendStatusToCard: (arg: boolean) => void;
  onComplete?: () => void;
}

interface FormType {
  serviceName: string;
  trackingName: string;
  customerName: string;
  placeOfDelivery: string;
  courierDate: Date | null;
  numOfItems: string;
  courierCharges: string;
  notes: string;
  ecgcDoc: File | null;
  is_sent_to_customer: ISelect | null;
  documents_courier_currency: string | null;
  telex_message: string;
  telex_remarks: string;
  communication_type: ISelect | null;
}

const AddCourierDetailsModal = (props: CourierDetailsModalProps) => {
  const { open, onClose = () => {}, taskId, sendStatusToCard, onComplete } = props;

  const addCourierDetailsForm = useForm<FormType>({
    resolver: yupResolver(addCourierDetailsSchema),
    defaultValues: {
      serviceName: '',
      trackingName: '',
      placeOfDelivery: '',
      numOfItems: '',
      courierCharges: '',
      notes: '',
      courierDate: null,
      ecgcDoc: null,
      is_sent_to_customer: null,
      documents_courier_currency: null,
      telex_message: '',
      telex_remarks: '',
      communication_type: null
    }
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = addCourierDetailsForm;

  const watch = useWatch({ name: 'ecgcDoc', control: control });
  const courierSentToCustWatch = useWatch({ name: 'is_sent_to_customer', control: control });
  const communicationTypeWatch = useWatch({ name: 'communication_type', control: control });
  const handleECGCFileChange = (file: any[]) => {
    if (file.length) {
      setValue('ecgcDoc', file[0]);
    }
  };

  const handleDeleteFile = useCallback(() => {
    setValue('ecgcDoc', null);
  }, []);

  const handleFormSubmit: SubmitHandler<Partial<FormType>> = async (param) => {
    if (!param.ecgcDoc && communicationTypeWatch?.value == 'courier') {
      notify({
        message: 'Please upload Courier slip',
        title: 'Error Occured',
        severity: 'error',
        dismissible: true
      });
      return;
    }
    const formData = new FormData();
    if (courierSentToCustWatch?.value) {
      if (communicationTypeWatch?.value === 'courier') {
        formData.append(
          'documents_courier_date',
          moment(param.courierDate as Date).format('YYYY-MM-DD') ?? ''
        );
        formData.append('documents_courier_service_name', param.serviceName ?? '');
        formData.append('documents_courier_tracking_number', param.trackingName ?? '');
        formData.append('documents_courier_slip', param.ecgcDoc as Blob);
        formData.append('documents_courier_place_of_delivery', param.placeOfDelivery ?? '');
        formData.append('documents_courier_no_of_items', param.numOfItems ?? '');
        formData.append('documents_courier_charges', param.courierCharges ?? '');
        formData.append('documents_courier_remarks', param.notes ?? '');
      } else if (communicationTypeWatch?.value === 'telex') {
        formData.append('telex_message', param.telex_message ?? '');
        formData.append('telex_remarks', param.telex_remarks ?? '');
      }
    } else {
      formData.append('not_applicable', 'true');
    }

    const response = await addCourierDetails(taskId as string, formData);
    const { data, error, success } = response;
    if (success) {
      notify({ message: data.message, dismissible: true });
      sendStatusToCard(true);
      if (onComplete) {
        onComplete();
      }
    } else
      notify({
        message: error ?? 'Unable to add courier details',
        title: 'Error Occured',
        severity: 'error',
        dismissible: true
      });
    onClose();
  };

  useEffect(() => {
    if (!courierSentToCustWatch || !courierSentToCustWatch?.value) {
      setValue('communication_type', null);
    }
  }, [courierSentToCustWatch]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Courier Details</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="div"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={css.fieldsWrapper}>
            <Controller
              name="is_sent_to_customer"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  required
                  label="Was message sent to customer?"
                  isSearchable
                  options={booleanSelectFields?.length ? booleanSelectFields : []}
                  getOptionLabel={(option: any) => `${option.label}`}
                  getOptionValue={(option: any) => option.value}
                  rootClassName={css.fieldSpacing}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            {courierSentToCustWatch && courierSentToCustWatch?.value ? (
              <Controller
                name="communication_type"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    required
                    label="Communication Type"
                    isSearchable
                    isDisabled={!courierSentToCustWatch || !courierSentToCustWatch.value}
                    options={COMMUNICATION_TYPE?.length ? COMMUNICATION_TYPE : []}
                    getOptionLabel={(option: any) => `${option.label}`}
                    getOptionValue={(option: any) => option.value}
                    rootClassName={css.fieldSpacing}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            ) : (
              <></>
            )}

            {communicationTypeWatch?.value === 'courier' && courierSentToCustWatch?.value ? (
              <>
                <Controller
                  name="serviceName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      label="Service Name"
                      disabled={!courierSentToCustWatch || !courierSentToCustWatch.value}
                      placeholder="Enter Service Name"
                      rootClassName={css.fieldSpacing}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  name="trackingName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      label="Tracking Number"
                      disabled={!courierSentToCustWatch || !courierSentToCustWatch.value}
                      placeholder="Enter Tracking Number"
                      rootClassName={css.fieldSpacing}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  name="placeOfDelivery"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      label="Place of Delivery"
                      disabled={!courierSentToCustWatch || !courierSentToCustWatch.value}
                      placeholder="Enter place"
                      rootClassName={css.fieldSpacing}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  name="courierDate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputDatePicker
                      {...field}
                      required
                      label="Courier Date"
                      disabled={!courierSentToCustWatch || !courierSentToCustWatch.value}
                      rootClassName={css.fieldSpacing}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                      value={field.value}
                    />
                  )}
                />

                <Controller
                  name="numOfItems"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      label="Number of Items"
                      disabled={!courierSentToCustWatch || !courierSentToCustWatch.value}
                      placeholder="Enter Number of Items"
                      rootClassName={css.fieldSpacing}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />

                <div className={css.rowWrapper}>
                  <Controller
                    name="documents_courier_currency"
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectLabel
                        {...field}
                        required
                        label="Currency"
                        isSearchable
                        isDisabled={!courierSentToCustWatch || !courierSentToCustWatch.value}
                        options={CURRENCY?.length ? CURRENCY : []}
                        getOptionLabel={(option: any) => `${option.label}`}
                        getOptionValue={(option: any) => option.value}
                        rootClassName={css.currencyFieldSpacing}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="courierCharges"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        label="Courier Charges"
                        disabled={!courierSentToCustWatch || !courierSentToCustWatch.value}
                        placeholder="Enter Charges"
                        rootClassName={css.fieldSpacing}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </div>

                <Controller
                  name="notes"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextareaLabel
                      {...field}
                      label="Remarks/Notes"
                      disabled={!courierSentToCustWatch || !courierSentToCustWatch.value}
                      rootClassName={css.fieldSpacing}
                      placeholder="Enter a Remark/Note"
                      className={css.textArea}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  name="ecgcDoc"
                  control={control}
                  render={({ fieldState }) => (
                    <DragDropUpload
                      disabled={!courierSentToCustWatch || !courierSentToCustWatch.value}
                      onDrop={handleECGCFileChange}
                      required
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                {watch && (
                  <div className={css.fileUploadState}>
                    <Typography variant="p" className={css.fileName}>
                      {watch.name}
                    </Typography>
                    <IconNode
                      src={Images.deleteRed}
                      alt="delete icon"
                      component="button"
                      className={css.deleteButton}
                      onClick={handleDeleteFile}
                    />
                  </div>
                )}
              </>
            ) : (
              <></>
            )}

            {communicationTypeWatch?.value === 'telex' && courierSentToCustWatch?.value ? (
              <>
                <Controller
                  name="telex_message"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextEditor
                      {...field}
                      required
                      label="Telex Message"
                      placeholder="Enter telex message"
                      rootClassName={css.fieldSpacing}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  name="telex_remarks"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextareaLabel
                      {...field}
                      label="Remarks/Notes"
                      disabled={!courierSentToCustWatch || !courierSentToCustWatch.value}
                      rootClassName={css.fieldSpacing}
                      placeholder="Enter a Remark/Note"
                      className={css.textArea}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className={css.actionButtonWrapper}>
            <Button variant="outlined-secondary" className={css.actionButton} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" className={css.actionButton}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddCourierDetailsModal;
