import { Typography } from '@components/base';
import css from './index.module.scss';
import { chunkArray, getCommaSepratedValue, getFormattedDate } from '@helpers/utils';
import {
  IFilteredValue,
  IOrderPayment,
  PaymentDetails as IPaymentDetails
} from '../../../orderOverview';
import RenderChildrenBasedOnScreen from 'src/hoc/screen-conditions';

interface IPaymentDetailsProps {
  previewData: {
    paymentDetails: IPaymentDetails;
    currency: string;
  };
  filteredValue?: IFilteredValue;
}

const PaymentDetails = (props: IPaymentDetailsProps) => {
  const { previewData, filteredValue } = props;
  const { paymentDetails, currency } = previewData;

  const addAdditionalClass = (key: string) => {
    return filteredValue?.key == key ? css.filtered : '';
  };

  return (
    <div className={css.detailWrapper}>
      <div className={css.detailsTitle}>
        <Typography variant="h4">Payment Details</Typography>
      </div>
      <div className={css.detailContainer}>
        {paymentDetails.orderPayment?.length > 0 && (
          <div className={css.paymentDetailsTableWrapper}>
            <div className={css.paymentDetailHeader}>
              <RenderChildrenBasedOnScreen showOnWeb>
                <Typography variant="label" className={css.paymentDetailHeaderLabel}>
                  S.No.
                </Typography>
              </RenderChildrenBasedOnScreen>

              <Typography variant="label" className={css.paymentDetailHeaderLabel}>
                Payment Type
              </Typography>
              {/* <Typography variant="label" className={css.paymentDetailHeaderLabel}>
                Payment Mode
              </Typography> */}
              <Typography variant="label" className={css.paymentDetailHeaderLabel}>
                Payment Date
              </Typography>
              <Typography variant="label" className={css.paymentDetailHeaderLabel}>
                Amount Paid
              </Typography>
              <Typography variant="label" className={css.paymentDetailHeaderLabel}>
                Additional Charges
              </Typography>
            </div>
            {paymentDetails.orderPayment?.map((item: IOrderPayment, index: number) => (
              <>
                <div className={css.paymentDetailHeader}>
                  <RenderChildrenBasedOnScreen showOnWeb>
                    <Typography variant="body">{index + 1}</Typography>
                  </RenderChildrenBasedOnScreen>
                  <Typography variant="body">{item.payment_type}</Typography>
                  {/* <Typography variant="body">{item.payment_mode}</Typography> */}
                  <Typography variant="body">{getFormattedDate(item.payment_date)}</Typography>
                  <Typography variant="body">
                    {currency} {getCommaSepratedValue(item.amount_paid)}
                  </Typography>
                  <Typography variant="body">
                    {currency} {item.additional_charges}
                  </Typography>
                </div>
              </>
            ))}
          </div>
        )}
        <div className={css.rowWrapper}>
          <div className={`${css.fieldWrapper} ${addAdditionalClass('paymentTerm')}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass('paymentTerm')}`}>
              Payment Term
            </Typography>
            {paymentDetails?.paymentTerm ? (
              <Typography variant="body" className={`${addAdditionalClass('paymentTerm')}`}>
                {paymentDetails?.paymentTerm}
              </Typography>
            ) : (
              <Typography
                variant="label"
                className={`${css.notAvailable} ${addAdditionalClass('paymentTerm')}`}>
                Not Available
              </Typography>
            )}
          </div>
          <div className={`${css.fieldWrapper} ${addAdditionalClass('exportBillBankRefNumber')}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass('exportBillBankRefNumber')}`}>
              Export Bill Bank Reference Number
            </Typography>
            {paymentDetails?.export_bill_bank_reference_no ? (
              <Typography
                variant="body"
                className={`${addAdditionalClass('exportBillBankRefNumber')}`}>
                {paymentDetails?.export_bill_bank_reference_no}
              </Typography>
            ) : (
              <Typography
                variant="label"
                className={`${css.notAvailable} ${addAdditionalClass('exportBillBankRefNumber')}`}>
                Not Available
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
