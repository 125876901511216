import {
  AutoComplete,
  BoxContainer,
  InputDatePicker,
  SelectLabel,
  TextField
} from '@components/common';
import css from './index.module.scss';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { BL_TYPES, FREIGHT_TYPES, PORT_FIELD_TYPES } from '@helpers/constants';
import { Button, CheckboxLabel, IconNode } from '@components/base';
import Images from '@assets/images';
import SealNumberWrapper from './components/sealWrapper';
import HAZDetails from './components/HAZDetails';
import { useEffect, useState } from 'react';
import { IOrderItem, ITaskItemProps } from '@helpers/types/task-tabs';
import { formatPortOptions } from '@helpers/utils';
import notify from '@helpers/toastify-helper';
import { fetchPortList } from '@services/util.service';
import { IShippingFormData } from '@helpers/types/si';

interface IShippingInstructionFormProps {
  data: ITaskItemProps;
  shippingFormData: IShippingFormData;
  handleShippingInstructionSubmit: (data: any) => void;
  handleCancelClick: () => void;
}

const ShippingInstructionForm = (props: IShippingInstructionFormProps) => {
  const { data, handleShippingInstructionSubmit, handleCancelClick, shippingFormData } = props;
  const { handleSubmit, control, setValue } = useFormContext();
  const [portOfFinalDestination, setPortOfFinalDestination] = useState([]);
  const switchBLCaseWatch = useWatch({
    name: 'switch_bl_case',
    control
  });

  const [notifyParties, setNotifyParties] = useState({
    notify1: false,
    notify2: false
  });

  const isBlOrAwb = data?.transportation_mode === 'AIR' ? 'AWB' : 'BL';

  const filterPorts = async (event?: any, param = '', fieldType?: string) => {
    const portType = data?.transportation_mode;
    let portTypeLabel = '';
    if (portType === 'SEA') {
      portTypeLabel = 'seaport';
    } else if (portType === 'AIR') {
      portTypeLabel = 'airport';
    }
    const portList = await fetchPortList({ port_type: portTypeLabel, search_text: param });
    if (portList?.success) {
      setPortOfFinalDestination(portList.data.filter_options);
    } else {
      notify({ message: portList?.error || `Internal Server Error`, severity: 'error' });
    }
  };

  useEffect(() => {
    filterPorts();
  }, []);

  useEffect(() => {
    if (shippingFormData.notify_party_1.length > 0 && shippingFormData.notify_party_2.length && shippingFormData.switch_bl_case == switchBLCaseWatch) {
      setValue('notify_party_1', shippingFormData.notify_party_1);
      setValue('notify_party_2', shippingFormData.notify_party_2);
    } else if (data?.order_flow_D) {
      if (switchBLCaseWatch) {
        setValue('notify_party_1', '');
        setNotifyParties((prev) => ({ ...prev, notify1: false }));
        if (!data?.consignee_same_as_customer) {
          setValue('notify_party_1', data?.customer);
          setNotifyParties((prev) => ({ ...prev, notify1: true }));
          setValue('notify_party_2', '');
          setNotifyParties((prev) => ({ ...prev, notify2: false }));
        }
      } else {
        setValue('notify_party_1', data?.elchemy_header);
        setNotifyParties((prev) => ({ ...prev, notify1: true }));
        if (!data?.consignee_same_as_customer) {
          setValue('notify_party_2', data?.customer);
          setNotifyParties((prev) => ({ ...prev, notify2: true }));
        }
      }
    } else {
      setValue('notify_party_1', '');
      setNotifyParties((prev) => ({ ...prev, notify1: false }));
      setValue('notify_party_2', '');
      setNotifyParties((prev) => ({ ...prev, notify2: false }));
      if (!data?.consignee_same_as_customer) {
        setValue('notify_party_1', data?.customer);
        setNotifyParties((prev) => ({ ...prev, notify1: true }));
        setValue('notify_party_2', '');
        setNotifyParties((prev) => ({ ...prev, notify2: false }));
      }
    }
  }, [switchBLCaseWatch]);

  const hazItems = data?.order_items.filter((item: IOrderItem) => item.haz);

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleShippingInstructionSubmit)}
      className={css.formWrapper}>
      <BoxContainer title="Shipping Instruction Details">
        <div className={css.orderContainer}>
          <div className={css.fieldWrapper}>
            <Controller
              name="blType"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  options={BL_TYPES}
                  required
                  label="BL Type"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="placeOfReceipt"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Place of Receipt"
                  placeholder="Place of Receipt"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.fieldWrapper}>
            <Controller
              name="portOfFinalDestination"
              control={control}
              render={({ field, fieldState }) => {
                const onPortSelection = (event: any, param: any) => {
                  if (param) {
                    setValue('portOfFinalDestination', param);
                  } else {
                    setValue('portOfFinalDestination', null);
                  }
                };
                const formattedPortOfFinalDestination = formatPortOptions(portOfFinalDestination);
                return (
                  <AutoComplete
                    {...field}
                    label="Port Of Final Destination"
                    placeholder="Start typing"
                    options={formattedPortOfFinalDestination}
                    onInputChange={(event: any, param: any) =>
                      filterPorts(event, param, PORT_FIELD_TYPES.PORT_OF_FINAL_DESTINATION)
                    }
                    onInputSelection={onPortSelection}
                    keyOption="label"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldWrapper1}
                  />
                );
              }}
            />
          </div>
          <div className={css.fieldWrapper2}>
            <Controller
              name="freight"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  options={FREIGHT_TYPES}
                  required
                  label="Freight"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.fieldWrapper}>
            <Controller
              name="shippingBillNumber"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Shipping Bill Number"
                  placeholder="Shipping Bill Number"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="shippingBillDate"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="Shipping Bill Date"
                  value={field.value}
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />
          </div>
          <div className={css.fieldWrapper}>
            <SealNumberWrapper fieldName={'sealNumber'} inputName={'Seal Number'} />
          </div>
          <div className={css.fieldWrapper}>
            <SealNumberWrapper fieldName={'customSealNumber'} inputName={'Custom Seal Number'} />
          </div>
          <div className={css.fieldWrapper}>
            <Controller
              name="switch_bl_case"
              control={control}
              render={({ field }) => (
                <CheckboxLabel
                  {...field}
                  required
                  label={`Is This a Switch ${isBlOrAwb} Case`}
                  value={`${field.value}`}
                  checked={field.value}
                />
              )}
            />
          </div>
          <div className={css.fieldWrapper}>
            <Controller
              name="notify_party_1"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  label="Notify Party 1"
                  disabled={notifyParties.notify1}
                  placeholder="Notify Party 1"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="notify_party_2"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  label="Notify Party 2"
                  disabled={notifyParties.notify2}
                  placeholder="Notify Party 2"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
        </div>
      </BoxContainer>
      {hazItems?.map((item: IOrderItem, index: number) => {
        return <HAZDetails key={index} data={item} index={index} />;
      })}
      <div className={css.formActionWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button type="submit">Save and Proceed</Button>
      </div>
    </form>
  );
};

export default ShippingInstructionForm;
