import css from './index.module.scss';
import { Typography } from '@components/base';
import { memo } from 'react';

const NoSupplierPlaceHolder = () => {
  return (
    <div className={css.containerWrapper}>
      <Typography variant="h4">Supplier has not been added to this Product</Typography>
      <Typography variant="body">
        When a supplier is added to a product, they will show up here.
      </Typography>
    </div>
  );
};
export default memo(NoSupplierPlaceHolder);
