import { useFieldArray, useFormContext } from 'react-hook-form';
import css from './index.module.scss';
import Button from '@components/base/button';
import ExporterDetails from '../exporter';
import Consignee from '../consignee';
import Item from '../item';
import OrderDetails from '../order-details';
import { ISelect } from '@helpers/types';
import { OrderInfo } from '@helpers/types/order';
import { FieldsThatWillBeModified } from '../..';

interface FormWrapperProps {
  onFormSubmit: (data: any) => void;
  onCancelClick: () => void;
  countryList: ISelect[];
  orderInfo: OrderInfo | null;
  unitOfWeights: ISelect[];
  packageTypes: ISelect[];
  fieldsThatWillBeModified: FieldsThatWillBeModified;
  setDirtyFields: (fields: FieldsThatWillBeModified) => void;
  dirtyFieldsArray: any;
}

const FormWrapper = (props: FormWrapperProps) => {
  const {
    onFormSubmit,
    onCancelClick,
    // entityList,
    countryList,
    orderInfo,
    unitOfWeights,
    packageTypes,
    fieldsThatWillBeModified,
    setDirtyFields,
    dirtyFieldsArray
  } = props;
  const {
    handleSubmit,
    formState: { isDirty }
  } = useFormContext();

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
        {/* <ExporterDetails entityList={entityList} /> */}
        <Consignee
          currentCustomer={orderInfo?.customer ?? null}
          editable={fieldsThatWillBeModified?.consignee_details}
          setDirtyFields={setDirtyFields}
        />
        <Item
          unitOfWeights={unitOfWeights}
          packageTypes={packageTypes}
          editable={fieldsThatWillBeModified}
          currency={orderInfo?.currency ?? ''}
          orderUnitOfWeight={orderInfo?.unit_of_weight ?? ''}
          buyingPrice={orderInfo?.buying_price ?? null}
          sellingPrice={orderInfo?.selling_price ?? null}
          itemDetails={orderInfo?.order_item ?? []}
          setDirtyFields={setDirtyFields}
          dirtyFields={dirtyFieldsArray}
        />
        <OrderDetails
          orderInfo={orderInfo}
          editableFields={fieldsThatWillBeModified}
          setDirtyFields={setDirtyFields}
          dirtyFields={dirtyFieldsArray}
        />
        <div className={css.actionBtnWrapper}>
          <Button variant="text" onClick={onCancelClick}>
            Cancel
          </Button>
          <div>
            <Button type="submit" disabled={!isDirty}>
              Save and Proceed
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default FormWrapper;
