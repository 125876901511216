import { Typography, Modal, IconNode, Button, Divider } from '@components/base';
import { TextField } from '@components/common';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import Images from '@assets/images';
import { useEffect } from 'react';
import { CONTAINER_TYPE } from '@helpers/constants';
import { addContainerData } from '@services/task.service';
import notify from '@helpers/toastify-helper';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';

import css from './index.module.scss';

interface ContainerDetailsModalProps {
  open: boolean;
  onClose: () => void;
  taskData: any;
  onComplete?: () => void;
}

const ContainerDetailsModal = (props: ContainerDetailsModalProps) => {
  const { open, onClose, taskData, onComplete } = props;
  const { container_data, task_id } = taskData;
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext();

  const { fields, append } = useFieldArray({
    name: 'container',
    control
  });

  useEffect(() => {
    if (container_data?.length) {
      append(
        container_data.map((item: any) => ({
          container_id: item?.container_id,
          container_type: CONTAINER_TYPE.find((con: any) => con.value === item?.container_type)
            ?.label,
          container_no: null
        }))
      );
    }
  }, [container_data]);

  const onFormSubmit = async (data: any) => {
    const body = {
      container_carrier_name: data.container_carrier_name,
      container_data: data.container.map((item: any) => {
        return {
          container_id: item.container_id,
          container_number: item.container_no
        };
      })
    };
    const response = await addContainerData(task_id, body);
    if (response.success) {
      notify({
        message: 'Container details added successfully'
      });
      if (onComplete) {
        onComplete();
      }
      onClose();
    } else {
      notify({ message: response.error ?? 'Something went wrong!', severity: 'error' });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Add Container Details</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>

        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
          <section className={css.modalContent}>
            <Controller
              name="container_carrier_name"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  autoFocus
                  label="Carrier Name"
                  placeholder="Enter Carrier Name"
                  rootClassName={css.typeField}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Divider />
            <div>
              {fields.map((item: any, index) => (
                <ContainerDataForm
                  key={item.id}
                  index={index}
                  container_type={item.container_type}
                  container_number={`container.${index}.container_no`}
                />
              ))}
            </div>
          </section>
          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </section>
        </form>
      </div>
    </Modal>
  );
};

interface ContainerDataFormProps {
  container_type: string;
  index: number;
  container_number: string;
}

const ContainerDataForm = (props: ContainerDataFormProps) => {
  const { container_type, container_number } = props;
  const { control } = useFormContext();
  return (
    <>
      <div className={css.rowWrapper}>
        <Controller
          name={container_number}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              inputMode="numeric"
              enterKeyHint="next"
              label={container_type}
              placeholder="Enter container number"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              rootClassName={css.fieldWrapper}
            />
          )}
        />
      </div>
    </>
  );
};

export default ContainerDetailsModal;
