import { useEffect, useRef, useState } from 'react';
import Popover from '../../base/popover';
import _ from 'lodash';
import css from './index.module.scss'
interface PopOverButtonProps {
  children: React.ReactNode;
  openButton: React.ReactNode;
  visible?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
  setIsPopoverOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}
export interface ILocationProperties {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

const PopOverButton = ({
  children,
  openButton,
  visible = false,
  position = 'top',
  setIsPopoverOpen,
  disabled = false,
  ...otherProps
}: PopOverButtonProps) => {
  const [isOpen, setIsOpen] = useState(visible);
  const popoverRef = useRef<HTMLDivElement>(null);
  const openButtonRef = useRef<HTMLDivElement>(null);
  const [location, setLocation] = useState<ILocationProperties>();
  useEffect(() => {
    setIsOpen(visible);
  }, [visible]);

  const handleClose = () => {
    setIsPopoverOpen?.(false);
    setIsOpen(false);
  };

  useEffect(() => {
    const mainElement = document.querySelector('main');
    const handleGlobalClick = (event: MouseEvent) => {
      if (isOpen && popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        handleClose();
      }
    };
    if (isOpen) {
      if (mainElement) {
        mainElement.style.overflow = 'hidden';
      }
      document.addEventListener('click', handleGlobalClick);
    }
    return () => {
      document.removeEventListener('click', handleGlobalClick);
      if (mainElement) {
        mainElement.style.overflow = 'auto';
      }
    };
  }, [isOpen]);

  const getVisibleElementLocation = (element: HTMLDivElement) => {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    if (rect.bottom >= 0 && rect.top <= windowHeight) {
      return {
        top: rect.top,
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right
      };
    } else {
      return null;
    }
  };

  const debouncedHandleScroll = _.debounce(() => {
    if (openButtonRef.current) {
      const locationProperties = getVisibleElementLocation(openButtonRef.current);
      if (locationProperties) {
        setLocation(locationProperties);
      }
    }
  }, 200);

  const handleOpen = () => {
    debouncedHandleScroll();
    setIsPopoverOpen?.(true);
    !disabled && setIsOpen(!isOpen);
  };

  return (
    <div ref={popoverRef} {...otherProps}>
      {openButton && (
        <div ref={openButtonRef} className={css.triggerButton} onClick={handleOpen}>
          {openButton}
        </div>
      )}
      <Popover visible={isOpen} position={position} rect={location}>
        {children}
      </Popover>
    </div>
  );
};

export default PopOverButton;
