import css from './index.module.scss';
import { BoxContainer } from '@components/common';
import PocDetailCard from '../../../poc-detail-card';
import { Poc } from '@helpers/types/point-of-contact';

interface CustomerPocBoxProps {
  pocs: Poc[];
}

const CustomerPocBox = (props: CustomerPocBoxProps) => {
  const { pocs } = props;
  return (
    <div className={css.mainWrapper}>
      <BoxContainer title="Point of Contact">
        <div className={css.subWrapper}>
          {pocs.map((poc, index) => (
            <PocDetailCard
              key={index}
              name={`${poc.given_name} ${poc.family_name}`}
              email={poc.email}
              phoneNumber={poc.phone_number}
              className={css.cardWrapper}
            />
          ))}
        </div>
      </BoxContainer>
    </div>
  );
};

export default CustomerPocBox;
