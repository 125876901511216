/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Loader, Typography } from '@components/base';
import { SideBar } from '@components/common';
import { AppContext } from '@helpers/hooks/AppContext';
import notify from '@helpers/toastify-helper';
import {
  IBLFormDetail,
  IItemDetailsInfo,
  IPurchaseOrderDetailsProps,
  IRemarksProps,
  PerformaInvoiceStates
} from '@helpers/types/pi';
import { CIItemDetailSchema } from '@helpers/yup/add-order.schema';
import { blDetailFormSchema } from '@helpers/yup/commercial_invoice.schema';
import { purchaseOrderDetailsScheme } from '@helpers/yup/purchase-order-details.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { addCI, fetchOrderInfo, generateCommercialInvoice } from '@services/order.service';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ItemDetailForm from './components/item-details-tab';
import OrderBankDetailsTab from './components/order-bank-details-tab';
import PartiesForm from './components/parties-tab';
import PIReview from './components/preview-tab';
import PurchaseOrderDetailsTab from './components/purchase-order-detail';
import RemarkTab from './components/remarks-tab';
import { remarkFunction } from './components/remarks-tab/remarks';
import css from './index.module.scss';
import { CLIENT_ROUTES } from '@router/routes';
import { useScrollToTop } from '@helpers/hooks';
import { getFormattedDate } from '@helpers/utils';
const writtenNumber = require('written-number');

interface IRemarkCheckbox {
  payment_transfers: boolean;
  pss_confirmation: boolean;
  free_days_at_port: boolean;
  neutral_packing: boolean;
  consignment_elchemy: boolean;
  percent_advance_payment: boolean;
  deviation_quality_days: boolean;
  bl_switchable_port: boolean;
  payment_due_date: boolean;
  inspection_cost: boolean;
  other_remarks: boolean;
}

const GenerateCommercialInvoice = () => {
  // const id = '283df353-db5c-408e-a24d-c749a743c06a';
  const urlParams = useParams();
  const { id } = urlParams;
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { PIData } = appState;
  const { invoiceType } = PIData;

  const [remarkCheckboxTabs, setRemarkCheckboxTabs] = useState<IRemarkCheckbox>({
    payment_transfers: false,
    pss_confirmation: false,
    free_days_at_port: false,
    neutral_packing: false,
    consignment_elchemy: false,
    percent_advance_payment: false,
    deviation_quality_days: false,
    bl_switchable_port: false,
    payment_due_date: false,
    inspection_cost: false,
    other_remarks: false
  });

  const [orderState, setOrderState] = useState<PerformaInvoiceStates>({
    isLoading: false,
    activeStep: 0,
    itemDetailsInfo: null,
    purchaseOrder: null,
    blDetailInfo: null,
    remarksInfo: {},
    remarks: '',
    order_total_amount: '',
    orderInfo: {
      invoice_number: '',
      order_type: '',
      order_number: ''
    },
    entityList: {
      name: '',
      gst_number: '',
      address: ''
    },
    customerInfo: {
      name: '',
      country: null,
      address: '',
      customer_poc: {}
    },
    consigneeInfo: {
      isSameAsCustomer: false,
      consignee_name: '',
      consignee_phone_number: '',
      consignee_email_id: '',
      consignee_office_address: ''
    },
    itemInfo: {
      items: [],
      totalAmount: '',
      supplier: [],
      currency: '',
      unit_of_weight: ''
    },
    orderDetails: {
      transportation_mode: '',
      inco_terms: '',
      country_of_origin: '',
      country_of_final_destination: '',
      port_of_loading: '',
      port_of_loading_display_value: '',
      port_of_loading_country_display_value: '',
      port_of_discharge: '',
      port_of_discharge_display_value: '',
      port_of_discharge_country_display_value: '',
      place_of_delivery: '',
      payment_terms: '',
      is_qc_test: false,
      delivery_term: '',
      place_of_quality_test: ''
    },
    bankDetails: {
      bank_name: '',
      branch_name: '',
      bank_account_holder_name: '',
      account_number: '',
      ifsc_code: '',
      swift_code: '',
      address: ''
    },
    orderPi: null
  });

  const {
    isLoading,
    activeStep,
    orderDetails,
    itemInfo,
    customerInfo,
    purchaseOrder,
    itemDetailsInfo,
    remarks,
    blDetailInfo,
    orderPi,
    order_total_amount
  } = orderState;

  const handleSidebarClick = (value: number) => {
    handleNavigation(value);
  };

  const handleSidebarEnterKey = (value: number) => {
    handleNavigation(value);
  };

  const topRef = useRef(null);

  const itemDetailForm = useForm<IItemDetailsInfo>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(CIItemDetailSchema),
    defaultValues: {
      custom_items: [],
      order_items: [
        {
          rate_ci: '',
          quantity_ci: '',
          order_item_id: '',
          order_item_description_ci: '',
          include_grade_name_in_docs: false
        }
      ],
      include_advance_payment: false
    }
  });

  const blDetailForm = useForm<IBLFormDetail>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(blDetailFormSchema),
    defaultValues: {
      bl_date: null,
      bl_number: '',
      awb_service_name: ''
    }
  });

  const remarksForm = useForm<IRemarksProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      field_free_days_port: '',
      field_percentage_payment: '',
      field_days_deviation: '',
      field_bl_port: '',
      field_payment_due: new Date(),
      field_inspection_cost: ''
    }
  });

  const purchaseOrderForm = useForm<IPurchaseOrderDetailsProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(purchaseOrderDetailsScheme),
    defaultValues: {
      po_reference_number: '',
      document_date: null,
      notify_party: ''
    }
  });

  useEffect(() => {
    (async () => {
      setOrderState((prevState: PerformaInvoiceStates) => ({
        ...prevState,
        isLoading: !prevState.isLoading
      }));
      const response = await fetchOrderInfo(id as string);
      if (response.success) {
        const { data: orderinfo } = response;
        let invoiceData =
          invoiceType == 'Commercial'
            ? orderinfo?.order_ci ?? orderinfo?.order_pi
            : orderinfo?.order_pi;
        const customItems = orderinfo?.custom_items?.length
          ? orderinfo?.custom_items?.map((item: any) => {
              return {
                custom_item_name: item.name,
                custom_item_description: item.description,
                custom_item_amount: item.amount,
                custom_item_quantity: item.quantity,
                custom_item_rate: item.rate
              };
            })
          : [];
        invoiceData = {
          ...invoiceData,
          custom_items: customItems
        };
        setOrderState((prevState: PerformaInvoiceStates) => ({
          ...prevState,
          isLoading: !prevState.isLoading,
          order_total_amount: orderinfo?.total,
          orderInfo: {
            invoice_number: '',
            order_type: orderinfo?.supply_type,
            order_number: orderinfo?.readable_order_id
          },
          entityList: {
            name: orderinfo?.elchemy_entity.name,
            gst_number: orderinfo?.elchemy_entity.gst_number,
            address: orderinfo?.elchemy_entity.address
          },
          customerInfo: {
            name: orderinfo?.customer.name,
            country: orderinfo?.customer.country,
            address: `${
              orderinfo?.customer_address ? orderinfo?.customer_address?.address_line1 : ''
            } ${orderinfo?.customer_address ? orderinfo?.customer_address?.address_line2 : ''} ${
              orderinfo?.customer_address ? orderinfo?.customer_address?.city : ''
            } ${orderinfo?.customer_address ? orderinfo?.customer_address.state : ''} ${
              orderinfo?.customer_address ? orderinfo?.customer_address?.country : ''
            }`,
            customer_poc: orderinfo?.customer_poc
          },
          consigneeInfo: {
            isSameAsCustomer: orderinfo?.consignee_same_as_customer,
            consignee_name: orderinfo?.consignee_name,
            consignee_phone_number: orderinfo?.consignee_phone_number,
            consignee_email_id: orderinfo?.consignee_email_id,
            consignee_office_address: orderinfo?.consignee_office_address,
            address: `${
              orderinfo?.customer_address ? orderinfo?.customer_address?.address_line1 : ''
            } ${orderinfo?.customer_address ? orderinfo?.customer_address?.address_line2 : ''} ${
              orderinfo?.customer_address ? orderinfo?.customer_address?.city : ''
            } ${orderinfo?.customer_address ? orderinfo?.customer_address.state : ''} ${
              orderinfo?.customer_address ? orderinfo?.customer_address?.country : ''
            }`,
            customer_poc: orderinfo?.customer_poc
          },
          itemInfo: {
            items: orderinfo?.order_item?.map((item: any) => {
              return {
                ...item,
                rate_ci: item?.selling_price ?? null
              };
            }),
            totalAmount: orderinfo?.total,
            supplier: orderinfo?.suppliers,
            currency: orderinfo?.currency,
            unit_of_weight: orderinfo?.unit_of_weight
          },
          bankDetails: {
            bank_name: orderinfo?.elchemy_bank?.bank_name,
            branch_name: orderinfo?.elchemy_bank?.branch_name,
            bank_account_holder_name: orderinfo?.elchemy_bank?.bank_account_holder_name,
            account_number: orderinfo?.elchemy_bank?.account_number,
            ifsc_code: orderinfo?.elchemy_bank?.ifsc_code,
            swift_code: orderinfo?.elchemy_bank?.swift_code,
            address: `${orderinfo?.elchemy_bank?.address_line1 ?? ''} ${
              orderinfo?.elchemy_bank?.address_line2 ?? ''
            } ${orderinfo?.elchemy_bank?.city ?? ''} ${orderinfo?.elchemy_bank.state ?? ''}`
          },
          orderDetails: {
            transportation_mode: orderinfo?.transportation_mode,
            inco_terms: orderinfo?.inco_terms,
            country_of_origin: orderinfo?.country_of_origin,
            country_of_final_destination: orderinfo?.country_of_final_destination,
            port_of_loading: orderinfo?.port_of_loading,
            port_of_loading_display_value: orderinfo?.port_of_loading_display_value,
            port_of_loading_country_display_value: orderinfo?.port_of_loading_country_display_value,
            port_of_discharge: orderinfo?.port_of_discharge,
            port_of_discharge_display_value: orderinfo?.port_of_discharge_display_value,
            port_of_discharge_country_display_value:
              orderinfo?.port_of_discharge_country_display_value,
            place_of_delivery: orderinfo?.place_of_delivery,
            payment_terms: orderinfo?.payment_terms_display_value,
            is_qc_test: orderinfo?.is_qc_test,
            place_of_quality_test: orderinfo?.place_of_quality_test,
            delivery_term: orderinfo?.inco_terms,
            bl_date: orderinfo?.bl_date,
            bl_number: orderinfo?.bl_number,
            awb_date: orderinfo?.awb_date,
            awb_number: orderinfo?.awb_number,
            awb_service_name: orderinfo?.awb_service_name
          },
          orderPi: invoiceData
        }));
        purchaseOrderForm.reset({
          po_reference_number: orderinfo?.order_pi?.po_reference_no,
          document_date: new Date(orderinfo?.order_pi?.po_reference_date),
          notify_party: orderinfo?.order_pi?.notify_party
        });
      } else {
        setOrderState((prevState: PerformaInvoiceStates) => ({
          ...prevState,
          isLoading: !prevState.isLoading
        }));
        notify({
          message: response ?? 'Unable to fetch data',
          severity: 'error',
          dismissible: true
        });
      }
    })();
  }, []);

  const handlePurchaseOrderSubmit: SubmitHandler<IPurchaseOrderDetailsProps> = (data) => {
    const newData = { ...data };
    setOrderState((prevState: any) => ({ ...prevState, purchaseOrder: newData, activeStep: 4 }));
  };

  const handleItemDetailFormSubmit: SubmitHandler<IItemDetailsInfo> = (data) => {
    const itemCopy = orderState.itemInfo.items;
    itemCopy.forEach((item: any) => {
      data?.order_items?.forEach((updatedItem: any) => {
        if (updatedItem.order_item_id === item.order_item_id) {
          if (updatedItem.quantity_ci) {
            item.quantity_mt = updatedItem.quantity_ci;
            updatedItem.quantity_mt = updatedItem.quantity_ci;
          }
          if (updatedItem.rate_ci) {
            item.rate_mt_usd = updatedItem.rate_ci;
            updatedItem.rate_mt_usd = updatedItem.rate_ci;
          }
          item.order_item_description_ci = updatedItem.order_item_description_ci;
          item.total_amount = Number(item.quantity_mt) * Number(item.rate_mt_usd);
          updatedItem.include_grade_name_in_docs = updatedItem.include_grade_name_in_docs?.value;
        }
      });
    });
    const customItemTotalAmount = Number(
      data?.custom_items?.reduce((total: any, item: any) => total + item.custom_item_amount, 0)
    ).toFixed(2);
    const orderItemTotalAmount = Number(
      itemCopy.reduce((total: any, item: any) => total + item.total_amount, 0)
    ).toFixed(2);
    const totalOrderAmount = Number(order_total_amount).toFixed(2);
    if (
      parseFloat(customItemTotalAmount) + parseFloat(orderItemTotalAmount) !=
      parseFloat(totalOrderAmount)
    ) {
      notify({
        message: `Total amount of custom items and order items should be equal to ${order_total_amount}`,
        severity: 'error',
        dismissible: true
      });
      return;
    }
    setOrderState((prevState: PerformaInvoiceStates) => ({
      ...prevState,
      itemDetailsInfo: data,
      activeStep: 2
    }));
  };

  const handleRemarkSubmit: SubmitHandler<Partial<IRemarksProps>> = (data) => {
    const due_date = data.field_payment_due;
    const changedDate = `${due_date?.getFullYear()}-${
      Number(due_date?.getMonth()) + 1
    }-${due_date?.getDate()}`;
    const newData = { ...data, field_payment_due: changedDate };
    const fnRemarkData = { ...newData, ...remarkCheckboxTabs, ...customerInfo };
    const remarkData = remarkFunction(fnRemarkData, orderDetails?.transportation_mode);
    setOrderState((prevState: any) => ({
      ...prevState,
      remarks: remarkData,
      remarksInfo: fnRemarkData,
      activeStep: 5
    }));
  };

  const handleBLSubmit: SubmitHandler<IBLFormDetail> = (data) => {
    const blDate = data.bl_date;
    const newDate = `${moment(blDate).format('YYYY-MM-DD')}`;
    const newData = { ...data, document_date: newDate };
    setOrderState((prevState: PerformaInvoiceStates) => ({
      ...prevState,
      blDetailInfo: newData,
      activeStep: 3
    }));
  };

  const handleCancelClick = () => {
    navigate(`/${CLIENT_ROUTES.order}/${id as string}`);
  };

  const handleFormSubmit = async () => {
    const customItemsLenght = itemDetailsInfo?.custom_items.length as number;
    let totalItems = itemInfo.items.length + customItemsLenght;
    const current_date = new Date();
    const document_date = `${current_date?.getFullYear()}-${
      Number(current_date?.getMonth()) + 1
    }-${current_date?.getDate()}`;

    let total_custom = 0,
      total_order = 0;
    if (itemDetailsInfo?.custom_items)
      total_custom = itemDetailsInfo?.custom_items.reduce(
        (previousValue: number, item: any) => Number(item.custom_item_amount) + previousValue,
        0
      );

    if (itemDetailsInfo?.order_items)
      total_order = itemDetailsInfo?.order_items.reduce(
        (previousValue: number, item: any) =>
          Number(item.quantity_ci) * Number(item.rate_ci) + previousValue,
        0
      );
    totalItems = total_custom + total_order;
    const postFormData = {
      order: id as string,
      remarks: remarks,
      document_date: document_date,
      total_amount: totalItems,
      total_amount_of_all_items_in_words: writtenNumber(totalItems),
      po_reference_no: purchaseOrder?.po_reference_number,
      po_reference_date: moment(purchaseOrder?.document_date).format('YYYY-MM-DD'),
      notify_party: purchaseOrder?.notify_party,
      custom_items: itemDetailsInfo?.custom_items?.length
        ? itemDetailsInfo?.custom_items.map((ci: any) => {
            return {
              name: ci.custom_item_name,
              description: ci.custom_item_description,
              amount: ci.custom_item_amount,
              quantity: ci.custom_item_quantity,
              rate: ci.custom_item_rate
            };
          })
        : [],
      order_items: itemDetailsInfo?.order_items?.map((oi: any) => {
        delete oi.quantity_mt, delete oi.rate_mt_usd;
        oi.selling_price = Number(oi.rate_ci);
        oi.quantity_ci = Number(oi.quantity_ci);
        delete oi.rate_ci;
        return oi;
        ``;
      }),
      include_advance_payment: itemDetailsInfo?.include_advance_payment
    };
    let response;
    if (invoiceType === 'Commercial') {
      response = await generateCommercialInvoice(PIData?.task_id as string, postFormData);
    } else {
      response = await addCI(PIData?.task_id as string, postFormData);
    }
    if (response.success) {
      notify({
        message: `${invoiceType} Invoice generated successfully!`,
        severity: 'success',
        dismissible: true
      });
      navigate(`/${CLIENT_ROUTES.order}/${id}`, { replace: true });
    } else {
      notify({
        message: response.error ?? 'Something went wrong!',
        severity: 'error',
        dismissible: true
      });
    }
  };

  const handleNavigation = (currentStep: number) => {
    if (currentStep === 0) {
      setOrderState((prevState: any) => ({
        ...prevState,
        activeStep: currentStep,
        itemDetailsInfo: null,
        purchaseOrder: null,
        remarksInfo: ''
      }));
    } else if (currentStep === 1) {
      setOrderState((prevState: any) => ({
        ...prevState,
        activeStep: currentStep,
        purchaseOrder: null,
        remarksInfo: ''
      }));
    } else if (currentStep === 3) {
      setOrderState((prevState: any) => ({
        ...prevState,
        activeStep: currentStep,
        remarksInfo: ''
      }));
    } else {
      setOrderState((prevState: PerformaInvoiceStates) => ({
        ...prevState,
        activeStep: currentStep
      }));
    }
  };

  useScrollToTop({ topRef, dependencyArray: [activeStep] });

  return (
    <main className={css.purchaseOrderWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Generate {invoiceType} Invoice</Typography>
        <Typography variant="subheading1">0{activeStep + 1} of 06</Typography>
      </div>
      <div className={css.purchaseOrderContainer} ref={topRef}>
        <div className={css.sideBarWrapper}>
          <SideBar
            activeStep={activeStep}
            onClick={handleSidebarClick}
            onEnter={handleSidebarEnterKey}>
            <SideBar.Item label="Parties" value={0} />
            <SideBar.Item label="Item Details" value={1} disabled={activeStep! <= 1} />
            <SideBar.Item
              label="Order and Entity Bank Details "
              value={2}
              disabled={activeStep! <= 2}
            />
            <SideBar.Item label="Purchase Order Details" value={3} disabled={activeStep! <= 3} />
            <SideBar.Item label="Standard Remarks" value={4} disabled={activeStep! <= 4} />
            <SideBar.Item label="Preview" value={5} disabled={activeStep! <= 5} />
          </SideBar>
        </div>
        {activeStep === 0 && (
          <PartiesForm
            data={orderState}
            handleCancelClick={handleCancelClick}
            handlePartiesSubmit={() => handleNavigation(1)}
          />
        )}
        <FormProvider {...itemDetailForm}>
          {activeStep === 1 && (
            <ItemDetailForm
              productList={itemInfo}
              onFormSubmit={handleItemDetailFormSubmit}
              onCancelClick={handleCancelClick}
              onBackClick={() => handleNavigation(0)}
              customItemList={orderPi?.custom_items ?? []}
            />
          )}
        </FormProvider>
        <FormProvider {...blDetailForm}>
          {activeStep === 2 && (
            <OrderBankDetailsTab
              orderBankData={orderState}
              handleCancelClick={handleCancelClick}
              onBackClick={() => handleNavigation(1)}
              onFormSubmit={handleBLSubmit}
              invoiceType={invoiceType}
            />
          )}
        </FormProvider>
        <FormProvider {...purchaseOrderForm}>
          {activeStep === 3 && (
            <PurchaseOrderDetailsTab
              purchaseOrderDetails={orderDetails}
              handleCancelClick={handleCancelClick}
              onFormSubmit={handlePurchaseOrderSubmit}
              onBackClick={() => handleNavigation(2)}
              orderPi={orderPi}
            />
          )}
        </FormProvider>
        <FormProvider {...remarksForm}>
          {activeStep === 4 && (
            <RemarkTab
              orderDetails={orderDetails}
              onBackClick={() => handleNavigation(3)}
              onFormSubmit={handleRemarkSubmit}
              remarkCheckboxTabs={remarkCheckboxTabs}
              setRemarkCheckboxTabs={setRemarkCheckboxTabs}
              fieldsData={customerInfo}
              handleCancelClick={handleCancelClick}
            />
          )}
        </FormProvider>
        {activeStep === 5 && (
          <PIReview
            previewData={orderState}
            handleFormSubmit={handleFormSubmit}
            handleCancelClick={handleCancelClick}
            handleBackClick={() => handleNavigation(4)}
            setOrderState={setOrderState}
            invoiceType={invoiceType}
            orderPi={orderPi}
          />
        )}
      </div>
      <Loader open={isLoading} />
    </main>
  );
};

export default GenerateCommercialInvoice;
