import Images from '@assets/images';
import { IconNode, Typography } from '@components/base';
import { BoxContainer, UploadButton } from '@components/common';
import { ChangeEvent, useMemo } from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import css from './index.module.scss';
import { IFormDocument } from '@helpers/types/customer';

const UploadDocumentContainer = () => {
  const { control } = useFormContext();
  const { fields, update } = useFieldArray({
    name: 'documents',
    control
  });
  return (
    <BoxContainer title="Upload Customer Documents" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.mainDocContainer}>
          {fields.map((item, index) => {
            const handleFileChange =
              (item: IFormDocument) => (event: ChangeEvent<HTMLInputElement>) => {
                const selectedFiles = event.currentTarget.files;
                const currentFile = selectedFiles?.[0];
                if (currentFile) {
                  item.document_object = currentFile;
                  update(index, item);
                }
              };
            return (
              <DocRow
                key={item.id}
                label={(item as any).document_name}
                name={`documents.${index}.document_object`}
                onFileChange={handleFileChange(item as any)}
                viewUri={(item as any).document_object}
              />
            );
          })}
        </div>
      </div>
    </BoxContainer>
  );
};

interface DocRowProps {
  label: string;
  name: string;
  viewUri?: File | null;
  showDeleteButton?: boolean;
  showViewButton?: boolean;
  onDeleteClick?: () => void;
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  required?: boolean;
}

export const DocRow = (props: DocRowProps) => {
  const {
    viewUri,
    label,
    name,
    onFileChange,
    onDeleteClick,
    showViewButton,
    showDeleteButton,
    className,
    required = false
  } = props;
  const { control } = useFormContext();

  const url = useMemo(() => {
    if (viewUri) return URL.createObjectURL(viewUri);
  }, [props.viewUri]);

  return (
    <div className={`${css.docRowWrapper} ${className}`}>
      <div className={css.docFirstColumn}>
        <Typography variant="body">
          {label} {required ? <span className={css.requiredIcon}>*</span> : <></>}
        </Typography>
        {viewUri && url && <IconNode src={Images.rightTickGreen} alt="green tick" />}
      </div>
      <div className={css.docSecondColumn}>
        {url && viewUri && showViewButton && (
          <a href={url} target="_blank" rel="noreferrer" className={css.viewWrapper}>
            <IconNode src={Images.eyeRed} alt="view icon" />
            View
          </a>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <UploadButton ref={field.ref} name={field.name} onChange={onFileChange} />
          )}
        />
        {showDeleteButton && (
          <IconNode
            src={Images.deleteRed}
            alt="delete icon"
            component="button"
            onClick={onDeleteClick}
            className={css.deleteIcon}
          />
        )}
      </div>
    </div>
  );
};

DocRow.defaultProps = {
  showViewButton: true,
  showDeleteButton: false
};

export default UploadDocumentContainer;
