import React from 'react';
import css from './index.module.scss';
import { BoxContainer } from '@components/common';
import Button from '@components/base/button';
import Item from './components/item';

interface PreviewProps {
  handleCancelClick: () => void;
  data: any;
  onSubmit: () => void;
  handleBackClick: (index: number) => void;
  handleNavigation: (index: number) => void;
  isReview?: boolean;
  openRejectModal: (value: boolean) => void;
}

const Preview = (props: any) => {
  const {
    handleCancelClick,
    data,
    onSubmit,
    handleBackClick,
    handleNavigation,
    isReview = false,
    openRejectModal
  } = props;
  return (
    <div className={css.formWrapper}>
      <BoxContainer title="Preview">
        <div className={css.PreviewWrapper}>
          <Item data={data.itemDetails} handleNavigation={handleNavigation} />

          <div className={css.actionButtonWrapper}>
            <Button variant="text" onClick={handleCancelClick}>
              Cancel
            </Button>
            <div className={css.buttonContainer}>
              {!isReview ? (
                <Button variant="outlined-secondary" onClick={() => handleBackClick(0)}>
                  Back
                </Button>
              ) : (
                <Button variant="outlined-secondary" onClick={() => openRejectModal(true)}>
                  Reject
                </Button>
              )}

              <Button type="submit" onClick={onSubmit}>
                {isReview ? 'Approve' : 'Save and Proceed'}
              </Button>
            </div>
          </div>
        </div>
      </BoxContainer>
    </div>
  );
};

export default Preview;
