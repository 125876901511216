import { Typography } from '@components/base';
import css from '../index.module.scss';
import { IRemarksFormData } from '@helpers/types/si';

interface IRemarksProps {
  remarksFormData: IRemarksFormData[];
}

const Remarks = (props: IRemarksProps) => {
  const { remarksFormData } = props;

  return (
    <div>
      <div className={css.boxWrapper}>
        <div className={css.remarkBoxContainer}>
          <div className={css.fieldWrapper}>
            <Typography variant="pdoc">Remarks</Typography>
          </div>
          <div className={css.remarksPreview}>
            {remarksFormData?.map((remark: IRemarksFormData, index: number) => (
              <Typography key={index}>{`${index + 1}. ${remark}`}</Typography>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Remarks;
