import { deleteNote, getNotes } from '@services/order.service';
import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import css from './index.module.scss';
import { ListPlaceholder, PaginationScroller, Seo } from '@components/common';
import { Button, Chip, Loader, Typography } from '@components/base';
import notify from '@helpers/toastify-helper';
import NotesCard from './notes-card';
// import NotesCreateEditModal from './notes-createEdit';
import NotesCreateEditModal from '@components/common/notes-createEdit';
import AccessWrapper from '@authorization/access-wrapper';
import Skeleton from '@components/base/skeleton';
import { INotes } from '@helpers/types/order';

interface INotesProps {
  notes: INotes[];
  isLoading: boolean;
  hasNext: boolean;
  isEdit: boolean;
  editNotesId: string;
  state: number;
  currentPage: number;
  retry: boolean;
  notesOrderCount: number;
}

interface NotesProps {
  actions: IActions;
}

const itemLimit = 12;
const getInternalState = (urlState: string): number => {
  return urlState === 'mynotes' ? 1 : 0;
};
const getUrlState = (internalState: number): string => {
  return internalState === 1 ? 'mynotes' : 'public';
};

const Notes = (props: NotesProps) => {
  const { actions } = props;
  const params = useParams();
  const { id } = params;
  const [searchParams, setSearchParams] = useSearchParams();
  const rootContainer = useRef(null);
  const initialPageNumber = searchParams.get('page')
    ? parseInt(searchParams.get('page') as string)
    : 1;
  const initialActiveSubTab = getInternalState(searchParams.get('state') || 'public');

  const [noteState, setNoteState] = useState<INotesProps>({
    notes: [],
    isLoading: false,
    hasNext: false,
    retry: false,
    isEdit: false,
    editNotesId: '',
    state: 0,
    currentPage: initialPageNumber,
    notesOrderCount: 0
  });
  const {
    notes,
    isLoading,
    hasNext,
    isEdit,
    editNotesId,
    state,
    currentPage,
    retry,
    notesOrderCount
  } = noteState;
  const [notesCreateEditModal, setNotesCreateEditModal] = useState(false);

  const getNotesForOrder = async (params: number) => {
    if (isLoading) return;
    setNoteState((prevState) => ({ ...prevState, isLoading: true }));
    const reqBody = {
      page: params,
      is_private: state === 1 ? true : false
    };
    const response = await getNotes(id as string, reqBody);
    if (response.success) {
      setNoteState((prevState) => ({
        ...prevState,
        notes: response.data?.results,
        isLoading: false,
        notesOrderCount: response.data.count,
        hasNext: !!response.data.next,
        currentPage: params,
        isEdit: false,
        retry: false
      }));
      setNotesCreateEditModal(false);
    } else if (response.error) {
      notify({
        message: 'Failed to fetch notes',
        severity: 'error'
      });
      setNoteState((prevState) => ({ ...prevState, isLoading: false, retry: true }));
    }
  };

  const handleDeleteNote = async (noteId: string) => {
    const editNotesData = notes.filter((note: INotes) => note.id === noteId);
    setNoteState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await deleteNote(editNotesData[0]?.is_private, id as string, noteId);
    if (response.success) {
      notify({
        message: 'Note deleted successfully',
        severity: 'success'
      });
      getNotesForOrder(1);
      setNoteState((prevState) => ({ ...prevState, isLoading: false }));
    } else if (response.error) {
      notify({
        message: response.error || 'Failed to delete note',
        severity: 'error'
      });
      setNoteState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const handleEditButton = (noteId: string) => {
    setNoteState((prevState) => ({
      ...prevState,
      isEdit: true,
      editNotesId: noteId
    }));
    setNotesCreateEditModal(true);
  };

  useEffect(() => {
    handlePageChange(1);
  }, [state]);

  const handleModalClose = () => {
    setNoteState((prevState) => ({
      ...prevState,
      editNotesId: '',
      isEdit: false,
      currentPage: 1
    }));
    getNotesForOrder(1);
  };

  const handleCancel = () => {
    setNotesCreateEditModal(false);
    setNoteState((prevState) => ({
      ...prevState,
      isEdit: false,
      editNotesId: ''
    }));
  };

  const handleTabChange = (newState: number) => {
    setNoteState((prevState) => ({
      ...prevState,
      state: newState,
      notes: []
    }));
    setSearchParams({
      activeTab: 'Notes',
      activeSubTab: getUrlState(newState),
      page: '1'
    });
  };

  const handlePageChange = async (newPage: number) => {
    setNoteState((prev) => ({ ...prev, isLoading: true }));
    await getNotesForOrder(newPage);
    setNoteState((prevState) => ({ ...prevState, isLoading: false }));
    setSearchParams({
      activeTab: 'Notes',
      activeSubTab: getUrlState(state),
      page: newPage.toString()
    });
  };

  const validatePageChange = (event: any, value: number) => {
    if (isNaN(value)) return;
    if (value === 0 || value > Math.ceil(notesOrderCount / itemLimit)) return;
    handlePageChange(value);
  };

  if (isLoading) return <NotesSkeleton />;

  return (
    <main ref={rootContainer} className={css.mainWrapper}>
      <Seo title="Notes" />
      <div className={css.notesHeader}>
        <div className={css.notifyHeaderText}>
          <Chip
            label="Public"
            variant={state === 0 ? `filled` : `outlined`}
            onClick={() => handleTabChange(0)}
          />
          <Chip
            label="My Notes"
            variant={state === 1 ? `filled` : `outlined`}
            onClick={() => handleTabChange(1)}
          />
        </div>
        <AccessWrapper show={actions?.create}>
          <Button variant="outlined-secondary" onClick={() => setNotesCreateEditModal(true)}>
            Add Note
          </Button>
        </AccessWrapper>
      </div>
      <section className={css.notesCard}>
        {notes.length > 0 &&
          !isLoading &&
          notes.map((note: INotes, index: number) => {
            return (
              <NotesCard
                key={note.id}
                data={note}
                handleDelete={handleDeleteNote}
                handleEdit={handleEditButton}
                actions={actions}
              />
            );
          })}
      </section>
      {notes.length <= 0 && !isLoading && (
        <ListPlaceholder
          title="No Notes Added Yet"
          supportingText="When a Note is added,
          they will show up here."
          buttonText="Add Note"
          handleButtonClick={() => setNotesCreateEditModal(true)}
        />
      )}
      <AccessWrapper show={actions?.create}>
        {notesCreateEditModal && (
          <NotesCreateEditModal
            open={notesCreateEditModal}
            onClose={handleModalClose}
            notesData={notes}
            orderId={id as string}
            isEdit={isEdit}
            editNotesId={editNotesId}
            handleCancel={handleCancel}
          />
        )}
      </AccessWrapper>
      {isLoading && <Loader open={isLoading} />}
      {!isLoading && notes.length > 0 && (
        <PaginationScroller
          variant="text"
          defaultPage={1}
          count={notesOrderCount}
          pageLimit={itemLimit}
          page={currentPage}
          onChange={validatePageChange}
        />
      )}
    </main>
  );
};

export default Notes;

const NotesSkeleton = () => {
  return (
    <div className={css.notesSkeletonWrapper}>
      <div className={css.notesTabFilter}>
        <Skeleton animation="wave" width={'150px'} height={20} />
        <Skeleton animation="wave" width={'150px'} height={20} />
      </div>
      <NotesSkeletonCard />
      <NotesSkeletonCard />
      <NotesSkeletonCard />
    </div>
  );
};

const NotesSkeletonCard = () => {
  return (
    <div className={css.notesSkeletonCard}>
      <Skeleton variant="rectangular" animation="wave" width={'33%'} height={250} />
      <Skeleton variant="rectangular" animation="wave" width={'33%'} height={250} />
      <Skeleton variant="rectangular" animation="wave" width={'33%'} height={250} />
    </div>
  );
};
