import { Accordion, Button, IconNode, Typography } from '@components/base';
import { Avatar } from '@components/common';
import css from './index.module.scss';
import Images from '@assets/images';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

interface AssignPeopleModalProps {
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete?: () => void;
}

const ApproveOrderCard = (props: AssignPeopleModalProps) => {
  const { alertSuccessFaint, dotGrey } = Images;
  const navigate = useNavigate();

  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {props.taskData[0].is_completed ? (
                  <IconNode src={alertSuccessFaint} className={css.successIcon} />
                ) : (
                  <Avatar src={dotGrey} alt="assign-icon" />
                )}

                <Link to={`/order/${props.taskData[0]?.order}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="h5" className={css.underlineOnHover}>
                    #{props.taskData[0].readable_task_id} - Review and Approve Order
                  </Typography>
                </Link>
              </div>
              <div className={css.pocWrapper}>
                {!props.taskData[0].is_completed && (
                  <Button
                    variant="text"
                    onClick={() =>
                      navigate(
                        `/${CLIENT_ROUTES.order}/approve-order/${props.taskData[0]?.order}/${props.taskData[0]?.task_id}`
                      )
                    }
                    className={css.assignButton}>
                    Review and Approve Order
                  </Button>
                )}
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        <Footer
          createdDate={props.taskData[0].created_at as Date}
          updatedDate={props.taskData[0].updated_at as Date}
        />
      </div>
    </>
  );
};

export default ApproveOrderCard;
