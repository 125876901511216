import { Button, IconNode, Modal, Typography, Loader } from '@components/base';
import css from './index.module.scss';
import { InputDatePicker, TextField, DragDropUpload } from '@components/common';
import Images from '@assets/images';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { uploadLCDetails } from '@services/task.service';
import notify from '@helpers/toastify-helper';
import { convertDateString } from '@helpers/constants';
import { useCallback } from 'react';
import { addLCDetails } from '@helpers/yup/add-lc-details.schema';

interface uploadLCProps {
  open: boolean;
  onClose?: () => void;
  sendStatusToCard: (arg: boolean) => void;
  taskId: string;
  onComplete?: () => void;
}

interface FormType {
  lc_bank: string;
  lc_date: Date | null;
  lc_copy: any;
}

const UploadLCDetailsCard = (props: uploadLCProps) => {
  const { open, onClose, sendStatusToCard, taskId, onComplete } = props;

  const uploadLCForm = useForm<FormType>({
    resolver: yupResolver(addLCDetails),
    defaultValues: {
      lc_bank: '',
      lc_date: null,
      lc_copy: null
    }
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting }
  } = uploadLCForm;

  const watch = useWatch({ name: 'lc_copy', control: control });

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
      const { onClose } = props;
      const { lc_date } = data;
      const formattedCuOffDate = convertDateString(lc_date);
    const reqBody = new FormData();
    reqBody.append('customer_lc_issuing_bank', data.lc_bank);
    reqBody.append('customer_lc_issue_date', formattedCuOffDate as string);
    reqBody.append('customer_lc', data.lc_copy);
    
    const response = await uploadLCDetails(taskId, reqBody);
    if (response.success) {
      notify({ message: 'LC Details Uploaded' });
      sendStatusToCard(true);
      if (onComplete) {
        onComplete();
      }
      onClose?.();
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else notify({ message: 'Some error occurred', severity: 'error' });
  };

  const handleFileDrop = useCallback((file: File[]) => {
    if (file.length) {
      setValue('lc_copy', file[0]);
    }
  }, []);

  const handleDeleteFile = useCallback(() => {
    setValue('lc_copy', null);
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Upload LC Details</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="div"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={css.fieldsWrapper}>
            <Controller
              name="lc_bank"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  label="LC Issuing Bank"
                  placeholder="Enter LC Issuing Bank"
                  autoComplete="off"
                  enterKeyHint="next"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            <Controller
              name="lc_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  label="LC Issue Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />
            <div>
              <Controller
                name="lc_copy"
                control={control}
                render={({ fieldState }) => (
                  <DragDropUpload
                    onDrop={handleFileDrop}
                    required
                    label="Upload LC copy"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              {watch && (
                <div className={css.fileUploadState}>
                  <Typography variant="p" className={css.fileName}>
                    {watch.name}
                  </Typography>
                  <IconNode
                    src={Images.deleteRed}
                    alt="delete icon"
                    component="button"
                    className={css.deleteButton}
                    onClick={handleDeleteFile}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={css.actionButtonWrapper}>
            <Button variant="outlined-secondary" className={css.actionButton} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" className={css.actionButton}>
              Save
            </Button>
          </div>
        </form>
        <Loader open={isSubmitting} />
      </div>
    </Modal>
  );
};

export default UploadLCDetailsCard;
