import Images from '@assets/images';
import { Divider, IconNode, Typography } from '@components/base';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import css from '../index.module.scss';

interface IParties {
  partiesData: PerformaInvoiceStates;
  setOrderState: (orderState: PerformaInvoiceStates) => void;
}

const Parties = (props: IParties) => {
  const { partiesData, setOrderState } = props;
  const { entityList, consigneeInfo } = partiesData;
  const { editRed } = Images;

  return (
    <div className={css.boxWrapper}>
      <div className={css.partiesContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Parties</Typography>
          <IconNode
            className={css.editRed}
            src={editRed}
            onClick={() =>
              setOrderState({
                ...partiesData,
                activeStep: 0
              })
            }
          />
        </div>
        <div className={css.fieldWrapper}>
          <div className={css.partyContainer}>
            <div className={css.fieldTitle}>
              <Typography variant="pdoc">Exporter</Typography>
            </div>
            <div className={css.wrapper}>
              <Typography variant="h3">{entityList?.name}</Typography>
              <Typography variant="span">{entityList?.address}</Typography>
              <Typography variant="span">GST - {entityList.gst_number}</Typography>
            </div>
          </div>
          <Divider className={css.divider} />
          <div className={css.partyContainer}>
            <div className={css.fieldTitle}>
              <Typography variant="pdoc">Customer (Consignee)</Typography>
            </div>
            <div className={css.wrapper}>
              <Typography variant="h3">{consigneeInfo?.consignee_name}</Typography>
              <Typography variant="span">{consigneeInfo?.consignee_office_address}</Typography>
              <div className={css.wrapper}>
                <Typography variant="span">
                  Tel - {consigneeInfo?.consignee_phone_number}
                </Typography>
                <Typography variant="span">Email - {consigneeInfo?.consignee_email_id}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parties;
