import { BoxContainer, TextAreaLabel } from '@components/common';
import { memo } from 'react';
import css from './index.module.scss';
import { sanitizeValue } from '@helpers/utils';
import { ITaskItemProps } from '@helpers/types/task-tabs';

interface IShipperContainerProps {
  data: ITaskItemProps;
}

const shipperContainer = (props: IShipperContainerProps) => {
  const { data } = props;
  return (
    <BoxContainer title="Shipper Details" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <TextAreaLabel
          disabled
          value={sanitizeValue(data?.shipper)}
          label="Shipper Name"
          placeholder="Enter Shipper Details"
        />
      </div>
    </BoxContainer>
  );
};

export default memo(shipperContainer);
