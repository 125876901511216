import css from './index.module.scss';
import { BoxContainer, TextField, SelectLabel } from '@components/common';
import { Controller, useFormContext } from 'react-hook-form';
import { BUSINESS_TYPE } from '@helpers/constants';
import { IconNode } from '@components/base';
import Images from '@assets/images';

interface CustomerDetailContainerProps {
  enableEditMode?: boolean;
}

const CustomerDetailContainer = (props: CustomerDetailContainerProps) => {
  const { enableEditMode } = props;
  const { control } = useFormContext();
  return (
    <BoxContainer title="Customer Details" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                autoFocus={!enableEditMode}
                disabled={enableEditMode}
                required
                type="text"
                inputMode="text"
                enterKeyHint="next"
                autoComplete="name"
                label="Customer’s Name"
                placeholder="Enter name"
                rootClassName={css.fieldWrapper}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <Controller
            name="number"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                autoFocus={enableEditMode}
                type="tel"
                inputMode="tel"
                enterKeyHint="next"
                autoComplete="tel"
                label="Customer’s Phone Number"
                placeholder="Enter Phone Number"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                type="email"
                inputMode="email"
                enterKeyHint="next"
                autoComplete="email"
                label="Customer’s Email"
                placeholder="Enter email address"
                rootClassName={css.fieldWrapper}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <Controller
            name="website"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="url"
                inputMode="url"
                enterKeyHint="next"
                autoComplete="url"
                name="website"
                label="Customer’s Website"
                placeholder="Enter website"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="business_type"
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required
                label="Type of Customer"
                options={BUSINESS_TYPE}
                rootClassName={css.fieldWrapper}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          {/* <Controller
            name="customer_country"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required
                isSearchable
                label="Country"
                options={countries}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          /> */}
        </div>
      </div>
    </BoxContainer>
  );
};

export default CustomerDetailContainer;
