import { ReactNode } from 'react';
import css from './index.module.scss';
import { Typography } from '@components/base';

interface BoxContainerProps {
  title: ReactNode;
  children: ReactNode;
  className?: string;
}

const BoxContainer = (props: BoxContainerProps) => {
  const { title, children, className } = props;
  return (
    <div className={`${css.accordionWrapper} ${className}`}>
      <div className={css.accordionHeaderWrapper}>
        <Typography variant="h4">{title}</Typography>
      </div>
      <div className={css.accordionContentWrapper}>{children}</div>
    </div>
  );
};

export default BoxContainer;
