import css from './index.module.scss';
import React from 'react';
import Typography from '@components/base/typography';
import { IconNode } from '@components/base';
import Images from '@assets/images';
import { FieldValue } from '@helpers/types/coa';
import { getFormattedDate } from '@helpers/utils';

interface OrderPropTypes {
  data: FieldValue[];
  handleNavigation: (index: number) => void;
}

const ConsigneeDetails = (props: any) => {
  const { data, handleNavigation } = props;
  return (
    <div className={css.boxWrapper}>
      <div className={css.orderContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="h4">Order Details</Typography>
          <IconNode
            className={css.editIcon}
            src={Images.editRed}
            onClick={() => handleNavigation(0)}
          />
        </div>
        <div className={css.rowWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="label">Invoice Number</Typography>
            <Typography variant="p">
              {data?.order_ci
                ? data?.order_ci.document_number
                : data?.order_pi.document_number ?? '-'}
            </Typography>
          </div>
          <div className={css.columnWrapper}>
            <Typography variant="label">Invoice Date</Typography>
            <Typography variant="p">
              {data?.order_ci
                ? getFormattedDate(data?.order_ci?.document_date)?.toString()
                : getFormattedDate(data?.order_pi?.document_date)?.toString() ?? '-'}
            </Typography>
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="label">Mode of Transportation</Typography>
            <Typography variant="p">{data?.transportation_mode_display_value ?? '-'}</Typography>
          </div>
          <div className={css.columnWrapper}>
            <Typography variant="label">Inco Terms</Typography>
            <Typography variant="p">{data?.inco_terms_display_value ?? '-'}</Typography>
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="label">Country of Origin</Typography>
            <Typography variant="p">{data?.country_of_origin_display_value ?? '-'}</Typography>
          </div>
          <div className={css.columnWrapper}>
            <Typography variant="label">Country of Final Destination</Typography>
            <Typography variant="p">
              {data?.country_of_final_destination_display_value ?? '-'}
            </Typography>
          </div>
        </div>
        {data?.bl_number ? (
          <div className={css.rowWrapper}>
            <div className={css.columnWrapper}>
              <Typography variant="label">BL Number</Typography>
              <Typography variant="p"> value={data?.bl_number ?? '-'}</Typography>
            </div>
            <div className={css.columnWrapper}>
              <Typography variant="label">BL Date</Typography>
              <Typography variant="p">
                {getFormattedDate(data?.bl_date)?.toString() ?? '-'}
              </Typography>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ConsigneeDetails;
