import { memo } from 'react';
import css from './index.module.scss';
import { IconNode, Typography, Divider } from '@components/base';
import Images from '@assets/images';

interface PartiesContainerProps {
  exporter: string;
  exporterAddress: string;
  exporterGSTNumber: string;
  name: string;
  country: string;
  address: any;
  pocName: string;
  pocEmail: string;
  pocPhoneNumber: string;
  onEditClick?: () => void;
  previewForm?: boolean;
}

const PartiesContainer = (props: PartiesContainerProps) => {
  const {
    exporter,
    exporterAddress,
    exporterGSTNumber,
    name,
    country,
    address,
    pocName,
    pocEmail,
    pocPhoneNumber,
    onEditClick,
    previewForm = true
  } = props;

  return (
    <div className={css.outlineWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="pdoc">Parties</Typography>
        {previewForm ? (
          <IconNode
            src={Images.editRed}
            alt="edit icon"
            className={css.editButton}
            component="button"
            onClick={onEditClick}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={css.outlineWrapper}>
        <Typography variant="pdoc">Exporter</Typography>
        <Typography variant="h4" className={css.textWrapper}>
          {exporter}
        </Typography>
        <Typography variant="ptext" className={css.textWrapper}>
          {exporterAddress}
        </Typography>
        <Typography variant="ptext" className={css.textWrapper}>
          GST - {exporterGSTNumber}
        </Typography>
      </div>
      <div className={css.outlineWrapper}>
        <Typography variant="pdoc" className={css.textWrapper}>
          Customer (Consignee)
        </Typography>
        <Typography variant="h4" className={css.textWrapper}>
          {name}
        </Typography>
        {/* <div className={css.rowWrapper}>
          <Typography variant="ptext">Country:</Typography>
          <Typography variant="ptext">{country}</Typography>
        </div> */}
        <div className={css.rowWrapper}>
          <Typography variant="ptext">Address:</Typography>
          <Typography variant="ptext">
            {address?.readable_address ? address?.readable_address : '-'}
          </Typography>
        </div>
        <Divider className={css.divider} />
        <div className={css.rowWrapper}>
          <Typography variant="ptext">Point of Contact:</Typography>
          <Typography variant="ptext">{pocName}</Typography>
        </div>
        <div className={css.rowWrapper}>
          <Typography variant="ptext">POC Details: </Typography>
          <Typography variant="ptext">
            {pocPhoneNumber} | {pocEmail}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default memo(PartiesContainer);
