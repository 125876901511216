import AccessWrapper from '@authorization/access-wrapper';
import { USER_TYPES } from '@helpers/constants';
import InternalOrderOverview from './internal';
import CustomerOrderOverview from './customer';

const OrderOverview = (props: any) => {
  const { actions } = props;
  return (
    <>
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.customer]} byPassShowWithShowTo>
        <CustomerOrderOverview {...props} />
      </AccessWrapper>
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]} byPassShowWithShowTo>
        <InternalOrderOverview {...props} />
      </AccessWrapper>
    </>
  );
};

export default OrderOverview;
