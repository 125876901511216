import { BreadCrumb, Button, IconNode, Loader, Skeleton, Tab, Tabs } from '@components/base';
import { AutoComplete, LinkButton, Seo } from '@components/common';
import { OrderInfo } from '@helpers/types/order';
import { CLIENT_ROUTES } from '@router/routes';
import {
  fetchOrderInfo,
  getAdjacentOrderIds,
  getReadableOrderIdList,
  orderPendingActions
} from '@services/order.service';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ActivityLogTab from './components/activity-log-tab/components/assignment-card';
import OrderCard from './components/order-card';
import OrderOverview from './components/order-overview';
import OrderPeopleTab from './components/people-tab';
import TaskTab, { TaskSkeleton } from './components/task-tab';
import DocumentTab from './components/document-tab';
import StuffingPhotos from './components/stuffing-photos';
import Tracking from './components/tracking';
import CommunicationTab from './components/communication-tab';
import css from './index.module.scss';
import AccessWrapper from 'src/authorization/access-wrapper';
import { EVENT_TRACKING_TYPES, MODULE_NAMES, USER_TYPES } from '@helpers/constants';
import Chip from '@components/base/chip';
import {
  executeFunctionByCondition,
  getPermissionFromLocalStorage,
  pushEventTracking,
  sanitizeValue
} from '@helpers/utils';
import notify from '@helpers/toastify-helper';
import Images from '@assets/images';
import Notes from './components/notes-home';
import KeyValueSkeleton from '@components/common/skeletons/key-value';
import InternalOrderOverview from './components/order-overview/internal';

interface OrderIndiviualState {
  orderInfo: OrderInfo | null;
  activeTab: number;
  isLoading: boolean;
  pendingActions: any;
  activeSubTab?: number; //This is passed inside the tab component to show the specific subtab
}

const ORDER_STATE_MAPPING = [
  {
    value: 0,
    key: 'order_overview',
    defaultFor: ['customer']
  },
  {
    value: 1,
    key: 'documents',
    children: [
      {
        value: 0,
        key: 'all_documents',
        default: true
      },
      {
        value: 1,
        key: 'customer_view'
      },
      {
        value: 2,
        key: 'document_visibility'
      },
      {
        value: 3,
        key: 'invoices'
      },

    ]
  },
  {
    value: 2,
    key: 'tracking'
  },
  {
    value: 3,
    key: 'stuffing_photos'
  },
  {
    value: 4,
    key: 'people'
  },
  {
    value: 5,
    key: 'order_progress',
    children: [
      {
        value: 0,
        key: 'activity',
        default: true
      },
      {
        value: 1,
        key: 'pending_task'
      }
    ]
  },
  {
    value: 6,
    key: 'tasks',
    defaultFor: ['internal'],
    children: [
      {
        value: 0,
        key: 'all'
      },
      {
        value: 1,
        key: 'in_progress',
        default: true
      },
      {
        value: 2,
        key: 'past_completed'
      },
      {
        value: 3,
        key: 'generate_label'
      }
    ]
  },
  {
    value: 7,
    key: 'emails',
    children: [
      {
        value: 0,
        key: 'history'
      },
      {
        value: 1,
        key: 'to_be_sent',
        default: true
      },
      {
        value: 2,
        key: 'outbox'
      }
    ]
  },
  {
    value: 8,
    key: 'Notes'
  }
];

interface IReadableOrderId {
  order_id: string;
  readable_order_id: string;
}

interface AdjacentOrderState {
  previousOrder: IReadableOrderId | null;
  nextOrder: IReadableOrderId | null;
  currentOrder: IReadableOrderId | null;
}

const ORDER_TABS = [
  {
    label: EVENT_TRACKING_TYPES.ORDER_OVERVIEW_TAB_SELECT,
    value: 0
  },
  {
    label: EVENT_TRACKING_TYPES.ORDER_DOCUMENTS_TAB_SELECT,
    value: 1
  },
  {
    label: EVENT_TRACKING_TYPES.ORDER_TRACKING_TAB_SELECT,
    value: 2
  },
  {
    label: EVENT_TRACKING_TYPES.ORDER_STUFFING_PHOTOS_TAB_SELECT,
    value: 3
  },
  {
    label: EVENT_TRACKING_TYPES.ORDER_PEOPLE_TAB_SELECT,
    value: 4
  },
  {
    label: EVENT_TRACKING_TYPES.ORDER_PROGRESS_TAB_SELECT,
    value: 5
  }
];

const getDefaultTab = (userType: string) => {
  const defaultTab = ORDER_STATE_MAPPING.find(
    (tab) => tab.defaultFor && tab.defaultFor.includes(userType)
  );
  return defaultTab ? defaultTab : ORDER_STATE_MAPPING[0];
};

const OrderIndividual = (props: any) => {
  const { modulePermissions, otherModulePermissions } = props;
  const { actions } = modulePermissions;
  const [orderOverviewSectionValue, setOrderOverviewSectionValue] = useState(-1);
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const topRef = useRef<HTMLDivElement>(null);

  const orderActivityActions = useMemo(
    () =>
      otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderActivity)?.actions,
    [otherModulePermissions]
  );
  const orderTrackingActions = useMemo(
    () =>
      otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderTracking)?.actions,
    [otherModulePermissions]
  );
  const orderDocumentsActions = useMemo(
    () =>
      otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderDocuments)?.actions,
    [otherModulePermissions]
  );
  const orderPeopleActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderPeople)?.actions,
    [otherModulePermissions]
  );
  const orderOverviewActions = useMemo(
    () =>
      otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderOverview)?.actions,
    [otherModulePermissions]
  );
  const orderStuffingPhotosActions = useMemo(
    () =>
      otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderStuffingPhotos)
        ?.actions,
    [otherModulePermissions]
  );
  const orderTasksActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.task)?.actions,
    [otherModulePermissions]
  );

  const emailActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.email)?.actions,
    [otherModulePermissions]
  );

  const notesActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.note)?.actions,
    [otherModulePermissions]
  );

  const userType = useMemo(() => getPermissionFromLocalStorage()?.user_type, []);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabParam = searchParams.get('activeTab');
  const defaultTab = useMemo(() => getDefaultTab(userType), [userType]);
  const initialOrderState = useMemo(() => {
    return ORDER_STATE_MAPPING.find((item) => item.key === activeTabParam)?.key || defaultTab.key;
  }, [activeTabParam]);

  function getValueByKey(key: string) {
    const task = ORDER_STATE_MAPPING.find((item) => item.key === key);
    return task ? task.value : defaultTab.value;
  }

  const [orderState, setOrderState] = useState<OrderIndiviualState>({
    orderInfo: null,
    activeTab: getValueByKey(initialOrderState),
    isLoading: false,
    pendingActions: null,
    activeSubTab: 0
  });

  const [adjacentOrders, setAdjacentOrders] = useState<AdjacentOrderState>({
    previousOrder: null,
    nextOrder: null,
    currentOrder: null
  });

  const { orderInfo, activeTab, isLoading, pendingActions, activeSubTab } = orderState;

  const getPreviousAndNextOrder = async (orderId: string) => {
    const response = await getAdjacentOrderIds(orderId);
    if (response.success) {
      setAdjacentOrders({
        previousOrder: response.data.previous_order,
        nextOrder: response.data.next_order,
        currentOrder: response.data.current_order
      });
    } else {
      notify({
        message: response.error ?? 'Something Went Wrong',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
      setTimeout(() => {
        topRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
  }, [activeTab, topRef]);

  useEffect(() => {
    userType === USER_TYPES.internal && getPreviousAndNextOrder(id as string);
    getOrderInfo(id as string);
    executeFunctionByCondition(orderTasksActions || emailActions, getPendingActions);
    const activeTabQuery = searchParams?.get('activeTab');
    const activeSubTab = searchParams?.get('activeSubTab');
    if (
      activeTabQuery &&
      parseInt(activeTabQuery as string) >= 0 &&
      activeSubTab &&
      parseInt(activeSubTab as string) >= 0
    ) {
      setOrderState((prevState) => ({
        ...prevState,
        activeTab: parseInt(activeTabQuery as string),
        activeSubTab: parseInt(activeSubTab as string)
      }));
    } else if (activeSubTab && parseInt(activeSubTab as string) >= 0) {
      setOrderState((prevState) => ({
        ...prevState,
        activeSubTab: parseInt(activeTabQuery as string),
        activeTab: userType === USER_TYPES.customer ? 0 : 6
      }));
    } else if (
      (activeTabQuery && parseInt(activeTabQuery as string) < 0) ||
      parseInt(activeTabQuery as string) > 7
    ) {
      setOrderState((prevState) => ({
        ...prevState,
        activeTab: userType === USER_TYPES.customer ? 0 : 6
      }));
    } else if (activeTabQuery && parseInt(activeTabQuery as string) >= 0) {
      setOrderState((prevState) => ({
        ...prevState,
        activeTab: parseInt(activeTabQuery as string)
      }));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', () => {
      navigate(0);
    });
  }, [activeTabParam]);

  useEffect(() => {
    !(userType == USER_TYPES.customer) && orderInfo != null && getOrderIdList();
    const readableOrderIdObject = {
      readable_order_id: orderInfo?.readable_order_id ?? '',
      order_id: id ?? ''
    };
    setCurrentOrderId(readableOrderIdObject);
  }, [orderInfo]);

  const getOrderInfo = async (orderId: string) => {
    if (orderId) {
      setOrderState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await fetchOrderInfo(orderId);
      if (response.success) {
        const { data } = response;
        setOrderState((prevState) => ({ ...prevState, orderInfo: data, isLoading: false }));
      } else {
        setOrderState((prevState) => ({ ...prevState, isLoading: false }));
        navigate('/404');
      }
    }
  };

  const [readableOrderIdList, setReadableOrderIdList] = useState([]);
  const [currentOrderId, setCurrentOrderId] = useState<IReadableOrderId>();

  const getOrderIdList = async () => {
    const response = await getReadableOrderIdList();
    const readableOrderIdObject = {
      readable_order_id: orderInfo?.readable_order_id,
      order_id: id
    };
    if (response.success) {
      let orderIdList = response.data.results;
      const isPresent = orderIdList.some(
        (order: IReadableOrderId) =>
          order.readable_order_id === readableOrderIdObject.readable_order_id
      );
      if (!isPresent) {
        orderIdList = [...orderIdList, readableOrderIdObject];
      }
      setReadableOrderIdList(response.data.results);
    } else {
      notify({
        message: response.error ?? 'Unable To Fetch Order List',
        severity: 'error'
      });
    }
  };

  const getPendingActions = async () => {
    const { id } = params;
    const response = await orderPendingActions(id ?? '');
    if (response?.success) {
      setOrderState((prevState) => ({
        ...prevState,
        pendingActions: response?.data
      }));
    }
  };

  const handleTabChange = useCallback((value: number, subtab?: number) => {
    const newTabItem = ORDER_STATE_MAPPING.find((item) => item.value === value);
    if (newTabItem) {
      setOrderState((prevState) => ({
        ...prevState,
        activeTab: value,
        activeSubTab:
          subtab !== undefined
            ? subtab
            : newTabItem.children
            ? newTabItem.children.find((child) => child.default)?.value || 0
            : 0
      }));
      pushEventTracking(ORDER_TABS.find((tab) => tab.value === value)?.label ?? '');
      setSearchParams({
        activeTab: newTabItem.key,
        ...(newTabItem.children && {
          activeSubTab:
            subtab !== undefined
              ? newTabItem.children.find((child) => child.value === subtab)?.key
              : newTabItem.children.find((child) => child.default)?.key
        })
      });
    }
  }, []);

  const handleOrderIdChange = async (event: any, param: string) => {
    if (param == currentOrderId?.readable_order_id) return;
    const response = await getReadableOrderIdList(param ?? '');
    if (response.success) {
      setReadableOrderIdList(response.data.results);
    } else {
      notify({
        message: response.error ?? 'Unable To Fetch Order List',
        severity: 'error'
      });
    }
  };

  const orderListInputSelection = (event: any, value: IReadableOrderId) => {
    navigate(`/order/${value?.order_id}`);
    navigate(0);
  };

  const documentsChildren =
    ORDER_STATE_MAPPING.find((item) => item.key === 'documents')?.children || [];
  const orderProgressChildren =
    ORDER_STATE_MAPPING.find((item) => item.key === 'order_progress')?.children || [];
  const taskChildren = ORDER_STATE_MAPPING.find((item) => item.key === 'tasks')?.children || [];
  const emailChildren = ORDER_STATE_MAPPING.find((item) => item.key === 'emails')?.children || [];

  if (isLoading) {
    return <OrderIndividualSkeleton />;
  }

  return (
    <main className={css.orderWrapper}>
      {orderInfo && <Seo title={orderInfo.consignee_name} />}
      <div className={css.orderIndividualHeader}>
        {orderInfo && (
          <BreadCrumb>
            <BreadCrumb.Item value={0} to={`/${CLIENT_ROUTES.order}`}>
              Orders List
            </BreadCrumb.Item>
            <div className={css.breadCrumbItem}>
              #{' '}
              {userType == USER_TYPES.customer ? (
                orderInfo.po_reference_no
              ) : (
                <AutoComplete
                  disableClearable
                  value={currentOrderId}
                  options={readableOrderIdList}
                  placeholder="Order Id"
                  onInputChange={handleOrderIdChange}
                  width="175%"
                  keyOption="readable_order_id"
                  onInputSelection={orderListInputSelection}
                />
              )}
            </div>
          </BreadCrumb>
        )}
        <AccessWrapper show={actions?.read} showTo={[USER_TYPES.customer]} byPassShowWithShowTo>
          <LinkButton
            variant="outlined-secondary"
            to={`/enquiry?activestep=placeRFQ`}
            eventTrackingName="RFQ_BTN_CLICK">
            Place RFQ
          </LinkButton>
        </AccessWrapper>
        <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]} byPassShowWithShowTo>
          <div className={css.prevNextOrder}>
            <div className={css.prevNextButtons}>
              {adjacentOrders.previousOrder?.order_id && (
                <Button
                  variant="text"
                  startIcon={<IconNode src={Images.previousLeft} alt="Previous icon" />}
                  eventTrackingName="PREV_ORDER_BTN_CLICK"
                  disabled={adjacentOrders.previousOrder?.order_id == null}
                  onClick={() =>
                    adjacentOrders.previousOrder != null &&
                    orderListInputSelection(null, adjacentOrders.previousOrder)
                  }>
                  {adjacentOrders.previousOrder &&
                    sanitizeValue(adjacentOrders.previousOrder?.readable_order_id)}
                </Button>
              )}
              {adjacentOrders.nextOrder?.order_id && (
                <Button
                  variant="text"
                  endIcon={<IconNode src={Images.forwardRight} alt="Next icon" />}
                  eventTrackingName="NEXT_ORDER_BTN_CLICK"
                  disabled={adjacentOrders.nextOrder?.order_id == null}
                  onClick={() =>
                    adjacentOrders.nextOrder != null &&
                    orderListInputSelection(null, adjacentOrders.nextOrder)
                  }>
                  {adjacentOrders.nextOrder && adjacentOrders.nextOrder?.readable_order_id}
                </Button>
              )}
            </div>
          </div>
        </AccessWrapper>
      </div>

      <AccessWrapper show={actions?.read}>
        {orderInfo && (
          <OrderCard
            customerName={orderInfo.customer?.name ? orderInfo.customer?.name : '-'}
            items={orderInfo.order_item}
            paymentTerm={orderInfo.payment_terms_display_value}
            placeOfDelivery={orderInfo?.place_of_delivery}
            createdAt={orderInfo.created_at as any}
            totalAmount={orderInfo.total}
            amountDue={orderInfo.amount_due}
            currentState={orderInfo.current_state_for_customer_present_tense}
            orderInfo={orderInfo}
            pendingTasks={orderInfo.pending_tasks ?? []}
            handleTabChange={handleTabChange}
            actions={actions}
            setOrderOverviewSectionValue={setOrderOverviewSectionValue}
          />
        )}
      </AccessWrapper>

      {orderInfo && (
        <Fragment>
          <div ref={topRef}>
            <Tabs value={activeTab}>
              <AccessWrapper show={orderOverviewActions?.read}>
                <Tab value={0} label="Order Overview" onClick={() => handleTabChange(0)} />
              </AccessWrapper>
              <AccessWrapper show={orderDocumentsActions?.read}>
                <Tab value={1} label="Documents" onClick={() => handleTabChange(1)} />
              </AccessWrapper>

              <AccessWrapper show={orderTrackingActions?.read}>
                <Tab value={2} label="Tracking" onClick={() => handleTabChange(2)} />
              </AccessWrapper>
              <AccessWrapper show={orderStuffingPhotosActions?.read}>
                <Tab value={3} label="Stuffing Photos" onClick={() => handleTabChange(3)} />
              </AccessWrapper>
              <AccessWrapper show={orderPeopleActions?.read}>
                <Tab value={4} label="People" onClick={() => handleTabChange(4)} />
              </AccessWrapper>
              <AccessWrapper show={orderActivityActions?.read}>
                <Tab value={5} label="Order Progress" onClick={() => handleTabChange(5)} />
              </AccessWrapper>

              <AccessWrapper show={orderTasksActions?.read}>
                <Tab
                  value={6}
                  label="Tasks"
                  icon={
                    <Chip
                      variant={`filled-red`}
                      label={pendingActions?.tasks?.count ?? 0}
                      clickable={false}
                    />
                  }
                  iconPosition="end"
                  onClick={() => handleTabChange(6)}
                />
              </AccessWrapper>
              <AccessWrapper show={emailActions?.read}>
                <Tab
                  value={7}
                  label="Emails"
                  icon={
                    <Chip
                      variant={`filled-red`}
                      label={pendingActions?.nudge_emails?.count ?? 0}
                      clickable={false}
                    />
                  }
                  iconPosition="end"
                  onClick={() => handleTabChange(7)}
                />
              </AccessWrapper>
              <AccessWrapper show={notesActions?.read}>
                <Tab value={8} label="Notes" onClick={() => handleTabChange(8)} />
              </AccessWrapper>
            </Tabs>
          </div>
          {activeTab === 0 && (
            <AccessWrapper show={orderOverviewActions?.read}>
              <OrderOverview
                orderOverviewSectionValue={orderOverviewSectionValue}
                setOrderOverviewSectionValue={setOrderOverviewSectionValue}
                actions={actions}
              />
            </AccessWrapper>
          )}
          {activeTab === 1 && (
            <AccessWrapper show={orderDocumentsActions?.read}>
              <DocumentTab
                orderId={orderInfo.order_id}
                readableOrderId={orderInfo.readable_order_id}
                customerPocs={orderInfo?.customer_poc}
                activeSubTab={activeSubTab}
                actions={orderDocumentsActions}
                documentMenuMapping={documentsChildren}
              />
            </AccessWrapper>
          )}
          {activeTab === 2 && (
            <AccessWrapper show={orderTrackingActions?.read}>
              <Tracking
                orderId={orderInfo.order_id}
                shipmentType={orderInfo?.transportation_mode_display_value}
              />
            </AccessWrapper>
          )}
          {activeTab === 3 && (
            <AccessWrapper show={orderStuffingPhotosActions?.read}>
              <StuffingPhotos orderId={orderInfo.order_id} actions={orderStuffingPhotosActions} />
            </AccessWrapper>
          )}

          {activeTab === 4 && (
            <AccessWrapper show={orderPeopleActions?.read}>
              <OrderPeopleTab />
            </AccessWrapper>
          )}

          {activeTab === 5 && (
            <AccessWrapper show={orderActivityActions?.read}>
              <ActivityLogTab
                orderId={orderInfo.order_id}
                actions={orderActivityActions}
                orderProgressMapping={orderProgressChildren}
              />
            </AccessWrapper>
          )}
          {activeTab === 6 && (
            <AccessWrapper show={orderTasksActions?.read}>
              <TaskTab
                orderInfo={orderState}
                getOrderInfo={getOrderInfo}
                getPendingActions={getPendingActions}
                taskMapping={taskChildren}
              />
            </AccessWrapper>
          )}

          {activeTab === 7 && (
            <AccessWrapper show={emailActions?.read}>
              <CommunicationTab orderId={orderInfo.order_id} emailMapping={emailChildren} readable_order_id={orderInfo.readable_order_id}/>
            </AccessWrapper>
          )}
          {activeTab === 8 && (
            <AccessWrapper show={notesActions?.read}>
              <Notes actions={notesActions} />
            </AccessWrapper>
          )}
        </Fragment>
      )}
    </main>
  );
};

export default OrderIndividual;

const OrderIndividualCardSkeleton = () => {
  return (
    <div className={css.mainWrapper}>
      <div className={css.header}>
        <KeyValueSkeleton />
        <KeyValueSkeleton />
        <KeyValueSkeleton />
        <KeyValueSkeleton />
      </div>
      <div className={css.header}>
        <KeyValueSkeleton />
        <KeyValueSkeleton />
        <KeyValueSkeleton />
        <KeyValueSkeleton />
      </div>
    </div>
  );
};

const OrderIndividualSkeleton = () => {
  const userType = useMemo(() => getPermissionFromLocalStorage()?.user_type, []);
  return (
    <div className={css.container}>
      <OrderIndividualCardSkeleton />
      <div className={css.filterTabs}>
        {[...Array(6)].map((_, index) => (
          <Skeleton key={index} variant="text" width={100} height={20} />
        ))}
      </div>
      {/* {userType === USER_TYPES.internal ? <TaskSkeleton /> : <InternalOrderOverview />} */}
    </div>
  );
};
