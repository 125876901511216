import { forwardRef, memo } from 'react';
import { IconNode, Input, InputProps, Spinner } from '@components/base';
import Images from '@assets/images';

interface SearchBarProps extends InputProps {
  isLoading: boolean;
}

const SearchBar = forwardRef((props: SearchBarProps, ref) => {
  const { isLoading, ...otherProps } = props;
  return (
    <Input
      {...otherProps}
      ref={ref}
      type="search"
      inputMode="search"
      enterKeyHint="search"
      autoComplete="organization"
      startIcon={<IconNode src={Images.search} alt="search icon" />}
      endIcon={isLoading && <Spinner />}
    />
  );
});

export default memo(SearchBar);
