import css from './index.module.scss';
import { Typography } from '@components/base';
import { SideBar } from '@components/common';
import TransportDetailForm from './detail-form';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TransportDetails } from '@helpers/types/transport-details';
import { transportDetailsSchema } from '@helpers/yup/add-transport-details.schema';
import { useEffect, useState } from 'react';
import { fetchOrderInfoFinance } from '@services/order.service';
import { getTaskList, postTransportDetails } from '@services/task.service';
import notify from '@helpers/toastify-helper';

const AddTransportDetail = () => {
  const [orderData, setOrderData] = useState<any>([]);
  const urlParams = useParams();
  const { taskid, supplierid } = urlParams;
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};

  const transportDetailsForm = useForm({
    resolver: yupResolver(transportDetailsSchema),
    defaultValues: {
      transportdetails: [
        {
          transport_truck_number: '',
          net_wt_mt: '',
          gross_wt_mt: '',
          tare_wt_mt: '',
          lot_number: '',
          product_details: '',
          marks_and_number: '',
          transportation_costs: 0
        }
      ]
    }
  });

  const handleTypeOfOrderFormSubmit: SubmitHandler<TransportDetails> = async () => {
    const transportData = transportDetailsForm.control._formValues;
    // const supplierId = orderData[0]?.description?.split(" ")[1];
    // const task_id = orderData[0]?.task_id;
    const response: any = await postTransportDetails(
      String(taskid),
      String(supplierid),
      transportData
    );

    if (response.success) {
      //const dataOnComplete = {taskIsCompleted : true};
      notify({
        message: 'Added Transport details successfully',
        severity: 'success',
        dismissible: true
      });

      navigate(`/order/${data.orderId}`, {
        replace: true
      });
    } else {
      notify({
        message: response.error ?? 'Error adding Transport Details',
        severity: 'error',
        dismissible: true
      });
    }
    return response;
  };

  const onCancel = () => {
    navigate(`/order/${data.orderId}`);
  };

  return (
    <main className={css.mainWrapper}>
      <Typography variant="h4">Add Transportation Details</Typography>

      <section className={css.sectionWrapper}>
        <div className={css.sidebarWrapper}>
          <SideBar>
            <SideBar.Item label="Details" value={0} />
          </SideBar>
        </div>
        <FormProvider {...transportDetailsForm}>
          <TransportDetailForm onCancel={onCancel} onFormSubmit={handleTypeOfOrderFormSubmit} />
        </FormProvider>
      </section>
    </main>
  );
};

export default AddTransportDetail;
