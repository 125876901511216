import { Fragment, ImgHTMLAttributes, memo, useState } from 'react';
import './index.style.scss';
import { Typography } from '@components/base';
import { ReactComponent as Icon } from '@assets/images/rectangle.svg';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallbackText?: string;
  fallbackClass?: string;
}

function Image(props: ImageProps) {
  const { src, alt, fallbackText, className, fallbackClass, ...otherProps } = props;
  const [imagePlaceholder, setImagePlaceholder] = useState<boolean>(false);

  const handleError = (event: any) => {
    event.currentTarget.onerror = null;
    if (!imagePlaceholder) setImagePlaceholder(true);
  };

  if (src && !imagePlaceholder) {
    return (
      <Fragment>
        <img
          {...otherProps}
          src={src}
          alt={alt}
          className={className}
          draggable={false}
          onError={handleError}
        />
      </Fragment>
    );
  } else if (fallbackText) {
    return (
      <div
        role="presentation"
        className={`image-placeholder ${fallbackClass}`}
        onClick={props.onClick as any}
        onKeyDown={props.onKeyDown as any}>
        <Typography className="profile-text">
          {fallbackText.split(' ')[0][0]?.toUpperCase()}
        </Typography>
      </div>
    );
  }
  return (
    <Fragment>
      <Icon className={className} onClick={props.onClick as any} />
    </Fragment>
  );
}

export type { ImageProps };
export default memo(Image);
