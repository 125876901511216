import { useCallback } from 'react';
import css from './index.module.scss';
import { Button, IconNode, Loader, Modal, Typography } from '@components/base';
import {
  DragDropUpload,
  InputDatePicker,
  SelectLabel,
  TextAreaLabel,
  TextField
} from '@components/common';
import { useForm, Controller, useWatch, SubmitHandler } from 'react-hook-form';
import Images from '@assets/images';
import { PAYMENT_TERMS } from '@helpers/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { paymentDetailSchema } from '@helpers/yup/add-payment.schema';
import { customerAcceptanceProof } from '@helpers/yup/upload-customer-acceptance.schema';
import { ISelect } from '@helpers/types';
import { addOrderPaymentDetail } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { uploadCustomerAcceptance } from '@services/task.service';

interface FormType {
  acceptance_proof: File | null;
}

interface AddPaymentDetailsProps {
  open: boolean;
  onClose?: () => void;
  taskId: string;
  sendStatusToCard: (arg: boolean) => void;
  onComplete?: () => void;
}

const UploadDocModal = (props: AddPaymentDetailsProps) => {
  const { open, onClose, sendStatusToCard, onComplete } = props;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors }
  } = useForm<FormType>({
    resolver: yupResolver(customerAcceptanceProof),
    defaultValues: {
      acceptance_proof: null
    }
  });

  const watch = useWatch({ name: 'acceptance_proof', control });

  const handleFileSelect = (file: any[]) => {
    if (file.length) {
      setValue('acceptance_proof', file[0]);
    }
  };

  const handleDeleteFile = useCallback(() => {
    setValue('acceptance_proof', null);
  }, []);

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const { onClose, taskId } = props;
    if (!data?.acceptance_proof) {
      notify({ message: 'Please upload Order Acceptance Document', severity: 'error' });
      return;
    }
    const reqBody = new FormData();
    data.acceptance_proof &&
      reqBody.append('customer_order_acceptance_proof', data.acceptance_proof);
    const response = await uploadCustomerAcceptance(taskId, reqBody);
    if (response.success) {
      notify({ message: 'Order Acceptance Document Uploaded Successfully' });
      sendStatusToCard(true);
      if (onComplete) {
        onComplete();
      }
      onClose?.();
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else notify({ message: 'Some error occurred', severity: 'error' });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Upload Customer Acceptance Proof</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={css.formWrapper}>
          <div className={css.fieldsWrapper}>
            <label htmlFor="Acceptance">
              <Typography variant="p" className={css.uploadWrapper}>
                Upload Proof
              </Typography>
              <Controller
                name="acceptance_proof"
                control={control}
                render={() => <DragDropUpload onDrop={handleFileSelect} />}
              />
              {watch && (
                <div className={css.fileUploadState}>
                  <Typography variant="p" className={css.fileName}>
                    {watch.name}
                  </Typography>
                  <IconNode
                    src={Images.deleteRed}
                    alt="delete icon"
                    component="button"
                    className={css.deleteButton}
                    onClick={handleDeleteFile}
                  />
                </div>
              )}
            </label>
          </div>
          <div className={css.actionButtonWrapper}>
            <Button variant="outlined-secondary" onClick={onClose} className={css.actionButton}>
              Cancel
            </Button>
            <Button type="submit" className={css.actionButton}>
              Save
            </Button>
          </div>
        </form>
        <Loader open={isSubmitting} />
      </div>
    </Modal>
  );
};

export default UploadDocModal;
