import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { IconNode, Image, Typography } from '@components/base';
import ProgressBarModal from '../progress-bar-modal';

import css from './index.module.scss';
import { checkScreenType, pushEventTracking } from '@helpers/utils';
import { EVENT_TRACKING_TYPES, SCREEN_TYPE } from '@helpers/constants';
import Images from '@assets/images';
import { StepIcon, StepIconProps } from '@mui/material';

interface StepProgressBarProps {
  label: any;
  currentStep: number;
  orientation?: 'horizontal' | 'vertical';
  modalRequired?: boolean;
  alternativeLabel?: boolean;
}

interface CustomStepIconProps extends StepIconProps {
  active?: boolean;
  index: number;
  label: string;
}

const ICON_MAPPING = [
  {
    index: 0,
    label: 'Order Placed',
    iconCompleted: Images.orderPlaced,
    iconNotCompleted: Images.orderPlacedGrey
  },
  {
    index: 1,
    label: 'Order Under Confirmation',
    iconCompleted: Images.orderUnderConfirmation,
    iconNotCompleted: Images.orderUnderConfirmationGrey
  },
  {
    index: 2,
    label: 'Order Confirmed',
    iconCompleted: Images.orderConfirmed,
    iconNotCompleted: Images.orderConfirmedGrey
  },
  {
    index: 3,
    label: 'Products Manufactured',
    iconCompleted: Images.productsManufactured,
    iconNotCompleted: Images.productsManufacturedGrey
  },
  {
    index: 4,
    label: 'Export Clearance Obtained',
    iconCompleted: Images.exportClearance,
    iconNotCompleted: Images.exportClearanceGrey
  },
  {
    index: 5,
    label: 'Shipment Departure from POL',
    iconCompleted: Images.shipmentDeparture,
    iconNotCompleted: Images.shipmentDepartureGrey
  },
  {
    index: 6,
    label: 'Shipment Arrival at POD',
    iconCompleted: Images.shipmentArrival,
    iconNotCompleted: Images.shipmentArrivalGrey
  },
  {
    index: 7,
    label: 'Order Completed',
    iconCompleted: Images.orderCompleted,
    iconNotCompleted: Images.orderCompletedGrey
  }
];

const CustomStepIcon = (props: CustomStepIconProps) => {
  const { active, completed, index, label } = props;
  const currentState = ICON_MAPPING?.find((item) => item.label === label);
  if (active) {
    return (
      <IconNode
        src={Images.progressBarGIF}
        alt="Current State GIF"
        className={css.progressBarStep}
      />
    );
  } else if (currentState) {
    return (
      <IconNode
        src={completed ? currentState.iconCompleted : currentState.iconNotCompleted}
        style={{
          width: 45,
          height: 45
        }}
      />
    );
  }

  return <StepIcon {...props} />;
};

const stepStyle = {
  '.Mui-completed': {
    '.MuiSvgIcon-root': {
      color: '#009999'
    },
    '.MuiStepConnector-line': {
      borderColor: '#009999'
    }
  },
  '.MuiSvgIcon-root.Mui-active': {
    color: '#028783',
    fontSize: 35,
    position: 'relative',
    bottom: 7
  },
  '.MuiStepIcon-text': {
    display: 'none'
  },
  '.MuiStepLabel-iconContainer.Mui-active': {
    height: 54
  }
};

const StepProgressBar = (props: StepProgressBarProps) => {
  const {
    label,
    currentStep,
    orientation = 'horizontal',
    modalRequired = true,
    alternativeLabel = true
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [outerLabel, setOuterLabel] = useState<any>([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [modalStates, setOpenModalStates] = useState({
    label: [],
    currentStep: 0,
    orientation: 'vertical'
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (checkScreenType() != SCREEN_TYPE.mobile.label) {
        setOuterLabel(label);
        setCurrentStepIndex(currentStep);
      } else {
        if (currentStep == label.length - 1) {
          setOuterLabel([label[0], label[currentStep]]);
        } else {
          setOuterLabel([label[0], label[currentStep], label[label.length - 1]]);
        }
        setCurrentStepIndex(1);
      }
    }
  }, [label]);

  const fetchModalDetails = (data: any, modalState: boolean) => {
    setOpenModal(modalState);
    let logsBetweenStates: any = [];
    if (data?.logs?.length) {
      logsBetweenStates = [...data.logs];
      if (data?.index > 0 && data?.index < label?.length) {
        logsBetweenStates.push(label[data?.index]);
        logsBetweenStates.unshift(label[data?.index - 1]);
      } else if (data?.index == 0) {
        logsBetweenStates.push(label[data?.index + 1]);
        logsBetweenStates.unshift(label[data?.index]);
      } else if (data?.index == label?.length - 1) {
        logsBetweenStates.push(label[data?.index]);
        logsBetweenStates.unshift(label[data?.index - 1]);
      }
    }
    setOpenModalStates((prevState) => ({
      ...prevState,
      label: logsBetweenStates,
      currentStep:
        data?.index >= currentStep
          ? Math.min(logsBetweenStates?.length - 2, logsBetweenStates?.length)
          : 100
    }));
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Stepper
          activeStep={currentStepIndex}
          alternativeLabel={alternativeLabel}
          sx={stepStyle}
          orientation={orientation}>
          {outerLabel.map((item: any, index: number) => (
            <Step key={index}>
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon {...props} index={index} label={item?.label} />
                )}
                onClick={
                  modalRequired && item?.logs?.length
                    ? () => {
                        fetchModalDetails({ ...item, index }, true);
                        pushEventTracking(EVENT_TRACKING_TYPES.PROGRESS_BAR_DETAILED_CLICK);
                      }
                    : undefined
                }
                style={{
                  cursor: modalRequired && item?.logs?.length ? 'pointer' : 'default'
                }}>
                <Typography
                  variant="pdoc"
                  className={`${currentStep == index ? css.currentState : css.primaryText}`}>
                  {item?.label}
                </Typography>
                <Typography variant="p" className={css.secondaryText}>
                  {item?.date}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <ProgressBarModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        data={modalStates.label}
        currentStep={modalStates.currentStep}
      />
    </>
  );
};

export default StepProgressBar;
