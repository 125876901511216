/* eslint-disable @typescript-eslint/no-empty-function */
import Images from '@assets/images';
import { Button, CheckboxLabel, Divider, IconNode, Typography } from '@components/base';
import {
  BoxContainer,
  DataGrid,
  DocumentRow,
  SelectLabel,
  TextAreaLabel,
  TextField
} from '@components/common';
import { IItemDetail, IItems } from '@helpers/types/pi';
import { memo, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { CellProps, Column } from 'react-table';
import {
  DEFAULT_AMOUNT_ROUND_OFF,
  QUANTITY_INPUT_DISABLE_SWITCH,
  booleanSelectFields
} from '@helpers/constants';
import css from './index.module.scss';
import { generateReadablePackingDetails } from '@helpers/utils';

interface ItemDetailContainerProps {
  itemsList: IItems[];
  setPostTotalAmount?: (text: string) => void;
  postTotalAmount?: string;
  currency?: string;
  unitOfWeight?: string;
}

interface DocumentGrid {
  name: string;
  proof: string;
}

interface AdhocOrderDetailFormProps {
  currency?: string;
  unitOfWeight?: string;
}

const ItemDetailContainer = (props: ItemDetailContainerProps) => {
  const { control } = useFormContext();
  const {
    itemsList,
    setPostTotalAmount = () => {},
    postTotalAmount,
    currency,
    unitOfWeight
  } = props;
  const { fields, append, remove } = useFieldArray({
    name: 'custom_items',
    control
  });

  const watch = useWatch({
    name: 'custom_items',
    control
  });

  const itemsCount = itemsList.reduce(
    (prevValue: number, item) => Number(item?.total_amount) + prevValue,
    0
  );

  const totalAmount = watch?.reduce(
    (previousValue: number, item: IItemDetail) => Number(item.custom_item_amount) + previousValue,
    0
  );

  setPostTotalAmount(String(Number(itemsCount) + Number(totalAmount)));

  const handleAddField = () => {
    append({
      custom_item_name: '',
      custom_item_description: '',
      custom_item_rate: '',
      custom_item_quantity: '',
      custom_item_amount: ''
    });
  };

  const productItemLength = itemsList.length;
  return (
    <BoxContainer title="Item Details" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <AdhocOrderDetailForm currency={currency} unitOfWeight={unitOfWeight} />
      </div>
      <Divider className={css.dividerWrapper} />
      <div className={css.boxWrapper}>
        <div className={css.itemFieldWrapper}>
          {itemsList?.map((item, index) => {
            return (
              <>
                <ItemDetailFields
                  key={index}
                  index={index}
                  items={item}
                  rate_pi={`order_items.${index}.rate_pi`}
                  quantity_pi={`order_items.${index}.quantity_pi`}
                  order_item_id={`order_items.${index}.order_item_id`}
                  order_item_description_pi={`order_items.${index}.order_item_description_pi`}
                  currency={currency}
                  unit_of_weight={unitOfWeight}
                  include_grade_name_in_docs={`order_items.${index}.include_grade_name_in_docs`}
                  is_pre_shipment_sample_required={`order_items.${index}.is_pre_shipment_sample_required`}
                />
                <Divider className={css.divider} />
              </>
            );
          })}
          {fields.map((item, index) => {
            const handleDeleteClick = () => remove(index);
            return (
              <CustomItemsField
                key={item.id}
                index={index}
                custom_item_name={`custom_items.${index}.custom_item_name`}
                custom_item_amount={`custom_items.${index}.custom_item_amount`}
                custom_item_description={`custom_items.${index}.custom_item_description`}
                custom_item_quantity={`custom_items.${index}.custom_item_quantity`}
                custom_item_rate={`custom_items.${index}.custom_item_rate`}
                onDeleteClick={handleDeleteClick}
                showDelete
                productItemLength={productItemLength}
                currency={currency}
                unit_of_weight={unitOfWeight}
              />
            );
          })}
          <Button
            variant="text"
            onClick={handleAddField}
            startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
            Add Custom Item
          </Button>
        </div>
        {/* <Divider className={css.dividerWrapper} />
        <TextField
          disabled
          value={postTotalAmount}
          required
          label="Total Amount"
          placeholder="Total amount"
        /> */}
      </div>
    </BoxContainer>
  );
};

interface ItemDetailFieldsProps {
  index: number;
  items: IItems;
  quantity_pi: string;
  rate_pi: string;
  order_item_id: string;
  currency: string;
  unit_of_weight: string;
  order_item_description_pi: string;
  include_grade_name_in_docs: string;
  is_pre_shipment_sample_required: string;
}

const ItemDetailFields = (props: any) => {
  const {
    items,
    index,
    quantity_pi,
    rate_pi,
    order_item_id,
    currency,
    unit_of_weight,
    order_item_description_pi,
    include_grade_name_in_docs,
    is_pre_shipment_sample_required
  } = props;
  const { order_item_document: ItemsDoc } = items;
  const { control, setValue, watch } = useFormContext();

  const totalAmount = useMemo(() => {
    return watch(quantity_pi) * watch(rate_pi);
  }, [watch(quantity_pi), watch(rate_pi)]);

  const [documentColumn, documentData] = useMemo(() => {
    const column: Column<DocumentGrid>[] = [
      {
        Header: 'Document Name',
        accessor: 'name',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return (
            <DocumentRow.Title
              title={value?.document_type ?? ''}
              documentAvailable={value?.document_type ?? false}
            />
          );
        }
      },
      {
        Header: 'Proofs',
        accessor: 'proof',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return <DocumentRow.View document={value?.document_object ?? ''} title="View" />;
        }
      }
    ];
    const row: DocumentGrid[] = ItemsDoc?.map((items: any) => ({
      name: items,
      proof: items
    }));
    return [column, row];
  }, [props.items]);

  return (
    <div className={css.itemMainWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="h4">Item {index + 1}</Typography>
      </div>
      <div className={css.rowWrapper}>
        <SelectLabel
          required
          label="Product"
          placeholder="Select item"
          isDisabled
          value={{
            label: items.product_name,
            value: items.product_name
          }}
        />
      </div>
      <div className={css.rowWrapper}>
        <TextField
          disabled
          value={items.hs_code ?? '-'}
          label="HS Code"
          placeholder="Enter HS code"
        />
        <TextField
          disabled
          value={items.CAS_number ?? '-'}
          label="CAS Number"
          placeholder="Enter CAS Number"
        />
      </div>
      <div className={css.rowWrapper}>
        <TextField
          disabled
          value={items.product_grade_name ?? '-'}
          label="Grade"
          placeholder="Enter grade"
        />
        <Controller
          name={include_grade_name_in_docs}
          control={control}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              required
              isSearchable
              label="Show grade on document"
              placeholder="Select option"
              options={booleanSelectFields}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </div>

      <div className={css.rowWrapper}>
        {/* <TextField
          inputMode="decimal"
          enterKeyHint="next"
          autoComplete="off"
          label="Quantity in MT"
          placeholder="Enter rate"
          value={items.quantity_mt ?? ''}
        /> */}
        <Controller
          name={quantity_pi}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              inputMode="decimal"
              enterKeyHint="next"
              autoComplete="off"
              disabled={QUANTITY_INPUT_DISABLE_SWITCH}
              value={items.quantity}
              label={`Quantity ${unit_of_weight ? `in ${unit_of_weight}` : ``}`}
              placeholder="Enter rate"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        {/* <TextField
          required
          inputMode="decimal"
          enterKeyHint="next"
          autoComplete="off"
          label="Rate per MT (USD)"
          placeholder="Enter amount"
          value={items.rate_mt_usd ?? ''}
        /> */}
        <Controller
          name={rate_pi}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              inputMode="decimal"
              enterKeyHint="next"
              autoComplete="off"
              label={`Rate per ${unit_of_weight} (${currency ?? ``})`}
              placeholder="Enter amount"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        <TextField
          required
          disabled
          value={totalAmount ? totalAmount.toFixed(DEFAULT_AMOUNT_ROUND_OFF) : '-'}
          label={`Amount (${currency})`}
          placeholder="Enter amount"
        />
      </div>
      <div className={css.rowWrapper}>
        <TextField
          required
          disabled
          value={items?.package ? generateReadablePackingDetails(items?.package).join(', ') : '-'}
          label="Packaging details"
          placeholder="Enter"
        />
      </div>

      <div className={css.textfield}>
        <Controller
          name={order_item_description_pi}
          control={control}
          render={({ field, fieldState }) => (
            <TextAreaLabel
              {...field}
              label="Item Description"
              placeholder="Enter description"
              rows={5}
              rootClassName={css.fieldSpacing}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
      </div>
      <div className={css.textfield}>
        <Controller
          name={is_pre_shipment_sample_required}
          control={control}
          render={({ field }) => (
            <CheckboxLabel
              {...field}
              label="Is pre-shipment sample required?"
              value={`${field.value}`}
              checked={field.value}
            />
          )}
        />
      </div>
      {ItemsDoc?.length > 0 && (
        <>
          <Typography variant="p">Item Documents</Typography>
          <DataGrid columns={documentColumn} data={documentData} />
        </>
      )}
    </div>
  );
};

interface ICustomField {
  index: number;
  showDelete?: boolean;
  onDeleteClick?: () => void;
  custom_item_name: string;
  custom_item_amount: string;
  custom_item_description: string;
  productItemLength: number;
  custom_item_quantity: string;
  custom_item_rate: string;
}

const CustomItemsField = (props: any) => {
  const {
    custom_item_description,
    custom_item_quantity,
    custom_item_rate,
    index,
    custom_item_name,
    onDeleteClick,
    showDelete,
    custom_item_amount,
    productItemLength,
    currency,
    unit_of_weight
  } = props;

  const { control } = useFormContext();
  const watchField = useWatch({
    name: [custom_item_quantity, custom_item_rate],
    control
  });

  return (
    <div className={css.itemMainWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="h4">Item {productItemLength + index + 1}</Typography>
        {showDelete && (
          <Button
            variant="text"
            title="Delete Item"
            onClick={onDeleteClick}
            startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}>
            Delete
          </Button>
        )}
      </div>
      <Controller
        name={custom_item_name}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required
            label="Custom item"
            placeholder="Enter item name"
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
          />
        )}
      />
      <div className={css.rowWrapper}>
        <Controller
          name={custom_item_quantity}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              inputMode="decimal"
              enterKeyHint="next"
              autoComplete="off"
              type="text"
              label={`Quantity (${unit_of_weight})`}
              placeholder="Enter quantity"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        <Controller
          name={custom_item_rate}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              inputMode="decimal"
              enterKeyHint="next"
              autoComplete="off"
              type="text"
              label={`Rate (${currency ?? ``})`}
              placeholder="Enter rate"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        <Controller
          name={custom_item_amount}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="text"
              label={`Amount (${currency ?? ``})`}
              placeholder="Enter amount"
              inputMode="decimal"
              enterKeyHint="next"
              autoComplete="off"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
      </div>
      <Controller
        name={custom_item_description}
        control={control}
        render={({ field, fieldState }) => (
          <TextAreaLabel
            {...field}
            label="Item Description"
            placeholder="Enter description"
            rows={5}
            rootClassName={css.fieldSpacing}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
          />
        )}
      />
      <Divider className={css.divider} />
    </div>
  );
};

const AdhocOrderDetailForm = (props: AdhocOrderDetailFormProps) => {
  const { currency, unitOfWeight } = props;
  return (
    <div className={css.itemMainWrapper}>
      <div className={css.rowWrapper}>
        <TextField disabled value={currency} label="Currency" />
        <TextField disabled value={unitOfWeight} label="Unit of Weight" />
      </div>
    </div>
  );
};

export default memo(ItemDetailContainer);
