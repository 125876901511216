import { Accordion, Button, IconNode, Typography } from '@components/base';
import { Avatar } from '@components/common';
import css from './index.module.scss';
import Images from '@assets/images';
import Footer from '../components/footer';
import { useContext } from 'react';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { CLIENT_ROUTES } from '@router/routes';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@helpers/hooks/AppContext';
import { Link } from 'react-router-dom';

interface VerifyPropType {
  taskData: Partial<ITaskTabProps>;
  onComplete?: () => void;
}

const AddBatch = (props: VerifyPropType) => {
  const { taskData } = props;
  const { alertSuccessFaint, illustrationEdit } = Images;
  const navigate = useNavigate();
  const { appDispatch } = useContext(AppContext);
  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {taskData?.is_completed ? (
                  <IconNode src={alertSuccessFaint} className={css.successIcon} />
                ) : (
                  <Avatar src={illustrationEdit} alt="edit-icon" />
                )}

                <Link to={`/order/${taskData?.order}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="h5" className={css.underlineOnHover}>
                    #{taskData.readable_task_id} - Add Batch Number
                  </Typography>
                </Link>
              </div>
              <div className={css.pocWrapper}>
                {!taskData?.is_completed && (
                  <Button
                    variant="text"
                    className={css.addBtn}
                    onClick={() => {
                      appDispatch({
                        type: 'setPackageData',
                        payload: {
                          task_id: taskData?.task_id,
                          order_id: taskData?.order,
                          supplier_id: taskData?.supplier_id
                        }
                      });
                      navigate(
                        `/${CLIENT_ROUTES.order}/add-batch-number/${props.taskData?.order}/`
                      );
                    }}>
                    Add
                  </Button>
                )}
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        <Footer
          createdDate={taskData.created_at as Date}
          updatedDate={taskData.updated_at as Date}
        />
      </div>
    </>
  );
};

export default AddBatch;
