import Images from '@assets/images';
import { Typography, Image } from '@components/base';
import { IAddress } from '@helpers/types/address';
import { memo, useMemo } from 'react';
import css from './index.module.scss';

interface DetailCardProps {
  supplierName: string;
  phoneNumber: string;
  landlineNumber: string;
  location: IAddress[];
}

const SupplierDetailCard = (props: DetailCardProps) => {
  const { supplierName, phoneNumber, landlineNumber, location } = props;

  const nameInitial = useMemo(() => {
    const name = supplierName.charAt(0).toUpperCase();
    return name;
  }, [props.supplierName]);

  const address = useMemo(() => {
    const officeLocation = location.find((item) => item.address_type === 'OFC');
    if (officeLocation) return officeLocation.readable_address;
    return 'Unknown location';
  }, [props.location]);

  return (
    <div className={css.cardWrapper}>
      <div className={css.cardInfoWrapper}>
        <div className={css.cardProfileWrapper}>{nameInitial}</div>
        <Typography variant="h2">{supplierName}</Typography>
      </div>
      <div className={css.cardDetailWrapper}>
        <Typography variant="h5">Contact Details</Typography>
        <div className={css.cardContactWrapper}>
          <div className={css.contactWrapper}>
            <Image src={Images.telephone} alt="telephone icon" />
            <a
              href={`tel:${phoneNumber}`}
              className={css.linkLabel}
              target="_blank"
              rel="noopener noreferrer">
              {phoneNumber}
            </a>
            |
            <a
              href={`tel:${landlineNumber}`}
              className={css.linkLabel}
              target="_blank"
              rel="noopener noreferrer">
              {landlineNumber}
            </a>
          </div>
          <div className={css.contactWrapper}>
            <Image src={Images.locationPin} alt="location-pin icon" />
            <a
              href={`http://maps.google.com/?q=${address}`}
              className={css.linkLabel}
              target="_blank"
              rel="noopener noreferrer">
              {address}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SupplierDetailCard);
