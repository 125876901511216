import { Typography, Modal, IconNode, Button } from '@components/base';
import { TextField } from '@components/common';
import { useFormContext, Controller } from 'react-hook-form';
import Images from '@assets/images';
import { updateOrderInfo } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';

import css from './index.module.scss';

interface CancelOrderProps {
  open: boolean;
  onClose: () => void;
  orderId: string;
}

const CancelOrder = (props: CancelOrderProps) => {
  const { open, onClose, orderId } = props;
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext();

  const navigate = useNavigate();

  const onFormSubmit = async (data: any) => {
    const body = {
      is_cancelled: true,
      cancel_remarks: data.cancel_remarks ?? ''
    };
    const response = await updateOrderInfo(orderId, body);
    if (response.success) {
      notify({
        message: 'Order Cancelled Successfully'
      });
      onClose();
      navigate(`/${CLIENT_ROUTES.order}/${orderId}`, { replace: true });
    } else {
      notify({ message: response.error ?? 'Something went wrong!', severity: 'error' });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Cancel Order</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <section className={css.mesageWrapper}>
          <Typography variant="h5">Are you sure you want to cancel this order?</Typography>
        </section>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
          <section className={css.modalContent}>
            <Controller
              name="cancel_remarks"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  autoFocus={true}
                  label="Remarks"
                  rootClassName={css.typeField}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </section>
          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={onClose} disabled={isSubmitting}>
              No
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Yes
            </Button>
          </section>
        </form>
      </div>
    </Modal>
  );
};

export default CancelOrder;
