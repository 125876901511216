import { useContext, useState, useEffect, useCallback, useRef } from 'react';
import css from './index.module.scss';
import { Loader, Typography } from '@components/base';
import { SideBar } from '@components/common';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AppContext } from '@helpers/hooks/AppContext';
import { fetchOrderInfo, getOrderItemDetails } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { yupResolver } from '@hookform/resolvers/yup';
import { CLIENT_ROUTES } from '@router/routes';
import {
  addBatchNumber,
  generatePostShipmentPackingList,
  generatePreShipmentPackingList
} from '@services/task.service';
import { useNavigate } from 'react-router-dom';
import OrderDetails from './components/order-details';
import ItemDetails from './components/item-details';
import Preview from './components/preview';
import { packingListSchema } from '@helpers/yup/order-tasks.schema';
import { useBeforeUnloadAndNavigate, useScrollToTop } from '@helpers/hooks';
import { getFormattedDate } from '@helpers/utils';
import moment from 'moment';

const GeneratePackingList = () => {
  const [formState, setFormState] = useState<any>({
    activeStep: 0,
    isLoading: false,
    orderDetails: null,
    itemDetails: null,
    disableForm: false
  });
  useBeforeUnloadAndNavigate();

  const { activeStep, isLoading, itemDetails, orderDetails, disableForm } = formState;
  const topRef = useRef(null);
  const navigate = useNavigate();

  const { appState } = useContext(AppContext);
  const { PIData } = appState;

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const itemDetailForm = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(packingListSchema),
    defaultValues: {
      items: [
        {
          name: null,
          order_item_id: null,
          handling_and_storage: '',
          packaging_document_data: [
            {
              package_details: null,
              batch_number: null,
              start_package_no: null,
              end_package_no: null,
              tare_wt: null,
              unit_of_tare_wt: null,
              gross_wt: null,
              no_of_packages: null,
              net_wt: null
            }
          ]
        }
      ],
      handling_and_storage: '',
      total_net_wt: null,
      total_gross_wt: null,
      total_pallets_packed: null,
      total_no_of_packages: null,
      po_reference_no: null,
      po_reference_date: null
    }
  });

  const fetchOrderDetails = async () => {
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await fetchOrderInfo(PIData?.order_id);
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: false
    }));
    if (response?.success) {
      setFormState((prevState: any) => ({
        ...prevState,
        orderDetails: response?.data
      }));

      const poRefNumber = response?.data?.order_pi?.po_reference_no;
      const poRefDate = response?.data?.order_pi?.po_reference_date;

      if (PIData?.invoiceType == 'post_shipment') {
        const disableForm = response?.data?.order_item?.some(
          (item: any) => item?.packaging_document_data?.length
        );
        setFormState((prevState: any) => ({
          ...prevState,
          disableForm: disableForm
        }));
      }
      itemDetailForm.reset({
        items: response?.data?.order_item?.map((item: any) => {
          let packagingDocDataTemp = [
            {
              package_details: null,
              batch_number: null,
              start_package_no: null,
              end_package_no: null,
              tare_wt: null,
              unit_of_tare_wt: null,
              gross_wt: null,
              no_of_packages: null,
              net_wt: null
            }
          ];
          if (item?.packaging_document_data?.length) {
            packagingDocDataTemp = item?.packaging_document_data?.map((packaging: any) => {
              const calculatedNetWeight = parseFloat(
                (packaging?.no_of_package * packaging?.weight).toFixed(4)
              );
              return {
                package_details: {
                  id: packaging?.package,
                  package_display_name: packaging?.package_display_name,
                  weight: packaging?.weight,
                  unit_of_weight: packaging?.unit_of_tare_wt
                },
                batch_number: {
                  id: packaging?.batch,
                  batch_number: packaging?.batch_number,
                  manufacturing_date: packaging.manufacturing_date,
                  expiry_date: packaging.expiry_date
                },
                start_package_no: packaging?.start_package_no,
                end_package_no: packaging?.end_package_no,
                tare_wt: packaging?.tare_wt,
                unit_of_tare_wt: packaging?.unit_of_tare_wt,
                gross_wt: calculatedNetWeight + parseFloat(packaging?.tare_wt),
                no_of_packages: packaging?.no_of_package,
                net_wt: calculatedNetWeight
              };
            });
          }
          return {
            name: item?.product_name ?? '-',
            order_item_id: item?.order_item_id,
            handling_and_storage: item?.handling_and_storage,
            packaging_document_data: packagingDocDataTemp,
            total_pallets_packed: item?.total_pallets_packed
          };
        }),
        po_reference_no: poRefNumber,
        po_reference_date: new Date(poRefDate)
      });
    } else {
      notify({
        severity: 'error',
        message: response?.message ?? 'Something went wrong'
      });
    }
  };

  const handleNavigation = (currentStep: number) => {
    setFormState((prevState: any) => ({
      ...prevState,
      activeStep: currentStep
    }));
  };

  const handleSidebarClick = (value: number) => {
    handleNavigation(value);
  };

  const handleSidebarEnterKey = (value: number) => {
    handleNavigation(value);
  };

  const handleBackClick = (currentStep: number) => () => {
    handleNavigation(currentStep - 1);
  };

  const handleCancelClick = useCallback(() => {
    navigate(`/${CLIENT_ROUTES.order}/${PIData?.order_id}`);
  }, []);

  const itemDetailFormSubmit = (data: any) => {
    const packages: any = {};
    const packingDocumentData: any = {};
    formState.orderDetails.order_item.forEach((item: any) => {
      item.package.forEach((packaging: any) => {
        const id = packaging.id;
        const noOfPackage = packaging.no_of_package;
        if (!packages[id]) {
          packages[id] = noOfPackage;
        } else {
          packages[id] += noOfPackage;
        }
      });
    });
    const packageFormState = Object.keys(packages).map((id) => ({
      id: parseInt(id),
      noOfPackage: packages[id]
    }));
    data.items.forEach((item: any) => {
      item.packaging_document_data.forEach((packaging: any) => {
        const id = packaging.package_details.id;
        const noOfPackage = packaging.no_of_packages;

        if (!packingDocumentData[id]) {
          packingDocumentData[id] = noOfPackage;
        } else {
          packingDocumentData[id] += noOfPackage;
        }
      });
    });
    const packingDocumentArray = Object.keys(packingDocumentData).map((id) => ({
      id: parseInt(id),
      noOfPackage: packingDocumentData[id]
    }));

    if (JSON.stringify(packageFormState) != JSON.stringify(packingDocumentArray)) {
      return;
    }

    setFormState((prevState: any) => ({
      ...prevState,
      itemDetails: data
    }));
    handleNavigation(2);
  };
  const batchFormSubmitHandler = async () => {
    const body = {
      order_items: itemDetails?.items?.map((item: any) => {
        return {
          order_item_id: item.order_item_id,
          handling_and_storage: item.handling_and_storage,
          packaging_document_data: item.packaging_document_data.map((packaging: any) => {
            return {
              package: packaging?.package_details?.id,
              batch: packaging?.batch_number?.id,
              no_of_package: packaging?.no_of_packages,
              start_package_no: packaging?.start_package_no,
              end_package_no: packaging?.end_package_no,
              tare_wt: packaging?.tare_wt,
              unit_of_tare_wt: packaging?.package_details?.unit_of_weight
            };
          })
        };
      }),
      handling_and_storage: itemDetails.handling_and_storage,
      total_pallets_packed: itemDetails.total_pallets_packed,
      po_reference_no: itemDetails.po_reference_no,
      po_reference_date: moment(itemDetails.po_reference_date).format('YYYY-MM-DD')
    };
    let response;
    if (PIData?.invoiceType == 'pre_shipment') {
      response = await generatePreShipmentPackingList(PIData.task_id, body);
    } else {
      response = await generatePostShipmentPackingList(PIData.task_id, body);
    }
    if (response?.success) {
      notify({
        severity: 'success',
        message: `Packing List Generated successfully`
      });
      navigate(`/${CLIENT_ROUTES.order}/${PIData.order_id}`, { replace: true });
    } else {
      notify({
        severity: 'error',
        message: response?.error ?? 'Something went wrong'
      });
    }
  };
  useScrollToTop({ topRef, dependencyArray: [activeStep] });
  return (
    <main className={css.formWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Generate Packing List</Typography>
        <Typography variant="subheading1">0{activeStep + 1} of 03</Typography>
      </div>
      <div className={css.formContainer} ref={topRef}>
        <div className={css.sideBarWrapper}>
          <SideBar
            activeStep={activeStep}
            onClick={handleSidebarClick}
            onEnter={handleSidebarEnterKey}>
            <SideBar.Item label="Consignee and Order Details" value={0} />
            <SideBar.Item label="Item Details" value={1} disabled={activeStep! <= 1} />
            <SideBar.Item label="Preview" value={2} disabled={activeStep! <= 2} />
          </SideBar>
        </div>
        {activeStep === 0 && (
          <OrderDetails
            data={orderDetails}
            onNext={() => handleNavigation(1)}
            onCancelClick={handleCancelClick}
          />
        )}
        {activeStep === 1 && (
          <FormProvider {...itemDetailForm}>
            <ItemDetails
              onFormSubmit={itemDetailFormSubmit}
              onCancelClick={handleCancelClick}
              onBackClick={handleBackClick}
              data={orderDetails?.order_item}
              disableForm={disableForm}
            />
          </FormProvider>
        )}
        {activeStep === 2 && (
          <Preview
            orderData={orderDetails}
            itemData={itemDetails}
            handleBackClick={handleNavigation}
            onSubmit={batchFormSubmitHandler}
            handleCancelClick={handleCancelClick}
            handleNavigation={handleNavigation}
          />
        )}
      </div>
      <Loader open={isLoading} />
    </main>
  );
};

export default GeneratePackingList;
