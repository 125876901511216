import { Typography, IconNode } from '@components/base';
import css from './index.module.scss';
import { CSSProperties } from 'react';
import Images from '@assets/images';

interface TaskOrderCardProps {
    src?: string;
    primaryTitle: string;
    secondaryTitle: string;
    showPrimaryIcon?: boolean;
    showInfoIcon?: boolean;
    style?: CSSProperties;
  }
const PODetails = (props: TaskOrderCardProps) => {
    const {
      src,
      primaryTitle,
      secondaryTitle,
      style,
      showPrimaryIcon = true,
      showInfoIcon = true
    } = props;
    return (
      <div className={css.orderCardContainer} style={style}>
        {showPrimaryIcon && (
          <IconNode src={src} alt={`${primaryTitle} icon`} className={css.profileIcon} />
        )}
        <div className={css.orderCardDetailColumn}>
          <div className={css.titleWrapper}>
            <Typography variant="span" className={css.primaryTitleLabel}>
              {primaryTitle}
            </Typography>
            {showInfoIcon && <IconNode src={Images.infoGrey} alt="info icon" />}
          </div>
          <Typography variant="body">{secondaryTitle}</Typography>
        </div>
      </div>
    );
  };

export default PODetails;