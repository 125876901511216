import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from '@helpers/constants';
import * as yup from 'yup';

export const ebrcDetailsConfirmation = yup.object().shape({
  remarks: yup.string().trim().notRequired(),
  ebrc_number: yup.string().trim().required('EBRC Number is required'),
  ebrc_date: yup.date().required('EBRC Date is required'),
  document: yup
    .mixed()
    .required('EBRC Document is required')
    .test('fileSize', 'File Size is too large', (value: any) =>
      value ? value?.size <= MAX_FILE_SIZE : true
    )
    .test('fileType', 'Unsupported File Format', (value: any) =>
      value ? SUPPORTED_FORMATS.includes(value?.type) : true
    )
});
