import { Typography, Modal, IconNode } from '@components/base';
import Images from '@assets/images';
import css from './index.module.scss';
import { Box, Step, StepIcon, StepLabel, Stepper, StepIconProps } from '@mui/material';

interface ProgressBarModalProps {
  open: boolean;
  onClose: () => void;
  data?: any;
  currentStep?: number;
}

interface CustomStepIconProps extends StepIconProps {
  active?: boolean;
  index: number;
}

const CustomStepIcon = (props: CustomStepIconProps) => {
  const { active } = props;
  if (active) {
    return <img src={Images.progressBarGIF} alt="Current State GIF" className={css.progressBarStep} />;
  }

  return <StepIcon {...props} />;
};

const stepStyle = {
  '.Mui-completed': {
    '.MuiSvgIcon-root': {
      color: '#009999'
    },
    '.MuiStepConnector-line': {
      borderColor: '#009999'
    }
  },
  '.MuiSvgIcon-root.Mui-active': {
    color: '#028783',
    fontSize: 35,
    position: 'relative',
    bottom: 7
  },
  '.MuiStepIcon-text': {
    display: 'none'
  }
};

const ProgressBarModal = (props: ProgressBarModalProps) => {
  const { open, onClose, data, currentStep } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Order Progress</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <div className={css.formWrapper}>
          <div className={css.modalContent}>
            <>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={currentStep} orientation="vertical" sx={stepStyle}>
                  {data.map((item: any, index: number) => (
                    <Step key={index}>
                      <StepLabel
                        StepIconComponent={(props) => <CustomStepIcon {...props} index={index} />}>
                        <Typography
                          variant="pdoc"
                          className={
                            currentStep == index
                              ? `${css.currentState} ${
                                  index == 0 || index == data?.length - 1 ? css.primaryText : ''
                                }`
                              : index == 0 || index == data?.length - 1
                              ? css.primaryText
                              : ''
                          }>
                          {item?.label}
                        </Typography>
                        <Typography
                          variant="p"
                          className={currentStep == index ? css.currentState : css.secondaryText}>
                          {item?.date}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProgressBarModal;
