import { IconNode, Image, ToolTip, Typography } from '@components/base';
import { PeopleType, USER_TYPES } from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import { IActivity, IActivityStates } from '@helpers/types/activity';
import activityLogs from '@services/activity.service';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import css from './index.module.scss';
import Images from '@assets/images';
import { getFormattedDate, getFormattedTime } from '@helpers/utils';
import { ListPlaceholder } from '@components/common';
import Skeleton from '@components/base/skeleton';
import { Card, CardHeader } from '@mui/material';

const ActivityCard = () => {
  const params = useParams();
  const [activityState, setActivityState] = useState<IActivityStates>({
    activityList: [],
    isLoading: false
  });

  const { sales2 } = Images;

  const { activityList, isLoading } = activityState;

  useEffect(() => {
    (async () => {
      setActivityState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
      const response = await activityLogs(params.id as string);
      if (response.success) {
        // const { data: peopleInfo } = response;
        setActivityState((prevState) => ({
          ...prevState,
          isLoading: !prevState.isLoading,
          activityList: response?.data ?? []
        }));
      } else {
        setActivityState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
        notify({
          message: response.error ?? 'Unable to fetch data',
          severity: 'error',
          dismissible: true
        });
      }
    })();
  }, []);

  if (isLoading) return <OrderProgressSkeleton />;

  return (
    <>
      <section className={css.wrapper}>
        <div className={css.mainWrapper}>
          {activityList?.map((activity: IActivity) => (
            <div key={activity?.timestamp} className={css.cardWrapper}>
              <div className={css.card}>
                <div>
                  <div className={css.cardTitle}>
                    <Typography variant="h5">{activity?.activity_log}</Typography>
                  </div>
                  <Typography variant="label" className={css.cardContent}>
                    {getFormattedDate(activity?.timestamp)} at{' '}
                    {getFormattedTime(activity?.timestamp)} by{' '}
                    <Typography variant="label" className={css.userName}>
                      {activity?.full_name ?? 'Elchemy Internal Member'}
                    </Typography>
                  </Typography>
                </div>

                <Image
                  src={activity?.profile_image ?? ''}
                  alt={`${activity?.full_name ?? 'Elchemy Internal Member'} icon`}
                  className={css.profileIcon}
                  fallbackText={`${
                    activity?.full_name?.toLocaleUpperCase() ?? 'Elchemy Internal Member'
                  }`}
                />
              </div>
            </div>
          ))}
        </div>
      </section>

      {activityList.length == 0 && !isLoading && (
        <ListPlaceholder
          to={``}
          title="No Activity performed on this order yet"
          supportingText="When any activity is performed on this order, it will show up here."
          buttonText=""
        />
      )}
    </>
  );
};

export default ActivityCard;

const OrderProgressSkeleton = () => {
  return (
    <div className={css.Skeletonwrapper}>
      <OrderProgressSkeletonCard />
      <OrderProgressSkeletonCard />
      <OrderProgressSkeletonCard />
      <OrderProgressSkeletonCard />
      <OrderProgressSkeletonCard />
    </div>
  );
};

const OrderProgressSkeletonCard = () => {
  return (
    <Card>
      <CardHeader
        sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        title={<Skeleton animation="wave" height={20} width="60%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation="wave" height={20} width="30%" />}
      />
    </Card>
  );
};
