import { Button, IconNode, Modal, Typography } from '@components/base';
import css from './index.module.scss';
import Images from '@assets/images';
import { getFormattedDate, getFormattedTime } from '@helpers/utils';
import { EmailStatus } from '@helpers/constants';

const EmailModal = ({ email, onClose, open }: any) => {
  const formatEmailArray = (emails: Array<string> | undefined) => emails?.join(', ') || 'None';

  const statusNumber: number = email.status;
  const status = EmailStatus[statusNumber as keyof typeof EmailStatus];

  return (
    <Modal open={open}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Email Details</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="div"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <div className={css.formWrapper}>
          <div className={css.fieldsWrapper}>
            {email?.email_from && (
              <div className={css.rowWrapper}>
                <Typography variant="label">From:</Typography>
                <Typography>{email?.email_from}</Typography>
              </div>
            )}
            {email?.email_to && (
              <div className={css.rowWrapper}>
                <Typography variant="label">To:</Typography>
                <Typography>{formatEmailArray(email?.email_to)}</Typography>
              </div>
            )}
            {email?.email_cc && (
              <div className={css.rowWrapper}>
                <Typography variant="label">CC:</Typography>
                <Typography>{formatEmailArray(email?.email_cc)}</Typography>
              </div>
            )}
            {email?.email_bcc && (
              <div className={css.rowWrapper}>
                <Typography variant="label">BCC:</Typography>
                <Typography>{formatEmailArray(email?.email_bcc)}</Typography>
              </div>
            )}
            {email?.email_reply_to && (
              <div className={css.rowWrapper}>
                <Typography variant="label">Reply-To:</Typography>
                <Typography>{email?.email_reply_to}</Typography>
              </div>
            )}
            {email?.email_subject && (
              <div className={css.rowWrapper}>
                <Typography variant="label">Subject:</Typography>

                <Typography>{email?.email_subject}</Typography>
              </div>
            )}
            {/* {email?.status && (
              <div className={css.rowWrapper}>
                <Typography variant="label">Status:</Typography>
                <Typography>{status}</Typography>
              </div>
            )} */}
            {email?.sent_on && (
              <div className={css.rowWrapper}>
                <Typography variant="label">Sent On:</Typography>
                <Typography>
                  {getFormattedDate(email?.sent_on)} - {getFormattedTime(email?.sent_on)}
                </Typography>
              </div>
            )}
            {email?.decline_remarks && (
              <div className={css.rowWrapper}>
                <Typography variant="label">Decline Remarks:</Typography>
                <Typography>{email?.decline_remarks}</Typography>
              </div>
            )}
            {email?.template_display_name && (
              <div className={css.rowWrapper}>
                <Typography variant="label">Template:</Typography>
                <Typography>{email?.template_display_name}</Typography>
              </div>
            )}
            {email?.params && (
              <div
                className={css.emailBody}
                dangerouslySetInnerHTML={{ __html: email?.params.HTML }}
              />
            )}
            {email?.body && (
              <div className={css.emailBody} dangerouslySetInnerHTML={{ __html: email?.body }} />
            )}
            <div className={css.attachmentWrapper}>
              {email?.attachments &&
                email?.attachments.map((attachmentUrl: any, index: number) => (
                  <div key={index} className={css.attachmentButtonWrapper}>
                    <Button
                      onClick={() =>
                        window.open(
                          attachmentUrl.file_temporary_url,
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }
                      variant="outlined"
                      className={css.downloadPdf}>
                      {attachmentUrl.display_name}
                    </Button>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmailModal;
