import Images from '@assets/images';
import { Button, CheckboxLabel, Divider, IconNode, Typography } from '@components/base';
import {
  BoxContainer,
  DataGrid,
  DocumentRow,
  SelectLabel,
  TextAreaLabel,
  TextField
} from '@components/common';
import { IItemDetail, IItems } from '@helpers/types/pi';
import { memo, useMemo, useEffect, useRef } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { CellProps, Column } from 'react-table';
import { DEFAULT_AMOUNT_ROUND_OFF, booleanSelectFields } from '@helpers/constants';
import css from './index.module.scss';
import { generateReadablePackingDetails } from '@helpers/utils';

interface ItemDetailContainerProps {
  itemsList: IItems[];
  totalAmount: string;
  customItemList: IItemDetail[];
  currency?: string;
  unit_of_weight?: string;
}

interface DocumentGrid {
  name: string;
  proof: string;
}

interface AdhocOrderDetailFormProps {
  currency?: string;
  unitOfWeight?: string;
}

const ItemDetailContainer = (props: ItemDetailContainerProps) => {
  const { control } = useFormContext();
  const { itemsList, customItemList, currency, unit_of_weight } = props;
  const { fields, append, remove } = useFieldArray({
    name: 'custom_items',
    control
  });

  const watch = useWatch({
    name: 'custom_items',
    control
  });

  useEffect(() => {
    customItemList.forEach((customItem: any) => {
      const isEixisting = fields.find((item: any) => customItem.custom_id === item.custom_id);
      if (!isEixisting) {
        append(customItem);
      }
    });
  }, []);

  const totalAmount = itemsList.reduce(
    (previousValue: number, item: any) =>
      Number(item.quantity_mt) * Number(item.rate_mt_usd) + previousValue,
    0
  );

  const total_amount = watch.reduce(
    (previousValue: number, item: IItemDetail) =>
      Number(item.custom_item_quantity) * Number(item.custom_item_rate) + previousValue,
    0
  );

  const display_total_amount = Number(totalAmount) + total_amount;

  const handleAddField = () => {
    append({
      custom_item_name: '',
      custom_item_description: '',
      custom_item_rate: '',
      custom_item_quantity: ''
    });
  };

  const productItemLength = itemsList.length;
  return (
    <BoxContainer title="Item Details" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <AdhocOrderDetailForm currency={currency} unitOfWeight={unit_of_weight} />
      </div>
      <div className={css.boxWrapper}>
        <div className={css.itemFieldWrapper}>
          {itemsList?.map((item, index) => {
            return (
              <ItemDetailFields
                key={index}
                index={index}
                items={item}
                rate_ci={`order_items.${index}.rate_ci`}
                quantity_ci={`order_items.${index}.quantity_ci`}
                order_item_id={`order_items.${index}.order_item_id`}
                order_item_description_ci={`order_items.${index}.order_item_description_ci`}
                currency={currency}
                unitOfWeight={unit_of_weight}
                include_grade_name_in_docs={`order_items.${index}.include_grade_name_in_docs`}
              />
            );
          })}
          {fields.map((item, index) => {
            const handleDeleteClick = () => remove(index);
            return (
              <CustomItemsField
                key={item.id}
                index={index}
                custom_item_name={`custom_items.${index}.custom_item_name`}
                custom_item_amount={`custom_items.${index}.custom_item_amount`}
                custom_item_description={`custom_items.${index}.custom_item_description`}
                custom_item_quantity={`custom_items.${index}.custom_item_quantity`}
                custom_item_rate={`custom_items.${index}.custom_item_rate`}
                onDeleteClick={handleDeleteClick}
                showDelete
                productItemLength={productItemLength}
              />
            );
          })}
          <Button
            variant="text"
            onClick={handleAddField}
            startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
            Add Custom Item
          </Button>
        </div>
        {/* <Divider className={css.dividerWrapper} /> */}
        {/* <TextField
          disabled
          value={display_total_amount ?? ''}
          required
          label="Total Amount"
          placeholder="Total amount"
        /> */}
      </div>
    </BoxContainer>
  );
};

interface ItemDetailFieldsProps {
  index: number;
  items: IItems;
  rate_ci: string;
  quantity_ci: string;
  order_item_id: string;
  order_item_description_ci: string;
  unitOfWeight?: string;
  currency?: string;
  include_grade_name_in_docs: string;
}

const ItemDetailFields = (props: ItemDetailFieldsProps) => {
  const {
    items,
    index,
    rate_ci,
    quantity_ci,
    order_item_id,
    order_item_description_ci,
    currency,
    unitOfWeight,
    include_grade_name_in_docs
  } = props;
  const { control, setValue, watch } = useFormContext();

  useEffect(() => {
    setValue(order_item_id, items.order_item_id);
    setValue(quantity_ci, items.quantity_ci ? items.quantity_ci : items.quantity_pi);
    setValue(rate_ci, items.rate_ci ? items.rate_ci : items.rate_pi);
    setValue(
      order_item_description_ci,
      items.order_item_description_ci?.length
        ? items.order_item_description_ci
        : items.order_item_description_pi?.length
        ? items.order_item_description_pi
        : items.order_item_description
    );
    setValue(
      include_grade_name_in_docs,
      items.include_grade_name_in_docs ? items.include_grade_name_in_docs : booleanSelectFields[0]
    );
  }, []);

  const totalAmount = useMemo(() => {
    return watch(quantity_ci) * watch(rate_ci);
  }, [watch(quantity_ci), watch(rate_ci)]);

  const [documentColumn, documentData] = useMemo(() => {
    const { order_item_document: ItemsDoc } = items;
    const column: Column<DocumentGrid>[] = [
      {
        Header: 'Document Name',
        accessor: 'name',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return (
            <DocumentRow.Title
              title={value?.document_type ?? ''}
              documentAvailable={value?.document_type ?? false}
            />
          );
        }
      },
      {
        Header: 'Proofs',
        accessor: 'proof',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return <DocumentRow.View document={value?.document_object ?? ''} title="View" />;
        }
      }
    ];
    const row: DocumentGrid[] = ItemsDoc?.map((items: any) => ({
      name: items,
      proof: items
    }));
    return [column, row];
  }, [props.items]);

  return (
    <div className={css.itemMainWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="h4">Item {index + 1}</Typography>
      </div>

      <SelectLabel
        required
        label="Product"
        placeholder="Select item"
        isDisabled
        value={{
          label: items.product_name,
          value: items.product_name
        }}
      />
      <div className={css.rowWrapper}>
        <TextField
          disabled
          value={items.product_grade_name ?? '-'}
          label="Grade"
          placeholder="Enter grade"
        />
        <Controller
          name={include_grade_name_in_docs}
          control={control}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              required
              isSearchable
              label="Show grade on document"
              placeholder="Select option"
              options={booleanSelectFields}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </div>

      <div className={css.rowWrapper}>
        {/* <TextField
          inputMode="decimal"
          enterKeyHint="next"
          autoComplete="off"
          label="Quantity in MT"
          placeholder="Enter rate"
          value={items.quantity_mt ?? ''}
          disabled
        /> */}
        <Controller
          name={quantity_ci}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              disabled
              inputMode="decimal"
              enterKeyHint="next"
              autoComplete="off"
              label={`Quantity in ${unitOfWeight}`}
              placeholder="Enter rate"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        {/* <TextField
          required
          inputMode="decimal"
          enterKeyHint="next"
          autoComplete="off"
          label="Rate per MT (USD)"
          placeholder="Enter amount"
          value={items.rate_mt_usd ?? ''}
          disabled
        /> */}
        <Controller
          name={rate_ci}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              inputMode="decimal"
              enterKeyHint="next"
              autoComplete="off"
              label={`Rate per ${unitOfWeight} (${currency})`}
              placeholder="Enter amount"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        <TextField
          required
          disabled
          value={totalAmount ? totalAmount.toFixed(DEFAULT_AMOUNT_ROUND_OFF) : '-'}
          label={`Amount (${currency})`}
          placeholder="Enter amount"
        />
      </div>
      <div className={css.rowWrapper}>
        <TextField
          required
          disabled
          value={items?.package ? generateReadablePackingDetails(items?.package).join(', ') : '-'}
          label="Packaging details"
          placeholder="Enter"
        />
      </div>
      <div className={css.textfield}>
        <Controller
          name={order_item_description_ci}
          control={control}
          render={({ field, fieldState }) => (
            <TextAreaLabel
              {...field}
              label="Item Description"
              placeholder="Enter description"
              rows={5}
              rootClassName={css.fieldSpacing}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
      </div>
      {items.order_item_document.length > 0 && (
        <>
          <Typography variant="p">Item Documents</Typography>
          <DataGrid columns={documentColumn} data={documentData} />
        </>
      )}
    </div>
  );
};

interface ICustomField {
  index: number;
  showDelete?: boolean;
  onDeleteClick?: () => void;
  custom_item_name: string;
  custom_item_amount: string;
  custom_item_description: string;
  productItemLength: number;
  custom_item_quantity: string;
  custom_item_rate: string;
}

const CustomItemsField = (props: ICustomField) => {
  const {
    custom_item_description,
    custom_item_quantity,
    custom_item_rate,
    index,
    custom_item_name,
    onDeleteClick,
    showDelete,
    custom_item_amount,
    productItemLength
  } = props;

  const { control } = useFormContext();
  const watchField = useWatch({
    name: [custom_item_quantity, custom_item_rate],
    control
  });

  return (
    <div className={css.itemMainWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="h4">Item {productItemLength + index + 1}</Typography>
        {showDelete && (
          <Button
            variant="text"
            title="Delete Item"
            onClick={onDeleteClick}
            startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}>
            Delete
          </Button>
        )}
      </div>
      <Controller
        name={custom_item_name}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Custom item"
            placeholder="Enter item name"
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
          />
        )}
      />
      <div className={css.rowWrapper}>
        <Controller
          name={custom_item_quantity}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              inputMode="decimal"
              enterKeyHint="next"
              autoComplete="off"
              type="text"
              label="Quantity"
              placeholder="Enter quantity"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        <Controller
          name={custom_item_rate}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              inputMode="decimal"
              enterKeyHint="next"
              autoComplete="off"
              type="text"
              label="Rate"
              placeholder="Enter rate"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        <Controller
          name={custom_item_amount}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              inputMode="decimal"
              enterKeyHint="next"
              autoComplete="off"
              label="Amount"
              placeholder="Enter amount"
            />
          )}
        />
      </div>
      <Controller
        name={custom_item_description}
        control={control}
        render={({ field, fieldState }) => (
          <TextAreaLabel
            {...field}
            label="Item Description"
            placeholder="Enter description"
            rows={5}
            rootClassName={css.fieldSpacing}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
          />
        )}
      />
      <Divider className={css.divider} />
    </div>
  );
};

const AdhocOrderDetailForm = (props: AdhocOrderDetailFormProps) => {
  const { currency, unitOfWeight } = props;
  const { control } = useFormContext();
  return (
    <div className={css.itemMainWrapper}>
      <div className={css.rowWrapper}>
        <TextField disabled value={currency} label="Currency" />
        <TextField disabled value={unitOfWeight} label="Unit of Weight" />
      </div>
      <Controller
        name="include_advance_payment"
        control={control}
        render={({ field }) => (
          <CheckboxLabel {...field} label="Show advance amount in document" checked={field.value} />
        )}
      />
    </div>
  );
};

export default memo(ItemDetailContainer);
