import css from './index.module.scss';
import { Typography, Image } from '@components/base';
import { LinkButton, Seo } from '@components/common';
import { CLIENT_ROUTES } from '@router/routes';
import Images from '@assets/images';
import ForgotPasswordForm from './components/forgot-password-form';

const ForgotPassword = () => {
  return (
    <main className={css.forgotPasswordWrapper}>
      <Seo title="Forgot password" />
      <LinkButton to={`${CLIENT_ROUTES.login}`} className={css.backButton} replace>
        <Image src={Images.backArrow} alt="Back button" />
        <Typography variant="p">Back to Login</Typography>
      </LinkButton>
      <ForgotPasswordForm />
    </main>
  );
};

export default ForgotPassword;
