import { Skeleton, Typography } from '@components/base';
import css from './index.module.scss';
import { Party } from '../../../orderOverview';

interface PartiesProps {
  partiesData: {
    parties: Party;
    consigneeSameAsCustomer: boolean;
  };
  isLoading?: boolean;
}

const Parties = (props: PartiesProps) => {
  const { partiesData, isLoading = false } = props;
  const { parties, consigneeSameAsCustomer } = partiesData;
  return (
    <div className={css.partiesWrapper}>
      <div className={css.partiesContainer}>
        <div className={css.parties}>
          <div className={css.fieldTitle}>
            <Typography variant="body">Exporter</Typography>
          </div>
          {isLoading ? (
            <PartiesSkeleton />
          ) : (
            <div className={css.wrapper}>
              <Typography variant="h3">{parties.exporterInfo.name}</Typography>
              <Typography variant="body">{parties.exporterInfo.address}</Typography>
              <Typography variant="body">GST - {parties.exporterInfo.gstNumber}</Typography>
              <Typography variant="body">
                {parties.exporterInfo.phoneNumber && `Tel - ${parties.exporterInfo.phoneNumber}`}
              </Typography>
              <Typography variant="body">
                {parties.exporterInfo.emailId && `Email -${parties.exporterInfo.emailId}`}
              </Typography>
            </div>
          )}
        </div>
        <div className={css.parties}>
          <div className={css.fieldTitle}>
            <Typography variant="body">Customer</Typography>
          </div>
          {isLoading ? (
            <PartiesSkeleton />
          ) : (
            <div className={css.wrapper}>
              <Typography variant="h3">{parties.customerInfo.name}</Typography>
              <Typography variant="body">
                {!consigneeSameAsCustomer
                  ? parties.customerInfo.address
                  : parties.consigneeInfo.address}
              </Typography>
              <Typography variant="body">
                {parties.customerInfo.customerPOC?.phoneNumber &&
                  `Tel: ${parties.customerInfo.customerPOC?.phoneNumber}`}
              </Typography>
              <Typography variant="body">
                {parties.customerInfo.customerPOC?.emailId &&
                  `Email: ${parties.customerInfo.customerPOC?.emailId}`}
              </Typography>
            </div>
          )}
        </div>

        <div className={css.parties}>
          <div className={css.fieldTitle}>
            <Typography variant="body">Consignee</Typography>
          </div>
          {isLoading ? (
            <PartiesSkeleton />
          ) : (
            <div className={css.wrapper}>
              <Typography variant="h3">{parties.consigneeInfo.name}</Typography>
              <Typography variant="body">{parties.consigneeInfo.address}</Typography>
              <Typography variant="body">
                {parties.consigneeInfo.phoneNumber && `Tel: ${parties.consigneeInfo.phoneNumber}`}
              </Typography>
              <Typography variant="body">
                {parties.consigneeInfo.emailId && `Email: ${parties.consigneeInfo.emailId}`}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Parties;

const PartiesSkeleton = () => {
  return (
    <div className={css.skeletonWrapper}>
      <Skeleton variant="text" width={300} height={20} />
      <Skeleton variant="text" width={300} height={20} />
      <Skeleton variant="text" width={300} height={20} />
      <Skeleton variant="text" width={300} height={20} />
    </div>
  );
};
