import Images from '@assets/images';
import { Accordion, Button, Divider, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { countries, getLabelFromValue } from '@helpers/constants';
import { AppContext } from '@helpers/hooks/AppContext';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import moment from 'moment';
import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import css from './index.module.scss';
import UserCard from './user-card';
import { getFormattedDate } from '@helpers/utils';
import { DOCUMENT_GENERATION_STATUS } from '@helpers/constants';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

interface RectifyPIProps {
  taskData: Array<Partial<ITaskTabProps>>;
}

const RectifyPICard = (props: RectifyPIProps) => {
  const { appDispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useParams();
  const { taskData } = props;
  const taskItem = taskData[0];
  const dateString = getFormattedDate(taskItem.date_of_order);

  const docGenerationStatus = useMemo(() => {
    if (taskItem?.proforma_invoice_generation_status as any) {
      return DOCUMENT_GENERATION_STATUS.find(
        (i) => i.value === taskItem?.proforma_invoice_generation_status
      )?.label;
    }
    return null;
  }, [taskItem]);

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {/* {!taskItem?.is_completed ? (
                <Avatar src={Images.illustrationEdit} alt="" />
              ) : (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              )} */}

              <Link to={`/order/${taskItem?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  {`#${taskItem?.readable_task_id}`} - Rectify Proforma Invoice{' '}
                </Typography>
              </Link>
            </div>
            <div className={css.pocWrapper}>
              {!taskItem?.is_completed &&
                (!docGenerationStatus || docGenerationStatus == 'DOC_GENERATION_SUCCESS') && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      appDispatch({
                        type: 'setRectifyData',
                        payload: {
                          isRectify: true,
                          task_id: taskItem?.task_id,
                          order_id: taskItem?.order
                        }
                      });
                      navigate(
                        `/order/rectify-performa-invoice/${
                          (params?.id as string) ?? (taskItem?.order as string)
                        }`
                      );
                    }}
                    className={css.rectifyButton}>
                    Rectify PI
                  </Button>
                )}
              {!taskItem?.is_completed &&
                (docGenerationStatus == 'DOC_GENERATION_PROCESSING' ||
                  docGenerationStatus == 'DOC_GENERATION_REQUESTED') && (
                  <Button variant="text" disabled className={css.assignButton}>
                    Document generation in process
                  </Button>
                )}
              {!taskItem?.is_completed && docGenerationStatus == 'DOC_GENERATION_FAILED' && (
                <Button variant="text" disabled className={css.assignButton}>
                  Document Generation Failed
                </Button>
              )}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordionContent}>
          <div className={css.orderCardFirstWrapper}>
            <UserCard
              primaryTitle="Customer"
              secondaryTitle={taskItem?.customer_name as string}
              showPrimaryIcon={false}
              showInfoIcon={false}
            />
            <UserCard
              primaryTitle="Product"
              secondaryTitle={taskItem?.product_names?.[0] as string}
              showPrimaryIcon={false}
              showInfoIcon={false}
            />
            <UserCard
              primaryTitle="Date of Order"
              secondaryTitle={`${dateString as string}`}
              showPrimaryIcon={false}
              showInfoIcon={false}
            />
            <UserCard
              primaryTitle="Country"
              secondaryTitle={getLabelFromValue(taskItem?.country as string, countries)}
              showPrimaryIcon={false}
              showInfoIcon={false}
            />
          </div>
        </Accordion.Content>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskItem?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskItem?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskItem?.updated_at)?.diff(taskItem.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};
export default RectifyPICard;
