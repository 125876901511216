/* eslint-disable @typescript-eslint/no-var-requires */
import Images from '@assets/images';
import { Button, IconNode, Loader, Modal, Typography } from '@components/base';
import {
  DragDropUpload,
  InputDatePicker,
  SelectLabel,
  TextAreaLabel,
  TextField
} from '@components/common';
import { PAYMENT_TYPE } from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import { ISelect } from '@helpers/types';
import { paymentDetailSchema } from '@helpers/yup/add-payment.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { addOrderPaymentDetail } from '@services/order.service';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import css from './index.module.scss';
const moment = require('moment');

interface FormType {
  amount_paid: string;
  payment_date: Date | null;
  // payment_mode: string;
  payment_type: ISelect | null;
  remarks: '';
  payment_proof: File | null;
  additional_charges: string;
  export_bill_bank_reference_no: string;
}
interface AddPaymentDetailsProps {
  open: boolean;
  onClose?: () => void;
  sendStatusToCard: (arg: boolean) => void;
  taskId: string;
  onComplete?: () => void;
  dueAmount: number;
  totalAmount: number;
  currency: string;
  exportBillBankReferenceNumber: string;
}

const AddPaymentDetails = (props: AddPaymentDetailsProps) => {
  const {
    open,
    onClose,
    sendStatusToCard,
    onComplete,
    dueAmount,
    totalAmount,
    currency,
    exportBillBankReferenceNumber
  } = props;
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting }
  } = useForm<FormType>({
    resolver: yupResolver(paymentDetailSchema),
    defaultValues: {
      amount_paid: '',
      payment_date: null,
      // payment_mode: '',
      payment_type: null,
      remarks: '',
      payment_proof: null,
      additional_charges: '',
      export_bill_bank_reference_no: ''
    }
  });

  const watch = useWatch({ name: 'payment_proof', control });

  const watchFields = useWatch({
    control,
    name: ['amount_paid', 'additional_charges']
  });

  const handleFileSelect = (file: any[]) => {
    if (file.length) {
      setValue('payment_proof', file[0]);
    }
  };

  const handleDeleteFile = useCallback(() => {
    setValue('payment_proof', null);
  }, []);

  useEffect(() => {
    if (getValues('export_bill_bank_reference_no') === '') {
      setValue('export_bill_bank_reference_no', exportBillBankReferenceNumber);
    }
  }, [exportBillBankReferenceNumber]);

  const amountDue = useMemo(() => {
    const amountPaid = parseFloat(watchFields[0]) || 0;
    const additionalCharges = parseFloat(watchFields[1]) || 0;
    const initalDueAmount = dueAmount || 0;
    return initalDueAmount - (amountPaid + additionalCharges);
  }, [watchFields]);

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const { taskId, onClose } = props;
    const reqBody = new FormData();
    reqBody.append('amount_paid', data.amount_paid);
    reqBody.append('payment_date', moment(data.payment_date).format('YYYY-MM-DD'));
    reqBody.append('payment_type', data.payment_type!.value);
    // reqBody.append('payment_mode', data.payment_mode);
    reqBody.append('remarks', data.remarks);
    reqBody.append('additional_charges', data.additional_charges);
    reqBody.append('export_bill_bank_reference_no', data.export_bill_bank_reference_no);
    data.payment_proof && reqBody.append('payment_proof', data.payment_proof);
    const response = await addOrderPaymentDetail(taskId as string, reqBody);
    if (response.success) {
      notify({ message: 'Payment details updated successfully' });
      sendStatusToCard(true);
      onClose?.();
      if (onComplete) {
        onComplete();
      }
      if (onClose) {
        onClose();
      }
    } else {
      notify({ message: response.error ?? 'Failed to submit payment detail', severity: 'error' });
      onClose?.();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Add Payment Details</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={css.formWrapper}>
          <div className={css.fieldsWrapper}>
            <Controller
              name="amount_paid"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  autoFocus
                  required
                  inputMode="decimal"
                  enterKeyHint="next"
                  autoComplete="off"
                  label="Amount Paid"
                  placeholder="Enter amount"
                  rootClassName={css.fieldSpacing}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            <Controller
              name="additional_charges"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  inputMode="decimal"
                  enterKeyHint="next"
                  autoComplete="off"
                  label="Additional Charges"
                  placeholder="Enter amount"
                  rootClassName={css.fieldSpacing}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />

            <TextField
              label="Amount Due/Total Amount"
              placeholder="Amount"
              value={`${currency} ${amountDue}/${currency} ${totalAmount}`}
              disabled={true}
              rootClassName={css.fieldSpacing}
            />

            <Controller
              name="payment_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  label="Date of Payment"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />

            {/* <Controller
              name="payment_mode"
              control={control}
              render={({ field, fieldState }) => (
                // <SelectLabel
                //   {...field}
                //   required
                //   options={PAYMENT_TERMS}
                //   label="Payment Mode"
                //   error={fieldState.invalid}
                //   helperText={fieldState.error?.message}
                //   rootClassName={css.fieldSpacing}
                // />
                <TextField
                  {...field}
                  label="Payment Mode"
                  placeholder="Enter Payment Mode"
                  autoComplete="off"
                  enterKeyHint="next"
                  required
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            /> */}

            <Controller
              name="payment_type"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  required
                  options={PAYMENT_TYPE}
                  label="Payment Type"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />

            <Controller
              name="export_bill_bank_reference_no"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  inputMode="decimal"
                  enterKeyHint="next"
                  autoComplete="off"
                  label="Export Bill Bank Reference Number"
                  placeholder="Enter Number"
                  rootClassName={css.fieldSpacing}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />

            <label htmlFor="Payment">
              <Controller
                name="payment_proof"
                control={control}
                render={({ fieldState }) => (
                  <DragDropUpload
                    onDrop={handleFileSelect}
                    required
                    label="Upload Proof"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              {watch && (
                <div className={css.fileUploadState}>
                  <Typography variant="p" className={css.fileName}>
                    {watch.name}
                  </Typography>
                  <IconNode
                    src={Images.deleteRed}
                    alt="delete icon"
                    component="button"
                    className={css.deleteButton}
                    onClick={handleDeleteFile}
                  />
                </div>
              )}
            </label>
            <Controller
              name="remarks"
              control={control}
              render={({ field, fieldState }) => (
                <TextAreaLabel
                  {...field}
                  rows={6}
                  inputMode="text"
                  label="Remarks"
                  placeholder="Enter a remark"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.uploadWrapperRemark}
                />
              )}
            />
          </div>
          <div className={css.actionButtonWrapper}>
            <Button variant="outlined-secondary" onClick={onClose} className={css.actionButton}>
              Cancel
            </Button>
            <Button type="submit" className={css.actionButton}>
              Save
            </Button>
          </div>
        </form>
        <Loader open={isSubmitting} />
      </div>
    </Modal>
  );
};

export default AddPaymentDetails;
