import React from 'react';
import css from './index.module.scss';
import { Typography, IconNode } from '@components/base';
import Images from '@assets/images';

const RemarksPreview = (props: any) => {
  const { handleTabState, remarksInfo } = props;
  const handleEditClick = () => handleTabState(2);
  
  return (
    <div className={css.boxWrapper}>
      <div className={css.remarkBoxContainer}>
        <div className={css.fieldWrapper}>
          <Typography variant="pdoc">Remarks</Typography>
          <IconNode src={Images.editRed} onClick={handleEditClick} />
        </div>
        <div className={css.fieldWrapper}>
          <ol
            className={
              remarksInfo?.string?.length
                ? css.remarkListContainer
                : css.remarkListContainerNoMargin
            }>
            {remarksInfo?.string?.length
              ? remarksInfo?.string?.split('\n')?.map((remark: string, index: number) => {
                  return <li key={index}>{remark}</li>;
                })
              : `No Remarks Added`}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default RemarksPreview;
