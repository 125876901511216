import httpClient from './index.service';
import { SERVER_CONFIG } from '@helpers/constants';

async function createBillOfExchange(task_id: any, reqBody: any) {
  try {
    const response = await httpClient.patch(`${SERVER_CONFIG.order}/${task_id}/${SERVER_CONFIG.createBillOfExchange}`, reqBody);
    return response;
  } catch (error: any) {
    return error;
  }
}

export default createBillOfExchange;
