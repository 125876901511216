import { useEffect, useReducer } from 'react';
import { BoxContainer } from '@components/common';
import { Button } from '@components/base';
import FieldHeader from './components/field-header';
import FieldInput from './components/field-input';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { COA_ORDER_DETAILS_DEFAULT } from '@helpers/constants';
import { FormPropsStates } from '@helpers/types/coa';
import css from './index.module.scss';

const OrderDetails = (props: FormPropsStates) => {
  const { onFormSubmit, handleCancelClick, orderItem } = props;
  const { control, handleSubmit, watch } = useFormContext<any>();

  const { fields, append, remove } = useFieldArray({
    name: 'order_details',
    control
  });

  function generateUniqueId() {
    return `id-${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
  }

  const orderDetailsWatch = watch('order_details');
  useEffect(() => {
    if (COA_ORDER_DETAILS_DEFAULT?.length && !fields.length) {
      const defaultFields = COA_ORDER_DETAILS_DEFAULT.map((item: any) => {
        let fieldValue = '';
        if (item === 'Item') {
          fieldValue = orderItem?.order_product_name ?? 'ss';
        } else if (item === 'Batch Number') {
          fieldValue = orderItem?.coa_batch_number;
        }
        return {
          id: generateUniqueId(),
          field_name: item,
          field_value: fieldValue,
        };
      });

      append(defaultFields);
    }
  }, []);

  const handleAddField = () => {
    append({
      id: generateUniqueId(),
      field_name: '',
      field_value: ''
    });
  };

  return (
    <form noValidate className={css.coaFormWrapper} onSubmit={handleSubmit(onFormSubmit)}>
      <BoxContainer title="Order Details">
        <div className={css.orderDetailsWrapper}>
          <FieldHeader data={orderDetailsWatch} remove={remove} handleAddField={handleAddField} />
          <FieldInput data={orderDetailsWatch} />

          <div className={css.formActionWrapper}>
            <Button variant="text" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button type="submit">Save and Proceed</Button>
          </div>
        </div>
      </BoxContainer>
    </form>
  );
};

export default OrderDetails;
