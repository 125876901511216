import css from './index.module.scss';
import { Button } from '@components/base';
import CustomerContainer from './customer-container';
import { ICustomerInfo } from '@helpers/types/customer';
import { useFormContext, useWatch } from 'react-hook-form';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';

interface CustomerFormProps {
  customerOptions: ICustomerInfo[];
  onFormSubmit: (data: any) => void;
  onCancelClick?: () => void;
  onBackClick?: () => void;
  rectifyRemarks?: string;
}

const CustomerForm = (props: CustomerFormProps) => {
  const { customerOptions, onCancelClick, onFormSubmit, onBackClick, rectifyRemarks } = props;
  const {
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useFormContext();

  useBeforeUnloadAndNavigate();

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <CustomerContainer options={customerOptions} rectifyRemarks={rectifyRemarks} />
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div>
          <Button variant="outlined-secondary" onClick={onBackClick} className={css.nextBtn}>
            Back
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save and Proceed
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CustomerForm;
