import { useCallback, useState } from 'react';
import css from './index.module.scss';
import { Typography, Loader } from '@components/base';
import { SideBar, Seo, ProductDetailForm, GradeDetailForm } from '@components/common';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IProductForm } from '@helpers/types/product';
import { addProductSchema, addGradesSchema } from '@helpers/yup';
import { IGradeForm } from '@helpers/types/grade';
import { CLIENT_ROUTES } from '@router/routes';
import { useNavigate } from 'react-router-dom';
import notify from '@helpers/toastify-helper';
import { createProduct } from '@services/product.service';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import AccessWrapper from '@authorization/access-wrapper';

interface AddProductState {
  activeStep: number;
  productInfo: IProductForm | null;
}

const AddProduct = (props: any) => {
  const { actions = {} } = props.modulePermissions;
  const [productState, setProductState] = useState<AddProductState>({
    activeStep: 0,
    productInfo: null
  });

  const { activeStep, productInfo } = productState;

  useBeforeUnloadAndNavigate();
  const navigate = useNavigate();

  const productDetailForm = useForm<IProductForm>({
    resolver: yupResolver(addProductSchema),
    defaultValues: {
      product_id: '',
      name: '',
      CAS_number: '',
      description: '',
      remarks: '',
      packaging_details: '',
      haz: false,
      msds_doc: null,
      hs_code: ''
    }
  });

  const gradeDetailForm = useForm<IGradeForm>({
    resolver: yupResolver(addGradesSchema),
    defaultValues: {
      grades: [
        {
          grade_id: '',
          name: '',
          description: '',
          packaging_details: '',
          remarks: ''
        }
      ]
    }
  });

  const handleSideNavigation = useCallback((activeStep: number) => {
    setProductState((prevState: any) => ({ ...prevState, activeStep }));
  }, []);

  const handleNavigation = useCallback(() => {
    const { activeStep } = productState;
    if (activeStep === 1) handleSideNavigation(0);
    else if (activeStep === 0) handleSideNavigation(1);
  }, [handleSideNavigation, activeStep]);

  const handleCancelClick = useCallback(() => {
    navigate(`/${CLIENT_ROUTES.product}`);
  }, []);

  const handleProductDetailFormSubmit: SubmitHandler<IProductForm> = (data) => {
    setProductState((prevState: any) => ({ ...prevState, productInfo: data, activeStep: 1 }));
  };

  const handleGradeDetailFormSubmit: SubmitHandler<IGradeForm> = async (data) => {
    const { productInfo } = productState;
    const formData = new FormData();
    if (productInfo) {
      const { product_id, ...rest } = productInfo;
      const reqBody = {
        ...(product_id && { product_id }),
        ...rest,
        grades: data.grades.map((item) => {
          const { grade_id, ...rest } = item;
          return { ...rest, ...(grade_id && { grade_id }) };
        })
      };
      formData.append('msds_doc', reqBody.msds_doc as File);
      formData.append('data', JSON.stringify(reqBody));
      const response = await createProduct(formData);
      if (response.success) {
        notify({ message: 'New Product has been added successfully!', dismissible: true });
        navigate(`/${CLIENT_ROUTES.product}/${response.data.product_id}`);
      } else if (response.error) {
        const { error } = response;
        if (error) {
          handleSideNavigation(0);
          firstSetError('CAS_number', error ?? 'CAS number should be in the right format');
          notify({
            message: error ?? 'CAS number should be in the right format',
            dismissible: true,
            severity: 'error'
          });
        } else {
          notify({ message: 'Unable to add product', dismissible: true, severity: 'error' });
        }
      }
    }
  };

  const {
    setError: firstSetError,
    formState: { isSubmitting: firstIsSubmitting }
  } = productDetailForm;

  const {
    formState: { isSubmitting: secondIsSubmitting }
  } = gradeDetailForm;

  return (
    <AccessWrapper show={actions?.create} showUnauthorised>
      <main className={css.mainWrapper}>
        <Seo title="Add Product" />
        <div className={css.labelWrapper}>
          <Typography variant="h2">Add Product</Typography>
          <Typography variant="span">0{activeStep + 1} of 02</Typography>
        </div>
        <div className={css.subMainWrapper}>
          <div className={css.sidebarWrapper}>
            <SideBar activeStep={activeStep} onClick={handleSideNavigation}>
              <SideBar.Item label="Product Details" value={0} />
              <SideBar.Item label="Grades" value={1} disabled={!productInfo} />
            </SideBar>
          </div>
          <FormProvider {...productDetailForm}>
            {activeStep === 0 && (
              <ProductDetailForm
                onFormSubmit={handleProductDetailFormSubmit}
                onCancelClick={handleCancelClick}
              />
            )}
          </FormProvider>
          <FormProvider {...gradeDetailForm}>
            {activeStep === 1 && (
              <GradeDetailForm
                onFormSubmit={handleGradeDetailFormSubmit}
                onBackClick={handleNavigation}
                onCancelClick={handleCancelClick}
              />
            )}
          </FormProvider>
        </div>
        <Loader open={firstIsSubmitting || secondIsSubmitting} />
      </main>
    </AccessWrapper>
  );
};

export default AddProduct;
