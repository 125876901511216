import { Divider, Typography } from '@components/base';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import { PurchaseOrderStates } from '@helpers/types/po';
import css from '../index.module.scss';

interface IParties {
  partiesData: PurchaseOrderStates;
}

const Parties = (props: IParties) => {
  const { partiesData } = props;
  const { entityList, consigneeInfo, supplier } = partiesData;
  return (
    <div className={css.boxWrapper}>
      <div className={css.partiesContainer}>
        {/* <div className={css.fieldTitle}>
          <Typography variant="pdoc">Parties</Typography>
        </div> */}
        <div className={css.fieldWrapper}>
          <div className={css.partyContainer}>
            <div className={css.fieldTitle}>
              <Typography variant="pdoc">Purchaser</Typography>
            </div>
            <div className={css.wrapper}>
              <Typography variant="h3">{entityList?.name}</Typography>
              <Typography variant="span">{entityList?.address}</Typography>
              <Typography variant="span">GST - {entityList.gst_number}</Typography>
            </div>
          </div>
          <Divider className={css.divider} />
          <div className={css.partyContainer}>
            <div className={css.fieldTitle}>
              <Typography variant="pdoc">Supplier</Typography>
            </div>
            <div className={css.wrapper}>
              <Typography variant="h3">{consigneeInfo?.consignee_name}</Typography>
              <Typography variant="span">
                {supplier.supplier_factory_address?.readable_address ?? consigneeInfo?.address}
              </Typography>
              <div className={css.wrapper}>
                <Typography variant="span">GST - {consigneeInfo?.gst_number}</Typography>
              </div>
              <div>
                <Typography variant="span">{`POC - ${supplier.supplier_poc?.given_name} ${supplier.supplier_poc?.family_name} - ${supplier.supplier_poc?.email}`}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parties;
