import Images from '@assets/images';
import { Accordion, Button, Select, Typography } from '@components/base';
import { Avatar } from '@components/common';
import notify from '@helpers/toastify-helper';
import { useEffect, useState } from 'react';
import { updateShipmentCLearanceTask } from '@services/order.service';
import moment from 'moment';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import css from './index.module.scss';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';
import DateModal from './date-modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { dateConfirmSchema } from '@helpers/yup/order-tasks.schema';
import { ISelect, ISelectBoolean } from '@helpers/types';
import { Link } from 'react-router-dom';

interface ClearanceProps {
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete: () => void;
}

interface dateChangeFormProps {
  shipment_arrival_state_actual_date: Date | null;
}

interface updateGateInProps {
  shipment_clearance_confirmation: ISelectBoolean;
}

const UpdateShipmentClearanceCard = (props: ClearanceProps) => {
  const { taskData, onComplete } = props;
  const getTaskStatus = taskData[0]?.is_completed;
  const [isCompleted, setIsCompleted] = useState<boolean | undefined>(getTaskStatus);
  const taskId = taskData[0].task_id;

  const [modalOpen, setModalOpen] = useState(false);

  const doc_submitted = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const updateGateIn = useForm<updateGateInProps>({
    defaultValues: {
      shipment_clearance_confirmation: { label: 'No', value: false }
    }
  });

  const dateChangeForm = useForm<dateChangeFormProps>({
    resolver: yupResolver(dateConfirmSchema),
    defaultValues: {
      shipment_arrival_state_actual_date: null
    },
    shouldUnregister: true
  });

  const { control, getValues } = updateGateIn;

  const handleSelectChange = async () => {
    const values = getValues();
    if (values.shipment_clearance_confirmation.value == false) {
      notify({ message: 'Please select Yes as an option', severity: 'error' });
    } else {
      setModalOpen(true);
    }
  };

  useEffect(() => {
    const { setValue } = dateChangeForm;
    setValue(
      'shipment_arrival_state_actual_date',
      moment(taskData[0].shipment_arrival_state_actual_date).toDate()
    );
  }, []);

  const onFormSubmit = async (data: any) => {
    const body = {
      shipment_arrival_state_actual_date: `${moment(data.shipment_arrival_state_actual_date).format(
        'YYYY-MM-DD'
      )}`,
      shipment_clearance_confirmation: true
    };

    const response = await updateShipmentCLearanceTask(taskId as string, body);
    if (response.success) {
      notify({ message: 'Shipment clearance updated!' });
      onComplete();
      setIsCompleted(true);
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else notify({ message: 'Some error occurred', severity: 'error' });
  };

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {getTaskStatus || isCompleted ? (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              ) : (
                <Avatar src={Images.illustration} alt="" />
              )}
              <Link to={`/order/${taskData[0]?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  #{taskData[0]?.readable_task_id} - Update shipment clearance at POD
                </Typography>
              </Link>
            </div>
            <div className={css.pocWrapper}>
              <div className={css.uploadWrapper}>
                {!getTaskStatus && isCompleted === false && (
                  <Button variant="text" className={css.detailsButton} onClick={handleSelectChange}>
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordionContent}>
          <div className={css.stickerDetailsWrapper}>
            <div className={css.stickersInnerWrapper}>
              <Typography variant="body">Confirm Shipment Clearance</Typography>
              <div className={css.selectWrapper}>
                {isCompleted ? (
                  <Typography variant="h5">Yes</Typography>
                ) : (
                  <Controller
                    name="shipment_clearance_confirmation"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={doc_submitted}
                        isDisabled={getTaskStatus || isCompleted}
                        onChange={(e: any) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
      {modalOpen && (
        <FormProvider {...dateChangeForm}>
          <DateModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onFormSubmit={onFormSubmit}
            shipmentDate={taskData[0].shipment_arrival_state_actual_date ?? null}
          />
        </FormProvider>
      )}
    </div>
  );
};

export default UpdateShipmentClearanceCard;
