import { Button, IconNode, Modal, Typography, Loader } from '@components/base';
import css from './index.module.scss';
import { TextField, SelectLabel } from '@components/common';
import Images from '@assets/images';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { addOperationCosts as addOperationCostsService } from '@services/task.service';
import { addOperationCosts } from '@helpers/yup/add-vessel.schema';
import notify from '@helpers/toastify-helper';
import { CURRENCY } from '@helpers/constants';
import { ISelect } from '@helpers/types';

interface OperationCostsModalProps {
  open: boolean;
  onClose?: () => void;
  sendStatusToCard: (arg: boolean) => void;
  taskId: string;
  onComplete?: () => void;
}

interface FormType {
  freight_expenses?: string;
  port_expenses?: string;
  other_costs?: string;
  freight_expenses_currency?: ISelect | null;
  port_expenses_currency?: ISelect | null;
  other_costs_currency?: ISelect | null;
}

const AddOperationCostsCard = (props: OperationCostsModalProps) => {
  const { open, onClose, sendStatusToCard, taskId, onComplete } = props;

  const operationCostsForm = useForm<FormType>({
    resolver: yupResolver(addOperationCosts),
    defaultValues: {
      freight_expenses: '',
      port_expenses: '',
      other_costs: '',
      freight_expenses_currency: null,
      port_expenses_currency: null,
      other_costs_currency: null
    }
  });
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = operationCostsForm;

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const { onClose } = props;
    const reqBody = {
      freight_expenses: data.freight_expenses,
      port_expenses: data.port_expenses,
      other_costs: data.other_costs,
      freight_expenses_currency: data.freight_expenses_currency?.value,
      port_expenses_currency: data.port_expenses_currency?.value,
      other_costs_currency: data.other_costs_currency?.value
    };
    const response = await addOperationCostsService(taskId, reqBody);
    if (response.success) {
      notify({ message: 'Details Added Successfully' });
      sendStatusToCard(true);
      if (onComplete) {
        onComplete();
      }
      onClose?.();
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else notify({ message: 'Some error occurred', severity: 'error' });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Add Operation Costs</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="div"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={css.fieldsWrapper}>
            <div className={css.rowWrapper}>
              <Controller
                name="freight_expenses_currency"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    label="Currency"
                    required
                    options={CURRENCY ?? []}
                    defaultValue={null}
                    isSearchable
                    placeholder="Currency"
                    getOptionLabel={(option: any) => option.value}
                    getOptionValue={(option: any) => option.label}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.currencyFieldSpacing}
                  />
                )}
              />
              <Controller
                name="freight_expenses"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    label="Freight Expenses"
                    placeholder="Enter Expenses"
                    autoComplete="off"
                    enterKeyHint="next"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                    }
                  />
                )}
              />
            </div>
            <div className={css.rowWrapper}>
              <Controller
                name="port_expenses_currency"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    required
                    label="Currency"
                    options={CURRENCY ?? []}
                    defaultValue={null}
                    isSearchable
                    placeholder="Currency"
                    getOptionLabel={(option: any) => option.value}
                    getOptionValue={(option: any) => option.label}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.currencyFieldSpacing}
                  />
                )}
              />
              <Controller
                name="port_expenses"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Port Expenses"
                    required
                    placeholder="Enter Expenses"
                    autoComplete="off"
                    enterKeyHint="next"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                    }
                  />
                )}
              />
            </div>

            <div className={css.rowWrapper}>
              <Controller
                name="other_costs_currency"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    label="Currency"
                    required
                    options={CURRENCY ?? []}
                    defaultValue={null}
                    isSearchable
                    placeholder="Currency"
                    getOptionLabel={(option: any) => option.value}
                    getOptionValue={(option: any) => option.label}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.currencyFieldSpacing}
                  />
                )}
              />
              <Controller
                name="other_costs"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Other Costs"
                    required
                    placeholder="Enter Cost"
                    autoComplete="off"
                    enterKeyHint="next"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className={css.actionButtonWrapper}>
            <Button variant="outlined-secondary" className={css.actionButton} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" className={css.actionButton}>
              Save
            </Button>
          </div>
        </form>
        <Loader open={isSubmitting} />
      </div>
    </Modal>
  );
};

export default AddOperationCostsCard;
