import React, { useState } from 'react';
import css from './index.module.scss';
import { BoxContainer, TextField } from '@components/common';
import Button from '@components/base/button';
import { getFormattedDate } from '@helpers/utils';

const OrderDetails = (props: any) => {
  const { onNext, onCancelClick, data } = props;
  return (
    <div className={css.formWrapper1}>
      <OrderDataWrapper data={data} />
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div>
          <Button onClick={onNext}>Save and Proceed</Button>
        </div>
      </div>
    </div>
  );
};

const OrderDataWrapper = (props: any) => {
  const { data } = props;
  return (
    <>
      <BoxContainer title="Consignee Details" className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={data?.customer?.name}
            className={css.fieldSpacing}
            label="Customer Name"
          />
          <TextField
            disabled
            value={data?.customer_address?.country}
            className={css.fieldSpacing}
            label="Country"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={data?.customer?.number}
            className={css.fieldSpacing}
            label="Customer Phone"
          />
          <TextField
            disabled
            value={data?.customer?.email}
            className={css.fieldSpacing}
            label="Email"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={data?.customer_address?.readable_address}
            className={css.fieldSpacing}
            label="Customer Address"
          />
        </div>
      </BoxContainer>
      <BoxContainer title="Order Details" className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={
              data?.order_ci
                ? data?.order_ci.commercial_invoice_id ?? data?.order_ci?.customs_invoice_id ?? '-'
                : data?.order_pi.document_number ?? '-'
            }
            className={css.fieldSpacing}
            label="Invoice Number"
          />
          <TextField
            disabled
            value={
              data?.order_ci
                ? getFormattedDate(data?.order_ci?.document_date)?.toString()
                : getFormattedDate(data?.order_pi?.document_date)?.toString() ?? '-'
            }
            className={css.fieldSpacing}
            label="Invoice Date"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={data?.transportation_mode_display_value}
            className={css.fieldSpacing}
            label="Mode of Transportation"
          />
          <TextField
            disabled
            value={data?.inco_terms_display_value}
            className={css.fieldSpacing}
            label="Inco Terms"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={data?.country_of_origin_display_value}
            className={css.fieldSpacing}
            label="Country of Origin"
          />
          <TextField
            disabled
            value={data?.country_of_final_destination_display_value}
            className={css.fieldSpacing}
            label="Country of Final Destination"
          />
        </div>
        {data?.bl_number ? (
          <div className={css.rowWrapper}>
            <TextField
              disabled
              value={data?.bl_number ?? '-'}
              className={css.fieldSpacing}
              label="BL Number"
            />
            <TextField
              disabled
              value={getFormattedDate(data?.bl_date)?.toString() ?? '-'}
              className={css.fieldSpacing}
              label="BL Date"
            />
          </div>
        ) : (
          <></>
        )}
      </BoxContainer>
    </>
  );
};

export default OrderDetails;
