import css from './index.module.scss';
import Skeleton from '@components/base/skeleton';
import { Divider } from '@components/base';

const Card = () => {
  return (
    <div className={css.orderListingCardSkeletonWrapper}>
      <div className={css.orderListingCardSkeletonHeader}>
        <div className={css.orderListingCardSkeletonHeaderLeft}>
          <Skeleton animation="wave" width={'40%'} height={'15px'} />
          <Skeleton animation="wave" width={'100%'} height={'20px'} />
        </div>
        <div className={css.orderListingCardSkeletonHeaderRight}>
          <Divider className={css.divider} />
          <Skeleton animation="wave" width={'100%'} height={'40px'} />
          <Divider className={css.divider} />
          <Skeleton animation="wave" width={'100%'} height={'40px'} />
        </div>
      </div>
      <div className={css.orderListingCardSkeletonBody}>
        <div className={css.orderListingCardSkeletonBodyRow}>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
          </div>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
          </div>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
          </div>
        </div>
        <div className={css.orderListingCardSkeletonBodyRow}>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
          </div>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
          </div>
          <div className={css.orderListingCardSkeletonBodyRowItem}>
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
            <Skeleton animation="wave" width={'100%'} height={'30px'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;

const CardListing = () => {
  return (
    <>
      {Array.from({ length: 4 }).map((_, index) => (
        <Card key={index} />
      ))}
    </>
  );
};

export { CardListing };
