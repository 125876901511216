import { UnauthorizedPage } from '@components/common';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from '@helpers/hooks/AppContext';

interface IAccessWrapperProps {
  children: React.ReactNode;
  show: boolean;
  showUnauthorised?: boolean;
  showTo?: string[];
  byPassShowWithShowTo?: boolean;
}

const AccessWrapper = (props: IAccessWrapperProps) => {
  const {
    children,
    show,
    showUnauthorised = false,
    showTo = [],
    byPassShowWithShowTo = false
  } = props;
  const [displayChildren, setDisplayChildren] = useState(false);
  const { appState } = useContext(AppContext);
  const { userType = null } = appState;

  useEffect(() => {
    if (show || byPassShowWithShowTo) {
      if (showTo.length > 0) {
        if (showTo.includes(userType)) {
          setDisplayChildren(true);
        } else {
          setDisplayChildren(false);
        }
      } else {
        setDisplayChildren(true);
      }
    } else {
      setDisplayChildren(false);
    }
  }, [showTo, show]);
  return (
    <>
      {displayChildren && children}
      {showUnauthorised && !displayChildren && <UnauthorizedPage />}
    </>
  );
};

export default AccessWrapper;
