import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from '@helpers/constants';
import * as yup from 'yup';

export const customerAcceptanceProof = yup.object().shape({
  acceptance_proof: yup.mixed().nullable(),
  // .required('Please select a file to upload')
  // .test('fileSize', 'File Size is too large', (value: any) =>
  //   value ? value?.size <= MAX_FILE_SIZE : true
  // )
  // .test('fileType', 'Unsupported File Format', (value: any) =>
  //   value ? SUPPORTED_FORMATS.includes(value?.type) : true
  // )
  remarks: yup.string().nullable().optional()
});
