import React, { useCallback, useState } from 'react';
import css from './index.module.scss';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Button, IconNode, Loader, Modal, Typography } from '@components/base';
import Images from '@assets/images';
import { DragDropUpload, InputDatePicker, TextAreaLabel } from '@components/common';
import { uploadEBRCConfirmation } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { yupResolver } from '@hookform/resolvers/yup';
import { ebrcDetailsConfirmation } from '@helpers/yup/add-ebrc.schema';
import moment from 'moment';
import TextField from '@components/common/text-field';

interface IEBRCConfirmation {
  document: File | null;
  ebrc_number: string;
  ebrc_date: Date | null;
  remarks: string;
}

interface IUploadEBRCConfirmationProps {
  open: boolean;
  onClose: () => void;
  sendStatusToCard: (status: boolean) => void;
  onComplete?: () => void;
  taskId: string;
}

const UploadEBRCConfirmation = (props: IUploadEBRCConfirmationProps) => {
  const { open, onClose, sendStatusToCard, onComplete, taskId } = props;

  const ebrcConfirmation = useForm<IEBRCConfirmation>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(ebrcDetailsConfirmation),
    defaultValues: {
      document: null,
      ebrc_number: '',
      ebrc_date: null,
      remarks: ''
    }
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue
  } = ebrcConfirmation;

  const docWatch = useWatch({
    name: 'document',
    control: control
  });

  const handleFormSubmit = async (data: IEBRCConfirmation) => {
    const reqBody = new FormData();
    reqBody.append('ebrc_document', data.document as File);
    reqBody.append('remarks', data.remarks);
    reqBody.append('ebrc_number', data.ebrc_number as string);
    reqBody.append('ebrc_date', moment(data.ebrc_date).format('YYYY-MM-DD'));
    const response = await uploadEBRCConfirmation(taskId, reqBody);
    if (response.success) {
      notify({ message: 'EBRC Uploaded successfully' });
      sendStatusToCard(true);
      onClose?.();
      if (onComplete) {
        onComplete();
      }
    } else if (response?.error) {
      notify({
        message: response?.error ?? 'Something went wrong, Contact Tech Team',
        severity: 'error'
      });
    }
  };

  const handleDeleteFile = useCallback(() => {
    setValue('document', null);
  }, []);

  const handleFileSelect = (file: any[]) => {
    if (file.length) {
      setValue('document', file[0]);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Upload Confirmation</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="div"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={css.fieldsWrapper}>
            <Controller
              name="ebrc_number"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  required
                  inputMode="text"
                  enterKeyHint="next"
                  label="EBRC Number"
                  placeholder="Enter Number"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  rootClassName={css.fieldSpacing}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="ebrc_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  label="EBRC Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />
            <div>
              <Typography variant="pdoc" className={css.docLabel}>
                Upload EBRC Confirmation
              </Typography>

              <Controller
                name="document"
                control={control}
                render={({ fieldState }) => (
                  <DragDropUpload
                    acceptImages={true}
                    multiple={false}
                    onDrop={handleFileSelect}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              {docWatch && (
                <div className={css.fileUploadState}>
                  <Typography variant="p" className={css.fileName}>
                    {docWatch.name}
                  </Typography>
                  <IconNode
                    src={Images.deleteRed}
                    alt="delete icon"
                    component="button"
                    className={css.deleteButton}
                    onClick={handleDeleteFile}
                  />
                </div>
              )}
            </div>
            <Controller
              name="remarks"
              control={control}
              render={({ field, fieldState }) => (
                <TextAreaLabel
                  {...field}
                  rows={6}
                  inputMode="text"
                  label="Remarks"
                  placeholder="Enter a remark"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.uploadWrapperRemark}
                />
              )}
            />
          </div>
          <div className={css.actionButtonWrapper}>
            <Button variant="outlined-secondary" className={css.actionButton} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" className={css.actionButton} disabled={isSubmitting}>
              {isSubmitting ? 'Uploading...' : 'Upload'}
            </Button>
          </div>
        </form>
        <Loader open={isSubmitting} />
      </div>
    </Modal>
  );
};

export default UploadEBRCConfirmation;
