/* eslint-disable @typescript-eslint/no-empty-function */
import { Loader, Typography } from '@components/base';
import { DataGrid, DocumentRow, TableSkeleton } from '@components/common';
import {
  CURRENCY,
  SHIPMENT_DOCUMENTS_TYPE_MAP,
  UPLOAD_DOCS_MAP,
  getLabelFromValue
} from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import { ShipmentDocForm, ShipmentDocument } from '@helpers/types/document';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  updateOrderOtherDoc,
  updateShipmentDocuments,
  uploadOrderOtherDoc,
  uploadShipmentDocuments
} from '@services/order.service';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CellProps, Column } from 'react-table';
import css from './documents.module.scss';
import UploadDocument from './upload-document';
import { addMappingDocumentValidation } from '@helpers/yup/add-document.schema';

interface DocumentsTabProps {
  documents: ShipmentDocument[];
  onChange?: (param: ShipmentDocument) => void;
  taskId: string;
  getApiCall?: () => void;
  shipmentType?: string;
  taskItem: Partial<ITaskTabProps>;
  orderId: string;
  documentDataLoading: boolean;
}

interface DocumentsTabStates {
  modalState: boolean;
  isLoading: boolean;
  currentDoc: Partial<ShipmentDocument>;
  editMode: boolean;
}

interface DocumentGrid {
  name: Partial<ShipmentDocument>;
  proof: Partial<ShipmentDocument>;
  doc_object: Partial<ShipmentDocument>;
}

export interface IDocumentMapping {
  [key: string]: {
    type: string;
    label: string;
    placeholder: string;
    documentKey: number;
    required: boolean;
    initialValue: any;
    options?: any[];
    validation?: {
      type: string;
      typeErrorMessage: string;
    };
  };
}

const documentMapping: IDocumentMapping = {
  sb_number: {
    type: 'text',
    label: 'Shipping Bill Number',
    placeholder: 'Shipping Bill Number',
    required: true,
    documentKey: 14,
    initialValue: null
  },
  sb_invoice_amount: {
    type: 'text',
    label: 'Shipping Bill Invoice Amount',
    placeholder: 'Shipping Bill Invoice Amount',
    required: true,
    documentKey: 14,
    initialValue: null,
    validation: {
      type: 'number',
      typeErrorMessage: 'Shipping Bill Invoice Amount must be a number'
    }
  },
  sb_invoice_currency: {
    type: 'select',
    label: 'Shipping Bill Invoice Currency',
    placeholder: 'Shipping Bill Invoice Currency',
    required: true,
    documentKey: 14,
    initialValue: null,
    options: CURRENCY
  },
  sb_fob_amount: {
    type: 'text',
    label: 'Shipping Bill FOB Amount',
    placeholder: 'Shipping Bill FOB Amount',
    required: true,
    documentKey: 14,
    initialValue: null,
    validation: {
      type: 'number',
      typeErrorMessage: 'Shipping Bill FOB Amount must be a number'
    }
  },
  sb_fob_currency: {
    type: 'select',
    label: 'Shipping Bill FOB Currency',
    placeholder: 'Shipping Bill FOB Currency',
    required: true,
    documentKey: 14,
    initialValue: null,
    options: CURRENCY
  },
  rodtep_amount: {
    type: 'text',
    label: 'RODTEP Amount',
    placeholder: 'RODTEP Amount',
    required: true,
    documentKey: 14,
    initialValue: null,
    validation: {
      type: 'number',
      typeErrorMessage: 'RODTEP Amount must be a number'
    }
  },
  rodtep_currency: {
    type: 'select',
    label: 'RODTEP Currency',
    placeholder: 'RODTEP Currency',
    required: true,
    documentKey: 14,
    initialValue: null,
    options: CURRENCY
  },
  drawback_amount: {
    type: 'text',
    label: 'Drawback Amount',
    placeholder: 'Drawback Amount',
    required: true,
    documentKey: 14,
    initialValue: null,
    validation: {
      type: 'number',
      typeErrorMessage: 'Drawback Amount must be a number'
    }
  },
  drawback_currency: {
    type: 'select',
    label: 'Drawback Currency',
    placeholder: 'Drawback Currency',
    required: true,
    documentKey: 14,
    initialValue: null,
    options: CURRENCY
  }
};

const DocumentsTab = (props: DocumentsTabProps) => {
  const { documents, getApiCall = () => {}, taskItem, orderId, documentDataLoading } = props;
  const [documentState, setDocumentState] = useState<DocumentsTabStates>({
    modalState: false,
    isLoading: false,
    currentDoc: {},
    editMode: false
  });
  const { modalState, isLoading, currentDoc, editMode } = documentState;

  const documentForm = useForm<any>({
    resolver: yupResolver(
      addMappingDocumentValidation(documentMapping, Number(currentDoc.doc_key))
    ),
    defaultValues: {
      document_id: '',
      document_type: null,
      document_name: '',
      remarks: '',
      document_object: null,
      reason_for_update: null,
      edit_mode: false
    },
    shouldUnregister: true
  });

  const {
    setValue,
    formState: { isSubmitting, errors },
    unregister,
    register
  } = documentForm;

  const toggleModalState = () => {
    setDocumentState((prevState) => ({
      ...prevState,
      modalState: !prevState.modalState
    }));
  };

  const handleUploadDocument = (data: ShipmentDocument) => async () => {
    setDocumentState((prevState) => ({
      ...prevState,
      modalState: true,
      currentDoc: data,
      editMode: data?.is_uploaded
    }));

    setValue('document_type', { label: data.doc_key, value: data.doc_key }, { shouldDirty: false });
    setValue('document_object', null, { shouldDirty: false });
    setValue('edit_mode', data?.is_uploaded, { shouldDirty: false });
  };

  const handleFormSubmit: SubmitHandler<any> = async (param) => {
    const postId = currentDoc?.document_primary_key
      ? currentDoc?.document_primary_key
      : currentDoc?.object_primary_key;

    const formData = new FormData();
    formData.append('remarks', param.remarks as string);
    formData.append('document_object', param.document_object as Blob);
    formData.append('type', param.document_type.value);
    if (param?.reason_for_update) {
      formData.append('reason_for_update', param.reason_for_update);
    }
    if (param.document_type.value) {
      const matchingFields = Object.entries(documentMapping).filter(
        ([key, config]) => config.documentKey === parseInt(param.document_type.value ?? '0')
      );
      matchingFields.forEach(([key, config]) => {
        if (config.type === 'select') {
          formData.append(key, param[key]?.value as string);
        } else {
          formData.append(key, param[key] as string);
        }
      });
    }
    switch (currentDoc?.doc_type) {
      case SHIPMENT_DOCUMENTS_TYPE_MAP.order_related: {
        const res = await handleApiCall(
          currentDoc?.is_uploaded as boolean,
          postId as string,
          SHIPMENT_DOCUMENTS_TYPE_MAP.order_related,
          formData
        );
        res?.success
          ? notify({
              message: 'Document Uploaded Successfully',
              severity: 'success',
              dismissible: true
            })
          : notify({
              message: res.error ?? 'Something went wrong',
              severity: 'error',
              dismissible: true
            });
        break;
      }

      case SHIPMENT_DOCUMENTS_TYPE_MAP.order_item_related: {
        formData.append('order_item', currentDoc.order_item_id);
        const res = await handleApiCall(
          currentDoc?.is_uploaded as boolean,
          postId as string,
          SHIPMENT_DOCUMENTS_TYPE_MAP.order_item_related,
          formData
        );
        res.success
          ? notify({
              message: 'Document Uploaded Successfully',
              severity: 'success',
              dismissible: true
            })
          : notify({
              message: res.error ?? 'Something went wrong',
              severity: 'error',
              dismissible: true
            });
        break;
      }

      default:
        break;
    }
    setDocumentState((prevState) => ({
      ...prevState,
      modalState: false
    }));
  };

  const handleApiCall = async (
    isUploaded: boolean,
    postId: string,
    doc_type: 'order_related' | 'order_item_related',
    requestBody: any
  ) => {
    setDocumentState((prevState) => ({
      ...prevState,
      isLoading: true
    }));

    if (!isUploaded) {
      const res = await uploadOrderOtherDoc(orderId, requestBody);
      getApiCall();
      setDocumentState((prevState) => ({ ...prevState, isLoading: false }));
      return res;
    } else {
      const res = await updateOrderOtherDoc(orderId, requestBody, postId);
      getApiCall();
      setDocumentState((prevState) => ({ ...prevState, isLoading: false }));
      return res;
    }
  };

  const [documentColumn, documentData] = useMemo(() => {
    const column: Column<DocumentGrid>[] = [
      {
        Header: 'Document Name',
        accessor: 'name',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return (
            <DocumentRow.Title
              title={value?.doc_name}
              documentAvailable={value?.doc_link}
              required={value?.compulsory}
            />
          );
        }
      },
      {
        Header: 'Proofs',
        accessor: 'proof',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return (
            <>
              {value.uploadable ? (
                <DocumentRow.Upload
                  documentAvailable={value?.doc_link}
                  onClick={handleUploadDocument(value)}
                  disabled={taskItem.is_completed}
                />
              ) : (
                <>
                  <Typography variant="label">
                    {value?.doc_link ? '' : 'Generate via task'}
                  </Typography>
                </>
              )}
            </>
          );
        }
      },
      {
        Header: 'View',
        accessor: 'doc_object',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return <DocumentRow.View document={value?.doc_link} title={''} />;
        }
      }
    ];

    const row: DocumentGrid[] = documents?.map((doc) => ({
      name: doc,
      proof: doc,
      doc_object: doc
    }));
    return [column, row];
  }, [props.documents]);

  useEffect(() => {
    Object.keys(documentMapping).forEach((key) => unregister(key));
    if (currentDoc?.doc_key) {
      const matchingFields = Object.entries(documentMapping).filter(
        ([key, config]) => config.documentKey === parseInt(currentDoc.doc_key ?? '0')
      );
      matchingFields.forEach(([key, config]) => {
        register(key, { required: config.required });
        if (config.type === 'select' && config.options === CURRENCY) {
          setValue(key as keyof ShipmentDocForm, {
            label: currentDoc?.data[key],
            value: currentDoc?.data[key]
          });
        } else {
          setValue(key as keyof ShipmentDocForm, currentDoc?.data[key] ?? config.initialValue);
        }
      });
    }
  }, [currentDoc, modalState]);

  return (
    <div className={css.documentTabWrapper}>
      <div className={css.documentWrapper}>
        {documentDataLoading ? (
          <TableSkeleton colsNum={3} rowsNum={3} />
        ) : (
          documents?.length > 0 && <DataGrid columns={documentColumn} data={documentData} />
        )}
      </div>
      <FormProvider {...documentForm}>
        <UploadDocument
          open={modalState}
          editMode={editMode}
          onClose={toggleModalState}
          availableDocumentType={{
            label: currentDoc?.doc_name as string,
            value: currentDoc?.doc_key as string
          }}
          onFormSubmit={handleFormSubmit}
          currentDoc={currentDoc}
          documentMapping={documentMapping}
        />
      </FormProvider>
      <Loader open={isSubmitting || isLoading} />
    </div>
  );
};

export default DocumentsTab;
