import { ILoginCredential, IPasswordResetParam } from '@helpers/types';
import httpClient from './index.service';
import { SERVER_CONFIG } from '@helpers/constants';

async function userLogin(credential: ILoginCredential) {
  try {
    const response = await httpClient.post(SERVER_CONFIG.login, credential);
    return response;
  } catch (error: any) {
    return error;
  }
}

async function googleLogin(code: string) {
  try {
    const response = await httpClient.post(SERVER_CONFIG.loginGoogle, { googletoken: code });
    return response;
  } catch (error: any) {
    return error;
  }
}

async function requestResetEmail(email: string) {
  try {
    const response = await httpClient.post(SERVER_CONFIG.resetEmail, {
      email
    });
    return response;
  } catch (error: any) {
    return error;
  }
}

async function patchPasswordReset(param: IPasswordResetParam) {
  try {
    const response = await httpClient.patch(SERVER_CONFIG.passwordReset, param);
    return response;
  } catch (error: any) {
    return error;
  }
}

async function sendOtp(email: string) {
  try {
    const response = await httpClient.post(SERVER_CONFIG.sendOtp, { email });
    return response;
  } catch (error: any) {
    return error;
  }
}

async function logoutUser(token: string | null) {
  try {
    const response = await httpClient.post(SERVER_CONFIG.logout, { refresh_token: token });
    return response;
  } catch (error: any) {
    return error;
  }
}

export { googleLogin, userLogin, requestResetEmail, patchPasswordReset, sendOtp, logoutUser };
