import css from './index.module.scss';
import { IconNode, Typography } from '@components/base';
import { BoxContainer, DataGrid } from '@components/common';
import { GradeInfo } from '@helpers/types/grade';
import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import Images from '@assets/images';

interface GradesInfoProps {
  grades: GradeInfo[];
}

interface GradesGrid {
  name: string;
  description: string;
  packaging_details: string;
  remarks: string;
  documents: string | null;
  display_product_grade_readable_grade_id: string;
}

const GradesInfo = (props: GradesInfoProps) => {
  const [gradesColumn, gradesData] = useMemo(() => {
    const { grades } = props;
    const column: Column<GradesGrid>[] = [
      {
        Header: 'Grade Name',
        accessor: 'name'
      },
      {
        Header: 'Grade ID',
        accessor: 'display_product_grade_readable_grade_id'
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (props: CellProps<GradesGrid>) => {
          const { value } = props;
          return (
            <Typography variant="p" className={css.alignText}>
              {value}
            </Typography>
          );
        }
      },
      {
        Header: 'Packaging Details',
        accessor: 'packaging_details',
        Cell: (props: CellProps<GradesGrid>) => {
          const { value } = props;
          return (
            <Typography variant="p" className={css.alignText}>
              {value}
            </Typography>
          );
        }
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
        Cell: (props: CellProps<GradesGrid>) => {
          const { value } = props;
          return (
            <Typography variant="p" className={css.alignText}>
              {value}
            </Typography>
          );
        }
      }
      // {
      //   Header: 'Documents',
      //   accessor: 'documents',
      //   Cell: (props: CellProps<GradesGrid>) => {
      //     const { value } = props;
      //     return (
      //       <a
      //         href={value}
      //         download={value}
      //         target="_blank"
      //         rel="noopener noreferrer"
      //         className={css.productDocCount}>
      //         <IconNode src={Images.documentInfo} alt="info-icon" />
      //         <Typography variant="p" className={css.alignText}>
      //           {value ? 'Grade Document' : 'Not Available'}
      //         </Typography>
      //       </a>
      //     );
      //   }
      // }
    ];
    const row: GradesGrid[] = grades.map((item) => ({
      name: item.name,
      description: item.description,
      packaging_details: item.packaging_details,
      remarks: item.remarks,
      documents: item.msds_doc,
      display_product_grade_readable_grade_id: item?.display_product_grade_readable_grade_id ?? '-'
    }));
    return [column, row];
  }, [props.grades]);

  return (
    <div className={css.productInfoContainer}>
      <BoxContainer title="Grades">
        <div className={css.detailBoxWrapper}>
          <DataGrid columns={gradesColumn} data={gradesData} />
        </div>
      </BoxContainer>
    </div>
  );
};
export default GradesInfo;
