import { cloneElement } from 'react';

interface PermissionWrapperProps {
  children: any;
  modulePermissions?: IUserPermissionsData;
  otherModulePermissions?: IUserPermissionsData[];
}

const PermissionWrapper = (props: PermissionWrapperProps) => {
  const { children, modulePermissions, otherModulePermissions } = props;
  const ClonedChildren = cloneElement(children, { modulePermissions, otherModulePermissions });
  return ClonedChildren;
};

export default PermissionWrapper;
