import { memo } from 'react';
import css from './index.module.scss';
import { BoxContainer, SelectLabel, TextField } from '@components/common';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { SelfEntity } from '@helpers/types/entity';
import { IExportConsigneeForm } from '@helpers/types/add-order';

interface ExporterContainerProps {
  options: SelfEntity[];
}

const ExporterContainer = (props: ExporterContainerProps) => {
  const { options } = props;
  const { control, setValue } = useFormContext<IExportConsigneeForm>();
  const watchField = useWatch({
    name: 'elchemy_entity',
    control
  });

  const handleChange = (newValue: unknown) => {
    if (newValue) setValue('elchemy_entity', newValue as SelfEntity, { shouldValidate: true });
    else setValue('elchemy_entity', null, { shouldValidate: true });
  };

  return (
    <BoxContainer title="Exporter" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <Controller
            name="elchemy_entity"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                autoFocus
                required
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.entity_id}
                options={options}
                onChange={handleChange}
                label="Name"
                rootClassName={css.fieldWrapper}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <TextField
            disabled
            value={watchField?.gst_number ?? ''}
            label="GST Number"
            placeholder="Enter GST Number"
          />
        </div>
        <TextField
          disabled
          value={watchField?.address ?? ''}
          label="Address"
          placeholder="Enter address"
        />
      </div>
    </BoxContainer>
  );
};

ExporterContainer.defaultProps = {
  options: []
};

export default memo(ExporterContainer);
