export const remarkFunction = (data: any) => {
  const remarkArr: string[] = [];
  if (data?.payment_transfers) {
    remarkArr.push(
      `All payment transfer charges outside India to be borne by Consignee ${data?.name}`
    );
  }

  if (data?.pss_confirmation) {
    remarkArr.push(`Consignment dispatch against PSS confirmation`);
  }

  if (data?.free_days_at_port) {
    remarkArr.push(`${data.field_free_days_port} days free days at port of discharge`);
  }

  if (data?.neutral_packing) {
    remarkArr.push(`Packing will be neutral`);
  }

  if (data?.consignment_elchemy) {
    remarkArr.push(`Consignment will be on Elchemy branding`);
  }

  if (data?.percent_advance_payment) {
    remarkArr.push(`${data.field_percentage_payment} of payment is done`);
  }

  if (data?.deviation_quality_days) {
    remarkArr.push(
      `Any deviation in quality should be reported within ${data.field_days_deviation} days of receiving cargo`
    );
  }

  if (data?.bl_switchable_port) {
    remarkArr.push(`BL will be switchable at ${data.field_bl_port}`);
  }

  if (data?.payment_due_date) {
    remarkArr.push(`Payment due date is ${data.field_payment_due}`);
  }

  if (data?.inspection_cost) {
    remarkArr.push(`Inspection Cost by ${data.field_inspection_cost} to be borne by Consignee`);
  }

  if (data?.late_fee) {
    remarkArr.push(
      `Please be advised that a monthly late fee of 1.5% of the total amount due will be charged on overdue payments.`
    );
  }

  if (data?.other_remarks) {
    const otherRemarkArr: string[] = [];
    data.Remarks.forEach((val: Record<string, string>) => {
      otherRemarkArr.push(Object.values(val).join('\n '));
    });
    remarkArr.push(`${otherRemarkArr.join('\n ')}`);
    return remarkArr.join('\n ');
  } else {
    return remarkArr.join('\n ');
  }
};
