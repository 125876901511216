import css from './index.module.scss';
import { Skeleton } from '@components/base';

const POCSkeletonCard = () => {
  return (
    <div className={css.POCSkeletonCardWrapper}>
      <Skeleton variant="circular" width={40} height={40} />
      <div className={css.POCSkeletonCardDetails}>
        <Skeleton variant="text" width={100} height={20} />
        <Skeleton variant="text" width={200} height={20} />
        <Skeleton variant="text" width={150} height={20} />
      </div>
    </div>
  );
};

export default POCSkeletonCard;
