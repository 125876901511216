import { Children, createContext, OlHTMLAttributes, ReactNode } from 'react';
import css from './index.module.scss';
import BreadCrumbItem from './breadcrumb-item';

interface BreadCrumbProps {
  children: ReactNode;
  listProps?: OlHTMLAttributes<HTMLOListElement>;
  separator: string;
  active?: number;
}

export const BreadCrumbContext = createContext({
  separator: '/',
  active: 0
});

const BreadCrumb = (props: BreadCrumbProps) => {
  const { listProps, children, separator, active = Children.count(children) - 1 } = props;
  return (
    <BreadCrumbContext.Provider value={{ separator, active }}>
      <nav aria-label="breadcrumb" className={css.breadcrumbWrapper}>
        <ol {...listProps} className={css.linkContainer}>
          {children}
        </ol>
      </nav>
    </BreadCrumbContext.Provider>
  );
};

BreadCrumb.defaultProps = {
  separator: '/'
};

BreadCrumb.Item = BreadCrumbItem;

export default BreadCrumb;
