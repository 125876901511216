import { BoxContainer, TextField } from '@components/common';
import { useFormContext, Controller } from 'react-hook-form';
import { IconNode } from '@components/base';
import Images from '@assets/images';
import { FieldValue } from '@helpers/types/coa';
import css from './index.module.scss';

interface FieldInputStates {
  data: FieldValue[];
}

const FieldInput = (props: FieldInputStates) => {
  const { data } = props;
  return (
    <BoxContainer title="Add Fields for Order Details" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.fieldValueWrapper}>
          {data.map((item: any, index: number) => (
            <FieldValueItem
              fieldValueIndex={`order_details.${index}.field_value`}
              data={item}
              key={item.id}
            />
          ))}
        </div>
      </div>
    </BoxContainer>
  );
};

interface FieldValueItemStates {
  fieldValueIndex: string;
  data: FieldValue;
}

const FieldValueItem = (props: FieldValueItemStates) => {
  const { fieldValueIndex, data } = props;
  const { control } = useFormContext<any>();
  return (
    <div className={css.fieldValueItem}>
      {data.field_name?.length ? (
        <Controller
          name={fieldValueIndex}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              autoFocus
              type="text"
              inputMode="text"
              enterKeyHint="next"
              disabled={data.field_name === 'Item' || data.field_name === 'Batch Number'}
              label={data.field_name}
              placeholder="Enter data"
              error={fieldState.invalid}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
              helperText={fieldState.error?.message}
            />
          )}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default FieldInput;
