import './index.style.scss';
import { Link, LinkProps } from 'react-router-dom';
import { buttonVariantType } from '@helpers/types';
import PropTypes from 'prop-types';
import { pushEventTracking } from '@helpers/utils';

export interface LinkButtonProps extends LinkProps {
  variant?: buttonVariantType;
  disabled?: boolean;
  eventTrackingName?: string;
}

const LinkButton = (props: LinkButtonProps) => {
  const {
    variant,
    className,
    children,
    disabled,
    eventTrackingName = 'sdf',
    ...otherProps
  } = props;
  const wrapperClassName = disabled
    ? `link-button-${variant} btn-${variant}-disabled ${className}`
    : `link-button-${variant} ${className}`;
  return (
    <Link
      role="button"
      {...otherProps}
      className={wrapperClassName}
      onClick={
        eventTrackingName
          ? () => {
              pushEventTracking(eventTrackingName);
            }
          : undefined
      }>
      {children}
    </Link>
  );
};

LinkButton.defaultProps = {
  variant: 'text'
};

LinkButton.propTypes = {
  variant: PropTypes.oneOf(['text', 'contained', 'outlined', 'outlined-secondary']),
  disabled: PropTypes.bool
};

LinkButton.displayName = 'LinkButton';

export default LinkButton;
