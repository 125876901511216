import { getToken } from '@helpers/auth-helper';
import { useEffect, useState, useContext } from 'react';
import PermissionWrapper from './permission-wrapper';
import { AppContext } from '@helpers/hooks/AppContext';
import { UnauthorizedPage } from '@components/common';
import SessionService from '@helpers/session-helper';
import { getPermissionFromLocalStorage } from '@helpers/utils';

interface IRouteGuardProps {
  children: JSX.Element;
  moduleData: {
    name: string;
    permissions: string[];
  };
}

const RouteGuard = (props: IRouteGuardProps) => {
  const { children, moduleData } = props;
  const [displayContent, setDisplayContent] = useState('NONE');
  const [userPermissions, setUserPermissions] = useState<IUserPermissionsData>();
  const [otherModulePermissions, setOtherModulePermissions] = useState<IUserPermissionsData[]>([]);

  const { appDispatch } = useContext(AppContext);

  useEffect(() => {
    const token = getToken('permissions');
    const userPermissions = getPermissionFromLocalStorage();
    if (!userPermissions) {
      setDisplayContent('DENIED');
      SessionService.clearSession();
      return;
    } else {
      const userType = userPermissions.user_type;
      if (moduleData?.permissions?.includes(userType)) {
        setDisplayContent('ALLOWED');
        setUserPermissions(
          userPermissions?.permissions?.find(
            (p: IUserPermission) => p.module === moduleData.name
          ) ?? {}
        );
        setOtherModulePermissions(userPermissions?.permissions);
        appDispatch({
          type: 'userType',
          payload: { userType }
        });
      } else {
        setDisplayContent('DENIED');
      }
    }
  }, [moduleData]);
  return (
    <>
      {displayContent === 'ALLOWED' && (
        <PermissionWrapper
          modulePermissions={userPermissions}
          otherModulePermissions={otherModulePermissions}>
          {children}
        </PermissionWrapper>
      )}
      {displayContent === 'DENIED' && <UnauthorizedPage />}
      {displayContent === 'NONE' && <></>}
    </>
  );
};

export default RouteGuard;
