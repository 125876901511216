import { useState } from 'react';
import { BoxContainer, TextField } from '@components/common';
import { useFormContext, Controller } from 'react-hook-form';
import { Button, IconNode } from '@components/base';
import Images from '@assets/images';
import { FieldValue } from '@helpers/types/coa';
import css from './index.module.scss';

interface FieldHeaderStates {
  data: FieldValue[];
  remove: (index?: number | number[]) => void;
  handleAddField: () => void;
  onSubmit: () => void;
}

const FieldHeader = (props: FieldHeaderStates) => {
  const { data, remove, handleAddField, onSubmit } = props;
  const [disableForm, setDisableForm] = useState(true);

  const formSubmitHandler = () => {
    onSubmit();
    setDisableForm(true);
  };

  return (
    <BoxContainer title="Table Column Names" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.fieldDataWrapper}>
          {data.map((item: any, index: number) => (
            <FieldDataItem
              onDeleteClick={() => remove(index)}
              key={item.id}
              fieldNameIndex={`item_details_skeleton.${index}.field_name`}
              showDelete={data.length > 1}
              disable={disableForm}
            />
          ))}
          <div className={css.btnContainer}>
            {disableForm ? (
              <Button variant="outlined-secondary" onClick={() => setDisableForm(false)}>
                Edit
              </Button>
            ) : (
              <div className={css.groupedBtn}>
                <Button
                  variant="text"
                  onClick={handleAddField}
                  startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
                  Add Field
                </Button>
                <Button variant="contained" onClick={formSubmitHandler}>
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </BoxContainer>
  );
};

interface FieldDataItemStates {
  onDeleteClick: () => void;
  fieldNameIndex: string;
  showDelete: boolean;
  disable: boolean;
}

const FieldDataItem = (props: FieldDataItemStates) => {
  const { onDeleteClick, fieldNameIndex, showDelete, disable } = props;
  const { control } = useFormContext<any>();
  return (
    <>
      <div className={css.fieldDataItem}>
        <Controller
          name={fieldNameIndex}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              type="text"
              inputMode="text"
              enterKeyHint="next"
              placeholder="Enter Field Name"
              error={fieldState.invalid}
              disabled={disable}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
              helperText={fieldState.error?.message}
            />
          )}
        />
        {showDelete && !disable ? (
          <Button
            variant="text"
            title="Delete Item"
            onClick={onDeleteClick}
            startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}></Button>
        ) : (
          <Button
            variant="text"
            title="Delete Item"
            disabled
            startIcon={<IconNode src={Images.delete} alt="delete icon" />}></Button>
        )}
      </div>
    </>
  );
};

export default FieldHeader;
