import { Button } from '@components/base';
import Typography from '@components/base/typography';
import { BoxContainer } from '@components/common';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import BankDetails from './components/bankdetails';
import Invoice from './components/invoice';
import Item from './components/item';
import Order from './components/order';
import Parties from './components/parties';
import PurchaseDetails from './components/purchaseDetails';
import Remarks from './components/remarks';
import css from './index.module.scss';

interface IPreview {
  previewData: PerformaInvoiceStates;
  handleFormSubmit: () => void;
  handleCancelClick: () => void;
  handleBackClick: () => void;
  setOrderState: (orderState: PerformaInvoiceStates) => void;
  isRectify: boolean;
}

const PIReview = (props: IPreview) => {
  const {
    previewData,
    handleFormSubmit,
    handleCancelClick,
    handleBackClick,
    setOrderState,
    isRectify
  } = props;
  const { orderInfo, rectify_remarks } = previewData;

  return (
    <div className={css.mainWrapper}>
      {isRectify && (
        <div className={css.rectifyContainer}>
          <Typography>Reasons to reject this PI</Typography>
          {rectify_remarks?.split(',').map((val, index) => {
            return (
              <Typography variant="p" key={index}>
                {val}
              </Typography>
            );
          })}
        </div>
      )}
      <BoxContainer title="Proforma Invoice">
        <Invoice orderInfoData={orderInfo} setOrderState={setOrderState} />
        <Parties partiesData={previewData} setOrderState={setOrderState} />
        <Item itemData={previewData} setOrderState={setOrderState} />
        <Order previewData={previewData} setOrderState={setOrderState} />
        <BankDetails previewData={previewData} setOrderState={setOrderState} />
        <PurchaseDetails purchaseOrderData={previewData} setOrderState={setOrderState} />
        <Remarks previewData={previewData} setOrderState={setOrderState} />
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button variant="outlined-secondary" onClick={handleBackClick}>
            Back
          </Button>
          <Button type="submit" onClick={handleFormSubmit}>
            Save and send for approval
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PIReview;
