import { IconNode, Loader, Typography } from '@components/base';
import css from './index.module.scss';
import { IFilteredValue, OrderState } from '../../../orderOverview';
import { getCommaSeperatedCurrencyAmount, sanitizeValue } from '@helpers/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { CURRENCY } from '@helpers/constants';
import moment from 'moment';
import RodtepAndDrawbackEdit from '../../modals/rodtepDrawbackEdit';
import Images from '@assets/images';
import { editAdhocDetailsSchema } from '@helpers/yup/add-order.schema';
import { updateOrderInfo } from '@services/order.service';
import notify from '@helpers/toastify-helper';

interface RodtepAndDrawbackProps {
  previewData: OrderState;
  filteredValue?: IFilteredValue;
  orderId?: string;
  getOrderData: () => void;
}

const RodtepAndDrawback = (props: RodtepAndDrawbackProps) => {
  const { previewData, filteredValue, orderId, getOrderData } = props;
  const { rodtepAndDrawback } = previewData;

  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const editForm = useForm<any>({
    defaultValues: {
      rodtep_currency: null,
      rodtep_amount: '',
      rodtep_credit_date: null,
      rodtep_percentage_claimed: '',
      drawback_currency: null,
      drawback_amount: '',
      drawback_credit_date: null
    },
    resolver: yupResolver(editAdhocDetailsSchema)
  });

  const { setValue } = editForm;

  const addAdditionalClass = (key: string) => {
    return filteredValue?.key == key ? css.filtered : '';
  };

  useEffect(() => {
    if (editMode) {
      editForm.reset();
      setValue(
        'rodtep_currency',
        CURRENCY?.find((currency) => currency.value === rodtepAndDrawback.rodtep_currency) ??
          CURRENCY[0]
      );
      setValue('rodtep_amount', rodtepAndDrawback.rodtep_amount);
      setValue(
        'rodtep_credit_date',
        rodtepAndDrawback.rodtep_credit_date ? new Date(rodtepAndDrawback.rodtep_credit_date) : null
      );
      setValue('rodtep_percentage_claimed', rodtepAndDrawback.rodtep_percentage_claimed);
      setValue(
        'drawback_currency',
        CURRENCY?.find((currency) => currency.value === rodtepAndDrawback.drawback_currency) ??
          CURRENCY[0]
      );
      setValue('drawback_amount', rodtepAndDrawback.drawback_amount);
      setValue(
        'drawback_credit_date',
        rodtepAndDrawback.drawback_credit_date
          ? new Date(rodtepAndDrawback.drawback_credit_date)
          : null
      );
    }
  }, [rodtepAndDrawback, editMode]);

  const submitFormHandler = async (data: any) => {
    setIsLoading(true);
    const body = {
      ...data,
      rodtep_amount: data.rodtep_amount,
      rodtep_percentage_claimed: data.rodtep_percentage_claimed ?? 0,
      rodtep_currency: data.rodtep_currency ? data.rodtep_currency?.value : null,
      drawback_currency: data.drawback_currency ? data.drawback_currency?.value : null,
      drawback_amount: data.drawback_amount,
      rodtep_credit_date: data.rodtep_credit_date
        ? moment(data.rodtep_credit_date).format('YYYY-MM-DD')
        : null,
      drawback_credit_date: data.drawback_credit_date
        ? moment(data.drawback_credit_date).format('YYYY-MM-DD')
        : null
    };
    const response = await updateOrderInfo(orderId ?? '', body);
    setIsLoading(false);
    if (response?.success) {
      setEditMode(false);
      editForm.reset();
      getOrderData();
      notify({
        message: 'Data updated successfully',
        severity: 'success'
      });
    } else {
      notify({
        message: response?.message ?? 'Something went wrong',
        severity: 'error'
      });
    }
  };

  return (
    <div className={css.detailWrapper}>
      <div className={css.detailsTitle}>
        <Typography variant="h4">Rodtep And Drawback</Typography>
        <IconNode
          src={Images.editDark}
          alt="edit icon"
          component="button"
          className={css.editButton}
          onClick={() => setEditMode(true)}
        />
      </div>
      <div className={css.detailContainer}>
        {/* {dataToShow?.map((item: any, index: number) => {
          return (
           
          );
        })} */}
        <div className={css.rowWrapper}>
          <div className={`${css.fieldWrapper} ${addAdditionalClass(`rodtepAmount`)}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass(`rodtepAmount`)}`}>
              Rodtep Amount
            </Typography>
            <div className={css.fieldValueContainer}>
              <>
                {rodtepAndDrawback?.rodtep_amount ? (
                  <Typography variant="body" className={`${addAdditionalClass(`rodtepAmount`)}`}>
                    {getCommaSeperatedCurrencyAmount(
                      rodtepAndDrawback?.rodtep_currency,
                      rodtepAndDrawback.rodtep_amount
                    )}
                  </Typography>
                ) : (
                  <Typography
                    variant="label"
                    className={`${css.notAvailable} ${addAdditionalClass(`rodtepAmount`)}`}>
                    Not Available
                  </Typography>
                )}
              </>
            </div>
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={`${css.fieldWrapper} ${addAdditionalClass(`rodtepCreditDate`)}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass(`rodtepCreditDate`)}`}>
              Rodtep Credit Date
            </Typography>
            <div className={css.fieldValueContainer}>
              <>
                {rodtepAndDrawback?.rodtep_credit_date ? (
                  <Typography
                    variant="body"
                    className={`${addAdditionalClass(`rodtepCreditDate`)}`}>
                    {sanitizeValue(
                      moment(rodtepAndDrawback?.rodtep_credit_date).format('DD MMM YYYY')
                    )}
                  </Typography>
                ) : (
                  <Typography
                    variant="label"
                    className={`${css.notAvailable} ${addAdditionalClass(`rodtepCreditDate`)}`}>
                    Not Available
                  </Typography>
                )}
              </>
            </div>
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={`${css.fieldWrapper} ${addAdditionalClass(`rodtepPercentageClaimed`)}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass(`rodtepPercentageClaimed`)}`}>
              Rodtep Amount Claimed
            </Typography>
            <div className={css.fieldValueContainer}>
              <>
                {rodtepAndDrawback?.rodtep_amount_claimed &&
                parseInt(rodtepAndDrawback?.rodtep_amount_claimed) > 0 ? (
                  <Typography
                    variant="body"
                    className={`${addAdditionalClass(`rodtepPercentageClaimed`)}`}>
                    {rodtepAndDrawback?.rodtep_amount_claimed}
                  </Typography>
                ) : (
                  <Typography
                    variant="label"
                    className={`${css.notAvailable} ${addAdditionalClass(
                      `rodtepPercentageClaimed`
                    )}`}>
                    Not Available
                  </Typography>
                )}
              </>
            </div>
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={`${css.fieldWrapper} ${addAdditionalClass(`drawbackAmount`)}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass(`drawbackAmount`)}`}>
              Drawback Amount
            </Typography>
            <div className={css.fieldValueContainer}>
              <>
                {rodtepAndDrawback?.drawback_amount ? (
                  <Typography variant="body" className={`${addAdditionalClass(`drawbackAmount`)}`}>
                    {getCommaSeperatedCurrencyAmount(
                      rodtepAndDrawback?.drawback_currency,
                      rodtepAndDrawback?.drawback_amount
                    )}
                  </Typography>
                ) : (
                  <Typography
                    variant="label"
                    className={`${css.notAvailable} ${addAdditionalClass(`drawbackAmount`)}`}>
                    Not Available
                  </Typography>
                )}
              </>
            </div>
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={`${css.fieldWrapper} ${addAdditionalClass(`drawbackCreditDate`)}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass(`drawbackCreditDate`)}`}>
              Drawback Credit Date
            </Typography>
            <div className={css.fieldValueContainer}>
              <>
                {rodtepAndDrawback?.drawback_credit_date ? (
                  <Typography
                    variant="body"
                    className={`${addAdditionalClass(`drawbackCreditDate`)}`}>
                    {sanitizeValue(
                      moment(rodtepAndDrawback?.drawback_credit_date).format('DD MMM YYYY')
                    )}
                  </Typography>
                ) : (
                  <Typography
                    variant="label"
                    className={`${css.notAvailable} ${addAdditionalClass(`drawbackCreditDate`)}`}>
                    Not Available
                  </Typography>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
      <Loader open={isLoading} />
      <FormProvider {...editForm}>
        <RodtepAndDrawbackEdit
          open={editMode}
          onClose={() => setEditMode(false)}
          onFormSubmit={submitFormHandler}
        />
      </FormProvider>
    </div>
  );
};

export default RodtepAndDrawback;
