import { useCallback, useEffect, useMemo, useState } from 'react';
import ActivityCard from './activity-card';
import { Chip } from '@components/base';
import PendingTasks from '../pending-tasks';

import css from './index.module.scss';
import AccessWrapper from '@authorization/access-wrapper';
import { USER_TYPES } from '@helpers/constants';
import { useSearchParams } from 'react-router-dom';

interface ActivityLogMenuItem {
  value: number;
  key: string;
  default?: boolean;
}

interface ActivityLogTabProps {
  orderId: string;
  actions: IActions;
  orderProgressMapping: ActivityLogMenuItem[];
}

const ActivityLogTab = (props: ActivityLogTabProps) => {
  const { orderId, actions, orderProgressMapping: ORDER_PROGRESS_MAPPING } = props;
  function getValueByKey(key: string) {
    const task = ORDER_PROGRESS_MAPPING.find((item) => item.key === key);
    return task ? task.value : 1;
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const activeSubTabParam = searchParams.get('activeSubTab');
  const getDefaultTab = useCallback( () => {
    const defaultTab = ORDER_PROGRESS_MAPPING.find((tab:ActivityLogMenuItem) => tab.default);
    return defaultTab ? defaultTab : ORDER_PROGRESS_MAPPING[0];
  }, [ORDER_PROGRESS_MAPPING]);
  const defaultTab = useMemo(() => getDefaultTab(), []);  
  const initialOrderState = useMemo(() => {
    return getValueByKey(activeSubTabParam || defaultTab.key);
  }, [activeSubTabParam]);
  const [state, setState] = useState(initialOrderState);
  
  const handleTabChange = (state: number) => {
    setState(state);
    const queryKey = ORDER_PROGRESS_MAPPING.find((item:ActivityLogMenuItem) => item.value === state);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      activeSubTab: queryKey?.key ?? defaultTab.key
    });
  };

  return (
    <div>
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]}>
        <div className={css.taskChipWrapper}>
          <Chip
            label="Activity"
            variant={state == 0 ? `filled` : `outlined`}
            onClick={() => handleTabChange(0)}
          />

          <Chip
            label="Pending tasks"
            variant={state == 1 ? `filled` : `outlined`}
            onClick={() => handleTabChange(1)}
          />
        </div>
      </AccessWrapper>
      {state === 0 && <ActivityCard />}
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]}>
        {state === 1 && <PendingTasks orderId={orderId} />}
      </AccessWrapper>
    </div>
  );
};

export default ActivityLogTab;
