import { Typography } from '@components/base';
import css from '../index.module.scss';
import { sanitizeValue } from '@helpers/utils';
import { ITaskItemProps } from '@helpers/types/task-tabs';

interface IOrderProps {
  data: ITaskItemProps;
}

const Order = (props: IOrderProps) => {
  const { data } = props;
  const {
    vessel_name,
    voyage_number,
    port_of_discharge,
    port_of_loading,
    net_weight,
    gross_weight,
    transportation_mode
  } = data;
  return (
    <div className={css.boxWrapper}>
      <div className={css.orderDetailsContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Order Details</Typography>
        </div>
        {transportation_mode === 'SEA' && (
          <div className={css.fieldWrapper}>
            <div>
              <Typography variant="label">Vessel</Typography>
              <Typography variant="body">{sanitizeValue(vessel_name, 'Not Available')}</Typography>
            </div>
            <div>
              <Typography variant="label">Voyage Number</Typography>
              <Typography variant="body">
                {sanitizeValue(voyage_number, 'Not Available')}
              </Typography>
            </div>
          </div>
        )}
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Place of Discharge</Typography>
            <Typography variant="body">
              {sanitizeValue(port_of_discharge, 'Not Available')}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Port Of Loading</Typography>
            <Typography variant="body">
              {sanitizeValue(port_of_loading, 'Not Available')}
            </Typography>
          </div>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Net Weight (KG)</Typography>
            <Typography variant="body">{net_weight}</Typography>
          </div>
          <div>
            <Typography variant="label">Gross Weight (KG)</Typography>
            <Typography variant="body">{gross_weight}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
