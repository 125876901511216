import { Fragment, ReactNode } from 'react';
import css from './index.module.scss';
import { Typography, Image } from '@components/base';
import { useDropzone } from 'react-dropzone';
import Images from '@assets/images';
import { MAX_FILE_SIZE } from '@helpers/constants';

interface DragDropUploadProps {
  onDrop?: (files: any[]) => void;
  multiple?: boolean;
  acceptImages?: boolean;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  helperText?: string;
  label?: ReactNode;
  rootClassName?: string;
}

function DragDropUpload(props: DragDropUploadProps) {
  const {
    onDrop,
    multiple = false,
    acceptImages = false,
    disabled = false,
    error,
    required = false,
    helperText,
    label,
    rootClassName
  } = props;
  const handleDrop = (acceptedFiles: any[]) => {
    onDrop?.(acceptedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: multiple,
    onDrop: handleDrop,
    maxFiles: multiple ? 5 : 1,
    maxSize: MAX_FILE_SIZE,
    accept: {
      'application/pdf': ['.pdf'],
      'application/doc': ['.doc'],
      'application/ms-doc': ['.doc'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'image/png': ['.png', '.jpeg', '.jpg']
    }
  });
  const helperTextClass = error ? 'helperTextError' : undefined;
  const showAsterisk = required ? ' *' : undefined;

  return (
    <div className={`${css.DragDropUpload} ${rootClassName}`}>
      {label && (
        <span className={`${css.DragDropLabel}`} data-value={showAsterisk}>
          {label}
        </span>
      )}
      <div {...getRootProps()} className={css.uploadWrapper}>
        <Image src={Images.upload} alt="upload icon" className={css.uploadIcon} />
        <div className={css.uploadTextWrapper}>
          <div className={css.uploadtextContainer}>
            {!isDragActive ? (
              <Fragment>
                <Typography variant="span" className={css.boldUploadText}>
                  Click to Upload
                </Typography>
                <Typography variant="span">or drag and drop</Typography>
              </Fragment>
            ) : (
              <Typography variant="span" className={css.boldUploadText}>
                Drop file here
              </Typography>
            )}
          </div>
          <Typography variant="span">
            .doc, .docx, .pdf{acceptImages ? ', .png, .jpg, .jpeg' : ''} (Max 5MB)
          </Typography>
        </div>
        <input {...getInputProps()} type="file" disabled={disabled} className={css.uploadField} />
      </div>
      {error && <span className={`${css.fieldHelperText} ${helperTextClass}`}>{helperText}</span>}
    </div>
  );
}

export default DragDropUpload;
