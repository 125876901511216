import css from './index.module.scss';

interface IRenderChildrenBasedOnScreenProps {
  showOnMobile?: boolean;
  children: React.ReactNode;
  showOnWeb?: boolean;
}

const RenderChildrenBasedOnScreen = (props: IRenderChildrenBasedOnScreenProps) => {
  const { showOnMobile, children } = props;
  return <span className={showOnMobile ? css.showOnMobile : css.showOnWeb}>{children}</span>;
};

export default RenderChildrenBasedOnScreen;
