import { HTMLAttributes, memo, ReactNode, MouseEvent, useMemo, useCallback } from 'react';
import Typography from '../typography';
import { ReactComponent as CloseIcon } from '@assets/images/cross-black.svg';
import './index.style.scss';
import { chipVariantType } from '@helpers/types';
import PropTypes from 'prop-types';

interface ChipProps extends HTMLAttributes<HTMLDivElement> {
  variant?: chipVariantType;
  label: ReactNode;
  className?: string;
  showCloseIcon?: boolean;
  onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  iconProps?: HTMLAttributes<HTMLButtonElement>;
  clickable?: boolean;
  onClick?: () => void;
}

function Chip(props: ChipProps) {
  const {
    startIcon,
    endIcon,
    label,
    variant,
    showCloseIcon,
    className,
    onClose,
    iconProps,
    clickable,
    onClick,
    ...otherProps
  } = props;

  const handleCloseClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { onClose, clickable } = props;
      clickable && event.stopPropagation();
      onClose?.(event);
    },
    [props.onClose, props.clickable]
  );

  const rootClickableClass = useMemo(
    () => (clickable ? 'chip-clickable' : null),
    [props.clickable]
  );

  return (
    <>
      {label ? (
        <div
          className={`chip-root chip-${variant} ${rootClickableClass} ${className}`}
          onClick={onClick}
          {...otherProps}>
          {startIcon}
          <Typography variant="span" className="chip-label">
            {label}
          </Typography>
          {endIcon}
          {showCloseIcon && (
            <button className="close-icon-wrapper" onClick={handleCloseClick} {...iconProps}>
              <CloseIcon className="close-icon" />
            </button>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

Chip.defaultProps = {
  variant: 'outlined',
  showCloseIcon: false,
  clickable: true
};

Chip.propTypes = {
  variant: PropTypes.oneOf([
    'filled',
    'outlined',
    'filled-blue',
    'filled-red',
    'filled-yellow',
    'filled-green'
  ]),
  showCloseIcon: PropTypes.bool,
  className: PropTypes.string,
  clickable: PropTypes.bool
};

export default memo(Chip);
