import { useMemo, useState } from 'react';
import css from './index.module.scss';
import { Button, IconNode, Typography } from '@components/base';
import {
  DataGrid,
  DocumentRow,
  NoDocumentPlaceholder,
  TableSkeleton,
  UploadDocument
} from '@components/common';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDocument } from '@helpers/yup';
import Images from '@assets/images';
import { CUSTOMER_DOCUMENT_TYPE } from '@helpers/constants';
import { ICustomerInfo } from '@helpers/types/customer';
import { DocumentForm, Document } from '@helpers/types/document';
import { deleteDocument, documentUpload, updateDocument } from '@services/customer.service';
import notify from '@helpers/toastify-helper';
import { ISelect } from '@helpers/types';
import { CellProps, Column } from 'react-table';

interface CustomerDocumentTabStates {
  editable: boolean;
  modalState: boolean;
  isLoading: boolean;
}

interface CustomerDocumentTabProps {
  customerInfo: ICustomerInfo;
  onChange?: (parama: ICustomerInfo) => void;
}

interface DocumentGrid {
  document_name: Document;
  proofs: Document;
  document_object: Document;
}

const CustomerDocumentTab = (props: CustomerDocumentTabProps) => {
  const { customerInfo } = props;
  const [customerState, setCustomerState] = useState<CustomerDocumentTabStates>({
    editable: false,
    modalState: false,
    isLoading: false
  });
  const { editable, modalState, isLoading } = customerState;

  const documentForm = useForm<DocumentForm>({
    resolver: yupResolver(addDocument),
    defaultValues: {
      document_id: '',
      document_type: null,
      document_name: '',
      document_object: null
    },
    shouldUnregister: true
  });

  const {
    setValue,
    formState: { isSubmitting }
  } = documentForm;

  const availableDocumentType = useMemo(() => {
    const { customerInfo } = props;
    const { documents } = customerInfo;
    const availDoc: ISelect[] = [...CUSTOMER_DOCUMENT_TYPE];
    documents.forEach((doc) => {
      const docAvail = CUSTOMER_DOCUMENT_TYPE.find((item) => item.value === doc.document_type);
      if (docAvail && availDoc.includes(docAvail) && docAvail.value !== 'OTHER') {
        const index = availDoc.indexOf(docAvail);
        availDoc.splice(index, 1);
      }
    });
    return availDoc;
  }, [props.customerInfo]);

  const handleFormSubmit: SubmitHandler<DocumentForm> = async (data) => {
    const { customerInfo, onChange } = props;
    const { editable } = customerState;
    const formData = new FormData();
    formData.append('document_type', data.document_type!.value);
    formData.append('document_name', data.document_name);
    formData.append('document_object', data.document_object as Blob);
    setCustomerState((prevState) => ({ ...prevState, isLoading: true }));
    if (!editable) {
      const response = await documentUpload(customerInfo.customer_id, formData);
      if (response.success) {
        const { data } = response;
        const { documents } = customerInfo;
        notify({ message: 'Document added successfully', dismissible: true });
        documents.unshift(data);
        onChange?.({ ...customerInfo, documents: documents });
        toggleModalState();
      } else if (response.error) {
        notify({ message: response.error, dismissible: true });
      } else {
        notify({ message: 'Unable to add document', dismissible: true });
      }
      setCustomerState((prevState) => ({ ...prevState, isLoading: false }));
    } else {
      const response = await updateDocument(data.document_id, formData);
      if (response.success) {
        const { data } = response;
        const { documents } = customerInfo;
        const currentDoc = documents.findIndex((doc) => doc.document_id === data.document_id);
        if (currentDoc !== -1) {
          documents[currentDoc] = data;
          onChange?.(customerInfo);
          notify({ message: 'Document updated successfully', dismissible: true });
          toggleModalState();
        } else if (response.error) {
          notify({ message: response.error, dismissible: true });
        } else {
          notify({ message: 'Unable to add document', dismissible: true });
        }
      }
      setCustomerState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const toggleModalState = () => {
    setCustomerState((prevState) => ({
      ...prevState,
      modalState: !prevState.modalState,
      editable: false
    }));
  };

  const handleReupload = (document: Document) => () => {
    const documentType = CUSTOMER_DOCUMENT_TYPE.find((doc) => doc.value === document.document_type);
    if (documentType) {
      setCustomerState((prevState) => ({
        ...prevState,
        modalState: true,
        editable: true
      }));
      setValue('document_id', document.document_id, { shouldDirty: false });
      setValue('document_type', documentType, { shouldDirty: false });
      setValue('document_name', document.document_name, { shouldDirty: false });
      setValue('document_object', null, { shouldDirty: false });
    }
  };

  const handleDocumentDelete = (document: Document) => async () => {
    const { customerInfo, onChange } = props;
    setCustomerState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await deleteDocument(document.document_id);
    setCustomerState((prevState) => ({ ...prevState, isLoading: false }));
    if (response.data) {
      const { documents } = customerInfo;
      const deletedDocIndex = documents.findIndex(
        (doc) => doc.document_id === document.document_id
      );
      documents.splice(deletedDocIndex, 1);
      onChange?.({ ...customerInfo, documents: documents });
      notify({ message: 'Document deleted successfully', dismissible: true });
    } else {
      notify({ message: response.error ?? 'Unable to delete document', dismissible: true });
    }
  };

  const [documentColumn, documentData] = useMemo(() => {
    const { customerInfo } = props;
    const { documents } = customerInfo;
    const column: Column<DocumentGrid>[] = [
      {
        Header: 'Document Name',
        accessor: 'document_name',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          const documentType = value.display_name;
          if (documentType)
            return (
              <DocumentRow.Title title={documentType} documentAvailable={value.document_object} />
            );
          return null;
        }
      },
      {
        Header: 'Proofs',
        accessor: 'proofs',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return (
            <DocumentRow.Upload
              documentAvailable={value.document_object}
              onClick={handleReupload(value)}
            />
          );
        }
      },
      {
        Header: '',
        accessor: 'document_object',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return (
            <DocumentRow.Action
              document={value.document_object}
              title={value.document_name}
              onClick={handleDocumentDelete(value)}
            />
          );
        }
      }
    ];
    const row: DocumentGrid[] = documents.map((doc) => ({
      document_name: doc,
      proofs: doc,
      document_object: doc
    }));
    return [column, row];
  }, [props.customerInfo]);

  const { documents } = customerInfo;

  return (
    <section className={css.mainWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="h4">Documents</Typography>
        <Button
          disabled={!availableDocumentType.length}
          variant="text"
          onClick={toggleModalState}
          startIcon={<IconNode src={Images.plusRed} alt="plus icon" />}>
          Upload Document
        </Button>
      </div>
      {
        isLoading || isSubmitting ? (
          <TableSkeleton />
        ) : documents.length ? (
          <DataGrid columns={documentColumn} data={documentData} />
      ) : (
        !isLoading && <NoDocumentPlaceholder
          title="No Customer’s Documents Added Yet"
          onClick={toggleModalState}
          disabled={!availableDocumentType.length}
        />
      )}
      <FormProvider {...documentForm}>
        {modalState && (
          <UploadDocument
            open={modalState}
            editMode={editable}
            onFormSubmit={handleFormSubmit}
            onClose={toggleModalState}
            availableDocumentType={availableDocumentType}
          />
        )}
      </FormProvider>
    </section>
  );
};

export default CustomerDocumentTab;
