export function encode(data: string) {
  try {
    return window.btoa(data);
  } catch (error: unknown) {
    return null;
  }
}

export function decode(data: string) {
  try {
    return window.atob(data);
  } catch (error: unknown) {
    return null;
  }
}

export function storeDataInSession(key: string, value: string) {
  try {
    window.sessionStorage.setItem(key, value);
  } catch (error) {
    console.warn('Failed to store');
  }
}

export function getDataFromSession(key: string) {
  try {
    return window.sessionStorage.getItem(key);
  } catch (error: unknown) {
    console.warn('Failed to retrieve data from session storage');
    return null;
  }
}

export function storeDataInLocalStorage(key: string, value: string) {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    console.warn('Failed to store');
  }
}

export function getDataFromLocalStorage(key: string) {
  try {
    return window.localStorage.getItem(key);
  } catch (error: unknown) {
    console.warn('Failed to retrieve data from local storage');
    return null;
  }
}

export function getToken(type: 'access' | 'refresh' | 'permissions') {
  const tokenType = `${type}-token`;
  const encodedAccessToken = getDataFromLocalStorage(tokenType);
  return encodedAccessToken ? decode(encodedAccessToken) : encodedAccessToken;
}

export function setToken(type: 'access' | 'refresh' | 'permissions', value: any) {
  const tokenType = `${type}-token`;
  const accessToken = encode(value);
  storeDataInLocalStorage(tokenType, `${accessToken}`);
}

export function clearToken(type: 'access' | 'refresh' | 'permissions') {
  const tokenType = `${type}-token`;
  localStorage.removeItem(tokenType);
}

export function parseJWT(token: any) {
  if (!token) return null;
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    console.warn('Failed to parse JWT');
    return null;
  }
}
