import { useCallback } from 'react';
import css from './index.module.scss';
import { Button, IconNode, Loader, Modal, Typography } from '@components/base';
import { DragDropUpload, TextField } from '@components/common';
import { useForm, Controller, useWatch, SubmitHandler } from 'react-hook-form';
import Images from '@assets/images';
import { yupResolver } from '@hookform/resolvers/yup';
import { customerAcceptanceProof } from '@helpers/yup/upload-customer-acceptance.schema';
import notify from '@helpers/toastify-helper';
import { uploadBlDraft } from '@services/task.service';

interface FormType {
  bl_draft: File | null;
  remarks: string;
}

interface AddPaymentDetailsProps {
  open: boolean;
  onClose?: () => void;
  taskId: string;
  sendStatusToCard: (arg: boolean) => void;
  onComplete?: () => void;
  taskName?: string;
}

const UploadDocModal = (props: AddPaymentDetailsProps) => {
  const { open, onClose, sendStatusToCard, onComplete, taskName } = props;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors }
  } = useForm<FormType>({
    resolver: yupResolver(customerAcceptanceProof),
    defaultValues: {
      bl_draft: null,
      remarks: ''
    }
  });
  
  const watch = useWatch({ name: 'bl_draft', control });

  const handleFileSelect = (file: any[]) => {
    if (file.length) {
      setValue('bl_draft', file[0]);
    }
  };

  const handleDeleteFile = useCallback(() => {
    setValue('bl_draft', null);
  }, []);

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const { onClose, taskId } = props;
    if (!data?.bl_draft) {
      notify({ message: `Please ${taskName} Document`, severity: 'error' });
      return;
    }
    const reqBody = new FormData();
    data.bl_draft && reqBody.append('draft_bl', data.bl_draft);
    data.remarks && reqBody.append('remarks', data.remarks);
    const response = await uploadBlDraft(taskId, reqBody);
    if (response.success) {
      notify({ message: `${taskName} Uploaded Successfully` });
      sendStatusToCard(true);
      if (onComplete) {
        onComplete();
      }
      onClose?.();
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else notify({ message: 'Some error occurred', severity: 'error' });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Upload {taskName}</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={css.formWrapper}>
          <div className={css.fieldsWrapper}>
            <label htmlFor="Acceptance">
              <Typography variant="p" className={css.uploadWrapper}>
                Upload Proof
              </Typography>
              <Controller
                name="bl_draft"
                control={control}
                render={() => <DragDropUpload onDrop={handleFileSelect} />}
              />
              {watch && (
                <div className={css.fileUploadState}>
                  <Typography variant="p" className={css.fileName}>
                    {watch.name}
                  </Typography>
                  <IconNode
                    src={Images.deleteRed}
                    alt="delete icon"
                    component="button"
                    className={css.deleteButton}
                    onClick={handleDeleteFile}
                  />
                </div>
              )}
            </label>

            <Controller
              name="remarks"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  inputMode="text"
                  label="Remarks"
                  placeholder="Add remarks if any"
                  rootClassName={css.inputFieldWrapper}
                />
              )}
            />
          </div>
          <div className={css.actionButtonWrapper}>
            <Button variant="outlined-secondary" onClick={onClose} className={css.actionButton}>
              Cancel
            </Button>
            <Button type="submit" className={css.actionButton}>
              Save
            </Button>
          </div>
        </form>
        <Loader open={isSubmitting} />
      </div>
    </Modal>
  );
};

export default UploadDocModal;
