import Images from '@assets/images';
import { IconNode, Typography } from '@components/base';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import css from '../index.module.scss';

interface IRemark {
  previewData: PerformaInvoiceStates;
  setOrderState: (orderState: PerformaInvoiceStates) => void;
}

const Remarks = (props: IRemark) => {
  const { previewData, setOrderState } = props;
  const { remarksInfo, remarks, orderDetails } = previewData;
  const { data } = remarksInfo;
  const { editRed } = Images;

  const isBlOrAwb = orderDetails?.transportation_mode === 'AIR' ? 'AWB' : 'BL';

  return (
    <div className={css.boxWrapper}>
      <div className={css.remarkBoxContainer}>
        <div className={css.fieldWrapper}>
          <Typography variant="pdoc">Standard Remarks</Typography>
          <IconNode
            className={css.editRed}
            src={editRed}
            onClick={() =>
              setOrderState({
                ...previewData,
                activeStep: 4
              })
            }
          />
        </div>
        <div className={css.fieldWrapper}>
          <ol
            className={remarks.length ? css.remarkListContainer : css.remarkListContainerNoMargin}>
            {data?.payment_transfers && (
              <li>
                All payment transfer charges outside India to be borne by Consignee {data?.name}
              </li>
            )}
            {/* {data?.pss_confirmation && (
              <li>Consignment dispatch against PSS confirmation (if order is on PSS basis)</li>
            )} */}
            {data?.free_days_at_port && (
              <li>
                {data?.field_free_days_port} days free days at port of discharge (if applicable)
              </li>
            )}
            {data?.neutral_packing && <li>Packing will be neutral (if packing is neutral)</li>}
            {data?.consignment_elchemy && (
              <li>Consignment will be on Elchemy branding (if applicable)</li>
            )}

            {data?.percent_advance_payment && (
              <li>
                {data?.field_percentage_payment} of payment is done (if advance payment is done)
              </li>
            )}
            {data?.deviation_quality_days && (
              <li>
                Any deviation in quality should be reported within {data?.field_days_deviation} days
                of receiving cargo
              </li>
            )}
            {data?.bl_switchable_port && (
              <li>{isBlOrAwb} will be switchable at {data?.field_bl_port} (if applicable)</li>
            )}
            {data?.payment_due_date && (
              <li>Payment due date is {data?.field_payment_due} (if applicable)</li>
            )}
            {data?.inspection_cost && (
              <li>
                Inspection Cost by {data?.field_inspection_cost} to be borne by Consignee (if
                applicable).
              </li>
            )}

            {remarks.length
              ? remarks?.split('\n ').map((val: string, index: number) => {
                  return <li key={index}>{val}</li>;
                })
              : 'No Remarks Added'}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Remarks;
