import { useCallback, useState, useEffect } from 'react';
import css from './index.module.scss';
import { Loader, Typography } from '@components/base';
import { Seo, SideBar, BankDetailForm, CompanyDetailForm } from '@components/common';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addCustomerSchema } from '@helpers/yup/add-customer.schema';
import { ICustomerDetailForm } from '@helpers/types/customer';
import { CLIENT_ROUTES } from '@router/routes';
import { useNavigate } from 'react-router-dom';
import {
  getCustomerFormValuesFromResponse,
  getRequestBodyForCustomerDetail,
  getRequestBodyForSupplierBankDetail
} from '@helpers/utils';
import { createCustomer, updateCustomerInfo } from '@services/customer.service';
import notify from '@helpers/toastify-helper';
import { IBankDetailForm } from '@helpers/types/supplier';
import { addBankDetailSchema } from '@helpers/yup';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { fetchAllUsers } from '@services/user.service';
import AccessWrapper from '@authorization/access-wrapper';

interface AddCustomerStates {
  activeFormValue: number;
  customerInfo: ICustomerDetailForm | null;
  userList: Array<object>;
  customerId: string | null;
}

const AddCustomer = (props: any) => {
  const { actions = {} } = props.modulePermissions;
  const [customerState, setCustomerState] = useState<AddCustomerStates>({
    activeFormValue: 0,
    customerInfo: null,
    userList: [],
    customerId: null
  });
  const { activeFormValue, customerInfo, userList, customerId } = customerState;

  useBeforeUnloadAndNavigate();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsersForPocList();
  }, []);

  const customerDetailForm = useForm<ICustomerDetailForm>({
    resolver: yupResolver(addCustomerSchema),
    defaultValues: {
      customer_id: '',
      name: '',
      number: '',
      email: '',
      website: '',
      business_type: null,
      // customer_country: null,
      same_as_office: false,
      pocs: [
        {
          user_id: '',
          given_name: '',
          family_name: '',
          email: '',
          phone_number: ''
        }
      ],
      address_id: '',
      address_line1: '',
      address_line2: '',
      address_type: 'OFC',
      zip_code: '',
      city: null,
      state: null,
      country: null,
      addresses: [
        {
          address_id: '',
          address_line1: '',
          address_line2: '',
          address_type: 'FAC',
          zip_code: '',
          city: null,
          state: null,
          country: null,
          address_suffix: null
        }
      ],
      remarks: ''
    }
  });

  const bankDetailForm = useForm<IBankDetailForm>({
    resolver: yupResolver(addBankDetailSchema),
    defaultValues: {
      bankdetails: [
        {
          bank_id: '',
          bank_name: '',
          branch_name: '',
          bank_account_holder_name: '',
          account_number: '',
          ifsc_code: '',
          swift_code: '',
          address_line1: '',
          address_line2: '',
          zip_code: '',
          city: null,
          state: null,
          country: null
        }
      ]
    }
  });

  const {
    reset: firstReset,
    formState: { isSubmitting: firstIsSubmitting }
  } = customerDetailForm;

  const {
    formState: { isSubmitting: secondIsSubmitting }
  } = bankDetailForm;

  const handleSideNavigation = (activeStep: number) => {
    const { customerInfo, activeFormValue } = customerState;
    if (activeStep === 0 && activeFormValue !== 0 && customerInfo) {
      firstReset(customerInfo, {
        keepErrors: false,
        keepDirty: false,
        keepDirtyValues: false,
        keepValues: false,
        keepDefaultValues: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false
      });
    }
    setCustomerState((prevState) => ({ ...prevState, activeFormValue: activeStep }));
  };

  const handleCancelClick = () => {
    customerId
      ? navigate(`/${CLIENT_ROUTES.customer}/${customerId}`)
      : navigate(`/${CLIENT_ROUTES.customer}`);
  };

  const handleNextClick = useCallback(() => {
    handleSideNavigation(1);
  }, [handleSideNavigation]);

  const handleBackClick = useCallback(() => {
    handleSideNavigation(0);
  }, [handleSideNavigation]);

  const handleFirstFormSubmit: SubmitHandler<ICustomerDetailForm> = async (data) => {
    const formData = getRequestBodyForCustomerDetail(data);
    if (!customerInfo) {
      const response = await createCustomer(formData);
      if (response.success) {
        const { data } = response;
        const formValues = getCustomerFormValuesFromResponse(data);
        setCustomerState((prevState) => ({
          ...prevState,
          customerInfo: formValues,
          activeFormValue: 1,
          customerId: data.customer_id
        }));
      } else if (response.error) {
        notify({ message: response.error, severity: 'error', dismissible: true });
      }
    } else {
      const response = await updateCustomerInfo(customerInfo.customer_id, formData);
      if (response.success) {
        const { data } = response;
        notify({ message: 'Customer info has been updated successfully!', dismissible: true });
        const formValues = getCustomerFormValuesFromResponse(data);
        setCustomerState((prevState) => ({
          ...prevState,
          customerInfo: formValues,
          activeFormValue: 1
        }));
      } else if (response.error) {
        notify({ message: response.error, severity: 'error', dismissible: true });
      }
    }
  };

  const fetchUsersForPocList = async () => {
    const userList = await fetchAllUsers({
      role: 'external',
      unassigned: true
    });
    if (userList?.success) {
      setCustomerState((prevState) => ({ ...prevState, userList: userList.data.users }));
    } else if (userList?.error) {
      notify({ message: userList.error, severity: 'error', dismissible: true });
    } else {
      notify({ message: 'Unable to fetch users', severity: 'error', dismissible: true });
    }
  };

  const handleSecondFormSubmit: SubmitHandler<IBankDetailForm> = async (data) => {
    if (customerInfo) {
      const reqBody = getRequestBodyForSupplierBankDetail(data);
      const response = await updateCustomerInfo(customerInfo.customer_id, reqBody);
      if (response.success) {
        notify({ message: 'A new customer has been added successfully!', dismissible: true });
        navigate(`/${CLIENT_ROUTES.customer}/${response.data.customer_id}`);
      } else
        notify({
          message: response.error ?? 'Please try again with valid data',
          title: 'Form validation failed',
          severity: 'error',
          dismissible: true
        });
    }
  };

  return (
    <AccessWrapper show={actions?.create} showUnauthorised>
      <main className={css.mainWrapper}>
        <Seo title="Add Customer" />
        <section className={css.headerWrapper}>
          <Typography variant="h2">Add Customer</Typography>
          <Typography variant="span">0{activeFormValue + 1} of 02</Typography>
        </section>
        <section className={css.subMainWrapper}>
          <div className={css.sidebarWrapper}>
            <SideBar activeStep={activeFormValue} onClick={handleSideNavigation}>
              <SideBar.Item label="Customer Details" value={0} />
              <SideBar.Item label="Bank Details" value={1} disabled={!customerInfo} />
            </SideBar>
          </div>
          <FormProvider {...customerDetailForm}>
            {activeFormValue === 0 && (
              <CompanyDetailForm
                onCancelClick={handleCancelClick}
                onNextClick={handleNextClick}
                onFormSubmit={handleFirstFormSubmit}
                enableEditMode={!!customerInfo}
                userList={userList}
              />
            )}
          </FormProvider>
          <FormProvider {...bankDetailForm}>
            {activeFormValue === 1 && (
              <BankDetailForm
                onFormSubmit={handleSecondFormSubmit}
                onBackClick={handleBackClick}
                onCancelClick={handleCancelClick}
              />
            )}
          </FormProvider>
        </section>
        <Loader open={firstIsSubmitting || secondIsSubmitting} />
      </main>
    </AccessWrapper>
  );
};

export default AddCustomer;
