import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import css from './index.module.scss';

const ToolTip = (props: TooltipProps) => {
  const { children, title, ...otherProps } = props;
  return (
    <Tooltip title={title} {...otherProps}>
      {children}
    </Tooltip>
  );
};

export default ToolTip;
export type { TooltipProps as ToolTipProps };
