import React from 'react';
import { Skeleton } from '@mui/material';
import css from './index.module.scss';

const Enquiry = () => {
  return (
    <main className={css.enquiryWrapper}>
      <div className={css.enquiryHeaderWrapper}>
        <div className={css.enquiryHeader}>
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
        </div>
        <Skeleton variant="rectangular" width={120} height={36} />
      </div>
      <section className={css.customerEnquiryWebContainer}>
        <div className={css.customerEnquiry}>
          <div className={css.customerEnquiryCardWrapper}>
            {[...Array(5)].map((_, index) => (
              <CustomerEnquiryCard key={index} />
            ))}
          </div>
          <div className={css.customerEnquiryViewCreateEdit}>
            <Skeleton variant="rectangular" width="100%" height={400} />
          </div>
        </div>
      </section>
      <section className={css.customerEnquiryMobileContainer}>
        <div className={css.customerMobileWrapper}>
          {[...Array(3)].map((_, index) => (
            <CustomerEnquiryCard key={index} />
          ))}
        </div>
      </section>
    </main>
  );
};

const CustomerEnquiryCard = () => (
  <div className={css.cardSkeleton}>
    <Skeleton variant="text" width="60%" height={24} />
    <Skeleton variant="text" width="40%" height={20} />
    <Skeleton variant="text" width="80%" height={20} />
    <div className={css.cardFooter}>
      <Skeleton variant="text" width="30%" height={20} />
      <Skeleton variant="circular" width={24} height={24} />
    </div>
  </div>
);

export default Enquiry;
