import React from 'react';
import { Skeleton } from '@components/base';
import css from './index.module.scss';

const EnquirySkeleton = () => {
  return (
    <main className={css.enquiryWrapper}>
      <div className={css.enquiryHeaderWrapper}>
        <div className={css.enquiryHeader}>
          <Skeleton variant="text" width={150} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
        </div>
      </div>
      <section className={css.internalEnquiry}>
        <div className={css.enquiryTabWrapper}>
          <Skeleton variant="rectangular" width="100%" height={48} />
        </div>
        <div className={css.enquiryFilterWrapper}>
          <div className={css.enquirySelectFilter}>
            {[...Array(3)].map((_, index) => (
              <Skeleton key={index} variant="rectangular" width={200} height={40} />
            ))}
          </div>
          <div className={css.enquiryPaginationFilter}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={80} height={24} />
            <Skeleton variant="circular" width={40} height={40} />
          </div>
        </div>
        <div className={css.internalEnquiryContainer}>
          {[...Array(10)].map((_, index) => (
            <InternalEnquiryCardSkeleton key={index} />
          ))}
        </div>
      </section>
      <div className={css.paginationWrapper}>
        <Skeleton variant="rectangular" width="100%" height={48} />
      </div>
    </main>
  );
};

const InternalEnquiryCardSkeleton = () => (
  <div className={css.cardSkeleton}>
    <div className={css.cardHeader}>
      <Skeleton variant="text" width="60%" height={24} />
      <Skeleton variant="text" width="30%" height={24} />
    </div>
    <div className={css.cardBody}>
      <Skeleton variant="text" width="80%" height={20} />
      <Skeleton variant="text" width="70%" height={20} />
    </div>
    <div className={css.cardFooter}>
      <Skeleton variant="text" width="40%" height={20} />
      <div className={css.cardActions}>
        <Skeleton variant="rectangular" width={100} height={36} />
        <Skeleton variant="rectangular" width={100} height={36} />
      </div>
    </div>
  </div>
);

export default EnquirySkeleton;