import { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import css from './index.module.scss';
import { Typography, Button, Badge, Divider, IconNode, Drawer, ToolTip } from '@components/base';
import {
  Seo,
  SearchBar,
  LinkButton,
  SearchPlaceholder,
  ListPlaceholder,
  PaginationScroller,
  CardListing
} from '@components/common';
import SupplierCard from '@components/common/supplier-card';
import Images from '@assets/images';
import { CLIENT_ROUTES } from '@router/routes';
import { getSupplierList, ListParam } from '@services/supplier.service';
import { ISupplier } from '@helpers/types/supplier';
import debounce from 'lodash.debounce';
import Filter, { FormType } from './components/filter';
import AccessWrapper from '@authorization/access-wrapper';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useScrollToTop } from '@helpers/hooks';
import { useSearchParams } from 'react-router-dom';
import { filterNonEmptyValues, getValidEntriesLength } from '@helpers/utils';

interface SupplierHomeState {
  showFilterDrawer: boolean;
  supplierList: ISupplier[];
  loading: boolean;
  supplierCount: number;
  hasNext: boolean;
  currentPageNumber: number;
  retry: boolean;
  searchText: string;
  searchLoading: boolean;
  filters: FormType | null;
  filterData: any;
}

const SupplierHome = (props: any) => {
  const { actions } = props.modulePermissions;
  const debounceSearch = useRef<any>();
  const rootContainer = useRef<HTMLElement>(null);
  const topRef = useRef<HTMLElement>(null);
  const [itemLimit, setItemLimit] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPageNumber = parseInt(searchParams.get('page') || '1');
  const [supplierState, setSupplierState] = useState<SupplierHomeState>({
    showFilterDrawer: false,
    supplierList: [],
    loading: false,
    supplierCount: 0,
    hasNext: false,
    currentPageNumber: initialPageNumber,
    retry: false,
    searchText: '',
    searchLoading: false,
    filters: null,
    filterData: null
  });

  const {
    loading,
    supplierList,
    supplierCount,
    hasNext,
    currentPageNumber,
    retry,
    searchText,
    searchLoading,
    filters,
    filterData
  } = supplierState;

  const fetchSupplierList = useCallback(async (param: ListParam) => {
    const { searchText, filterData } = supplierState;
    const data = {
      ...filterData,
      ...param,
      kwargs: param?.kwargs ?? searchText
    };
    setSupplierState((prevState) => ({
      ...prevState,
      loading: true
    }));
    const response = await getSupplierList(data, param.page);
    if (response.success) {
      const { data } = response;
      setSupplierState((prevState) => ({
        ...prevState,
        supplierList: data.results,
        supplierCount: data.count,
        hasNext: !!data.next,
        currentPageNumber: param.page ?? 1,
        retry: false,
        searchLoading: false,
        loading: false
      }));
    } else {
      setSupplierState((prevState) => ({
        ...prevState,
        retry: true,
        searchLoading: false,
        loading: false
      }));
    }
  }, []);

  const handleFilterClick = () => {
    setSupplierState((prevState) => ({
      ...prevState,
      showFilterDrawer: !prevState.showFilterDrawer
    }));
  };

  const fetchSearchResult = useCallback(
    async (searchText: string) => {
      const { filterData } = supplierState;
      setSupplierState((prevState) => ({
        ...prevState,
        supplierList: [],
        supplierCount: 0,
        hasNext: false,
        currentPageNumber: 1,
        retry: false,
        searchLoading: true
      }));
      await fetchSupplierList({ page: 1, kwargs: searchText, ...filterData });
    },
    [supplierState.filterData]
  );

  const handleSupplierSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setSupplierState((prevState) => ({ ...prevState, searchText: value, searchLoading: true }));
    debounceSearch.current?.cancel();
    debounceSearch.current = debounce(fetchSearchResult, 800);
    debounceSearch.current(value);
  };

  const handleFilterApply = async (data: FormType) => {
    const supplier_type = data.supplier_type.map((item) => item.value).join(',');
    const business_type_list = data.business_type?.map((item) => item.value).join(',');
    const supplier_type_list = data.supplier_type_origin.map((item) => item.value).join(',');

    const reqBody = {
      supplier_name_list: data.supplier.map((item) => item.supplier_name).join(','),
      product_list: data.product.map((item) => item.name).join(','),
      business_type_list,
      supplier_type_list,
      country: data.country.map((item) => item.value).join(','),
      supplier_types: supplier_type
    };
    const params = filterNonEmptyValues(reqBody);
    setSupplierState((prevState) => ({
      ...prevState,
      supplierList: [],
      loading: true,
      filters: data,
      filterData: params
    }));
    await fetchSupplierList({ page: 1, ...params });
    handleFilterClick();
  };

  const handleClearFilter = async () => {
    setSupplierState((prevState) => ({
      ...prevState,
      supplierList: [],
      filters: null,
      filterData: null,
      loading: true
    }));
    await fetchSupplierList({ page: 1 });
  };

  const validatePageChange = (event: any, value: number) => {
    if (isNaN(value)) return;
    if (value === 0 || value > Math.ceil(supplierCount / itemLimit)) return;
    changePageNumber(value);
  };

  const changePageNumber = (value: number) => {
    setSupplierState((prevState) => ({
      ...prevState,
      currentPageNumber: value
    }));
    setSearchParams({ ...Object.fromEntries(searchParams), page: value.toString() });
  };
  useScrollToTop({ topRef, dependencyArray: [currentPageNumber] });

  return (
    <AccessWrapper show={actions?.read} showUnauthorised>
      <main ref={rootContainer} className={css.supplierWrapper}>
        <Seo title="Suppliers" />
        <div className={css.supplierHeader}>
          <div className={css.supplierTextWrapper}>
            <Typography variant="h2">Supplier</Typography>
            <Badge>{supplierCount}</Badge>
          </div>
          <AccessWrapper show={actions?.create}>
            <LinkButton
              variant="contained"
              to={`${CLIENT_ROUTES.addSupplier}`}
              className={css.addSupplierButton}>
              Add Supplier
            </LinkButton>
          </AccessWrapper>
        </div>
        <Divider className={css.supplierDivider} />
        <div className={css.supplierFilterWrapper}>
          <div className={css.filterButtonWrapper}>
            <Button
              className={css.supplierFilterButton}
              variant="outlined"
              startIcon={<IconNode src={Images.filter} alt="filter icon" />}
              onClick={handleFilterClick}>
              {`Filter ${
                getValidEntriesLength(filterData) > 0
                  ? `(${getValidEntriesLength(filterData)})`
                  : ''
              }`}
            </Button>
            {getValidEntriesLength(filterData) > 0 && (
              <Button
                variant="text"
                onClick={handleClearFilter}
                startIcon={<IconNode src={Images.crossRed} alt="clear icon" />}>
                Clear Filters
              </Button>
            )}
          </div>
          <SearchBar
            placeholder="Search for Supplier"
            className={css.supplierSearch}
            onChange={handleSupplierSearch}
            isLoading={searchLoading}
          />
          <div className={css.paginationFilter}>
            <ToolTip title="Previous Page">
              <ArrowBackIosIcon
                sx={{
                  height: '40px',
                  cursor: currentPageNumber > 1 ? 'pointer' : 'default',
                  color: currentPageNumber > 1 ? '#000000' : '#e2e2e2'
                }}
                onClick={() => validatePageChange(null, currentPageNumber - 1)}
              />
            </ToolTip>
            <Typography className={css.pageOfTotalPage}>
              {currentPageNumber} of {Math.ceil(supplierCount / itemLimit)}
            </Typography>
            <ToolTip title="Next Page">
              <ArrowForwardIosIcon
                sx={{
                  height: '40px',
                  cursor:
                    currentPageNumber < Math.ceil(supplierCount / itemLimit)
                      ? 'pointer'
                      : 'default',
                  color:
                    currentPageNumber < Math.ceil(supplierCount / itemLimit) ? '#000000' : '#e2e2e2'
                }}
                onClick={() => validatePageChange(null, currentPageNumber + 1)}
              />
            </ToolTip>
          </div>
        </div>
        <section className={css.supplierSection} ref={topRef}>
          {loading ? (
            <CardListing />
          ) : supplierList.length ? (
            supplierList.map((supplier: any) => (
              <SupplierCard
                key={supplier.supplier_id}
                supplierId={supplier.supplier_id}
                vendorId={supplier.vendor_id}
                supplierName={supplier.supplier_name}
                emailId={supplier.supplier_email}
                contactNumber={supplier.supplier_phone_number}
                location={supplier.addresses[0]?.city ?? ''}
                numberOfOrders={supplier.total_order}
                type={{
                  label: supplier?.type_display_name,
                  value: supplier?.type
                }}
                origin={supplier?.origin_display_name}
                businessType={supplier?.business_type_display_name}
              />
            ))
          ) : searchText || filters ? (
            <SearchPlaceholder searchText={searchText || 'Filters'} />
          ) : (
            !loading && (
              <ListPlaceholder
                title="No Supplier Added Yet"
                supportingText="When a supplier is added by you, they will show up here."
                buttonText="Add Supplier"
                to={`${CLIENT_ROUTES.addSupplier}`}
              />
            )
          )}
        </section>
        <PaginationScroller
          variant="text"
          defaultPage={1}
          count={supplierCount}
          pageLimit={itemLimit}
          page={currentPageNumber}
          onChange={validatePageChange}
          onIntersect={(index: number) => fetchSupplierList({ page: index, ...filterData, kwargs: searchText })}
        />
        <Drawer open={supplierState.showFilterDrawer} anchor="right" onClose={handleFilterClick}>
          <Filter value={filters} onApply={handleFilterApply} onClose={handleFilterClick} />
        </Drawer>
      </main>
    </AccessWrapper>
  );
};

export default SupplierHome;
