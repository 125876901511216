import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from '@helpers/constants';
import * as yup from 'yup';

const nullableNumberTransform = (value: any, originalValue: any) => {
  if (originalValue === '' || originalValue === null) {
    return null;
  }
  return value;
};

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

const addVesselDetails = yup.object().shape({
  vessel_name: yup.string().optional(),
  cutoff_date: yup.date().typeError('Select valid date').required('Gate In Date is required'),
  shipment_date: yup.date().typeError('Select valid date').required('Shipment Date is required'),
  voyage_number: yup.string().nullable(),
  vessel_booking_copy: yup
    .mixed()
    .required('Vessel booking copy is required')
    .test('fileSize', 'File Size is too large', (value: any) =>
      value ? value?.size <= MAX_FILE_SIZE : true
    )
    .test('fileType', 'Unsupported File Format', (value: any) =>
      value ? SUPPORTED_FORMATS.includes(value?.type) : true
    ),
  shipment_departure_date: yup
    .date()
    .typeError('Select valid date')
    .required('Shipment Departure Date is required'),
  shipment_arrival_date: yup
    .date()
    .typeError('Select valid date')
    .required('Shipment Arrival Date is required')
});

const addOperationCosts = yup.object().shape({
  freight_expenses: yup
    .number()
    .typeError('Freight Expenses should be in number')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Freight Expenses is required'),
  port_expenses: yup
    .number()
    .typeError('Port Expenses should be in number')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Port Expenses is required'),
  other_costs: yup
    .number()
    .typeError('Other Costs should be in number')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Other Costs is required'),
  freight_expenses_currency: selectSchema.required('Currency is required'),
  port_expenses_currency: selectSchema.required('Currency is required'),
  other_costs_currency: selectSchema.required('Currency is required')
});

export { addVesselDetails, addOperationCosts };
