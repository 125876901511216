import { useEffect, useState } from 'react';
import css from './index.module.scss';
import { Button, IconNode, Modal, Typography } from '@components/base';
import { SelectLabel, TextField } from '@components/common';
import { Controller, SubmitHandler, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { assignPeopleSchema } from '@helpers/yup/assign-people.schema';
import { fetchAllUsers } from '@services/user.service';
import notify from '@helpers/toastify-helper';
import { ToBeAssignedUserList } from '@helpers/types/user';
import { AssignPeopleToOrderForm } from '@helpers/types/order';
import Images from '@assets/images';
import { assignPersonnel } from '@services/order.service';
import { OrderInfo } from '@helpers/types/order';

interface AssignPeopleModalStates {
  userList: ToBeAssignedUserList | null;
  isLoading: boolean;
}

interface orderInfoPropType {
  orderInfo?: OrderInfo;
}

interface AssignPeopleModalProps {
  orderInfo?: orderInfoPropType;
  open: boolean;
  onClose?: () => void;
  taskId: Array<any>;
  onComplete?: () => void;
  sendStatusToCard: (arg: boolean) => void;
}

const AssignPeopleModal = (props: AssignPeopleModalProps) => {
  const { open, onClose, taskId, onComplete, sendStatusToCard } = props;
  const [modalState, setModalState] = useState<AssignPeopleModalStates>({
    userList: null,
    isLoading: false
  });

  const { userList, isLoading } = modalState;

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    setFocus,
    reset
  } = useForm<AssignPeopleToOrderForm>({
    resolver: yupResolver(assignPeopleSchema),
    defaultValues: {
      purchase_head: null,
      purchase_poc: [
        {
          poc: null
        }
      ],
      ops_head: null,
      ops_poc: [],
      finance_head: null,
      finance_poc: [
        {
          poc: null
        }
      ],
      sales_head: null,
      sales_poc: [
        {
          poc: null
        }
      ]
    },
    shouldFocusError: false
  });

  const {
    fields: opsPocFields,
    append: opsPocAppend,
    remove: opsPocRemove
  } = useFieldArray({
    control,
    name: 'ops_poc'
  });

  const {
    fields: salesPocFields,
    append: salesPocAppend,
    remove: salesPocRemove
  } = useFieldArray({
    control,
    name: 'sales_poc'
  });

  const {
    fields: purchasePocFields,
    append: purchasePocAppend,
    remove: purchasePocRemove
  } = useFieldArray({
    control,
    name: 'purchase_poc'
  });

  const {
    fields: financePocFields,
    append: financePocAppend,
    remove: financePocRemove
  } = useFieldArray({
    control,
    name: 'finance_poc'
  });

  useEffect(() => {
    getUserList();
    //Prevent scroll
    setFocus('sales_head');
  }, []);

  const getUserList = async () => {
    setModalState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await fetchAllUsers({ sort_by_groups: 'True', role: 'internal' });
    setModalState((prevState) => ({ ...prevState, isLoading: false }));
    if (response.success) {
      const { data } = response;
      setModalState((prevState) => ({ ...prevState, userList: data }));
      const opsPocCopy = data?.operations_reps?.map((item: any) => {
        return {
          poc: item
        };
      });

      opsPocAppend(opsPocCopy);
      //Prevent scroll
      setFocus('sales_head');
    } else if (response.error) notify({ message: response.error, severity: 'error' });
    else
      notify({
        title: 'We have little problem',
        message: 'There was an error understanding your request.',
        severity: 'error'
      });
  };

  const handleFormSubmit: SubmitHandler<AssignPeopleToOrderForm> = async (data) => {
    const entries = Object.entries(data);
    const opsPocList: Array<object> = [],
      purchasePocList: Array<object> = [],
      salesPocList: Array<object> = [],
      financePocList: Array<object> = [];
    entries.forEach((entry: any) => {
      if (entry[0].includes('ops_poc_') && entry[1]) {
        opsPocList.push(entry[1].user_id);
      }
      if (entry[0].includes('purchase_poc_') && entry[1]) {
        purchasePocList.push(entry[1].user_id);
      }
      if (entry[0].includes('sales_poc_') && entry[1]) {
        salesPocList.push(entry[1].user_id);
      }
      if (entry[0].includes('finance_poc_') && entry[1]) {
        financePocList.push(entry[1].user_id);
      }
    });

    const requestBody = [
      {
        poc_type: 'purchase_head',
        user_id: [data.purchase_head?.user_id]
      },
      {
        poc_type: 'purchase_rep',
        user_id: data?.purchase_poc?.map((item: any) => item.poc.user_id)
      },
      {
        poc_type: 'finance_head',
        user_id: [data.finance_head?.user_id]
      },
      {
        poc_type: 'finance_rep',
        user_id: data?.finance_poc?.map((item: any) => item.poc.user_id)
      },
      {
        poc_type: 'ops_head',
        user_id: [data.ops_head?.user_id]
      },
      {
        poc_type: 'ops_rep',
        user_id: data?.ops_poc?.map((item: any) => item.poc.user_id)
      },
      {
        poc_type: 'sales_head',
        user_id: [data.sales_head?.user_id]
      }
    ];
    if (data?.sales_poc?.length && data?.sales_poc[0]?.poc) {
      requestBody.push({
        poc_type: 'sales_rep',
        user_id: data?.sales_poc?.map((item: any) => item?.poc?.user_id)
      });
    }
    const response = await assignPersonnel(requestBody, taskId[0]?.task_id);
    if (response.success) {
      notify({ message: 'People assigned to the order successfully!' });
      sendStatusToCard(true);
      reset();
      if (onComplete) {
        onComplete();
      }
      if (onClose) {
        onClose();
      }
    } else if (response.error) notify({ message: response.error, severity: 'error' });
    else
      notify({
        title: 'We have little problem',
        message: 'There was an error understanding your request.',
        severity: 'error'
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Assign People</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={css.formWrapper}>
          <section className={css.modalContent}>
            <TextField
              disabled={true}
              type="text"
              inputMode="text"
              enterKeyHint="next"
              autoComplete="address-line2"
              label="Order ID"
              value={`#${props.taskId[0].readable_order_id}`}
            />
            <div className={css.fieldWrapper}>
              <Typography variant="h4">Sales User</Typography>
              <Controller
                name="sales_head"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    required
                    autoFocus
                    isSearchable
                    isLoading={isLoading}
                    label="Select Head"
                    options={userList?.sales_heads ? userList?.sales_heads : []}
                    getOptionLabel={(option: any) => `${option.given_name} ${option.family_name}`}
                    getOptionValue={(option: any) => option.user_id}
                    rootClassName={css.fieldSpacing}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Typography variant="p" className={css.pocInputNote}>
                By default, order creator will added as Sales POC
              </Typography>
              {salesPocFields.map((item, index) => {
                return (
                  <div key={item.id} className={css.pocListContainer}>
                    <Controller
                      name={`sales_poc.${index}.poc`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <SelectLabel
                          {...field}
                          isLoading={isLoading}
                          label="Select POC"
                          options={userList?.sales_reps ? userList?.sales_reps : []}
                          defaultValue={null}
                          isSearchable
                          placeholder="Select"
                          getOptionLabel={(option: any) =>
                            `${option.given_name} ${option.family_name}`
                          }
                          getOptionValue={(option: any) => option.user_id}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <div>
                      {salesPocFields.length > 1 ? (
                        <IconNode
                          src={Images.deleteDark}
                          className={css.pocDeleteIcon}
                          onClick={() => salesPocRemove(index)}
                          style={{ cursor: 'pointer' }}
                          alt="delete icon"
                        />
                      ) : (
                        <IconNode
                          className={css.pocDeleteIcon}
                          src={Images.delete}
                          alt="delete icon"
                          title="Disabled"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              <div
                className={css.addPocText}
                onClick={() => {
                  salesPocAppend({ data: '' });
                }}>
                Add POC
              </div>
            </div>
            <div className={css.fieldWrapper}>
              <Typography variant="h4">Ops User</Typography>
              <Controller
                name="ops_head"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    required
                    isLoading={isLoading}
                    label="Select Head"
                    isSearchable
                    options={userList?.operations_heads ? userList?.operations_heads : []}
                    getOptionLabel={(option: any) => `${option.given_name} ${option.family_name}`}
                    getOptionValue={(option: any) => option.user_id}
                    rootClassName={css.fieldSpacing}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />

              {opsPocFields.map((item, index) => {
                return (
                  <div key={item.id} className={css.pocListContainer}>
                    <Controller
                      name={`ops_poc.${index}.poc`}
                      control={control}
                      key={item.id}
                      render={({ field, fieldState }) => (
                        <SelectLabel
                          {...field}
                          required
                          isLoading={isLoading}
                          label="Select POC"
                          isSearchable
                          options={userList?.operations_reps ? userList?.operations_reps : []}
                          defaultValue={null}
                          getOptionLabel={(option: any) =>
                            `${option.given_name} ${option.family_name}`
                          }
                          getOptionValue={(option: any) => option.user_id}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <div>
                      {opsPocFields.length > 1 ? (
                        <IconNode
                          src={Images.deleteDark}
                          className={css.pocDeleteIcon}
                          onClick={() => opsPocRemove(index)}
                          style={{ cursor: 'pointer' }}
                          alt="delete icon"
                        />
                      ) : (
                        <IconNode
                          className={css.pocDeleteIcon}
                          src={Images.delete}
                          alt="delete icon"
                          title="Disabled"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              <div
                className={css.addPocText}
                onClick={() => {
                  opsPocAppend({ data: '' });
                }}>
                Add POC
              </div>
            </div>
            <div className={css.fieldWrapper}>
              <Typography variant="h4">Purchase Users</Typography>
              <Controller
                name="purchase_head"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    required
                    isLoading={isLoading}
                    label="Select Head"
                    isSearchable
                    options={userList?.purchase_heads ? userList?.purchase_heads : []}
                    getOptionLabel={(option: any) => `${option.given_name} ${option.family_name}`}
                    getOptionValue={(option: any) => option.user_id}
                    rootClassName={css.fieldSpacing}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />

              {purchasePocFields.map((item, index) => {
                return (
                  <div key={item.id} className={css.pocListContainer}>
                    <Controller
                      name={`purchase_poc.${index}.poc`}
                      control={control}
                      key={item.id}
                      render={({ field, fieldState }) => (
                        <SelectLabel
                          {...field}
                          required
                          isLoading={isLoading}
                          label="Select POC"
                          defaultValue={null}
                          isSearchable
                          options={userList?.purchase_reps ? userList?.purchase_reps : []}
                          getOptionLabel={(option: any) =>
                            `${option.given_name} ${option.family_name}`
                          }
                          getOptionValue={(option: any) => option.user_id}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <div>
                      {purchasePocFields.length > 1 ? (
                        <IconNode
                          src={Images.deleteDark}
                          className={css.pocDeleteIcon}
                          onClick={() => purchasePocRemove(index)}
                          style={{ cursor: 'pointer' }}
                          alt="delete icon"
                        />
                      ) : (
                        <IconNode
                          className={css.pocDeleteIcon}
                          src={Images.delete}
                          alt="delete icon"
                          title="Disabled"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              <div
                className={css.addPocText}
                onClick={() => {
                  purchasePocAppend({ data: '' });
                }}>
                Add POC
              </div>
            </div>
            <div className={css.fieldWrapper}>
              <Typography variant="h4">Finance Users</Typography>
              <Controller
                name="finance_head"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    required
                    isLoading={isLoading}
                    label="Select Head"
                    isSearchable
                    options={userList?.finance_heads ? userList?.finance_heads : []}
                    getOptionLabel={(option: any) => `${option.given_name} ${option.family_name}`}
                    getOptionValue={(option: any) => option.user_id}
                    rootClassName={css.fieldSpacing}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />

              {financePocFields.map((item, index) => {
                return (
                  <div key={item.id} className={css.pocListContainer}>
                    <Controller
                      name={`finance_poc.${index}.poc`}
                      control={control}
                      key={item.id}
                      render={({ field, fieldState }) => (
                        <SelectLabel
                          {...field}
                          required
                          isLoading={isLoading}
                          label="Select POC"
                          defaultValue={null}
                          isSearchable
                          options={userList?.finance_reps ? userList?.finance_reps : []}
                          getOptionLabel={(option: any) =>
                            `${option.given_name} ${option.family_name}`
                          }
                          getOptionValue={(option: any) => option.user_id}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <div>
                      {financePocFields.length > 1 ? (
                        <IconNode
                          src={Images.deleteDark}
                          className={css.pocDeleteIcon}
                          onClick={() => financePocRemove(index)}
                          style={{ cursor: 'pointer' }}
                          alt="delete icon"
                        />
                      ) : (
                        <IconNode
                          className={css.pocDeleteIcon}
                          src={Images.delete}
                          alt="delete icon"
                          title="Disabled"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              <div
                className={css.addPocText}
                onClick={() => {
                  financePocAppend({ data: '' });
                }}>
                Add POC
              </div>
            </div>
          </section>
          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </section>
        </form>
      </div>
    </Modal>
  );
};

export default AssignPeopleModal;
