import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from '@helpers/constants';
import * as yup from 'yup';

export const addLCDetails = yup.object().shape({
  lc_bank: yup.string().required('LC Bank is required'),
  lc_date: yup.date().required('LC Date is required'),
  lc_copy: yup
    .mixed()
    .required('LC Copy is required')
    .test('fileSize', 'File Size is too large', (value: any) =>
      value ? value?.size <= MAX_FILE_SIZE : true
    )
    .test('fileType', 'Unsupported File Format', (value: any) =>
      value ? SUPPORTED_FORMATS.includes(value?.type) : true
    )
});
