import { Fragment, useState, useCallback, useEffect } from 'react';
import css from './index.module.scss';
import { Button, IconNode, Loader, Typography } from '@components/base';
import {
  SideBar,
  CompanyDetailForm,
  BankInfo,
  BankPlaceholder,
  BankDetailForm
} from '@components/common';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import CustomerDetailBox from './components/customer-detail-box';
import CustomerPocBox from './components/customer-poc-box';
import { ICustomerDetailForm, ICustomerInfo } from '@helpers/types/customer';
import { addBankDetailSchema, addCustomerSchema } from '@helpers/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Images from '@assets/images';
import {
  getCustomerFormValuesFromResponse,
  getBankFormValuesFromResponse,
  getRequestBodyForCustomerDetail,
  getRequestBodyForSupplierBankDetail,
  getCustomerCountry
} from '@helpers/utils';
import { ADDRESS_TYPE_CONSTANTS } from '@helpers/constants';
import { IBankDetailForm } from '@helpers/types';
import { updateCustomerInfo } from '@services/customer.service';
import notify from '@helpers/toastify-helper';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { fetchAllUsers } from '@services/user.service';
import AccessWrapper from '@authorization/access-wrapper';

interface CustomerDetailTabProps {
  customerInfo: ICustomerInfo;
  onChange: (data: ICustomerInfo) => void;
  actions: IActions;
}

interface CustomerDetailTabStates {
  activeStep: number;
  editable: boolean;
  userList: Array<object>;
}

const CustomerDetailTab = (props: CustomerDetailTabProps) => {
  const { customerInfo, actions } = props;
  const [customerState, setCustomerState] = useState<CustomerDetailTabStates>({
    activeStep: 0,
    editable: false,
    userList: []
  });

  useEffect(() => {
    fetchUsersForPocList();
  }, []);

  const { activeStep, editable, userList } = customerState;

  const getCustomerFormDefaultValues = useCallback(() => {
    const { customerInfo } = props;
    return Promise.resolve(getCustomerFormValuesFromResponse(customerInfo));
  }, []);

  const getBankInfoDefaultValues = useCallback(() => {
    const { customerInfo } = props;
    if (customerInfo.bankdetails.length < 1) {
      return Promise.resolve({
        bankdetails: [
          {
            bank_id: '',
            bank_name: '',
            branch_name: '',
            bank_account_holder_name: '',
            account_number: '',
            ifsc_code: '',
            swift_code: '',
            address_line1: '',
            address_line2: '',
            zip_code: '',
            city: null,
            state: null,
            country: null
          }
        ]
      });
    }
    const bankValues = getBankFormValuesFromResponse(customerInfo.bankdetails);
    return Promise.resolve({ bankdetails: bankValues });
  }, [props.customerInfo]);

  const customerDetailForm = useForm<ICustomerDetailForm>({
    resolver: yupResolver(addCustomerSchema),
    defaultValues: getCustomerFormDefaultValues
  });

  const bankInfoForm = useForm<IBankDetailForm>({
    resolver: yupResolver(addBankDetailSchema),
    defaultValues: getBankInfoDefaultValues
  });

  const {
    reset: firstReset,
    formState: { isDirty: firstIsDirty, isSubmitting: firstIsSubmitting }
  } = customerDetailForm;

  const {
    reset: secondReset,
    formState: { isDirty: secondIsDirty, isSubmitting: secondIsSubmitting }
  } = bankInfoForm;

  const handleCustomerDetailFormSubmit: SubmitHandler<ICustomerDetailForm> = async (data) => {
    const { customerInfo, onChange } = props;
    const reqBody = getRequestBodyForCustomerDetail(data);
    const response = await updateCustomerInfo(customerInfo.customer_id, reqBody);
    if (response.success) {
      const { data } = response;
      const defaultValues = getCustomerFormValuesFromResponse(data);
      firstReset(defaultValues, {
        keepErrors: false,
        keepDirty: false,
        keepDirtyValues: false,
        keepValues: false,
        keepDefaultValues: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false
      });
      onChange(data);
      notify({ message: 'Customer details updated successfully', dismissible: true });
      setCustomerState((prevState) => ({ ...prevState, editable: false }));
    } else if (response.error) {
      notify({
        message: response.error,
        dismissible: true,
        severity: 'error'
      });
    } else {
      notify({
        message: 'Some error occurred',
        dismissible: true,
        severity: 'error'
      });
    }
  };

  const handleEditClick = async () => {
    const { editable } = customerState;
    if (editable) {
      const firstFormDefaultValues = await getCustomerFormDefaultValues();
      const secondFormDefaultValues = await getBankInfoDefaultValues();
      firstIsDirty &&
        firstReset(firstFormDefaultValues, {
          keepErrors: false,
          keepDirty: false,
          keepDirtyValues: false,
          keepValues: false,
          keepDefaultValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false
        });
      secondIsDirty &&
        secondReset(secondFormDefaultValues, {
          keepErrors: false,
          keepDirty: false,
          keepDirtyValues: false,
          keepValues: false,
          keepDefaultValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false
        });
    }
    setCustomerState((prevState) => ({ ...prevState, editable: !prevState.editable }));
  };

  const handleSideNavigation = (activeStep: number) => {
    setCustomerState((prevState) => ({ ...prevState, activeStep: activeStep }));
  };

  const handleNavigation = useCallback(() => {
    const { activeStep } = customerState;
    if (activeStep === 0) handleSideNavigation(1);
    else handleSideNavigation(0);
  }, [handleSideNavigation, customerState.activeStep]);

  const handleBankInfoFormSubmit: SubmitHandler<IBankDetailForm> = async (param) => {
    const { customerInfo, onChange } = props;
    const reqBody = getRequestBodyForSupplierBankDetail(param);
    const response = await updateCustomerInfo(customerInfo.customer_id, reqBody);
    if (response.success) {
      const { data } = response;
      const defaultValues = getBankFormValuesFromResponse(data.bankdetails);
      secondReset(
        { bankdetails: defaultValues },
        {
          keepErrors: false,
          keepDirty: false,
          keepDirtyValues: false,
          keepValues: false,
          keepDefaultValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false
        }
      );
      onChange(data);
      notify({ message: 'Bank details have been updated successfully!', dismissible: true });
      setCustomerState((prevState) => ({ ...prevState, editable: false }));
    } else if (response.error) {
      notify({
        message: 'Please try again with valid data',
        title: 'Form validation failed',
        severity: 'error',
        dismissible: true
      });
    }
  };

  const fetchUsersForPocList = async () => {
    const userList = await fetchAllUsers({
      role: 'external',
      unassigned: true
    });
    if (userList?.success) {
      setCustomerState((prevState: any) => ({ ...prevState, userList: userList.data.users }));
    } else if (userList.error) {
      notify({ message: userList.error, severity: 'error', dismissible: true });
    } else {
      notify({ message: 'Unable to fetch users', severity: 'error', dismissible: true });
    }
  };

  return (
    <section className={css.mainWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="h4">Customer Details</Typography>
        <AccessWrapper show={actions?.update}>
          <Button
            variant="text"
            onClick={handleEditClick}
            startIcon={<IconNode src={Images.editRed} alt="edit icon" />}>
            Edit
          </Button>
        </AccessWrapper>
      </div>
      <div className={css.contentWrapper}>
        <div className={css.sidebarWrapper}>
          <SideBar
            activeStep={activeStep}
            onClick={handleSideNavigation}
            onEnter={handleSideNavigation}>
            <SideBar.Item label="Details" value={0} />
            <SideBar.Item label="Bank Details" value={1} />
          </SideBar>
        </div>
        {!editable && (
          <div className={css.boxWrapper}>
            {activeStep === 0 && (
              <Fragment>
                <CustomerDetailBox
                  email={customerInfo.email}
                  website={customerInfo.website}
                  country={getCustomerCountry(customerInfo)}
                  typeOfCustomer={customerInfo.business_type}
                  addresses={customerInfo.addresses}
                  description={customerInfo.description}
                  customerReadableId={customerInfo.customer_display_id}
                />
                <CustomerPocBox pocs={customerInfo.pocs} />
              </Fragment>
            )}
            {activeStep === 1 &&
              (customerInfo.bankdetails.length ? (
                <BankInfo bankInfo={customerInfo.bankdetails} />
              ) : (
                <BankPlaceholder
                  className={css.bankPlaceholderWrapper}
                  onAddClick={handleEditClick}
                />
              ))}
          </div>
        )}
        <FormProvider {...customerDetailForm}>
          {editable && activeStep === 0 && (
            <CompanyDetailForm
              enableEditMode
              onFormSubmit={handleCustomerDetailFormSubmit}
              onCancelClick={handleEditClick}
              onNextClick={handleNavigation}
              userList={userList}
            />
          )}
        </FormProvider>
        <FormProvider {...bankInfoForm}>
          {editable && activeStep === 1 && (
            <BankDetailForm
              editMode
              onBackClick={handleNavigation}
              onCancelClick={handleEditClick}
              onFormSubmit={handleBankInfoFormSubmit}
            />
          )}
        </FormProvider>
      </div>
      <Loader open={firstIsSubmitting || secondIsSubmitting} />
    </section>
  );
};

export default CustomerDetailTab;
