import { useState } from 'react';
import css from './index.module.scss';
import { Button, IconNode, Loader, Typography } from '@components/base';
import { TextField } from '@components/common';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MailSent from '../mail-sent';
import Images from '@assets/images';
import { forgotPasswordSchema } from '@helpers/yup';
import { IEmailField } from '@helpers/types';
import { requestResetEmail } from '@services/login.service';
import notify from '@helpers/toastify-helper';

const ForgotPasswordForm = () => {
  const [showTimer, setShowTimer] = useState(false);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(forgotPasswordSchema)
  });

  const handleFormSubmit: SubmitHandler<IEmailField> = async (data) => {
    const response = await requestResetEmail(data.email);
    if (response.success) {
      setShowTimer(true);
      notify({ message: response.data.success });
    } else
      notify({
        message: response.error ?? 'User not found with registered email.',
        severity: 'error'
      });
  };

  return (
    <div className={css.forgotWrapper}>
      <Typography variant="h1">Forgot Password</Typography>
      {!showTimer && (
        <form className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                autoFocus
                required
                type="email"
                inputMode="email"
                autoComplete="email"
                label="Email"
                placeholder="Enter your email"
                error={fieldState.invalid}
                helperText={
                  fieldState.invalid
                    ? fieldState.error?.message
                    : 'Enter your email ID and we will send a link to reset your password.'
                }
                endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
              />
            )}
          />
          <Button type="submit" disabled={isSubmitting}>
            Reset Password
          </Button>
        </form>
      )}
      {showTimer && <MailSent email={getValues('email')} />}
      <Loader open={isSubmitting} />
    </div>
  );
};

export default ForgotPasswordForm;
