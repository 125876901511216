import { CSSProperties, ReactNode, SyntheticEvent } from 'react';
import './index.style.scss';
import Images from '@assets/images';
import { Image } from '@components/base';
import PropTypes from 'prop-types';
import { alertIconMapping, crossIconMapping } from '@helpers/constants';
import AlertTitle from './alert-title';
import { severityType } from '@helpers/types';

export interface AlertProps {
  style?: CSSProperties;
  title?: ReactNode;
  severity?: severityType;
  children: ReactNode;
  icon?: boolean;
  actions?: ReactNode;
  onClose?: (event: SyntheticEvent) => void;
}

const Alert = (props: AlertProps) => {
  const { title, icon, style, children, actions, onClose, severity = 'success' } = props;

  const alertImgSource = Images[alertIconMapping[severity]];
  const crossImgSource = Images[crossIconMapping[severity]];

  return (
    <div role="alert" style={style} className={`alert-root alert-${severity}`}>
      {alertImgSource && icon && (
        <div className="alert-icon-wrapper">
          <Image src={alertImgSource} alt={`alert-${severity}`} className="alert-icon" />
        </div>
      )}
      <div className="alert-message">
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
      </div>
      {!actions && onClose && (
        <button className="alert-close-icon-wrapper" onClick={onClose}>
          <Image src={crossImgSource} alt={`alert-${severity}`} className="alert-icon" />
        </button>
      )}
      {actions}
    </div>
  );
};

Alert.defaultProps = {
  severity: 'success',
  icon: true
};

Alert.propTypes = {
  severity: PropTypes.oneOf(['error', 'info', 'warning', 'success', 'none'])
};

export default Alert;
