import { ChangeEvent, useEffect, useState } from 'react';
import css from './index.module.scss';
import { Button, IconNode, Loader, Modal, Typography } from '@components/base';
import { InputDatePicker, TextField, DocRow, SelectLabel, PaymentTerms } from '@components/common';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { uploadECGC } from '@helpers/yup/upload-ecgc.schema';
import { uploadEcgc } from '@services/task.service';
import Images from '@assets/images';
import { PAYMENT_TERMS, convertDateString } from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import { IPaymentTermsFilter, IPaymentTermsForm } from '@helpers/types/add-order';
import { getPaymentTermsFilterOptions } from '@services/order.service';
import Divider from '@components/base/divider';

interface ECGCModalProps {
  open: boolean;
  onClose?: () => void;
  customerId: string;
  taskId: string;
  sendStatusToCard: (arg: boolean) => void;
}
interface FormType {
  is_ecgc_approved: boolean;
  ecgc_approval_start_date: Date | null;
  ecgc_approval_end_date: Date | null;
  ecgc_approved_amount: string;
  payment_terms: IPaymentTermsForm[];
  ecgc_approval_letter: File | null;
  dnb_report: File | null;
}

const ECGCModal = (props: ECGCModalProps) => {
  const { open, onClose, taskId, sendStatusToCard } = props;
  const [paymentTermsOptions, setPaymentTermsOptions] = useState<IPaymentTermsFilter[]>([]);
  const [filteredPaymentTermsOptions, setFilteredPaymentTermsOptions] = useState<
    IPaymentTermsFilter[]
  >([]);

  const ecgcForm = useForm<FormType>({
    resolver: yupResolver(uploadECGC),
    defaultValues: {
      ecgc_approval_start_date: null,
      ecgc_approval_end_date: null,
      ecgc_approved_amount: '',
      payment_terms: [
        {
          payment_term: null,
          percentage: null,
          days: null
        }
      ],
      ecgc_approval_letter: null,
      dnb_report: null
    }
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting }
  } = ecgcForm;

  const watchField = useWatch({
    name: ['ecgc_approval_letter', 'dnb_report', 'payment_terms'],
    control
  });

  const {
    fields: paymentTermFields,
    append: paymentTermAppend,
    remove: paymentTermRemove
  } = useFieldArray({
    name: 'payment_terms',
    control
  });

  const handleAddPaymentTermField = () => {
    paymentTermAppend({
      payment_term: null,
      percentage: null,
      days: null
    });
  };

  useEffect(() => {
    getPaymentTermsOptions();
  }, []);

  const getPaymentTermsOptions = async () => {
    const response = await getPaymentTermsFilterOptions();
    if (response?.success) {
      setPaymentTermsOptions(response.data?.payment_terms_options);
    } else {
      notify({
        severity: 'error',
        title: 'Error',
        message: response.message ?? 'Unable to fetch payment terms'
      });
    }
  };

  /**
   * This useEffect is used to filter the payment terms options
   */
  useEffect(() => {
    const selectedPaymentTerms = watchField[2]?.map(
      (item: any) => item?.payment_term?.payment_term ?? ''
    );
    const filteredPaymentTermsOptionsCopy = paymentTermsOptions.filter((item: any) => {
      return !selectedPaymentTerms.includes(item.payment_term);
    });
    setFilteredPaymentTermsOptions(filteredPaymentTermsOptionsCopy);
  }, [watchField[2]]);

  const handleECGCFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.currentTarget.files;
    const currentFile = selectedFiles?.[0];
    if (currentFile) {
      setValue('ecgc_approval_letter', currentFile);
    }
  };

  const handleDNBFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.currentTarget.files;
    const currentFile = selectedFiles?.[0];
    if (currentFile) {
      setValue('dnb_report', currentFile);
    }
  };

  const handleDelete = (key: string | any) => () => {
    setValue(key, null); //reuploading fails after delete clicked once.
  };

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    //Validation for payment terms
    let totalPercentage = 0,
      isError = false;
    data?.payment_terms?.forEach((item: any) => {
      totalPercentage += parseInt(item.percentage);
      if (item?.payment_term?.days && (!item?.days || parseInt(item?.days) <= 0)) {
        notify({
          message: `Enter valid number of days for ${item?.payment_term?.payment_term}`,
          severity: 'error'
        });
        isError = true;
        return;
      }
    });
    if (totalPercentage !== 100) {
      notify({
        message: 'Total percentage in payment terms should be 100',
        severity: 'error'
      });
      isError = true;
      return;
    }
    if (isError) return;

    const { customerId, onClose } = props;
    const {
      ecgc_approval_end_date: endDate,
      ecgc_approval_start_date: startDate,
      ecgc_approval_letter
    } = data;
    if (!ecgc_approval_letter) {
      notify({ message: 'Please upload ECGC approval letter', severity: 'error' });
      return;
    }
    const formattedStartDate = convertDateString(startDate);
    const formattedEndDate = convertDateString(endDate);
    const reqBody = new FormData();
    reqBody.append('ecgc_approval_start_date', formattedStartDate as string);
    reqBody.append('ecgc_approval_end_date', formattedEndDate as string);
    reqBody.append('ecgc_approved_amount', data.ecgc_approved_amount);
    ecgc_approval_letter && reqBody.append('ecgc_approval_letter', ecgc_approval_letter);
    data.dnb_report && reqBody.append('dnb_report', data.dnb_report);
    reqBody.append('is_dnb_report', `${!!data.dnb_report}`);
    // ----- appending payment terms -----
    const paymentTerms = data?.payment_terms?.map((item: any) => {
      const obj: any = {};
      obj['payment_term'] = item.payment_term?.payment_term;
      obj['percentage'] = parseInt(item.percentage);
      if (item.days) {
        obj['days'] = parseInt(item.days);
      }
      return obj;
    });
    reqBody.append('ecgc_payment_term', JSON.stringify(paymentTerms));
    // ----- appending payment terms -----
    const response = await uploadEcgc(taskId, reqBody);
    if (response.success) {
      notify({ message: 'ECGC details updated' });
      sendStatusToCard(true);
      onClose?.();
    } else if (response.error) {
      notify({
        message: response.error ?? 'ECGC details could not be uploaded',
        severity: 'error'
      });
    } else notify({ message: 'Some error occurred', severity: 'error' });
  };

  return (
    <Modal open={open}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">ECGC Detail</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="div"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={css.fieldsWrapper}>
            <Controller
              name="ecgc_approval_start_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  label="Date of Approval"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />
            <Controller
              name="ecgc_approval_end_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  label="Valid Till"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  rootClassName={css.fieldSpacing}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="ecgc_approved_amount"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  inputMode="decimal"
                  autoComplete="off"
                  enterKeyHint="next"
                  label="Approved Amount Limit"
                  placeholder="Enter amount"
                  rootClassName={css.fieldSpacing}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />

            <div className={css.rowWrapper}>
              <FormProvider {...ecgcForm}>
                <PaymentTerms
                  paymentTermFields={paymentTermFields}
                  paymentTermRemove={paymentTermRemove}
                  paymentTermsOptions={paymentTermsOptions}
                  filteredPaymentTermsOptions={
                    filteredPaymentTermsOptions?.length
                      ? filteredPaymentTermsOptions
                      : paymentTermsOptions
                  }
                  handleAddPaymentTermField={handleAddPaymentTermField}
                />
              </FormProvider>
              <Divider className={css.divider} />
            </div>
            <label htmlFor="ECGC">
              <Typography variant="p" className={css.uploadWrapper}>
                Upload ECGC Document
              </Typography>
              <FormProvider {...ecgcForm}>
                <div className={css.docUploadWrapper}>
                  <DocRow
                    name="ecgc_approval_letter"
                    label="ECGC"
                    required
                    onFileChange={handleECGCFileChange}
                    //showDeleteButton={!!watchField[0]}
                    viewUri={watchField[0]}
                    onDeleteClick={handleDelete('ecgc_approval_letter')}
                    className={css.docWrapper}
                  />
                  <DocRow
                    name="dnb_report"
                    label="DNB Report"
                    onFileChange={handleDNBFileChange}
                    //showDeleteButton={!!watchField[1]}
                    viewUri={watchField[1]}
                    onDeleteClick={handleDelete('dnb_report')}
                  />
                </div>
              </FormProvider>
            </label>
          </div>
          <div className={css.actionButtonWrapper}>
            <Button variant="outlined-secondary" className={css.actionButton} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" className={css.actionButton}>
              Save
            </Button>
          </div>
        </form>
        <Loader open={isSubmitting} />
      </div>
    </Modal>
  );
};

export default ECGCModal;
