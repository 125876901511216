import { useEffect, useState, useRef, useCallback, ChangeEvent, useMemo } from 'react';
import css from './index.module.scss';
import { Badge, Button, Divider, IconNode, Loader, ToolTip, Typography } from '@components/base';
import {
  InfiniteScroll,
  LinkButton,
  SearchBar,
  Seo,
  SearchPlaceholder,
  ListPlaceholder,
  PaginationScroller,
  CardListing
} from '@components/common';
import debounce from 'lodash.debounce';
import { ADDRESS_TYPE_CONSTANTS } from '@helpers/constants';
import { ICustomerInfo } from '@helpers/types/customer';
import { CLIENT_ROUTES } from '@router/routes';
import { fetchAllCustomers } from '@services/customer.service';
import CustomerCard from './components/customer-card';
import Images from '@assets/images';
import notify from '@helpers/toastify-helper';
import { getCustomerCountry } from '@helpers/utils';
import AccessWrapper from '@authorization/access-wrapper';
import { useScrollToTop } from '@helpers/hooks';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSearchParams } from 'react-router-dom';

interface CustomerHomeStates {
  customerList: ICustomerInfo[];
  isLoading: boolean;
  customerCount: number;
  hasNext: boolean;
  currentPageNumber: number;
  retry: boolean;
  searchText: string;
  searchLoading: boolean;
}

const CustomerHome = (props: any) => {
  const { modulePermissions } = props;
  const { actions = {} } = modulePermissions;
  const [searchParams, setSearchParams] = useSearchParams();
  const debounceSearch = useRef<any>();
  const rootContainer = useRef<HTMLElement>(null);
  const topRef = useRef<HTMLElement>(null);
  const [itemLimit, setItemLimit] = useState(10);
  const initialPageNumber = parseInt(searchParams.get('page') || '1');
  const [customerState, setCustomerState] = useState<CustomerHomeStates>({
    customerList: [],
    isLoading: false,
    customerCount: 0,
    hasNext: false,
    currentPageNumber: initialPageNumber,
    retry: false,
    searchText: '',
    searchLoading: false
  });

  const fetchCustomerList = async (page = 1, searchText?: string) => {
    const response = await fetchAllCustomers(page, searchText);
    if (response.success) {
      const { data } = response;
      setCustomerState((prevState) => ({
        ...prevState,
        customerList: data.results,
        customerCount: data.count,
        hasNext: !!data.next,
        currentPageNumber: page,
        retry: false,
        searchLoading: false
      }));
    } else if (response.error) {
      setCustomerState((prevState) => ({ ...prevState, retry: true, searchLoading: false }));
      notify({ message: response.error, severity: 'error' });
    } else {
      setCustomerState((prevState) => ({ ...prevState, retry: true, searchLoading: false }));
      notify({ message: 'Unable to fetch customer list ', severity: 'error' });
    }
  };

  const fetchSearchResult = useCallback(async (searchText: string) => {
    const response = await fetchAllCustomers(1, searchText);
    if (response.success) {
      const { data } = response;
      setCustomerState((prevState) => ({
        ...prevState,
        customerList: data.results,
        customerCount: data.count,
        hasNext: !!data.next,
        currentPageNumber: 1,
        retry: false,
        searchLoading: false
      }));
    } else {
      setCustomerState((prevState) => ({
        ...prevState,
        retry: true,
        searchLoading: false
      }));
      notify({ message: response.error ?? 'Unable to fetch customer list ', severity: 'error' });
    }
  }, []);

  const handleCustomerSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setCustomerState((prevState) => ({ ...prevState, searchText: value, searchLoading: true }));
    debounceSearch.current?.cancel();
    debounceSearch.current = debounce(fetchSearchResult, 800);
    debounceSearch.current(value);
  };

  const {
    customerList,
    customerCount,
    currentPageNumber,
    hasNext,
    isLoading,
    retry,
    searchText,
    searchLoading
  } = customerState;

  const changePageNumber = (event: any, value: number) => {
    if (value === 0 || value > Math.ceil(customerCount / itemLimit)) return;
    setCustomerState((prevState) => ({
      ...prevState,
      currentPageNumber: value
    }));
  };

  const handlePageChange = async (newPage: number) => {
    setCustomerState((prev) => ({ ...prev, isLoading: true }));
    await fetchCustomerList(newPage, searchText);
    setSearchParams({ ...Object.fromEntries(searchParams), page: newPage.toString() });
    setCustomerState((prevState) => ({ ...prevState, isLoading: false }));
  };

  useEffect(() => {
    handlePageChange(currentPageNumber);
  }, [currentPageNumber]);

  useScrollToTop({ topRef, dependencyArray: [currentPageNumber] });

  return (
    <AccessWrapper show={actions?.read ?? false} showUnauthorised>
      <main ref={rootContainer} className={css.mainWrapper}>
        <Seo title="Customers" />
        <div className={css.header}>
          <div className={css.customerTextWrapper}>
            <Typography variant="h2">Customers</Typography>
            <Badge>{customerCount}</Badge>
          </div>
          <AccessWrapper show={actions?.create ?? false}>
            <LinkButton
              variant="contained"
              to={`${CLIENT_ROUTES.addCustomer}`}
              className={css.addCustomerButton}>
              Add Customer
            </LinkButton>
          </AccessWrapper>
        </div>
        <Divider className={css.divider} />
        <div className={css.filterWrapper}>
          {/* <Button
          className={css.filterButton}
          variant="outlined"
          startIcon={<IconNode src={Images.filter} alt="filter icon" />}
          // onClick={handleFilterClick}
        >
          Filter
        </Button> */}
          <SearchBar
            placeholder="Search"
            className={css.search}
            onChange={handleCustomerSearch}
            isLoading={searchLoading}
          />
          <div className={css.paginationFilter}>
            <ToolTip title="Previous Page">
              <ArrowBackIosIcon
                sx={{
                  height: '40px',
                  cursor: currentPageNumber > 1 ? 'pointer' : 'default',
                  color: currentPageNumber > 1 ? '#000000' : '#e2e2e2'
                }}
                onClick={() => changePageNumber(null, currentPageNumber - 1)}
              />
            </ToolTip>
            <Typography className={css.pageOfTotalPage}>
              {currentPageNumber} of {Math.ceil(customerCount / itemLimit)}
            </Typography>
            <ToolTip title="Next Page">
              <ArrowForwardIosIcon
                sx={{
                  height: '40px',
                  cursor:
                    currentPageNumber < Math.ceil(customerCount / itemLimit)
                      ? 'pointer'
                      : 'default',
                  color:
                    currentPageNumber < Math.ceil(customerCount / itemLimit) ? '#000000' : '#e2e2e2'
                }}
                onClick={() => changePageNumber(null, currentPageNumber + 1)}
              />
            </ToolTip>
          </div>
        </div>

        <section className={css.customerSection} ref={topRef}>
          {isLoading ? (
            <CardListing />
          ) : customerList.length ? (
            customerList.map((customer) => (
              <CustomerCard
                key={customer.customer_id}
                customerId={customer.customer_id}
                customerName={customer.name}
                customerEmail={customer.email}
                customerPhoneNumber={customer.number}
                customerCountry={getCustomerCountry(customer)}
                pocDetails={customer.pocs}
                addresses={customer.addresses}
                lastOrdered={customer.last_ordered_at}
                ecgcEndDate={customer.ecgc_approval_end_date}
                customerDisplayId={customer.customer_display_id}
              />
            ))
          ) : searchText ? (
            <SearchPlaceholder searchText={searchText} />
          ) : (
            !isLoading && (
              <ListPlaceholder
                to={`${CLIENT_ROUTES.addCustomer}`}
                title="No Customer Added Yet"
                supportingText="When a Customer is added by you,
          they will show up here."
                buttonText="Add Customer"
              />
            )
          )}
        </section>
        {!isLoading && (
          <PaginationScroller
            variant="text"
            defaultPage={1}
            count={customerCount}
            pageLimit={itemLimit}
            page={currentPageNumber}
            onChange={changePageNumber}
          />
        )}
      </main>
    </AccessWrapper>
  );
};

export default CustomerHome;
