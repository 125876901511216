import { ChangeEvent, useState, useRef, useCallback, useEffect } from 'react';
import css from './index.module.scss';
import { Typography, Checkbox, IconNode } from '@components/base';
import {
  BoxContainer,
  TextAreaLabel,
  TextField,
  AutoComplete,
  UploadButton
} from '@components/common';
import { Controller, useFormContext } from 'react-hook-form';
import Images from '@assets/images';
import { IProductForm, ProductInfo } from '@helpers/types/product';
import { fetchAllProducts } from '@services/product.service';
import debounce from 'lodash.debounce';
import notify from '@helpers/toastify-helper';

interface ProductDetailContainerStates {
  options: string[];
  isLoading: boolean;
  isOpen: boolean;
  docUploaded: boolean;
}

interface ProductDetailProps {
  editMode?: boolean;
}

const ProductDetailContainer = (props: ProductDetailProps) => {
  const [productStates, setProductStates] = useState<ProductDetailContainerStates>({
    options: [],
    isLoading: false,
    isOpen: false,
    docUploaded: false
  });
  const debounceRef = useRef<any>(null);
  const { control, setValue, watch } = useFormContext<IProductForm>();
  const { editMode = false } = props;

  const handleChange = async (value: string) => {
    const response = await fetchAllProducts(undefined, value);
    if (response.success) {
      const { data } = response;
      // const options = data.results.map((product: ProductInfo) => product.name);
      const options = data.results;
      setProductStates((prevState) => ({
        ...prevState,
        isLoading: false,
        options: options,
        isOpen: true
      }));
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else {
      notify({ message: 'Unable to fetch product list', severity: 'error' });
    }
  };

  useEffect(() => {
    if (watch('msds_doc')) {
      setProductStates((prevState) => ({ ...prevState, docUploaded: true }));
    }
  }, [watch('msds_doc')]);

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setValue('name', value, { shouldValidate: true });
    setProductStates((prevState) => ({ ...prevState, isLoading: true }));
    debounceRef.current?.cancel();
    debounceRef.current = debounce(handleChange, 800);
    debounceRef.current(value.trim());
  };

  const handleOptionSelection = useCallback((value: string) => {
    setValue('name', value, { shouldValidate: true });
    setProductStates((prevState) => ({ ...prevState, isOpen: false }));
  }, []);

  const handleOutsideClick = useCallback(() => {
    setProductStates((prevState) => ({ ...prevState, isOpen: false, isLoading: false }));
  }, []);

  return (
    <BoxContainer title="Details">
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                autoFocus
                type="text"
                inputMode="text"
                enterKeyHint="next"
                label="Product Name"
                placeholder="Enter name"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <Controller
            name="CAS_number"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                type="text"
                inputMode="numeric"
                enterKeyHint="next"
                label="CAS Number"
                placeholder="Enter CAS name"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <TextAreaLabel
                {...field}
                inputMode="text"
                label="Description"
                placeholder="Enter additional remarks/ requirements "
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="packaging_details"
            control={control}
            render={({ field, fieldState }) => (
              <TextAreaLabel
                {...field}
                inputMode="text"
                label="Packaging Details"
                placeholder="Enter packaging details"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="remarks"
            control={control}
            render={({ field, fieldState }) => (
              <TextAreaLabel
                {...field}
                inputMode="text"
                label="Additional Remarks"
                placeholder="Enter additional remarks"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        {!editMode ? (
          <div>
            <Typography variant="label" className={css.label}>
              Upload MSDS
            </Typography>
            <div className={css.uploadContainer}>
              <div className={css.uploadContainerRow}>
                <div style={{ display: 'flex' }}>
                  {productStates.docUploaded ? (
                    <IconNode
                      className={css.uploadSuccess}
                      src={Images.illustrationGreen}
                      alt="success icon"
                    />
                  ) : (
                    ``
                  )}
                  <Typography>MSDS</Typography>
                </div>
                <Controller
                  name="msds_doc"
                  control={control}
                  render={({ field, fieldState }) => (
                    <div>
                      <UploadButton
                        {...field}
                        required
                        value={''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setValue(
                            'msds_doc',
                            e?.currentTarget?.files?.length ? e?.currentTarget?.files[0] : null
                          )
                        }
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={css.rowWrapper}>
          <Controller
            name="hs_code"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="text"
                enterKeyHint="next"
                label="HS Code"
                placeholder="Enter HS Code"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>

        <div className={css.rowWrapper}>
          <label className={css.hazardousBoxWrapper}>
            {'' /* intentionally added */}
            <Controller
              name="haz"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value} value={`${field.value}`} />
              )}
            />
            <IconNode src={Images.hazardGrey} alt="Hazard Icon" />
            <Typography variant="p">This product is hazardous.</Typography>
          </label>
        </div>
      </div>
    </BoxContainer>
  );
};

export default ProductDetailContainer;
