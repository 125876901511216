import Images from '@assets/images';
import { Button, IconNode, Modal, Typography } from '@components/base';
import { DragDropUpload, InputDatePicker, SelectLabel, TextAreaLabel } from '@components/common';
import { ISelect } from '@helpers/types';
import { useCallback, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import css from './index.module.scss';
import TextField from '@components/common/text-field';
import { IDocumentMapping } from '..';

interface UploadDocumentProps {
  open: boolean;
  onClose: () => void;
  onFormSubmit: (data: any) => void;
  editMode?: boolean;
  availableDocumentType: ISelect;
  currentDoc?: any;
  documentMapping?: IDocumentMapping;
}

const UploadDocument = (props: UploadDocumentProps) => {
  const { open, onClose, onFormSubmit, editMode, availableDocumentType } = props;
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty, isSubmitting }
  } = useFormContext();

  const watch = useWatch({ name: 'document_object', control: control });

  const handleDeleteFile = useCallback(() => {
    setValue('document_object', null);
  }, []);

  const handleFileDrop = useCallback((file: any[]) => {
    if (file.length) {
      setValue('document_object', file[0]);
      /**
       * Prefill document_name as file name unless the document_type is OTHER
       */
      if (!(getValues('document_type')?.value == 'OTHER')) {
        setValue('document_name', file[0].name);
      } else {
        setValue('document_name', '');
      }
    }
  }, []);

  const filterDocumentMapping = Object.entries(props?.documentMapping || {}).filter(
    ([key, config]) => config.documentKey === parseInt(props?.currentDoc.doc_key ?? '0')
  );

  const documentFields = filterDocumentMapping.map(([key, config]) => {
    return {
      key: key,
      label: config.label,
      type: config.type,
      required: config.required,
      placeholder: config.placeholder,
      options: config?.options ?? []
    };
  });

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">{editMode ? 'Update' : 'Upload'} Document</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
          <section className={css.modalContent}>
            <Controller
              name="document_type"
              control={control}
              render={({ field }) => (
                <SelectLabel
                  {...field}
                  isDisabled
                  label="Document Type"
                  value={{
                    label: availableDocumentType.label,
                    value: availableDocumentType.value
                  }}
                />
              )}
            />
            {documentFields.map((documentKey) => {
              switch (documentKey.type) {
                case 'text':
                  return (
                    <Controller
                      key={documentKey.key}
                      name={documentKey.key}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required={documentKey.required}
                          label={documentKey.label}
                          placeholder={documentKey.placeholder}
                          error={fieldState.invalid}
                          rootClassName={css.typeField}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="Error Icon" />
                            )
                          }
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  );
                case 'select':
                  return (
                    <Controller
                      key={documentKey.key}
                      name={documentKey.key}
                      control={control}
                      render={({ field }) => (
                        <SelectLabel
                          {...field}
                          label={documentKey.label}
                          placeholder={documentKey.placeholder}
                          options={documentKey.options}
                          required={documentKey.required}
                          className={css.typeField}
                        />
                      )}
                    />
                  );
                case 'date':
                  return (
                    <Controller
                      key={documentKey.key}
                      name={documentKey.key}
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputDatePicker
                          {...field}
                          required={documentKey.required}
                          label={documentKey.label}
                          onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          rootClassName={css.typeField}
                        />
                      )}
                    />
                  );
                default:
                  return null;
              }
            })}
            <Typography variant="p">Document Description</Typography>
            <Controller
              name="remarks"
              control={control}
              render={({ field, fieldState }) => (
                <TextAreaLabel
                  {...field}
                  rows={3}
                  inputMode="text"
                  label=""
                  placeholder="Enter description"
                  rootClassName={css.typeField}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            <Controller
              name="document_object"
              control={control}
              render={() => <DragDropUpload onDrop={handleFileDrop} />}
            />
            {watch && (
              <div className={css.fileUploadState}>
                <Typography variant="p" className={css.fileName}>
                  {watch.name}
                </Typography>
                <IconNode
                  src={Images.deleteRed}
                  alt="delete icon"
                  component="button"
                  className={css.deleteButton}
                  onClick={handleDeleteFile}
                />
              </div>
            )}
            {editMode && watch && (
              <Controller
                name="reason_for_update"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required={true}
                    label="Reason for Re-Upload"
                    placeholder="Please provide a reason for re-uploading the document"
                    error={fieldState.invalid}
                    rootClassName={css.typeField}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          </section>
          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting || (editMode ? !isDirty : false)}>
              {editMode ? 'Update' : 'Save'}
            </Button>
          </section>
        </form>
      </div>
    </Modal>
  );
};

export default UploadDocument;
