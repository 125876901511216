import { Typography } from '@components/base';
import { getDiffBetweenTwoDates, getFormattedDate } from '@helpers/utils';

import css from './index.module.scss';

interface TaskFooterProps {
  createdDate: Date;
  updatedDate: Date;
}

const TaskFooter = (props: TaskFooterProps) => {
  const { createdDate, updatedDate } = props;

  const formattedUpdatedDate = updatedDate ? getFormattedDate(updatedDate) : null;
  const formattedCreatedDate = createdDate ? getFormattedDate(createdDate) : null;
  return (
    <>
      <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{formattedCreatedDate}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{formattedUpdatedDate}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {getDiffBetweenTwoDates(updatedDate as Date, createdDate as Date)} Day(s)
          </Typography>
        </div>
      </div>
    </>
  );
};

export default TaskFooter;
