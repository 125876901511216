import React, { useRef, useCallback, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import './index.style.scss';

interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  label?: string;
  error?: boolean;
  required?: boolean;
  labelClassName?: string;
  rootClassName?: string;
  helperText?: string;
  height?: number;
  placeholder?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({
  value,
  onChange,
  onBlur,
  label,
  error,
  required,
  labelClassName,
  rootClassName,
  helperText,
  height = 200,
  placeholder = 'Start Typing..',
  ...otherProps
}) => {
  const editorRef = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      height: height,
      placeholder: placeholder,
      toolbarAdaptive: false
    }),
    [height, placeholder]
  );

  const handleChange = useCallback(
    (newContent: string) => {
      onChange(newContent);
    },
    [onChange]
  );

  const helperTextClass = error ? 'helper-text-error' : undefined;
  const showAsterisk = required ? ' *' : undefined;

  return (
    <label className={`text-editor-root ${rootClassName}`}>
      {label && (
        <span data-value={showAsterisk} className={`text-editor-label ${labelClassName}`}>
          {label}
        </span>
      )}
      <JoditEditor
        ref={editorRef}
        value={value}
        config={config}
        onBlur={onBlur}
        onChange={handleChange}
        {...otherProps}
      />
      {helperText && <span className={`field-helperText ${helperTextClass}`}>{helperText}</span>}
    </label>
  );
};

export default React.memo(TextEditor);
