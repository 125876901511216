import * as yup from 'yup';

export const orderDetailFieldSchema = yup.object().shape({
  field_value: yup.string().required('Field value is required'),
  field_name: yup.string().required('Field name is required')
});

export const itemDetailFieldSchema = yup.object().shape({
  field_value: yup.string().optional().nullable()
});

export const orderDetailsSchema = yup.object().shape({
  order_details: yup.array().of(orderDetailFieldSchema).required('Order details is required')
});

export const additionalDetailsSchema = yup.object().shape({
  notes: yup.array().of(itemDetailFieldSchema).optional().nullable(),
  remarks: yup.array().of(itemDetailFieldSchema).optional().nullable(),
  desciption: yup.string().optional().nullable()
});
