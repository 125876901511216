// @ts-nocheck
import { MainLayout } from '@components/layouts';
import { useEffect, useState } from 'react';
import css from './index.module.scss';
import { BreadCrumb, Button } from '@components/base';
import { useNavigate, useParams } from 'react-router-dom';

const ContactUs = () => {
  const [poReferenceNo, setPoReferenceNo] = useState('');
  const params = new URLSearchParams(location.search);
  useEffect(() => {
    if (params.get('po_reference_no')) {
      setPoReferenceNo(params.get('po_reference_no'));
    }
  }, [location.search]);

  useEffect(() => {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

    const load = () => {
      // Load Tally embeds
      if (typeof Tally !== 'undefined') {
        Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document.querySelectorAll('iframe[data-tally-src]:not([src])').forEach((iframeEl) => {
        iframeEl.src = iframeEl.dataset.tallySrc;
      });
    };

    // If Tally is already loaded, load the embeds
    if (typeof Tally !== 'undefined') {
      load();
      return;
    }
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return;
    }
  }, []);

  const navigate = useNavigate();
  return (
    <div>
      <MainLayout />
      <div className={css.rfqTallyBackButton}>
        <BreadCrumb>
          <BreadCrumb.Item value={0} onClick={() => (poReferenceNo ? navigate(-2) : navigate(-1))}>
            Order List
          </BreadCrumb.Item>
          {poReferenceNo && (
            <BreadCrumb.Item value={1} onClick={() => navigate(-1)}>
              # {poReferenceNo}
            </BreadCrumb.Item>
          )}
          <BreadCrumb.Item value={2} onClick={() => navigate(-1)}>
            Contact Us
          </BreadCrumb.Item>
        </BreadCrumb>
      </div>
      <div className={css.rfqcontainer}>
        <iframe
          data-tally-src="https://tally.so/r/n0E9aP"
          loading="lazy"
          style={{
            height: '75vh',
            width: '80%',
            overflow: 'auto',
            border: 'none'
          }}
          className={css.tallyIframe}
          title="Newsletter subscribers"></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
