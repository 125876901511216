import { Button, Typography } from '@components/base';
import css from './index.module.scss';
import { useMemo, useState } from 'react';
import { Select } from '@components/base';
import { getCommaSepratedValue, getFormattedDate, sanitizeValue } from '@helpers/utils';
import { ReactComponent as ArrowDown } from '@assets/images/chevron-down.svg';
import { DeletePrompt, MultiplePlaceholder } from '@components/common';
import { Enquiry, IEmailSelect, IRFQProduct, ISelect } from '@helpers/types/enquiry';

interface IInternalEnquiryCardProps {
  data: Enquiry;
  handleClick: (enquiryId: string) => void;
  enquiryStatus: ISelect[];
  assignToPeople: IEmailSelect[];
  handleInternalAssignTo: (userId: string, enquiryId: string) => Promise<void>;
  handleInternalStatus: (status: number, enquiryId: string) => Promise<void>;
}

const InternalEnquiryCard = (props: IInternalEnquiryCardProps) => {
  const {
    data,
    handleClick,
    enquiryStatus,
    assignToPeople,
    handleInternalAssignTo,
    handleInternalStatus
  } = props;


  const statusDefaultValue = useMemo(
    () =>
      data?.status_display_value
        ? {
            label: data?.status_display_value,
            value: data?.status
          }
        : null,
    [data]
  );

  const assignToDefaultValue = useMemo(
    () =>
      data?.assigned_to_info
        ? {
            label: data.assigned_to_info?.full_name,
            value: data.assigned_to_info?.user_id
          }
        : null,
    [data]
  );
  const [remarkAccordion, setRemarkAccordion] = useState(false);
  const [openAssignPrompt, setOpenAssignPrompt] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null);

  const handleConfirmAssignToPrompt = () => {
    setOpenAssignPrompt(false);
    handleInternalAssignTo(selectedAssignment || '', data.id)
  }

  return (
    <div className={css.internalEnquiryCardContainer}>
      <div className={css.internalEnquiryCardHeader}>
        <div>
          <Typography variant="label">ID {sanitizeValue(data?.readable_id, 'Id Not Available')}</Typography>
          <Typography variant="h5">{sanitizeValue(data?.created_by_info?.full_name)}</Typography>
        </div>
        <div className={css.internalEnquiryCardHeaderSelectWrapper}>
          <Select
            isSearchable
            value={assignToDefaultValue}
            options={assignToPeople}
            isDisabled={data?.assigned_to_info === null ? false : true}
            placeholder="Assign Task"
            onChange={(e: any) => {
              setSelectedAssignment(e?.value);
              setOpenAssignPrompt(true);
            }}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            className={css.selectWrapper}
          />
          <Select
            isSearchable
            defaultValue={statusDefaultValue}
            options={enquiryStatus}
            placeholder="Status"
            onChange={(e: any) => {
              handleInternalStatus(e?.value, data.id);
            }}
            isOptionDisabled={(option:any) => option.disabled}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            className={css.selectWrapper}
          />
        </div>
        {openAssignPrompt && (
          <DeletePrompt
            open={openAssignPrompt}
            onClose={() => setOpenAssignPrompt(false)}
            onConfirm={handleConfirmAssignToPrompt}
            message="Are you sure you want to Assign?"
          />
        )}
      </div>
      <div className={css.internalEnquiryCardProductsWrapper}>
        <div className={css.internalEnquiryCardProducts}>
          <div>
            <Typography variant="label">Date</Typography>
            <Typography variant="body">
              {sanitizeValue(getFormattedDate(data?.created_at), 'Date Not Available')}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Customers</Typography>
            <Typography variant="body">
              <MultiplePlaceholder names={data?.created_by_info?.customers} label="Customers" />
            </Typography>
          </div>
          <div>
            <Typography variant="label">Place Of Delivery</Typography>
            <Typography variant="body">{sanitizeValue(data.place_of_delivery)}</Typography>
          </div>
        </div>
        {data?.products.map((product: IRFQProduct, index: number) => (
          <div key={index} className={css.internalEnquiryCardProducts}>
            <div>
              <Typography variant="label">Chemical Name</Typography>
              <Typography variant="body">{sanitizeValue(product?.name)}</Typography>
            </div>
            <div>
              <Typography variant="label">Price</Typography>
              <Typography variant="body">
                {product?.currency} {sanitizeValue(getCommaSepratedValue(product?.target_price))}
              </Typography>
            </div>
            <div>
              <Typography variant="label">Quantity</Typography>
              <Typography variant="body">
                {product?.quantity} {sanitizeValue(product?.unit)}
              </Typography>
            </div>
          </div>
        ))}
      </div>
      {!remarkAccordion ? (
        <div className={css.internalEnquiryRemarksClosedWrapper}>
          <div
            className={css.internalEnquiryRemarksClosed}
            onClick={() => data.remarks.length > 0 && setRemarkAccordion(!remarkAccordion)}>
            {data.remarks.length > 0 ? (
              <Typography variant="label">See remarks</Typography>
            ) : (
              <Typography variant="label">No Remarks Available</Typography>
            )}
            {data.remarks.length > 0 && (
              <ArrowDown onClick={() => setRemarkAccordion(!remarkAccordion)} />
            )}
          </div>
          <Button
            className={css.downloadButton}
            variant="outlined-secondary"
            disabled={data.documents.length === 0}
            onClick={() => handleClick(data?.id)}>
            Download
          </Button>
        </div>
      ) : (
        <div className={css.internalEnquiryRemarkOpenWrapper}>
          <div className={css.internalEnquiryRemarksOpen}>
            <div className={css.internalEnquiryRemarksLabel}>
              <Typography
                className={css.additionalRemarks}
                variant="label"
                onClick={() => setRemarkAccordion(!remarkAccordion)}>
                Additional Remarks{' '}
              </Typography>
              <ArrowDown onClick={() => setRemarkAccordion(!remarkAccordion)} />
            </div>
            <Typography variant="body">{sanitizeValue(data.remarks)}</Typography>
          </div>
          <Button
            className={css.downloadButton}
            variant="outlined-secondary"
            disabled={data.documents.length === 0}
            onClick={() => handleClick(data?.id)}>
            Download
          </Button>
        </div>
      )}
    </div>
  );
};

export default InternalEnquiryCard;
