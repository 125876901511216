import * as yup from 'yup';
import { selectSchema } from '.';
import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from '@helpers/constants';

const nullableNumberTransform = (value: any, originalValue: any) => {
  if (originalValue === '' || originalValue === null) {
    return null;
  }
  return value;
};
export const addCourierDetails = yup.object().shape({
  is_sent_to_customer: yup
    .mixed()
    .required('Please select one')
    .typeError('Please select a valid option'),
  communication_type: yup.mixed().when('is_sent_to_customer', {
    is: (value: any) => value?.value,
    then(schema) {
      return schema.required('Communication Type is required');
    },
    otherwise(schema) {
      return schema.optional().nullable();
    }
  }),
  serviceName: yup.string().when('communication_type', {
    is: (value: any) => value?.value == 'courier',
    then(schema) {
      return schema.typeError('Service Name should be string').required('Service Name is required');
    },
    otherwise(schema) {
      return schema.optional().nullable();
    }
  }),
  trackingName: yup.string().when('communication_type', {
    is: (value: any) => value?.value == 'courier',
    then(schema) {
      return schema
        .typeError('Tracking Number should be string')
        .required('Tracking Number is required');
    },
    otherwise(schema) {
      return schema.optional().nullable();
    }
  }),
  placeOfDelivery: yup.string().when('communication_type', {
    is: (value: any) => value?.value == 'courier',
    then(schema) {
      return schema
        .typeError('Place of Delivery should be string')
        .required('Place of Delivery is required');
    },
    otherwise(schema) {
      return schema.optional().nullable();
    }
  }),
  courierDate: yup.date().when('communication_type', {
    is: (value: any) => value?.value == 'courier',
    then(schema) {
      return schema.typeError('Courier date should be date').required('Courier date is required');
    },
    otherwise(schema) {
      return schema.optional().nullable();
    }
  }),
  numOfItems: yup.string().when('communication_type', {
    is: (value: any) => value?.value == 'courier',
    then(schema) {
      return schema
        .typeError('Number of Items should be number')
        .transform(nullableNumberTransform)
        .required('Number of Items is required');
    },
    otherwise(schema) {
      return schema.optional().nullable();
    }
  }),
  courierCharges: yup.string().when('communication_type', {
    is: (value: any) => value?.value == 'courier',
    then(schema) {
      return schema
        .typeError('Courier charges should be number')
        .transform(nullableNumberTransform)
        .required('Courier charges is required');
    },
    otherwise(schema) {
      return schema.optional().nullable();
    }
  }),
  notes: yup.string().typeError('Notes should be string').nullable().notRequired(),
  documents_courier_currency: yup.mixed().when('communication_type', {
    is: (value: any) => value?.value == 'courier',
    then(schema) {
      return schema.required('Currency is required');
    },
    otherwise(schema) {
      return schema.optional().nullable();
    }
  }),
  telex_message: yup.string().when('communication_type', {
    is: (value: any) => value?.value == 'telex',
    then(schema) {
      return schema.required('Telex message is required');
    },
    otherwise(schema) {
      return schema.optional().nullable();
    }
  }),
  telex_remarks: yup.string().optional().nullable(),
  ecgcDoc: yup.mixed().when('communication_type', {
    is: (value: any) => value?.value == 'courier',
    then(schema) {
      return schema
        .required('Document is required')
        .test('fileSize', 'File Size is too large', (value: any) =>
          value ? value?.size <= MAX_FILE_SIZE : true
        )
        .test('fileType', 'Unsupported File Format', (value: any) =>
          value ? SUPPORTED_FORMATS.includes(value?.type) : true
        );
    },
    otherwise(schema) {
      return schema.optional().nullable();
    }
  })
});
