import { useFormContext, Controller } from 'react-hook-form';
import { BoxContainer, TextAreaLabel } from '@components/common';
import css from './index.module.scss';

const DescriptionField = () => {
  const { control } = useFormContext();

  return (
    <BoxContainer title="Add Description" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.fieldDataWrapper}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextAreaLabel
                {...field}
                rows={6}
                label="Description"
                placeholder="Enter description"
                inputMode="text"
              />
            )}
          />
        </div>
      </div>
    </BoxContainer>
  );
};

export default DescriptionField;
