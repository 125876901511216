import { Image, Typography } from '@components/base';
import css from './index.module.scss';

import Images from '@assets/images';
import { SUPPORT_EMAIL } from '@helpers/utils';

interface UnauthorizedPageProps {
  showImage?: boolean;
}

const UnauthorizedPage = (props: UnauthorizedPageProps) => {
  const { showImage = true } = props;
  return (
    <div className={css.illustrationContainer}>
      {showImage ? (
        <Image className={css.illustration} src={Images.unauthorised} alt="401-unauthorised" />
      ) : (
        <></>
      )}
      <Typography>
        You are unauthorised to view this page, contact {SUPPORT_EMAIL} for more info.
      </Typography>
    </div>
  );
};

export default UnauthorizedPage;
