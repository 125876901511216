/* eslint-disable @typescript-eslint/no-empty-function */
import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { SHIPMENT_MAP, convertShipmentType } from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import { ShipmentDocument } from '@helpers/types/document';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import {
  getExportShipmentDocsById,
  getPostDocumentsById,
  getPreDocumentsById,
  updateExportClearance,
  updatePostShipment,
  updatePreShipment
} from '@services/order.service';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DocumentsTab from './components/documents-tab';
import css from './index.module.scss';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import ExportClearanceDateModal from './components/export-clearance-date-modal';
import { shipmentDateArrival } from '@helpers/yup/order-tasks.schema';
import { yupResolver } from '@hookform/resolvers/yup';

interface IPostShipmentDocs {
  taskData: Array<Partial<ITaskTabProps>>;
  shipmentType?: 'pre-shipment' | 'post-shipment' | 'export-clearance';
  onComplete?: () => void;
}

interface PostShipmentStates {
  isLoading: boolean;
  documentsInfo: ShipmentDocument[];
  openExportClearanceModal: boolean;
}

const sortShipmentDocs = (docList: ShipmentDocument[]) => {
  if (!docList) return [];
  const docsThatCannotBeUploaded = docList?.filter((item: ShipmentDocument) => !item.uploadable);
  const docsThatCanBeReuploaded = docList?.filter(
    (item: ShipmentDocument) => item.uploadable && item?.doc_link
  );
  const docsThatAreNotUploaded = docList?.filter(
    (item: ShipmentDocument) => item.uploadable && !item?.doc_link
  );
  const requiredDocsThatAreNotUploaded = docsThatAreNotUploaded.filter(
    (item: ShipmentDocument) => item.compulsory
  );
  const nonRequiredDocsThatAreNotUploaded = docsThatAreNotUploaded.filter(
    (item: ShipmentDocument) => !item.compulsory
  );

  return [
    ...requiredDocsThatAreNotUploaded,
    ...nonRequiredDocsThatAreNotUploaded,
    ...docsThatCanBeReuploaded,
    ...docsThatCannotBeUploaded
  ];
};

const ShipmentDocs = (props: IPostShipmentDocs) => {
  const { taskData, shipmentType } = props;
  const taskItem = taskData[0];
  const [shipmentData, setShipmentData] = useState<PostShipmentStates>({
    isLoading: false,
    documentsInfo: [],
    openExportClearanceModal: false
  });
  const { documentsInfo, openExportClearanceModal, isLoading } = shipmentData;

  const exportClearanceDateFormm = useForm<any>({
    resolver: yupResolver(shipmentDateArrival),
    defaultValues: {
      export_clearance_task_completed_date: null
    },
    shouldUnregister: true
  });

  useEffect(() => {
    getApiCall();
  }, []);

  const getApiCall = async () => {
    setShipmentData((prevState) => ({ ...prevState, isLoading: true }));
    switch (shipmentType) {
      case SHIPMENT_MAP.preShipment: {
        const response = await getPreDocumentsById(taskItem?.task_id as string);
        if (response?.success) {
          const { data: documentsList } = response;
          setShipmentData((prevState) => ({
            ...prevState,
            documentsInfo: sortShipmentDocs(documentsList),
            isLoading: false
          }));
        } else {
          setShipmentData((prevState) => ({ ...prevState, isLoading: false }));
          notify({
            message: response.error ?? 'Unable to fetch data',
            severity: 'error',
            dismissible: true
          });
        }
        break;
      }

      case SHIPMENT_MAP.postShipment: {
        const response = await getPostDocumentsById(taskItem?.task_id as string);
        if (response?.success) {
          const { data: documentsList } = response;
          setShipmentData((prevState) => ({
            ...prevState,
            documentsInfo: sortShipmentDocs(documentsList),
            isLoading: false
          }));
        } else {
          setShipmentData((prevState) => ({ ...prevState, isLoading: false }));
          notify({
            message: response.error ?? 'Unable to fetch data',
            severity: 'error',
            dismissible: true
          });
        }
        break;
      }

      case SHIPMENT_MAP.exportClearance: {
        const response = await getExportShipmentDocsById(taskItem?.task_id as string);
        if (response?.success) {
          const { data: documentsList } = response;
          setShipmentData((prevState) => ({
            ...prevState,
            documentsInfo: sortShipmentDocs(documentsList),
            isLoading: false
          }));
        } else {
          setShipmentData((prevState) => ({ ...prevState, isLoading: false }));
          notify({
            message: response.error ?? 'Unable to fetch data',
            severity: 'error',
            dismissible: true
          });
        }
        break;
      }

      default:
        break;
    }
  };

  const handleConfirmSubmit = async () => {
    switch (shipmentType) {
      case SHIPMENT_MAP.preShipment: {
        const res = await updatePreShipment(taskItem.task_id as string);
        const { data, error, success } = res;
        if (success) {
          notify({ message: data.message, severity: 'success', dismissible: true });
          getApiCall();
          window.location.reload();
        } else {
          notify({
            message: error ?? 'Something went wrong',
            severity: 'error',
            dismissible: true
          });
        }
        break;
      }

      case SHIPMENT_MAP.postShipment: {
        const res = await updatePostShipment(taskItem.task_id as string);
        const { data, error, success } = res;
        if (success) {
          notify({ message: data.message, severity: 'success', dismissible: true });
          getApiCall();
          window.location.reload();
        } else {
          notify({
            message: error ?? 'Something went wrong',
            severity: 'error',
            dismissible: true
          });
        }
        break;
      }

      case SHIPMENT_MAP.exportClearance: {
        setShipmentData((prevState) => ({ ...prevState, openExportClearanceModal: true }));
        break;
      }

      default:
        return;
    }
  };

  const handleExportClearanceSubmit = async (formData: any) => {
    const res = await updateExportClearance(taskItem.task_id as string, {
      export_clearance_task_completed_date: moment(
        formData.export_clearance_task_completed_date
      ).format('YYYY-MM-DD')
    });
    const { data, error, success } = res;
    if (success) {
      notify({ message: data.message, severity: 'success', dismissible: true });
      getApiCall();
      window.location.reload();
    } else {
      notify({
        message: error ?? 'Something went wrong',
        severity: 'error',
        dismissible: true
      });
    }
  };

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskItem.is_completed ? (
                <Avatar src={Images.illustration} alt="" />
              ) : (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              )}
              <div>
                <Link to={`/order/${taskItem?.order}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="h5" className={css.title}>
                    #{taskItem?.readable_task_id} - {convertShipmentType(shipmentType as string)}{' '}
                    Upload Documents{' '}
                  </Typography>
                </Link>
              </div>
            </div>
            <div className={css.pocWrapper}>
              {!taskItem?.is_completed ? (
                <Button
                  variant="outlined"
                  className={css.confirmBtn}
                  disabled={taskItem?.is_completed}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event?.stopPropagation();
                    handleConfirmSubmit();
                  }}>
                  Confirm
                </Button>
              ) : (
                <>
                  {/* <DocumentRow.Zip
                    document={taskItem?.documents_zip as string}
                    title="Download all files"
                  /> */}
                </>
              )}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordianContent}>
          <div className={css.docContainer}>
            <DocumentsTab
              documents={documentsInfo}
              taskId={taskItem?.task_id as string}
              getApiCall={getApiCall}
              taskItem={taskItem}
              orderId={taskItem?.order as string}
              documentDataLoading={isLoading}
            />
          </div>
        </Accordion.Content>
      </Accordion>
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
      <FormProvider {...exportClearanceDateFormm}>
        <ExportClearanceDateModal
          open={openExportClearanceModal}
          onClose={() =>
            setShipmentData((prevState) => ({ ...prevState, openExportClearanceModal: false }))
          }
          onFormSubmit={handleExportClearanceSubmit}
        />
      </FormProvider>
    </div>
  );
};

export default ShipmentDocs;
