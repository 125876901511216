import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import css from './index.module.scss';
import { BreadCrumb, Divider, Loader, Skeleton, Tab, Tabs } from '@components/base';
import { POCSkeletonCard, Seo } from '@components/common';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';
import { getSupplierDetail } from '@services/supplier.service';
import DocumentTab from './components/documents-tab';
import OrderTab from './components/order-tab';
import Products from './components/products';
import SupplierDetailCard from './components/supplier-detail-card';
import SupplierDetailTab from './components/supplier-detail-tab';
import { ISupplier } from '@helpers/types/supplier';
import POCDetail from './components/poc-detail';
import AccessWrapper from '@authorization/access-wrapper';
import { MODULE_NAMES } from '@helpers/constants';
import { useScrollToTop } from '@helpers/hooks';

interface SupplierIndividualStates {
  tabState: number;
  supplierInfo: ISupplier | null;
  loading: boolean;
}

const SUPPLIER_MAPPING = [
  {
    value: 0,
    key: 'documents'
  },
  {
    value: 1,
    key: 'order'
  },
  {
    value: 2,
    key: 'supplier_details',
    default: true
  },
  {
    value: 3,
    key: 'products'
  }
];

const getDefaultTab = () => {
  const defaultTab = SUPPLIER_MAPPING.find((tab) => tab.default);
  return defaultTab ? defaultTab : SUPPLIER_MAPPING[0];
};
const defaultTab = getDefaultTab();

const SupplierIndividual = (props: any) => {
  const { modulePermissions = {}, otherModulePermissions = [] } = props;
  const { actions } = modulePermissions;
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabParam = searchParams.get('activeTab');
  const initialSupplierState = useMemo(() => {
    return getValueByKey(activeTabParam || defaultTab.key);
  }, [activeTabParam]);
  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('popstate', () => {
      navigate(0);
    });
  }, [activeTabParam]);

  const orderActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.order)?.actions,
    [otherModulePermissions]
  );
  const productActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.product)?.actions,
    [otherModulePermissions]
  );

  function getValueByKey(key: string) {
    const supplier = SUPPLIER_MAPPING.find((item) => item.key === key);
    return supplier ? supplier.value : defaultTab.value;
  }

  const [supplierState, setSupplierState] = useState<SupplierIndividualStates>({
    tabState: initialSupplierState,
    supplierInfo: null,
    loading: false
  });

  const { tabState, supplierInfo, loading } = supplierState;

  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    fetchSupplierDetail();
  }, []);

  const fetchSupplierDetail = async () => {
    setSupplierState((prevState) => ({ ...prevState, loading: true }));
    const response = await getSupplierDetail(`${params.id}`);
    if (response.success)
      setSupplierState((prevState) => ({
        ...prevState,
        supplierInfo: response.data,
        loading: false
      }));
    else navigate('/404-not-found');
  };

  const handleTabChange = useCallback((newValue: number) => {
    const queryKey = SUPPLIER_MAPPING.find((state) => state.value === newValue);
    setSupplierState((prevState) => ({ ...prevState, tabState: newValue }));
    setSearchParams({
      ...Object.fromEntries(searchParams),
      activeTab: queryKey?.key ?? defaultTab.key
    });
  }, []);

  const handleFormEdit = useCallback((param: ISupplier) => {
    setSupplierState((prevState) => ({ ...prevState, supplierInfo: { ...param } }));
  }, []);

  const handleChange = (data: ISupplier) => {
    setSupplierState((prevState) => ({ ...prevState, supplierInfo: { ...data } }));
  };

  useScrollToTop({ topRef, dependencyArray: [tabState] });

  return (
    <AccessWrapper show={actions?.read}>
      {loading ? (
        <SupplierIndividualSkeleton />
      ) : (
        <main className={css.supplierWrapper}>
          {supplierInfo && <Seo title={supplierInfo.supplier_name} />}
          {supplierInfo && (
            <BreadCrumb>
              <BreadCrumb.Item value={0} to={`/${CLIENT_ROUTES.supplier}`}>
                Supplier List
              </BreadCrumb.Item>
              <BreadCrumb.Item value={1} to={`/${CLIENT_ROUTES.supplier}/${params.id}`}>
                {supplierInfo.vendor_id}
              </BreadCrumb.Item>
            </BreadCrumb>
          )}
          {supplierInfo && (
            <SupplierDetailCard
              supplierName={supplierInfo.supplier_name}
              phoneNumber={supplierInfo.supplier_phone_number}
              landlineNumber={supplierInfo.supplier_landline}
              location={supplierInfo.addresses}
            />
          )}
          {supplierInfo && (
            <POCDetail
              supplierId={supplierInfo.supplier_id}
              pocs={supplierInfo.supplier_pocs}
              onChange={handleChange}
            />
          )}
          <main ref={topRef} className={css.supplierTabContainer}>
            {supplierInfo && (
              <Tabs value={tabState}>
                <Tab label="Documents" value={0} onClick={() => handleTabChange(0)} />
                <AccessWrapper show={orderActions?.read}>
                  <Tab label="Orders" value={1} onClick={() => handleTabChange(1)} />
                </AccessWrapper>
                <Tab label="Supplier Details" value={2} onClick={() => handleTabChange(2)} />
                <AccessWrapper show={productActions?.read && supplierInfo?.type == 1}>
                  <Tab label="Products" value={3} onClick={() => handleTabChange(3)} />
                </AccessWrapper>
              </Tabs>
            )}
            {tabState === 0 && supplierInfo && (
              <DocumentTab supplierInfo={supplierInfo} onChange={handleFormEdit} />
            )}
            {tabState === 1 && supplierInfo && <OrderTab supplierId={supplierInfo.supplier_id} />}
            {tabState === 2 && supplierInfo && (
              <SupplierDetailTab
                supplierInfo={supplierInfo}
                onFormEdit={handleFormEdit}
                actions={actions}
                type={supplierInfo.type}
              />
            )}
            {tabState === 3 && supplierInfo && <Products supplierInfo={supplierInfo} />}
          </main>
        </main>
      )}
    </AccessWrapper>
  );
};

export default SupplierIndividual;

const SupplierIndividualSkeleton = () => {
  return (
    <div className={css.supplierIndividualSkeletonWrapper}>
      <div className={css.supplierIndividualSkeletonHeader}>
        <div className={css.supplierIndividualSkeletonHeaderLeft}>
          <Skeleton variant="circular" width={100} height={100} />
          <Skeleton variant="text" width={400} height={20} />
        </div>
        <Divider className={css.divider} />
        <div className={css.supplierIndividualSkeletonHeaderRight}>
          <Skeleton variant="text" width={400} height={20} />
          <Skeleton variant="text" width={400} height={20} />
          <Skeleton variant="text" width={400} height={20} />
        </div>
      </div>
      <div className={css.suppplierIndividualSkeletonBody}>
        <div className={css.suppplierIndividualSkeletonBodyUpper}>
          <Skeleton variant="text" width={150} height={20} />
          <Skeleton variant="text" width={150} height={20} />
        </div>
        <POCSkeletonCard />
      </div>
      <div className={css.supplierIndividualSkeletonFooter}>
        <div className={css.supplierIndividualSkeletonFooterUpper}>
          <Skeleton variant="text" width={150} height={20} />
          <Skeleton variant="text" width={150} height={20} />
          <Skeleton variant="text" width={150} height={20} />
          <Skeleton variant="text" width={150} height={20} />
        </div>
        <div className={css.supplierIndividualSkeletonFooterUpper}>
          <Skeleton variant="text" width={150} height={20} />
          <Skeleton variant="text" width={150} height={20} />
        </div>
        {/* <div className={css.supplierIndividualSkeletonFooter}>
          <div className={css.supplierIndividualSkeletonSidebar}>
            <Skeleton variant="text" width={250} height={20} />
            <Skeleton variant="text" width={250} height={20} />
            <Skeleton variant="text" width={250} height={20} />
            <Skeleton variant="text" width={250} height={20} />
            <Skeleton variant="text" width={250} height={20} />
            <Skeleton variant="text" width={250} height={20} />
            <Skeleton variant="text" width={250} height={20} />
            <Skeleton variant="text" width={250} height={20} />
            <Skeleton variant="text" width={250} height={20} />
          </div>
          <Divider className={css.divider} />
          <div className={css.supplierIndividualSkeletonDetails}>
            <Skeleton variant="text" width={400} height={20} />
            <div className={css.supplierIndividualSkeletonDetailsBody}>
              <div className={css.supplierIndividualSkeletonDetailsBodyObject}>
                <Skeleton variant="text" width={250} height={20} />
                <Skeleton variant="text" width={250} height={20} />
              </div>
              <div className={css.supplierIndividualSkeletonDetailsBodyWrapper}>
                <Divider className={css.divider} />
                <div className={css.supplierIndividualSkeletonDetailsBodyObject}>
                  <Skeleton variant="text" width={250} height={20} />
                  <Skeleton variant="text" width={250} height={20} />
                </div>
              </div>
              <div className={css.supplierIndividualSkeletonDetailsBodyWrapper}>
                <Divider className={css.divider} />
                <div className={css.supplierIndividualSkeletonDetailsBodyObject}>
                  <Skeleton variant="text" width={250} height={20} />
                  <Skeleton variant="text" width={250} height={20} />
                </div>
              </div>
            </div>
            <div className={css.supplierIndividualSkeletonDetailsBody}>
              <div className={css.supplierIndividualSkeletonDetailsBodyObject}>
                <Skeleton variant="text" width={250} height={20} />
                <Skeleton variant="text" width={250} height={20} />
              </div>
              <div className={css.supplierIndividualSkeletonDetailsBodyWrapper}>
                <Divider className={css.divider} />
                <div className={css.supplierIndividualSkeletonDetailsBodyObject}>
                  <Skeleton variant="text" width={250} height={20} />
                  <Skeleton variant="text" width={250} height={20} />
                </div>
              </div>
              <div className={css.supplierIndividualSkeletonDetailsBodyWrapper}>
                <Divider className={css.divider} />
                <div className={css.supplierIndividualSkeletonDetailsBodyObject}>
                  <Skeleton variant="text" width={250} height={20} />
                  <Skeleton variant="text" width={250} height={20} />
                </div>
              </div>
            </div>
            <div className={css.supplierIndividualSkeletonDetailsBody}>
              <div className={css.supplierIndividualSkeletonDetailsBodyObject}>
                <Skeleton variant="text" width={250} height={20} />
                <Skeleton variant="text" width={250} height={20} />
              </div>
              <div className={css.supplierIndividualSkeletonDetailsBodyWrapper}>
                <Divider className={css.divider} />
                <div className={css.supplierIndividualSkeletonDetailsBodyObject}>
                  <Skeleton variant="text" width={250} height={20} />
                  <Skeleton variant="text" width={250} height={20} />
                </div>
              </div>
              <div className={css.supplierIndividualSkeletonDetailsBodyWrapper}>
                <Divider className={css.divider} />
                <div className={css.supplierIndividualSkeletonDetailsBodyObject}>
                  <Skeleton variant="text" width={250} height={20} />
                  <Skeleton variant="text" width={250} height={20} />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
