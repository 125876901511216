import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import css from './index.module.scss';
import { Typography, Badge, Divider, Drawer, ToolTip } from '@components/base';
import {
  LinkButton,
  Seo,
  SearchBar,
  ListPlaceholder,
  SearchPlaceholder,
  DataGrid,
  PaginationScroller,
  TableSkeleton
} from '@components/common';
import { CLIENT_ROUTES } from '@router/routes';
import { ProductInfo } from '@helpers/types/product';
import { fetchAllProducts } from '@services/product.service';
import debounce from 'lodash.debounce';
import { Column, CellProps } from 'react-table';
import notify from '@helpers/toastify-helper';
import AccessWrapper from '@authorization/access-wrapper';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useScrollToTop } from '@helpers/hooks';
import { useSearchParams } from 'react-router-dom';

interface ProductHomeState {
  productCount: number;
  showFilterDrawer: boolean;
  searchText: string;
  searchLoading: boolean;
  productList: ProductInfo[];
  isLoading: boolean;
  hasNext: boolean;
  currentPageNumber: number;
  retry: boolean;
}

interface ProductTable {
  name: ProductInfo;
  CAS_number: string;
  grades: string;
  grade_document: string;
  readable_product_id: string;
}

const ProductHome = (props: any) => {
  const { actions } = props.modulePermissions;
  const debounceSearch = useRef<any>();
  const rootContainer = useRef<any>(null);
  const topRef = useRef<HTMLElement>(null);
  const [itemLimit, setItemLimit] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPageNumber = parseInt(searchParams.get('page') || '1');
  const [productState, setProductState] = useState<ProductHomeState>({
    productCount: 0,
    showFilterDrawer: false,
    searchLoading: false,
    productList: [],
    isLoading: false,
    searchText: '',
    hasNext: false,
    currentPageNumber: initialPageNumber,
    retry: false
  });

  const {
    productCount,
    searchLoading,
    productList,
    isLoading,
    currentPageNumber,
    hasNext,
    retry,
    searchText,
    showFilterDrawer
  } = productState;

  const getProductList = async (pageNumber: number, name?: string) => {
    const { searchText } = productState;
    const response = await fetchAllProducts(pageNumber, null, name ?? searchText);
    if (response.success) {
      const { data } = response;
      setProductState((prevState) => ({
        ...prevState,
        productList: data.results,
        productCount: data.count,
        hasNext: !!data.next,
        currentPageNumber: pageNumber,
        retry: false,
        searchLoading: false
      }));
    } else {
      setProductState((prevState) => ({ ...prevState, retry: true, searchLoading: false }));
      notify({ message: response.error ?? 'Unable to fetch product list ', severity: 'error' });
    }
  };

  const handleFilterClick = () => {
    setProductState((prevState) => ({
      ...prevState,
      showFilterDrawer: !prevState.showFilterDrawer
    }));
  };

  const fetchSearchResult = async (searchText: string) => {
    const response = await fetchAllProducts(1, null, searchText);
    if (response.success) {
      const { data } = response;
      setProductState((prevState) => ({
        ...prevState,
        productList: data.results,
        productCount: data.count,
        hasNext: !!data.next,
        currentPageNumber: 1,
        retry: false,
        searchLoading: false
      }));
    } else {
      setProductState((prevState) => ({
        ...prevState,
        retry: true,
        searchLoading: false
      }));
      notify({ message: response.error ?? 'Unable to fetch product list ', severity: 'error' });
    }
  };

  const handleProductSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setProductState((prevState) => ({ ...prevState, searchText: value, searchLoading: true }));
    debounceSearch.current?.cancel();
    debounceSearch.current = debounce(fetchSearchResult, 800);
    debounceSearch.current(value);
  };

  const getDocumentCount = (param: ProductInfo) => {
    let uploadedDocumentCount = 0;
    if (param?.msds_doc) {
      uploadedDocumentCount++;
    }
    return `${uploadedDocumentCount}/1`;
  };

  const [productColumn, productData] = useMemo(() => {
    const column: Column<ProductTable>[] = [
      {
        Header: 'Product Name',
        accessor: 'name',
        Cell: (props: CellProps<ProductTable>) => {
          const { value } = props;
          return (
            <LinkButton to={value.product_id} variant="text" className={css.productButton}>
              <Typography variant="body">{value.name}</Typography>
            </LinkButton>
          );
        }
      },
      {
        Header: 'Product ID',
        accessor: 'readable_product_id'
      },
      {
        Header: 'CAS Number',
        accessor: 'CAS_number',
        Cell: (props: CellProps<ProductTable>) => {
          const { value } = props;
          return <Typography variant="body">{value}</Typography>;
        }
      },
      {
        Header: 'No of Grades',
        accessor: 'grades',
        Cell: (props: CellProps<ProductTable>) => {
          const { value } = props;
          return <Typography variant="body">{value}</Typography>;
        }
      },
      {
        Header: 'Uploaded Docments',
        accessor: 'grade_document',
        Cell: (props: CellProps<ProductTable>) => {
          const { value } = props;
          return (
            <div className={css.doucmentCountWrapper}>
              <Typography variant="body">{value}</Typography>
              {/* <IconNode src={Images.infoGrey} alt="info icon" /> */}
            </div>
          );
        }
      }
    ];
    const row: ProductTable[] = productList.map((item) => ({
      name: item,
      CAS_number: item.CAS_number,
      grades: `${item.grades.length}`,
      grade_document: getDocumentCount(item),
      readable_product_id: item?.product_readable_id ?? '-'
    }));
    return [column, row];
  }, [productState.productList]);

  const changePageNumber = (event: any, value: number) => {
    if (value === 0 || value > Math.ceil(productCount / itemLimit)) return;
    setProductState((prevState) => ({
      ...prevState,
      currentPageNumber: value
    }));
  };

  const handlePageChange = async (newPage: number) => {
    setProductState((prev) => ({ ...prev, isLoading: true }));
    await getProductList(newPage, searchText);
    setSearchParams({ ...Object.fromEntries(searchParams), page: newPage.toString() });
    setProductState((prevState) => ({ ...prevState, isLoading: false }));
  };

  useEffect(() => {
    handlePageChange(currentPageNumber);
  }, [currentPageNumber]);

  useScrollToTop({ topRef, dependencyArray: [currentPageNumber] });

  return (  
    <AccessWrapper show={actions?.read} showUnauthorised>
      <main ref={rootContainer} className={css.productWrapper}>
        <Seo title="Products" />
        <div className={css.productHeader}>
          <div className={css.productTextWrapper}>
            <Typography variant="h2">Products</Typography>
            <Badge>{productCount}</Badge>
          </div>
          <AccessWrapper show={actions?.create}>
            <LinkButton
              variant="contained"
              to={`${CLIENT_ROUTES.addProduct}`}
              className={css.addProductButton}>
              Add Product
            </LinkButton>
          </AccessWrapper>
        </div>
        <Divider className={css.productDivider} />
        <div className={css.productFilterWrapper}>
          {/* <Button
          className={css.productFilterButton}
          variant="outlined"
          startIcon={<IconNode src={Images.filter} alt="filter icon" />}
          onClick={handleFilterClick}>
          Filter
        </Button> */}
          <SearchBar
            placeholder="Search for Product"
            value={searchText}
            className={css.productSearch}
            onChange={handleProductSearch}
            isLoading={searchLoading}
          />
          <div className={css.paginationFilter}>
            <ToolTip title="Previous Page">
              <ArrowBackIosIcon
                sx={{
                  height: '40px',
                  cursor: currentPageNumber > 1 ? 'pointer' : 'default',
                  color: currentPageNumber > 1 ? '#000000' : '#e2e2e2'
                }}
                onClick={() => changePageNumber(null, currentPageNumber - 1)}
              />
            </ToolTip>
            <Typography className={css.pageOfTotalPage}>
              {currentPageNumber} of {Math.ceil(productCount / itemLimit)}
            </Typography>
            <ToolTip title="Next Page">
              <ArrowForwardIosIcon
                sx={{
                  height: '40px',
                  cursor:
                    currentPageNumber < Math.ceil(productCount / itemLimit) ? 'pointer' : 'default',
                  color:
                    currentPageNumber < Math.ceil(productCount / itemLimit) ? '#000000' : '#e2e2e2'
                }}
                onClick={() => changePageNumber(null, currentPageNumber + 1)}
              />
            </ToolTip>
          </div>
        </div>
        <section className={css.productSection} ref={topRef}>
          {isLoading ? (
            <TableSkeleton colsNum={5} rowsNum={15} />
          ) : productList.length ? (
            <DataGrid columns={productColumn} data={productData} />
          ) : searchText ? (
            <SearchPlaceholder searchText={searchText} />
          ) : (
            !isLoading && (
              <ListPlaceholder
                to={`${CLIENT_ROUTES.addProduct}`}
                title="No Product Added Yet"
                supportingText="When a Product is added by you,
          they will show up here."
                buttonText="Add Product"
              />
            )
          )}
          <PaginationScroller
            variant="text"
            defaultPage={1}
            count={productCount}
            pageLimit={itemLimit}
            page={currentPageNumber}
            onChange={changePageNumber}
          />
        </section>
        <Drawer open={showFilterDrawer} anchor="right" onClose={handleFilterClick}>
          <Typography variant="span">Product</Typography>
        </Drawer>
      </main>
    </AccessWrapper>
  );
};

export default ProductHome;
