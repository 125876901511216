import { CSSProperties, Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { IconNode, Typography } from '@components/base';
import Images from '@assets/images';
import { OrderItem, OrderInfo } from '@helpers/types/order';
import { CURRENCY, PAYMENT_TERMS, SCREEN_TYPE } from '@helpers/constants';
import {
  checkScreenType,
  getCommaSeperatedCurrencyAmount,
  getFormattedDate,
  sanitizeValue
} from '@helpers/utils';
import StepProgressBar from '@components/common/progress-bar/progress-bar-step';
import { MultiplePlaceholder } from '@components/common';
import RenderChildrenBasedOnScreen from 'src/hoc/screen-conditions';

interface OrderCardProps {
  customerName: string;
  items: OrderItem[];
  paymentTerm: string;
  placeOfDelivery: string;
  createdAt: Date;
  totalAmount: string;
  amountDue: string;
  currentState: string;
  cancelStatus?: any;
  orderInfo: OrderInfo;
  handleTabChange: (newValue: number) => void;
  setOrderOverviewSectionValue?: React.Dispatch<React.SetStateAction<number>>;
}

interface ProgressBardState {
  labelList: any[];
  stepLabels: number[][];
  isCurrent: number;
}

const CustomerOrderCard = (props: OrderCardProps) => {
  const {
    customerName,
    placeOfDelivery,
    createdAt,
    totalAmount,
    amountDue,
    currentState,
    orderInfo,
    paymentTerm,
    handleTabChange,
    setOrderOverviewSectionValue
  } = props;
  const { currency } = orderInfo;

  const [progressBarState, setProgressBarState] = useState<ProgressBardState>({
    labelList: [],
    stepLabels: [],
    isCurrent: 0
  });

  const { labelList, stepLabels, isCurrent } = progressBarState;

  const { estimatedDateValue, primaryTitle } = useMemo(() => {
    const shipmentArrivalEstimate = orderInfo?.task_estimates?.find(
      (estimate) => estimate.state_type === 'Shipment Arrival at POD'
    );

    const shipmentDepartureEstimate = orderInfo?.task_estimates?.find(
      (estimate) => estimate.state_type === 'Shipment Departure from POL'
    );

    const estimatedDateValue = shipmentArrivalEstimate
      ? shipmentArrivalEstimate.estimated_date_value
      : shipmentDepartureEstimate?.estimated_date_value;

    const primaryTitle = shipmentArrivalEstimate
      ? 'Estimated Arrival Date'
      : 'Estimated Departure Date';

    return { estimatedDateValue, primaryTitle };
  }, [orderInfo?.task_estimates]);

  const supplierName = useMemo(() => {
    const { items } = props;
    const names = items
      .filter((data) => data?.supplier_name !== undefined)
      .map((data) => data.supplier_name);
    return <MultiplePlaceholder names={names} />;
  }, [props.items]);

  const productName = useMemo(() => {
    const { items } = props;
    const names = items.map((data) => data.product_name);
    if (checkScreenType() === SCREEN_TYPE.mobile.label) {
      return names?.length > 1 ? names.join(', ') : names[0];
    } else {
      return <MultiplePlaceholder names={names} />;
    }
  }, [props.items]);

  // const paymentTerms = useMemo(() => {
  //   const { paymentTerm } = props;
  //   const term = PAYMENT_TERMS.find((item) => item.value === paymentTerm);
  //   if (term) return term.label;
  //   return '-';
  // }, [props.paymentTerm]);

  const formattedCreatedAt = useMemo(() => {
    return getFormattedDate(createdAt);
  }, [createdAt]);

  const formattedTotalAmount = getCommaSeperatedCurrencyAmount(currency, totalAmount);
  const formattedAmountDue = getCommaSeperatedCurrencyAmount(currency, amountDue);

  const cancelStatus = useMemo(() => {
    return {
      is_cancelled: orderInfo?.is_cancelled,
      cancel_remarks: orderInfo?.cancel_remarks,
      last_updated_by: orderInfo?.last_updated_by,
      updated_at: getFormattedDate(orderInfo?.updated_at)
    };
  }, [orderInfo]);

  useEffect(() => {
    if (orderInfo?.customer_state_activity_log) {
      let labels = orderInfo?.customer_state_activity_log.map((item: any) => {
        return {
          label: item.state_name,
          date:
            getFormattedDate(
              item.actual_date ? item.actual_date : item.estimated_date ? item.estimated_date : ''
            ) ?? '',
          logs: item.logs?.map((log: any) => {
            return {
              label: log.log,
              date: getFormattedDate(log.date ?? '') ?? ''
            };
          }),
          is_current_state: item.is_current_state
        };
      });

      let isCurrentState = orderInfo?.is_order_completed
        ? Number.MAX_VALUE
        : orderInfo?.customer_state_activity_log.findIndex(
            (item: any) => item.is_current_state === true
          );
      if (checkScreenType() === SCREEN_TYPE.mobile.label) {
        if (isCurrentState != 0 && isCurrentState != labels.length - 1) {
          labels = [labels[0], labels[isCurrentState], labels[labels.length - 1]];
          isCurrentState = 1;
        } else {
          labels = [labels[0], labels[labels.length - 1]];
          isCurrentState = isCurrentState == 0 ? 0 : 1;
        }
      }
      setProgressBarState({
        labelList: labels,
        stepLabels: stepLabels,
        isCurrent: isCurrentState
      });
    }
  }, [orderInfo]);

  /*
  This function navigates from any tab to the 'Order Overview' tab and scrolls to a specified reference. 
  To use this function, provide the reference of the target component.
*/
  const handleOrderOverviewRefClick = (value: number) => {
    setOrderOverviewSectionValue && setOrderOverviewSectionValue(value);
    handleTabChange(0);
  };

  return (
    <div className={css.orderCardContainer}>
      {cancelStatus.is_cancelled ? (
        <div className={css.cancelWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="p" className={css.cancelOrderTitle}>
              This order has been cancelled on {cancelStatus.updated_at}
            </Typography>
          </div>
        </div>
      ) : (
        <></>
      )}

      <RenderChildrenBasedOnScreen showOnWeb>
        <div className={css.orderCardFirstWrapper}>
          <UserCard src={Images.productGrey} primaryTitle="Product" secondaryTitle={productName} />
          <UserCard
            showInfoIcon={false}
            showPrimaryIcon={false}
            primaryTitle="Proforma Invoice"
            secondaryTitle={orderInfo?.pi_document_no ?? '-'}
          />
          <UserCard
            showInfoIcon={false}
            showPrimaryIcon={false}
            primaryTitle="PO Reference Number"
            secondaryTitle={orderInfo?.po_reference_no ?? '-'}
          />
        </div>
      </RenderChildrenBasedOnScreen>
      <RenderChildrenBasedOnScreen showOnMobile>
        <div className={css.orderCardFirstWrapper}>
          <div className={css.orderCardTitle}>
            <UserCard
              src={Images.productGrey}
              primaryTitle="Product"
              secondaryTitle={productName}
            />
          </div>
          <div className={css.orderCardDetailWrapper}>
            <UserCard
              showInfoIcon={false}
              showPrimaryIcon={false}
              primaryTitle="Proforma Invoice"
              secondaryTitle={orderInfo?.pi_document_no ?? '-'}
            />
            <UserCard
              showInfoIcon={false}
              showPrimaryIcon={false}
              primaryTitle="PO Reference Number"
              secondaryTitle={orderInfo?.po_reference_no ?? '-'}
            />
          </div>
        </div>
      </RenderChildrenBasedOnScreen>
      <RenderChildrenBasedOnScreen showOnMobile>
        <div className={css.orderCardFirstWrapper}>
          <div className={css.orderCardDetailWrapper}>
            <UserCard
              primaryTitle="Payment Terms"
              secondaryTitle={paymentTerm}
              showPrimaryIcon={false}
              showInfoIcon={false}
            />
            <UserCard
              primaryTitle="Due Payment/Total Payment"
              secondaryTitle={`${formattedAmountDue}/${formattedTotalAmount}`}
              showPrimaryIcon={false}
              showInfoIcon={false}
              onClick={() => handleOrderOverviewRefClick(6)}
            />
          </div>
          <div className={css.orderCardDetailWrapper}>
            <UserCard
              primaryTitle="Latest Payment Date"
              secondaryTitle={getFormattedDate(orderInfo?.latest_payment_date) ?? '-'}
              showPrimaryIcon={false}
              showInfoIcon={false}
            />
            <UserCard
              primaryTitle={primaryTitle}
              secondaryTitle={sanitizeValue(getFormattedDate(estimatedDateValue))}
              showPrimaryIcon={false}
              showInfoIcon={false}
            />
          </div>
        </div>
      </RenderChildrenBasedOnScreen>
      <RenderChildrenBasedOnScreen showOnWeb>
        <div className={css.orderCardFirstWrapper}>
          <UserCard
            primaryTitle="Payment Terms"
            secondaryTitle={paymentTerm}
            showPrimaryIcon={false}
            showInfoIcon={false}
          />
          <UserCard
            primaryTitle="Due Payment/Total Payment"
            secondaryTitle={`${formattedAmountDue}/${formattedTotalAmount}`}
            showPrimaryIcon={false}
            showInfoIcon={false}
            onClick={() => handleOrderOverviewRefClick(6)}
          />
          <UserCard
            primaryTitle="Latest Payment Date"
            secondaryTitle={getFormattedDate(orderInfo?.latest_payment_date) ?? '-'}
            showPrimaryIcon={false}
            showInfoIcon={false}
          />
          <UserCard
            primaryTitle={primaryTitle}
            secondaryTitle={sanitizeValue(getFormattedDate(estimatedDateValue))}
            showPrimaryIcon={false}
            showInfoIcon={false}
          />
        </div>
      </RenderChildrenBasedOnScreen>

      <div className={css.rowWrapper}>
        <StepProgressBar label={labelList} currentStep={isCurrent} />
      </div>
    </div>
  );
};

interface UserCardProps {
  src?: string;
  primaryTitle: string;
  secondaryTitle: ReactNode;
  showPrimaryIcon?: boolean;
  showInfoIcon?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}
const UserCard = (props: UserCardProps) => {
  const {
    src,
    primaryTitle,
    secondaryTitle,
    style,
    showPrimaryIcon = true,
    showInfoIcon = false,
    onClick
  } = props;
  return (
    <div className={css.userCardContainer} style={style}>
      {showPrimaryIcon && (
        <IconNode src={src} alt={`${primaryTitle} icon`} className={css.profileIcon} />
      )}
      <div className={css.userCardDetailColumn}>
        <div className={css.titleWrapper}>
          <Typography variant="span" className={css.primaryTitleLabel}>
            {primaryTitle}
          </Typography>
          {showInfoIcon && <IconNode src={Images.infoGrey} alt="info icon" />}
        </div>
        <Typography
          className={`${onClick ? css.hoverText : null}`}
          onClick={() => (onClick ? onClick() : undefined)}
          variant="body">
          {secondaryTitle}
        </Typography>
      </div>
    </div>
  );
};
export default CustomerOrderCard;
