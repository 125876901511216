import { Button } from '@components/base';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { IItemDetailsInfo, IItemInfoTypes, IItemDetail } from '@helpers/types/pi';
import { useFormContext } from 'react-hook-form';
import css from './index.module.scss';
import ItemDetailContainer from './item-detail-product';

interface ItemDetailFormProps {
  onFormSubmit: (data: any) => void;
  onCancelClick: () => void;
  onBackClick: () => void;
  productList: IItemInfoTypes;
  customItemList: IItemDetail[];
}

const ItemDetailForm = (props: ItemDetailFormProps) => {
  const { onFormSubmit, onCancelClick, onBackClick, productList, customItemList } = props;
  const { items, totalAmount, currency, unit_of_weight } = productList;

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext();

  useBeforeUnloadAndNavigate();

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <ItemDetailContainer
        itemsList={items}
        totalAmount={totalAmount}
        customItemList={customItemList}
        currency={currency}
        unit_of_weight={unit_of_weight}
      />
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div>
          <Button variant="outlined-secondary" onClick={onBackClick} className={css.nextBtn}>
            Back
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save and Proceed
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ItemDetailForm;
