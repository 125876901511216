import { Fragment, useMemo } from 'react';
import css from './index.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { LinkButton, Seo, TextField } from '@components/common';
import { Button, IconNode, Typography, Image, Loader } from '@components/base';
import { CLIENT_ROUTES } from '@router/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordSchema } from '@helpers/yup';
import Images from '@assets/images';
import { patchPasswordReset } from '@services/login.service';
import notify from '@helpers/toastify-helper';

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: queryParams.get('email') ?? '',
      password: '',
      confirmPassword: ''
    },
    resolver: yupResolver(resetPasswordSchema)
  });

  const handleFormSubmit = async (data: any) => {
    const uidb64 = queryParams.get('uidb64');
    const token = queryParams.get('token');
    if (uidb64 && token) {
      const response = await patchPasswordReset({ uidb64, token, password: data.password });
      if (response.success) {
        navigate(`/${CLIENT_ROUTES.acknowledge}`, {
          replace: true,
          state: { email: data.email, password: data.password }
        });
      } else {
        notify({ message: 'Password reset link expired', severity: 'error' });
        navigate(`${CLIENT_ROUTES.login}`, { replace: true });
      }
    } else {
      notify({ message: 'Password reset link expired', severity: 'error' });
      navigate(`${CLIENT_ROUTES.login}`, { replace: true });
    }
  };

  return (
    <Fragment>
      <Seo title="Reset Password" />
      <LinkButton to={`${CLIENT_ROUTES.login}`} className={css.backButton} replace>
        <Image src={Images.backArrow} alt="Back button" />
        <Typography variant="p">Back to Login</Typography>
      </LinkButton>
      <form className={css.resetPasswordForm} onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Typography variant="h1">Reset Password</Typography>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              type="email"
              inputMode="email"
              enterKeyHint="next"
              autoComplete="email"
              label="Email"
              placeholder="Enter your email address"
              disabled={!!queryParams.get('email')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              autoFocus
              type="password"
              enterKeyHint="next"
              autoComplete="new-password"
              label="Set Password"
              placeholder="Enter your new password"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              type="password"
              enterKeyHint="done"
              autoComplete="new-password"
              label="Confirm Password"
              placeholder="Confirm your new password"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
            />
          )}
        />
        <Button type="submit" disabled={isSubmitting}>
          Save Password
        </Button>
      </form>
      <Loader open={isSubmitting} />
    </Fragment>
  );
};
export default ResetPasswordForm;
