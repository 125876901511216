import React, { useContext, useEffect, useState } from 'react';
import css from './index.module.scss';
import { AppContext } from '@helpers/hooks/AppContext';
import { getOrderItemDetails } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import Typography from '@components/base/typography';
import Preview from '../components/preview';
import { Loader } from '@components/base';
import RejectModal from './components/reject-modal';
import { approvePackagingDetails } from '@services/task.service';
import { CLIENT_ROUTES } from '@router/routes';
import { useNavigate } from 'react-router-dom';

interface ReviewStateType {
  isLoading: boolean;
  modalOpen: boolean;
  itemDetails: any;
}

const Review = () => {
  const [reviewState, setReviewState] = useState<ReviewStateType>({
    isLoading: false,
    modalOpen: false,
    itemDetails: {
      items: []
    }
  });

  const { appState } = useContext(AppContext);
  const { setReviewData } = appState;

  const { isLoading, modalOpen, itemDetails } = reviewState;

  const navigate = useNavigate();

  useEffect(() => {
    fetchItemDetails();
  }, []);

  const fetchItemDetails = async () => {
    setReviewState((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await getOrderItemDetails(setReviewData?.order_id, {
      supplier_id: setReviewData?.supplier_id
    });
    setReviewState((prevState: any) => ({
      ...prevState,
      isLoading: false
    }));
    if (response?.success) {
      setReviewState((prevState: any) => ({
        ...prevState,
        itemDetails: {
          items: response?.data?.results?.map((item: any) => {
            return {
              name: item?.product_name,
              order_item_id: item?.order_item_id,
              package: item?.package?.map((packageItem: any) => ({
                type: { value: packageItem?.type, label: packageItem?.type_label },
                no_of_package: packageItem?.no_of_package,
                unit: { label: packageItem?.unit_of_weight, value: packageItem?.unit_of_weight },
                weight: packageItem?.weight
              }))
            };
          })
        }
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.message ?? 'Something went wrong'
      });
    }
  };

  const packagingFormSubmitHandler = async () => {
    const body = {
      is_approved: true
    };
    setReviewState((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await approvePackagingDetails(setReviewData?.task_id, body);
    if (response?.success) {
      notify({
        severity: 'success',
        message: 'Packaging details approved successfully'
      });
      setReviewState((prevState: any) => ({
        ...prevState,
        isLoading: false,
        modalOpen: false
      }));
      navigate(`/${CLIENT_ROUTES.order}/${setReviewData?.order_id}`, { replace: true });
    } else {
      notify({
        severity: 'error',
        message: response?.error ?? 'Something went wrong'
      });
      setReviewState((prevState: any) => ({
        ...prevState,
        isLoading: false
      }));
    }
  };

  const handleCancelClick = () => {
    navigate(`/${CLIENT_ROUTES.order}/${setReviewData?.order_id}`, { replace: true });
  };

  const handleRejectOrder = async (data: any) => {
    const body = {
      is_approved: false,
      remarks: data?.order_rectify_remarks
    };
    setReviewState((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await approvePackagingDetails(setReviewData?.task_id, body);
    if (response?.success) {
      notify({
        severity: 'success',
        message: 'Packaging details rejected successfully'
      });
      setReviewState((prevState: any) => ({
        ...prevState,
        isLoading: false,
        modalOpen: false
      }));
      navigate(`/${CLIENT_ROUTES.order}/${setReviewData?.order_id}`, { replace: true });
    } else {
      notify({
        severity: 'error',
        message: response?.error ?? 'Something went wrong'
      });
      setReviewState((prevState: any) => ({
        ...prevState,
        isLoading: false
      }));
    }
  };
  return (
    <main className={css.formWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Review Packaging Details</Typography>
      </div>
      <div className={css.formContainer}>
        <Preview
          data={reviewState}
          onSubmit={packagingFormSubmitHandler}
          handleCancelClick={handleCancelClick}
          isReview={true}
          openRejectModal={() =>
            setReviewState((prevState: any) => ({ ...prevState, modalOpen: true }))
          }
        />
      </div>
      <Loader open={isLoading} />
      {modalOpen && (
        <RejectModal
          open={modalOpen}
          onClose={() => setReviewState((prevState: any) => ({ ...prevState, modalOpen: false }))}
          submitRejectForm={handleRejectOrder}
        />
      )}
    </main>
  );
};

export default Review;
