import css from './index.module.scss';
import React from 'react';
import Typography from '@components/base/typography';
import { IconNode } from '@components/base';
import Images from '@assets/images';
import { FieldValue } from '@helpers/types/coa';

interface OrderPropTypes {
  data: FieldValue[];
  handleNavigation: (index: number) => void;
}

const Order = (props: any) => {
  const { data, handleNavigation } = props;
  return (
    <div className={css.boxWrapper}>
      <div className={css.orderContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="h4">Consignee Details</Typography>
          <IconNode
            className={css.editIcon}
            src={Images.editRed}
            onClick={() => handleNavigation(0)}
          />
        </div>
        <div className={css.rowWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="label">Customer Name</Typography>
            <Typography variant="p">{data?.customer?.name ?? '-'}</Typography>
          </div>
          <div className={css.columnWrapper}>
            <Typography variant="label">Country</Typography>
            <Typography variant="p">{data?.customer_address?.country ?? '-'}</Typography>
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="label">Customer Phone</Typography>
            <Typography variant="p">{data?.customer?.number ?? '-'}</Typography>
          </div>
          <div className={css.columnWrapper}>
            <Typography variant="label">Email</Typography>
            <Typography variant="p">{data?.customer?.email ?? '-'}</Typography>
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="label">Customer Address</Typography>
            <Typography variant="p">{data?.customer_address?.readable_address ?? '-'}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
