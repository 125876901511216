import css from './index.module.scss';
import Images from '@assets/images';
import { Image } from '@components/base';
import { Outlet } from 'react-router-dom';

function Login() {
  return (
    <div className={css.loginWrapper}>
      <section className={css.loginContainer}>
        <div className={css.elchemyLogoWrapper}>
          <Image src={Images.elchemyLogo} alt="Elchemy Logo" />
        </div>
        <main className={css.loginSection}>
          <Outlet />
        </main>
      </section>
      <section className={css.loginCoverWrapper}>
        <Image src={Images.loginCover} alt="Cover Image" className={css.loginCover} />
      </section>
    </div>
  );
}
export default Login;
