import { Button } from '@components/base';
import Checkbox from '@components/base/checkbox/checkbox';
import { BoxContainer, SelectLabel, TextField } from '@components/common';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import css from './index.module.scss';
import { Controller, useFormContext } from 'react-hook-form';

interface IOrderBankDetails {
  orderBankData: PerformaInvoiceStates;
  onFormSubmit: (data: any) => void;
  handleCancelClick: () => void;
  onBackClick: () => void;
  paymentTermSuffixList: number[];
}

const OrderBankDetailsTab = (props: IOrderBankDetails) => {
  const { orderBankData, handleCancelClick, onFormSubmit, onBackClick, paymentTermSuffixList } =
    props;
  const { orderDetails, bankDetails } = orderBankData;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <form noValidate className={css.purchaseOrderFormWrapper} onSubmit={handleSubmit(onFormSubmit)}>
      <BoxContainer title="Order Details">
        <div className={css.purchaserWrapper}>
          <div className={css.nameFieldWrapper}>
            <SelectLabel
              label="Mode of Transportation"
              placeholder="Mode of Transportation"
              value={{
                value: orderDetails?.transportation_mode,
                label: orderDetails?.transportation_mode
              }}
              isDisabled
            />
            <TextField
              disabled
              label="Inco Terms"
              placeholder="Inco Terms"
              value={orderDetails?.inco_terms ?? ''}
            />
          </div>
        </div>
        <div className={css.purchaserWrapper}>
          <div className={css.nameFieldWrapper}>
            <SelectLabel
              label="Country of  Origin"
              placeholder="Country of  Origin"
              isDisabled
              value={{
                value: orderDetails?.country_of_origin,
                label: orderDetails?.country_of_origin
              }}
            />
            <SelectLabel
              label="Country of Final Destination"
              placeholder="Country of Final Destination"
              value={{
                value: orderDetails?.country_of_final_destination,
                label: orderDetails?.country_of_final_destination
              }}
              isDisabled
            />
          </div>
        </div>
        <div className={css.purchaserWrapper}>
          <div className={css.nameFieldWrapper}>
            <TextField
              disabled
              label="Port of Loading"
              placeholder="Port of Loading"
              value={
                orderDetails?.port_of_loading_country_display_value &&
                orderDetails?.port_of_loading_display_value
                  ? `${orderDetails?.port_of_loading_display_value} - ${orderDetails?.port_of_loading_country_display_value}`
                  : '-'
              }
            />
            <SelectLabel
              label="Delivery Term"
              placeholder="Delivery Term"
              isDisabled
              value={{
                value: orderDetails?.inco_terms,
                label: orderDetails?.inco_terms
              }}
            />
          </div>
          <div className={css.nameFieldWrapper}>
            <SelectLabel
              label="Payment Term"
              placeholder="Payment Term"
              isDisabled
              value={{
                value: orderDetails?.payment_terms,
                label: orderDetails?.payment_terms
              }}
            />
            <Controller
              name="payment_term_suffix"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  isClearable
                  label="Payment Term Suffix ( Only if Needed)"
                  placeholder="Payment Term Suffix"
                  options={paymentTermSuffixList}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.value}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.checkboXWrap}>
            <Checkbox
              checked={orderDetails?.is_qc_test}
              value={`${orderDetails?.is_qc_test}`}
              disabled
            />
            <TextField
              label="Place of Quality Test"
              disabled
              placeholder="Place of Quality Test"
              value={orderDetails?.place_of_quality_test ?? ''}
            />
          </div>
        </div>
      </BoxContainer>
      <BoxContainer title="Entity Bank Details">
        <div className={css.purchaserWrapper}>
          <div className={css.nameFieldWrapper}>
            <SelectLabel
              label="Bank Name"
              isDisabled
              value={{
                value: bankDetails?.bank_name,
                label: bankDetails?.bank_name
              }}
            />
            <TextField
              label="Branch Name"
              disabled
              placeholder="Country"
              value={bankDetails?.branch_name ?? ''}
            />
          </div>
          <div className={css.nameFieldWrapper}>
            <TextField
              label="Account Holder Name"
              disabled
              placeholder="POC Contact Number"
              value={bankDetails?.account_number ?? ''}
            />
          </div>
          <div className={css.nameFieldWrapper}>
            <TextField
              label="Bank Account Number"
              disabled
              placeholder="Country"
              value={bankDetails?.bank_account_holder_name ?? ''}
            />
            <TextField
              label="IFSC Code"
              disabled
              placeholder="Country"
              value={bankDetails?.ifsc_code ?? ''}
            />
            <TextField
              label="Swift"
              disabled
              placeholder="Country"
              value={bankDetails?.swift_code ?? ''}
            />
          </div>

          <TextField
            disabled
            label="Bank Address"
            placeholder="Bank Address"
            value={bankDetails?.address ?? ''}
          />
        </div>
      </BoxContainer>
      <div className={css.formActionWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button onClick={onBackClick} variant="outlined-secondary">
            Back
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save and Proceed
          </Button>
        </div>
      </div>
    </form>
  );
};

export default OrderBankDetailsTab;
