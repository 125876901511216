import { Button, IconNode, Typography } from '@components/base';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import css from './index.module.scss';
import { TextField } from '@components/common';
import Images from '@assets/images';
import { memo, useEffect } from 'react';

interface ISealNumberWrapperProps {
  fieldName: string;
  inputName: string;
}

const SealNumberWrapper = (props: ISealNumberWrapperProps) => {
  const { fieldName, inputName } = props;
  const { control } = useFormContext();
  const {
    fields: sealFields,
    append: sealAppend,
    remove: sealRemove
  } = useFieldArray({
    name: fieldName,
    control
  });

  const handleAddSealField = () => {
    sealAppend({ [fieldName]: '' });
  };

  useEffect(() => {
    if (sealFields.length === 0) {
      sealAppend({ [fieldName]: '' });
    }
  }, []);

  return (
    <div className={css.sealSectionWrapper}>
      <Typography variant="p" className={css.titleWrapper}>
        {inputName}
      </Typography>
      <div className={css.boxWrapper}>
        {sealFields.map((seal: any, index: number) => {
          const handleDeleteClick = () => sealRemove(index);
          return (
            <div key={seal.id} className={css.rowWrapper}>
              <Controller
                name={`${fieldName}.${index}.${fieldName}`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label={`${inputName} ${index + 1}`}
                    placeholder={inputName}
                    className={css.fieldWrapper}
                    {...field}
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              {sealFields.length > 0 && (
                <Button
                  className={css.deleteButton}
                  variant="text"
                  title={`Delete ${inputName}`}
                  onClick={handleDeleteClick}
                  startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}></Button>
              )}
            </div>
          );
        })}
        <Button
          variant="text"
          onClick={handleAddSealField}
          startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
          {`Add ${inputName}`}
        </Button>
      </div>
    </div>
  );
};

export default memo(SealNumberWrapper);
