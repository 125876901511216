import css from './index.module.scss';
import { Button, Divider, IconNode } from '@components/base';
import Typography from '@components/base/typography';
import Images from '@assets/images';
import { DeletePrompt, LinkButton } from '@components/common';
import { useState } from 'react';
import { deleteOrderOtherDoc } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { HttpStatusCode } from 'axios';
import { openDocumentSignedLink } from '@helpers/utils';

interface uploadedOutsideWorkflow {
  value: boolean;
  order_id: string;
  order_item_id: string;
}

interface TablePopOverProps {
  heading?: string;
  title?: string;
  documents: Array<any>;
  handleEdit?: () => void;
  uploadedOutsideWorkflow?: uploadedOutsideWorkflow;
  getDocumentData: () => Promise<void>;
  isEditable?: boolean;
  isPaymentInvoice?: boolean;
}

export const TablePopOver = (props: TablePopOverProps) => {
  const {
    heading,
    title,
    documents,
    handleEdit,
    uploadedOutsideWorkflow,
    getDocumentData,
    isEditable = true,
    isPaymentInvoice
  } = props;
  const handleDelete = async (uploadedOutsideWorkflow: any) => {
    const response = await deleteOrderOtherDoc(
      uploadedOutsideWorkflow.order_id,
      uploadedOutsideWorkflow.order_item_id
    );
    if (response?.status === HttpStatusCode.NoContent) {
      notify({ message: 'Document Deleted Successfully', severity: 'success' });
      getDocumentData();
    } else {
      notify({ message: response?.error ?? 'Something went wrong', severity: 'error' });
    }
  };

  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  return (
    <div className={css.mainWrapper}>
      {heading && (
        <Typography variant="h4" className={css.heading}>
          {heading}
        </Typography>
      )}
      {title && (
        <div className={css.titleContainer}>
          <IconNode
            src={Images.disclaimer}
            className={css.titleIcon}
            alt="Disclaimer Icon"></IconNode>
          <Typography variant="p" className={css.title}>
            {title}
          </Typography>
        </div>
      )}
      {documents?.map(
        (item: any, index: number) =>
          (item.pdfLink || item.docLink) && (
            <div key={index} className={css.document}>
              {item.text && (
                <div className={css.textContainer}>
                  <Typography className={css.text}>{item.text}</Typography>
                </div>
              )}
              <div className={css.links}>
                {item.pdfLink && (
                  <Button
                    onClick={() => openDocumentSignedLink(item.pdfLink)}
                    variant="outlined"
                    className={item.docLink ? css.downloadPdf : css.pdfLink}
                    endIcon={
                      documents.length > 1 && <IconNode src={Images.colouredPdf} alt="PDF icon" />
                    }>
                    View
                  </Button>
                )}
                {item.docLink && item.pdfLink && <Divider className={css.divider} />}
                {item.docLink && (
                  <Button
                    onClick={() => openDocumentSignedLink(item.docLink)}
                    variant="outlined"
                    className={css.generateWord}
                    endIcon={<IconNode src={Images.colouredWord} alt="Word icon" />}>
                    Download
                  </Button>
                )}
              </div>
            </div>
          )
      )}
      <div className={css.editContainer}>
        <Button
          variant="text"
          className={css.deleteButton}
          disabled={(uploadedOutsideWorkflow && !uploadedOutsideWorkflow.value) || isPaymentInvoice}
          startIcon={
            <IconNode
              src={
                uploadedOutsideWorkflow && !uploadedOutsideWorkflow.value
                  ? Images.delete
                  : Images.deleteRed
              }
              alt="Delete icon"
            />
          }
          onClick={() => setOpenDeletePrompt(true)}></Button>
        <Button
          variant="text"
          className={css.editTagsButton}
          disabled={!isEditable}
          startIcon={<IconNode src={Images.editRed} alt="PDF icon" />}
          onClick={handleEdit}>
          <Typography variant="pdoc" className={css.editText}>
            Edit
          </Typography>
        </Button>
        <DeletePrompt
          open={openDeletePrompt}
          onClose={() => setOpenDeletePrompt(false)}
          onConfirm={() => handleDelete(uploadedOutsideWorkflow)}
        />
      </div>
    </div>
  );
};
