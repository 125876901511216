import { useEffect, useRef, useState } from 'react';
import css from './index.module.scss';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createPurchaseOrder,
  fetchPOReviewByIdWithFormattedAmount,
  getPaymentTermsFilterOptions,
  getPaymentTermSuffixFilterOptions,
  getPOInitialData,
  rectifyPurchaseOrder
} from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { fetchCountryList } from '@services/util.service';
import {
  ADDRESS_TYPE_CONSTANTS,
  booleanSelectFields,
  CURRENCY,
  DELIVERY_TERMS,
  GST_RATE,
  UNITS_OF_WEIGHTS
} from '@helpers/constants';
import { Typography } from '@components/base';
import { SideBar } from '@components/common';
import { FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import PartiesForm from './components/parties-tab';
import { yupResolver } from '@hookform/resolvers/yup';
import { purchaseOrderDetail } from '@helpers/yup/generate-purchase-order';
import PurchaseOrderDetailsForm from './components/purchase-order-details';
import { ISelectBoolean } from '@helpers/types';
import {
  IPaymentTermsSuffix,
  IPurchaseOrderProps,
  IRemarksData
} from '@helpers/types/purchase-order';
import moment from 'moment';
import { remarkFunction } from './remarks';
import POPreview from './components/preview-tab';
import { CLIENT_ROUTES } from '@router/routes';
import { round } from 'lodash';
import { IPaymentTermsFilter } from '@helpers/types/add-order';
import RemarksForm from './components/remarks-tab';

interface IGeneratePO {
  activeStep: number;
  isLoading: boolean;
  order_items: Array<any>;
  purchaser: {
    purchaser_name: string;
    purchaser_gst_no: string;
    address: string;
  };
  supplier: {
    supplier_name: string;
    vendor_id: string;
    supplier_gst_no: string;
    office_address: string;
    factory_location: string;
    supplier_poc_list: Array<any>;
    factory_address_list: Array<any>;
  };
  remarksInfo: any;
  poData: any;
  countryOptions: Array<{ label: string; value: string }>;
  rectifyRemarks: string;
  paymentTermSuffixList: Array<IPaymentTermsSuffix>;
  paymentTermsOptions: Array<IPaymentTermsFilter>;
}

const GeneratePO = () => {
  useBeforeUnloadAndNavigate();

  const [tabState, setTabState] = useState<IGeneratePO>({
    activeStep: 0,
    isLoading: false,
    order_items: [],
    purchaser: {
      purchaser_name: '',
      purchaser_gst_no: '',
      address: ''
    },
    supplier: {
      supplier_name: '',
      vendor_id: '',
      supplier_gst_no: '',
      office_address: '',
      factory_location: '',
      supplier_poc_list: [],
      factory_address_list: []
    },
    remarksInfo: {
      string: '',
      data: {
        dispatch_date_info: null,
        fcl: null,
        amount_info: '',
        supplier_name: '',
        palletization_details: '',
        shipping_lines: '',
        text_remarks: ''
      },
      checkboxStates: {}
    },
    poData: {
      document_date: '',
      vendor_id: '',
      po_terms_of_delivery: '',
      delivery_address: '',
      payment_terms: [
        {
          payment_term: null,
          percentage: null,
          days: null
        }
      ],
      payment_term_suffix: null,
      is_palletized: false,
      supplier_name: '',
      gst_number: '',
      po_country_of_origin: null,
      currency: '',
      unit_of_weight: '',
      gst_rate: '',
      supplier_poc: null,
      supplier_factory_address: null,
      order_item: [],
      total_amount: '',
      total_amount_of_all_items_in_words: '',
      gst_amount: ''
    },
    countryOptions: [],
    rectifyRemarks: '',
    paymentTermSuffixList: [],
    paymentTermsOptions: []
  });

  const {
    activeStep,
    isLoading,
    order_items,
    purchaser,
    supplier,
    remarksInfo,
    poData,
    countryOptions,
    rectifyRemarks,
    paymentTermSuffixList,
    paymentTermsOptions
  } = tabState;
  const navigate = useNavigate();
  const topRef = useRef(null);
  const urlParams = useParams();
  const { id, supplierId, taskId } = urlParams;
  const isRectify = location.pathname.split('/')[2] === 'rectify-purchase-order';

  const partyForm = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      purchaserName: '',
      purchaseGstNumber: '',
      address: '',
      supplierName: '' || {
        label: '',
        value: 0
      },
      vendorId: '',
      supplierGstNumber: '',
      officeAddress: '' || {
        label: '',
        value: 0
      },
      factoryLocation: ''
    }
  });

  const PODetailsForm = useForm<IPurchaseOrderProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(purchaseOrderDetail),
    defaultValues: {
      document_date: new Date() || '',
      vendor_id: '',
      po_terms_of_delivery: '',
      delivery_address: '',
      payment_terms: [
        {
          payment_term: null,
          percentage: null,
          days: null
        }
      ],
      payment_term_suffix: null,
      is_palletized: false,
      supplier_name: '',
      gst_number: '',
      po_country_of_origin: null,
      currency: '',
      unit_of_weight: '',
      gst_rate: '',
      supplier_poc: null,
      supplier_factory_address: null,
      order_item: [],
      total_amount: '',
      total_amount_of_all_items_in_words: '',
      gst_amount: ''
    }
  });

  const remarkForm = useForm<IRemarksData>({
    defaultValues: {
      dispatch_date_info: null,
      fcl: null,
      amount_info: '',
      supplier_name: '',
      palletization_details: '',
      shipping_lines: '',
      text_remarks: ''
    }
  });

  const [remarkCheckboxTabs, setRemarkCheckboxTabs] = useState({
    dispatch_date: false,
    amount: false,
    proof_of_export: false,
    lifting_will_be_subject: false,
    product_should_be_from: false,
    palletization_detail: false,
    fumigation_certificate: false,
    standard_switch_bl_mark: false,
    shipment_to_be_processed: false,
    shipping_mark_in_the_name_of_elchemy: false,
    other_remarks: false,
    pss_confirmation: false,
    expiry_remarks: true
  });

  const paymentTermSuffixOptions = async () => {
    setTabState((prevState: IGeneratePO) => ({ ...prevState, isLoading: true }));
    const response = await getPaymentTermSuffixFilterOptions();
    setTabState((prevState: IGeneratePO) => ({ ...prevState, isLoading: false }));
    if (response.success) {
      setTabState((prevState: IGeneratePO) => ({
        ...prevState,
        paymentTermSuffixList: response.data.results
      }));
    } else {
      notify({
        message: response.error ?? 'Unable to fetch data',
        severity: 'error',
        dismissible: true
      });
    }
  };

  const filterCountryOptions = async (event?: any, param: any = null) => {
    setTabState((prevState: any) => ({ ...prevState, isLoading: true }));
    const countryList = await fetchCountryList({ search_text: param });
    setTabState((prevState: any) => ({ ...prevState, isLoading: false }));
    if (countryList?.success) {
      setTabState((prevState: any) => ({
        ...prevState,
        countryOptions: countryList.data.filter_options
      }));
    } else {
      notify({
        message: countryList.error ?? 'Error fetching country list',
        severity: 'error',
        dismissible: true
      });
    }
  };

  const getPaymentTermsOptions = async () => {
    setTabState((prevState: IGeneratePO) => ({ ...prevState, isLoading: true }));
    const response = await getPaymentTermsFilterOptions();
    if (response?.success) {
      setTabState((prevState: IGeneratePO) => ({
        ...prevState,
        paymentTermsOptions: response.data?.payment_terms_options,
        isLoading: false
      }));
    } else {
      notify({
        severity: 'error',
        title: 'Error',
        message: response.message ?? 'Unable to fetch payment terms'
      });
      setTabState((prevState: IGeneratePO) => ({ ...prevState, isLoading: false }));
    }
  };

  const getPOData = async () => {
    setTabState((prevState: IGeneratePO) => ({ ...prevState, isLoading: true }));
    const response = await getPOInitialData(id as string, supplierId as string);

    if (response.success) {
      const { data } = response;
      setTabState((prevState: IGeneratePO) => ({
        ...prevState,
        isLoading: false,
        purchaser: {
          purchaser_name: data.elchemy_entity.name,
          purchaser_gst_no: data.elchemy_entity.gst_number,
          address: data.elchemy_entity.address
        },
        supplier: {
          supplier_name: data.supplier_info.supplier_name,
          vendor_id: data.supplier_info.vendor_id,
          supplier_gst_no: data.supplier_info?.gst_number,
          office_address: data.supplier_info.addresses.length
            ? data.supplier_info.addresses.find(
                (address: any) => address.address_type == ADDRESS_TYPE_CONSTANTS.OFFICE
              ).readable_address
            : null,
          factory_location: data.supplier_info.addresses.length
            ? data.supplier_info.addresses.find(
                (address: any) => address.address_type == ADDRESS_TYPE_CONSTANTS.FACTORY
              ).city
            : '',
          supplier_poc_list: data.supplier_info.supplier_pocs,
          factory_address_list: data.supplier_info.addresses.filter(
            (address: any) => address.address_type == ADDRESS_TYPE_CONSTANTS.FACTORY
          )
        },
        order_items: data.order_items
      }));
      PODetailsForm.reset({
        document_date: new Date() || '',
        vendor_id: data.supplier_info.vendor_id,
        po_terms_of_delivery: '',
        delivery_address: '',
        payment_terms: [
          {
            payment_term: null,
            percentage: null,
            days: null
          }
        ],
        payment_term_suffix: null,
        is_palletized: false,
        supplier_name: data.supplier_info.supplier_name,
        gst_number: data.supplier_info?.gst_number,
        po_country_of_origin: null,
        currency: '',
        unit_of_weight: '',
        gst_rate: '',
        supplier_poc: null,
        supplier_factory_address: null,
        total_amount: '',
        total_amount_of_all_items_in_words: '',
        order_item: data.order_items.map((item: any) => {
          return {
            item_name: item.product_name,
            item_grade_name: item.product_grade_name,
            order_item_description_po: item?.order_item_description_po?.length
              ? item?.order_item_description_po
              : item?.order_item_description,
            include_grade_name_in_docs: item.include_grade_name_in_docs
              ? booleanSelectFields.find(
                  (opt: ISelectBoolean) => opt.value === item.include_grade_name_in_docs
                )
              : booleanSelectFields[0],
            quantity_po: item.quantity,
            rate_po: '',
            amount: '',
            package: item.package,
            CAS_number: item.CAS_number,
            hs_code: item.hs_code
          };
        })
      });
    } else {
      notify({
        message: response.error ?? 'Error fetching PO data',
        severity: 'error',
        dismissible: true
      });
      setTabState((prevState: IGeneratePO) => ({ ...prevState, isLoading: false }));
    }
  };

  const getRectifyPOData = async () => {
    setTabState((prevState: IGeneratePO) => ({ ...prevState, isLoading: true }));
    const response = await fetchPOReviewByIdWithFormattedAmount(
      id as string,
      supplierId as string,
      1
    );
    if (response.success) {
      const { data } = response;
      const po_orderItems = data.order.order_item.filter(
        (item: any) => item.supplier_id === supplierId
      );
      const parsedData = JSON.parse(data?.remarks);
      const formattedFcl = new Date(parsedData.data?.fcl);
      const formattedDispatch = parsedData.data?.dispatch_date_info;
      setTabState((prevState: IGeneratePO) => ({
        ...prevState,
        purchaser: {
          purchaser_name: data.order.elchemy_entity.name,
          purchaser_gst_no: data.order.elchemy_entity.gst_number,
          address: data.order.elchemy_entity.address
        },
        supplier: {
          supplier_name: data.supplier.supplier_name,
          vendor_id: data.supplier.vendor_id,
          supplier_gst_no: data.supplier?.gst_number,
          office_address: data.supplier.addresses.find(
            (address: any) => address.address_type == ADDRESS_TYPE_CONSTANTS.OFFICE
          ).readable_address,
          factory_location: data.supplier_factory_address.city,
          supplier_poc_list: data.supplier_poc,
          factory_address_list: data.supplier_factory_address
        },
        order_items: data.order.order_item.filter((item: any) => item.supplier_id === supplierId),
        poData: {
          document_date: data.document_date,
          vendor_id: data.supplier.vendor_id,
          po_terms_of_delivery: data.po_terms_of_delivery || '',
          delivery_address: data.delivery_address || '',
          payment_terms: data?.payment_term?.map((item: any) => {
            return {
              payment_term: {
                payment_term: item.payment_term,
                days: item.days ? true : false,
                percentage: item.percentage ? true : false
              },
              percentage: item.percentage,
              days: item.days ? item.days : null
            };
          }),
          payment_term_suffix: data.po_payment_term_suffix_value
            ? {
                name: data.po_payment_term_suffix_label,
                value: data.po_payment_term_suffix_value
              }
            : null,
          is_palletized: data.is_palletized,
          supplier_name: data.supplier.supplier_name,
          gst_number: data.supplier.gst_number,
          po_country_of_origin: data.po_country_of_origin
            ? {
                label: data.po_country_of_origin_display_value,
                value: data.po_country_of_origin
              }
            : null,
          currency: data.currency,
          unit_of_weight: data.unit_of_weight,
          gst_rate: data.gst_rate,
          supplier_poc: data.supplier_poc,
          supplier_factory_address: data.supplier_factory_address,
          order_item: po_orderItems?.map((item: any) => {
            const buyingPrice = item.buying_price.split(',').join('');
            return {
              item_name: item.product_name,
              item_grade_name: item.product_grade_name,
              order_item_description_po: item?.order_item_description_po?.length
                ? item?.order_item_description_po
                : item?.order_item_description,
              include_grade_name_in_docs: item.include_grade_name_in_docs
                ? booleanSelectFields.find(
                    (opt: ISelectBoolean) => opt.value === item.include_grade_name_in_docs
                  )
                : booleanSelectFields[0],
              quantity_po: item.quantity,
              rate_po: item.buying_price,
              amount: round(buyingPrice) * round(item.quantity)
            };
          }),
          total_amount: data.total_amount,
          total_amount_of_all_items_in_words: data.total_amount_in_words,
          gst_amount: data.gst_amount
        },
        rectifyRemarks: data.rectify_remarks,
        remarksInfo: {
          string: parsedData.string,
          data: {
            ...parsedData.data,
            dispatch_date_info: formattedDispatch,
            fcl: formattedFcl
          },
          checkboxStates: { ...parsedData.checkboxStates }
        },
        isLoading: false
      }));
      PODetailsForm.reset({
        document_date: new Date(data.document_date),
        vendor_id: data.supplier.vendor_id,
        po_terms_of_delivery: DELIVERY_TERMS.find(
          (item) => item.value == data?.po_terms_of_delivery
        ),
        delivery_address: data.delivery_address || '',
        payment_terms: data?.payment_term?.map((item: any) => {
          return {
            payment_term: {
              payment_term: item.payment_term,
              days: item.days ? true : false,
              percentage: item.percentage ? true : false
            },
            percentage: item.percentage,
            days: item.days ? item.days : null
          };
        }),
        payment_term_suffix: data.po_payment_term_suffix_value
          ? {
              name: data.po_payment_term_suffix_label,
              value: data.po_payment_term_suffix_value
            }
          : null,
        is_palletized: data.is_palletized,
        supplier_name: data.supplier.supplier_name,
        gst_number: data.supplier.gst_number,
        po_country_of_origin: data.po_country_of_origin
          ? {
              label: data.po_country_of_origin_display_value,
              value: data.po_country_of_origin
            }
          : null,
        currency: CURRENCY.find((item) => item.value == data.currency),
        unit_of_weight: UNITS_OF_WEIGHTS.find((item) => item.value == data.unit_of_weight),
        gst_rate: GST_RATE.find((item) => item.value == data.gst_rate),
        supplier_poc: data.supplier_poc,
        supplier_factory_address: data.supplier_factory_address,
        order_item: po_orderItems?.map((item: any) => {
          const buyingPrice = item.buying_price.split(',').join('');
          const orderItemAmount = round(buyingPrice) * round(item.quantity);
          return {
            item_name: item.product_name,
            item_grade_name: item.product_grade_name,
            order_item_description_po: item?.order_item_description_po?.length
              ? item?.order_item_description_po
              : item?.order_item_description,
            include_grade_name_in_docs: item.include_grade_name_in_docs
              ? booleanSelectFields.find(
                  (opt: ISelectBoolean) => opt.value === item.include_grade_name_in_docs
                )
              : booleanSelectFields[0],
            quantity_po: item.quantity,
            rate_po: buyingPrice,
            amount: orderItemAmount,
            package: item.package,
            CAS_number: item.CAS_number,
            hs_code: item.hs_code
          };
        }),
        total_amount: data.total_amount.split(',').join(''),
        total_amount_of_all_items_in_words: data.total_amount_in_words,
        gst_amount: data.gst_amount
      });
      remarkForm.reset({
        dispatch_date_info: formattedDispatch,
        fcl: formattedFcl,
        amount_info: parsedData.data?.amount_info,
        palletization_details: parsedData.data?.palletization_details,
        shipping_lines: parsedData.data?.shipping_lines,
        text_remarks: parsedData.data?.text_remarks,
        supplier_name: parsedData.data?.supplier_name
      });
      setRemarkCheckboxTabs({ ...parsedData.checkboxStates });
    } else if (response.error) {
      notify({
        message: response.error ?? 'Error fetching PO data',
        severity: 'error',
        dismissible: true
      });
      setTabState((prevState: IGeneratePO) => ({ ...prevState, isLoading: false }));
    }
  };

  const onCancel = () => {
    navigate(`/order/${id}`);
  };

  const onBack = () => {
    setTabState((prevState: IGeneratePO) => ({
      ...prevState,
      activeStep: activeStep - 1
    }));
  };

  const handleSidebarClick = (value: number) => {
    setTabState((prevState: IGeneratePO) => ({ ...prevState, activeStep: value }));
  };

  const handleSidebarEnterKey = (event: any) => {
    if (event.key === 'Enter') {
      setTabState((prevState: IGeneratePO) => ({
        ...prevState,
        activeStep: tabState.activeStep + 1
      }));
    }
  };

  const handleTabState = (value: number) => {
    setTabState((prevState: IGeneratePO) => ({ ...prevState, activeStep: value }));
  };

  const handlePartiesFormSubmit = () => {
    setTabState((prevState: IGeneratePO) => ({
      ...prevState,
      activeStep: activeStep + 1
    }));
  };

  const handlePurchaseOrderDetailsFormSubmit = (data: any) => {
    //Validation for payment terms
    let totalPercentage = 0,
      isError = false;
    data?.payment_terms?.forEach((item: any) => {
      totalPercentage += parseInt(item.percentage);
      if (item?.payment_term?.days && (!item?.days || parseInt(item?.days) <= 0)) {
        notify({
          message: `Enter valid number of days for ${item?.payment_term?.payment_term}`,
          severity: 'error'
        });
        isError = true;
        return;
      }
    });
    if (totalPercentage !== 100) {
      notify({
        message: 'Total percentage in payment terms should be 100',
        severity: 'error'
      });
      isError = true;
      return;
    }
    if (isError) return;
    setTabState((prevState: IGeneratePO) => ({
      ...prevState,
      poData: {
        ...data
      },
      activeStep: prevState.activeStep + 1
    }));
  };

  const handleRemarkSubmit: SubmitHandler<IRemarksData> = (data: IRemarksData) => {
    const fclFormattedDate = `${moment(data?.fcl).format('YYYY-MM-DD')}`;
    const formattedDateDispatch = data?.dispatch_date_info;
    const allRemarksData = {
      ...remarkCheckboxTabs,
      ...data,
      dispatch_date_info: formattedDateDispatch,
      fcl: fclFormattedDate
    };
    const remarkString = remarkFunction(allRemarksData);

    setTabState((prev: IGeneratePO) => ({
      ...prev,
      remarksInfo: {
        string: remarkString,
        data: {
          ...data,
          dispatch_date_info: formattedDateDispatch,
          fcl: fclFormattedDate
        },
        checkboxStates: { ...remarkCheckboxTabs }
      },
      activeStep: prev.activeStep + 1
    }));
  };

  const handleFormSubmit = async () => {
    const remarksData = JSON.stringify(remarksInfo);
    const newOrder = poData?.order_item.map((item: any, index: number) => {
      item.include_grade_name_in_docs = item.include_grade_name_in_docs
        ? item.include_grade_name_in_docs.value
        : false;
      delete item['amount'];
      delete item['item_description'];
      delete item['item_name'];
      item['buying_price'] = item?.rate_po ?? null;
      delete item?.rate_po;

      return { ...item, order_item_id: order_items?.[index].order_item_id };
    });
    const formData = {
      document_date: moment(poData?.document_date).format('YYYY-MM-DD'),
      po_terms_of_delivery: poData?.po_terms_of_delivery.value,
      delivery_address: poData?.delivery_address,
      po_country_of_origin: poData?.po_country_of_origin.value,
      supplier: supplierId,
      gst_number: supplier.supplier_gst_no,
      currency: poData?.currency.value,
      gst_rate: poData?.gst_rate.value,
      gst_amount: Number(poData?.gst_amount).toFixed(2),
      order_items: newOrder,
      total_amount: poData?.total_amount,
      total_amount_of_all_items_in_words: poData?.total_amount_of_all_items_in_words,
      unit_of_weight: poData?.unit_of_weight?.value,
      remarks: remarksData,
      supplier_factory_address: poData?.supplier_factory_address?.address_id,
      supplier_poc: poData?.supplier_poc?.user_id,
      is_palletized: poData?.is_palletized,
      payment_term: poData?.payment_terms?.map((item: any) => {
        const obj: any = {};
        obj['payment_term'] = item.payment_term?.payment_term;
        obj['percentage'] = parseInt(item.percentage);
        if (item.days) {
          obj['days'] = parseInt(item.days);
        }
        return obj;
      }),
      po_payment_term_suffix: poData?.payment_term_suffix
        ? { id: (poData.payment_term_suffix as any).value }
        : null
    };
    setTabState((prevState: IGeneratePO) => ({ ...prevState, isLoading: true }));

    const response = isRectify
      ? await rectifyPurchaseOrder(taskId as string, supplierId as string, formData)
      : await createPurchaseOrder(isRectify ? taskId : (taskId as string), supplierId, formData);
    setTabState((prevState: IGeneratePO) => ({ ...prevState, isLoading: false }));

    if (response?.success) {
      notify({
        message: 'Purchase Order  Created successfully',
        severity: 'success',
        dismissible: true
      });
      navigate(`/${CLIENT_ROUTES.order}/${id as string}`);
    } else {
      notify({
        message: response.error ?? 'Error creating PO',
        severity: 'error',
        dismissible: true
      });
    }

    return response;
  };

  useEffect(() => {
    filterCountryOptions();
    paymentTermSuffixOptions();
    getPaymentTermsOptions();
    isRectify ? getRectifyPOData() : getPOData();
  }, []);

  return (
    <main className={css.purchaseOrderWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">{isRectify ? `Rectify` : `Generate`} Purchase Order</Typography>
        <Typography variant="subheading1">0{tabState.activeStep + 1} of 04</Typography>
      </div>
      <div className={css.purchaseOrderContainer} ref={topRef}>
        <div className={css.sideBarWrapper}>
          <div className={css.sideBarWrapper}>
            <SideBar
              activeStep={tabState.activeStep}
              onClick={handleSidebarClick}
              onEnter={handleSidebarEnterKey}>
              <SideBar.Item label="Parties" value={0} />
              <SideBar.Item
                label="Purchase order details"
                value={1}
                disabled={tabState.activeStep! <= 1}
              />
              <SideBar.Item label="Remarks" value={2} disabled={tabState.activeStep! <= 2} />
              <SideBar.Item label="Preview" value={3} disabled={tabState.activeStep! <= 3} />
            </SideBar>
          </div>
        </div>
        <FormProvider {...partyForm}>
          {activeStep === 0 && (
            <PartiesForm
              purchaser={purchaser}
              supplier={supplier}
              onFormSubmit={handlePartiesFormSubmit}
              onCancelClick={onCancel}
            />
          )}
        </FormProvider>
        <FormProvider {...PODetailsForm}>
          {activeStep === 1 && (
            <PurchaseOrderDetailsForm
              poDetails={poData}
              supplierData={supplier}
              isRectify={isRectify}
              onFormSubmit={handlePurchaseOrderDetailsFormSubmit}
              onBackClick={onBack}
              onCancelClick={onCancel}
              paymentTermSuffixList={paymentTermSuffixList}
              countryOptions={countryOptions}
              paymentTermsOptions={paymentTermsOptions}
              filterCountryOptions={filterCountryOptions}
              orderItems={order_items}
            />
          )}
        </FormProvider>
        <FormProvider {...remarkForm}>
          {activeStep === 2 && (
            <RemarksForm
              formSubmit={handleRemarkSubmit}
              onBackClick={onBack}
              onCancelClick={onCancel}
              remarkCheckboxTabs={remarkCheckboxTabs}
              setRemarkCheckboxTabs={setRemarkCheckboxTabs}
            />
          )}
        </FormProvider>
        {activeStep === 3 && (
          <POPreview
            isRectify={isRectify}
            purchaseOrderData={poData}
            remarksInfo={remarksInfo}
            purchaser={purchaser}
            supplier={supplier}
            onCancelClick={onCancel}
            onBackClick={onBack}
            rectifyRemarks={rectifyRemarks}
            handleTabState={handleTabState}
            onFormSubmit={handleFormSubmit}
          />
        )}
      </div>
    </main>
  );
};

export default GeneratePO;
