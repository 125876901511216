import * as yup from 'yup';

export const completePaymentSchema = yup.object().shape({
  bank_details: yup.array().of(
    yup.object().shape({
      supplier_bank_id: yup.string().required('Supplier Bank ID is required'),
      supplier_bank_account_number: yup
        .string()
        .required('Supplier Bank Account Number is required'),
      supplier_name: yup.string().required('Supplier Name is required'),
      payment_date: yup
        .date()
        .max(new Date(), 'Date cannot be in the future')
        .required('Payment Date is required'),
      remarks: yup.string().nullable().notRequired(),
      reference_number: yup.string().required('Reference Number is required'),
      payment_info_ids: yup.array().of(yup.string()).required('Payment Info IDs is required')
    })
  )
});
