import { ChangeEvent, memo } from 'react';
import css from './index.module.scss';
import { BoxContainer, TextField } from '@components/common';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { IconNode, Typography, CheckboxLabel } from '@components/base';
import Images from '@assets/images';
import { IOrderCustomerForm } from '@helpers/types/add-order';
import { ICustomerInfo } from '@helpers/types/customer';

interface ConsigneeContainerProps {
  currentCustomer: ICustomerInfo | null;
  editable: boolean;
  setDirtyFields: (fields: any) => void;
}

const ConsigneeContainer = (props: ConsigneeContainerProps) => {
  const { control, setValue } = useFormContext();
  const watchField = useWatch({
    name: ['consignee_same_as_customer']
  });

  const { currentCustomer, editable, setDirtyFields } = props;

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    if (isChecked) {
      // const { customer } = currentCustomer;
      if (currentCustomer) {
        const address = currentCustomer?.addresses?.find(
          (item: any) => item.address_type === 'OFC'
        );
        setValue('consignee_same_as_customer', isChecked, { shouldDirty: true });
        setValue('consignee_name', currentCustomer.name, { shouldDirty: true });
        setValue('consignee_phone_number', currentCustomer.number, { shouldDirty: true });
        setValue('consignee_landline', currentCustomer.number, { shouldDirty: true });
        setValue('consignee_email_id', currentCustomer.email, { shouldDirty: true });
        address &&
          setValue('consignee_office_address', address.readable_address, { shouldDirty: true });
        setDirtyFields((prev: any) => ({
          ...prev,
          consignee_name: currentCustomer.name,
          consignee_phone_number: currentCustomer.number,
          consignee_landline: currentCustomer.number,
          consignee_email_id: currentCustomer.email,
          consignee_same_as_customer: isChecked,
          consignee_office_address: address?.readable_address ?? ''
        }));
        return;
      }
    }
    setValue('consignee_same_as_customer', isChecked, { shouldDirty: true });
    setValue('consignee_name', '', { shouldDirty: true });
    setValue('consignee_phone_number', '', { shouldDirty: true });
    setValue('consignee_landline', '', { shouldDirty: true });
    setValue('consignee_email_id', '', { shouldDirty: true });
    setValue('consignee_office_address', '', { shouldDirty: true });
    setDirtyFields((prev: any) => ({
      ...prev,
      consignee_name: '',
      consignee_phone_number: '',
      consignee_landline: '',
      consignee_email_id: '',
      consignee_office_address: '',
      consignee_same_as_customer: isChecked
    }));
  };

  const onValueChangeHandler = (type: any, value: any) => {
    if (type === 'consignee_name') {
      setDirtyFields((prev: any) => ({
        ...prev,
        consignee_name: value
      }));
    }
    if (type === 'consignee_phone_number') {
      setDirtyFields((prev: any) => ({
        ...prev,
        consignee_phone_number: value
      }));
    }
    if (type === 'consignee_landline') {
      setDirtyFields((prev: any) => ({
        ...prev,
        consignee_landline: value
      }));
    }
    if (type === 'consignee_email_id') {
      setDirtyFields((prev: any) => ({
        ...prev,
        consignee_email_id: value
      }));
    }
    if (type === 'consignee_office_address') {
      setDirtyFields((prev: any) => ({
        ...prev,
        consignee_office_address: value
      }));
    }
    if (type === 'consignee_same_as_customer') {
      setDirtyFields((prev: any) => ({
        ...prev,
        consignee_same_as_customer: value
      }));
    }
  };

  return (
    <BoxContainer
      title={
        <div className={css.boxTitleWrapper}>
          <Typography variant="h4">Consignee</Typography>
          <Controller
            name="consignee_same_as_customer"
            control={control}
            render={({ field }) => (
              <CheckboxLabel
                {...field}
                disabled={!editable}
                checked={field.value}
                value={`${field.value}`}
                onChange={handleCheckboxChange}
                label="Same as Customer"
              />
            )}
          />
        </div>
      }
      className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <Controller
            name="consignee_name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                disabled={watchField[0] || !editable}
                inputMode="text"
                enterKeyHint="next"
                autoComplete="name"
                onChange={(e) => {
                  field.onChange(e);
                  onValueChangeHandler('consignee_name', e.target.value);
                }}
                label="Consignee Name"
                placeholder="Enter consignee name"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="consignee_phone_number"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                disabled={watchField[0] || !editable}
                type="tel"
                onChange={(e) => {
                  field.onChange(e);
                  onValueChangeHandler('consignee_phone_number', e.target.value);
                }}
                inputMode="tel"
                enterKeyHint="next"
                autoComplete="tel"
                label="Phone Number"
                placeholder="Enter phone number"
                rootClassName={css.fieldWrapper}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <Controller
            name="consignee_landline"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                disabled={watchField[0] || !editable}
                type="tel"
                inputMode="tel"
                onChange={(e) => {
                  field.onChange(e);
                  onValueChangeHandler('consignee_landline', e.target.value);
                }}
                enterKeyHint="next"
                autoComplete="tel"
                label="Landline"
                placeholder="Enter land line"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="consignee_email_id"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                disabled={watchField[0] || !editable}
                type="email"
                inputMode="email"
                onChange={(e) => {
                  field.onChange(e);
                  onValueChangeHandler('consignee_email_id', e.target.value);
                }}
                enterKeyHint="next"
                autoComplete="email"
                label="Email ID"
                placeholder="Enter email ID"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="consignee_office_address"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                disabled={watchField[0] || !editable}
                type="text"
                onChange={(e) => {
                  field.onChange(e);
                  onValueChangeHandler('consignee_office_address', e.target.value);
                }}
                inputMode="text"
                enterKeyHint="next"
                autoComplete="street-address"
                label="Office Address"
                placeholder="Enter office address"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
      </div>
    </BoxContainer>
  );
};

export default memo(ConsigneeContainer);
