import { Accordion, Button, Select, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { IAddress } from '@helpers/types/address';
import { Poc } from '@helpers/types/point-of-contact';
import { useMemo, useState } from 'react';
import css from './index.module.scss';
import Images from '@assets/images';
import Chip from '@components/base/chip';
import IconNode from '@components/base/icon-node';
import Divider from '@components/base/divider';
import ECGCModal from '../ecgc-modal';
import { getFormattedDate } from '@helpers/utils';
import moment from 'moment';
import { countries } from '@helpers/constants';
import { Link } from 'react-router-dom';

interface TaskCardProps {
  pocDetails?: Poc[];
  addresses?: IAddress[];
  documents?: any;
  taskData: Array<any>;
  onComplete: () => void;
}

const TaskCard = (props: TaskCardProps) => {
  const { uploadRed, settingGrey, upload } = Images;
  const [openModal, setOpenModal] = useState(false);
  const { pocDetails, taskData, onComplete } = props;

  const getCompleteStatus = props.taskData[0]?.is_completed;
  const [taskIsCompleted, setTaskIsCompleted] = useState<boolean | undefined>(getCompleteStatus);

  const handleStatusFromModal = (data: boolean) => {
    onComplete();
  };

  const taskId = taskData[0].task_id;

  const pocName = useMemo(() => {
    if (pocDetails) return `${pocDetails[0].given_name} ${pocDetails[0].family_name}`;
  }, [props.pocDetails]);

  const country = useMemo(() => {
    if (taskData[0].country) {
      return countries.find((item: any) => item.value == taskData[0].country)?.label;
    } else {
      return '-';
    }
  }, []);

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {getCompleteStatus || taskIsCompleted ? (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              ) : (
                <Avatar src={settingGrey} alt="Task Icon" />
              )}
              {/* <Typography variant="h5">{customerName}</Typography> */}
              <Link to={`/order/${taskData[0]?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  #{taskData[0]?.readable_task_id} - Upload ECGC & DnB Report
                </Typography>
              </Link>
            </div>
            <div className={css.pocWrapper}>
              <div className={css.uploadWrapper}>
                {!getCompleteStatus && taskIsCompleted === false && (
                  <Button
                    variant="text"
                    className={css.detailsButton}
                    onClick={() => setOpenModal(true)}>
                    Upload
                  </Button>
                )}
              </div>
              {!getCompleteStatus && taskIsCompleted === false && (
                <Divider className={css.divider} />
              )}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content>
          <div className={css.mainContent}>
            <div className={css.pocWrapper}>
              <div className={css.poctextWrapper}>
                <Typography variant="ptext">Customer</Typography>
                <Typography variant="p">{taskData[0].customer_name}</Typography>
              </div>
            </div>
            <div className={css.textWrapper}>
              <Typography variant="ptext">Contact Number</Typography>
              <Typography variant="p">
                {taskData[0].contact_number ? taskData[0].contact_number : '-'}
              </Typography>
            </div>
            <div className={css.textWrapper}>
              <Typography variant="ptext">Email ID</Typography>
              <Typography variant="p">{taskData[0].contact_email}</Typography>
            </div>
            <div className={css.textWrapper}>
              <Typography variant="ptext">Country</Typography>
              <Typography variant="p">{country}</Typography>
            </div>
            {/* <div className={css.textWrapper}>
              <Typography variant="ptext">Last ordered</Typography>
              <Typography variant="p">-</Typography>
            </div> */}
          </div>
          {(getCompleteStatus || taskIsCompleted) && (
            <div className={css.docContainer}>
              <div className={css.textWrapper}>
                <Typography variant="pdoc">Document Name</Typography>
              </div>
              <div className={css.textWrapperNew}>
                <Typography variant="pdoc">Actions</Typography>
              </div>
            </div>
          )}
          {(getCompleteStatus || taskIsCompleted) && (
            <div className={css.docContent}>
              <div className={css.textWrapper}>
                <Typography variant="span" className={css.docTitle}>
                  Export Credit Guarantee Corporation of India (ECGC)
                </Typography>
              </div>
              <div className={css.textWrapperNew}>
                <DocumentRow.View title="" document={taskData[0]?.ecgc_approval_letter as string} />
              </div>
            </div>
          )}
          {(getCompleteStatus || taskIsCompleted) && (
            <div className={css.docContent}>
              <div className={css.textWrapper}>
                <Typography variant="span" className={css.docTitle}>
                  D&B Report
                </Typography>
              </div>
              <div className={css.textWrapperNew}>
                <DocumentRow.View title="" document={taskData[0]?.dnb_report as string} />
              </div>
            </div>
          )}
        </Accordion.Content>
      </Accordion>
      <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskData[0]?.updated_at)?.diff(taskData[0]?.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div>

      {openModal && taskId && (
        <ECGCModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          customerId="260s8jh"
          taskId={taskId}
          sendStatusToCard={handleStatusFromModal}
        />
      )}
    </div>
  );
};

export default TaskCard;
