import css from './index.module.scss';
import { Button, Divider, IconNode, Typography } from '@components/base';
import { BoxContainer, SelectLabel, TextField } from '@components/common';
import Images from '@assets/images';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ICustomerDetailForm } from '@helpers/types/customer';
import { TransportDetails } from '@helpers/types/transport-details';
import { Fragment, useCallback } from 'react';

interface TransportDetailFormProps {
  onCancelClick?: () => void;
  onBackClick?: () => void;
  onSubmit?: () => void;
  onFormSubmit: (data: TransportDetails) => void;
}

const TransportDetailForm = (props: any) => {
  const { onCancelClick, onBackClick, onFormSubmit } = props;

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <BoxContainer title="Transport Details">
        <div className={css.boxWrapper}>
          <DetailField />
        </div>
      </BoxContainer>
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button type="submit">Save and Proceed</Button>
      </div>
    </form>
  );
};

const DetailField = () => {
  const { control } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    name: 'transportdetails',
    control
  });

  const appendTransportFields = useCallback(
    () =>
      append({
        transport_truck_number: '',
        net_wt_mt: '',
        gross_wt_mt: '',
        tare_wt_mt: '',
        lot_number: '',
        product_details: '',
        marks_and_number: '',
        transportation_costs: 0
      }),
    []
  );

  return (
    <div className={css.formWrapper}>
      {fields.map((field, index) => {
        const deleteButtonState = (field as any).bank_id ? true : false;
        const removeBankDetailField = () => remove(index);
        return (
          <Fragment key={field.id}>
            <div className={css.bankDetailCountWrapper}>
              <Typography variant="h4">{`Truck ${index + 1}`}</Typography>
              {fields.length > 1 && (
                <Button
                  disabled={deleteButtonState}
                  variant="text"
                  startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}
                  onClick={removeBankDetailField}>
                  Delete
                </Button>
              )}
            </div>
            <div className={css.detailWrapper}>
              {/* <Typography variant="body">Details</Typography> */}
              <div className={css.fieldsWrapper}>
                <div className={css.rowWrapper} style={{ width: '49%' }}>
                  <Controller
                    name={`transportdetails.${index}.transport_truck_number`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        // disabled={!remarkCheckboxTabs?.amount}
                        type="text"
                        inputMode="text"
                        enterKeyHint="next"
                        label="Truck Number"
                        placeholder="Add Truck Number"
                        error={fieldState.invalid}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="Error Icon" />
                          )
                        }
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </div>
                <div className={css.rowWrapper}>
                  <Controller
                    name={`transportdetails.${index}.product_details`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        type="text"
                        inputMode="text"
                        enterKeyHint="next"
                        label="Product Details"
                        placeholder="Enter Product Details"
                        error={fieldState.invalid}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="Error Icon" />
                          )
                        }
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  {/* <TextField required label="Lot Number" placeholder="Enter lot number" /> */}
                  <Controller
                    name={`transportdetails.${index}.lot_number`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        type="text"
                        inputMode="text"
                        enterKeyHint="next"
                        label="Lot Number"
                        placeholder="Enter Lot Number"
                        error={fieldState.invalid}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="Error Icon" />
                          )
                        }
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </div>
                <div className={css.rowWrapper}>
                  <Controller
                    name={`transportdetails.${index}.net_wt_mt`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        type="text"
                        inputMode="text"
                        enterKeyHint="next"
                        autoComplete="cc-name"
                        label="Net Weight (MT)"
                        placeholder="Enter Net Weight (MT)"
                        error={fieldState.invalid}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="Error Icon" />
                          )
                        }
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    name={`transportdetails.${index}.tare_wt_mt`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        type="text"
                        inputMode="text"
                        enterKeyHint="next"
                        autoComplete="cc-number"
                        label="Tare Weight (MT)"
                        placeholder="Enter Tare Weight (MT)"
                        error={fieldState.invalid}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="Error Icon" />
                          )
                        }
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </div>
                <div className={css.rowWrapper} style={{ width: '49%' }}>
                  <Controller
                    name={`transportdetails.${index}.gross_wt_mt`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        type="text"
                        inputMode="text"
                        enterKeyHint="next"
                        label="Gross Weight (MT)"
                        placeholder="Enter Gross Weight (MT)"
                        error={fieldState.invalid}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="Error Icon" />
                          )
                        }
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </div>
                <div className={css.rowWrapper}>
                  <Controller
                    name={`transportdetails.${index}.marks_and_number`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        type="text"
                        inputMode="text"
                        enterKeyHint="next"
                        label="Marks and Numbers"
                        placeholder="Add Marks and Numbers"
                        error={fieldState.invalid}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="Error Icon" />
                          )
                        }
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    name={`transportdetails.${index}.transportation_costs`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        type="text"
                        inputMode="text"
                        enterKeyHint="next"
                        label="Transportation Costs"
                        placeholder="Enter Transportation Costs"
                        error={fieldState.invalid}
                        endIcon={
                          fieldState.invalid && (
                            <IconNode src={Images.alertError} alt="Error Icon" />
                          )
                        }
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <Divider className={css.divider} />
          </Fragment>
        );
      })}
      <Button
        variant="text"
        style={{ width: 'fit-content' }}
        startIcon={<IconNode src={Images.plusRed} alt="plus icon" />}
        onClick={appendTransportFields}>
        Add Truck
      </Button>
    </div>
  );
};
export default TransportDetailForm;
