import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import css from './index.module.scss';
import { BreadCrumb, Skeleton, Tab, Tabs } from '@components/base';
import { LinkButton, OrderCardListing, POCSkeletonCard, Seo } from '@components/common';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';
import { fetchCustomerDetail } from '@services/customer.service';
import CustomerDetailCard from './components/customer-detail-card';
import POCDetail from './components/poc-detail';
import { ICustomerInfo } from '@helpers/types/customer';
import CustomerDetailTab from './components/customer-detail-tab';
import CustomerDocumentTab from './components/customer-document-tab';
import PaymentHistoryTab from './components/payment-history-tab';
import OrdersTab from './components/orders-tab';
import AccessWrapper from '@authorization/access-wrapper';
import { MODULE_NAMES } from '@helpers/constants';
import { useScrollToTop } from '@helpers/hooks';

interface ViewCustomerStates {
  customerInfo: ICustomerInfo | null;
  tabState: number;
  isLoading: boolean;
}

const CUSTOMER_MAPPING = [
  {
    value: 0,
    key: 'order',
    default: true
  },
  {
    value: 1,
    key: 'documents'
  },
  {
    value: 2,
    key: 'payment_history'
  },
  {
    value: 3,
    key: 'customer_details'
  }
];

const getDefaultTab = () => {
  const defaultTab = CUSTOMER_MAPPING.find((tab) => tab.default);
  return defaultTab ? defaultTab : CUSTOMER_MAPPING[0];
};

const ViewCustomer = (props: any) => {
  const { modulePermissions = {}, otherModulePermissions = [] } = props;
  const { actions } = modulePermissions;
  const orderActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.order)?.actions,
    [otherModulePermissions]
  );
  const navigate = useNavigate();
  const topRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabParam = searchParams.get('activeTab');
  const defaultTab = useMemo(() => getDefaultTab(), []);
  const initialCustomerState = useMemo(() => {
    return CUSTOMER_MAPPING.find((item) => item.key === activeTabParam)?.key || defaultTab.key;
  }, [activeTabParam]);

  useEffect(() => {
    window.addEventListener('popstate', () => {
      navigate(0);
    });
  }, [activeTabParam]);

  function getValueByKey(key: string) {
    const state = CUSTOMER_MAPPING.find((item) => item.key === key);
    return state ? state.value : defaultTab.value;
  }

  const [customerState, setCustomerState] = useState<ViewCustomerStates>({
    customerInfo: null,
    tabState: getValueByKey(initialCustomerState),
    isLoading: false
  });

  const { customerInfo, tabState, isLoading } = customerState;

  useEffect(() => {
    fetchCustomerInfo();
  }, []);

  const fetchCustomerInfo = async () => {
    const { id } = params;
    if (id) {
      setCustomerState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await fetchCustomerDetail(id);
      setCustomerState((prevState) => ({ ...prevState, isLoading: false }));
      if (response.success) {
        const { data } = response;
        setCustomerState((prevState) => ({ ...prevState, customerInfo: data }));
      } else {
        navigate('/404');
      }
    } else {
      navigate(`/${CLIENT_ROUTES.customer}`);
    }
  };

  const handleTabChange = useCallback((activeStep: number) => {
    const queryKey = CUSTOMER_MAPPING.find((state) => state.value === activeStep);
    setCustomerState((prevState) => ({ ...prevState, tabState: activeStep }));
    setSearchParams({
      ...Object.fromEntries(searchParams),
      activeTab: queryKey?.key ?? defaultTab.key
    });
  }, []);

  const handleChange = (data: ICustomerInfo) => {
    setCustomerState((prevState) => ({ ...prevState, customerInfo: { ...data } }));
  };

  useScrollToTop({ topRef, dependencyArray: [tabState] });

  return (
    <AccessWrapper show={actions?.read ?? false} showUnauthorised>
      {isLoading ? (
        <ViewCustomerSkeleton />
      ) : (
        <main className={css.mainWrapper}>
          {customerInfo && <Seo title={customerInfo.name} />}
          {customerInfo && (
            <div className={css.header}>
              <BreadCrumb>
                <BreadCrumb.Item to={`/${CLIENT_ROUTES.customer}`} value={0}>
                  Customer List
                </BreadCrumb.Item>
                <BreadCrumb.Item
                  to={`/${CLIENT_ROUTES.customer}/${customerInfo.customer_id}`}
                  value={1}>
                  {customerInfo.name}
                </BreadCrumb.Item>
              </BreadCrumb>
              <AccessWrapper show={orderActions?.create ?? false}>
                <LinkButton
                  variant="contained"
                  to={`/${CLIENT_ROUTES.order}/${CLIENT_ROUTES.addOrder}`}
                  className={css.buttonWrapper}>
                  Add Order
                </LinkButton>
              </AccessWrapper>
            </div>
          )}
          {customerInfo && <CustomerDetailCard customerInfo={customerInfo} />}
          {customerInfo && (
            <POCDetail
              customerId={customerInfo.customer_id}
              pocs={customerInfo.pocs}
              onChange={handleChange}
            />
          )}
          <main ref={topRef}>
            {customerInfo && (
              <Tabs value={tabState}>
                <AccessWrapper show={orderActions?.read}>
                  <Tab label="Orders" value={0} onClick={() => handleTabChange(0)} />
                </AccessWrapper>
                <Tab label="Documents" value={1} onClick={() => handleTabChange(1)} />
                <Tab label="Payment History" value={2} onClick={() => handleTabChange(2)} />
                <Tab label="Customer Details" value={3} onClick={() => handleTabChange(3)} />
              </Tabs>
            )}
            {customerInfo && (
              <Fragment>
                {tabState === 0 && (
                  <AccessWrapper show={orderActions?.read ?? false}>
                    <OrdersTab customerId={params.id} actions={orderActions} />
                  </AccessWrapper>
                )}
                {tabState === 1 && (
                  <CustomerDocumentTab customerInfo={customerInfo} onChange={handleChange} />
                )}
                {tabState === 2 && <PaymentHistoryTab customerInfo={customerInfo} />}
                {tabState === 3 && (
                  <CustomerDetailTab
                    customerInfo={customerInfo}
                    onChange={handleChange}
                    actions={actions}
                  />
                )}
              </Fragment>
            )}
          </main>
        </main>
      )}
    </AccessWrapper>
  );
};

export default ViewCustomer;

const ViewCustomerSkeleton = () => {
  return (
    <div className={css.ViewCustomerSkeletonWrapper}>
      <div className={css.ViewCustomerSkeletonHeader}>
        <div className={css.ViewCustomerSkeletonHeaderLeft}>
          <div className={css.ViewCustomerSkeletonHeaderDate}>
            <Skeleton variant="text" width={150} />
            <Skeleton variant="text" width={200} />
          </div>
          <Skeleton variant="text" width={400} height={20} />
          <Skeleton variant="text" width={150} height={20} />
          <Skeleton variant="text" width={200} height={20} />
          <Skeleton variant="text" width={200} height={20} />
        </div>
        <div className={css.ViewCustomerSkeletonHeaderCenter}>
          <Skeleton variant="text" width={250} height={20} />
          <div className={css.ViewCustomerSkeletonHeaderCenterDetails}>
            <div className={css.ViewCustomerSkeletonHeaderCenterDetailsObject}>
              <Skeleton variant="text" width={200} height={20} />
              <Skeleton variant="text" width={200} height={20} />
            </div>
            <div className={css.ViewCustomerSkeletonHeaderCenterDetailsObject}>
              <Skeleton variant="text" width={200} height={20} />
              <Skeleton variant="text" width={200} height={20} />
            </div>
          </div>
          <div className={css.ViewCustomerSkeletonHeaderCenterDetails}>
            <div className={css.ViewCustomerSkeletonHeaderCenterDetailsObject}>
              <Skeleton variant="text" width={200} height={20} />
              <Skeleton variant="text" width={200} height={20} />
            </div>
            <div className={css.ViewCustomerSkeletonHeaderCenterDetailsObject}>
              <Skeleton variant="text" width={200} height={20} />
              <Skeleton variant="text" width={200} height={20} />
            </div>
          </div>
        </div>
        <div className={css.ViewCustomerSkeletonHeaderRight}>
          <Skeleton variant="text" width={400} height={20} />
          <Skeleton variant="text" width={200} height={20} />
          <Skeleton variant="text" width={400} height={20} />
          <Skeleton variant="text" width={200} height={20} />
          <Skeleton variant="text" width={400} height={20} />
          <Skeleton variant="text" width={200} height={20} />
        </div>
      </div>
      <div className={css.ViewCustomerSkeletonBody}>
        <div className={css.ViewCustomerSkeletonBodyUpper}>
          <Skeleton variant="text" width={150} height={20} />
          <Skeleton variant="text" width={150} height={20} />
        </div>
        <POCSkeletonCard />
      </div>
      <div className={css.ViewCustomerSkeletonFooter}>
        <div className={css.ViewCustomerSkeletonFooterTabs}>
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" width={150} height={30} />
        </div>
        {/* <OrderCardListing /> */}
      </div>
    </div>
  );
};
