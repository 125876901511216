import { Button, IconNode, Modal, CheckboxLabel, Typography, Loader } from '@components/base';
import css from './index.module.scss';
import { InputDatePicker, TextAreaLabel, DragDropUpload } from '@components/common';
import Images from '@assets/images';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { proofOfExport } from '@services/task.service';
import { addVesselDetails } from '@helpers/yup/add-vessel.schema';
import notify from '@helpers/toastify-helper';
import { MAX_FILES_ALLOWED } from '@helpers/constants';
import { useCallback, useEffect } from 'react';
import { ISelect } from '@helpers/types';

interface VesselModalProps {
  open: boolean;
  onClose?: () => void;
  sendStatusToCard: (arg: boolean) => void;
  taskId: string;
  onComplete?: () => void;
}

interface FormType {
  document_array: File[] | null;
  not_applicable: boolean;
  remarks: string;
}

const ProofOfExportModal = (props: VesselModalProps) => {
  const { open, onClose, sendStatusToCard, taskId, onComplete } = props;
  //const [isCompleted, setIsCompleted] = useState<boolean>(isCompleted)

  const vesselDispatchForm = useForm<FormType>({
    // resolver: yupResolver(addVesselDetails),
    defaultValues: {
      document_array: [],
      not_applicable: false,
      remarks: ''
    }
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting }
  } = vesselDispatchForm;

  const watch = useWatch({ name: 'not_applicable', control: control });
  const docWatch = useWatch({ name: 'document_array', control: control });
  const notApplicableWatch = useWatch({ name: 'not_applicable', control: control });

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const formData = new FormData();
    if (data.not_applicable) {
      formData.append('not_applicable', data.not_applicable ? 'true' : 'false');
    } else {
      if (data?.remarks?.length) {
        formData.append('remarks', data.remarks);
      }
      if (data?.document_array?.length) {
        data.document_array.forEach((file: any, index: number) => {
          formData.append(`${index}`, file);
        });
      } else {
        notify({ message: 'Please upload at least one file', severity: 'error' });
        return;
      }
    }

    const response = await proofOfExport(taskId, formData);
    if (response.success) {
      notify({ message: 'Proof of export details added sucessfully' });
      sendStatusToCard(true);
      if (onComplete) {
        onComplete();
      }
      onClose?.();
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else notify({ message: 'Some error occurred', severity: 'error' });
  };

  const handleFileDrop = useCallback((files: File[]) => {
    if (files.length) {
      if (files.length > MAX_FILES_ALLOWED.POOF_OF_EXPORT) {
        notify({
          message: `Maximum of ${MAX_FILES_ALLOWED.POOF_OF_EXPORT} can be uploaded`,
          severity: 'error'
        });
      } else {
        setValue('document_array', files.slice(0, MAX_FILES_ALLOWED.POOF_OF_EXPORT));
      }
    }
  }, []);

  const handleDeleteFile = useCallback(() => {
    setValue('document_array', null);
  }, []);

  useEffect(() => {
    if (notApplicableWatch) {
      setValue('remarks', '');
      setValue('document_array', null);
    }
  }, [notApplicableWatch]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Add Proof of Export</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="div"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={css.fieldsWrapper}>
            <div>
              <Typography variant="p" className={css.docLabel}>
                Upload booking copy
              </Typography>
              <div className={css.checkBox}>
                <Controller
                  name="not_applicable"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CheckboxLabel
                      {...field}
                      label="Is proof of export not required?"
                      value={`${field.value}`}
                      checked={field.value}
                    />
                  )}
                />
              </div>
              <Controller
                name="document_array"
                control={control}
                render={() => (
                  <DragDropUpload acceptImages={true} multiple={true} onDrop={handleFileDrop} />
                )}
              />
              {docWatch && (
                <div className={css.fileUploadState}>
                  <Typography variant="p" className={css.fileName}>
                    {docWatch?.length} File(s) uploaded
                  </Typography>
                  <IconNode
                    src={Images.deleteRed}
                    alt="delete icon"
                    component="button"
                    className={css.deleteButton}
                    onClick={handleDeleteFile}
                  />
                </div>
              )}
            </div>
            <Controller
              name="remarks"
              control={control}
              render={({ field, fieldState }) => (
                <TextAreaLabel
                  {...field}
                  inputMode="text"
                  rows={5}
                  disabled={watch}
                  label="Remarks"
                  placeholder="This is a hint text to help user."
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
          </div>
          <div className={css.actionButtonWrapper}>
            <Button variant="outlined-secondary" className={css.actionButton} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" className={css.actionButton}>
              Save
            </Button>
          </div>
        </form>
        <Loader open={isSubmitting} />
      </div>
    </Modal>
  );
};

export default ProofOfExportModal;
