import { BoxContainer, TextAreaLabel } from '@components/common';
import css from './index.module.scss';
import { Controller, useFormContext } from 'react-hook-form';

const RemarkContainer = () => {
  const { control } = useFormContext();
  return (
    <BoxContainer title="Additional Remarks/Requirements" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <Controller
          name="remarks"
          control={control}
          render={({ field }) => (
            <TextAreaLabel
              {...field}
              rows={6}
              inputMode="text"
              placeholder="Enter additional remarks/ requirements by the customer"
            />
          )}
        />
      </div>
    </BoxContainer>
  );
};

export default RemarkContainer;
