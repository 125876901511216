import React, { useRef, useEffect, useState } from 'react';
import { TableRow, TableCell, Skeleton } from '@mui/material';
import css from './index.module.scss';

interface TableSkeletonProps {
  rowsNum?: number;
  colsNum?: number;
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({ rowsNum = 10, colsNum = 5 }) => {
  const [skeletonWidth, setSkeletonWidth] = useState(0);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculateSkeletonWidth = () => {
      if (tableRef.current) {
        const tableWidth = tableRef.current.offsetWidth;
        const cellPadding = 16; // Assuming default MUI padding
        const availableWidth = tableWidth - cellPadding * 2 * colsNum;
        const calculatedWidth = Math.floor(availableWidth / colsNum);
        setSkeletonWidth(calculatedWidth > 0 ? calculatedWidth : 0);
      }
    };

    calculateSkeletonWidth();
    window.addEventListener('resize', calculateSkeletonWidth);

    return () => {
      window.removeEventListener('resize', calculateSkeletonWidth);
    };
  }, [colsNum]);

  return (
    <div className={css.tableSkeleton} ref={tableRef}>
      {[...Array(rowsNum)].map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          {[...Array(colsNum)].map((_, colIndex) => (
            <TableCell key={colIndex}>
              <Skeleton animation="wave" variant="text" width={skeletonWidth} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </div>
  );
};

export default TableSkeleton;
