import React from 'react';
import { BoxContainer, TextField } from '@components/common';
import { useFormContext, Controller } from 'react-hook-form';
import { IconNode, Button, Typography } from '@components/base';
import Images from '@assets/images';
import { FieldValue } from '@helpers/types/coa';
import css from './index.module.scss';

interface NotesPropsTypes {
  data: Array<any>;
  remove: (index?: number | number[]) => void;
  handleAddField: () => void;
}

const Notes = (props: NotesPropsTypes) => {
  const { data, remove, handleAddField } = props;
  return (
    <BoxContainer title="Notes" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.fieldValueWrapper}>
          {data.map((item: any, index: number) => (
            <FieldValueItem
              onDeleteClick={() => remove(index)}
              key={item.id}
              fieldNameIndex={`notes.${index}.field_value`}
              showDelete={data.length > 1}
            />
          ))}
          <Button
            variant="text"
            onClick={handleAddField}
            startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
            Add Note
          </Button>
        </div>
      </div>
    </BoxContainer>
  );
};

interface FieldValueItemPropsTypes {
  onDeleteClick: () => void;
  fieldNameIndex: string;
  showDelete: boolean;
}

const FieldValueItem = (props: FieldValueItemPropsTypes) => {
  const { onDeleteClick, fieldNameIndex, showDelete } = props;
  const { control } = useFormContext<any>();

  return (
    <>
      <div className={css.fieldValueItem}>
        <Controller
          name={fieldNameIndex}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              type="text"
              inputMode="text"
              enterKeyHint="next"
              placeholder="Enter Note"
              error={fieldState.invalid}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
              helperText={fieldState.error?.message}
            />
          )}
        />
        {showDelete ? (
          <Button
            variant="text"
            title="Delete Item"
            onClick={onDeleteClick}
            startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}></Button>
        ) : (
          <Button
            variant="text"
            title="Delete Item"
            disabled
            startIcon={<IconNode src={Images.delete} alt="delete icon" />}></Button>
        )}
      </div>
    </>
  );
};

export default Notes;
