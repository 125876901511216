import { Button } from '@components/base';
import Checkbox from '@components/base/checkbox/checkbox';
import { BoxContainer, InputDatePicker, SelectLabel, TextField } from '@components/common';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import { Controller, useFormContext } from 'react-hook-form';
import { getFormattedDate } from '@helpers/utils';
import css from './index.module.scss';

interface IOrderBankDetails {
  orderBankData: PerformaInvoiceStates;
  handleCancelClick: () => void;
  onBackClick: () => void;
  onFormSubmit: (data: any) => void;
  invoiceType: string;
}

const OrderBankDetailsTab = (props: IOrderBankDetails) => {
  const { control, handleSubmit } = useFormContext();
  const { orderBankData, handleCancelClick, onBackClick, onFormSubmit, invoiceType } = props;
  const { orderDetails, bankDetails } = orderBankData;
  useBeforeUnloadAndNavigate();

  const isBlOrAwb = orderDetails?.transportation_mode === 'AIR' ? 'AWB' : 'BL';

  return (
    <form noValidate className={css.purchaseOrderFormWrapper} onSubmit={handleSubmit(onFormSubmit)}>
      <BoxContainer title="Order Details">
        <div className={css.purchaserWrapper}>
          <div className={css.nameFieldWrapper}>
            <SelectLabel
              label="Mode of Transportation"
              placeholder="Mode of Transportation"
              value={{
                value: orderDetails?.transportation_mode,
                label: orderDetails?.transportation_mode
              }}
              isDisabled
            />
            <TextField
              disabled
              label="Inco Terms"
              placeholder="Inco Terms"
              value={orderDetails?.inco_terms ?? ''}
            />
          </div>
        </div>
        <div className={css.purchaserWrapper}>
          <div className={css.nameFieldWrapper}>
            <SelectLabel
              label="Country of  Origin"
              placeholder="Country of  Origin"
              isDisabled
              value={{
                value: orderDetails?.country_of_origin,
                label: orderDetails?.country_of_origin
              }}
            />
            <SelectLabel
              label="Country of Final Destination"
              placeholder="Country of Final Destination"
              value={{
                value: orderDetails?.country_of_final_destination,
                label: orderDetails?.country_of_final_destination
              }}
              isDisabled
            />
          </div>
        </div>
        <div className={css.purchaserWrapper}>
          <div className={css.nameFieldWrapper}>
            <TextField
              disabled
              label="Port of Loading"
              placeholder="Port of Loading"
              value={
                orderDetails?.port_of_loading_display_value &&
                orderDetails?.port_of_loading_country_display_value
                  ? `${orderDetails?.port_of_loading_display_value} - ${orderDetails?.port_of_loading_country_display_value}`
                  : ''
              }
            />
            <TextField
              disabled
              label="Port of Discharge"
              placeholder="Port of Discharge"
              value={
                orderDetails?.port_of_discharge_display_value &&
                orderDetails?.port_of_discharge_country_display_value
                  ? `${orderDetails?.port_of_discharge_display_value} - ${orderDetails?.port_of_discharge_country_display_value}`
                  : ''
              }
            />
            <TextField
              label="Place of Delivery"
              disabled
              placeholder="Place of Delivery"
              value={orderDetails?.place_of_delivery ?? ''}
            />
          </div>
          <div className={css.nameFieldWrapper}>
            <SelectLabel
              label="Payment Term"
              placeholder="Payment Term"
              isDisabled
              value={{
                value: orderDetails?.payment_terms,
                label: orderDetails?.payment_terms
              }}
            />
            <SelectLabel
              label="Delivery Term"
              placeholder="Delivery Term"
              isDisabled
              value={{
                value: orderDetails?.inco_terms,
                label: orderDetails?.inco_terms
              }}
            />
          </div>
          {invoiceType === 'Commercial' && (
            <div className={css.nameFieldWrapper}>
              <Controller
                name="bl_number"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={`${isBlOrAwb} Number`}
                    disabled
                    value={
                      orderDetails?.transportation_mode === 'AIR'
                        ? orderDetails?.awb_number ?? '-'
                        : orderDetails?.bl_number ?? '-'
                    }
                    placeholder="Enter number"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ''}
                  />
                )}
              />
              <TextField
                label={`${isBlOrAwb} Date`}
                value={
                  orderDetails?.transportation_mode === 'AIR'
                    ? orderDetails?.awb_date
                      ? getFormattedDate(orderDetails?.awb_date)?.toString()
                      : '-'
                    : orderDetails?.bl_date
                    ? getFormattedDate(orderDetails?.bl_date)?.toString()
                    : '-'
                }
                disabled
                placeholder="Enter date"
              />
              {orderDetails?.transportation_mode === 'AIR' && (
                <TextField
                  label="AWB Service Name"
                  value={orderDetails?.awb_service_name}
                  disabled
                  placeholder="Enter date"
                />
              )}
            </div>
          )}

          <div className={css.checkboXWrap}>
            <Checkbox
              checked={orderDetails?.is_qc_test}
              value={`${orderDetails?.is_qc_test}`}
              disabled
            />
            <TextField
              label="Place of Quality Test"
              disabled
              placeholder="Place of Quality Test"
              value={orderDetails?.place_of_quality_test ?? ''}
            />
          </div>
        </div>
      </BoxContainer>
      <BoxContainer title="Entity Bank Details">
        <div className={css.purchaserWrapper}>
          <div className={css.nameFieldWrapper}>
            <SelectLabel
              label="Bank Name"
              isDisabled
              value={{
                value: bankDetails?.bank_name,
                label: bankDetails?.bank_name
              }}
            />
            <TextField
              label="Branch Name"
              disabled
              placeholder="Country"
              value={bankDetails?.branch_name ?? ''}
            />
          </div>
          <div className={css.nameFieldWrapper}>
            <TextField
              label="Account Holder Name"
              disabled
              placeholder="POC Contact Number"
              value={bankDetails?.account_number ?? ''}
            />
          </div>
          <div className={css.nameFieldWrapper}>
            <TextField
              label="Bank Account Number"
              disabled
              placeholder="Country"
              value={bankDetails?.bank_account_holder_name ?? ''}
            />
            <TextField
              label="IFSC Code"
              disabled
              placeholder="Country"
              value={bankDetails?.ifsc_code ?? ''}
            />
            <TextField
              label="Swift"
              disabled
              placeholder="Country"
              value={bankDetails?.swift_code ?? ''}
            />
          </div>

          <TextField
            disabled
            label="Bank Address"
            placeholder="Bank Address"
            value={bankDetails?.address ?? ''}
          />
        </div>
      </BoxContainer>
      <div className={css.formActionWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button onClick={onBackClick} variant="outlined-secondary">
            Back
          </Button>
          <Button type="submit">Save and Proceed</Button>
        </div>
      </div>
    </form>
  );
};

export default OrderBankDetailsTab;
