/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Loader, Typography } from '@components/base';
import { AppContext } from '@helpers/hooks/AppContext';
import notify from '@helpers/toastify-helper';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import { CLIENT_ROUTES } from '@router/routes';
import {
  approvePerformaReview,
  fetchReviewById,
  rejectPerformaReview
} from '@services/order.service';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import PIReview from './components/preview-tab';
import RejectPIModal from './components/preview-tab/components/reject-modal';
import css from './index.module.scss';

interface IRectifyRemark {
  rectify_remarks: string;
}

const ProformaInvoiceReview = () => {
  const { appState } = useContext(AppContext);
  const { PIData } = appState;
  const navigate = useNavigate();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [reviewState, setReviewState] = useState<PerformaInvoiceStates>({
    isLoading: false,
    activeStep: 0,
    itemDetailsInfo: null,
    purchaseOrder: null,
    remarksInfo: {},
    remarks: '',
    orderInfo: {
      invoice_number: '',
      order_type: '',
      order_number: ''
    },
    entityList: {
      name: '',
      gst_number: '',
      address: ''
    },
    customerInfo: {
      name: '',
      country: null,
      address: '',
      customer_poc: {}
    },
    consigneeInfo: {
      isSameAsCustomer: false,
      consignee_name: '',
      consignee_phone_number: '',
      consignee_email_id: '',
      consignee_office_address: ''
    },
    itemInfo: {
      items: [],
      totalAmount: '',
      supplier: [],
      totalAmountInWords: ''
    },
    orderDetails: {
      transportation_mode: '',
      inco_terms: '',
      country_of_origin: '',
      country_of_final_destination: '',
      port_of_loading: '',
      port_of_loading_display_value: '',
      port_of_loading_country_display_value: '',
      port_of_discharge: '',
      port_of_discharge_display_value: '',
      port_of_discharge_country_display_value: '',
      place_of_delivery: '',
      payment_terms: '',
      is_qc_test: false,
      delivery_term: '',
      place_of_quality_test: ''
    },
    bankDetails: {
      bank_name: '',
      branch_name: '',
      bank_account_holder_name: '',
      account_number: '',
      ifsc_code: '',
      swift_code: '',
      address: ''
    },
    previewLink: null
  });

  const { isLoading, previewLink } = reviewState;

  useEffect(() => {
    (async () => {
      setReviewState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
      const response = await fetchReviewById(params.id as string);
      if (response.success) {
        const { data: reviewinfo } = response;
        /**
         * Binding pi order, totalAmt and rate value
         */
        if (reviewinfo?.order?.order_item) {
          reviewinfo.order.order_item = reviewinfo.order.order_item.map((item: any) => {
            item.quantity_mt = item.quantity_pi ? item.quantity_pi : item.quantity_mt;
            item.rate_mt_usd = item?.selling_price;
            item.total_amount = item.total_amount_pi ? item.total_amount_pi : item.total_amount;
            return item;
          });
        }
        setReviewState((prevState) => ({
          ...prevState,
          isLoading: !prevState.isLoading,
          orderInfo: {
            invoice_number: reviewinfo?.document_number,
            order_type: reviewinfo?.order?.supply_type,
            order_number: reviewinfo?.order?.readable_order_id
          },
          entityList: {
            name: reviewinfo?.order?.elchemy_entity?.name,
            gst_number: reviewinfo?.order?.elchemy_entity?.gst_number,
            address: reviewinfo?.order?.elchemy_entity?.address
          },
          customerInfo: {
            name: reviewinfo?.order?.customer?.name,
            country: reviewinfo?.order?.customer?.country,
            address: `${reviewinfo?.order?.customer_address?.address_line1 ?? ''} ${
              reviewinfo?.order?.customer_address?.address_line2 ?? ''
            } ${reviewinfo?.order?.customer_address?.city ?? ''} ${
              reviewinfo?.order?.customer_address?.state ?? ''
            } ${reviewinfo?.order?.customer_address?.country ?? ''}`,
            customer_poc: reviewinfo?.order?.customer_poc
          },
          consigneeInfo: {
            isSameAsCustomer: reviewinfo?.order?.consignee_same_as_customer,
            consignee_name: reviewinfo?.order?.consignee_name,
            consignee_phone_number: reviewinfo?.order?.consignee_phone_number,
            consignee_email_id: reviewinfo?.order?.consignee_email_id,
            consignee_office_address: reviewinfo?.order?.consignee_office_address,
            address: `${reviewinfo?.order?.customer_address?.address_line1 ?? ''} ${
              reviewinfo?.order?.customer_address?.address_line2 ?? ''
            } ${reviewinfo?.order?.customer_address?.city ?? ''} ${
              reviewinfo?.order?.customer_address?.state ?? ''
            } ${reviewinfo?.order?.customer_address?.country ?? ''}`,
            customer_poc: reviewinfo?.order?.customer_poc
          },
          itemInfo: {
            items: reviewinfo?.order?.order_item,
            totalAmount: reviewinfo?.total_amount,
            supplier: reviewinfo?.suppliers,
            totalAmountInWords: reviewinfo?.total_amount_of_all_items_in_words,
            currency: reviewinfo?.currency,
            unit_of_weight: reviewinfo?.unit_of_weight,
            order_item_description_pi: reviewinfo?.order_item_description_pi
          },
          bankDetails: {
            bank_name: reviewinfo?.order?.elchemy_bank?.bank_name,
            branch_name: reviewinfo?.order?.elchemy_bank?.branch_name,
            bank_account_holder_name: reviewinfo?.order?.elchemy_bank?.bank_account_holder_name,
            account_number: reviewinfo?.order?.elchemy_bank?.account_number,
            ifsc_code: reviewinfo?.order?.elchemy_bank?.ifsc_code,
            swift_code: reviewinfo?.order?.elchemy_bank?.swift_code,
            address: `${reviewinfo?.order?.elchemy_bank?.address_line1 ?? ''} ${
              reviewinfo?.elchemy_bank?.address_line2 ?? ''
            } ${reviewinfo?.order?.elchemy_bank?.city ?? ''} ${
              reviewinfo?.order?.elchemy_bank?.state ?? ''
            }`
          },
          orderDetails: {
            transportation_mode: reviewinfo?.order?.transportation_mode,
            inco_terms: reviewinfo?.order?.inco_terms,
            country_of_origin: reviewinfo?.order?.country_of_origin,
            country_of_final_destination: reviewinfo?.order?.country_of_final_destination,
            port_of_loading: reviewinfo?.order?.port_of_loading,
            port_of_loading_display_value: reviewinfo?.order?.port_of_loading_display_value,
            port_of_loading_country_display_value:
              reviewinfo?.order?.port_of_loading_country_display_value,
            port_of_discharge: reviewinfo?.order?.port_of_discharge,
            port_of_discharge_display_value: reviewinfo?.order?.port_of_discharge_display_value,
            port_of_discharge_country_display_value:
              reviewinfo?.order?.port_of_discharge_country_display_value,
            place_of_delivery: reviewinfo?.order?.place_of_delivery,
            payment_terms: reviewinfo?.order?.payment_terms_display_value,
            is_qc_test: reviewinfo?.order?.is_qc_test,
            place_of_quality_test: reviewinfo?.order?.place_of_quality_test,
            delivery_term: reviewinfo?.order?.inco_terms
          },
          itemDetailsInfo: {
            custom_items: reviewinfo?.custom_items?.map((item: any) => {
              return {
                custom_item_name: item?.name ?? '',
                custom_item_description: item?.value ?? '',
                custom_item_rate: item?.rate ?? null,
                custom_item_quantity: item?.quantity ?? null,
                custom_item_amount: item?.amount ?? null
              };
            })
          },
          purchaseOrder: {
            document_date: reviewinfo?.po_reference_date ?? null,
            po_reference_number: reviewinfo?.po_reference_no,
            notify_party: reviewinfo?.notify_party
          },
          remarksInfo: {
            string: JSON.parse(reviewinfo?.remarks).string
          },
          previewLink: reviewinfo?.pdf_document_display_url
        }));
      } else {
        setReviewState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
        notify({
          message: response.error ?? 'Unable to fetch data',
          severity: 'error',
          dismissible: true
        });
      }
    })();
  }, []);

  const handleFormSubmit = async () => {
    setReviewState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
    const response = await approvePerformaReview(PIData?.task_id as string, {});
    if (response.success) {
      notify({ message: response?.data?.message, severity: 'success', dismissible: true });
    } else {
      notify({
        message: response.error ?? 'Something went wrong!',
        severity: 'error',
        dismissible: true
      });
    }
    setReviewState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
    navigate(`/${CLIENT_ROUTES.order}/${params?.id as string}`);
  };

  const handleCancelClick = () => {
    navigate(`/${CLIENT_ROUTES.order}/${params?.id as string}`);
  };

  const handleRejectNoteSubmit: SubmitHandler<IRectifyRemark> = async (data) => {
    setReviewState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
    const response = await rejectPerformaReview(PIData?.task_id as string, data);
    if (response.success) {
      notify({ message: response?.data?.message, severity: 'success', dismissible: true });
    } else {
      notify({
        message: response.error ?? 'Something went wrong!',
        severity: 'error',
        dismissible: true
      });
    }
    setReviewState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
    navigate(`/${CLIENT_ROUTES.order}/${params?.id as string}`);
  };

  const openPreviewLink = () => {
    if (previewLink?.length) {
      window.open(previewLink, '_blank');
    }
  };

  return (
    <main className={css.purchaseOrderWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Review Proforma Invoice</Typography>
        <Button variant="outlined" onClick={openPreviewLink}>
          Open in PDF
        </Button>
      </div>
      <div className={css.purchaseOrderContainer}>
        <PIReview
          previewData={reviewState}
          handleFormSubmit={handleFormSubmit}
          handleCancelClick={handleCancelClick}
          handleRejectClick={() => setOpen(!open)}
        />
      </div>
      <RejectPIModal open={open} onFormSubmit={handleRejectNoteSubmit} onClose={setOpen} />
      <Loader open={isLoading} />
    </main>
  );
};

export default ProformaInvoiceReview;
