import css from './index.module.scss';
import { Button, IconNode } from '@components/base';
import { BoxContainer } from '@components/common';
import { useFormContext, useFieldArray } from 'react-hook-form';
import GradeFields from '../grade-fields';
import Images from '@assets/images';
import { IGradeForm } from '@helpers/types/grade';

const GradeDetailContainer = () => {
  const { control } = useFormContext<IGradeForm>();
  const { fields, append, remove } = useFieldArray({
    name: 'grades',
    control
  });

  const addGradeField = () => {
    append({ grade_id: '', name: '', description: '', packaging_details: '', remarks: '' });
  };

  return (
    <BoxContainer title="Grade Details" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        {fields.map((field, index) => {
          const handleDelete = () => remove(index);
          return (
            <GradeFields
              key={field.id}
              index={index + 1}
              gradeId={(field as any).grade_id}
              name={`grades.${index}.name`}
              description={`grades.${index}.description`}
              packagingDetails={`grades.${index}.packaging_details`}
              remarks={`grades.${index}.remarks`}
              showDeleteButton={fields.length > 1}
              onDelete={handleDelete}
            />
          );
        })}
        <Button
          variant="text"
          onClick={addGradeField}
          startIcon={<IconNode src={Images.plusRed} alt="add icon" />}
          className={css.buttonWrapper}>
          Add Grade
        </Button>
      </div>
    </BoxContainer>
  );
};

export default GradeDetailContainer;
