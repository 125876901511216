import { DataGrid, ListPlaceholder, TableSkeleton } from '@components/common';
import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import css from './index.module.scss';
import { Button, IconNode, Typography } from '@components/base';
import {
  getCommaSeperatedCurrencyAmount,
  getFormattedDate,
  getFormattedTime,
  getPermissionFromLocalStorage,
  sortByUploadedOnNewToOld,
  sortByUploadedOnOldToNew
} from '@helpers/utils';
import CompletePayment from './complete-payment';
import { getPaymentBulkSheet } from '@services/finance.service';
import notify from '@helpers/toastify-helper';
import Images from '@assets/images';
import { CURRENCY } from '@helpers/constants';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileSaver = require('file-saver');

const PaymentInitiated = (props: any) => {
  const { data, dataLoading, fetchPaymentTableData, handleChipClick } = props;
  const [selectedPayments, setSelectedPayments] = useState<any[]>([]);
  const [openConfirmPaymentModal, setOpenConfirmPaymentModal] = useState(false);

  const [documentState, setDocumentState] = useState({
    docData: [],
    sortedDocData: [],
    currentStatus: 0
  });

  useEffect(() => {
    setDocumentState((prevState: any) => ({
      ...prevState,
      docData: data,
      sortedDocData: data
    }));
  }, [data]);

  const { docData, currentStatus, sortedDocData } = documentState;

  const sortDocument = (status: number) => {
    let sortedData: Array<any>;
    switch (status) {
      case 0:
        sortedData = [...docData];
        break;
      case 1:
        sortedData = [...docData].sort(sortByUploadedOnNewToOld);
        break;
      case 2:
        sortedData = [...docData].sort(sortByUploadedOnOldToNew);
        break;
      default:
        sortedData = [...docData];
    }

    setDocumentState((prevState: any) => ({
      ...prevState,
      sortedDocData: sortedData,
      currentStatus: status
    }));
  };

  const is_finance_team_member = useMemo(
    () => getPermissionFromLocalStorage()?.is_finance_team_member,
    []
  );

  const bulkPaymentSheetDownload = async (value: any) => {
    const response = await getPaymentBulkSheet(value?.payment_cart_id);
    if (typeof response == 'object' && !response?.error) {
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      FileSaver.saveAs(blob, `${value?.internal_payment_cart_id ?? `ORDER`}.xls`);
    } else {
      notify({
        message: response?.error ?? 'Something Went Wrong',
        severity: 'error'
      });
    }
  };

  const [columnData, rowData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Cart ID',
        accessor: 'internal_payment_cart_id'
      },
      {
        Header: 'Supplier',
        accessor: 'suppliers'
      },
      {
        Header: 'Net Amount',
        accessor: 'total_amount'
      },
      {
        Header: () => (
          <div
            className={css.sortTableHeader}
            onClick={() => {
              const newStatus = currentStatus === 0 ? 1 : currentStatus === 1 ? 2 : 1;
              sortDocument(newStatus);
            }}>
            <span>Created On</span>
            <IconNode src={Images.sort} alt="document" width={16} height={16} />
          </div>
        ),
        accessor: 'created_at',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <div>
              <div>{getFormattedDate(value)}</div>
              <div>{getFormattedTime(value)}</div>
            </div>
          );
        }
      },
      {
        Header: 'Bulk Payment Doc',
        accessor: 'bulk_payment_doc',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Button variant="text" onClick={() => bulkPaymentSheetDownload(value)}>
                Generate{' '}
              </Button>
            </>
          );
        }
      },
      {
        Header: '',
        accessor: 'action',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              {is_finance_team_member ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedPayments(value);
                    setOpenConfirmPaymentModal(true);
                  }}>
                  Complete Payment
                </Button>
              ) : (
                <Button
                  variant="text"
                  onClick={() => {
                    setSelectedPayments(value);
                    setOpenConfirmPaymentModal(true);
                  }}>
                  View
                </Button>
              )}
            </>
          );
        }
      }
    ];

    const row: Array<any> = sortedDocData?.length
      ? sortedDocData?.map((item: any) => {
          const formattedNetAmount = getCommaSeperatedCurrencyAmount(item.currency, item?.total_amount);

          return {
            // check_box: item,
            internal_payment_cart_id: item?.internal_payment_cart_id ?? '-',
            total_amount: formattedNetAmount,
            suppliers: item?.suppliers ?? '-',
            // invoice_details: item?.invoice_details ?? null,
            created_at: item?.created_at,
            action: item,
            bulk_payment_doc: item
          };
        })
      : [];

    return [column, row];
  }, [data, sortedDocData, docData]);

  return (
    <div className={css.tableWrapper}>
      {dataLoading ? (
        <TableSkeleton colsNum={6} rowsNum={10} />
      ) : rowData.length ? (
        <>
          <DataGrid columns={columnData} data={rowData} tableStyle={{ width: '100%' }} />
        </>
      ) : (
        <ListPlaceholder
          title="No Payment Initiated"
          supportingText="You have not initiated any payment yet."
        />
      )}
      {openConfirmPaymentModal && (
        <CompletePayment
          open={openConfirmPaymentModal}
          onClose={() => setOpenConfirmPaymentModal(false)}
          data={selectedPayments}
          contentType={is_finance_team_member ? 'form' : 'view'}
          fetchPaymentTableData={fetchPaymentTableData}
          handleChipClick={handleChipClick}
          setData={setSelectedPayments}
        />
      )}
    </div>
  );
};

export default PaymentInitiated;
