import { useEffect } from 'react';

interface scrollTopProps {
  topRef: React.MutableRefObject<any>;
  dependencyArray?: any[];
  timeDiff?: number;
}

const useScrollToTop = (props: scrollTopProps): void => {
  const { topRef, dependencyArray = [], timeDiff = 0 } = props;
  useEffect(() => {
    setTimeout(() => {
      topRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, timeDiff);
  }, dependencyArray);
};

export default useScrollToTop;
