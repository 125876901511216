import css from './index.module.scss';
import { Checkbox, Typography, CheckboxLabel, Button, IconNode } from '@components/base';
import {
  BoxContainer,
  InputDatePicker,
  SelectLabel,
  TextAreaLabel,
  TextField
} from '@components/common';
import Images from '@assets/images';
import { Controller, useFormContext } from 'react-hook-form';
import { countries } from '@helpers/constants';

const RemarksForm = (props: any) => {
  const { formSubmit, onBackClick, remarkCheckboxTabs, setRemarkCheckboxTabs, onCancelClick } =
    props;
  const { control, handleSubmit } = useFormContext();

  return (
    <form className={css.formWrapper} onSubmit={handleSubmit(formSubmit)} noValidate>
      <BoxContainer title="Remarks">
        <div className={css.boxWrapper}>
          <div className={css.fieldWrapper}>
            <Checkbox
              checked={remarkCheckboxTabs.dispatch_date}
              value={`${remarkCheckboxTabs.dispatch_date}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  dispatch_date: !remarkCheckboxTabs.dispatch_date
                })
              }
            />
            <Typography>Dispatch Date:</Typography>
            <Controller
              name={`dispatch_date_info`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  label=""
                  type="text"
                  placeholder="Enter number"
                  error={fieldState.invalid}
                  value={field?.value ?? ''}
                  disabled={!remarkCheckboxTabs.dispatch_date}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                  // onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                />
              )}
            />
            <Typography>FCLs on or before</Typography>
            <Controller
              name={`fcl`}
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  label=""
                  placeholder="select date"
                  error={fieldState.invalid}
                  value={new Date(`${field?.value ?? ''}`) ?? ''}
                  disabled={!remarkCheckboxTabs.dispatch_date}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                />
              )}
            />
          </div>
          <div className={css.fieldWrapper}>
            <Checkbox
              checked={remarkCheckboxTabs.amount}
              value={`${remarkCheckboxTabs.amount}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  amount: !remarkCheckboxTabs.amount
                })
              }
            />
            <Controller
              name={`amount_info`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  disabled={!remarkCheckboxTabs?.amount}
                  type="text"
                  inputMode="text"
                  enterKeyHint="next"
                  label=""
                  placeholder="Enter amount"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Typography>has been paid as advance (if applicable)</Typography>
          </div>
          <div className={css.fieldWrapper}>
            <Checkbox
              checked={remarkCheckboxTabs.proof_of_export}
              value={`${remarkCheckboxTabs.proof_of_export}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  proof_of_export: !remarkCheckboxTabs.proof_of_export
                })
              }
            />
            <Typography>Proof of export to be shared with</Typography>
            <Controller
              name={`supplier_name`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  disabled={!remarkCheckboxTabs?.proof_of_export}
                  type="text"
                  inputMode="text"
                  enterKeyHint="next"
                  label=""
                  placeholder="Enter supplier name"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Typography>(if applicable)</Typography>
          </div>
          <CheckboxLabel
            label="Lifting will be subject to inspection and approval by Elchemy"
            checked={remarkCheckboxTabs.lifting_will_be_subject}
            value={`${remarkCheckboxTabs.lifting_will_be_subject}`}
            onChange={() =>
              setRemarkCheckboxTabs({
                ...remarkCheckboxTabs,
                lifting_will_be_subject: !remarkCheckboxTabs.lifting_will_be_subject
              })
            }
          />
          <CheckboxLabel
            label="Product should be from a recently manufactured batch"
            checked={remarkCheckboxTabs.product_should_be_from}
            value={`${remarkCheckboxTabs.product_should_be_from}`}
            onChange={() =>
              setRemarkCheckboxTabs({
                ...remarkCheckboxTabs,
                product_should_be_from: !remarkCheckboxTabs.product_should_be_from
              })
            }
          />
          <div className={css.fieldWrapper}>
            <Checkbox
              checked={remarkCheckboxTabs.palletization_detail}
              value={`${remarkCheckboxTabs.palletization_detail}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  palletization_detail: !remarkCheckboxTabs.palletization_detail
                })
              }
            />
            <Controller
              name={`palletization_details`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  disabled={!remarkCheckboxTabs?.palletization_detail}
                  type="text"
                  inputMode="text"
                  enterKeyHint="next"
                  label=""
                  placeholder="Enter palletization details"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Typography>should be new and in clean condition (if applicable)</Typography>
          </div>
          <CheckboxLabel
            label="Fumigation Certificate required for pallets (if applicable)"
            checked={remarkCheckboxTabs.fumigation_certificate}
            value={`${remarkCheckboxTabs.fumigation_certificate}`}
            onChange={() =>
              setRemarkCheckboxTabs({
                ...remarkCheckboxTabs,
                fumigation_certificate: !remarkCheckboxTabs.fumigation_certificate
              })
            }
          />
          <div className={css.blWrapper}>
            <CheckboxLabel
              label="Standard switch BL remarks (provided one of Elchemy or consignee wants to switch)"
              checked={remarkCheckboxTabs.standard_switch_bl_mark}
              value={`${remarkCheckboxTabs.standard_switch_bl_mark}`}
              onChange={() =>
                setRemarkCheckboxTabs({
                  ...remarkCheckboxTabs,
                  standard_switch_bl_mark: !remarkCheckboxTabs.standard_switch_bl_mark
                })
              }
            />
            <div className={css.blRadioWrapper}>
              <div className={css.fieldWrapper}>
                <div className={css.fieldWrapper}>
                  <CheckboxLabel
                    label="BL should be switchable in Shipping lines"
                    checked={remarkCheckboxTabs.bl_switchable}
                    value={`${remarkCheckboxTabs.bl_switchable}`}
                    onChange={() =>
                      setRemarkCheckboxTabs({
                        ...remarkCheckboxTabs,
                        bl_switchable: !remarkCheckboxTabs.bl_switchable
                      })
                    }
                  />
                  <Controller
                    name={`shipping_lines`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectLabel
                        {...field}
                        required
                        isDisabled={!remarkCheckboxTabs.standard_switch_bl_mark}
                        label=""
                        placeholder="Country"
                        options={countries}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  <Typography>office</Typography>
                </div>
              </div>
              <CheckboxLabel
                label="Master BL/Liners BL required"
                checked={remarkCheckboxTabs.master_bl}
                value={`${remarkCheckboxTabs.master_bl}`}
                onChange={() =>
                  setRemarkCheckboxTabs({
                    ...remarkCheckboxTabs,
                    master_bl: !remarkCheckboxTabs.master_bl
                  })
                }
              />
            </div>
          </div>
          <CheckboxLabel
            label="Shipment to be processed after Pre-shipment verification by Elchemy’s third-party partner (if applicable)"
            checked={remarkCheckboxTabs.shipment_to_be_processed}
            value={`${remarkCheckboxTabs.shipment_to_be_processed}`}
            onChange={() =>
              setRemarkCheckboxTabs({
                ...remarkCheckboxTabs,
                shipment_to_be_processed: !remarkCheckboxTabs.shipment_to_be_processed
              })
            }
          />
          <CheckboxLabel
            label="Shipping marks should be in the name of Elchemy"
            checked={remarkCheckboxTabs.shipping_mark_in_the_name_of_elchemy}
            value={`${remarkCheckboxTabs.shipping_mark_in_the_name_of_elchemy}`}
            onChange={() =>
              setRemarkCheckboxTabs({
                ...remarkCheckboxTabs,
                shipping_mark_in_the_name_of_elchemy:
                  !remarkCheckboxTabs.shipping_mark_in_the_name_of_elchemy
              })
            }
          />
          <CheckboxLabel
            label="Product expiry date to be atleast 1 year from the date of purchase of goods"
            checked={remarkCheckboxTabs.expiry_remarks}
            value={`${remarkCheckboxTabs.expiry_remarks}`}
            onChange={() =>
              setRemarkCheckboxTabs({
                ...remarkCheckboxTabs,
                expiry_remarks: !remarkCheckboxTabs.expiry_remarks
              })
            }
          />
          <CheckboxLabel
            label="Other Remarks"
            checked={remarkCheckboxTabs.other_remarks}
            value={`${remarkCheckboxTabs.other_remarks}`}
            onChange={() =>
              setRemarkCheckboxTabs({
                ...remarkCheckboxTabs,
                other_remarks: !remarkCheckboxTabs.other_remarks
              })
            }
          />
          {remarkCheckboxTabs.pss_confirmation ? (
            <div className={css.fieldWrapper}>
              <Checkbox
                checked={remarkCheckboxTabs.pss_confirmation}
                value={`${remarkCheckboxTabs.pss_confirmation}`}
                disabled={true}
              />
              <Typography>Consignment dispatch against PSS confirmation.</Typography>
            </div>
          ) : (
            <></>
          )}

          <div className={css.remarkBoxContainer}>
            <div className={css.fieldWrapper}>
              <Controller
                name="text_remarks"
                control={control}
                render={({ field, fieldState }) => (
                  <TextAreaLabel
                    {...field}
                    label="Enter Remark"
                    required
                    disabled={!remarkCheckboxTabs.other_remarks}
                    placeholder=""
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button onClick={onCancelClick} variant="text">
          Cancel
        </Button>
        <div className={css.actionButtonContainer}>
          <Button onClick={onBackClick} variant="outlined-secondary">
            Back
          </Button>
          <Button type="submit">Save and Proceed</Button>
        </div>
      </div>
    </form>
  );
};

export default RemarksForm;
