/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useReducer } from 'react';
import { appReducer } from './appReducer';

interface IAppContextProps {
  children: React.ReactNode;
}

export const AppContext = createContext<any>({});

const AppContextProvider: React.FC<IAppContextProps> = (props) => {
  const initialState = {
    PIData: {
      task_id: '',
      order_id: ''
    },

    RectifyData: {
      isRectify: false,
      task_id: '',
      order_id: ''
    },

    POData: {
      task_id: '',
      order_id: ''
    },
    tasksData: []
  };

  const [appState, appDispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ appState, appDispatch }}>{props.children}</AppContext.Provider>
  );
};

export default AppContextProvider;
