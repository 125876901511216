import { memo } from 'react';
import { Typography } from '@components/base';
import css from './index.module.scss';

interface RectifyRemarkContainerProps {
  rectifyRemarks?: string;
  module?: string;
}

const RectifyRemarkContainer = (props: RectifyRemarkContainerProps) => {
  const { rectifyRemarks, module } = props;
  return (
    <div className={css.rectifyRemarkWrapper}>
      <Typography variant="p" className={css.rectifyRemarkTitle}>
        Reasons to reject {module ? `this ${module}` : ``}
      </Typography>
      <Typography variant="p" className={css.rectifyRemarkContent}>
        {rectifyRemarks}
      </Typography>
    </div>
  );
};

export default memo(RectifyRemarkContainer);
