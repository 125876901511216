import { Button } from '@components/base';
import { BoxContainer } from '@components/common';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import BankDetails from './components/bankdetails';
import Invoice from './components/invoice';
import Item from './components/item';
import Order from './components/order';
import Parties from './components/parties';
import PurchaseDetails from './components/purchaseDetails';
import Remarks from './components/remarks';
import css from './index.module.scss';

interface IPreview {
  previewData: PerformaInvoiceStates;
  handleFormSubmit: () => void;
  handleCancelClick: () => void;
  handleBackClick: () => void;
  setOrderState: (orderState: PerformaInvoiceStates) => void;
  invoiceType: string;
  orderPi: any;
}

const PIReview = (props: IPreview) => {
  const {
    previewData,
    handleFormSubmit,
    handleCancelClick,
    handleBackClick,
    setOrderState,
    invoiceType,
    orderPi
  } = props;
  const { orderInfo } = previewData;

  return (
    <div className={css.mainWrapper}>
      <BoxContainer title={`${invoiceType} Invoice`}>
        <Invoice orderInfoData={orderInfo} setOrderState={setOrderState} />
        <Parties partiesData={previewData} setOrderState={setOrderState} />
        <Item itemData={previewData} setOrderState={setOrderState} />
        <Order previewData={previewData} setOrderState={setOrderState} invoiceType={invoiceType} />
        <BankDetails previewData={previewData} setOrderState={setOrderState} />
        <PurchaseDetails
          purchaseOrderData={previewData}
          setOrderState={setOrderState}
          po_reference_date={orderPi.po_reference_date}
        />
        <Remarks previewData={previewData} setOrderState={setOrderState} />
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button variant="outlined-secondary" onClick={handleBackClick}>
            Back
          </Button>
          <Button type="submit" onClick={handleFormSubmit}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PIReview;
