import { BoxContainer, SelectLabel, TextAreaLabel, TextField } from '@components/common';
import React from 'react';
import css from './index.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { sanitizeValue } from '@helpers/utils';
import { Button } from '@components/base';

const PartiesForm = (props: any) => {
  const { purchaser, supplier, onFormSubmit, onCancelClick } = props;
  const { control, handleSubmit } = useFormContext();

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.partiesFormWrapper}>
      <BoxContainer title="Purchaser">
        <div className={css.purchaserContainer}>
          <div className={css.nameFieldWrapper}>
            <Controller
              name="purchaserName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={sanitizeValue(purchaser?.purchaser_name)}
                  label="Name"
                  disabled
                  placeholder="Enter name"
                />
              )}
            />
            <Controller
              name="purchaserGstNumber"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={sanitizeValue(purchaser?.purchaser_gst_no)}
                  label="GST No."
                  disabled
                  placeholder="Enter GST No."
                />
              )}
            />
          </div>
          <Controller
            name="address"
            control={control}
            render={({ field, fieldState }) => (
              <TextAreaLabel
                {...field}
                value={sanitizeValue(purchaser?.address)}
                label="Address"
                disabled
                placeholder="Enter address"
              />
            )}
          />
        </div>
      </BoxContainer>
      <BoxContainer title="Supplier">
        <div className={css.supplierContainer}>
          <Controller
            name="supplierName"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                isDisabled
                value={{
                  label: supplier?.supplier_name ?? '',
                  value: supplier?.supplier_name ?? ''
                }}
                label="Name"
              />
            )}
          />
          <div className={css.nameFieldWrapper}>
            <Controller
              name="vendorId"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled
                  value={sanitizeValue(supplier?.vendor_id)}
                  label="Vendor ID"
                  placeholder="Enter vendor ID"
                />
              )}
            />
            <Controller
              name="supplierGstNumber"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="GST No."
                  value={sanitizeValue(supplier?.supplier_gst_no)}
                  disabled
                  placeholder="Enter GST No."
                />
              )}
            />
          </div>
          <Controller
            name="officeAddress"
            control={control}
            render={({ field, fieldState }) => (
              <TextAreaLabel
                {...field}
                disabled
                value={sanitizeValue(supplier?.office_address)}
                label="Office Address"
              />
            )}
          />
          <Controller
            name="factoryLocation"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                value={sanitizeValue(supplier?.factory_location)}
                disabled
                label="Factory Location"
                placeholder="Enter factory location"
              />
            )}
          />
        </div>
      </BoxContainer>
      <div className={css.formActionWrapper}>
        <Button onClick={onCancelClick} variant="text">
          Cancel
        </Button>
        <Button type="submit">Save and Proceed</Button>
      </div>
    </form>
  );
};

export default PartiesForm;
