import React, { useContext, useState, useEffect, useCallback } from 'react';
import css from './index.module.scss';
import { Loader, Typography } from '@components/base';
import { SideBar } from '@components/common';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AppContext } from '@helpers/hooks/AppContext';
import { getOrderItemDetails } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { yupResolver } from '@hookform/resolvers/yup';
import PackagingForm from '../components/form-skeleton';
import { CLIENT_ROUTES } from '@router/routes';
import Preview from '../components/preview';
import { rectifyPackagingDetails, verifyPackagingDetails } from '@services/task.service';
import { useNavigate } from 'react-router-dom';
import { generateQuantityFromPackingDetails } from '@helpers/utils';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';

const Rectify = () => {
  const [formState, setFormState] = useState<any>({
    activeStep: 0,
    isLoading: false,
    itemDetails: []
  });
  useBeforeUnloadAndNavigate();

  const { activeStep, isLoading, itemDetails } = formState;

  const navigate = useNavigate();

  const { appState } = useContext(AppContext);
  const { setPackageData } = appState;

  useEffect(() => {
    fetchItemDetails();
  }, []);

  const itemDetailForm = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // resolver: yupResolver(orderDetailsSchema),
    defaultValues: {
      items: [
        {
          name: null,
          order_item_id: null,
          order_unit_of_weight: null,
          package: [
            {
              package_type: null,
              quantity: null,
              unit: null,
              weight: null,
              id: null
            }
          ]
        }
      ]
    }
  });

  const fetchItemDetails = async () => {
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await getOrderItemDetails(setPackageData?.order_id, {
      supplier_id: setPackageData?.supplier_id
    });
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: false
    }));
    if (response?.success) {
      itemDetailForm.reset({
        items: response?.data?.results?.map((item: any) => ({
          name: item?.product_name,
          order_item_id: item?.order_item_id,
          order_unit_of_weight: item?.order_unit_of_weight,
          package: item?.package?.map((packageItem: any) => ({
            type: { value: packageItem?.type, label: packageItem?.type_label },
            no_of_package: packageItem?.no_of_package,
            unit: { label: packageItem?.unit_of_weight, value: packageItem?.unit_of_weight },
            weight: packageItem?.weight,
            id: packageItem?.id
          }))
        }))
      });
    } else {
      notify({
        severity: 'error',
        message: response?.message ?? 'Something went wrong'
      });
    }
  };

  const handleNavigation = (currentStep: number) => {
    setFormState((prevState: any) => ({
      ...prevState,
      activeStep: currentStep
    }));
  };

  const handleSidebarClick = (value: number) => {
    handleNavigation(value);
  };

  const handleSidebarEnterKey = (value: number) => {
    handleNavigation(value);
  };

  const handleBackClick = (currentStep: number) => () => {
    handleNavigation(currentStep - 1);
  };

  const handleCancelClick = useCallback(() => {
    navigate(`/${CLIENT_ROUTES.order}/${setPackageData?.order_id}`);
  }, []);

  const itemDetailFormSubmit: SubmitHandler<any> = (data: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      itemDetails: data
    }));
    handleNavigation(1);
  };

  const packagingFormSubmitHandler = async () => {
    const body = {
      is_verified: false,
      order_items: itemDetails?.items?.map((item: any) => {
        return {
          order_item_id: item.order_item_id,
          quantity: generateQuantityFromPackingDetails(
            item.package.map((packageItem: any) => ({
              type: packageItem.type.value,
              no_of_package: packageItem.no_of_package,
              unit: packageItem.unit.value,
              weight: packageItem.weight
            })),
            item?.order_unit_of_weight
          ),
          package: item.package.map((packageItem: any) => ({
            type: packageItem.type.value,
            no_of_package: packageItem.no_of_package,
            unit_of_weight: packageItem.unit.value,
            weight: packageItem.weight,
            id: packageItem.id
          }))
        };
      })
    };
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));
    let response;
    if (setPackageData?.rectifyRemarks?.length) {
      response = await rectifyPackagingDetails(setPackageData?.task_id, body);
    } else {
      response = await verifyPackagingDetails(setPackageData?.task_id, body);
    }
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: false
    }));
    if (response?.success) {
      notify({
        severity: 'success',
        message: `Packaging details ${
          setPackageData?.rectifyRemark?.length ? 'Rectified' : 'verified'
        } successfully`
      });
      navigate(`/${CLIENT_ROUTES.order}/${setPackageData?.order_id}`, { replace: true });
    } else {
      notify({
        severity: 'error',
        message: response?.error ?? 'Something went wrong'
      });
    }
  };

  return (
    <main className={css.formWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Rectify Packaging Details</Typography>
        <Typography variant="subheading1">0{activeStep + 1} of 02</Typography>
      </div>
      <div className={css.formContainer}>
        <div className={css.sideBarWrapper}>
          <SideBar
            activeStep={activeStep}
            onClick={handleSidebarClick}
            onEnter={handleSidebarEnterKey}>
            <SideBar.Item label="Item Details" value={0} />
            <SideBar.Item label="Preview" value={1} disabled={activeStep! <= 1} />
            {/* <SideBar.Item label="Additional Details" value={2} disabled={activeStep! <= 2} />
            <SideBar.Item label="Preview" value={3} disabled={activeStep! <= 3} /> */}
          </SideBar>
        </div>
        {activeStep === 0 && (
          <FormProvider {...itemDetailForm}>
            <PackagingForm
              onFormSubmit={itemDetailFormSubmit}
              onCancelClick={handleCancelClick}
              rectifyRemarks={setPackageData?.rectifyRemarks ?? null}
            />
          </FormProvider>
        )}
        {activeStep === 1 && (
          <Preview
            data={formState}
            handleBackClick={handleNavigation}
            onSubmit={packagingFormSubmitHandler}
            handleCancelClick={handleCancelClick}
            handleNavigation={handleNavigation}
          />
        )}
      </div>
      <Loader open={isLoading} />
    </main>
  );
};

export default Rectify;
