import Images from '@assets/images';
import { IconNode, Typography } from '@components/base';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import { TRANSPORTATION_MODE, INCO_TERMS, PAYMENT_TERMS, DELIVERY_TERMS } from '@helpers/constants';
import css from '../index.module.scss';
import { getFormattedDate } from '@helpers/utils';

interface IOrderDetails {
  previewData: PerformaInvoiceStates;
  setOrderState: (orderState: PerformaInvoiceStates) => void;
  invoiceType: string;
}

const Order = (props: IOrderDetails) => {
  const { previewData, setOrderState, invoiceType } = props;
  const { orderDetails } = previewData;
  const { editRed } = Images;

  const isBlOrAwb = orderDetails?.transportation_mode === 'AIR' ? 'AWB' : 'BL';

  return (
    <div className={css.boxWrapper}>
      <div className={css.orderDetailsContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Order Details</Typography>
          <IconNode
            className={css.editRed}
            src={editRed}
            onClick={() =>
              setOrderState({
                ...previewData,
                activeStep: 2
              })
            }
          />
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Mode of Transportation</Typography>
            <Typography variant="body">
              {TRANSPORTATION_MODE.find(
                (item: any) => orderDetails?.transportation_mode == item.value
              )?.label ?? '-'}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Inco Terms</Typography>
            <Typography variant="body">
              {INCO_TERMS.find((item: any) => orderDetails?.inco_terms == item.value)?.label ?? '-'}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Quality Testing</Typography>
            <Typography variant="body">{orderDetails?.place_of_quality_test ?? '-'}</Typography>
          </div>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Country of Origin</Typography>
            <Typography variant="body">{orderDetails?.country_of_origin}</Typography>
          </div>
          <div>
            <Typography variant="label">Country of Final Destination</Typography>
            <Typography variant="body">{orderDetails?.country_of_final_destination}</Typography>
          </div>
          <div>
            <Typography variant="label">Delivery Terms</Typography>
            <Typography variant="body">
              {DELIVERY_TERMS.find((item: any) => orderDetails?.inco_terms == item.value)?.label ??
                '-'}
            </Typography>
          </div>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Port of Loading</Typography>
            <Typography variant="body">
              {orderDetails?.port_of_loading_display_value &&
              orderDetails?.port_of_loading_country_display_value
                ? `${orderDetails?.port_of_loading_display_value} - ${orderDetails?.port_of_loading_country_display_value}`
                : '-'}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Port of Discharge</Typography>
            <Typography variant="body">
              {orderDetails?.port_of_discharge_display_value &&
              orderDetails?.port_of_discharge_country_display_value
                ? `${orderDetails?.port_of_discharge_display_value} - ${orderDetails?.port_of_discharge_country_display_value}`
                : '-'}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Place of Delivery</Typography>
            <Typography variant="body">{orderDetails?.place_of_delivery}</Typography>
          </div>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Payment Terms</Typography>
            <Typography variant="body">{orderDetails?.payment_terms ?? '-'}</Typography>
          </div>
          <div>
            <Typography variant="label">Show advance amount in document</Typography>
            <Typography variant="body">
              {previewData?.itemDetailsInfo?.include_advance_payment ? 'Yes' : 'No'}
            </Typography>
          </div>
        </div>
        {invoiceType === 'Commercial' && (
          <div className={css.fieldWrapper}>
            <div>
              <Typography variant="label">{isBlOrAwb} Number</Typography>
              <Typography variant="body">
                {orderDetails?.transportation_mode === 'AIR'
                  ? orderDetails?.awb_number
                  : orderDetails?.bl_number}
              </Typography>
            </div>
            <div>
              <Typography variant="label">{isBlOrAwb} Date</Typography>
              <Typography variant="body">
                {orderDetails?.transportation_mode === 'AIR'
                  ? getFormattedDate(orderDetails?.awb_date)
                  : getFormattedDate(orderDetails?.bl_date)}
              </Typography>
            </div>
            {orderDetails?.transportation_mode === 'AIR' && (
              <div>
                <Typography variant="label">AWB Service Number</Typography>
                <Typography variant="body">{orderDetails?.awb_service_name}</Typography>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Order;
