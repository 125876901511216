import { Modal, Typography, IconNode, Button } from '@components/base';
import Images from '@assets/images';
import css from './index.module.scss';
import TextareaLabel from '@components/common/textarea-label';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { rejectOrderSchema } from '@helpers/yup/order-tasks.schema';
import { yupResolver } from '@hookform/resolvers/yup';

interface RejectModalProps {
  submitRejectForm: (data: any) => void;
  onClose: () => void;
  open: boolean;
}

const RejectModal = (props: RejectModalProps) => {
  const { handleSubmit, control } = useForm<any>({
    resolver: yupResolver(rejectOrderSchema),
    defaultValues: {
      order_rectify_remarks: ''
    }
  });

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Write your reasons to reject this Order</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="div"
            className={css.closeButton}
            onClick={props.onClose}
          />
        </div>
        <form
          noValidate
          className={css.formWrapper}
          onSubmit={handleSubmit(props.submitRejectForm)}>
          <div className={css.fieldsWrapper}>
            <Controller
              name={`order_rectify_remarks`}
              control={control}
              render={({ field, fieldState }) => (
                <TextareaLabel
                  {...field}
                  label="Description"
                  helperText={fieldState.error?.message}
                  placeholder="Enter a description..."
                  rootClassName={css.fieldSpacing}
                  error={fieldState.invalid}
                />
              )}
            />
          </div>
          <Button variant="outlined-secondary" type="submit" className={css.rightButton}>
            Reject
          </Button>
        </form>
      </div>
    </Modal>
  );
};
export default RejectModal;
