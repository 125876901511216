import AccessWrapper from '@authorization/access-wrapper';
import CustomerEnquiry from './customer';
import { USER_TYPES } from '@helpers/constants';
import InternalEnquiry from './internal';

const EnquiryHome = (props: any) => {
  const { actions } = props;
  return (
    <>
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.customer]} byPassShowWithShowTo>
        <CustomerEnquiry {...props} />
      </AccessWrapper>
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]} byPassShowWithShowTo>
        <InternalEnquiry {...props} />
      </AccessWrapper>
    </>
  );
};

export default EnquiryHome;
