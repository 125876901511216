import * as yup from 'yup';

const addProductSchema = yup.object().shape({
  product_id: yup.string().trim().optional(),
  name: yup.mixed().required('Product name is required'),
  CAS_number: yup
    .string()
    .trim()
    .max(32, 'CAS number can be at most 32 characters long')
    .matches(/\b[1-9]{1}[0-9]{1,5}-\d{2}-\d\b/gm, 'Enter valid CAS number')
    .required('CAS number is required'),
  description: yup.string().trim().optional(),
  packaging_details: yup.string().trim().optional(),
  remarks: yup.string().trim().optional(),
  haz: yup.bool().optional(),
  msds_doc: yup.mixed().nullable().optional(),
  hs_code: yup.string().optional()
});

const gradeSchema = yup.object().shape({
  grade_id: yup.string().trim().optional(),
  name: yup.string().trim().required('Grade name is required'),
  description: yup.string().trim().optional(),
  packaging_details: yup.string().trim().optional(),
  remarks: yup.string().trim().optional()
});

const addGradesSchema = yup.object().shape({
  grades: yup.array().of(gradeSchema)
});

const productGradeSchema = yup.object().shape({
  productName: yup.mixed().required('Product name is required'),
  productGrade: yup.mixed().required('Product grade is required')
});

export { addProductSchema, addGradesSchema, productGradeSchema };
