import css from './index.module.scss';
import Image from '@assets/images';
import NavItems from './nav-items';
import { NavLinkProps } from 'react-router-dom';
import React, { useMemo, useEffect, useState } from 'react';
import SessionService from '@helpers/session-helper';
import { Typography, Button, IconNode, Menu, MenuItem } from '@components/base';
import { getToken, parseJWT } from '@helpers/auth-helper';
import { EVENT_TRACKING_TYPES, USER_TYPES } from '@helpers/constants';
import { getPermissionFromLocalStorage, pushEventTracking } from '@helpers/utils';
import { logoutUser } from '@services/login.service';
import notify from '@helpers/toastify-helper';
import RenderChildrenBasedOnScreen from 'src/hoc/screen-conditions';

interface NavigationBarProps {
  items: NavLinkProps[];
  navItemsForCustomer: NavLinkProps[];
}

interface NavigationBarUserState {
  family_name: string;
  given_name: string;
  email: string;
  phone_number: string;
  user_id: string;
}

function NavigationBar(props: NavigationBarProps) {
  const userType = getPermissionFromLocalStorage()?.user_type;
  const navItems = useMemo(() => props.items, [props.items]);
  const navItemsForCustomer = useMemo(() => props.navItemsForCustomer, [props.navItemsForCustomer]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navItemsToBeDisplayed = useMemo(() => {
    if (userType == USER_TYPES.customer) {
      return navItemsForCustomer;
    }
    if (userType == USER_TYPES.internal) {
      return navItems;
    }
    return [];
  }, [navItems, navItemsForCustomer]);
  const [user, setUser] = useState<NavigationBarUserState>();

  useEffect(() => {
    const accessToken = getToken('access');
    const jwtData = parseJWT(accessToken);
    setUser(jwtData);
  }, []);

  const logoutUserHandler = async () => {
    const token = getToken('refresh');
    const response = await logoutUser(token);
    if (response?.success) {
      SessionService.clearSession(true);
      pushEventTracking(EVENT_TRACKING_TYPES.LOGOUT);
    } else {
      notify({ message: response.error ?? 'Something went wrong.', severity: 'error' });
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <nav className={css.navWrapper}>
      <IconNode
        src={Image.elchemyLogo}
        alt="elchemy-logo"
        className={css.brandLogo}
        loading="eager"
      />
      <RenderChildrenBasedOnScreen showOnWeb>
        <ul className={css.navItemWrapper}>
          <NavItems items={navItemsToBeDisplayed} />
        </ul>
      </RenderChildrenBasedOnScreen>

      <div className={css.navActionWrapper}>
        <RenderChildrenBasedOnScreen showOnWeb>
          <Typography variant="p">
            Welcome, {user?.given_name ? `${user.given_name} ${user.family_name}` : `User`}
          </Typography>
        </RenderChildrenBasedOnScreen>

        <RenderChildrenBasedOnScreen showOnWeb>
          <Button onClick={logoutUserHandler}>Logout</Button>
        </RenderChildrenBasedOnScreen>

        <RenderChildrenBasedOnScreen showOnMobile>
          <Button
            variant="text"
            onClick={handleMenuClick}
            startIcon={<IconNode src={Image.menu} alt="menu" loading="eager" />}></Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}>
            {navItemsToBeDisplayed.map((item, index) => (
              <MenuItem key={index} onClick={handleClose}>
                <NavItems items={[item]} />
              </MenuItem>
            ))}
            <MenuItem onClick={handleClose}>
              <Button onClick={logoutUserHandler}>Logout</Button>
            </MenuItem>
          </Menu>
        </RenderChildrenBasedOnScreen>
      </div>
    </nav>
  );
}

export default NavigationBar;
export type { NavigationBarProps };
