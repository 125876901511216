import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { DOCUMENT_GENERATION_STATUS } from '@helpers/constants';
import { AppContext } from '@helpers/hooks/AppContext';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { getFormattedDate } from '@helpers/utils';
import moment from 'moment';
import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../components/footer';
import css from './index.module.scss';
import { Link } from 'react-router-dom';

interface GenerateCommercialInvoiceCardProps {
  taskData: Partial<ITaskTabProps>;
  onComplete?: () => void;
}

const GenerateCommercialInvoiceCard = (props: GenerateCommercialInvoiceCardProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const { appDispatch } = useContext(AppContext);
  const { taskData } = props;
  const taskItem = taskData;

  /**
   * Used for for doc generation status and render UI accordingly
   */
  const docGenerationStatus = useMemo(() => {
    if (taskItem?.commercial_invoice_generation_status as any) {
      return DOCUMENT_GENERATION_STATUS.find(
        (i) => i.value === taskItem?.commercial_invoice_generation_status
      )?.label;
    }
    return null;
  }, [taskItem]);

  const shouldEnableGeneration = useMemo(() => {
    if (taskItem?.transportation_mode === 'AIR') {
      return true;
    } else if (taskItem?.transportation_mode === 'SEA' && taskItem?.is_container_details_added) {
      return true;
    }
    return false;
  }, [taskItem?.transportation_mode, taskItem?.is_container_details_added]);

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskItem?.is_completed ? (
                <Avatar src={Images.illustrationEdit} alt="" />
              ) : (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              )}

              <Link to={`/order/${taskItem?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  #{taskItem?.readable_task_id} - Generate Commercial Invoice
                </Typography>
              </Link>
            </div>
            <div className={css.pocWrapper}>
              {/**
               * !taskItem?.commercial_invoice_pdf this condition is only added for generate commercial invoice card
               * other card would not have this condition, and would have docGenerationStatus
               */}
              {!taskItem?.is_completed &&
                !taskItem?.commercial_invoice_pdf &&
                !(
                  docGenerationStatus == 'DOC_GENERATION_PROCESSING' ||
                  docGenerationStatus == 'DOC_GENERATION_REQUESTED'
                ) && (
                  <Button
                    variant="text"
                    disabled={!shouldEnableGeneration}
                    onClick={() => {
                      appDispatch({
                        type: 'setPIData',
                        payload: {
                          task_id: taskItem?.task_id,
                          order_id: taskItem?.order,
                          invoiceType: 'Commercial'
                        }
                      });
                      navigate(
                        `/order/generate-commercial-invoice/${
                          (params.id as string) ?? (taskItem?.order as string)
                        }`
                      );
                    }}
                    className={css.assignButton}>
                    {shouldEnableGeneration ? `Generate` : `Add Container Details`}
                  </Button>
                )}
              {!taskItem?.is_completed &&
                (docGenerationStatus == 'DOC_GENERATION_PROCESSING' ||
                  docGenerationStatus == 'DOC_GENERATION_REQUESTED') && (
                  <Button variant="text" disabled className={css.assignButton}>
                    Document generation in process
                  </Button>
                )}
              {!taskItem?.is_completed && docGenerationStatus == 'DOC_GENERATION_FAILED' && (
                <Button variant="text" disabled className={css.assignButton}>
                  Document Generation Failed
                </Button>
              )}
            </div>
          </div>
        </Accordion.Header>
      </Accordion>
      <Footer
        createdDate={props.taskData.created_at as Date}
        updatedDate={props.taskData.updated_at as Date}
      />
    </div>
  );
};

export default GenerateCommercialInvoiceCard;
