import { Fragment, useCallback, useEffect, useState } from 'react';
import css from './index.module.scss';
import { Typography, Button, IconNode, Loader } from '@components/base';
import { GradeDetailForm, ProductDetailForm, SideBar } from '@components/common';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Images from '@assets/images';
import { IProductForm, ProductInfo } from '@helpers/types/product';
import ProductView from './components/product-info';
import GradesInfo from './components/grades-info';
import { IGradeForm } from '@helpers/types/grade';
import { yupResolver } from '@hookform/resolvers/yup';
import { addGradesSchema, addProductSchema } from '@helpers/yup';
import { updateProductInfo } from '@services/product.service';
import notify from '@helpers/toastify-helper';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import AccessWrapper from '@authorization/access-wrapper';

interface ProductDetailTabProps {
  productInfo: ProductInfo;
  onChange?: (param: ProductInfo) => void;
  enableEdit?: boolean;
  actions: IActions;
}

interface ProductDetailTabStates {
  activeStep: number;
  editable: boolean;
}

const DetailsTab = (props: ProductDetailTabProps) => {
  const { productInfo, actions } = props;
  const [productState, setProductState] = useState<ProductDetailTabStates>({
    activeStep: 0,
    editable: false
  });
  const { activeStep, editable } = productState;

  useEffect(() => {
    const { enableEdit } = props;
    setProductState((prevState) => ({ ...prevState, editable: !!enableEdit }));
  }, [props.enableEdit]);

  const getProductFormValuesFromResponse = (productInfo: ProductInfo) => {
    const data = {
      product_id: productInfo.product_id,
      name: productInfo.name,
      CAS_number: productInfo.CAS_number,
      packaging_details: productInfo.packaging_details,
      description: productInfo.description,
      remarks: productInfo.remarks,
      haz: productInfo.haz,
      msds_doc: productInfo.msds_doc,
      hs_code: productInfo.hs_code
    };
    return data;
  };

  const getProductDetailDefaultValues = () => {
    const { productInfo } = props;
    return Promise.resolve(getProductFormValuesFromResponse(productInfo));
  };

  const getProductGradeDefaultValues = (param: IGradeForm) => {
    const { grades } = param;
    const updatedGrades = grades.map((grade) => ({
      grade_id: grade.grade_id,
      name: grade.name,
      description: grade.description,
      packaging_details: grade.packaging_details,
      remarks: grade.remarks
    }));
    return { grades: updatedGrades };
  };

  const getGradeDetailDefaultValues = () => {
    const { productInfo } = props;
    return Promise.resolve(getProductGradeDefaultValues({ grades: productInfo.grades }));
  };

  const productDetailForm = useForm<IProductForm>({
    resolver: yupResolver(addProductSchema),
    defaultValues: getProductDetailDefaultValues
  });

  const gradeDetailForm = useForm<IGradeForm>({
    resolver: yupResolver(addGradesSchema),
    defaultValues: getGradeDetailDefaultValues
  });

  const {
    reset: firstReset,
    formState: { isSubmitting: firstIsSubmitting, isDirty: firstIsDirty }
  } = productDetailForm;

  const {
    reset: secondReset,
    formState: { isSubmitting: secondIsSubmitting, isDirty: secondIsDirty }
  } = gradeDetailForm;

  const handleEditClick = async () => {
    const { editable } = productState;
    if (editable) {
      const firstFormDefaultValues = await getProductDetailDefaultValues();
      const secondFormDefaultValues = await getGradeDetailDefaultValues();
      firstIsDirty &&
        firstReset(firstFormDefaultValues, {
          keepErrors: false,
          keepDirty: false,
          keepDirtyValues: false,
          keepValues: false,
          keepDefaultValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false
        });
      secondIsDirty &&
        secondReset(secondFormDefaultValues, {
          keepErrors: false,
          keepDirty: false,
          keepDirtyValues: false,
          keepValues: false,
          keepDefaultValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false
        });
    }
    setProductState((prevState) => ({ ...prevState, editable: !prevState.editable }));
  };

  const handleStepClick = useCallback((step: number) => {
    setProductState((prevState) => ({ ...prevState, activeStep: step }));
  }, []);

  const handleProductDetailFormSubmit: SubmitHandler<IProductForm> = async (param) => {
    const { onChange } = props;
    const { product_id, ...reqBody } = param;
    delete reqBody.msds_doc;
    const response = await updateProductInfo(product_id, reqBody);
    if (response.success) {
      const { data } = response;
      const defaultValues = getProductFormValuesFromResponse(data);
      firstReset(defaultValues);
      onChange?.(data);
      setProductState((prevState) => ({ ...prevState, editable: false }));
      notify({ message: 'Product details updated successfully', dismissible: true });
    } else {
      notify({
        message: response.error ?? 'Unable to  update product details',
        dismissible: true,
        severity: 'error'
      });
    }
  };

  const handleGradeDetailFormSubmit: SubmitHandler<IGradeForm> = async (param) => {
    const { productInfo, onChange } = props;
    const reqBody = param.grades.map((grade) => {
      const { grade_id, ...rest } = grade;
      return {
        ...(grade_id && { grade_id }),
        ...rest
      };
    });
    const response = await updateProductInfo(productInfo.product_id, { grades: reqBody });
    if (response.success) {
      const { data } = response;
      const defaultValues = getProductGradeDefaultValues({ grades: data.grades });
      secondReset(defaultValues);
      onChange?.(data);
      setProductState((prevState) => ({ ...prevState, editable: false }));
      notify({ message: 'Grade details updated successfully', dismissible: true });
    } else {
      notify({ message: 'Unable to update grade details', dismissible: true, severity: 'error' });
    }
  };

  const handleBackClick = () => {
    handleStepClick(0);
  };

  return (
    <div className={css.productDetailTabWrapper}>
      <div className={css.productDetailWrapper}>
        <Typography variant="h4">Product Details</Typography>
        <AccessWrapper show={actions?.update}>
          <Button
            variant="text"
            onClick={handleEditClick}
            startIcon={<IconNode src={Images.editRed} alt="edit icon"></IconNode>}>
            Edit
          </Button>
        </AccessWrapper>
      </div>
      <div className={css.productDetailContainer}>
        <div className={css.sideBar}>
          <SideBar activeStep={activeStep} onClick={handleStepClick} onEnter={handleStepClick}>
            <SideBar.Item value={0} label="Details" />
            <SideBar.Item value={1} label="Grades" />
          </SideBar>
        </div>
        {!editable && (
          <Fragment>
            {activeStep === 0 && (
              <ProductView
                productName={productInfo.name}
                casNumber={productInfo.CAS_number}
                packagingDetails={productInfo.packaging_details}
                description={productInfo.description}
                remarks={productInfo.remarks}
                hsCode={productInfo.hs_code}
                productReadableId={productInfo.product_readable_id}
              />
            )}
            {activeStep === 1 && <GradesInfo grades={productInfo.grades} />}
          </Fragment>
        )}
        {editable && (
          <Fragment>
            <FormProvider {...productDetailForm}>
              {activeStep === 0 && (
                <ProductDetailForm
                  editMode={productState.editable}
                  onFormSubmit={handleProductDetailFormSubmit}
                  onCancelClick={handleEditClick}
                />
              )}
            </FormProvider>
            <FormProvider {...gradeDetailForm}>
              {activeStep === 1 && (
                <GradeDetailForm
                  editMode={productState.editable}
                  onBackClick={handleBackClick}
                  onFormSubmit={handleGradeDetailFormSubmit}
                  onCancelClick={handleEditClick}
                />
              )}
            </FormProvider>
          </Fragment>
        )}
      </div>
      <Loader open={firstIsSubmitting || secondIsSubmitting} />
    </div>
  );
};
export default DetailsTab;
