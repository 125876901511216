import NoSupplierPlaceHolder from './no-supplier-placeholder';
import { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import css from './index.module.scss';
import { Typography } from '@components/base';
import {
  Seo,
  SearchBar,
  InfiniteScroll,
  SearchPlaceholder,
  CardListing
} from '@components/common';
import SupplierCard from '@components/common/supplier-card';
import { getSupplierList, ListParam } from '@services/supplier.service';
import { ISupplier } from '@helpers/types/supplier';
import debounce from 'lodash.debounce';
import { ProductInfo } from '@helpers/types/product';
import notify from '@helpers/toastify-helper';

interface SupplierHomeState {
  supplierList: ISupplier[];
  loading: boolean;
  hasNext: boolean;
  currentPageNumber: number;
  retry: boolean;
  searchText: string;
  searchLoading: boolean;
}

interface PropsType {
  productInfo: ProductInfo;
}

const SupplierTab = (props: PropsType) => {
  const debounceSearch = useRef<any>();
  const rootContainer = useRef<HTMLElement>(null);
  const [supplierState, setSupplierState] = useState<SupplierHomeState>({
    supplierList: [],
    loading: false,
    hasNext: false,
    currentPageNumber: 1,
    retry: false,
    searchText: '',
    searchLoading: false
  });

  const { loading, supplierList, hasNext, currentPageNumber, retry, searchText, searchLoading } =
    supplierState;

  useEffect(() => {
    (async () => {
      await fetchSupplierList({ page: 1 });
    })();
  }, []);

  const fetchSupplierList = useCallback(async (param: ListParam) => {
    const { searchText } = supplierState;
    const data = {
      ...param,
      kwargs: param?.kwargs ?? searchText,
      product_list: props?.productInfo?.name ?? props?.productInfo?.name
    };
    setSupplierState((prevState) => ({ ...prevState, loading: true }));
    const response = await getSupplierList(data);
    if (response.success) {
      const { data } = response;
      setSupplierState((prevState) => ({
        ...prevState,
        supplierList: [...prevState.supplierList, ...data.results],
        hasNext: !!data.next,
        currentPageNumber: param.page ?? 1,
        retry: false,
        searchLoading: false,
        loading: false
      }));
    } else {
      setSupplierState((prevState) => ({
        ...prevState,
        retry: true,
        searchLoading: false,
        loading: false
      }));
      notify({ message: response.error ?? 'Unable to fetch supplier list', severity: 'error' });
    }
  }, []);

  const fetchSearchResult = useCallback(async (searchText: string) => {
    setSupplierState((prevState) => ({
      ...prevState,
      supplierList: [],
      hasNext: false,
      currentPageNumber: 1,
      retry: false,
      searchLoading: true
    }));
    await fetchSupplierList({
      page: 1,
      kwargs: searchText,
      product_list: props?.productInfo?.name ?? props?.productInfo?.name
    });
  }, []);

  const handleSupplierSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setSupplierState((prevState) => ({ ...prevState, searchText: value, searchLoading: true }));
    debounceSearch.current?.cancel();
    debounceSearch.current = debounce(fetchSearchResult, 800);
    debounceSearch.current(value);
  };

  return (
    <main ref={rootContainer} className={css.supplierWrapper}>
      <Seo title="Suppliers" />
      <div className={css.supplierHeader}>
        <div className={css.supplierTextWrapper}>
          <Typography variant="h3">Related Supplier</Typography>
        </div>
        <SearchBar
          placeholder="Search for Supplier"
          className={css.supplierSearch}
          onChange={handleSupplierSearch}
          isLoading={searchLoading}
        />
      </div>
      <div className={css.supplierFilterWrapper}></div>
      <section className={css.supplierSection}>
        {loading ? (
          <CardListing />
        ) : supplierList.length ? (
          <InfiniteScroll
            rootRef={rootContainer.current!}
            currentIndex={currentPageNumber}
            hasMore={hasNext}
            hideEndText={supplierList.length <= 10}
            onIntersect={(index: number) => fetchSupplierList({ page: index })}
            retry={retry}>
            {supplierList.map((supplier) => (
              <SupplierCard
                key={supplier.supplier_id}
                supplierId={supplier.supplier_id}
                vendorId={supplier.vendor_id}
                supplierName={supplier.supplier_name}
                emailId={supplier.supplier_email}
                contactNumber={supplier.supplier_phone_number}
                location={supplier.addresses[0]?.city ?? ''}
                numberOfOrders={supplier.total_order}
                type={{
                  label: supplier?.type_display_name,
                  value: supplier?.type
                }}
                origin={supplier?.origin_display_name}
                businessType={supplier?.business_type_display_name}
              />
            ))}
          </InfiniteScroll>
        ) : searchText ? (
          <SearchPlaceholder searchText={searchText} />
        ) : (
          <NoSupplierPlaceHolder />
        )}
      </section>
    </main>
  );
};
export default SupplierTab;
