import React, { useEffect, useState } from 'react';
import css from './index.module.scss';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, Typography } from '@components/base';
import {
  BoxContainer,
  PackingDataFields,
  RectifyRemarksContainer,
  TextField
} from '@components/common';
import { IPackageSchema } from '@helpers/types/packing';
import { generateQuantityFromPackingDetails } from '@helpers/utils';

interface PackagingFormProps {
  onFormSubmit: (data: any) => void;
  onCancelClick: () => void;
  rectifyRemarks?: string;
}

const PackagingForm = (props: PackagingFormProps) => {
  const { onFormSubmit, onCancelClick, rectifyRemarks = null } = props;
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext<any>();

  const { fields: itemFields } = useFieldArray({
    name: `items`,
    control
  });

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      {rectifyRemarks ? (
        <RectifyRemarksContainer rectifyRemarks={rectifyRemarks} module="Packaging" />
      ) : (
        <></>
      )}
      <BoxContainer title="Item and Packaging Details">
        <ItemWrapper itemFields={itemFields} control={control} />
      </BoxContainer>
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div>
          <Button type="submit" disabled={isSubmitting}>
            Save and Proceed
          </Button>
        </div>
      </div>
    </form>
  );
};

interface ItemWrapperProps {
  itemFields: any;
  control: any;
}

const ItemWrapper = (props: ItemWrapperProps) => {
  const { itemFields, control } = props;
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  return (
    <>
      {itemFields.map((item: any, index: number) => {
        return (
          <div className={css.itemWrapper} key={index}>
            <div className={css.rowWrapper}>
              <Controller
                name={`items.${index}.name`}
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Item Name"
                    placeholder="Item Name"
                    className={css.fieldWrapper}
                    {...field}
                    disabled
                  />
                )}
              />

              <TextField
                label="Total Quantity"
                placeholder="Total Quantity"
                className={css.fieldWrapper}
                value={totalQuantity}
                disabled
              />
            </div>
            <PackageWrapper index={index} item={item} setTotalQuantity={setTotalQuantity} />
          </div>
        );
      })}
    </>
  );
};

interface PackageWrapperProps {
  index: number;
  item: any;
}

const PackageWrapper = (props: any) => {
  const { index, item, setTotalQuantity } = props;
  const { control } = useFormContext<any>();

  const {
    fields: packageFields,
    append: packageAppend,
    remove: packageRemove
  } = useFieldArray({
    name: `items.${index}.package`,
    control
  });

  const handleAddPackageField = () => {
    packageAppend({
      type: null,
      no_of_package: null,
      weight: null,
      unit: null
    });
  };

  const packageWatch = useWatch({
    name: `items.${index}.package`,
    control
  });

  useEffect(() => {
    setTotalQuantity(generateQuantityFromPackingDetails(packageWatch, item.order_unit_of_weight))
  }, [packageWatch])

  return (
    <div className={css.pacakageSectionWrapper}>
      <Typography variant="p" className={css.titleWrapper}>
        Packaging details
      </Typography>
      <PackingDataFields
        fields={packageFields}
        handleAddField={handleAddPackageField}
        remove={packageRemove}
        itemIndex={index}
        unitOfWeightWatch={item.package?.[0]?.unit}
      />
    </div>
  );
};

export default PackagingForm;
