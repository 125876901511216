import httpClient from './index.service';
import { SERVER_CONFIG } from '@helpers/constants';
import { ReqProduct } from '@helpers/types/product';

export async function fetchAllProducts(
  pageNumber?: number,
  name?: string | null,
  kwargs?: string,
  ids: string[] = []
) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.listProducts}`, {
      params: {
        ...(pageNumber && { page: pageNumber }),
        ...(name && { name }),
        ...(kwargs && { kwargs }),
        ...(ids.length > 0 && { product_id_list: ids.join(',') })
      }
    });
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function createProduct(product: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.createProduct}`, product);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchProductInfo(productId: string) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.fetchProduct}${productId}/`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateProductInfo(productId: string, reqBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.updateProductInfo}${productId}/`,
      reqBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateProductGrade(gradeId: string, reqBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.updateProductGrade}${gradeId}/`,
      reqBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function deleteGradeDocument(gradeId: string) {
  try {
    const response = await httpClient.delete(
      `${SERVER_CONFIG.deleteGradeDocument}${gradeId}/delete/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getPOInfo(POId: string) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.getPO}${POId}/po`);
    return response;
  } catch (error: any) {
    return error;
  }
}
