import { Button } from '@components/base';
import { BoxContainer } from '@components/common';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import { PurchaseOrderStates } from '@helpers/types/po';
import BankDetails from './components/bankdetails';
import Invoice from './components/invoice';
import Item from './components/item';
import Order from './components/order';
import Parties from './components/parties';
import PurchaseDetails from './components/purchaseDetails';
import Remarks from './components/remarks';
import css from './index.module.scss';

interface IPreview {
  previewData: PurchaseOrderStates;
  handleFormSubmit: () => void;
  handleCancelClick: () => void;
  handleRejectClick: () => void;
}

const PIReview = (props: IPreview) => {
  const { previewData, handleFormSubmit, handleCancelClick, handleRejectClick } = props;
  const { orderInfo } = previewData;

  return (
    <div className={css.mainWrapper}>
      <BoxContainer title="Purchase Order" className={css.boxContainerReview}>
        <Invoice orderInfoData={previewData} />
        <Parties partiesData={previewData} />
        <Item itemData={previewData} />
        {/* <BankDetails previewData={previewData} /> */}
        {/* <PurchaseDetails purchaseOrderData={previewData} /> */}
        <Remarks previewData={previewData} />
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button variant="outlined-secondary" onClick={handleRejectClick}>
            Reject
          </Button>
          <Button type="submit" onClick={handleFormSubmit}>
            Approve
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PIReview;
