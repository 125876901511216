import * as yup from 'yup';

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

const booleanSelectSchema = yup.object().shape({
  value: yup.boolean().required(),
  label: yup.string().required()
});

export const addRodTepConfirmationValidation = yup.object().shape({
  claim_confirmation: booleanSelectSchema
    .required('RODTEP Claimed is required')
    .test('is-true', 'RODTEP Claimed must be true', (value) => value.value != false),
  currency: selectSchema.required('Currency is required'),
  rodtep_percentage_claimed: yup
    .number()
    .typeError('RODTEP Percentage Claimed should be in number')
    .positive()
    .min(0, 'RODTEP Percentage Claimed should be greater than 0')
    .max(100, 'RODTEP Percentage Claimed should be less than 100')
    .required('RODTEP Percentage Claimed is required'),
  credit_date: yup.date().required('RODTEP Credit Date is required'),
  amount: yup
    .number()
    .typeError('RODTEP Amount should be in number')
    .required('RODTEP Amount is required')
});

export const addDrawbackConfirmationValidation = yup.object().shape({
  claim_confirmation: booleanSelectSchema
    .required('Drawback Claimed is required')
    .test('is-true', 'Drawback Claimed must be true', (value) => value.value != false),
  currency: selectSchema.required('Currency is required'),
  credit_date: yup.date().required('Drawback Credit Date is required'),
  amount: yup
    .number()
    .typeError('Drawback Amount should be in number')
    .required('Drawback Amount is required')
});
