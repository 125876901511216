import { ChangeEvent, memo, useEffect, useState } from 'react';
import css from './index.module.scss';
import { Checkbox, CheckboxLabel, Button, IconNode, Typography, Divider } from '@components/base';
import {
  BoxContainer,
  SelectLabel,
  TextField,
  RectifyRemarksContainer,
  AutoComplete,
  PaymentTerms
} from '@components/common';
import { Controller, useFormContext, useWatch, useFieldArray } from 'react-hook-form';
import {
  INCO_TERMS,
  QC_LOCATION,
  TRANSPORTATION_MODE,
  CONTAINER_TYPE,
  COUNTRY_FIELD_TYPES,
  PORT_FIELD_TYPES
} from '@helpers/constants';
import Images from '@assets/images';
import { getPaymentTermsFilterOptions } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { IPaymentTermsFilter } from '@helpers/types/add-order';
import { fetchCountryList, fetchPortList } from '@services/util.service';
import { formatPortOptions } from '@helpers/utils';
import { AddOrderStates } from '@pages/order/add-order';

interface OrderDetailContainerProps {
  rectifyRemarks?: string;
  countryOptions?: Array<{ label: any; value: any }>;
  orderState?: AddOrderStates;
}

const OrderDetailContainer = (props: OrderDetailContainerProps) => {
  const [countryList, setCountryList] = useState<Array<{ label: any; value: any }>>([]);
  const { rectifyRemarks, countryOptions, orderState } = props;
  const [countryOfOrigin, setCountryOfOrigin] = useState<Array<{ label: any; value: any }>>([]);
  const [countryOfFinalDestination, setCountryOfFinalDestination] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [paymentTermsOptions, setPaymentTermsOptions] = useState<IPaymentTermsFilter[]>([]);
  const [filteredPaymentTermsOptions, setFilteredPaymentTermsOptions] = useState<
    IPaymentTermsFilter[]
  >([]);
  const [portList, setPortList] = useState([]);
  const [portOfLoading, setPortOfLoading] = useState([]);
  const [portOfDischarge, setPortOfDischarge] = useState([]);
  const { control, setValue } = useFormContext();
  const watchField = useWatch({
    name: ['is_qc_test', 'inco_terms', 'payment_terms'],
    control
  });

  const modeOfTransportWatchField = useWatch({
    name: 'transportation_mode',
    control
  });

  const filterCountryList = async () => {
    const data = await fetchCountryList();
    if (data?.success) {
      setCountryList(data.data.filter_options);
      setCountryOfOrigin(data.data.filter_options);
      setCountryOfFinalDestination(data.data.filter_options);
    }
  };

  const filterCountry = async (event?: any, param?: any, fieldType?: string) => {
    if (param) {
      const countryList = await fetchCountryList({ search_text: param });
      if (countryList?.success) {
        fieldType === COUNTRY_FIELD_TYPES.COUNTRY_OF_ORIGIN
          ? setCountryOfOrigin(countryList.data.filter_options)
          : setCountryOfFinalDestination(countryList.data.filter_options);
      } else {
        notify({
          message: countryList.error ?? 'Unable to fetch country list',
          severity: 'error'
        });
      }
    } else {
      origin ? setCountryOfOrigin(countryList) : setCountryOfFinalDestination(countryList);
    }
  };

  const filterPortList = async () => {
    const data = await fetchPortList();
    if (data?.success) {
      setPortList(data.data.filter_options);
      setPortOfLoading(data.data.filter_options);
      setPortOfDischarge(data.data.filter_options);
    }
  };

  const filterPorts = async (event?: any, param?: any, fieldType?: string) => {
    let portTypeLabel = '';
    if (modeOfTransportWatchField?.value === 'SEA') {
      portTypeLabel = 'seaport';
    } else if (modeOfTransportWatchField?.value === 'AIR') {
      portTypeLabel = 'airport';
    }
    if (param) {
      const portList = await fetchPortList({ port_type: portTypeLabel, search_text: param });
      if (portList?.success) {
        fieldType === PORT_FIELD_TYPES.PORT_OF_LOADING
          ? setPortOfLoading(portList.data.filter_options)
          : setPortOfDischarge(portList.data.filter_options);
      } else {
        notify({
          message: portList.error ?? 'Internal Server Error',
          severity: 'error'
        });
      }
    } else {
      fieldType === PORT_FIELD_TYPES.PORT_OF_LOADING
        ? setPortOfLoading(portList)
        : setPortOfDischarge(portList);
    }
  };

  const {
    fields: containerFields,
    append: containerAppend,
    remove: containerRemove
  } = useFieldArray({
    name: 'container',
    control
  });

  const {
    fields: paymentTermFields,
    append: paymentTermAppend,
    remove: paymentTermRemove
  } = useFieldArray({
    name: 'payment_terms',
    control
  });

  const handleAddField = () => {
    containerAppend({
      container_type: null,
      no_of_container: null
    });
  };

  const handleAddPaymentTermField = () => {
    paymentTermAppend({
      payment_term: null,
      percentage: '',
      days: ''
    });
  };

  useEffect(() => {
    filterPorts();
  }, [modeOfTransportWatchField]);

  useEffect(() => {
    filterCountryList();
    getPaymentTermsOptions();
    filterPortList();
  }, []);

  const getPaymentTermsOptions = async () => {
    const response = await getPaymentTermsFilterOptions();
    if (response?.success) {
      setPaymentTermsOptions(response.data?.payment_terms_options);
    } else {
      notify({
        severity: 'error',
        title: 'Error',
        message: response.message ?? 'Unable to fetch payment terms'
      });
    }
  };

  /**
   * This useEffect is used to filter the payment terms options
   */
  useEffect(() => {
    const selectedPaymentTerms = watchField[2]?.map(
      (item: any) => item?.payment_term?.payment_term ?? ''
    );
    const filteredPaymentTermsOptionsCopy = paymentTermsOptions.filter((item: any) => {
      return !selectedPaymentTerms.includes(item.payment_term);
    });
    setFilteredPaymentTermsOptions(filteredPaymentTermsOptionsCopy);
  }, [watchField[2]]);

  const incoTermsOptions =
    orderState?.consigneeInfo?.elchemy_entity?.is_elchemy_entity === true
      ? INCO_TERMS.filter((option) => option.value !== 'CIF')
      : INCO_TERMS;

  return (
    <>
      {rectifyRemarks ? (
        <RectifyRemarksContainer rectifyRemarks={rectifyRemarks} module="Order" />
      ) : (
        <></>
      )}
      <BoxContainer title="Order Details" className={css.mainWrapper}>
        <div className={css.boxWrapper}>
          <div className={css.rowWrapper}>
            <Controller
              name="transportation_mode"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  autoFocus
                  required
                  options={TRANSPORTATION_MODE}
                  label="Mode of Transportation"
                  placeholder="Select mode"
                  rootClassName={css.fieldWrapper}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  isSearchable
                />
              )}
            />
            <Controller
              name="inco_terms"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  required
                  options={incoTermsOptions}
                  label="Inco Terms"
                  placeholder="Select term"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  isSearchable
                />
              )}
            />
          </div>
          <div className={css.rowWrapper1}>
            <Controller
              name="country_of_origin"
              control={control}
              render={({ field, fieldState }) => {
                const onCountrySelection = (event: any, param: any) => {
                  if (param) {
                    setValue('country_of_origin', param);
                  } else {
                    setValue('country_of_origin', null);
                  }
                };
                return (
                  <AutoComplete
                    {...field}
                    required
                    label="Country of  Origin"
                    placeholder="Start typing"
                    options={countryOfOrigin}
                    onInputChange={(event: any, param: any) =>
                      filterCountry(event, param, COUNTRY_FIELD_TYPES.COUNTRY_OF_ORIGIN)
                    }
                    onInputSelection={onCountrySelection}
                    keyOption="label"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldWrapper1}
                  />
                );
              }}
            />
            <Controller
              name="country_of_final_destination"
              control={control}
              render={({ field, fieldState }) => {
                const onCountrySelection = (event: any, param: any) => {
                  if (param) {
                    setValue('country_of_final_destination', param);
                  } else {
                    setValue('country_of_final_destination', null);
                  }
                };
                return (
                  <AutoComplete
                    {...field}
                    required
                    label="Country of Final Destination"
                    placeholder="Start typing"
                    options={countryOfFinalDestination}
                    onInputChange={(event: any, param: any) =>
                      filterCountry(event, param, COUNTRY_FIELD_TYPES.COUNTRY_OF_FINAL_DESTINATION)
                    }
                    onInputSelection={onCountrySelection}
                    keyOption="label"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldWrapper1}
                  />
                );
              }}
            />
          </div>
          <div className={css.rowWrapper1}>
            <Controller
              name="port_of_loading"
              control={control}
              render={({ field, fieldState }) => {
                const onPortSelection = (event: any, param: any) => {
                  if (param) {
                    setValue('port_of_loading', param);
                  } else {
                    setValue('port_of_loading', null);
                  }
                };
                const formattedPortOfLoading = formatPortOptions(portOfLoading);
                return (
                  <AutoComplete
                    {...field}
                    required
                    label="Port Of Loading"
                    placeholder="Start typing"
                    options={formattedPortOfLoading}
                    onInputChange={(event: any, param: any) =>
                      filterPorts(event, param, PORT_FIELD_TYPES.PORT_OF_LOADING)
                    }
                    onInputSelection={onPortSelection}
                    keyOption="label"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldWrapper1}
                  />
                );
              }}
            />
            <Controller
              name="port_of_discharge"
              control={control}
              render={({ field, fieldState }) => {
                const onPortSelection = (event: any, param: any) => {
                  if (param) {
                    setValue('port_of_discharge', param);
                  } else {
                    setValue('port_of_discharge', null);
                  }
                };
                const formattedPortOfDischarge = formatPortOptions(portOfDischarge);
                return (
                  <AutoComplete
                    {...field}
                    required
                    label="Port Of Discharge"
                    placeholder="Start typing"
                    options={formattedPortOfDischarge}
                    onInputChange={(event: any, param: any) =>
                      filterPorts(event, param, PORT_FIELD_TYPES.PORT_OF_DISCHARGE)
                    }
                    onInputSelection={onPortSelection}
                    keyOption="label"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldWrapper1}
                  />
                );
              }}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name="place_of_delivery"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  inputMode="text"
                  enterKeyHint="next"
                  autoComplete="street-address"
                  label="Place of Delivery"
                  placeholder="Enter place"
                  error={fieldState.invalid}
                  rootClassName={css.fieldWrapper}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <SelectLabel
              isDisabled
              value={watchField[1]}
              options={INCO_TERMS}
              label="Delivery Term"
              placeholder="Select term"
            />
          </div>
          <Divider className={css.dividerWrapper} />
          <div className={css.rowWrapper}>
            <PaymentTerms
              paymentTermFields={paymentTermFields}
              paymentTermRemove={paymentTermRemove}
              paymentTermsOptions={paymentTermsOptions}
              filteredPaymentTermsOptions={
                filteredPaymentTermsOptions?.length
                  ? filteredPaymentTermsOptions
                  : paymentTermsOptions
              }
              handleAddPaymentTermField={handleAddPaymentTermField}
            />
          </div>
          <Divider className={css.dividerWrapper} />
          <div className={css.rowWrapper}>
            <div className={css.qualityTestWrapper}>
              <Controller
                name="is_qc_test"
                control={control}
                render={({ field }) => {
                  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
                    const { onChange } = field;
                    const checked = event.currentTarget.checked;
                    if (!checked)
                      setValue('place_of_quality_test', null, {
                        shouldValidate: true,
                        shouldDirty: false
                      });
                    onChange(event);
                  };
                  return (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      value={`${field.value}`}
                      onChange={handleChange}
                    />
                  );
                }}
              />
              <Controller
                name="place_of_quality_test"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    required
                    isDisabled={!watchField[0]}
                    options={QC_LOCATION}
                    label="Place of Quality Test"
                    placeholder="Select option"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name="palletization"
              control={control}
              render={({ field }) => (
                <CheckboxLabel
                  {...field}
                  label="Item to be palletized"
                  value={`${field.value}`}
                  checked={field.value}
                />
              )}
            />
          </div>
        </div>
        <Divider />
        {modeOfTransportWatchField?.value !== 'AIR' && (
          <div className={css.boxWrapper}>
            <Typography variant="h4">Container Details</Typography>

            {containerFields.map((item, index) => {
              const handleDeleteClick = () => containerRemove(index);
              return (
                <ContainerDataForm
                  key={item.id}
                  index={index}
                  no_of_container={`container.${index}.no_of_container`}
                  container_type={`container.${index}.container_type`}
                  showDelete={containerFields.length > 1}
                  onDeleteClick={handleDeleteClick}
                />
              );
            })}
            <Button
              variant="text"
              onClick={handleAddField}
              startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
              Add Container
            </Button>
          </div>
        )}
      </BoxContainer>
    </>
  );
};

interface ContainerDataFormProps {
  no_of_container: string;
  container_type: string;
  showDelete: boolean;
  onDeleteClick: () => void;
  index: number;
}

const ContainerDataForm = (props: ContainerDataFormProps) => {
  const { no_of_container, container_type, showDelete, onDeleteClick, index } = props;
  const { control, setValue } = useFormContext();

  return (
    <>
      <div className={css.headerWrapper}>
        <Typography variant="h5">Item {index + 1}</Typography>
        {showDelete && (
          <Button
            variant="text"
            title="Delete Item"
            onClick={onDeleteClick}
            startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}>
            Delete
          </Button>
        )}
      </div>
      <div className={css.rowWrapper}>
        <Controller
          name={no_of_container}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              inputMode="numeric"
              enterKeyHint="next"
              label="Number of containers"
              placeholder="Enter number"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              rootClassName={css.fieldWrapper}
            />
          )}
        />
        <Controller
          name={container_type}
          control={control}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              required
              isSearchable
              options={CONTAINER_TYPE}
              label="Container type"
              placeholder="Select option"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              getOptionLabel={(option: any) => option.label}
              rootClassName={css.fieldWrapper}
            />
          )}
        />
      </div>
    </>
  );
};

export default memo(OrderDetailContainer);
