import { memo } from 'react';
import css from '../index.module.scss';
import { Typography } from '@components/base';
import { getFormattedDate, sanitizeValue } from '@helpers/utils';
import { IShippingFormData } from '@helpers/types/si';

interface IShippingInstructionPreviewProps {
  shippingFormData: IShippingFormData;
}

const ShippingInstructionPreview = (props: IShippingInstructionPreviewProps) => {
  const { shippingFormData } = props;
  const {
    blType,
    placeOfReceipt,
    portOfFinalDestination,
    sealNumber,
    customSealNumber,
    shippingBillNumber,
    shippingBillDate,
    freight,
    switch_bl_case,
    notify_party_1,
    notify_party_2
  } = shippingFormData;
  return (
    <div className={css.boxWrapper}>
      <div className={css.orderDetailsContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Shipping Instruction Details</Typography>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">BL Type</Typography>
            <Typography variant="body">{sanitizeValue(blType?.label, 'Not Available')}</Typography>
          </div>
          <div>
            <Typography variant="label">Place of Receipt</Typography>
            <Typography variant="body">{sanitizeValue(placeOfReceipt, 'Not Available')}</Typography>
          </div>
          <div>
            <Typography variant="label">Port of Final Destination</Typography>
            <Typography variant="body">
              {sanitizeValue(portOfFinalDestination?.label, 'Not Available')}
            </Typography>
          </div>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Shipping Bill Number</Typography>
            <Typography variant="body">
              {sanitizeValue(shippingBillNumber, `Not Available`)}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Shipping Bill Date</Typography>
            <Typography variant="body">{getFormattedDate(shippingBillDate)}</Typography>
          </div>
          <div>
            <Typography variant="label">Switch BL Case</Typography>
            <Typography variant="body">{switch_bl_case ? 'Yes' : 'No'}</Typography>
          </div>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Freight</Typography>
            <Typography variant="body">{sanitizeValue(freight?.label, `Not Available`)}</Typography>
          </div>
          <div>
            <Typography variant="label">Notify Party 1</Typography>
            <Typography variant="body">{sanitizeValue(notify_party_1, `Not Available`)}</Typography>
          </div>
          <div>
            <Typography variant="label">Notify Party 2</Typography>
            <Typography variant="body">{sanitizeValue(notify_party_2, `Not Available`)}</Typography>
          </div>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Seal Number</Typography>
            <Typography variant="body">
              {sealNumber.length
                ? sealNumber
                    .map((sealItem: { sealNumber: string }, index: number) => sealItem.sealNumber)
                    .join(', ')
                : '-'}
            </Typography>
          </div>
          <div>
            <Typography variant="label">Custom Seal Number</Typography>
            <Typography variant="body">
              {customSealNumber.length
                ? customSealNumber
                    .map(
                      (sealItem: { customSealNumber: string }, index: number) =>
                        sealItem.customSealNumber
                    )
                    .join(', ')
                : '-'}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ShippingInstructionPreview);
