import Axios, {
  AxiosError,
  AxiosResponse,
  HttpStatusCode,
  InternalAxiosRequestConfig,
  isAxiosError
} from 'axios';
import notify from '@helpers/toastify-helper';
import SessionService from '@helpers/session-helper';

const httpClient = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 2000 * 10,
  responseType: 'json'
});

/**
 * This is a second instance of axios to handle binary data
 */
const httpClient2 = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 2000 * 10,
  responseType: 'arraybuffer'
});

const handleRequest = (config: InternalAxiosRequestConfig) => {
  const { accessToken } = SessionService.getSession();
  if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};

const handleRequestError = (error: any) => {
  return Promise.reject(error);
};

const handleResponse = (response: AxiosResponse) => {
  const data = response.data;
  return { ...data, success: data.success, data: data.data, status: response.status };
};

const handleResponseForBlobs = (response: AxiosResponse) => {
  return response.data;
};

const handleResponseError = async (error: any) => {
  if (isAxiosError(error)) {
    return await parseError(error);
  }
  return Promise.reject(error);
};

const getErrorCode = (error: AxiosError) => {
  switch (error.code) {
    case AxiosError.ECONNABORTED:
      notify({
        title: 'Connection timeout',
        message: 'Server took too long to respond',
        severity: 'warning'
      });
      break;
    case AxiosError.ERR_NETWORK:
      notify({
        title: 'Service unavailable',
        message: "Can't connect to server.",
        severity: 'warning',
        dismissible: false,
        showIcon: false
      });
      break;
    case AxiosError.ERR_CANCELED:
      break;
    default:
      notify({
        title: 'Unknown error',
        message: 'Something went wrong.',
        severity: 'error'
      });
      break;
  }
  return Promise.reject({
    data: null,
    error: error.message
  });
};

const parseError = async (error: AxiosError | any) => {
  if (!error.response) return await getErrorCode(error);
  else {
    const statusCode = error.response.status;
    const responseConfig = error.response.config;
    if (statusCode === HttpStatusCode.Unauthorized && !responseConfig._retry) {
      responseConfig._retry = true;
      const refreshSession = await SessionService.refreshSession();
      if (refreshSession) {
        const response = await httpClient(responseConfig);
        if (response.data) return Promise.resolve(response);
        else return Promise.reject(response);
      }
    } else if (statusCode === HttpStatusCode.Unauthorized && responseConfig._retry) {
      SessionService.clearSession();
    }

    return Promise.reject({
      ...error.response.data,
      success: error.response.data.success,
      status: statusCode,
      message: error.response.data.message ?? error.response.data.error ?? 'Something went Wrong',
      error: error.response.data.message ?? error.response.data.error ?? 'Something went Wrong'
    });
  }
};

httpClient.interceptors.request.use(handleRequest, handleRequestError);
httpClient.interceptors.response.use(handleResponse, handleResponseError);

httpClient2.interceptors.request.use(handleRequest, handleRequestError);
httpClient2.interceptors.response.use(handleResponseForBlobs, handleResponseError);

export default httpClient;
export { httpClient2 };
