import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { AppContext } from '@helpers/hooks/AppContext';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import moment from 'moment';
import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import css from './index.module.scss';
import { getFormattedDate } from '@helpers/utils';
import { DOCUMENT_GENERATION_STATUS } from '@helpers/constants';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

interface ICICard {
  taskData: Array<Partial<ITaskTabProps>>;
}

const GenerateCICard = (props: ICICard) => {
  const navigate = useNavigate();
  const params = useParams();
  const { appDispatch } = useContext(AppContext);
  const { taskData } = props;
  const taskItem = taskData[0];

  // TODO: Ideally memo should not be required here. Can directly use map in doc generator constants
  const docGenerationStatus = useMemo(() => {
    if (taskItem?.custom_invoice_generation_status as number) {
      return DOCUMENT_GENERATION_STATUS.find(
        (i) => i.value === taskItem?.custom_invoice_generation_status
      )?.label;
    }
    return null;
  }, [taskItem]);

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskItem.is_completed ? (
                <Avatar src={Images.illustrationEdit} alt="" />
              ) : (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              )}

              <Link to={`/order/${taskItem?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  #{taskItem?.readable_task_id} - Generate Custom Invoice
                </Typography>
              </Link>
            </div>
            <div className={css.pocWrapper}>
              {/* TODO: Rethink this logic */}
              {/* {taskItem.is_completed && taskItem?.custom_invoice_pdf && (
                <div className={css.btnContainer}>
                  <DocumentRow.View title="" document={taskItem?.custom_invoice_pdf as string} />
                  <DocumentRow.ViewWord
                    document={taskData[0]?.custom_invoice_docx as string}
                    title=""
                  />
                </div>
              )} */}
              {!taskItem.is_completed && !docGenerationStatus && (
                <Button
                  variant="text"
                  onClick={() => {
                    appDispatch({
                      type: 'setPIData',
                      payload: {
                        task_id: taskItem?.task_id,
                        order_id: taskItem?.order,
                        invoiceType: 'Custom'
                      }
                    });
                    navigate(
                      `/order/generate-commercial-invoice/${
                        (params.id as string) ?? (taskItem?.order as string)
                      }`
                    );
                  }}
                  className={css.assignButton}>
                  Generate
                </Button>
              )}
              {!taskItem?.is_completed &&
                (docGenerationStatus == 'DOC_GENERATION_PROCESSING' ||
                  docGenerationStatus == 'DOC_GENERATION_REQUESTED') && (
                  <Button variant="text" disabled className={css.assignButton}>
                    Document generation in process
                  </Button>
                )}
              {!taskItem?.is_completed && docGenerationStatus == 'DOC_GENERATION_FAILED' && (
                <Button variant="text" disabled className={css.assignButton}>
                  Document Generation Failed
                </Button>
              )}
            </div>
          </div>
        </Accordion.Header>
      </Accordion>
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};

export default GenerateCICard;
