import Images from '@assets/images';
import { Accordion, Chip, Divider, IconNode, Typography } from '@components/base';
import { Avatar } from '@components/common';
import { getFormattedDate } from '@helpers/utils';
import moment from 'moment';
import { useState } from 'react';
import ProofOfExportModal from './components';
import css from './index.module.scss';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

interface vesselProps {
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete?: () => void;
}

const ProofOfExport = (props: vesselProps) => {
  const [openModal, setopenModal] = useState(false);
  const getCompleteStatus = props.taskData[0].is_completed;
  const [taskIsCompleted, setTaskIsCompleted] = useState<boolean | undefined>(getCompleteStatus);
  const { taskData } = props;

  const handleStatusFromModal = (data: boolean) => {
    setTaskIsCompleted(data);
  };

  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {getCompleteStatus || taskIsCompleted ? (
                  <Avatar src={Images.alertSuccessFaint} alt="" />
                ) : (
                  <Avatar src={Images.illustration} alt="" />
                )}

                <Link to={`/order/${taskData[0]?.order}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="h5" className={css.underlineOnHover}>
                    #{taskData[0]?.readable_task_id} - Add Proof of Export (
                    {taskData[0]?.supplier_name})
                  </Typography>
                </Link>
              </div>
              <div className={css.pocWrapper}>
                {!getCompleteStatus && taskIsCompleted === false && (
                  <Chip
                    label="Add Details"
                    variant="outlined"
                    clickable={true}
                    className={css.uploadButton}
                    onClick={() => setopenModal(true)}
                  />
                )}
                {(!getCompleteStatus || !taskIsCompleted) && <Divider className={css.divider} />}
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        <Footer
          createdDate={props.taskData[0].created_at as Date}
          updatedDate={props.taskData[0].updated_at as Date}
        />
        {openModal && (
          <ProofOfExportModal
            open={openModal}
            onClose={() => setopenModal(false)}
            sendStatusToCard={handleStatusFromModal}
            taskId={taskData[0].task_id as string}
            onComplete={props.onComplete}
          />
        )}
      </div>
    </>
  );
};

export default ProofOfExport;
