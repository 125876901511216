import { useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { Typography, Button, Loader } from '@components/base';
import {
  UploadDocument,
  NoDocumentPlaceholder,
  DocumentRow,
  DataGrid,
  TableSkeleton
} from '@components/common';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDocument } from '@helpers/yup';
import {
  deleteDocument,
  documentUpload,
  getSupplierDocumentOptions,
  updateDocument
} from '@services/supplier.service';
import notify from '@helpers/toastify-helper';
import { ISupplier } from '@helpers/types/supplier';
import { Document, DocumentForm } from '@helpers/types/document';
import { SUPPLIER_DOCUMENT_TYPE } from '@helpers/constants';
import { ISelect } from '@helpers/types';
import { CellProps, Column } from 'react-table';

interface DocumentTabStates {
  supplierDocumentUploadModal: boolean;
  editDocument: boolean;
  isLoading: boolean;
  documentTypeOptions: ISelect[];
}

interface DocumentTabProps {
  supplierInfo: ISupplier;
  onChange?: (param: ISupplier) => void;
}

interface DocumentGrid {
  document_name: Document;
  proofs: Document;
  document_object: Document;
}

const DocumentTab = (props: DocumentTabProps) => {
  const { supplierInfo } = props;
  const [documentTabState, setDocumentTabState] = useState<DocumentTabStates>({
    supplierDocumentUploadModal: false,
    editDocument: false,
    isLoading: false,
    documentTypeOptions: []
  });

  const { documents } = supplierInfo;

  const formMethods = useForm<DocumentForm>({
    resolver: yupResolver(addDocument),
    defaultValues: {
      document_id: '',
      document_type: null,
      document_name: '',
      document_object: null
    },
    shouldUnregister: true
  });

  const {
    setValue,
    formState: { isSubmitting }
  } = formMethods;

  const { supplierDocumentUploadModal, editDocument, isLoading, documentTypeOptions } =
    documentTabState;

  useEffect(() => {
    fetchSupplierDocumentTypes();
  }, []);

  const availDocumentType = useMemo(() => {
    if (documentTypeOptions?.length == 0) return [];
    const { supplierInfo } = props;
    const { documents } = supplierInfo;
    const availDoc: ISelect[] = [...documentTypeOptions];
    documents.forEach((doc) => {
      const docAvail = documentTypeOptions.find((item) => item.value === doc.document_type);
      if (docAvail && availDoc.includes(docAvail) && docAvail.value !== 'OTHER') {
        const index = availDoc.indexOf(docAvail);
        availDoc.splice(index, 1);
      }
    });
    return availDoc;
  }, [props.supplierInfo, documentTypeOptions]);

  const handleAddSupplierDocument = () => {
    setDocumentTabState((prevState) => ({
      ...prevState,
      supplierDocumentUploadModal: !prevState.supplierDocumentUploadModal,
      editDocument: false
    }));
  };

  const handleSupplierDocumentModalClose = async () => {
    setDocumentTabState((prevState) => ({
      ...prevState,
      supplierDocumentUploadModal: false,
      editDocument: false
    }));
  };

  const handleFormSubmission: SubmitHandler<DocumentForm> = async (data) => {
    const { supplierInfo, onChange } = props;
    const formData = new FormData();
    formData.append('document_type', data.document_type!.value);
    formData.append('document_name', data.document_name);
    formData.append('document_object', data.document_object as Blob);
    if (!editDocument) {
      setDocumentTabState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await documentUpload(supplierInfo.supplier_id, formData);
      setDocumentTabState((prevState) => ({ ...prevState, isLoading: false }));
      if (response.success) {
        const { data } = response;
        const { documents } = supplierInfo;
        notify({ message: 'Document added successfully', dismissible: true });
        documents.unshift(data);
        onChange?.({ ...supplierInfo, documents: documents });
        handleSupplierDocumentModalClose();
      } else {
        notify({ message: response.error ?? 'Unable to add document', dismissible: true });
      }
    } else {
      setDocumentTabState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await updateDocument(data.document_id, formData);
      setDocumentTabState((prevState) => ({ ...prevState, isLoading: false }));
      if (response.success) {
        const { data } = response;
        const { documents } = supplierInfo;
        const currentDoc = documents.findIndex((doc) => doc.document_id === data.document_id);
        if (currentDoc !== -1) {
          documents[currentDoc] = data;
          onChange?.(supplierInfo);
          notify({ message: 'Document updated successfully', dismissible: true });
          handleSupplierDocumentModalClose();
        }
      } else {
        notify({ message: response.error ?? 'Unable to update document', dismissible: true });
      }
    }
  };

  const handleReupload = (document: Document) => () => {
    const documentType = SUPPLIER_DOCUMENT_TYPE.find((doc) => doc.value === document.document_type);
    if (documentType) {
      setDocumentTabState((prevState) => ({
        ...prevState,
        supplierDocumentUploadModal: true,
        editDocument: true
      }));
      setValue('document_id', document.document_id, { shouldDirty: false });
      setValue('document_type', documentType, { shouldDirty: false });
      setValue('document_name', document.document_name, { shouldDirty: false });
      setValue('document_object', null, { shouldDirty: false });
    }
  };

  const handleDocumentDelete = (document: Document) => async () => {
    const { supplierInfo, onChange } = props;
    setDocumentTabState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await deleteDocument(document.document_id);
    setDocumentTabState((prevState) => ({ ...prevState, isLoading: false }));
    if (response.success) {
      const { documents } = supplierInfo;
      const deletedDocIndex = documents.findIndex(
        (doc) => doc.document_id === document.document_id
      );
      documents.splice(deletedDocIndex, 1);
      onChange?.({ ...supplierInfo, documents: documents });
      notify({ message: 'Document deleted successfully', dismissible: true });
    } else {
      notify({ message: response.error ?? 'Unable to delete document', dismissible: true });
    }
  };

  const [documentColumn, documentData] = useMemo(() => {
    const { supplierInfo } = props;
    const { documents } = supplierInfo;
    const column: Column<DocumentGrid>[] = [
      {
        Header: 'Document Name',
        accessor: 'document_name',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          const documentType = value.display_name;
          if (documentType)
            return (
              <DocumentRow.Title title={documentType} documentAvailable={value.document_object} />
            );
          return null;
        }
      },
      {
        Header: 'Proofs',
        accessor: 'proofs',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return (
            <DocumentRow.Upload
              documentAvailable={value.document_object}
              onClick={handleReupload(value)}
            />
          );
        }
      },
      {
        Header: '',
        accessor: 'document_object',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return (
            <DocumentRow.Action
              document={value.document_object}
              title={value.document_name}
              onClick={handleDocumentDelete(value)}
            />
          );
        }
      }
    ];
    const row: DocumentGrid[] = documents.map((doc) => ({
      document_name: doc,
      proofs: doc,
      document_object: doc
    }));
    return [column, row];
  }, [props.supplierInfo]);

  const fetchSupplierDocumentTypes = async () => {
    setDocumentTabState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await getSupplierDocumentOptions();
    if (response.success) {
      setDocumentTabState((prevState) => ({
        ...prevState,
        documentTypeOptions: response.data?.document_type_filter_options ?? [],
        isLoading: false
      }));
    } else {
      notify({ message: response.error ?? 'Unable to fetch document types', dismissible: true });
      setDocumentTabState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  return (
    <div className={css.documentTabWrapper}>
      <div className={css.documentWrapper}>
        <div className={css.supplierDocumentHeaderWrapper}>
          <Typography variant="h4">Supplier’s Documents</Typography>
          <Button onClick={handleAddSupplierDocument}>Add Documents</Button>
        </div>
        {isLoading || isSubmitting ? (
          <TableSkeleton />
        ) : documents.length ? (
          <DataGrid columns={documentColumn} data={documentData} />
        ) : (
          !isLoading && (
            <NoDocumentPlaceholder
              title="No Supplier’s Documents Added Yet"
              onClick={handleAddSupplierDocument}
            />
          )
        )}
      </div>
      <FormProvider {...formMethods}>
        {supplierDocumentUploadModal && (
          <UploadDocument
            open={supplierDocumentUploadModal}
            onClose={handleSupplierDocumentModalClose}
            onFormSubmit={handleFormSubmission}
            editMode={editDocument}
            availableDocumentType={availDocumentType}
          />
        )}
      </FormProvider>
    </div>
  );
};

export default DocumentTab;
