/* eslint-disable @typescript-eslint/no-empty-function */
import { BoxContainer, SelectLabel, TextField } from '@components/common';
import { IEntityListTypes } from '@helpers/types/pi';
import { memo } from 'react';
import css from './index.module.scss';

interface ExporterContainerProps {
  exporterData: IEntityListTypes;
}

const ExporterContainer = (props: ExporterContainerProps) => {
  const { exporterData } = props;
  return (
    <BoxContainer title="Exporter" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <SelectLabel
            isDisabled
            value={{ value: exporterData?.name, label: exporterData?.name }}
            onChange={() => {}}
            label="Name"
            rootClassName={css.fieldWrapper}
          />
          <TextField
            disabled
            value={exporterData?.gst_number ?? ''}
            label="GST Number"
            placeholder="Enter GST Number"
          />
        </div>
        <TextField
          disabled
          value={exporterData?.address ?? ''}
          label="Address"
          placeholder="Enter address"
        />
      </div>
    </BoxContainer>
  );
};

export default memo(ExporterContainer);
