import { memo, Fragment } from 'react';
import css from './index.module.scss';
import { BoxContainer, SelectLabel, TextField, RectifyRemarksContainer } from '@components/common';
import { Controller, useFormContext } from 'react-hook-form';
import { SUPPLY_TYPE } from '@helpers/constants';

interface TypeOfOrderContainerProps {
  rectifyRemarks?: string;
}

const TypeOfOrderContainer = (props: TypeOfOrderContainerProps) => {
  const { control } = useFormContext();
  const { rectifyRemarks } = props;
  return (
    <>
      {rectifyRemarks ? (
        <RectifyRemarksContainer rectifyRemarks={rectifyRemarks} module="Order" />
      ) : (
        <></>
      )}

      <BoxContainer title="Type of Order">
        <div className={css.boxWrapper}>
          <div className={css.rowWrapper}>
            <Controller
              name="supply_type"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  autoFocus
                  required
                  options={SUPPLY_TYPE}
                  label="Select the Type of Order"
                  rootClassName={css.fieldWrapper}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="readable_order_id"
              control={control}
              render={({ field }) => (
                <TextField {...field} disabled label="Order Number" placeholder="Order ID" />
              )}
            />
          </div>
        </div>
      </BoxContainer>
    </>
  );
};

export default memo(TypeOfOrderContainer);
