import css from './index.module.scss';
import { Accordion, Typography, Select, Button, Chip } from '@components/base';
import { Avatar } from '@components/common';
import Images from '@assets/images';
import notify from '@helpers/toastify-helper';
import { getProductList, postProudctData } from '@services/task.service';
import { useEffect, useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';
import { ListPlaceholder, DataGrid } from '@components/common';
import { Link } from 'react-router-dom';

interface GateInProps {
  taskId: string;
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete?: () => void;
}
interface supplier {
  supplier_product_grade_id: string;
  supplier_id: string;
  supplier_name: string;
}
interface supplierDataProps {
  order_item_id: string;
  product_name: string;
  grade_name: string;
  supplier_list: Array<supplier>;
}

interface RowGridType {
  supplier_name: string;
  product_name: string;
}

const AddAllSuppliers = (props: GateInProps) => {
  const { taskId, taskData, onComplete } = props;
  const getTaskStatus = taskData[0]?.is_completed;
  const [isCompleted, setIsCompleted] = useState<boolean | undefined>(getTaskStatus);
  const [productData, setProductData] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState<Array<supplierDataProps>>([]);
  const productSupplierData = taskData[0]?.product_supplier_data;

  const selectSupplier = useForm<any>({
    defaultValues: {
      suppliers: [
        {
          value: ''
        }
      ]
    }
  });

  const { control, getValues, handleSubmit } = selectSupplier;

  useEffect(() => {
    (async () => {
      // get the list of products and set the product list state
      const response = await getProductList(taskId);
      if (response?.success) {
        const { data } = response;
        setProductData(data);
      } else {
        notify({ message: response.error ?? 'Failed to fetch product list', severity: 'error' });
      }
    })();
  }, []);

  const onSubmit = async () => {
    const orderItemMap = new Map();
    for (const obj of selectedSuppliers) {
      orderItemMap.set(obj.order_item_id, obj);
    }
    const uniqueObjects = Array.from(orderItemMap.values());
    const data = { order_item_mapping: uniqueObjects };
    const response = await postProudctData(taskId, data);
    if (response.success) {
      notify({ message: 'Suppliers added successfully!' });
      setIsCompleted(true);
      if (onComplete) {
        onComplete();
      }
      return response;
    } else {
      notify({ message: response.error ?? 'Failed to add suppliers', severity: 'error' });
    }
  };

  const [documentColumn, documentData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Product Name',
        accessor: 'product_name'
      },
      {
        Header: 'Supplier Name',
        accessor: 'supplier_name'
      }
    ];

    const row: Array<RowGridType> = productSupplierData?.length
      ? productSupplierData?.map((prod: any) => ({
          supplier_name: prod.supplier_name ? prod.supplier_name : '-',
          product_name: prod.product_name ? prod.product_name : '-'
        }))
      : [];

    return [column, row];
  }, [productSupplierData]);

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {getTaskStatus || isCompleted ? (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              ) : (
                <Avatar src={Images.illustration} alt="" />
              )}

              <Link to={`/order/${taskData[0]?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  #{taskData[0]?.readable_task_id} - Confirm Suppliers
                </Typography>
              </Link>
            </div>
            <div className={css.pocWrapper}>
              <div className={css.uploadWrapper}>
                {!getTaskStatus && isCompleted == false && (
                  <Button
                    type="submit"
                    variant="text"
                    className={css.detailsButton}
                    onClick={onSubmit}>
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content>
          <div className={css.productSection}>
            <div className={css.dataDesign}>
              {!getTaskStatus ? (
                productData?.map((item: supplierDataProps, index: any) => {
                  const listOfSupplierKeys = item?.supplier_list.map((item: any) => ({
                    label: item.supplier_name,
                    value: item.supplier_product_grade_id
                  }));
                  return (
                    <div key={index} className={css.container}>
                      <Typography className={css.productLabel} variant="p">
                        {item?.product_name}
                      </Typography>
                      <div className={css.dropDown}>
                        <Controller
                          name={`suppliers.${index}.value`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              defaultValue={''}
                              options={listOfSupplierKeys}
                              placeholder="Select a Supplier"
                              isDisabled={isCompleted}
                              onChange={(e: any) => {
                                field.onChange(e);
                                const data = {
                                  supplier_product_grade_id: e.value,
                                  order_item_id: item?.order_item_id
                                };
                                setSelectedSuppliers((prev: any) => [...prev, data]);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <DataGrid
                  tableStyle={{ width: '55%' }}
                  columns={documentColumn}
                  data={documentData}
                />
              )}
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskData[0]?.updated_at)?.diff(taskData[0]?.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};
export default AddAllSuppliers;
