import { Skeleton, Typography } from '@components/base';
import css from './index.module.scss';
import { IFilteredValue, IObject, IOrderDetails } from '../../../orderOverview';
import { checkScreenType, chunkArray } from '@helpers/utils';
import { SCREEN_TYPE } from '@helpers/constants';

interface IOrderDetailsProps {
  filteredValue?: IFilteredValue;
  orderDetailsFields: IObject[];
  isLoading?: boolean;
}

const Order = (props: IOrderDetailsProps) => {
  const { filteredValue, orderDetailsFields, isLoading = false } = props;

  return (
    <div className={css.detailWrapper}>
      <div className={css.detailsTitle}>
        <Typography variant="h4">Order Details</Typography>
      </div>
      <div className={css.detailContainer}>
        {chunkArray(orderDetailsFields, checkScreenType() == SCREEN_TYPE.mobile.label ? 1 : 2).map(
          (chunk: any[], index: number) => (
            <div className={css.rowWrapper} key={index}>
              {chunk.map((field: any, subIndex: number) => (
                <div
                  className={`${css.fieldWrapper} ${
                    filteredValue && filteredValue.key === field.key ? css.filtered : ''
                  }`}
                  key={subIndex}>
                  <Typography
                    variant="label"
                    className={`${css.fieldLabel} ${
                      filteredValue && filteredValue.key === field.key ? css.filtered : ''
                    }`}>
                    {field.label}
                  </Typography>
                  <div className={css.fieldValueContainer}>
                    {isLoading ? (
                      <Skeleton variant="text" width={200} height={20} />
                    ) : field.value ? (
                      <Typography
                        variant="body"
                        className={`${css.notAvailable} ${
                          filteredValue && filteredValue.key === field.key ? css.filtered : ''
                        }`}>
                        {field.value}
                      </Typography>
                    ) : (
                      <Typography variant="label" className={css.notAvailable}>
                        Not Available
                      </Typography>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Order;
