import css from './index.module.scss';
import React, { useMemo } from 'react';
import { Typography, IconNode } from '@components/base';
import { DataGrid } from '@components/common';
import Images from '@assets/images';
import { FieldValue } from '@helpers/types/coa';

interface ItemDetailsPropTypes {
  data: {
    item_details: any;
    item_details_skeleton: FieldValue[];
  };
  handleNavigation: (index: number) => void;
}

const ItemDetails = (props: ItemDetailsPropTypes) => {
  const { data, handleNavigation } = props;
  const { item_details, item_details_skeleton } = data;
  const [itemDataColumn, itemDataRow] = useMemo(() => {
    const columns: any = item_details_skeleton?.map((item: any) => {
      return {
        Header: item.field_name,
        accessor: item.field_name,
        Cell: (props: any) => {
          const { value } = props;
          return <Typography variant="p">{value?.length ? value : '-'}</Typography>;
        }
      };
    });
    const rows: any = item_details?.length
      ? item_details?.map((item: any, index: number) => ({
          ...item
        }))
      : [];

    return [columns, rows];
  }, [item_details]);

  return (
    <div className={css.boxWrapper}>
      <div className={css.orderContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="p">Item Details</Typography>
          <IconNode
            className={css.editIcon}
            src={Images.editRed}
            onClick={() => handleNavigation(1)}
          />
        </div>
        <div className={css.rowWrapper}>
          <DataGrid tableStyle={{ width: '100%' }} columns={itemDataColumn} data={itemDataRow} />
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
