import { Typography } from '@components/base';
import css from '../index.module.scss';
import { IShippingFormData } from '@helpers/types/si';
import { sanitizeValue } from '@helpers/utils';
import { IOrderItem } from '@helpers/types/task-tabs';

interface HAZDetailsProps {
  shippingFormData: IShippingFormData;
  data: IOrderItem;
  index: number;
}

const HAZDetails = (props: HAZDetailsProps) => {
  const { shippingFormData, data } = props;
  const { product_name, order_item_id } = data;
  const { order_items } = shippingFormData;

  const hazOrderItem = order_items.filter((item: any) => item.order_item_id === order_item_id);

  return (
    <div className={css.boxWrapper}>
      {hazOrderItem.map((item: any, index: number) => (
        <div className={css.orderDetailsContainer} key={index}>
          <div className={css.fieldTitle}>
            <Typography variant="pdoc">{`HAZ Details - ${product_name}`}</Typography>
          </div>
          <div className={css.fieldWrapper}>
            <div>
              <Typography variant="label">UN Number</Typography>
              <Typography variant="body">{item.un_number}</Typography>
            </div>
            <div>
              <Typography variant="label">Class</Typography>
              <Typography variant="body">{item.class}</Typography>
            </div>
          </div>
          <div className={css.fieldWrapper}>
            <div>
              <Typography variant="label">E.M.S</Typography>
              <Typography variant="body">{sanitizeValue(item.ems, `Not Available`)}</Typography>
            </div>
            <div>
              <Typography variant="label">Packing Group</Typography>
              <Typography variant="body">{item.packing_group?.label}</Typography>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HAZDetails;
