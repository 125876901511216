/* eslint-disable @typescript-eslint/no-empty-function */
import { BoxContainer, SelectLabel, TextField } from '@components/common';
import { ICustomerInfoTypes } from '@helpers/types/pi';
import { memo } from 'react';
import css from './index.module.scss';

interface CustomerContainerProps {
  customerData: ICustomerInfoTypes;
  isConsignee?: boolean;
}

const CustomerForm = (props: CustomerContainerProps) => {
  const { customerData, isConsignee } = props;
  const { customer_poc } = customerData;
  
  return (
    <BoxContainer title={`${isConsignee ? 'Consignee' : 'Customer'}`} className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <SelectLabel
            isDisabled
            value={{ value: customerData?.name, label: customerData?.name }}
            label="Customer Name"
            placeholder="Select customer"
            onChange={() => {}}
            rootClassName={css.fieldWrapper}
          />
          <SelectLabel
            isDisabled
            value= {customerData.country}
            label="Country"
            placeholder="Select Country"
          />
        </div>
        <div className={css.rowWrapper}>
          <SelectLabel
            isDisabled
            label="Point of Contact Name"
            placeholder="Select POC name"
            rootClassName={css.fieldWrapper}
            value={{
              label: `${customer_poc?.given_name} ${customer_poc?.family_name}`,
              value: `${customer_poc?.given_name} ${customer_poc?.family_name}`
            }}
          />
          <TextField
            disabled
            value={customer_poc?.phone_number ?? ''}
            label="POC Contact Number"
            placeholder="Enter POC Contact Number"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={customer_poc?.email ?? ''}
            label="POC Email ID"
            placeholder="Enter POC Email ID"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={customerData?.address ?? ''}
            label="Customer Address"
            placeholder="Enter Customer Address"
          />
        </div>
      </div>
    </BoxContainer>
  );
};

export default memo(CustomerForm);
