import { Accordion, Button, IconNode, Typography } from '@components/base';
import { Avatar } from '@components/common';
import css from './index.module.scss';
import Images from '@assets/images';
import Footer from '../components/footer';
import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BLDetailsModal from './BLDetailsModal';
import { awbDataSchema, blDataSchema } from '@helpers/yup/order-tasks.schema';
import { getFormattedDate } from '@helpers/utils';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { ISelect } from '@helpers/types';
import { Link } from 'react-router-dom';

interface AddBLDetailsProps {
  taskData: Partial<ITaskTabProps>;
  onComplete?: () => void;
}

interface AddBLDetailsForm {
  bl_date: Date | null;
  bl_number: string;
  bl_type: ISelect | null;
}

interface AddAWBDetailsForm {
  awb_date: Date | null;
  awb_number: string;
  awb_service_name?: string;
}

const AddBLDetails = (props: AddBLDetailsProps) => {
  const { taskData, onComplete } = props;
  const { alertSuccessFaint, illustrationEdit } = Images;
  const [blDataModal, setBLDataModal] = useState(false);
  const { transportation_mode } = taskData;
  const blDataForm = useForm<AddBLDetailsForm>({
    resolver: yupResolver(blDataSchema),
    defaultValues: {
      bl_date: null,
      bl_number: '',
      bl_type: null
    },
    shouldUnregister: true
  });

  const awbDataForm = useForm<AddAWBDetailsForm>({
    resolver: yupResolver(awbDataSchema),
    defaultValues: {
      awb_date: null,
      awb_number: '',
      awb_service_name: ''
    },
    shouldUnregister: true
  });

  const taskName = transportation_mode === 'AIR' ? 'AWB' : 'BL';
  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {taskData?.is_completed ? (
                  <IconNode src={alertSuccessFaint} className={css.successIcon} />
                ) : (
                  <Avatar src={illustrationEdit} alt="edit-icon" />
                )}

                <Link to={`/order/${taskData?.order}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="h5" className={css.underlineOnHover}>
                    #{taskData.readable_task_id} - Add {taskName} Details
                  </Typography>
                </Link>
              </div>
              <div className={css.pocWrapper}>
                {!taskData?.is_completed && (
                  <Button
                    variant="text"
                    className={css.addBtn}
                    onClick={() => setBLDataModal(true)}>
                    Add
                  </Button>
                )}
              </div>
            </div>
          </Accordion.Header>
          {taskData?.is_completed && (
            <Accordion.Content>
              {transportation_mode !== 'AIR' ? (
                <div className={css.bodyWrapper}>
                  <Typography variant="label">BL Number:</Typography>
                  <Typography variant="p">{taskData?.bl_number}</Typography>
                  <br />
                  <Typography variant="label">BL Date:</Typography>
                  <Typography variant="p">{getFormattedDate(taskData?.bl_date)}</Typography>
                  <br />
                  <Typography variant="label">BL Type:</Typography>
                  <Typography variant="p">{taskData?.bl_type}</Typography>
                </div>
              ) : (
                <div className={css.bodyWrapper}>
                  <Typography variant="label">AWB Service Name:</Typography>
                  <Typography variant="p">{taskData?.awb_service_name}</Typography>
                  <br />
                  <Typography variant="label">AWB Bill Number:</Typography>
                  <Typography variant="p">{taskData?.awb_number}</Typography>
                  <br />
                  <Typography variant="label">{taskName} Date:</Typography>
                  <Typography variant="p">{getFormattedDate(taskData?.awb_date)}</Typography>
                </div>
              )}
            </Accordion.Content>
          )}
        </Accordion>
        <Footer
          createdDate={taskData.created_at as Date}
          updatedDate={taskData.updated_at as Date}
        />
      </div>
      {transportation_mode === 'SEA' ? (
        <FormProvider {...blDataForm}>
          <BLDetailsModal
            open={blDataModal}
            onClose={() => setBLDataModal(false)}
            taskData={taskData}
            onComplete={onComplete}
            taskName={taskName}
          />
        </FormProvider>
      ) : (
        <FormProvider {...awbDataForm}>
          <BLDetailsModal
            open={blDataModal}
            onClose={() => setBLDataModal(false)}
            taskData={taskData}
            onComplete={onComplete}
            taskName={taskName}
          />
        </FormProvider>
      )}
    </>
  );
};

export default AddBLDetails;
