import * as yup from 'yup';

export const assignPeopleSchema = yup.object().shape({
  purchase_head: yup.mixed().required('Purchase Head is required'),
  purchase_poc: yup.mixed().required('Purchase POC is required'),
  ops_head: yup.mixed().required('Operations Head is required'),
  ops_poc: yup.mixed().required('Operations POC is required'),
  finance_head: yup.mixed().required('Finance Head is required'),
  sales_head: yup.mixed().required('Sales Head is required'),
  sales_poc: yup.mixed().optional().nullable(),
  finance_poc: yup.mixed().required('Finance POC is required')
});
