import { Button, IconNode, Modal, TextArea, Typography, Loader } from '@components/base';
import css from './index.module.scss';
import { InputDatePicker, TextField, DragDropUpload, AutoComplete } from '@components/common';
import Images from '@assets/images';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { getShippingLineNames, uploadVesselDetails } from '@services/task.service';
import { addVesselDetails } from '@helpers/yup/add-vessel.schema';
import notify from '@helpers/toastify-helper';
import { convertDateString } from '@helpers/constants';
import { useCallback, useEffect, useState } from 'react';
import { ISelect } from '@helpers/types';

interface VesselModalProps {
  open: boolean;
  onClose?: () => void;
  sendStatusToCard: (arg: boolean) => void;
  taskId: string;
  onComplete?: () => void;
}

interface FormType {
  vessel_name: string;
  cutoff_date: Date | null;
  shipment_date: Date | null;
  voyage_number?: string;
  vessel_booking_copy: any;
  shipping_line: ISelect | null;
  shipment_departure_date: Date | null;
  shipment_arrival_date: Date | null;
}

const VesselDispatchCard = (props: VesselModalProps) => {
  const { open, onClose, sendStatusToCard, taskId, onComplete } = props;
  const [shippingLineDetails, setShippingLineDetails] = useState<any>({
    options: [],
    disabled: false
  });

  const vesselDispatchForm = useForm<FormType>({
    resolver: yupResolver(addVesselDetails),
    defaultValues: {
      vessel_name: '',
      cutoff_date: null,
      shipment_date: null,
      voyage_number: '',
      vessel_booking_copy: null,
      shipping_line: null,
      shipment_departure_date: null,
      shipment_arrival_date: null
    }
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting }
  } = vesselDispatchForm;

  const watch = useWatch({ name: 'vessel_booking_copy', control: control });

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const { onClose } = props;
    const { shipment_date: shipment_date, cutoff_date: cutoff_date } = data;
    const formattedCuOffDate = convertDateString(cutoff_date);
    const formattedShipmentDate = convertDateString(shipment_date);
    const formattedShipmentDepartureDate = convertDateString(data.shipment_departure_date);
    const formattedShipmentArrivalDate = convertDateString(data.shipment_arrival_date);

    const reqBody = new FormData();
    reqBody.append('vessel_name', data.vessel_name);
    reqBody.append('cutoff_date', formattedCuOffDate as string);
    reqBody.append('shipment_date', formattedShipmentDate as string);
    reqBody.append('shipment_departure_date', formattedShipmentDepartureDate as string);
    reqBody.append('shipment_arrival_date', formattedShipmentArrivalDate as string);
    {
      data.voyage_number && reqBody.append('voyage_number', data.voyage_number);
    }
    {
      data.shipping_line && reqBody.append('shipping_line', data.shipping_line?.value);
    }

    reqBody.append('vessel_booking_copy', data.vessel_booking_copy);

    const response = await uploadVesselDetails(taskId, reqBody);
    if (response.success) {
      notify({ message: 'Vessel details uploaded' });
      sendStatusToCard(true);
      if (onComplete) {
        onComplete();
      }
      onClose?.();
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else notify({ message: 'Some error occurred', severity: 'error' });
  };

  const handleFileDrop = useCallback((file: File[]) => {
    if (file.length) {
      setValue('vessel_booking_copy', file[0]);
    }
  }, []);

  const handleDeleteFile = useCallback(() => {
    setValue('vessel_booking_copy', null);
  }, []);

  useEffect(() => {
    fetchShippingLine(null, '');
  }, []);

  const fetchShippingLine = async (event: any, search: string) => {
    setShippingLineDetails((prevState: any) => ({ ...prevState, disabled: true }));
    const response = await getShippingLineNames(search ?? '');
    if (response.success) {
      setShippingLineDetails((prevState: any) => ({
        ...prevState,
        options: response.data?.shipping_line_name_options,
        disabled: false
      }));
    } else {
      setShippingLineDetails((prevState: any) => ({ ...prevState, disabled: false }));
      notify({ message: response.error ?? 'Something went wrong!', severity: 'error' });
    }
  };

  const onShippingLineSelection = (event: any, selected: ISelect) => {
    if (selected) setValue('shipping_line', selected);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Vessel Dispatch Details</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="div"
            className={css.closeButton}
            onClick={onClose}
          />
        </div>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={css.fieldsWrapper}>
            <Controller
              name="vessel_name"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Vessel Name"
                  placeholder="Enter Vessel Name"
                  autoComplete="off"
                  enterKeyHint="next"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            <Controller
              name="cutoff_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  label="Cut-off Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />
            <Controller
              name="shipment_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  label="Shipment Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />
            <Controller
              name="shipment_departure_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  label="Shipment Departure Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />
            <Controller
              name="shipment_arrival_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  label="Shipment Arrival Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />
            <Controller
              name="voyage_number"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Voyage Number"
                  placeholder="XXX XXXX"
                  autoComplete="off"
                  enterKeyHint="next"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            <div className={css.autoComplete}>
              <Controller
                name="shipping_line"
                control={control}
                render={({ field, fieldState }) => (
                  <AutoComplete
                    {...field}
                    required
                    label="Shipping Line"
                    placeholder="Start typing"
                    // disabled={shippingLineDetails.disabled}
                    options={shippingLineDetails.options}
                    onInputChange={fetchShippingLine}
                    onInputSelection={onShippingLineSelection}
                    keyOption="label"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    width="100%"
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="vessel_booking_copy"
                control={control}
                render={({ fieldState }) => (
                  <DragDropUpload
                    onDrop={handleFileDrop}
                    required
                    label="Upload booking copy"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              {watch && (
                <div className={css.fileUploadState}>
                  <Typography variant="p" className={css.fileName}>
                    {watch.name}
                  </Typography>
                  <IconNode
                    src={Images.deleteRed}
                    alt="delete icon"
                    component="button"
                    className={css.deleteButton}
                    onClick={handleDeleteFile}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={css.actionButtonWrapper}>
            <Button variant="outlined-secondary" className={css.actionButton} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" className={css.actionButton}>
              Save
            </Button>
          </div>
        </form>
        <Loader open={isSubmitting} />
      </div>
    </Modal>
  );
};

export default VesselDispatchCard;
