import { memo } from 'react';
import css from './index.module.scss';
import { Typography } from '@components/base';
import { Avatar } from '@components/common';

interface POCDetailCardProps {
  name: string;
  email: string;
  phoneNumber: string;
  className?: string;
}

const POCDetailCard = (props: POCDetailCardProps) => {
  const { name, email, phoneNumber, className } = props;
  return (
    <div className={`${css.cardWrapper} ${className}`}>
      <Avatar src={''} alt={name} />
      <div className={css.contentWrapper}>
        <Typography variant="p">{name}</Typography>
        <Typography variant="span">{email}</Typography>
        <Typography variant="span">{phoneNumber}</Typography>
      </div>
    </div>
  );
};

export default memo(POCDetailCard);
