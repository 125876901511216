import React, { useEffect, useState } from 'react';
import css from './index.module.scss';
import Typography from '@components/base/typography';
import { Seo } from '@components/common';
import { useNavigate, useParams } from 'react-router-dom';
import {
  checkOrderEditFields,
  fetchEntityList,
  fetchOrderInfo,
  getDocThatWillBeModified,
  getPackagingListFilterOptions,
  orderEdit
} from '@services/order.service';
import { FormProvider, useForm } from 'react-hook-form';
import { IPackageSchema } from '@helpers/types/packing';
import FormWrapper from './components/form-wrapper';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { fetchCountryList } from '@services/util.service';
import notify from '@helpers/toastify-helper';
import { CONTAINER_TYPE } from '@helpers/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { editOrderSchema } from '@helpers/yup/add-order.schema';
import ConfirmationModal from './components/confirmation-modal';
import AccessWrapper from '@authorization/access-wrapper';
import _ from 'lodash';
import { BreadCrumb, Loader } from '@components/base';
import { generateQuantityFromPackingDetails, parseOrderEditFlag } from '@helpers/utils';
import { OrderInfo, OrderItem } from '@helpers/types/order';
import { ISelect } from '@helpers/types';
import { IContainer } from '@helpers/types/add-order';
import { INewCustomItem } from '../order-individual/components/order-overview/orderOverview';
import moment from 'moment';
import { ITaskEstimateObj } from '@helpers/types/pi';

interface OrderEditStates {
  isLoading: boolean;
  orderInfo: OrderInfo | null;
  countryList: ISelect[];
  packageTypes: ISelect[];
  unitOfWeights: ISelect[];
  openConfirmationModal: boolean;
  docToBeRegenerated: DocToBeRegeneratedObject[];
  requestBodyCopy: any;
  fieldsThatWillBeModified: FieldsThatWillBeModified;
}

export interface FieldsThatWillBeModified {
  consignee_details: boolean;
  port_of_loading: boolean;
  port_of_discharge: boolean;
  place_of_delivery: boolean;
  packages: boolean;
  containers: boolean;
  containers_count_data: boolean;
  custom_items: boolean;
  bl_date: boolean;
}

export interface DocToBeRegeneratedObject {
  order_document_id: string;
  document_type_display_value: string | null;
  display_name: string | null;
  document: string;
}

interface OrderEditFrom {
  consignee_same_as_customer: boolean;
  consignee_name: string;
  consignee_phone_number: string;
  consignee_landline: string;
  consignee_email_id: string;
  consignee_office_address: string;
  order_item: OrderItem[];
  containers_count_data: IContainer[];
  port_of_loading: ISelect | null;
  port_of_discharge: ISelect | null;
  place_of_delivery: string | null;
  containers: IContainerNumberObject[];
  container_editable: boolean;
  container_count_editable: boolean;
  custom_items: INewCustomItem[];
  bl_date: Date | null;
  awb_date: Date | null;
  bl_lower_limit: Date | null;
  bl_upper_limit: Date | null;
}

interface IContainerNumberObject {
  container_type: string | null;
  container_id: string | null;
  container_number: string | null;
}
//Return fields that are dirty based on react hook form dirtyFields boolean
const dirtyValues = (dirtyFields: any | boolean, allValues: any): any => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
  );
};

const getBLLowerLimitDate = (taskEstimates: ITaskEstimateObj[]) => {
  if (!taskEstimates) return null;
  const exportClearanceObj = taskEstimates?.find(
    (est: any) => est?.state_type == 'Export Clearance Obtained'
  );
  if (!exportClearanceObj) return null;
  if (exportClearanceObj?.actual_date) return new Date(exportClearanceObj?.actual_date);
  if (exportClearanceObj?.estimated_date_value)
    return new Date(exportClearanceObj?.estimated_date_value);
};

const getBLUpperLimitDate = (taskEstimates: ITaskEstimateObj[]) => {
  if (!taskEstimates) return null;
  const PODDate = taskEstimates?.find((est: any) => est?.state_type == 'Export Clearance Obtained');
  if (!PODDate) {
    const completedDate = taskEstimates?.find((est: any) => est?.state_type == 'Order Completed');
    if (!completedDate) return null;
    if (completedDate?.actual_date) return new Date(completedDate?.actual_date);
    if (completedDate?.estimated_date_value) return new Date(completedDate?.estimated_date_value);
  } else {
    if (PODDate?.actual_date) return new Date(PODDate?.actual_date);
    if (PODDate?.estimated_date_value) return new Date(PODDate?.estimated_date_value);
  }
};

const OrderEdit = (props: any) => {
  const params = useParams();
  const { id } = params;
  const { actions } = props.modulePermissions;

  const navigate = useNavigate();

  const [editOrderState, setEditOrderState] = useState<OrderEditStates>({
    isLoading: false,
    orderInfo: null,
    countryList: [],
    packageTypes: [],
    unitOfWeights: [],
    openConfirmationModal: false,
    docToBeRegenerated: [],
    requestBodyCopy: null,
    fieldsThatWillBeModified: {
      consignee_details: false,
      port_of_loading: false,
      port_of_discharge: false,
      place_of_delivery: false,
      packages: false,
      containers: false,
      containers_count_data: false,
      custom_items: false,
      bl_date: false
    }
  });

  const [dirtyFieldsArray, setDirtyFields] = useState<any>({
    order_item: []
  });

  const {
    orderInfo,
    isLoading,
    countryList,
    packageTypes,
    unitOfWeights,
    openConfirmationModal,
    docToBeRegenerated,
    requestBodyCopy,
    fieldsThatWillBeModified
  } = editOrderState;

  const editForm = useForm<OrderEditFrom>({
    resolver: yupResolver(editOrderSchema),
    defaultValues: {
      // elchemy_entity: null,
      consignee_same_as_customer: false,
      consignee_name: '',
      consignee_phone_number: '',
      consignee_landline: '',
      consignee_email_id: '',
      consignee_office_address: '',
      order_item: [
        {
          packages: [
            {
              type: null,
              no_of_package: null,
              weight: null,
              unit: null,
              order_item: null
            }
          ],
          product_name: '',
          order_item: null,
          selling_price: '',
          buying_price: '',
          selling_price_editable: false,
          buying_price_editable: false
        }
      ],

      containers_count_data: [
        {
          container_type: null,
          no_of_container: null
        }
      ],
      port_of_loading: null,
      port_of_discharge: null,
      place_of_delivery: null,
      containers: [
        {
          container_id: null,
          container_type: null,
          container_number: null
        }
      ],
      container_editable: false,
      container_count_editable: false,
      custom_items: [],
      bl_date: null,
      awb_date: null,
      //used to validate bl_date
      bl_lower_limit: null,
      //used to validate bl_date
      bl_upper_limit: null
    }
  });

  useEffect(() => {
    checkEditableFields();
  }, []);

  const checkEditableFields = async () => {
    setEditOrderState((prevState: OrderEditStates) => ({ ...prevState, isLoading: true }));
    const response = await checkOrderEditFields(id ?? '');
    setEditOrderState((prevState: OrderEditStates) => ({ ...prevState, isLoading: false }));
    if (response?.success) {
      if (!parseOrderEditFlag(response?.data)) {
        navigate('/404');
      } else {
        getOrderInfo(id as string);
        fetchFilterOptions();
        setEditOrderState((prevState: OrderEditStates) => ({
          ...prevState,
          fieldsThatWillBeModified: response?.data
        }));
      }
    } else {
      navigate('/404');
    }
  };

  const fetchFilterOptions = async () => {
    // const entityResponse = await fetchEntityList();
    setEditOrderState((prevState: OrderEditStates) => ({ ...prevState, isLoading: true }));
    const countryResponse = await fetchCountryList();
    const packingListResponse = await getPackagingListFilterOptions();
    if (countryResponse?.success && packingListResponse?.success) {
      setEditOrderState((prevState: OrderEditStates) => ({
        ...prevState,
        // entityList: entityResponse?.data?.results ?? [],
        countryList: countryResponse?.data?.filter_options ?? [],
        packageTypes: packingListResponse?.data?.filter_options?.package_types ?? [],
        unitOfWeights: packingListResponse?.data?.filter_options?.unit_of_weights ?? [],
        isLoading: false
      }));
    } else {
      setEditOrderState((prevState: OrderEditStates) => ({ ...prevState, isLoading: false }));
    }
  };

  const getOrderInfo = async (orderId: string) => {
    if (orderId) {
      setEditOrderState((prevState: OrderEditStates) => ({ ...prevState, isLoading: true }));
      const response = await fetchOrderInfo(id ?? '');
      if (response.success) {
        const { data } = response;

        setEditOrderState((prevState: OrderEditStates) => ({
          ...prevState,
          orderInfo: data,
          isLoading: false
        }));
        const containerTransformedArray: any = [];
        if (data?.container?.length) {
          data?.container.forEach((item: any) => {
            const { container_id, container_type, container_numbers } = item;

            container_id.forEach((id: string, index: number) => {
              containerTransformedArray.push({
                container_type: container_type,
                container_id: id,
                container_number: container_numbers[index]
              });
            });
          });
        }

        editForm.reset({
          // elchemy_entity: data.elchemy_entity ? data.elchemy_entity : null,
          consignee_same_as_customer: data.consignee_same_as_customer
            ? data.consignee_same_as_customer
            : false,
          consignee_name: data.consignee_name ? data.consignee_name : null,
          consignee_phone_number: data.consignee_phone_number ? data.consignee_phone_number : null,
          consignee_landline: data.consignee_landline ? data.consignee_landline : null,
          consignee_email_id: data.consignee_email_id ? data.consignee_email_id : null,
          consignee_office_address: data.consignee_office_address
            ? data.consignee_office_address
            : null,
          order_item: data.order_item
            ? data.order_item?.map((oi: any) => {
                return {
                  packages: oi.package?.map((p: IPackageSchema) => {
                    const obj: any = {};
                    if (p) {
                      obj['type'] = { label: p.type_label, value: p.type };
                      obj['no_of_package'] = p.no_of_package;
                      obj['weight'] = p.weight;
                      obj['unit'] = {
                        label: p?.unit_of_weight,
                        value: p?.unit_of_weight
                      };
                      obj['id'] = p.id;
                    }
                    return obj;
                  }),
                  product_name: oi.product_name,
                  order_item_id: oi.order_item_id,
                  selling_price: oi.selling_price,
                  buying_price: oi.buying_price,
                  selling_price_editable: !!oi?.selling_price?.length ?? false,
                  buying_price_editable: !!oi?.buying_price?.length ?? false,
                  quantity: generateQuantityFromPackingDetails(oi.package)
                };
              })
            : null,
          containers_count_data: data.container
            ? data.container?.map((item: any) => {
                return {
                  container_type: item.container_type
                    ? CONTAINER_TYPE.find((type: any) => type.value === item.container_type)
                    : null,
                  no_of_container: item.no_of_container ? item.no_of_container : null
                };
              })
            : [],

          port_of_loading: data.port_of_loading
            ? {
                label: `${data.port_of_loading_display_value} - ${data.port_of_loading_country_display_value}`,
                value: data.port_of_loading
              }
            : null,
          port_of_discharge: data.port_of_discharge
            ? {
                label: `${data.port_of_discharge_display_value} - ${data.port_of_discharge_country_display_value}`,
                value: data.port_of_discharge
              }
            : null,
          place_of_delivery: data.place_of_delivery ? data.place_of_delivery : null,
          containers: containerTransformedArray,
          container_editable: !!fieldsThatWillBeModified?.containers,
          container_count_editable: !!fieldsThatWillBeModified?.containers_count_data,
          custom_items: data.custom_items
            ? data.custom_items?.map((item: any) => {
                return {
                  name: item?.name ?? '',
                  description: item?.description ?? '',
                  rate: item?.rate ?? '',
                  quantity: item?.quantity ?? '',
                  amount: item?.amount ?? '',
                  custom_item_id: item?.custom_item_id ?? null
                };
              })
            : [],
          bl_date: data.bl_date ? new Date(data.bl_date) : null,
          awb_date: data.awb_date ? new Date(data.awb_date) : null,
          bl_lower_limit: getBLLowerLimitDate(data?.task_estimates),
          bl_upper_limit: getBLUpperLimitDate(data?.task_estimates)
        });
      } else {
        setEditOrderState((prevState: OrderEditStates) => ({ ...prevState, isLoading: false }));
        navigate('/404');
      }
    }
  };

  const onFormSubmit = async (data: any) => {
    const { dirtyFields } = editForm.formState;
    const editedFields = dirtyValues(dirtyFields, data);
    const body: any = {};

    let consignee_details = {};

    if ('consignee_same_as_customer' in dirtyFieldsArray) {
      consignee_details = {
        ...consignee_details,
        consignee_same_as_customer: dirtyFieldsArray?.consignee_same_as_customer
      };
    }

    if (dirtyFieldsArray?.consignee_name) {
      consignee_details = {
        ...consignee_details,
        consignee_name: dirtyFieldsArray?.consignee_name
      };
    }

    if (dirtyFieldsArray?.consignee_phone_number?.length) {
      consignee_details = {
        ...consignee_details,
        consignee_phone_number: dirtyFieldsArray?.consignee_phone_number
      };
    }

    if (dirtyFieldsArray?.consignee_landline?.length) {
      consignee_details = {
        ...consignee_details,
        consignee_landline: dirtyFieldsArray?.consignee_landline
      };
    }

    if (dirtyFieldsArray?.consignee_email_id?.length) {
      consignee_details = {
        ...consignee_details,
        consignee_email_id: dirtyFieldsArray?.consignee_email_id
      };
    }

    if (dirtyFieldsArray?.consignee_office_address?.length) {
      consignee_details = {
        ...consignee_details,
        consignee_office_address: dirtyFieldsArray?.consignee_office_address
      };
    }

    if (Object.keys(consignee_details).length) {
      body['consignee_details'] = consignee_details;
    }

    if (dirtyFieldsArray?.custom_items?.length) {
      body.custom_items = dirtyFieldsArray?.custom_items;
    } else if (data?.custom_items?.length != orderInfo?.custom_items?.length) {
      body.custom_items = data?.custom_items;
    }

    if (dirtyFieldsArray.order_item.length) {
      body.order_items = dirtyFieldsArray.order_item;
    }

    if (dirtyFieldsArray?.port_of_discharge?.value) {
      body.port_of_discharge = dirtyFieldsArray?.port_of_discharge?.value;
    }

    if (dirtyFieldsArray?.port_of_loading?.value) {
      body.port_of_loading = dirtyFieldsArray?.port_of_loading?.value;
    }

    if (dirtyFieldsArray?.place_of_delivery) {
      body.place_of_delivery = dirtyFieldsArray?.place_of_delivery;
    }

    if (dirtyFieldsArray?.bl_date) {
      body.bl_date = `${moment(dirtyFieldsArray.bl_date).format('YYYY-MM-DD')}`;
    }

    if (dirtyFieldsArray?.awb_date) {
      body.awb_date = `${moment(dirtyFieldsArray.awb_date).format('YYYY-MM-DD')}`;
    }

    if (dirtyFieldsArray?.containers_count_data?.length) {
      body.containers_count_data = dirtyFieldsArray?.containers_count_data?.map((item: any) => {
        return {
          container_type: item.container_type?.value,
          no_of_container: parseInt(item.no_of_container)
        };
      });
    } else if (data?.containers_count_data?.length != orderInfo?.container?.length) {
      body.containers_count_data = data?.containers_count_data?.length
        ? data?.containers_count_data?.map((item: any) => {
            return {
              container_type: item.container_type?.value,
              no_of_container: parseInt(item.no_of_container)
            };
          })
        : [];
    }

    if (dirtyFieldsArray?.containers?.length) {
      body.containers = dirtyFieldsArray?.containers;
    }

    setEditOrderState((prevState: OrderEditStates) => ({ ...prevState, isLoading: true }));

    const response = await getDocThatWillBeModified(orderInfo?.order_id ?? '', { data: body });
    if (response?.success) {
      setEditOrderState((prevState: OrderEditStates) => ({
        ...prevState,
        openConfirmationModal: true,
        docToBeRegenerated: response?.data ?? [],
        requestBodyCopy: body,
        isLoading: false
      }));
    } else {
      notify({
        severity: 'error',
        title: 'Error',
        message: response?.message ?? 'Something went wrong!'
      });
      setEditOrderState((prevState: OrderEditStates) => ({ ...prevState, isLoading: false }));
    }
  };

  const onCancelClick = () => {
    navigate(`/order/${id}?activeTab=order_overview`);
  };

  useBeforeUnloadAndNavigate();

  const onConfirmationModalSubmit = async () => {
    setEditOrderState((prevState: OrderEditStates) => ({ ...prevState, isLoading: true }));
    const response = await orderEdit(orderInfo?.order_id ?? '', { data: requestBodyCopy });
    setEditOrderState((prevState: OrderEditStates) => ({ ...prevState, isLoading: false }));
    if (response?.success) {
      notify({
        severity: 'success',
        title: 'Success',
        message: 'Order updated successfully'
      });
      navigate(`/order/${id}?activeTab=order_overview`);
    } else {
      notify({
        severity: 'error',
        title: 'Error',
        message: response?.message ?? 'Something went wrong!'
      });
    }
  };

  return (
    <AccessWrapper show={actions?.update}>
      <main className={css.mainWrapper}>
        <Seo title="Edit Order" />
        <div className={css.header}>
          <div className={css.orderTextWrapper}>
            <Typography variant="h2">Edit Order</Typography>
          </div>
          <BreadCrumb>
            <BreadCrumb.Item value={0} to={`/order`}>
              Order List
            </BreadCrumb.Item>
            <BreadCrumb.Item value={1} to={`/order/${orderInfo?.order_id}`}>
              {orderInfo?.readable_order_id}
            </BreadCrumb.Item>
            <BreadCrumb.Item value={2} to="">
              Edit
            </BreadCrumb.Item>
          </BreadCrumb>
        </div>

        <div className={css.orderFormContainer}>
          <FormProvider {...editForm}>
            <FormWrapper
              onFormSubmit={onFormSubmit}
              onCancelClick={onCancelClick}
              countryList={countryList}
              orderInfo={orderInfo}
              packageTypes={packageTypes}
              unitOfWeights={unitOfWeights}
              fieldsThatWillBeModified={fieldsThatWillBeModified}
              setDirtyFields={setDirtyFields}
              dirtyFieldsArray={dirtyFieldsArray}
            />
          </FormProvider>
        </div>
        {openConfirmationModal && (
          <ConfirmationModal
            open={openConfirmationModal}
            docToBeRegenerated={docToBeRegenerated}
            onClose={() =>
              setEditOrderState((prevState: OrderEditStates) => ({
                ...prevState,
                openConfirmationModal: false
              }))
            }
            onConfirm={onConfirmationModalSubmit}
          />
        )}
      </main>
      <Loader open={isLoading} />
    </AccessWrapper>
  );
};

export default OrderEdit;
