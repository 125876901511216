import * as yup from 'yup';

export const advancePaymentSchema = yup.object().shape({
  payment_reference_no: yup
    .string()
    .typeError('Invalid String')
    .required('Payment Reference Number field is required')
});

export const amountDetailsSchema = yup.object().shape({
  total_amount: yup.number().required('Total Amount field is required').typeError('Invalid Amount'),
  due_amount: yup.number().required('Due Amount field is required').typeError('Invalid Amount')
});

export const paymentTermsSchema = yup.object().shape({
  lc_number: yup.string().typeError('Invalid String').required('LC Number field is required'),
  lc_date: yup.string().typeError('Invalid String').required('LC Date field is required'),
  shipping_bill_number: yup
    .string()
    .typeError('Invalid String')
    .required('Shipping Bill Number field is required'),
  customer_lc_issuing_bank: yup.string().optional()
});

export const crlSchema = yup.object().shape({
  document_date: yup.date().typeError('Invalid Date Format').required('BL/AWB Date is required'),
  crl_remitted_amount: yup
    .number()
    .typeError('Due amount should be in number')
    .positive()
    .required(),
  crl_remitted_amount_in_words: yup
    .string()
    .typeError('CRL amount should be in string')
    .required('CRL amount in words is required field'),
  value_date: yup.date().typeError('Invalid Date Format').required('Value Date required'),
  customers_correspondent_bank_details: yup.string().optional(),
  purpose_of_remittance: yup.string().optional(),
  foreign_bank_charges: yup
    .string()
    .typeError('Invalid String')
    .required('Foreign bank exchange is required field'),
  advance_import_remittance: yup.boolean().required(),
  merchanting_trade_transaction: yup.boolean().required(),
  part_payment: yup.boolean().required().default(true),
  debit_from_cc_acc_no: yup.string().required(),
  amount_to_debit_from_cc: yup.string().required(),
  port_of_loading: yup.string().required(),
  forward_contract_no_and_date: yup.string().optional(),
  forward_contract_amount: yup.string().optional(),
  amount_to_be_utilized: yup.string().optional(),
  due_date_of_contract: yup.string().optional()
  // payment_reference_no: yup.string().optional(),
  // lc_number: yup.string().optional(),
  // lc_date: yup.string().optional(),
  // shipping_billing_no: yup.string().optional(),
});
