import css from './index.module.scss';
import { Typography, ToolTip, Chip, ToolTipProps } from '@components/base';
import { typographyVariantType } from '@helpers/types';

interface multiplePlaceholder {
  names: string[];
  label?: string;
  placement?: ToolTipProps['placement'];
  onClick?: () => void;
  typographyVariant?: typographyVariantType;
}

const MultiplePlaceholder = (props: multiplePlaceholder) => {
  const {
    names,
    label = 'Multiple',
    placement = 'right',
    onClick,
    typographyVariant = 'p'
  } = props;
  if (names && names.length > 0) {
    if (names.length > 1) {
      return (
        <ToolTip title={names.join(', ')} placement={placement}>
          <div className={css.orderNameChip} onClick={onClick}>
            <Chip label={label} clickable={true} />
          </div>
        </ToolTip>
      );
    } else {
      return <Typography variant={typographyVariant}>{names[0] ?? '-'}</Typography>;
    }
  } else {
    return <Typography variant={typographyVariant}>-</Typography>;
  }
};

export default MultiplePlaceholder;
