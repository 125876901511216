import React from 'react';
import { BoxContainer } from '@components/common';
import { Button } from '@components/base';
import OrderDetails from './components/Order';
import ItemDetails from './components/Item';
import Description from './components/Description';
import Notes from './components/Notes';
import { COAFormState } from '@helpers/types/coa';
import css from './index.module.scss';

interface PreviewPropsTypes {
  handleCancelClick: () => void;
  data: COAFormState;
  onSubmit: () => void;
  handleBackClick: (step: number) => void;
  handleNavigation: (step: number) => void;
}

const Preview = (props: PreviewPropsTypes) => {
  const { handleCancelClick, data, onSubmit, handleBackClick, handleNavigation } = props;
  return (
    <div className={css.coaFormWrapper}>
      <BoxContainer title="Preview">
        <div className={css.PreviewWrapper}>
          <OrderDetails data={data.orderDetails} handleNavigation={handleNavigation} />
          <ItemDetails data={data.itemDetails} handleNavigation={handleNavigation} />
          {data?.additionalDetails?.description?.length ? (
            <Description
              data={data.additionalDetails?.description}
              handleNavigation={handleNavigation}
            />
          ) : null}
          {data.additionalDetails?.notes?.length &&
          data.additionalDetails?.notes[0]?.field_value?.length ? (
            <Notes
              data={data.additionalDetails?.notes?.map((item: any) => item.field_value)}
              type="Notes"
              handleNavigation={handleNavigation}
            />
          ) : null}
          {data.additionalDetails?.remarks?.length &&
          data.additionalDetails?.remarks[0]?.field_value?.length ? (
            <Notes
              data={data.additionalDetails?.remarks?.map((item: any) => item.field_value)}
              type="Remarks"
              handleNavigation={handleNavigation}
            />
          ) : null}
          <div className={css.actionButtonWrapper}>
            <Button variant="text" onClick={handleCancelClick}>
              Cancel
            </Button>
            <div className={css.buttonContainer}>
              <Button variant="outlined-secondary" onClick={() => handleBackClick(2)}>
                Back
              </Button>
              <Button type="submit" onClick={onSubmit}>
                Save and Proceed
              </Button>
            </div>
          </div>
        </div>
      </BoxContainer>
    </div>
  );
};

export default Preview;
