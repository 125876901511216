import React from 'react';
import css from './index.module.scss';
import Divider from '@components/base/divider';
import { Typography } from '@components/base';

const PartyPreview = (props: any) => {
  const { purchaser, supplier, purchaseOrderData } = props;
  return (
    <div className={css.mainWrapper}>
      <div className={css.purchaserDetails}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Purchaser</Typography>
        </div>
        <div className={css.wrapper}>
          <Typography variant="h3">{purchaser.purchaser_name}</Typography>
          <Typography variant="span">{purchaser.address}</Typography>
          <Typography variant="span">
            GST Number:{purchaser.purchaser_gst_no ?? 'Not Available'}
          </Typography>
        </div>
      </div>
      <Divider className={css.divider} />
      <div className={css.supplierDetails}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Supplier</Typography>
        </div>
        <div className={css.wrapper}>
          <Typography variant="h3">{supplier?.supplier_name}</Typography>
          <Typography variant="span">
            {purchaseOrderData.supplier_factory_address?.readable_address ??
              supplier?.factory_address_list[0]?.readable_address}
          </Typography>
          <div>
            <Typography variant="span">{`GST Number - ${supplier?.supplier_gst_no}`}</Typography>
          </div>
          <div>
            <Typography variant="span">{`POC - ${purchaseOrderData.supplier_poc?.given_name} ${purchaseOrderData.supplier_poc?.family_name} - ${purchaseOrderData.supplier_poc?.email}`}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartyPreview;
