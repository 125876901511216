import css from './index.module.scss';
import React from 'react';
import Typography from '@components/base/typography';
import { IconNode } from '@components/base';
import Images from '@assets/images';
import { FieldValue } from '@helpers/types/coa';

interface OrderPropTypes {
  data: FieldValue[];
  handleNavigation: (index: number) => void;
}

const Order = (props: OrderPropTypes) => {
  const { data, handleNavigation } = props;
  return (
    <div className={css.boxWrapper}>
      <div className={css.orderContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="p">Order Details</Typography>
          <IconNode
            className={css.editIcon}
            src={Images.editRed}
            onClick={() => handleNavigation(0)}
          />
        </div>
        <div>
          {data?.map((item: any, index: number) => (
            <div className={css.orderItem} key={index}>
              <Typography variant="label">{item.field_name}</Typography>
              <Typography variant="p">{item.field_value}</Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Order;
