import css from './index.module.scss';
import { Typography } from '@components/base';

interface SearchPlaceholderProps {
  searchText: string;
}

const SearchPlaceholder = (props: SearchPlaceholderProps) => {
  const { searchText } = props;
  return (
    <div className={css.searchPlaceholderWrapper}>
      <Typography variant="h4">{`We couldn’t find any matches for “${searchText}”`}</Typography>
      <Typography variant="span" className={css.secondaryText}>
        The item you are looking for cannot be found. Try to search with a different name.
      </Typography>
    </div>
  );
};

export default SearchPlaceholder;
