import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { AppContext } from '@helpers/hooks/AppContext';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { getFormattedDate } from '@helpers/utils';
import moment from 'moment';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PODetails from './components/PI-details';
import css from './index.module.scss';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

interface TaskCardProps {
  taskData: Array<Partial<ITaskTabProps>>;
}

const ReviewApprovePOCard = (props: TaskCardProps) => {
  const { appDispatch } = useContext(AppContext);
  const params = useParams();
  const { taskData } = props;
  const taskItem = taskData[0];
  const navigate = useNavigate();

  const handleReviewClick = () => {
    appDispatch({
      type: 'setPIData',
      payload: { task_id: taskItem?.task_id, order_id: taskItem?.order }
    });
    navigate(
      `/order/purchase-order-review/${taskItem?.order ?? (params.id as string)}/${
        taskItem?.supplier_id
      }`
    );
  };

  const productList = taskItem?.supplier_product_names?.join(', ');

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskItem?.is_completed ? (
                <Avatar src={Images.illustrationEdit} alt="" />
              ) : (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              )}

              <Link to={`/order/${taskData[0]?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  #{taskData[0]?.readable_task_id} - Review Purchase Order{' '}
                </Typography>
              </Link>
            </div>

            <div className={css.pocWrapper}>
              {!taskItem?.is_completed && (
                <div className={css.beforeApproval}>
                  <Button
                    variant="outlined"
                    className={css.approveButton}
                    onClick={handleReviewClick}>
                    Review and Approve
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordionContent}>
          <div className={css.orderCardWrapper}>
            <div className={css.orderCardFirstWrapper}>
              <PODetails
                primaryTitle="Supplier"
                secondaryTitle={String(taskItem?.supplier_name)}
                showPrimaryIcon={false}
                showInfoIcon={false}
              />
              <PODetails
                primaryTitle="Product"
                secondaryTitle={String(productList)}
                showPrimaryIcon={false}
                showInfoIcon={false}
                style={{ borderRight: '1px solid var(--color-grey-1)' }}
              />
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskData[0]?.updated_at)?.diff(taskData[0]?.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};

export default ReviewApprovePOCard;
