import { ElementType, memo, useContext, ComponentPropsWithoutRef, ReactNode } from 'react';
import css from './index.module.scss';
import { Link, LinkProps } from 'react-router-dom';
import { BreadCrumbContext } from './breadcrumb';

interface BreadCrumbItemProps extends LinkProps {
  value: number;
}

const BreadCrumbItem = (props: BreadCrumbItemProps) => {
  const { children, value, ...otherProps } = props;
  const breadcrumbConsumer = useContext(BreadCrumbContext);

  const { separator, active } = breadcrumbConsumer;

  const activeClass = value === active ? `${css.breadCrumbActiveItem}` : '';

  return (
    <li
      className={`${css.breadcrumbItemWrapper} ${activeClass}
`}
      data-separator={separator}
      aria-current={value === active ? 'page' : undefined}>
      <Link
        {...otherProps}
        className={css.breadcrumbItem}
        tabIndex={value === active ? -1 : undefined}>
        {children}
      </Link>
    </li>
  );
};

BreadCrumbItem.defaultProps = {
  component: Link
};

export default memo(BreadCrumbItem);
