import css from './index.module.scss';
import { Button } from '@components/base';
import { useFormContext } from 'react-hook-form';
import { IBankDetailForm, ISupplier } from '@helpers/types/supplier';
import SupplierBankFields from './supplier-bank-fields';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';

interface SupplierBankDetailFormProps {
  editMode?: boolean;
  onFormSubmit: (data: IBankDetailForm) => void;
  onBackClick?: () => void;
  onCancelClick?: () => void;
  supplierData?: ISupplier | null;
}

const SupplierBankDetailForm = (props: SupplierBankDetailFormProps) => {
  const { onCancelClick, onFormSubmit, editMode = false } = props;
  useBeforeUnloadAndNavigate();

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty }
  } = useFormContext<IBankDetailForm>();

  return (
    <form className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <SupplierBankFields supplierData={props?.supplierData}/>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Proceed without adding bank details
        </Button>
        <div className={css.actionWrapper}>
          <Button type="submit" disabled={isSubmitting || (editMode ? !isDirty : false)}>
            {editMode ? 'Save' : 'Save and Proceed'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SupplierBankDetailForm;
