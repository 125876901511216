import { useCallback } from 'react';
import css from './index.module.scss';
import { Button, IconNode, Typography, Divider } from '@components/base';
import { BoxContainer, TextField, SelectLabel } from '@components/common';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ICustomerDetailForm } from '@helpers/types/customer';
import Images from '@assets/images';

interface POCDetailContainerProps {
  enableEditMode?: boolean;
  userList: Array<object>;
}

const POCDetailContainer = (props: POCDetailContainerProps) => {
  const { enableEditMode, userList } = props;
  const { control } = useFormContext<ICustomerDetailForm>();
  const { fields, append, remove } = useFieldArray({
    name: 'pocs',
    control: control
  });

  const addPocFields = useCallback(
    () => append({ uid: '', email: '', family_name: '', given_name: '', phone_number: '' }),
    []
  );

  return (
    <BoxContainer title="Point of Contact Details" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.fieldsContainer}>
          {fields.map((item, index) => {
            const handleDelete = () => remove(index);
            return (
              <POCFields
                key={item.id}
                index={index + 1}
                pocUid={item.id}
                userId={`pocs.${index}`}
                pocFirstName={`pocs.${index}.given_name`}
                pocLastName={`pocs.${index}.family_name`}
                pocPhoneNumber={`pocs.${index}.phone_number`}
                pocEmail={`pocs.${index}.email`}
                showDeleteButton={fields.length > 1}
                onDeleteClick={handleDelete}
                disabledEmailField={!!item.email && enableEditMode}
                userList={userList}
              />
            );
          })}
          <Button
            variant="text"
            startIcon={<IconNode src={Images.plusRed} alt="plus icon" />}
            className={css.addButton}
            onClick={addPocFields}>
            Add POC
          </Button>
        </div>
      </div>
    </BoxContainer>
  );
};

interface POCFieldsProps {
  index: number;
  pocFirstName: string;
  pocLastName: string;
  pocEmail: string;
  pocPhoneNumber: string;
  showDeleteButton: boolean;
  onDeleteClick: () => void;
  disabledEmailField?: boolean;
  pocUid?: string;
  userId: string;
  userList: Array<object>;
}

const POCFields = (props: POCFieldsProps) => {
  const {
    index,
    pocFirstName,
    pocLastName,
    pocPhoneNumber,
    pocEmail,
    showDeleteButton,
    onDeleteClick,
    disabledEmailField,
    pocUid,
    userId,
    userList = []
  } = props;
  const { control } = useFormContext();
  return (
    <div className={css.fieldsWrapper}>
      <div className={css.fieldsHeader}>
        <Typography variant="h5">POC {index}</Typography>
        {showDeleteButton && (
          <Button
            variant="text"
            onClick={onDeleteClick}
            startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}>
            Delete
          </Button>
        )}
      </div>
      <div className={css.rowWrapper}>
        <Controller
          name={userId}
          control={control}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              required
              isSearchable
              label="Select POC"
              placeholder="Select POC"
              options={userList}
              getOptionLabel={(option: any) =>
                `${option.given_name} ${option.family_name} ${option.email}`
              }
              getOptionValue={(option: any) => option}
              rootClassName={css.numberFieldWrapper}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
        {/* // <TextField
            //   {...field}
            //   required
            //   inputMode="text"
            //   enterKeyHint="next"
            //   autoComplete="given-name"
            //   label="POC First Name"
            //   placeholder="Enter first name"
            //   rootClassName={css.numberFieldWrapper}
            //   error={fieldState.invalid}
            //   helperText={fieldState.error?.message}
            //   endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            /> */}
        {/* <Controller
          name={pocFirstName}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              inputMode="text"
              enterKeyHint="next"
              autoComplete="given-name"
              label="POC First Name"
              placeholder="Enter first name"
              rootClassName={css.numberFieldWrapper}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        <Controller
          name={pocLastName}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              inputMode="text"
              enterKeyHint="next"
              autoComplete="family-name"
              label="POC Last Name"
              placeholder="Enter last name"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        /> */}
      </div>
      <div className={css.rowWrapper}>
        {/* <Controller
          name={pocPhoneNumber}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              type="tel"
              inputMode="tel"
              enterKeyHint="next"
              autoComplete="tel"
              label="POC Phone Number"
              placeholder="Enter Phone Number"
              rootClassName={css.numberFieldWrapper}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        /> */}
        {/* <Controller
          name={pocEmail}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              disabled={disabledEmailField}
              type="email"
              inputMode="email"
              enterKeyHint="next"
              autoComplete="email"
              label="POC Email address"
              placeholder="Enter Email address"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        /> */}
      </div>
      <Divider className={css.divider} />
    </div>
  );
};

export default POCDetailContainer;
