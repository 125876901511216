import { Button } from '@components/base';
import { BoxContainer, InputDatePicker, SelectLabel, TextField } from '@components/common';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { IOrderDetailsTypes, IPurchaseOrderDetailsProps } from '@helpers/types/pi';
import { Controller, useFormContext } from 'react-hook-form';
import css from './index.module.scss';
import { useEffect } from 'react';
import { getFormattedDate } from '@helpers/utils';

interface IPurchaseOrderDetails {
  purchaseOrderDetails: IOrderDetailsTypes;
  handleCancelClick: () => void;
  onBackClick: () => void;
  onFormSubmit: (data: any) => void; //IPurchaseOrderDetailsProps
  orderPi?: { [key: string]: any };
}

const PurchaseOrderDetailsTab = (props: IPurchaseOrderDetails) => {
  const { control, handleSubmit } = useFormContext();
  useBeforeUnloadAndNavigate();

  const { purchaseOrderDetails, handleCancelClick, onFormSubmit, onBackClick, orderPi } = props;
  return (
    <form noValidate className={css.purchaseOrderFormWrapper} onSubmit={handleSubmit(onFormSubmit)}>
      <BoxContainer title="Purchase Order Details">
        <div className={css.purchaserWrapper}>
          <div className={css.nameFieldWrapper}>
            <Controller
              name="po_reference_number"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  label="PO Reference Number"
                  placeholder="Enter Reference Number"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message ?? ''}
                />
              )}
            />
            <Controller
              name="document_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  value={field.value}
                  required
                  placeholder="Select date"
                  label="PO Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />
            {/* <TextField
              disabled
              rootClassName={css.fieldSpacing}
              label="PO Date"
              value={
                orderPi?.po_reference_date
                  ? getFormattedDate(orderPi?.po_reference_date)?.toString()
                  : '-'
              }
            /> */}
          </div>
          <div className={css.nameFieldWrapper}>
            <TextField
              disabled
              label="Port of Destination"
              placeholder="Port of Destination"
              value={
                purchaseOrderDetails?.port_of_discharge_display_value &&
                purchaseOrderDetails?.port_of_discharge_country_display_value
                  ? `${purchaseOrderDetails?.port_of_discharge_display_value} - ${purchaseOrderDetails?.port_of_discharge_country_display_value}`
                  : ''
              }
            />
            <SelectLabel
              label="Place of Delivery"
              isDisabled
              value={{
                label: purchaseOrderDetails?.place_of_delivery,
                value: purchaseOrderDetails?.place_of_delivery
              }}
            />
          </div>
        </div>
      </BoxContainer>
      <div className={css.formActionWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button onClick={onBackClick} variant="outlined-secondary">
            Back
          </Button>
          <Button type="submit">Save and Proceed</Button>
        </div>
      </div>
    </form>
  );
};

export default PurchaseOrderDetailsTab;
