import { getPermissionFromLocalStorage } from '@helpers/utils';
import httpClient from './index.service';
import { CUSTOMER_SERVER_CONFIG, SERVER_CONFIG, USER_TYPES } from '@helpers/constants';

async function activityLogs(order_id: any) {
  const userType = getPermissionFromLocalStorage()?.user_type;

  const URL =
    userType === USER_TYPES.customer
      ? `${CUSTOMER_SERVER_CONFIG.main}/${CUSTOMER_SERVER_CONFIG.order}/${order_id}/${CUSTOMER_SERVER_CONFIG.activityLog}/`
      : `${SERVER_CONFIG.order}/${order_id}/${SERVER_CONFIG.activityLog}?activity_log_type=internal`;

  try {
    const response = await httpClient.get(URL);
    return response;
  } catch (error: any) {
    return error;
  }
}

export default activityLogs;
