import { Typography, IconNode, Button, Modal } from '@components/base';
import { InputDatePicker, SelectLabel, TextField } from '@components/common';
import { Controller, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form';
import Images from '@assets/images';

import moment from 'moment';

import css from './index.module.scss';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { taskEstimateSchema } from '@helpers/yup/order-tasks.schema';
import { CURRENCY } from '@helpers/constants';

interface RodtepAndDrawbackEditProps {
  open: boolean;
  onClose: () => void;
  onFormSubmit: (data: any) => void;
}

const RodtepAndDrawbackEdit = (props: RodtepAndDrawbackEditProps) => {
  const { open, onClose, onFormSubmit } = props;

  const { handleSubmit, control } = useFormContext();

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Edit Details</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>

        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
          <section className={css.modalContent}>
            <Controller
              control={control}
              name="rodtep_currency"
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  label="Rodtep Currency"
                  placeholder="Select Date"
                  options={CURRENCY}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.inputField}
                />
              )}
            />
            <Controller
              control={control}
              name="rodtep_amount"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Rodtep Amount"
                  placeholder="Enter Number"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                  rootClassName={css.inputField}
                />
              )}
            />
            <Controller
              control={control}
              name="rodtep_credit_date"
              render={({ field }) => (
                <InputDatePicker
                  {...field}
                  label="Rodtep Credit Date"
                  placeholder="Select Date"
                  rootClassName={css.inputField}
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                />
              )}
            />
            <Controller
              control={control}
              name="rodtep_percentage_claimed"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Rodtep Percentage Claimed"
                  placeholder="Enter Number"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                  rootClassName={css.inputField}
                />
              )}
            />
            <Controller
              control={control}
              name="drawback_currency"
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  label="Drawback Currency"
                  placeholder="Select Date"
                  options={CURRENCY}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.inputField}
                />
              )}
            />
            <Controller
              control={control}
              name="drawback_amount"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Drawback Amount"
                  placeholder="Enter Number"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                  rootClassName={css.inputField}
                />
              )}
            />
            <Controller
              control={control}
              name="drawback_credit_date"
              render={({ field }) => (
                <InputDatePicker
                  {...field}
                  label="Drawback Credit Date"
                  placeholder="Select Date"
                  rootClassName={css.inputField}
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                />
              )}
            />
          </section>

          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </section>
        </form>
      </div>
    </Modal>
  );
};

export default RodtepAndDrawbackEdit;
