import { Button, Typography } from '@components/base';
import css from './index.module.scss';

interface BankPlaceholderProps {
  onAddClick?: () => void;
  className?: string;
}

const BankPlaceholder = (props: BankPlaceholderProps) => {
  const { onAddClick, className } = props;
  return (
    <div className={`${css.placeholderContainer} ${className}`}>
      <Typography variant="span" className={css.titleHeader}>
        No Bank Details Added Yet
      </Typography>
      <Typography variant="span" className={css.descriptionHeader}>
        When bank details are added by you, they will show up here.
      </Typography>
      <Button onClick={onAddClick}>Add Bank Details</Button>
    </div>
  );
};

export default BankPlaceholder;
