import css from './index.module.scss';
import React, { useMemo } from 'react';
import { Typography, IconNode, Button } from '@components/base';
import { BoxContainer, DataGrid } from '@components/common';
import Images from '@assets/images';
import { FieldValue } from '@helpers/types/coa';
import { getFormattedDate } from '@helpers/utils';
import { PackagingItem } from '../..';

interface ItemDetailsPropTypes {
  data: PackagingItem[];
  handleNavigation: (step: number) => void;
  handleCancelClick: () => void;
  handlePreviewSubmit: () => void;
}

const LabelPreview = (props: ItemDetailsPropTypes) => {
  const { data, handleNavigation, handleCancelClick, handlePreviewSubmit } = props;

  const tableDataSource = useMemo(() => {
    const dataSource: any = [];
    data?.forEach((item: any) => {
      item.packaging_document_data.forEach((pack: any) => {
        const temp: any = {};
        temp['no_of_package'] = `${pack.no_of_packages}`;
        temp['batch'] = pack.batch_number.batch_number;
        temp['manufacturing_date'] = getFormattedDate(pack.manufacturing_date);
        temp['expiry_date'] = getFormattedDate(pack.expiry_date);
        temp['packing'] = `${pack.package_details?.package_display_name}`;
        temp['gross_wt'] = `${pack.gross_wt} KG`;
        temp['tare_wt'] = `${pack.tare_wt} KG`;
        temp['net_wt'] = `${pack.net_wt} KG`;
        temp['item_name'] = item.name;
        temp['item_id'] = item.order_item_id;
        temp['drum_no'] = `${pack.start_package_no} / ${pack.end_package_no}`;
        dataSource.push(temp);
      });
    });
    return dataSource;
  }, [data]);

  const [itemDataColumn, itemDataRow] = useMemo(() => {
    const columns: any = [
      {
        Header: 'Sr No',
        accessor: 'index'
      },
      {
        Header: 'Item Name',
        accessor: 'item_name'
      },
      {
        Header: 'No. of Package',
        accessor: 'no_of_package'
      },
      {
        Header: 'Batch',
        accessor: 'batch'
      },
      {
        Header: 'Manufacturing Date',
        accessor: 'manufacturing_date'
      },
      {
        Header: 'Expiry Date',
        accessor: 'expiry_date'
      },
      {
        Header: 'Drum No',
        accessor: 'drum_no'
      },
      {
        Header: 'Packing',
        accessor: 'packing'
      },
      {
        Header: 'Gross Wt.',
        accessor: 'gross_wt'
      },
      {
        Header: 'Tare Wt.',
        accessor: 'tare_wt'
      },
      {
        Header: 'Net Wt.',
        accessor: 'net_wt'
      }
    ];

    const rows: any = tableDataSource?.length
      ? tableDataSource?.map((item: any, index: number) => {
          return {
            index: index + 1,
            item_name: item.item_name,
            no_of_package: item.no_of_package,
            batch: item.batch,
            manufacturing_date: item.manufacturing_date,
            expiry_date: item.expiry_date,
            packing: item.packing,
            gross_wt: item.gross_wt,
            tare_wt: item.tare_wt,
            net_wt: item.net_wt,
            drum_no: item.drum_no
          };
        })
      : [];

    return [columns, rows];
  }, [data]);

  return (
    <div className={css.mainWrapper}>
      <div className={css.previewContainer}>
        <Typography variant="h2">Label Preview</Typography>
        <DataGrid tableStyle={{ width: '100%' }} columns={itemDataColumn} data={itemDataRow} />
      </div>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button variant="outlined-secondary" onClick={() => handleNavigation(1)}>
            Back
          </Button>
          <Button type="submit" onClick={handlePreviewSubmit}>
            Save and Proceed
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LabelPreview;
