import css from './index.module.scss';
import { Button, Typography } from '@components/base';
import { useFormContext } from 'react-hook-form';
import ProductDetailContainer from './product-detail-container';
import { IProductForm } from '@helpers/types/product';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';

interface ProductDetailFormProps {
  editMode?: boolean;
  onFormSubmit: (product: IProductForm) => void;
  onCancelClick?: () => void;
}

const ProductDetailForm = (props: ProductDetailFormProps) => {
  const { editMode, onFormSubmit, onCancelClick } = props;
  useBeforeUnloadAndNavigate();

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty }
  } = useFormContext<IProductForm>();
  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <Typography variant="h2">Product Details</Typography>
      <ProductDetailContainer editMode={editMode} />
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting || (editMode ? !isDirty : false)}>
          Save and Proceed
        </Button>
      </div>
    </form>
  );
};

export default ProductDetailForm;
