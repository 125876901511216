/* eslint-disable @typescript-eslint/no-empty-function */
import Images from '@assets/images';
import { Button, IconNode, Modal, Typography } from '@components/base';
import TextareaLabel from '@components/common/textarea-label';
import { useForm } from 'react-hook-form';
import css from './index.module.scss';

interface RejectModalProps {
  open: boolean;
  onClose?: (open: boolean) => void;
  onFormSubmit?: (data: any) => void;
}

const RejectPIModal = (props: RejectModalProps) => {
  const { open, onFormSubmit = () => {}, onClose = () => {} } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      rectify_remarks: ''
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h4">Write your reasons to reject this Order</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="div"
            className={css.closeButton}
            onClick={(open) => onClose(!open)}
          />
        </div>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
          <div className={css.fieldsWrapper}>
            <TextareaLabel
              rootClassName={css.fieldSpacing}
              placeholder="Enter a description.."
              className={css.textArea}
              {...register('rectify_remarks', {
                required: {
                  value: true,
                  message: 'Field is required!'
                },
                maxLength: {
                  value: 250,
                  message: 'Maximum length exceeded!'
                }
              })}
              error={!!errors.rectify_remarks?.message}
              helperText={errors?.rectify_remarks?.message}
              endIcon={
                !!errors.rectify_remarks?.message && (
                  <IconNode src={Images.alertError} alt="error icon" />
                )
              }
            />
          </div>
          <Button
            type="submit"
            variant="outlined-secondary"
            className={css.rightButton}
            disabled={isSubmitting}>
            Reject
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default RejectPIModal;
