import * as yup from 'yup';
import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from '@helpers/constants';

const selectSchema = yup.object().shape({
  value: yup.string(),
  label: yup.string()
});

export const enquiryDetailsSchema = yup.object().shape({
  place_of_delivery: yup.string().optional(),
  remarks: yup.string().optional(),
  products: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Product name is required'),
      currency: selectSchema.nullable().when('target_price', {
        is: (value: any) => !!value,
        then: (schema) => schema.required('Currency is required when target price is specified'),
        otherwise: (schema) => schema
      }),
      target_price: yup.number().typeError('Target price must be a number').nullable().transform((value, originalValue) => (originalValue === '' ? null : value)),
      quantity: yup.number().typeError('Quantity must be a number').nullable().transform((value, originalValue) => (originalValue === '' ? null : value)),
      unit: selectSchema.nullable().when('quantity', {
        is: (value: any) => !!value,
        then: (schema) => schema.required('Unit is required when quantity is specified'),
        otherwise: (schema) => schema
      })
    })
  )
});
