import React, { useMemo, useRef, useState } from 'react';
import css from './index.module.scss';
import { Chip, Modal, ToolTip } from '@components/base';
import Typography from '@components/base/typography';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';
import Button from '@components/base/button';
import { CellProps, Column } from 'react-table';
import Checkbox from '@components/base/checkbox/checkbox';
import { DataGrid, DocumentRow, ListPlaceholder } from '@components/common';
import { FormProvider, useForm } from 'react-hook-form';
import UploadOrderDocument from '@components/common/upload-order-document';
import { addOtherDocument } from '@helpers/yup/add-document.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { IFilterDocument } from '@helpers/types/email';

interface formType {
  type: any;
  internal_tags: Array<any>;
  name: string | null;
  document_type: string;
  remarks: string | null;
  document_object: File | null;
  supplier: any;
  order_item: any;
  order_document_id: string | null;
  product_required: boolean;
  supplier_required: boolean;
}

interface IRowGrid {
  check_box: IFilterDocument;
  doc_name: IFilterDocument;
  doc_link: IFilterDocument;
}

const OrderDocModal = (props: any) => {
  const {
    open,
    onClose,
    orderDocumentList,
    onDocSelection,
    orderPhotoList,
    onPhotoSelection,
    handleAddStuffingPhotosSubmit,
    orderId,
    onAddDocumentClose,
    openAddDocumentModal,
    setOpenAddDocumentModal
  } = props;

  const inputRef = useRef<any>(null);
  const [orderAttachments, setOrderAttachments] = useState<any>({
    state: 0
  });
  const { state } = orderAttachments;
  const [selectedDocList, setSelectedDocList] =
    useState<any>(orderDocumentList?.filter((doc: any) => doc?.check_box)) || [];
  const [selectedPhotoList, setSelectedPhotoList] =
    useState<any>(orderPhotoList?.filter((photo: any) => photo?.check_box)) || [];

  const selectUnselectDoc = (e: any, doc: any) => {
    const copyOfOldSelectedDocs = selectedDocList;
    if (e.target.checked) {
      copyOfOldSelectedDocs.push(doc);
    } else {
      const index = copyOfOldSelectedDocs.indexOf(doc);
      if (index > -1) {
        copyOfOldSelectedDocs.splice(index, 1);
      }
    }
    setSelectedDocList(copyOfOldSelectedDocs);
  };

  const selectUnselectPhotos = (photo: any) => {
    const copyOfOldSelectedPhotos = [...selectedPhotoList];
    const photoIndex = copyOfOldSelectedPhotos.indexOf(photo);
    if (photoIndex > -1) {
      copyOfOldSelectedPhotos.splice(photoIndex, 1);
    } else {
      copyOfOldSelectedPhotos.push(photo);
    }
    setSelectedPhotoList(copyOfOldSelectedPhotos);
  };

  const [containerDataColumn, containerDataRow] = useMemo(() => {
    const column: any = [
      {
        Header: '',
        accessor: 'check_box',
        Cell: (props: CellProps<IRowGrid>) => {
          const { value } = props;
          return (
            <>
              {value?.url?.length ? (
                <Checkbox
                  defaultChecked={value.check_box}
                  onChange={(e) => selectUnselectDoc(e, value)}
                  disabled={value?.is_inconsistent}
                />
              ) : (
                <></>
              )}
            </>
          );
        }
      },
      {
        Header: 'Document Name',
        accessor: 'doc_name',
        Cell: (props: CellProps<IRowGrid>) => {
          const { value } = props;
          return (
            <div className={css.documentNameWrapper}>
              <Typography variant="pdoc">
                {value?.name} - {value?.type}
              </Typography>

              {value?.is_inconsistent && (
                <ToolTip placement="right" title="Document is inconsistent, please resolve it">
                  <div>
                    <IconNode src={Images.alertWarning} alt="Warning" />
                  </div>
                </ToolTip>
              )}
            </div>
          );
        }
      },
      {
        Header: '',
        accessor: 'doc_link',
        Cell: (props: CellProps<IRowGrid>) => {
          const { value } = props;
          let docUrl = value?.url;
          if (value?.is_inconsistent) {
            docUrl = null;
          }
          return (
            <DocumentRow.View showEyeIconWhenDocumentMissing={true} document={docUrl} title="" />
          );
        }
      }
    ];
    const rows: IRowGrid[] = orderDocumentList?.length
      ? orderDocumentList?.map((doc: IFilterDocument) => ({
          check_box: doc,
          doc_name: doc,
          doc_link: doc
        }))
      : [];
    return [column, rows];
  }, [orderDocumentList]);

  const imageLinks = useMemo(() => {
    const links: any = [];
    orderPhotoList?.forEach((photo: any) => {
      if (photo?.url?.length) {
        links.push(photo);
      }
    });
    return links;
  }, [orderPhotoList]);

  const handleClick = () => {
    state === 1 && inputRef?.current?.click();
    state === 0 && setOpenAddDocumentModal(true);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Select Documents to be attached</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <div className={css.formWrapper}>
          <div className={css.formHeader}>
            <div className={css.modalChipWrapper}>
              <Chip
                label="Documents"
                variant={state === 0 ? `filled` : `outlined`}
                onClick={() =>
                  setOrderAttachments((prevState: any) => ({ ...prevState, state: 0 }))
                }
              />
              <Chip
                label="Stuffing Photos"
                variant={state === 1 ? `filled` : `outlined`}
                onClick={() =>
                  setOrderAttachments((prevState: any) => ({ ...prevState, state: 1 }))
                }
              />
            </div>
            <div className={css.formHeaderButton}>
              <Button variant="contained" onClick={handleClick}>
                {state === 0 ? `Add Document` : `Add Stuffing Photo`}
              </Button>
              {state === 1 && (
                <input
                  style={{ display: 'none' }}
                  ref={inputRef}
                  type="file"
                  onChange={handleAddStuffingPhotosSubmit}
                  multiple
                  accept=".jpg,.jpeg,.png"
                />
              )}
              {state === 0 && openAddDocumentModal && (
                <UploadOrderDocument
                  open={openAddDocumentModal}
                  onClose={onAddDocumentClose}
                  orderId={orderId}
                  uploadType="order_document"
                />
              )}
            </div>
          </div>
          <section className={css.modalContent}>
            {state === 0 &&
              (orderDocumentList.length > 0 ? (
                <DataGrid
                  columns={containerDataColumn}
                  data={containerDataRow}
                  tableStyle={{ width: '100%' }}
                />
              ) : (
                <ListPlaceholder
                  title="No Documents Added Yet"
                  supportingText="When documents are added by you, they will show up here."
                  buttonText=""
                  to={``}
                />
              ))}
            {state === 1 && (
              <ul>
                {imageLinks.length > 0 ? (
                  imageLinks.map((link: any, index: number) => (
                    <li key={index}>
                      {/* //try to use in house input */}
                      <input
                        type="checkbox"
                        id={`myCheckbox${index + 1}`}
                        checked={selectedPhotoList.includes(link)}
                        onChange={() => selectUnselectPhotos(link)}
                      />
                      <label htmlFor={`myCheckbox${index + 1}`}>
                        <img src={link.url} alt="image" />
                      </label>
                    </li>
                  ))
                ) : (
                  <ListPlaceholder
                    title="No Stuffing Photos Added Yet"
                    supportingText="When photos are added by you, they will show up here."
                    buttonText=""
                    to={``}
                  />
                )}
              </ul>
            )}
          </section>
        </div>

        <section className={css.modalFooter}>
          <Button variant="outlined-secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onDocSelection(selectedDocList);
              onPhotoSelection(selectedPhotoList);
              onClose();
            }}>
            Save
          </Button>
        </section>
      </div>
    </Modal>
  );
};
export default OrderDocModal;
