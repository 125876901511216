/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { ChangeEvent, Fragment, useCallback, useEffect, useRef } from 'react';
import css from './index.module.scss';
import { Button, CheckboxLabel, Divider, IconNode, Typography } from '@components/base';
import { BoxContainer, AddressFields } from '@components/common';
import { useFormContext, useFieldArray, useWatch, Controller } from 'react-hook-form';
import { ISupplierDetailForm } from '@helpers/types';
import Images from '@assets/images';

const SupplierAddressFields = () => {
  const firstMount = useRef(true);
  const { control, getValues, resetField, setValue } = useFormContext<ISupplierDetailForm>();
  const watchFields = useWatch({
    name: [
      'same_as_office',
      'address_line1',
      'address_line2',
      'zip_code',
      'city',
      'state',
      'country',
      'address_suffix'
    ],
    control
  });

  const { fields, append, remove, update } = useFieldArray<ISupplierDetailForm>({
    name: 'addresses',
    control
  });

  useEffect(() => {
    if (watchFields[0] && !firstMount.current) {
      const firstAddressId = getValues('addresses.0').address_id;
      resetField('addresses.0', {
        defaultValue: {
          address_id: firstAddressId,
          address_type: 'FAC',
          address_line1: watchFields[1] || '',
          address_line2: watchFields[2] || '',
          zip_code: watchFields[3] || '',
          city: watchFields[4] ?? null,
          state: watchFields[5] ?? null,
          country: watchFields[6] ?? null,
          address_suffix: watchFields[7] ?? null
        },
        keepDirty: false,
        keepError: false,
        keepTouched: false
      }); // resetField is used intentionally here to prevent dirty changes while "form EDIT"
      setValue('addresses.0.city', watchFields[4] ?? null, { shouldDirty: false });
      setValue('addresses.0.state', watchFields[5] ?? null, { shouldDirty: false });
      setValue('addresses.0.country', watchFields[6] ?? null, { shouldDirty: false });
    }
    return () => {
      firstMount.current = false; //used intentionally to prevent firstMount update
    };
  }, [watchFields]);

  const handleAddFactoryAddress = useCallback(() => {
    append({
      address_id: '',
      address_type: 'FAC',
      address_line1: '',
      address_line2: '',
      zip_code: '',
      city: null,
      state: null,
      country: null,
      address_suffix: null
    });
  }, [fields]);

  const handleCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.currentTarget.checked;
    if (!checked) {
      const firstAddressId = getValues('addresses.0').address_id;
      update(0, {
        address_id: firstAddressId,
        address_type: 'FAC',
        address_line1: '',
        address_line2: '',
        zip_code: '',
        city: null,
        state: null,
        country: null,
        address_suffix: null
      });
    }
    setValue('same_as_office', !!checked);
  }, []);

  return (
    <BoxContainer title="Address">
      <div className={css.supplierInfoWrapper}>
        <Typography variant="h4">Office Address</Typography>
        <AddressFields
          firstAddressLine="address_line1"
          secondAddressLine="address_line2"
          zipCode="zip_code"
          city="city"
          state="state"
          country="country"
          suffix="address_suffix"
        />
        <Divider className={css.supplierFactoryAddressDivider} />
        <div className={css.supplierFactoryTextWrapper}>
          <Typography variant="h4">Factory Address</Typography>
          <Controller
            name="same_as_office"
            control={control}
            render={({ field }) => (
              <CheckboxLabel
                {...field}
                label="Same as Office Address"
                value={`${field.value}`}
                checked={field.value}
                onChange={handleCheckboxChange}
              />
            )}
          />
        </div>
        {fields.map((fld, index) => {
          const deleteButtonState: boolean = (fld as any).address_id ? true : false;
          const handleDelete = () => remove(index);
          return (
            <Fragment key={fld.id}>
              {index > 0 && (
                <div className={css.supplierAddressDeleteContainer}>
                  <Typography variant="h4">{`Factory Address ${index + 1}`}</Typography>
                  <Button
                    variant="text"
                    startIcon={<IconNode src={Images.deleteRed} alt="Delete Icon" />}
                    style={{ maxWidth: 'fit-content', alignSelf: 'flex-end' }}
                    onClick={handleDelete}>
                    Delete
                  </Button>
                </div>
              )}
              <AddressFields
                firstAddressLine={`addresses.${index}.address_line1`}
                secondAddressLine={`addresses.${index}.address_line2`}
                zipCode={`addresses.${index}.zip_code`}
                city={`addresses.${index}.city`}
                state={`addresses.${index}.state`}
                country={`addresses.${index}.country`}
                disabled={index === 0 && watchFields[0]}
                suffix={`addresses.${index}.address_suffix`}
              />
              <Divider className={css.supplierFactoryAddressDivider} />
            </Fragment>
          );
        })}
        <Button
          variant="text"
          startIcon={<IconNode src={Images.plusRed} alt="plus red icon" />}
          style={{ maxWidth: 'fit-content' }}
          onClick={handleAddFactoryAddress}>
          Add Factory Address
        </Button>
      </div>
    </BoxContainer>
  );
};

export default SupplierAddressFields;
