import { Fragment, memo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import css from './index.module.scss';

interface NavItemsProps {
  items: NavLinkProps[];
}

const NavItems = (props: NavItemsProps) => {
  const { items } = props;
  return (
    <Fragment>
      {items.map((item: NavLinkProps, index) => (
        <NavItem key={index} {...item} />
      ))}
    </Fragment>
  );
};

const NavItem = (props: NavLinkProps) => {
  const handleClass = ({ isActive }: { isActive: boolean }) =>
    isActive ? css.navActive : css.navInactive;

  return (
    <li className={css.navItem}>
      <NavLink {...props} className={handleClass}>
        {props.children}
      </NavLink>
    </li>
  );
};

export default memo(NavItems);
