import css from './index.module.scss';
import React, { useMemo } from 'react';
import { Typography, IconNode } from '@components/base';
import { DataGrid } from '@components/common';
import Images from '@assets/images';
import { FieldValue } from '@helpers/types/coa';
import { generateReadablePackingDetails } from '@helpers/utils';

interface ItemDetailsProps {
  data: {
    items: FieldValue[];
  };
  handleNavigation: (index: number) => void;
}

const ItemDetails = (props: ItemDetailsProps) => {
  const { data, handleNavigation } = props;
  const { items } = data;
  const [itemDataColumn, itemDataRow] = useMemo(() => {
    const columns: any = [
      {
        Header: 'Sr No',
        accessor: 'index'
      },
      {
        Header: 'Item Name',
        accessor: 'item_name'
      }
    ];

    const rows: any = items?.length
      ? items?.map((item: any, index: number) => {
          return {
            index: index + 1,
            item_name: (
              <>
                <Typography variant="p">{item.name}</Typography>{' '}
                {item?.package?.length ? (
                  <>
                    <br />
                    <Typography variant="label">
                      Packaging Details: {generateReadablePackingDetails(item.package).join(', ')}
                    </Typography>
                  </>
                ) : (
                  <>
                    <br />
                    <Typography variant="label">Packaging details not available</Typography>
                  </>
                )}
              </>
            )
          };
        })
      : [];

    return [columns, rows];
  }, [items]);

  return (
    <div className={css.boxWrapper}>
      <div className={css.orderContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="p">Item Details</Typography>
          {handleNavigation && (
            <IconNode
              className={css.editIcon}
              src={Images.editRed}
              onClick={() => handleNavigation(0)}
            />
          )}
        </div>
        <div className={css.rowWrapper}>
          <DataGrid tableStyle={{ width: '100%' }} columns={itemDataColumn} data={itemDataRow} />
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
