import css from './index.module.scss';
import { Button, IconNode, Loader, Typography } from '@components/base';
import { TextField, SelectLabel } from '@components/common';
import { Controller, useForm } from 'react-hook-form';
import Images from '@assets/images';
import { yupResolver } from '@hookform/resolvers/yup';
import { pocSchema } from '@helpers/yup/add-customer.schema';
import { IPointOfContact } from '@helpers/types/point-of-contact';

interface POCAddProps {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  userList: Array<object>;
}

const POCAdd = (props: POCAddProps) => {
  const { onSubmit, onCancel, userList } = props;
  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm<Omit<IPointOfContact, 'uid'>>({
    resolver: yupResolver(pocSchema),
    defaultValues: {
      user_id: '',
      email: '',
      given_name: '',
      family_name: '',
      phone_number: ''
    }
  });

  return (
    <div className={css.mainWrapper}>
      <Typography variant="h4">Add POC</Typography>
      <form noValidate onSubmit={handleSubmit(onSubmit)} className={css.formWrapper}>
        <div className={css.fieldsWrapper}>
          <Controller
            name="user_id"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required
                isSearchable
                label="Select POC"
                placeholder="Select POC"
                defaultValue={null}
                options={userList}
                getOptionLabel={(option: any) =>
                  `${option.given_name} ${option.family_name}  ${option.email}`
                }
                getOptionValue={(option: any) => option.user_id}
                rootClassName={css.numberFieldWrapper}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
          {/* <Controller
            name="given_name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                autoFocus
                inputMode="text"
                enterKeyHint="next"
                autoComplete="given-name"
                label="First Name"
                placeholder="Enter first name"
                error={fieldState.invalid}
                helperText={fieldState.invalid && fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <Controller
            name="family_name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                inputMode="text"
                enterKeyHint="next"
                autoComplete="family-name"
                label="Last Name"
                placeholder="Enter last name"
                error={fieldState.invalid}
                helperText={fieldState.invalid && fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                type="email"
                inputMode="email"
                enterKeyHint="next"
                autoComplete="email"
                label="Email address"
                placeholder="Enter Email address"
                error={fieldState.invalid}
                helperText={fieldState.invalid && fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <Controller
            name="phone_number"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                type="tel"
                inputMode="tel"
                enterKeyHint="next"
                autoComplete="tel"
                label="Phone Number"
                placeholder="Enter Phone Number"
                error={fieldState.invalid}
                helperText={fieldState.invalid && fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          /> */}
        </div>
        <div className={css.actionButtonWrapper}>
          <Button variant="outlined-secondary" disabled={isSubmitting} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </form>
      <Loader open={isSubmitting} />
    </div>
  );
};

export default POCAdd;
