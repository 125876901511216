import httpClient from './index.service';
import { SERVER_CONFIG } from '@helpers/constants';

export async function getFilterOptions(orderId: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.formFilterOptions.emailCompose}`
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getTemplateDetails(orderId: string, templateId: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.templateDetails}/${templateId}`
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getEmailHistory(orderId: string, query: number[]) {
  let queryParams = '';
  query.forEach((item) => {
    queryParams += `status=${item}&`;
  });
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${orderId}/emails?${queryParams}`
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function sendEmailService(orderId: string, body: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.sendEmail}/`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getEmailDetails(orderId: string, emailId: string) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.order}/${orderId}/email/${emailId}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function sendNudge(orderId: string, emailId: string | undefined, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${orderId}/email/${emailId}/`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}
