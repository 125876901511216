import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { AppContext } from '@helpers/hooks/AppContext';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import notify from '@helpers/toastify-helper';
import { createLabel, getLabelData, updateLabel } from '@services/task.service';
import { Loader, Typography } from '@components/base';
import { SideBar } from '@components/common';
import css from './index.module.scss';
import BatchForm from '@pages/order/add-batch-number/components/batch-form';
import ItemDetails from './components/add-packing-details';
import moment from 'moment';
import { CLIENT_ROUTES } from '@router/routes';
import {
  addBatchNumberLabelGenerationSchema,
  packingListSchema
} from '@helpers/yup/add-label-generation.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import LabelPreview from './components/label-preview';
import { IProduct, PackageData } from '@helpers/types/label';

interface Batch {
  batch_number: string | null;
  manufacturing_date: Date | string | null;
  expiry_date: Date | string | null;
  id?: string | null;
}

export interface Item {
  name: string | null;
  order_item_id: string | null;
  batch: Batch[];
}

interface BatchFormValues {
  items: Item[];
}

interface PackagingDocumentData {
  package_details: PackageData | null;
  batch_number: Batch | null;
  start_package_no: number | null;
  end_package_no: number | null;
  tare_wt: number | null;
  unit_of_tare_wt: string;
  gross_wt: number | null;
  no_of_packages: number | null;
  net_wt: number | null;
}

export interface PackagingItem {
  name: string | null;
  order_item_id: string | null;
  handling_and_storage: string;
  packaging_document_data: PackagingDocumentData[];
}

export interface PackagingFormValue {
  items: PackagingItem[];
}

interface IFormState {
  activeStep: number;
  isLoading: boolean;
  itemBatchDetails: Item[];
  itemPackingDetails: PackagingItem[];
  orderDetails: IProduct[];
  disableForm: boolean;
  labelType: number;
}

const GenerateLabels = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = params?.orderid;
  const productId = useMemo(() => {
    return searchParams.get('productId');
  }, [searchParams]);
  useBeforeUnloadAndNavigate();

  const [formState, setFormState] = useState<IFormState>({
    activeStep: productId ? 0 : 1,
    isLoading: false,
    itemBatchDetails: [],
    itemPackingDetails: [],
    orderDetails: [],
    disableForm: false,
    labelType: 0
  });

  const {
    activeStep,
    isLoading,
    itemBatchDetails,
    itemPackingDetails,
    orderDetails,
    disableForm,
    labelType
  } = formState;

  const checkIfAllProducts = (labelType: number) => labelType === 3;

  const itemBatchDetailForm = useForm<BatchFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(addBatchNumberLabelGenerationSchema),
    defaultValues: {
      items: [
        {
          name: null,
          order_item_id: null,
          batch: [
            {
              id: null,
              batch_number: null,
              manufacturing_date: null,
              expiry_date: null
            }
          ]
        }
      ]
    }
  });

  const {
    formState: { isSubmitting, isDirty }
  } = itemBatchDetailForm;

  const itemPackagingDetailForm = useForm<PackagingFormValue>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(packingListSchema),
    defaultValues: {
      items: [
        {
          name: null,
          order_item_id: null,
          handling_and_storage: '',
          packaging_document_data: [
            {
              package_details: null,
              batch_number: null,
              start_package_no: null,
              end_package_no: null,
              tare_wt: null,
              unit_of_tare_wt: 'KG',
              gross_wt: null,
              no_of_packages: null,
              net_wt: null
            }
          ]
        }
      ]
    }
  });

  const fetchItemDetails = async () => {
    setFormState((prevState: IFormState) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await getLabelData(orderId as string);
    setFormState((prevState: IFormState) => ({
      ...prevState,
      isLoading: false
    }));

    if (response?.success) {
      const labelType = response?.data?.label_type;
      const orderItem = response.data?.order_items?.find(
        (item: any) => item?.order_item_id === productId
      );
      setFormState((prevState: IFormState) => ({
        ...prevState,
        labelType: labelType,
        disableForm: checkIfAllProducts(labelType) && !productId,
        orderDetails: checkIfAllProducts(labelType)
          ? response?.data?.order_items
          : response?.data?.order_items.filter((item: any) => item.order_item_id === productId)
      }));
      const batchNumbers =
        orderItem?.batch_list.length > 0
          ? orderItem.batch_list?.map((batch: any) => {
              return {
                id: batch.id,
                batch_number: batch.batch_number,
                manufacturing_date: new Date(batch.manufacturing_date),
                expiry_date: new Date(batch.expiry_date)
              };
            })
          : [
              {
                batch_number: null,
                manufacturing_date: null,
                expiry_date: null
              }
            ];
      itemBatchDetailForm.reset({
        items: [
          {
            name: orderItem?.product_name,
            order_item_id: orderItem?.order_item_id,
            batch: batchNumbers
          }
        ]
      });
      checkIfAllProducts(labelType)
        ? itemPackagingDetailForm.reset({
            items: response?.data?.order_items?.map((item: any) => {
              let packagingDocDataTemp = [
                {
                  package_details: null,
                  batch_number: null,
                  manufacturing_date: null,
                  expiry_date: null,
                  start_package_no: null,
                  end_package_no: null,
                  tare_wt: null,
                  unit_of_tare_wt: 'KG',
                  gross_wt: null,
                  no_of_packages: null,
                  net_wt: null
                }
              ];
              if (item?.label_data.length && item.label_data[0]?.packaging_document_data?.length) {
                packagingDocDataTemp = item?.label_data[0]?.packaging_document_data.map(
                  (packaging: any) => {
                    const calculatedNetWeight = parseFloat(
                      (packaging.no_of_package * packaging.package_data.weight).toFixed(4)
                    );
                    return {
                      package_details: {
                        id: packaging.package,
                        package_display_name: packaging.package_data.package_display_name,
                        weight: packaging.package_data.weight,
                        unit_of_weight: packaging.package_data.unit_of_tare_wt
                      },
                      batch_number: {
                        id: packaging.batch.id,
                        batch_number: packaging.batch.batch_number,
                        manufacturing_date: packaging.batch.manufacturing_date,
                        expiry_date: packaging.batch.expiry_date
                      },
                      expiry_date: new Date(packaging.batch.expiry_date),
                      manufacturing_date: new Date(packaging.batch.manufacturing_date),
                      start_package_no: packaging.start_package_no,
                      end_package_no: packaging.end_package_no,
                      tare_wt: packaging.tare_wt,
                      unit_of_tare_wt: packaging.unit_of_tare_wt || 'KG',
                      gross_wt: calculatedNetWeight + parseFloat(packaging.tare_wt),
                      no_of_packages: packaging.package_data.no_of_package,
                      net_wt: calculatedNetWeight
                    };
                  }
                );
              }
              return {
                name: item?.package[0]?.order_item_name ?? item?.label_data[0]?.product_name ?? '-',
                order_item_id: item?.order_item_id,
                handling_and_storage: item?.handling_and_storage,
                packaging_document_data: packagingDocDataTemp
              };
            })
          })
        : itemPackagingDetailForm.reset({
            items: response?.data?.order_items
              .filter((item: any) => item.order_item_id === productId)
              .map((item: any) => {
                let packagingDocDataTemp = [
                  {
                    package_details: null,
                    batch_number: null,
                    manufacturing_date: null,
                    expiry_date: null,
                    start_package_no: null,
                    end_package_no: null,
                    tare_wt: null,
                    unit_of_tare_wt: 'KG',
                    gross_wt: null,
                    no_of_packages: null,
                    net_wt: null
                  }
                ];
                if (
                  item?.label_data.length &&
                  item.label_data[0]?.packaging_document_data?.length
                ) {
                  packagingDocDataTemp = item?.label_data[0]?.packaging_document_data.map(
                    (packaging: any) => {
                      const calculatedNetWeight = parseFloat(
                        (packaging.no_of_package * packaging.package_data.weight).toFixed(4)
                      );
                      return {
                        package_details: {
                          id: packaging.package,
                          package_display_name: packaging.package_data.package_display_name,
                          weight: packaging.package_data.weight,
                          unit_of_weight: packaging.package_data.unit_of_tare_wt
                        },
                        batch_number: {
                          id: packaging.batch.id,
                          batch_number: packaging.batch.batch_number,
                          manufacturing_date: packaging.batch.manufacturing_date,
                          expiry_date: packaging.batch.expiry_date
                        },
                        expiry_date: new Date(packaging.batch.expiry_date),
                        manufacturing_date: new Date(packaging.batch.manufacturing_date),
                        start_package_no: packaging.start_package_no,
                        end_package_no: packaging.end_package_no,
                        tare_wt: packaging.tare_wt,
                        unit_of_tare_wt: packaging.unit_of_tare_wt || 'KG',
                        gross_wt: calculatedNetWeight + parseFloat(packaging.tare_wt),
                        no_of_packages: packaging.no_of_package,
                        net_wt: calculatedNetWeight
                      };
                    }
                  );
                }
                return {
                  name:
                    item?.package[0]?.order_item_name ?? item?.label_data[0]?.product_name ?? '-',
                  order_item_id: item?.order_item_id,
                  packaging_document_data: packagingDocDataTemp,
                  handling_and_storage: item?.handling_and_storage
                };
              })
          });
    } else {
      notify({
        severity: 'error',
        message: response?.message ?? 'Something went wrong'
      });
    }
  };

  useEffect(() => {
    fetchItemDetails();
  }, []);

  const handleNavigation = (currentStep: number) => {
    setFormState((prevState: IFormState) => ({
      ...prevState,
      activeStep: currentStep
    }));
  };

  const itemBatchDetailFormSubmit: SubmitHandler<BatchFormValues> = (data: BatchFormValues) => {
    const batchData = data.items.map((item: Item) => {
      return {
        order_item_id: item.order_item_id,
        name: item.name,
        batch: item.batch.map((batch: Batch) => {
          return {
            batch_number: batch.batch_number,
            manufacturing_date: `${moment(batch.manufacturing_date).format('YYYY-MM-DD')}`,
            expiry_date: `${moment(batch.expiry_date).format('YYYY-MM-DD')}`
          };
        })
      };
    });
    isDirty &&
      itemPackagingDetailForm.reset({
        items: batchData.map((item: any) => {
          return {
            name: item.name,
            order_item_id: item.order_item_id,
            handling_and_storage: '',
            packaging_document_data: [
              {
                package_details: null,
                batch_number: null,
                start_package_no: null,
                end_package_no: null,
                tare_wt: null,
                unit_of_tare_wt: 'KG',
                gross_wt: null,
                no_of_packages: null,
                net_wt: null
              }
            ]
          };
        })
      });
    setFormState((prevState: IFormState) => ({
      ...prevState,
      itemBatchDetails: batchData
    }));
    handleNavigation(1);
  };

  const handleSidebarClick = (value: number) => {
    handleNavigation(value);
  };

  const handleSidebarEnterKey = (value: number) => {
    handleNavigation(value);
  };

  const handleBackClick = (currentStep: number) => () => {
    handleNavigation(currentStep - 1);
  };

  const handleCancelClick = () => {
    checkIfAllProducts(labelType)
      ? navigate(`/${CLIENT_ROUTES.order}/${orderId}`)
      : navigate(`/${CLIENT_ROUTES.order}/${orderId}?activeTab=tasks&activeSubTab=generate_label`);
  };

  const itemPackagingDetailFormSubmit = async (data: PackagingFormValue) => {
    const packages: any = {};
    const packingDocumentData: any = {};
    orderDetails.forEach((orderItem: any) => {
      orderItem.package.forEach((packaging: any) => {
        const id = packaging.id.toString();
        packages[id] = (packages[id] || 0) + packaging.no_of_package;
      });
    });

    data.items.forEach((item: PackagingItem) => {
      item.packaging_document_data.forEach((packaging: PackagingDocumentData) => {
        if (packaging.package_details) {
          const id = packaging.package_details.id.toString();
          packingDocumentData[id] =
            (packingDocumentData[id] || 0) + (packaging.no_of_packages || 0);
        }
      });
    });
    const packageFormState = Object.entries(packages).map(([id, noOfPackage]) => ({
      id: parseInt(id),
      noOfPackage
    }));

    const packingDocumentArray = Object.entries(packingDocumentData).map(([id, noOfPackage]) => ({
      id: parseInt(id),
      noOfPackage
    }));

    packageFormState.sort((a, b) => a.id - b.id);
    packingDocumentArray.sort((a, b) => a.id - b.id);

    if (JSON.stringify(packageFormState) !== JSON.stringify(packingDocumentArray)) {
      notify({
        severity: 'error',
        message: 'Please enter the correct number of packages'
      });
      return;
    }
    setFormState((prevState: IFormState) => ({
      ...prevState,
      itemPackingDetails: data.items
    }));
    handleNavigation(2);
  };

  const handlePreviewSubmit = async () => {
    const isEdit = orderDetails.some((item: any) => item.label_data.length > 0);
    const reqBody = isEdit
      ? itemPackingDetails.map((item: PackagingItem) => ({
          order_item_id: item.order_item_id,
          handling_and_storage: item.handling_and_storage || '',
          label_data: [
            {
              id: orderDetails?.find(
                (orderItem: any) => orderItem?.order_item_id === item.order_item_id
              )?.label_data[0].id,
              order_item_id: item.order_item_id,
              packaging_document_data: item.packaging_document_data.map(
                (packaging: PackagingDocumentData) => {
                  const manufacturingDate = `${moment(
                    packaging.batch_number && packaging.batch_number.manufacturing_date
                  ).format('YYYY-MM-DD')}`;
                  const expiryDate = `${moment(
                    packaging.batch_number && packaging.batch_number.expiry_date
                  ).format('YYYY-MM-DD')}`;
                  return {
                    package: packaging.package_details && packaging.package_details.id,
                    batch: {
                      batch_number: packaging.batch_number && packaging.batch_number.batch_number,
                      manufacturing_date: manufacturingDate,
                      expiry_date: expiryDate,
                      order_item_id: item.order_item_id
                    },
                    package_data: {
                      id: packaging.package_details && packaging.package_details.id,
                      type: packaging.package_details && packaging.package_details.type,
                      weight: packaging.package_details && packaging.package_details.weight,
                      unit_of_weight:
                        packaging.package_details && packaging.package_details.unit_of_weight,
                      no_of_package:
                        packaging.package_details && packaging.package_details.no_of_package,
                      type_label: packaging.package_details && packaging.package_details.type_label
                    },
                    start_package_no: packaging.start_package_no,
                    end_package_no: packaging.end_package_no,
                    tare_wt: packaging.tare_wt,
                    unit_of_tare_wt: 'KG',
                    no_of_package: packaging.no_of_packages,
                    net_wt: packaging.net_wt,
                    order_item: item.order_item_id
                  };
                }
              )
            }
          ]
        }))
      : itemPackingDetails.map((item: PackagingItem) => ({
          order_item_id: item.order_item_id,
          handling_and_storage: item.handling_and_storage || '',
          packaging_document_data: item.packaging_document_data.map(
            (packaging: PackagingDocumentData) => {
              const manufacturingDate = `${moment(
                packaging.batch_number && packaging.batch_number.manufacturing_date
              ).format('YYYY-MM-DD')}`;
              const expiryDate = `${moment(
                packaging.batch_number && packaging.batch_number.expiry_date
              ).format('YYYY-MM-DD')}`;
              return {
                package: packaging.package_details && packaging.package_details.id,
                batch: {
                  batch_number: packaging.batch_number && packaging.batch_number.batch_number,
                  manufacturing_date: manufacturingDate,
                  expiry_date: expiryDate
                },
                start_package_no: packaging.start_package_no,
                end_package_no: packaging.end_package_no,
                tare_wt: packaging.tare_wt,
                unit_of_tare_wt: 'KG',
                gross_wt: packaging.gross_wt,
                no_of_package: packaging.no_of_packages,
                net_wt: packaging.net_wt,
                order_item: item.order_item_id
              };
            }
          )
        }));

    const response = isEdit
      ? await updateLabel(orderId as string, reqBody)
      : await createLabel(orderId as string, reqBody);

    if (response.success) {
      notify({
        severity: 'success',
        message: response.data.message ?? 'Label created successfully'
      });
      checkIfAllProducts(labelType)
        ? navigate(`/${CLIENT_ROUTES.order}/${orderId}`)
        : navigate(
            `/${CLIENT_ROUTES.order}/${orderId}?activeTab=tasks&activeSubTab=generate_label`
          );
    } else {
      notify({
        severity: 'error',
        message: response?.message ?? 'Something went wrong'
      });
    }
  };

  return (
    <main className={css.formWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Generate Label</Typography>
        <Typography variant="subheading1">0{activeStep + 1} of 03</Typography>
      </div>
      <div className={css.formContainer}>
        <div className={css.sideBarWrapper}>
          {checkIfAllProducts(labelType) ? (
            <SideBar
              activeStep={activeStep}
              onClick={handleSidebarClick}
              onEnter={handleSidebarEnterKey}>
              <SideBar.Item label="Packaging List" value={1} disabled={activeStep! <= 1} />
              <SideBar.Item label="Preview" value={2} disabled={activeStep! <= 1} />
            </SideBar>
          ) : (
            <SideBar
              activeStep={activeStep}
              onClick={handleSidebarClick}
              onEnter={handleSidebarEnterKey}>
              <SideBar.Item label="Batch Number" value={0} disabled={!productId} />
              <SideBar.Item label="Packaging List" value={1} disabled={activeStep! <= 1} />
              <SideBar.Item label="Preview" value={2} disabled={activeStep! <= 1} />
            </SideBar>
          )}
        </div>
        {activeStep === 0 && (
          <FormProvider {...itemBatchDetailForm}>
            <BatchForm onFormSubmit={itemBatchDetailFormSubmit} onCancelClick={handleCancelClick} />
          </FormProvider>
        )}
        {activeStep === 1 && (
          <FormProvider {...itemPackagingDetailForm}>
            <ItemDetails
              onFormSubmit={itemPackagingDetailFormSubmit}
              onCancelClick={handleCancelClick}
              data={orderDetails}
              disableForm={disableForm}
              itemBatchDetails={itemBatchDetails}
            />
          </FormProvider>
        )}
        {activeStep === 2 && (
          <LabelPreview
            data={itemPackingDetails}
            handleCancelClick={handleCancelClick}
            handleNavigation={handleNavigation}
            handlePreviewSubmit={handlePreviewSubmit}
          />
        )}
      </div>
      <Loader open={isLoading} />
    </main>
  );
};

export default GenerateLabels;
