import css from './index.module.scss';
import { Accordion, Typography, Select, Button } from '@components/base';
import { Avatar } from '@components/common';
import Images from '@assets/images';
import { getFormattedDate } from '@helpers/utils';
import { Controller, useForm } from 'react-hook-form';
import { addRodtepDrawback } from '@services/order.service';
import { useState } from 'react';
import notify from '@helpers/toastify-helper';
import moment from 'moment';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

interface RODTEPProps {
  taskId: string;
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete: () => void;
}
interface RodtepFormType {
  drawback_claim_confirmation: {
    label: string;
    value: boolean;
  };
  rodtep_claim_confirmation: {
    label: string;
    value: boolean;
  };
}

const RODTEPDrawbackCard = (props: RODTEPProps) => {
  const { taskId, taskData, onComplete } = props;
  const getTaskStatus = taskData[0]?.is_completed;
  const [isCompleted, setIsCompleted] = useState<boolean | undefined>(getTaskStatus);

  const doc_submitted = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const roDtepDrawBack = useForm<RodtepFormType>({
    defaultValues: {
      drawback_claim_confirmation: { label: 'No', value: false },
      rodtep_claim_confirmation: { label: 'No', value: false }
    }
  });

  const { control, getValues } = roDtepDrawBack;

  const handleSelectChange = async () => {
    const values = getValues();
    if (
      values.drawback_claim_confirmation.value == false ||
      values.rodtep_claim_confirmation.value == false
    ) {
      notify({ message: 'Please select Yes as an option', severity: 'error' });
    } else {
      const body = {
        drawback_claim_confirmation: values.drawback_claim_confirmation.value,
        rodtep_claim_confirmation: values.rodtep_claim_confirmation.value
      };

      const response = await addRodtepDrawback(taskId, body);
      if (response.success) {
        notify({ message: 'RO-DTEP and Drawback updated!' });
        onComplete();
        setIsCompleted(true);
      } else if (response.error) {
        notify({ message: response.error, severity: 'error' });
      } else notify({ message: 'Some error occurred', severity: 'error' });
    }
  };

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {getTaskStatus || isCompleted ? (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              ) : (
                <Avatar src={Images.illustration} alt="" />
              )}
              <Link to={`/order/${taskData[0]?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  #{taskData[0]?.readable_task_id} - RO-DTEP and Drawback
                </Typography>
              </Link>
            </div>
            <div className={css.pocWrapper}>
              <div className={css.uploadWrapper}>
                {!getTaskStatus && isCompleted === false && (
                  <Button variant="text" className={css.detailsButton} onClick={handleSelectChange}>
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordionContent}>
          <div className={css.stickerDetailsWrapper}>
            <div className={css.stickersInnerWrapper}>
              <Typography variant="body">Has the RoDTEP form been filed?</Typography>
              <div className={css.selectWrapper}>
                {isCompleted ? (
                  <Typography variant="h5">Yes</Typography>
                ) : (
                  <Controller
                    name="rodtep_claim_confirmation"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={doc_submitted}
                        isDisabled={getTaskStatus || isCompleted}
                        onChange={(e: any) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
            <div className={css.stickersInnerWrapper}>
              <Typography variant="body">Has the Drawback form been filed?</Typography>
              <div className={css.selectWrapper}>
                {isCompleted ? (
                  <Typography variant="h5">Yes</Typography>
                ) : (
                  <Controller
                    name="drawback_claim_confirmation"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={doc_submitted}
                        isDisabled={getTaskStatus || isCompleted}
                        onChange={(e: any) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskData[0]?.updated_at)?.diff(taskData[0]?.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={taskData[0].created_at as Date}
        updatedDate={taskData[0].updated_at as Date}
      />
    </div>
  );
};
export default RODTEPDrawbackCard;
