import { useCallback, useEffect, useState } from 'react';
import css from './index.module.scss';
import { IconNode, Typography, Button, Alert, Loader } from '@components/base';
import { LinkButton, Seo, TextField } from '@components/common';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { CLIENT_ROUTES } from '@router/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { loginSchema } from '@helpers/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Images from '@assets/images';
import { googleLogin, sendOtp, userLogin } from '@services/login.service';
import { ILoginCredential } from '@helpers/types';
import SessionService from '@helpers/session-helper';
import notify from '@helpers/toastify-helper';
import { EVENT_TRACKING_TYPES, USER_TYPES } from '@helpers/constants';
import { pushEventTracking } from '@helpers/utils';

interface SignInState {
  showLoader: boolean;
  showErrorMessage: boolean;
  loginType: 'password' | 'otp';
  isOtpSent: boolean;
  timerisActive: boolean;
}

const SignIn = () => {
  const [signInState, setSignInState] = useState<SignInState>({
    showLoader: false,
    showErrorMessage: false,
    loginType: 'password',
    isOtpSent: false,
    timerisActive: false
  });

  const [time, setTime] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();
  const { loginType, isOtpSent, timerisActive } = signInState;
  const { customerEmail = '', customerPassword = '' } = location.state || {};
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: customerEmail,
      password: customerPassword,
      otp: '',
      loginVia: 'password'
    }
  });

  const handleFormSubmit: SubmitHandler<ILoginCredential> = async (data) => {
    setSignInState((prevState) => ({ ...prevState, showErrorMessage: false }));
    if (loginType == 'otp' && !isOtpSent) {
      //call otp send
      sendOtpToUser(data?.email ?? null);
    } else {
      const body: any = { ...data };
      if (loginType === 'otp') {
        delete body.password;
      } else {
        delete body.otp;
      }
      const response = await userLogin(body);
      if (response?.success) {
        pushEventTracking(EVENT_TRACKING_TYPES.LOGIN_SUCCESS);
        const {
          access,
          refresh,
          email,
          uidb64,
          token,
          first_time_login,
          user_auth_info,
          role,
          is_finance_team_member
        } = response.data;
        if (first_time_login) {
          navigate(`/${CLIENT_ROUTES.passwordSetup}`, {
            replace: true,
            state: { email, uidb64, token }
          });
          return;
        }
        const user_authorization_data = {
          ...user_auth_info,
          is_finance_team_member: is_finance_team_member,
          user_type: role === USER_TYPES.customer ? USER_TYPES.customer : USER_TYPES.internal
        };
        SessionService.saveSession(access, refresh, user_authorization_data);
      } else {
        setSignInState((prevState) => ({ ...prevState, showErrorMessage: true }));
        pushEventTracking(EVENT_TRACKING_TYPES.LOGIN_FAILED);
      }
    }
  };

  const handleGoogleLogin = useCallback(async (credentialResponse: CredentialResponse) => {
    setSignInState((prevState) => ({ ...prevState, showErrorMessage: false, showLoader: true }));
    const response = await googleLogin(`${credentialResponse.credential}`);
    setSignInState((prevState) => ({ ...prevState, showLoader: false }));
    if (response?.success) {
      pushEventTracking(EVENT_TRACKING_TYPES.LOGIN_SUCCESS);
      pushEventTracking(EVENT_TRACKING_TYPES.GOOGLE_LOGIN_SUCCESS);
      const { access, refresh, user_auth_info, role, is_finance_team_member } = response.data;
      const user_authorization_data = {
        ...user_auth_info,
        is_finance_team_member: is_finance_team_member,
        user_type: role === USER_TYPES.customer ? USER_TYPES.customer : USER_TYPES.internal
      };
      SessionService.saveSession(access, refresh, user_authorization_data);
    } else {
      pushEventTracking(EVENT_TRACKING_TYPES.LOGIN_FAILED);
      pushEventTracking(EVENT_TRACKING_TYPES.GOOGLE_LOGIN_FAILED);
      notify({
        message: 'The email you selected does not appear to be registered.',
        severity: 'error'
      });
    }
  }, []);

  const handleGoogleLoginError = useCallback(() => {
    notify({
      message: 'Login failed. Please try again.',
      severity: 'error'
    });
  }, []);

  const switchToOTP = () => {
    if (loginType === 'password') {
      setSignInState((prevState) => ({
        ...prevState,
        loginType: 'otp',
        isOtpSent: false,
        showErrorMessage: false,
        timerisActive: false
      }));
      setValue('loginVia', 'otp_request');
    } else if (loginType === 'otp') {
      setSignInState((prevState) => ({
        ...prevState,
        loginType: 'password',
        showErrorMessage: false,
        timerisActive: false,
        isOtpSent: false,
        timerIsActive: false
      }));
      setValue('loginVia', 'password');
    }
  };

  const sendOtpToUser = async (email: string) => {
    if (!email) {
      return;
    }
    setSignInState((prevState) => ({ ...prevState, showErrorMessage: false, showLoader: true }));
    const response = await sendOtp(email);
    if (response?.success) {
      setSignInState((prevState) => ({ ...prevState, isOtpSent: true, showLoader: false }));
      startCountdown();
      notify({
        message: 'OTP sent successfully',
        severity: 'success'
      });
      setValue('loginVia', 'otp_sent');
    } else {
      setSignInState((prevState) => ({ ...prevState, showErrorMessage: true, showLoader: false }));
      notify({
        message: response?.error ?? 'Failed to send OTP',
        severity: 'error'
      });
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (timerisActive && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0) {
      clearInterval(interval!);
      setSignInState((prevState) => ({ ...prevState, timerisActive: false }));
      setTime(0);
    }
    return () => clearInterval(interval!);
  }, [timerisActive, time]);

  const startCountdown = () => {
    setTime(30);
    setSignInState((prevState) => ({ ...prevState, timerisActive: true }));
  };

  return (
    <div className={css.loginGroupSection}>
      <Typography variant="h1">Log In</Typography>
      <Seo title="Sign In" />
      <form className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              type="email"
              inputMode="email"
              disabled={isOtpSent}
              enterKeyHint="next"
              autoComplete="email"
              label="Email"
              placeholder="Enter your email"
              autoFocus={!customerEmail?.length}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
            />
          )}
        />

        {loginType === 'password' && (
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                type="password"
                enterKeyHint="done"
                autoComplete="current-password"
                label="Password"
                placeholder="Enter your password"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
              />
            )}
          />
        )}
        {loginType === 'otp' && isOtpSent && (
          <Controller
            name="otp"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                type="text"
                label="OTP"
                placeholder="Enter OTP sent to your email"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
              />
            )}
          />
        )}

        {signInState.showErrorMessage && (
          <Alert
            severity="error"
            title={`${
              loginType == 'password'
                ? 'Invalid  Credentials'
                : isOtpSent
                ? 'Invalid OTP'
                : 'Something went wrong!'
            }`}
            icon={false}>
            {loginType == 'password'
              ? `Please re-enter password and try again.`
              : isOtpSent
              ? `Please re-enter OTP and try again.`
              : `Please try again later.`}
          </Alert>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {loginType === 'otp' && isOtpSent && (
            <>
              {isOtpSent && (
                <div>
                  <Button
                    variant="text"
                    onClick={() => {
                      setSignInState((prevState) => ({ ...prevState, isOtpSent: false }));
                    }}>
                    Change Email
                  </Button>
                </div>
              )}
              <Button
                variant="text"
                onClick={() => sendOtpToUser(watch('email'))}
                disabled={timerisActive}>
                Resend OTP {timerisActive ? `in ${formatTime(time)}` : ``}
              </Button>
            </>
          )}
          {loginType === 'password' && (
            <>
              <LinkButton
                to={`${CLIENT_ROUTES.forgotPassword}`}
                eventTrackingName={EVENT_TRACKING_TYPES.FORGOT_PASSWORD_CLICK}>
                Forgot password
              </LinkButton>
            </>
          )}
        </div>

        <div className={css.fieldActionButton}>
          <Button
            type="submit"
            disabled={isSubmitting}
            eventTrackingName={EVENT_TRACKING_TYPES.LOGIN_BTN_CLICK}>
            {loginType === 'otp' && !isOtpSent && 'Send OTP'}
            {loginType === 'otp' && isOtpSent && 'Log In'}
            {loginType === 'password' && 'Log In'}
          </Button>
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={handleGoogleLoginError}
            width="360px"
            text="continue_with"
            logo_alignment="center"
          />
          <Button
            variant="text"
            onClick={switchToOTP}
            eventTrackingName={EVENT_TRACKING_TYPES.FORGOT_PASSWORD_CLICK}>
            {loginType === 'otp' ? 'Log in with password' : 'Log in with OTP'}
          </Button>
        </div>
      </form>
      <Loader open={isSubmitting || signInState.showLoader} />
    </div>
  );
};

export default SignIn;
