import { memo } from 'react';
import css from './index.module.scss';
import { Typography, Divider } from '@components/base';
import { BoxContainer } from '@components/common';
import { IBankdetail } from '@helpers/types/supplier';

interface BankInfoProps {
  bankInfo: IBankdetail[];
}

const BankInfo = (props: BankInfoProps) => {
  const { bankInfo } = props;

  return (
    <div className={css.bankInfoContainer}>
      <BoxContainer title="Bank Details">
        <div className={css.detailBoxWrapper}>
          {bankInfo.map((info, index) => (
            <div key={index} className={css.bankRowWrapper}>
              <Typography variant="span" className={css.bankLabel}>
                Bank {index + 1}
              </Typography>
              <div className={css.rowWrapper}>
                <div className={css.textColumnWrapper}>
                  <Typography variant="subheading1">Bank Name</Typography>
                  <Typography variant="p">{info.bank_name}</Typography>
                </div>
                <div className={css.textColumnWrapper}>
                  <Typography variant="subheading1">Account Number</Typography>
                  <Typography variant="p">{info.account_number}</Typography>
                </div>
                <div className={css.textColumnWrapper}>
                  <Typography variant="subheading1">IFSC Code</Typography>
                  <Typography variant="p">{info.ifsc_code}</Typography>
                </div>
                <div className={css.textColumnWrapper}>
                  <Typography variant="subheading1">SWIFT Code</Typography>
                  <Typography variant="p">{info.swift_code}</Typography>
                </div>
              </div>
              <div className={css.bankAddressWrapper}>
                <Typography variant="subheading1">Bank Address</Typography>
                <a
                  href={`http://maps.google.com/?q=${info.readable_address}`}
                  className={css.linkLabel}
                  target="_blank"
                  rel="noopener noreferrer">
                  {`${info.readable_address}`}
                </a>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </BoxContainer>
    </div>
  );
};

export default memo(BankInfo);
