import React from 'react';
import { BoxContainer } from '@components/common';
import { Button } from '@components/base';
import OrderDetails from './components/Consignee';
import ItemDetails from './components/Item';
import ConsigneeDetails from './components/Order';

import { COAFormState } from '@helpers/types/coa';
import css from './index.module.scss';

interface PreviewPropsTypes {
  handleCancelClick: () => void;
  data: COAFormState;
  onSubmit: () => void;
  handleBackClick: (step: number) => void;
  handleNavigation: (step: number) => void;
}

const Preview = (props: any) => {
  const { handleCancelClick, orderData, itemData, onSubmit, handleBackClick, handleNavigation } =
    props;
  return (
    <div className={css.coaFormWrapper}>
      <BoxContainer title="Preview">
        <div className={css.PreviewWrapper}>
          <OrderDetails data={orderData} handleNavigation={handleNavigation} />
          <ConsigneeDetails data={orderData} handleNavigation={handleNavigation} />
          <ItemDetails data={itemData} handleNavigation={handleNavigation} />
          <div className={css.actionButtonWrapper}>
            <Button variant="text" onClick={handleCancelClick}>
              Cancel
            </Button>
            <div className={css.buttonContainer}>
              <Button variant="outlined-secondary" onClick={() => handleBackClick(1)}>
                Back
              </Button>
              <Button type="submit" onClick={onSubmit}>
                Save and Proceed
              </Button>
            </div>
          </div>
        </div>
      </BoxContainer>
    </div>
  );
};

export default Preview;
