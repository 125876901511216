import { useEffect } from 'react';
import css from './index.module.scss';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Button from '@components/base/button';
import TextField from '@components/common/text-field';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';

interface IOrderInvoiceShareProps {
  onClose: () => void;
  orderId: string;
  selectedOrderIds: any;
  onFormSubmit: (data: any) => Promise<void>;
  handleBackClick: () => void;
  editMode: string | null | undefined;
}

const OrderInvoiceShare = (props: IOrderInvoiceShareProps) => {
  const { onClose, orderId, selectedOrderIds, onFormSubmit, handleBackClick, editMode } = props;
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext();

  const { fields, append } = useFieldArray({
    control,
    name: 'order_ids_to_invoice_share_map'
  });

  useEffect(() => {
    if (!fields.length) {
      const fieldArrayCopy = selectedOrderIds.map((order: any) => {
        return {
          ...order,
          share: null
        };
      });
      append(fieldArrayCopy);
    }
  }, [selectedOrderIds]);

  return (
    <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
      <section className={css.modalContent}>
        {fields.map((field: any, index: number) => {
          return (
            <div key={field.id} className={css.rowWrapper}>
              <Controller
                name={`order_ids_to_invoice_share_map.${index}.readable_order_id`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required={true}
                    label="Order ID"
                    disabled
                    placeholder="Select Order ID"
                    error={fieldState.invalid}
                    rootClassName={css.typeField}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`order_ids_to_invoice_share_map.${index}.share`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required={true}
                    label="Share %"
                    placeholder="Enter in percentage"
                    error={fieldState.invalid}
                    rootClassName={css.typeField}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
          );
        })}
      </section>
      <section className={css.modalFooter}>
        <Button variant="outlined-secondary" onClick={handleBackClick} disabled={isSubmitting}>
          Back
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          {editMode ? (isSubmitting ? 'Updating' : 'Update') : isSubmitting ? 'Saving' : 'Save'}
        </Button>
      </section>
    </form>
  );
};

export default OrderInvoiceShare;
