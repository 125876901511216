import { Skeleton } from '@components/base';
import css from './index.module.scss';

const KeyValueSkeleton = () => {
  return (
    <div className={css.mainWrapper}>
      <Skeleton variant="text" width={200} height={20} />
      <Skeleton variant="text" width={200} height={20} />
    </div>
  );
};

export default KeyValueSkeleton;
