import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, DocumentRow, DataGrid } from '@components/common';
import { DOCUMENT_GENERATION_STATUS } from '@helpers/constants';
import { AppContext } from '@helpers/hooks/AppContext';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { useContext, useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../components/footer';
import css from './index.module.scss';
import { Link } from 'react-router-dom';

interface GenerateCOACardPropsType {
  taskData: Array<Partial<ITaskTabProps>>;
}
export type STATUS = 'not generated' | 'done' | 'rejected' | 'in review';
interface COATable {
  product_name: string;
  coa_batch_number: string;
  actions: STATUS | string;
}

const GenerateCOACard = (props: GenerateCOACardPropsType) => {
  const navigate = useNavigate();
  const params = useParams();
  const { appDispatch } = useContext(AppContext);
  const { taskData } = props;
  const taskItem = taskData[0];

  /**
   * Used for for doc generation status and render UI accordingly
   */
  const docGenerationStatus = useMemo(() => {
    if (taskItem?.coa_generation_status as any) {
      return DOCUMENT_GENERATION_STATUS.find((i) => i.value === taskItem?.coa_generation_status)
        ?.label;
    }
    return null;
  }, [taskItem]);

  const handleGenerateCOAClick = () => {
    appDispatch({
      type: 'setCOAData',
      payload: {
        task_id: taskItem?.task_id,
        order_id: taskItem?.order,
        order_item_details: {
          order_item_id: taskItem?.order_item,
          order_product_name: taskItem?.product_name,
          coa_batch_number: taskItem?.coa_batch_number
        }
      }
    });
    navigate(`/order/generate-coa/${taskItem?.task_id}/${taskItem?.order}`);
  };

  let actionsColumn: React.ReactNode;
  function renderActionDiv(actionStatus: string) {
    if (actionStatus === 'notGenerated') {
      actionsColumn = (
        <Button
          onClick={handleGenerateCOAClick}
          variant="outlined-secondary"
          className={css.linkButton}>
          Generate
        </Button>
      );
    } else if (actionStatus === 'generated') {
      actionsColumn = <div className={css.btnContainer}></div>;
    } else if (actionStatus === 'inProcess') {
      actionsColumn = (
        <Button variant="text" disabled className={css.assignButton}>
          Document generation in process
        </Button>
      );
    } else if (actionStatus === 'generationFailed') {
      actionsColumn = (
        <Button variant="text" disabled className={css.assignButton}>
          Document generation Failed
        </Button>
      );
    }
    return actionsColumn;
  }

  const [productColumn, productData] = useMemo(() => {
    const columns: any = [
      {
        Header: 'Product Name',
        accessor: 'product_name'
      },
      {
        Header: 'Batch Number',
        accessor: 'coa_batch_number'
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: (props: CellProps<COATable>) => {
          let actionStatus = 'notGenerated';
          if (taskItem?.coa_docx && taskItem?.coa_pdf) {
            actionStatus = 'generated';
          } else if (
            !taskItem?.is_completed &&
            (docGenerationStatus == 'DOC_GENERATION_PROCESSING' ||
              docGenerationStatus == 'DOC_GENERATION_REQUESTED')
          ) {
            actionStatus = 'inProcess';
          } else if (docGenerationStatus == 'DOC_GENERATION_FAILED') {
            actionStatus = 'generationFailed';
          }
          // @ts-ignore: Unreachable code error
          return <>{renderActionDiv(actionStatus)}</>;
        }
      }
    ];
    const data: any = [
      {
        product_name: taskItem?.product_name,
        coa_batch_number: taskItem?.coa_batch_number
      }
    ];
    return [columns, data];
  }, [taskItem]);

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskItem?.is_completed ? (
                <Avatar src={Images.illustrationEdit} alt="" />
              ) : (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              )}

              <Link to={`/order/${taskItem?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  #{taskItem?.readable_task_id} - Generate Certificate of Analysis
                </Typography>
              </Link>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordionContent}>
          <DataGrid tableStyle={{ width: '100%' }} columns={productColumn} data={productData} />
        </Accordion.Content>
      </Accordion>
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};

export default GenerateCOACard;
