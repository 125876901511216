import Button from '@components/base/button';
import css from './index.module.scss';
import OrderItemContainer from './components/orderItemContainer';
import { BoxContainer } from '@components/common';
import { IOrderItem, ITaskItemProps } from '@helpers/types/task-tabs';

interface IOrderItemDetailsProps {
  data: ITaskItemProps;
  handleOrderItemSubmit: () => void;
  handleCancelClick: () => void;
}

const OrderItemDetails = (props: IOrderItemDetailsProps) => {
  const { data, handleOrderItemSubmit, handleCancelClick } = props;

  return (
    <div className={css.orderItemFormWrapper}>
      <BoxContainer title="Order Item Details">
        <div className={css.orderItemDetailWrapper}>
          {data?.order_items.map((itemDetail: IOrderItem, index: number) => (
            <OrderItemContainer key={index} itemDetails={itemDetail} index={index} />
          ))}
          <div className={css.formActionWrapper}>
            <Button variant="text" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button onClick={handleOrderItemSubmit}>Save and Proceed</Button>
          </div>
        </div>
      </BoxContainer>
    </div>
  );
};

export default OrderItemDetails;
