/* eslint-disable @typescript-eslint/no-var-requires */
import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { useState } from 'react';
import AddCourierDetailsModal from './components';
import css from './index.module.scss';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

interface CorierDetailsProps {
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete: () => void;
}

const AddCourierDetailsCard = (props: CorierDetailsProps) => {
  const { taskData, onComplete } = props;
  const taskItem = taskData[0];
  const getCompleteStatus = props.taskData[0]?.is_completed;
  const [taskIsCompleted, setTaskIsCompleted] = useState<boolean | undefined>(getCompleteStatus);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleStatusFromModal = (data: boolean) => {
    onComplete();
  };

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordianHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {getCompleteStatus || taskIsCompleted ? (
                <Avatar src={Images.alertSuccessFaint} alt="alertSucess" />
              ) : (
                <Avatar src={Images.illustrationEdit} alt="edit" />
              )}

              <Link to={`/order/${taskItem?.order}`} style={{ textDecoration: 'none' }}>
                <Typography variant="h5" className={css.underlineOnHover}>
                  #{taskItem?.readable_task_id} - Add Courier Details
                </Typography>
              </Link>
            </div>
            <div className={css.pocWrapper}>
              {!getCompleteStatus && taskIsCompleted == false && (
                <div className={css.buttonWrapper}>
                  {!getCompleteStatus && taskIsCompleted == false && (
                    <Button
                      variant="outlined"
                      className={css.detailsButton}
                      onClick={() => setShowModal(true)}>
                      Add Details
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </Accordion.Header>
        {getCompleteStatus && taskIsCompleted && (
          <Accordion.Content className={css.accordianContent}>
            <div className={css.docContainer}>
              <div className={css.textWrapper}>
                <Typography variant="pdoc">Document Name</Typography>
              </div>
              <div className={css.textWrapperNew}>
                <Typography variant="pdoc">Actions</Typography>
              </div>
            </div>
            <div className={css.docContent}>
              <div className={css.textWrapper}>
                <Typography variant="body" className={css.docTitle}>
                  Courier Slip
                </Typography>
              </div>
              <div>
                <div className={css.iconWrapper}>
                  <DocumentRow.View
                    title=""
                    document={taskItem?.documents_courier_slip as string}
                  />
                </div>
              </div>
            </div>
          </Accordion.Content>
        )}
      </Accordion>
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
      {showModal && (
        <AddCourierDetailsModal
          open={showModal}
          onClose={() => setShowModal(false)}
          taskId={taskItem.task_id as string}
          sendStatusToCard={handleStatusFromModal}
          onComplete={props.onComplete}
        />
      )}
    </div>
  );
};
export default AddCourierDetailsCard;
