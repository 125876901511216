import css from './index.module.scss';
import { Typography } from '@components/base';
import { LinkButton } from '@components/common';
import { CLIENT_ROUTES } from '@router/routes';
import { useLocation } from 'react-router-dom';

const AcknowledgeView = () => {
  const location = useLocation();
  return (
    <main className={css.acknowledgeWrapper}>
      <div className={css.textWrapper}>
        <Typography variant="h1">Password Set</Typography>
        <Typography variant="span" className={css.acknowledgeText}>
          Your password has been updated. Use this new password to log in from now on.
        </Typography>
      </div>
      <LinkButton
        variant="contained"
        to={`${CLIENT_ROUTES.login}`}
        replace
        state={{
          customerEmail: location.state?.email,
          customerPassword: location.state?.password
        }}>
        Login
      </LinkButton>
    </main>
  );
};

export default AcknowledgeView;
