import * as yup from 'yup';

const selectSchema = yup.object().shape({
  value: yup.string(),
  label: yup.string()
});

const orderBankDetailsSchema = yup.object().shape({
  payment_term_suffix: selectSchema.notRequired().nullable()
});

export { orderBankDetailsSchema };
