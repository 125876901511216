import { ChangeEvent, memo } from 'react';
import css from './index.module.scss';
import { BoxContainer, TextField } from '@components/common';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { IconNode, Typography, CheckboxLabel } from '@components/base';
import Images from '@assets/images';
import { IOrderCustomerForm } from '@helpers/types/add-order';

interface ConsigneeContainerProps {
  currentCustomer: IOrderCustomerForm;
}

const ConsigneeContainer = (props: ConsigneeContainerProps) => {
  const { control, setValue } = useFormContext();
  const watchField = useWatch({
    name: ['consignee_same_as_customer']
  });

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { currentCustomer } = props;
    const isChecked = event.currentTarget.checked;
    if (isChecked) {
      const { customer } = currentCustomer;
      if (customer) {
        const address = customer.addresses.find((item) => item.address_type === 'OFC');
        setValue('consignee_same_as_customer', isChecked);
        setValue('consignee_name', customer.name);
        setValue('consignee_phone_number', customer.number);
        setValue('consignee_landline', customer.number);
        setValue('consignee_email_id', customer.email);
        address && setValue('consignee_office_address', address.readable_address);
        return;
      }
    }
    setValue('consignee_same_as_customer', isChecked);
    setValue('consignee_name', '');
    setValue('consignee_phone_number', '');
    setValue('consignee_landline', '');
    setValue('consignee_email_id', '');
    setValue('consignee_office_address', '');
  };

  return (
    <BoxContainer
      title={
        <div className={css.boxTitleWrapper}>
          <Typography variant="h4">Consignee</Typography>
          <Controller
            name="consignee_same_as_customer"
            control={control}
            render={({ field }) => (
              <CheckboxLabel
                {...field}
                checked={field.value}
                value={`${field.value}`}
                onChange={handleCheckboxChange}
                label="Same as Customer"
              />
            )}
          />
        </div>
      }
      className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <Controller
            name="consignee_name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                disabled={watchField[0]}
                inputMode="text"
                enterKeyHint="next"
                autoComplete="name"
                label="Consignee Name"
                placeholder="Enter consignee name"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="consignee_phone_number"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                disabled={watchField[0]}
                type="tel"
                inputMode="tel"
                enterKeyHint="next"
                autoComplete="tel"
                label="Phone Number"
                placeholder="Enter phone number"
                rootClassName={css.fieldWrapper}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <Controller
            name="consignee_landline"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                disabled={watchField[0]}
                type="tel"
                inputMode="tel"
                enterKeyHint="next"
                autoComplete="tel"
                label="Landline"
                placeholder="Enter land line"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="consignee_email_id"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                disabled={watchField[0]}
                type="email"
                inputMode="email"
                enterKeyHint="next"
                autoComplete="email"
                label="Email ID"
                placeholder="Enter email ID"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="consignee_office_address"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                disabled={watchField[0]}
                type="text"
                inputMode="text"
                enterKeyHint="next"
                autoComplete="street-address"
                label="Office Address"
                placeholder="Enter office address"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
        </div>
      </div>
    </BoxContainer>
  );
};

export default memo(ConsigneeContainer);
