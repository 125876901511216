import { HTMLAttributes, ReactNode, useMemo } from 'react';
import { IconNode, Typography } from '@components/base';
import css from './index.module.scss';
import Images from '@assets/images';

interface DocumentContainerProps {
  headers: string[];
  headerClass?: string;
  children: ReactNode;
  className?: string;
  headerItemClassName?: string;
}

const DocumentContainer = (props: DocumentContainerProps) => {
  const { children, headers, headerClass, className, headerItemClassName } = props;

  const renderHeader = useMemo(() => {
    return headers.map((header, index) => (
      <Typography key={index} variant="p" className={`${css.header} ${headerItemClassName}`}>
        {header}
      </Typography>
    ));
  }, [props.headers]);

  return (
    <div className={`${css.documentTableWrapper} ${className}`}>
      <div className={`${css.documentTableHeader} ${headerClass}`}>{renderHeader}</div>
      <div className={css.documentContentWrapper}>{children}</div>
    </div>
  );
};

interface DocumentRowProps {
  name: string;
  documentObject: string;
  className?: string;
}

const DocumentRow = (props: DocumentRowProps) => {
  const { name, documentObject, className } = props;
  return (
    <div className={`${css.rowWrapper} ${className}`}>
      <div className={css.rowItemWrapper}>{name}</div>
      <div className={css.rowItemWrapper}>
        {documentObject && (
          <a
            href={documentObject}
            download={name}
            target="_blank"
            rel="noopener noreferrer"
            className={css.anchorItem}>
            <IconNode src={Images.eyeRed} alt="view icon" />
            <Typography>View</Typography>
          </a>
        )}
      </div>
    </div>
  );
};

DocumentContainer.Row = DocumentRow;

export default DocumentContainer;
