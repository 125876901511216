import * as yup from 'yup';

export const requestPaymentSchema = yup.object().shape({
  invoice_number: yup.object().required('Invoice Number is required'),
  amount_request: yup
    .number()
    .typeError('Amount Requested must be a number')
    .required('Amount Requested is required')
    .moreThan(0, 'Amount Requested must be greater than 0'),
  due_date: yup.date().required('Due Date is required'),
  requester_remarks: yup.string().trim().nullable().notRequired()
});

const paymentInfoSchema = yup.object().shape({
  amount_requested: yup
    .number()
    .typeError('Amount must be a number')
    .positive('Amount must be positive')
    .required('Amount is required'),
  due_date: yup.date().nullable(),
  requester_remarks: yup.string().nullable()
});

export const makePaymentSchema = yup.object().shape({
  invoice: yup.array().of(
    yup.object().shape({
      total_amount_eligible_for_payment: yup
        .number()
        .typeError('Net Amount must be a number')
        .positive('Net Amount must be positive')
        .required('Net Amount is required'),
      currency: yup.mixed().nullable(),
      amount: yup
        .number()
        .typeError('Amount must be a number')
        .positive('Amount must be positive')
        .required('Amount is required'),
      gst_amount: yup
        .number()
        .typeError('GST amount must be a number')
        .min(0, 'GST amount must be non-negative')
        .required('GST amount is required'),
      tds_amount: yup
        .number()
        .typeError('TDS amount must be a number')
        .min(0, 'TDS amount must be non-negative')
        .required('TDS amount is required'),
      tcs_amount: yup
        .number()
        .typeError('TCS amount must be a number')
        .min(0, 'TCS amount must be non-negative')
        .required('TCS amount is required'),
      total_amount_initiated: yup.number().nullable(),
      total_amount_paid: yup.number().nullable()
    })
  )
});

export const paymentSchema = yup.object().shape({
  invoices: yup.array().of(
    yup.object().shape({
      payment_infos: paymentInfoSchema
    })
  )
});
