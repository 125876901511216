import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Button, Loader, Typography } from '@components/base';
import css from './index.module.scss';
import { requestResetEmail } from '@services/login.service';
import notify from '@helpers/toastify-helper';

interface MailSentProps {
  email: string;
}

const MailSent = (props: MailSentProps) => {
  const timerInterval = useRef<ReturnType<typeof setInterval>>();
  const [mailSentState, setMailSentState] = useState({
    countDownRemaining: '02:00',
    showResendButton: false,
    showLoader: false
  });

  const secondToMinuteConversion = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const extraSeconds = seconds % 60;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    const extraSecondsStr = extraSeconds < 10 ? '0' + extraSeconds : extraSeconds;
    return `${minutesStr}:${extraSecondsStr}`;
  };

  const timer = useCallback((remaining = 120) => {
    timerInterval.current = setInterval(() => {
      remaining -= 1;
      if (remaining < 1) {
        clearInterval(timerInterval.current);
        setMailSentState((prevState) => ({
          ...prevState,
          countDownRemaining: '02:00',
          showResendButton: true
        }));
      } else
        setMailSentState((prevState) => ({
          ...prevState,
          showResendButton: false,
          countDownRemaining: secondToMinuteConversion(remaining)
        }));
    }, 1000);
  }, []);

  useEffect(() => {
    timer();
    return () => clearInterval(timerInterval.current);
  }, []);

  const maskedEmail = useMemo(() => {
    const { email } = props;
    const splitAt = email.split('@');
    const splitDot = splitAt[1]?.split('.');
    const maskedLength = email.length - (2 + splitDot[1]?.length || 0);
    const maskedChar = Array(maskedLength).join('x');
    const tlds = splitDot[1] ? `.${splitDot[1]}` : '';
    return `${email.substring(0, 2)}${maskedChar}${tlds}`;
  }, [props.email]);

  const handleResend = async () => {
    const { email } = props;
    setMailSentState((prevState) => ({ ...prevState, showLoader: true }));
    const response = await requestResetEmail(email);
    if (response.success) {
      setMailSentState((prevState) => ({
        ...prevState,
        showResendButton: false,
        showLoader: false
      }));
      timer();
    } else
      notify({
        message: response.error ?? 'Something went wrong. Please try again',
        severity: 'error'
      });
    setMailSentState((prevState) => ({
      ...prevState,
      showLoader: false
    }));
  };

  return (
    <div className={css.mailSentWrapper}>
      <div className={css.mailSentTextWrapper}>
        <Typography variant="p" className={css.mailSentText}>
          We have sent a reset link to
        </Typography>
        <Typography variant="span" className={css.maskedMail}>{`“${maskedEmail}”.`}</Typography>
      </div>
      <div className={css.actionWrapper}>
        <Typography variant="span" className={css.actionText}>
          Didn’t receive email yet?
        </Typography>
        {!mailSentState.showResendButton && (
          <Typography
            variant="span"
            className={css.actionText}>{`${mailSentState.countDownRemaining}`}</Typography>
        )}
        {mailSentState.showResendButton && (
          <Button variant="text" onClick={handleResend}>
            Resend Password
          </Button>
        )}
      </div>
      <Loader open={mailSentState.showLoader} />
    </div>
  );
};

export default MailSent;
