import { Button } from '@components/base';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { IItemInfoTypes } from '@helpers/types/pi';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import css from './index.module.scss';
import ItemDetailContainer from './item-detail-product';

interface ItemDetailFormProps {
  onFormSubmit: (data: any) => void;
  onCancelClick: () => void;
  onBackClick: () => void;
  productList: IItemInfoTypes;
  setTotalAmount?: (text: string) => void;
  postTotalAmount?: string;
  currency?: string;
  unitOfWeight?: string;
}

const ItemDetailForm = (props: ItemDetailFormProps) => {
  const {
    onFormSubmit,
    onCancelClick,
    onBackClick,
    productList,
    setTotalAmount,
    postTotalAmount,
    currency,
    unitOfWeight
  } = props;
  const { items } = productList;
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext();

  useBeforeUnloadAndNavigate();

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <ItemDetailContainer
        itemsList={items}
        postTotalAmount={postTotalAmount}
        setPostTotalAmount={setTotalAmount}
        currency={currency}
        unitOfWeight={unitOfWeight}
      />
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div>
          <Button variant="outlined-secondary" onClick={onBackClick} className={css.nextBtn}>
            Back
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save and Proceed
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ItemDetailForm;
