import css from './index.module.scss';
import { Button } from '@components/base';
import { useFormContext } from 'react-hook-form';
import ExporterContainer from './exporter-container';
import ConsigneeContainer from './consignee-container';
import { IExportConsigneeForm, IOrderCustomerForm } from '@helpers/types/add-order';
import { SelfEntity } from '@helpers/types/entity';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { RectifyRemarksContainer } from '@components/common';
interface ExporterConsigneeFormProps {
  onBackClick?: () => void;
  onCancelClick?: () => void;
  onFormSubmit: (data: IExportConsigneeForm) => void;
  currentCustomer: IOrderCustomerForm | null;
  entityList: SelfEntity[];
  rectifyRemarks?: string;
}

const ExporterConsigneeForm = (props: ExporterConsigneeFormProps) => {
  const { onBackClick, onCancelClick, onFormSubmit, currentCustomer, entityList, rectifyRemarks } =
    props;
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext<IExportConsigneeForm>();
  useBeforeUnloadAndNavigate();

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      {rectifyRemarks?.length ? (
        <RectifyRemarksContainer rectifyRemarks={rectifyRemarks} module="Order" />
      ) : (
        <></>
      )}
      <ExporterContainer options={entityList} />
      <ConsigneeContainer currentCustomer={currentCustomer!} />
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick} disabled={isSubmitting}>
          Cancel
        </Button>
        <div>
          <Button
            variant="outlined-secondary"
            onClick={onBackClick}
            className={css.nextBtn}
            disabled={isSubmitting}>
            Back
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save and Proceed
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ExporterConsigneeForm;
