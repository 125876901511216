import { PerformaInvoiceStates } from '@helpers/types/pi';
import PIReview from './components/preview-tab';
import css from './index.module.scss';

interface IPreview {
  previewData: PerformaInvoiceStates;
  handleFormSubmit: () => void;
  handleCancelClick: () => void;
  handleBackClick: () => void;
  setOrderState: (orderState: PerformaInvoiceStates) => void;
  invoiceType: string;
  orderPi: any;
}

const GenerateProformaInvoiceReview = (props: IPreview) => {
  const {
    previewData,
    handleFormSubmit,
    handleCancelClick,
    handleBackClick,
    setOrderState,
    invoiceType,
    orderPi
  } = props;
  return (
    <main className={css.purchaseOrderWrapper}>
      <div className={css.purchaseOrderContainer}>
        <PIReview
          previewData={previewData}
          handleFormSubmit={handleFormSubmit}
          handleCancelClick={handleCancelClick}
          handleBackClick={handleBackClick}
          setOrderState={setOrderState}
          invoiceType={invoiceType}
          orderPi={orderPi}
        />
      </div>
    </main>
  );
};

export default GenerateProformaInvoiceReview;
