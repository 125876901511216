import css from './index.module.scss';
import CreateEnquiryDataFields from './createEnquiryDataFields';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, IconNode, Typography } from '@components/base';
import Images from '@assets/images';
import { DragDropUpload, TextAreaLabel, TextField } from '@components/common';
import { useCallback } from 'react';
import { FormType } from '@helpers/types/enquiry';
interface ICreateEnquiryContainerProps {
  handleFileDrop: (files: File[]) => void;
}

const CreateEnquiryContainer = (props: ICreateEnquiryContainerProps) => {
  const { control, setValue } = useFormContext<FormType>();
  const { handleFileDrop } = props;

  const docWatch = useWatch({ name: 'document', control });

  const handleDeleteFile = useCallback(() => {
    setValue('document', null);
  }, []);

  const { fields, append, remove } = useFieldArray({
    name: 'products',
    control
  });

  const handleAddProductField = () => {
    append({
      name: '',
      currency: null,
      target_price: '',
      quantity: '',
      unit: null
    });
  };

  return (
    <div className={css.createEnquiryContainerWrapper}>
      <div className={css.createEnquiryProductWrapper}>
        {fields.map((item: any, index: number) => {
          const handleDeleteClick = () => remove(index);
          return (
            <CreateEnquiryDataFields
              key={item.id}
              index={index}
              name={`products.${index}.name`}
              currency={`products.${index}.currency`}
              target_price={`products.${index}.target_price`}
              quantity={`products.${index}.quantity`}
              unit={`products.${index}.unit`}
              showDelete={fields.length > 1}
              onDeleteClick={handleDeleteClick}
            />
          );
        })}
        <Button
          variant="text"
          onClick={handleAddProductField}
          startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
          Add Item
        </Button>
      </div>
      <div className={css.createEnquiryPlaceOfDelivery}>
        <Controller
          name="place_of_delivery"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={`Place of Delivery`}
              placeholder="Enter Place of Delivery"
              error={fieldState.invalid}
              rootClassName={`${css.placeOfDeliveryFieldSpacing}`}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
      </div>
      <div className={css.createEnquiryDownloadWrapper}>
        <Typography variant="h5">Upload Supporting Documents</Typography>
        <Controller
          name="document"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DragDropUpload
              acceptImages={true}
              multiple={true}
              onDrop={(files) => {
                onChange(files);
                handleFileDrop(files);
              }}
              rootClassName={css.createEnquiryDocumentUpload}
            />
          )}
        />
        {docWatch && (
          <div className={css.fileUploadState}>
            <Typography variant="p" className={css.fileName}>
              {docWatch?.length} File(s) uploaded
            </Typography>
            <IconNode
              src={Images.deleteRed}
              alt="delete icon"
              component="button"
              className={css.deleteButton}
              onClick={handleDeleteFile}
            />
          </div>
        )}
      </div>
      <div className={css.createEnquiryRemarksWrapper}>
        <Typography variant="h5">Additional Comments</Typography>
        <Controller
          name="remarks"
          control={control}
          render={({ field, fieldState }) => (
            <TextAreaLabel
              {...field}
              inputMode="text"
              placeholder="Enter Additional Remarks/ Requirements "
              error={fieldState.invalid}
              rootClassName={css.createEnquiryRemarks}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
      </div>
    </div>
  );
};

export default CreateEnquiryContainer;
