import css from './index.module.scss';
import { Button, IconNode, Modal, Typography } from '@components/base';
import { SelectLabel, UploadButton, AutoComplete } from '@components/common';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Images from '@assets/images';
import { useEffect, useState } from 'react';
import { fetchAllProducts } from '@services/product.service';
import { ProductInfo } from '@helpers/types/product';
import notify from '@helpers/toastify-helper';
import { GradeInfo } from '@helpers/types/grade';
import { createProductGrade } from '@services/supplier.service';
import { useParams } from 'react-router-dom';
import { ChangeEvent } from 'react';
import { productGradeSchema } from '@helpers/yup/add-product.schema';
import { yupResolver } from '@hookform/resolvers/yup';

interface ProductHomeState {
  productCount: number;
  productList: ProductInfo[];
  searchQuery: string | null;
}

interface FormTypes {
  productName: ProductInfo | null;
  productGrade: GradeInfo | null;
  TDSDocument: File | null;
  IIPCertificate: File | null;
}
interface AddProductProps {
  open: boolean;
  onClose?: () => void;
  getProductListOfSupplier: () => void;
}

interface DocsObjectType {
  document_type: string;
  document_name: string;
}

const AddProduct = (props: AddProductProps) => {
  const { open, onClose, getProductListOfSupplier } = props;
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
    watch
  } = useForm<FormTypes>({
    resolver: yupResolver(productGradeSchema),
    defaultValues: {
      productName: null,
      productGrade: null,
      TDSDocument: null,
      IIPCertificate: null
    }
  });

  const params = useParams();

  const [productState, setProductState] = useState<ProductHomeState>({
    productCount: 0,
    productList: [],
    searchQuery: null
  });

  const { productCount, productList, searchQuery } = productState;

  const watchField = useWatch({
    name: ['productName'],
    control
  });

  const getProductList = async (event?: any, param?: any) => {
    let response: any;
    param ? response = await fetchAllProducts(undefined, param) : response = await fetchAllProducts();
    if (param) {
      if (response.success) {
        setProductState((prevState: any) => ({
          ...prevState,
          productList: [...response.data.results],
          productCount: response.data.count,
          searchQuery: param
        }));
      } else {
        setProductState((prevState: any) => ({
          ...prevState,
          productList: [],
          productCount: 0,
          searchQuery: param
        }));
        notify({ message: response.error ?? 'Unable to fetch product list ', severity: 'error' });
      }
    } else {
      if (response.success) {
        setProductState(() => ({
          productList: [...response.data.results],
          productCount: response.data.count,
          searchQuery: ''
        }));
      } else {
        setProductState(() => ({
          productList: [],
          productCount: 0,
          searchQuery: ''
        }));
        notify({ message: response.error ?? 'Unable to fetch product list ', severity: 'error' });
      }
    }
  };

  useEffect(() => {
    getProductList();
  }, [])

  useEffect(() => {
    if (watchField[0]?.name) {
      setValue('productGrade', null);
    }
  }, [watchField[0]?.name])

  const productSelectionHandler = (event: any, param: any) => {
    if (param) {
      setValue('productName', param);
    } else {
      setValue('productName', null);
    }
  };

  const handleFormSubmit = async (data: FormTypes) => {
    const formData = new FormData();
    const docsObject: Array<DocsObjectType> = [];
    const docsFile: Array<File> = [];
    const { id } = params;

    if (data.TDSDocument) {
      docsObject.push({
        document_type: 'TDS',
        document_name: data.TDSDocument.name
      });
      docsFile.push(data.TDSDocument);
    }
    if (data.IIPCertificate) {
      docsObject.push({
        document_type: 'IIP',
        document_name: data.IIPCertificate.name
      });
      docsFile.push(data.IIPCertificate);
    }
    const requestData = {
      supplier: id,
      product_grade: data?.productGrade?.grade_id,
      documents: docsObject
    };
    if (docsFile?.length) {
      docsFile.forEach((doc: File, index: number) => formData.append(`${index}`, doc));
    }
    formData.append('data', JSON.stringify(requestData));
    const response = await createProductGrade(formData);
    if (response?.success) {
      reset();
      getProductListOfSupplier();
      if (onClose) onClose();
      notify({ message: 'A new product has been added successfully!', dismissible: true });
    } else {
      notify({
        message: response.error ?? 'Error adding product',
        severity: 'error',
        dismissible: true
      });
    }
  };
  return (
    <Modal open={open} onClose={onClose} keepMounted>
      <div className={css.modalWrapper}>
        <div className={css.modalHeader}>
          <Typography variant="h4">Add Product</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close"
            component="button"
            onClick={onClose}
            className={css.modalCloseButton}
          />
        </div>
        <form className={css.modalForm} noValidate onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={css.modalContent}>
            <Controller
              name="productName"
              control={control}
              render={({ field, fieldState }) => (
                <AutoComplete
                  {...control}
                  required
                  isSearchable
                  label="Product Name"
                  placeholder="Start typing"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  options={productList}
                  keyOption="name"
                  width="auto"
                  onInputChange={getProductList}
                  onInputSelection={productSelectionHandler}
                />
              )}
            />
            <Controller
              name="productGrade"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  required
                  isSearchable
                  label="Product Grade"
                  placeholder="Select Product Grade"
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.grade_id}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  options={watchField[0]?.grades ?? []}
                />
              )}
            />
            <div className={css.documentUploadContainer}>
              <Typography variant="h5">Upload Documents</Typography>
              <div className={css.uploadContainer}>
                <div className={css.uploadContainerRow}>
                  <div style={{ display: 'flex' }}>
                    {watch('TDSDocument') ? (
                      <IconNode
                        className={css.uploadSuccess}
                        src={Images.illustrationGreen}
                        alt="success icon"
                      />
                    ) : (
                      ``
                    )}
                    <Typography>TDS</Typography>
                  </div>
                  <Controller
                    name="TDSDocument"
                    control={control}
                    render={({ field, fieldState }) => (
                      <div>
                        <UploadButton
                          {...field}
                          required
                          value={''}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setValue(
                              'TDSDocument',
                              e?.currentTarget?.files?.length ? e?.currentTarget?.files[0] : null
                            )
                          }
                        />
                      </div>
                    )}
                  />
                </div>
                {/* <div className={css.uploadContainerRow}>
                  <div style={{ display: 'flex' }}>
                    {watch('IIPCertificate') ? (
                      <IconNode
                        className={css.uploadSuccess}
                        src={Images.illustrationGreen}
                        alt="success icon"
                      />
                    ) : (
                      ``
                    )}
                    <Typography>IIP Certificate</Typography>
                  </div>
                  <Controller
                    name="IIPCertificate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <div>
                        <UploadButton
                          {...field}
                          required
                          value={''}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setValue(
                              'IIPCertificate',
                              e?.currentTarget?.files?.length ? e?.currentTarget?.files[0] : null
                            )
                          }
                        />
                      </div>
                    )}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className={css.actionBtnWrapper}>
            <Button variant="outlined-secondary" className={css.actionBtn} onClick={onClose}>
              Cancel
            </Button>
            <Button className={css.actionBtn} type="submit" disabled={isSubmitting}>
              Add Product
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default AddProduct;
