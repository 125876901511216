import { Typography } from '@components/base';
import { Avatar } from '@components/common';
import { IUSERTYPES } from '@helpers/types/people';
import React, { CSSProperties } from 'react';
import css from './index.module.scss';
import { Image, ImageProps } from '@components/base';

interface UserCardProps {
  src?: string;
  userInfo: IUSERTYPES[];
  style?: CSSProperties;
}

const UserCard = (props: UserCardProps) => {
  const { userInfo } = props;
  return (
    <React.Fragment>
      {userInfo?.map((user, index) => {
        return (
          <div key={index} className={css.userCardContainer}>
            <Image
              src={user?.profile_image}
              alt={`${user?.given_name} icon`}
              className={css.profileIcon}
              fallbackText={`${user?.given_name[0]?.toLocaleUpperCase() ?? ''}`}
            />
            <div className={css.userCardDetailColumn}>
              <Typography variant="h5" className={css.primaryTitleLabel}>
                {user?.given_name} {user?.family_name}
              </Typography>
              <Typography variant="p" className={css.secondaryText}>
                {user?.email}
              </Typography>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default UserCard;
