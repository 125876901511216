import { Divider, Typography } from '@components/base';
import { IOrderInfoTypes } from '@helpers/types/pi';
import { PurchaseOrderStates } from '@helpers/types/po';
import css from '../index.module.scss';

interface IOrderInfo {
  orderInfoData: PurchaseOrderStates;
}

const Invoice = (props: IOrderInfo) => {
  const {
    orderInfoData: { purchaseOrder }
  } = props;
  const newDate = new Date();
  const renderField = (label: string, value: string) => {
    return (
      <div>
        <Typography variant="pdoc">{label}</Typography>
        <Typography variant="h4">{value}</Typography>
      </div>
    );
  };
  return (
    <div className={css.boxWrapper}>
      <div className={css.invoiceContainer}>
        <div className={css.fieldWrapper}>
          {renderField('Purchase Order Date', `${String(purchaseOrder?.document_date)}`)}
        </div>
        <Divider className={css.invoiceDivider} />
        <div className={css.fieldWrapper}>
          {renderField('Purchase Order No', `${purchaseOrder?.document_number}`)}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
