import React, { useEffect, useState } from 'react';
import css from './index.module.scss';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { formatPortOptions } from '@helpers/utils';
import { AutoComplete, BoxContainer, InputDatePicker, SelectLabel } from '@components/common';
import { CONTAINER_TYPE, PORT_FIELD_TYPES } from '@helpers/constants';
import TextField from '@components/common/text-field';
import { fetchPortList } from '@services/util.service';
import notify from '@helpers/toastify-helper';
import Typography from '@components/base/typography';
import { Button, Loader } from '@components/base';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';
import { FieldsThatWillBeModified } from '../..';
import { OrderInfo } from '@helpers/types/order';

interface OrderDetailsProps {
  orderInfo: OrderInfo | null;
  editableFields: FieldsThatWillBeModified;
  setDirtyFields: (fields: any) => void;
  dirtyFields: any;
}

const OrderDetails = (props: OrderDetailsProps) => {
  const { orderInfo, editableFields, setDirtyFields, dirtyFields } = props;
  const {
    control,
    setValue,
    formState: { dirtyFields: formDirtyFields }
  } = useFormContext();

  const [portList, setPortList] = useState([]);
  const [portOfLoading, setPortOfLoading] = useState([]);
  const [portOfDischarge, setPortOfDischarge] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    fields: containerFields,
    append: containerAppend,
    remove: containerRemove
  } = useFieldArray({
    name: `containers_count_data`,
    control
  });

  const { fields: containerNumberFields } = useFieldArray({
    name: `containers`,
    control
  });

  const containerCountWatch = useWatch({
    name: `containers_count_data`,
    control
  });

  const containerWatch = useWatch({
    name: `containers`,
    control
  });

  useEffect(() => {
    if (formDirtyFields?.containers_count_data) {
      onValueChangeHandler('containers_count_data', containerCountWatch);
    }
  }, [containerCountWatch]);

  useEffect(() => {
    if (
      formDirtyFields?.containers?.length &&
      formDirtyFields?.containers.some((obj: any) =>
        Object.values(obj).some((value) => value === true)
      )
    ) {
      onValueChangeHandler('containers', containerWatch);
    }
  }, [containerWatch]);

  const handleAddcontainerField = () => {
    containerAppend({
      container_type: null,
      no_of_container: null
    });
  };

  const filterPorts = async (event?: any, param?: any, fieldType?: string) => {
    let portTypeLabel = '';
    if (orderInfo?.transportation_mode === 'SEA') {
      portTypeLabel = 'seaport';
    } else if (orderInfo?.transportation_mode === 'AIR') {
      portTypeLabel = 'airport';
    }
    if (param) {
      setIsLoading(true);
      const portList = await fetchPortList({ port_type: portTypeLabel, search_text: param });
      setIsLoading(false);
      if (portList?.success) {
        fieldType === PORT_FIELD_TYPES.PORT_OF_LOADING
          ? setPortOfLoading(portList.data.filter_options)
          : setPortOfDischarge(portList.data.filter_options);
      } else {
        notify({
          message: portList.error ?? 'Internal Server Error',
          severity: 'error'
        });
      }
    } else {
      fieldType === PORT_FIELD_TYPES.PORT_OF_LOADING
        ? setPortOfLoading(portList)
        : setPortOfDischarge(portList);
    }
  };

  const onValueChangeHandler = (type: any, value: any) => {
    if (!type || !value) return;
    if (type === 'port_of_loading') {
      setDirtyFields((prev: any) => ({
        ...prev,
        port_of_loading: value
      }));
    } else if (type === 'place_of_delivery') {
      setDirtyFields((prev: any) => ({
        ...prev,
        place_of_delivery: value
      }));
    } else if (type === 'port_of_discharge') {
      setDirtyFields((prev: any) => ({
        ...prev,
        port_of_discharge: value
      }));
    }
    if (type === 'containers_count_data') {
      setDirtyFields((prev: any) => ({
        ...prev,
        containers_count_data: value
      }));
    }
    if (type === 'containers') {
      setDirtyFields((prev: any) => ({
        ...prev,
        containers: value
      }));
    }
    if (type === 'bl_date') {
      setDirtyFields((prev: any) => ({
        ...prev,
        bl_date: value
      }));
    }
    if (type === 'awb_date') {
      setDirtyFields((prev: any) => ({
        ...prev,
        awb_date: value
      }));
    }
  };

  return (
    <BoxContainer title="Order Details" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <Controller
            name="port_of_loading"
            control={control}
            render={({ field, fieldState }) => {
              const onPortSelection = (event: any, param: any) => {
                if (param) {
                  setValue('port_of_loading', param, { shouldDirty: true });
                  onValueChangeHandler('port_of_loading', param);
                } else {
                  setValue('port_of_loading', null, { shouldDirty: true });
                  onValueChangeHandler('port_of_loading', null);
                }
              };
              const formattedPortOfLoading = formatPortOptions(portOfLoading);
              return (
                <AutoComplete
                  {...field}
                  required
                  disabled={!editableFields?.port_of_loading}
                  label="Port Of Loading"
                  placeholder="Start typing"
                  options={formattedPortOfLoading}
                  onInputChange={(event: any, param: any) =>
                    filterPorts(event, param, PORT_FIELD_TYPES.PORT_OF_LOADING)
                  }
                  onInputSelection={onPortSelection}
                  keyOption="label"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldWrapper}
                />
              );
            }}
          />
          <Controller
            name="place_of_delivery"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                disabled={!editableFields?.place_of_delivery}
                inputMode="text"
                onChange={(e) => {
                  field.onChange(e);
                  onValueChangeHandler('place_of_delivery', e.target.value);
                }}
                enterKeyHint="next"
                autoComplete="street-address"
                label="Place of Delivery"
                placeholder="Enter place"
                error={fieldState.invalid}
                rootClassName={css.fieldWrapper}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="port_of_discharge"
            control={control}
            render={({ field, fieldState }) => {
              const onPortSelection = (event: any, param: any) => {
                if (param) {
                  setValue('port_of_discharge', param, { shouldDirty: true });
                  onValueChangeHandler('port_of_discharge', param);
                } else {
                  setValue('port_of_discharge', null, { shouldDirty: true });
                  onValueChangeHandler('port_of_discharge', null);
                }
              };
              const formattedPortOfDischarge = formatPortOptions(portOfDischarge);
              return (
                <AutoComplete
                  {...field}
                  required
                  label="Port Of Discharge"
                  disabled={!editableFields?.port_of_discharge}
                  placeholder="Start typing"
                  options={formattedPortOfDischarge}
                  onInputChange={(event: any, param: any) =>
                    filterPorts(event, param, PORT_FIELD_TYPES.PORT_OF_DISCHARGE)
                  }
                  onInputSelection={onPortSelection}
                  keyOption="label"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldWrapper}
                />
              );
            }}
          />
          {orderInfo?.awb_date && (
            <Controller
              name="awb_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  // setDatePickerOpen={setIsDatePickerOpen}
                  value={field.value}
                  placeholder="Select date"
                  disabled={!editableFields?.bl_date}
                  label={`AWB Date`}
                  onSelect={(day: Date | undefined) => {
                    field.onChange(day ?? null);
                    onValueChangeHandler('awb_date', day);
                  }}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldWrapper}
                />
              )}
            />
          )}
          {orderInfo?.bl_date && (
            <Controller
              name="bl_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  // setDatePickerOpen={setIsDatePickerOpen}
                  value={field.value}
                  placeholder="Select date"
                  disabled={!editableFields?.bl_date}
                  label={`BL Date`}
                  onSelect={(day: Date | undefined) => {
                    field.onChange(day ?? null);
                    onValueChangeHandler('bl_date', day);
                  }}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldWrapper}
                />
              )}
            />
          )}
        </div>
        {editableFields?.containers_count_data && containerFields?.length > 0 && (
          <div className={css.containerWrapper}>
            <Typography variant="p" className={css.titleWrapper}>
              Container Details
            </Typography>
            {containerFields.map((item: any, index: number) => {
              const handleDeleteClick = () => containerRemove(index);
              return (
                <>
                  <div className={css.headerWrapper}>
                    <Typography variant="h5">Item {index + 1}</Typography>
                    {containerFields?.length > 1 && editableFields?.containers_count_data && (
                      <Button
                        variant="text"
                        title="Delete Item"
                        disabled={!editableFields?.containers_count_data}
                        onClick={handleDeleteClick}
                        startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}>
                        Delete
                      </Button>
                    )}
                  </div>
                  <div className={css.rowWrapper}>
                    <Controller
                      name={`containers_count_data.${index}.no_of_container`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          inputMode="numeric"
                          disabled={!editableFields?.containers_count_data}
                          enterKeyHint="next"
                          label="Number of containers"
                          placeholder="Enter number"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          rootClassName={css.fieldWrapper}
                        />
                      )}
                    />
                    <Controller
                      name={`containers_count_data.${index}.container_type`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <SelectLabel
                          {...field}
                          required
                          isSearchable
                          options={CONTAINER_TYPE}
                          isDisabled={!editableFields?.containers_count_data}
                          label="Container type"
                          placeholder="Select option"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          getOptionLabel={(option: any) => option.label}
                          rootClassName={css.fieldWrapper}
                        />
                      )}
                    />
                  </div>
                </>
              );
            })}
            <Button
              variant="text"
              disabled={!editableFields?.containers_count_data}
              onClick={handleAddcontainerField}
              startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
              Add Container
            </Button>
          </div>
        )}

        {editableFields?.containers && (
          <div className={css.containerWrapper}>
            <Typography variant="p" className={css.titleWrapper}>
              Container Numbers
            </Typography>
            <div className={css.container}>
              {containerNumberFields.map((item: any, index: number) => {
                return (
                  <>
                    <div className={css.rowWrapper}>
                      <Controller
                        name={`containers.${index}.container_number`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            required
                            inputMode="numeric"
                            disabled={!editableFields?.containers}
                            enterKeyHint="next"
                            label={item?.container_type}
                            placeholder="Enter number"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                            rootClassName={css.fieldWrapper}
                          />
                        )}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {/* <Loader open={isLoading} /> */}
    </BoxContainer>
  );
};

export default OrderDetails;
