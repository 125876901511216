import { memo, useMemo, useState, useEffect } from 'react';
import css from './index.module.scss';
import { Button, CheckboxLabel, Divider, IconNode, Typography } from '@components/base';
import {
  BoxContainer,
  SelectLabel,
  TextAreaLabel,
  TextField,
  RectifyRemarksContainer,
  AutoComplete
} from '@components/common';
import { useFieldArray, useFormContext, Controller, useWatch } from 'react-hook-form';
import Images from '@assets/images';
import { ProductInfo } from '@helpers/types/product';
import { IOrderItemForm } from '@helpers/types/add-order';
import { GradeInfo } from '@helpers/types/grade';
import { fetchSupplierProductList } from '@services/supplier.service';
import notify from '@helpers/toastify-helper';
import { SupplierProductGrade } from '@helpers/types/supplier-grade';
import { fetchAllProducts } from '@services/product.service';
import {
  CURRENCY,
  DEFAULT_AMOUNT_ROUND_OFF,
  QUANTITY_INPUT_DISABLE_SWITCH,
  UNITS_OF_WEIGHTS
} from '@helpers/constants';
import { PackingDataFields } from '@components/common';
import { generateQuantityFromPackingDetails } from '@helpers/utils';

interface ItemDetailContainerProps {
  productList: ProductInfo[];
  rectifyRemarks?: string;
  countryOptions?: Array<{ label: any; value: any }>;
  filterCountryOptions?: any;
}

const ItemDetailContainer = (props: ItemDetailContainerProps) => {
  const { productList, rectifyRemarks, countryOptions, filterCountryOptions } = props;
  const { control } = useFormContext<IOrderItemForm>();

  const { fields, append, remove } = useFieldArray({
    name: 'items',
    control
  });

  const watch = useWatch({
    name: 'items',
    control
  });

  const total_amount = watch.reduce(
    (previousValue, item) => Number(item.rate_per_unit) * Number(item.quantity) + previousValue,
    0
  );

  const handleAddField = () => {
    append({
      name: null,
      quantity: '',
      rate_per_unit: '',
      product_grade: null,
      description: '',
      supplier: null,
      hs_code: '',
      cas_number: '',
      country_of_origin: null,
      package: [
        {
          type: null,
          no_of_package: null,
          weight: null,
          unit: null
        }
      ],
      is_pre_shipment_sample_required: false
    });
  };

  return (
    <>
      {' '}
      {rectifyRemarks?.length ? (
        <RectifyRemarksContainer rectifyRemarks={rectifyRemarks} module="Order" />
      ) : (
        <></>
      )}
      <BoxContainer title="Item Details" className={css.mainWrapper}>
        <div className={css.boxWrapper}>
          <AdhocOrderDetailForm />
        </div>
        <Divider className={css.dividerWrapper} />
        <div className={css.boxWrapper}>
          <div className={css.itemFieldWrapper}>
            {fields.map((item, index) => {
              const handleDeleteClick = () => remove(index);
              return (
                <ItemDetailFields
                  key={item.id}
                  index={index}
                  name={`items.${index}.name`}
                  quantity={`items.${index}.quantity`}
                  rate={`items.${index}.rate_per_unit`}
                  description={`items.${index}.description`}
                  grade={`items.${index}.product_grade`}
                  supplier={`items.${index}.supplier`}
                  hs_code={`items.${index}.hs_code`}
                  cas_number={`items.${index}.cas_number`}
                  country_of_origin={`items.${index}.country_of_origin`}
                  showDelete={fields.length > 1}
                  productList={productList}
                  onDeleteClick={handleDeleteClick}
                  countryOptions={countryOptions}
                  filterCountryOptions={filterCountryOptions}
                  is_pre_shipment_sample_required={`items.${index}.is_pre_shipment_sample_required`}
                />
              );
            })}
            <Button
              variant="text"
              onClick={handleAddField}
              startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
              Add Item
            </Button>
          </div>
          <Divider className={css.dividerWrapper} />
          <TextField
            disabled
            value={total_amount ? total_amount.toFixed(DEFAULT_AMOUNT_ROUND_OFF).toString() : '0'}
            required
            label="Total Amount"
            placeholder="Total amount"
          />
        </div>
      </BoxContainer>
    </>
  );
};

interface ItemDetailFieldsProps {
  index: number;
  showDelete?: boolean;
  onDeleteClick?: () => void;
  name: string;
  quantity: string;
  rate: string;
  grade: string;
  description: string;
  supplier: string;
  productList: ProductInfo[];
  hs_code: string;
  cas_number: string;
  country_of_origin: string;
  countryOptions?: Array<{ label: any; value: any }>;
  filterCountryOptions?: any;
  is_pre_shipment_sample_required: string;
}

interface ItemDetailFieldsStates {
  supplierOptions: SupplierProductGrade[];
  isLoading: boolean;
  productOptions: any;
  isOpen: boolean;
  productQuery: string | null;
}

const ItemDetailFields = (props: ItemDetailFieldsProps) => {
  const {
    description,
    index,
    grade,
    name,
    quantity,
    rate,
    supplier,
    onDeleteClick,
    showDelete,
    productList,
    hs_code,
    cas_number,
    country_of_origin,
    countryOptions,
    filterCountryOptions,
    is_pre_shipment_sample_required
  } = props;

  const [fieldOptionState, setFieldOptionState] = useState<ItemDetailFieldsStates>({
    supplierOptions: [],
    productOptions: [],
    isLoading: false,
    isOpen: false,
    productQuery: null
  });

  const { supplierOptions, isLoading, isOpen, productOptions, productQuery } = fieldOptionState;

  const { control, setValue } = useFormContext();

  const {
    fields: packageFields,
    append: packageAppend,
    remove: packageRemove
  } = useFieldArray({
    name: `items.${index}.package`,
    control
  });

  const handleAddPackageField = () => {
    packageAppend({
      type: null,
      no_of_package: null,
      weight: null,
      unit: null
    });
  };

  const watchField = useWatch({
    name: [name, quantity, rate, 'currency'],
    control
  });

  const packageWatch = useWatch({
    name: `items.${index}.package`,
    control
  });

  const unitOfWeightWatch = useWatch({
    name: 'unit_of_weight',
    control
  });

  const fetchProducts = async () => {
    setFieldOptionState((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));
    try {
      const productListResponse = await fetchAllProducts();

      if (productListResponse?.success) {
        setFieldOptionState((prevState: any) => ({
          ...prevState,
          isLoading: false,
          productOptions: productListResponse?.data?.results
            ? productListResponse?.data?.results
            : [],
          isOpen: true
        }));
      } else if (productListResponse?.error) {
        setFieldOptionState((prevState: any) => ({
          ...prevState,
          isLoading: false,
          isOpen: true
        }));
        notify({ message: productListResponse?.error, severity: 'error' });
      }
    } catch (error) {
      setFieldOptionState((prevState: any) => ({
        ...prevState,
        isLoading: false
      }));
      notify({ message: 'Error fetching products', severity: 'error' });
    }
  };

  useEffect(() => {
    if (watchField?.length && watchField[0]?.name) {
      setFieldOptionState((prevState: any) => ({
        ...prevState,

        productQuery: watchField[0]
      }));
    }
    fetchProducts();
  }, []);

  const handleItemSelection = (event: any, newValue: any) => {
    const { name, grade, supplier } = props;
    if (newValue) {
      const data = newValue as any;
      const productDetails = watchField[0];
      setValue(name, data, { shouldValidate: true });
      setValue(grade, null);
      setValue(supplier, null);
      //Prefill item description
      setValue(description, `${data?.description}`);
      setValue(hs_code, data?.hs_code);
      setValue(cas_number, data?.CAS_number);
      setFieldOptionState((prevState: any) => ({
        ...prevState,
        isLoading: false,
        productQuery: newValue,
        isOpen: false
      }));
    } else setValue(name, null);
  };

  const handleChange = async (event: any, param: any) => {
    setFieldOptionState((prevState: any) => ({
      ...prevState,
      isLoading: true
      // productQuery: param ? param : ''
    }));
    const productListResp = await fetchAllProducts(undefined, param ? param : '');
    if (productListResp?.success) {
      setFieldOptionState((prevState: any) => ({
        ...prevState,
        isLoading: false,
        productOptions: productListResp?.data?.results ? productListResp?.data?.results : [],
        isOpen: true
      }));
    } else if (productListResp?.error) {
      setFieldOptionState((prevState: any) => ({
        ...prevState,
        isLoading: false,
        isOpen: true
      }));
      notify({ message: productListResp?.error, severity: 'error' });
    }
  };

  const handleGradeSelect = async (param: unknown) => {
    const { grade, supplier } = props;
    if (param) {
      const newValue = param as GradeInfo;
      setFieldOptionState((prevState) => ({
        ...prevState,
        isLoading: true
      }));
      setValue(supplier, null);
      //const response = await fetchSupplierProductList({ product_grade_id: newValue.grade_id });
      // if (response.success) {
      //const { data } = response;
      setValue(grade, newValue, { shouldValidate: true });
      // setFieldOptionState((prevState) => ({
      //   ...prevState,
      //   isLoading: false,
      //   supplierOptions: data.results
      // }));
      // } else if (response.error) {
      setFieldOptionState((prevState) => ({
        ...prevState,
        isLoading: false,
        supplierOptions: []
      }));
      // notify({
      //   message: 'Unable to fetch supplier grade list',
      //   severity: 'error',
      //   dismissible: true
      // });
      // }
    } else {
      setValue(grade, null, { shouldValidate: true });
      setValue(supplier, null);
    }
  };

  const handleOutsideClick = () => {
    setFieldOptionState((prevState: any) => ({ ...prevState, isOpen: false, isLoading: false }));
  };

  /**
   * Calculate quantity from packing details
   */

  useEffect(() => {
    setValue(quantity, generateQuantityFromPackingDetails(packageWatch, unitOfWeightWatch));
  }, [packageWatch, unitOfWeightWatch]);

  return (
    <div className={css.itemMainWrapper}>
      <div className={css.headerWrapper}>
        <Typography variant="h4">Item {index + 1}</Typography>
        {showDelete && (
          <Button
            variant="text"
            title="Delete Item"
            onClick={onDeleteClick}
            startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}>
            Delete
          </Button>
        )}
      </div>
      <div className={css.rowWrapper1}>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }) => (
            <AutoComplete
              {...field}
              required
              label="Item Name"
              placeholder="Start typing"
              onClickAway={handleOutsideClick}
              options={productOptions}
              isOpen={isOpen}
              isLoading={isLoading}
              onInputChange={handleChange}
              onInputSelection={handleItemSelection}
              keyOption="name"
              value={productQuery}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              rootClassName={css.fieldSpacing2}
            />
          )}
        />
        <Controller
          name={country_of_origin}
          control={control}
          render={({ field, fieldState }) => {
            const onCountrySelection = (event: any, param: any) => {
              if (param) {
                setValue(country_of_origin, param);
              } else {
                setValue(country_of_origin, null);
              }
            };
            return (
              <AutoComplete
                {...field}
                label="Country of Origin"
                placeholder="Start typing"
                options={countryOptions}
                onInputChange={filterCountryOptions}
                onInputSelection={onCountrySelection}
                keyOption="label"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                rootClassName={css.fieldWrapper1}
              />
            );
          }}
        />
      </div>
      <div className={css.pacakageSectionWrapper}>
        <Typography variant="p" className={css.titleWrapper}>
          Packaging details
        </Typography>
        <PackingDataFields
          fields={packageFields}
          handleAddField={handleAddPackageField}
          remove={packageRemove}
          unitOfWeightWatch={unitOfWeightWatch}
          itemIndex={index}
        />
      </div>
      <div className={css.rowWrapper}>
        <Controller
          name={quantity}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              inputMode="decimal"
              enterKeyHint="next"
              autoComplete="off"
              disabled={QUANTITY_INPUT_DISABLE_SWITCH}
              label={`Quantity ${unitOfWeightWatch ? `in ${unitOfWeightWatch?.label}` : ``}`}
              placeholder="Enter rate"
              rootClassName={`${css.fieldWrapper} ${css.fieldSpacing}`}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        <Controller
          name={rate}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              label={`Rate per ${unitOfWeightWatch?.label ?? ''} (${watchField[3]?.label ?? ``})`}
              placeholder="Enter"
              error={fieldState.invalid}
              rootClassName={` ${css.fieldSpacing}`}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
      </div>

      <div className={css.rowWrapper}>
        <Controller
          name={grade}
          control={control}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              required
              isLoading={isLoading}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.grade_id}
              options={watchField[0]?.grades ?? []}
              onChange={handleGradeSelect}
              label="Grade"
              placeholder="Select grade"
              rootClassName={css.fieldWrapper}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              isSearchable
            />
          )}
        />
        <TextField
          disabled
          value={`${(watchField[1] * watchField[2]).toFixed(DEFAULT_AMOUNT_ROUND_OFF)}`.toString()}
          label={`Amount ${watchField[3] ? `(${watchField[3]?.label})` : ``}`}
          placeholder="Enter amount"
        />
      </div>
      <div className={css.rowWrapper}>
        <Controller
          name={description}
          control={control}
          render={({ field, fieldState }) => (
            <TextAreaLabel
              {...field}
              label="Item Description"
              placeholder="Enter description"
              rows={5}
              rootClassName={css.fieldSpacing}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
      </div>

      <div className={css.rowWrapper}>
        <Controller
          name={hs_code}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              type="text"
              enterKeyHint="next"
              label="HS Code"
              placeholder="Enter HS Code"
              rootClassName={`${css.fieldWrapper} ${css.fieldSpacing}`}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
        <Controller
          name={cas_number}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              disabled
              type="text"
              enterKeyHint="next"
              label="CAS Number"
              placeholder="CAS Number"
              rootClassName={`${css.fieldSpacing}`}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />}
            />
          )}
        />
      </div>
      <div className={css.rowWrapper}>
        <Controller
          name={is_pre_shipment_sample_required}
          control={control}
          render={({ field }) => (
            <CheckboxLabel
              {...field}
              label="Is pre-shipment sample required?"
              value={`${field.value}`}
              checked={field.value}
            />
          )}
        />
      </div>
      <Divider className={css.divider} />
    </div>
  );
};

const AdhocOrderDetailForm = () => {
  const { control } = useFormContext();
  return (
    <div className={css.itemMainWrapper}>
      <div className={css.rowWrapper}>
        <Controller
          name="currency"
          control={control}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              required
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              options={CURRENCY}
              label="Currency"
              placeholder="Select one"
              rootClassName={css.fieldWrapper}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              isSearchable
            />
          )}
        />
        <Controller
          name="unit_of_weight"
          control={control}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              required
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              options={UNITS_OF_WEIGHTS}
              label="Unit of Weight"
              placeholder="Select"
              rootClassName={css.fieldWrapper}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              isSearchable
            />
          )}
        />
      </div>
    </div>
  );
};

export default memo(ItemDetailContainer);
