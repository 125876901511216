import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import './index.style.scss';

const Tabs = (props: MuiTabsProps) => {
  return <MuiTabs {...props}>{props.children}</MuiTabs>;
};

Tabs.defaultProps = {
  scrollButtons: 'auto',
  variant: 'scrollable'
};

export default Tabs;
