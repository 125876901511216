import * as yup from 'yup';

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

export { selectSchema };
export { loginSchema, forgotPasswordSchema, resetPasswordSchema } from './user-login.schema';
export { addSupplierSchema, addBankDetailSchema } from './add-supplier.schema';
export { addProductSchema, addGradesSchema } from './add-product.schema';
export { addDocument } from './add-document.schema';
export { addCustomerSchema, pocSchema } from './add-customer.schema';
export { boeCustomerSchema } from './generate-boe.schema';
export { rejectOrderSchema } from './order-tasks.schema';
