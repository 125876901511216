import { Typography } from '@components/base';
import { BoxContainer, DataGrid } from '@components/common';
import { sanitizeValue } from '@helpers/utils';
import { memo, useMemo } from 'react';
import css from '../index.module.scss';
import { IOrderItem, ITaskItemProps } from '@helpers/types/task-tabs';
interface IOrderItemPreviewProps {
  data: ITaskItemProps;
}

interface IItemDetailContainerProps {
  data: IOrderItem;
  index: number;
}

const OrderItemPreview = (props: IOrderItemPreviewProps) => {
  const { data } = props;
  const { order_items } = data;
  return (
    <BoxContainer title="Order Items Details">
      <div className={css.outlineWrapper}>
        {order_items.map((item: IOrderItem, index: number) => (
          <ItemDetailContainer key={index} index={index} data={item} />
        ))}
      </div>
    </BoxContainer>
  );
};

const ItemDetailContainer = (props: IItemDetailContainerProps) => {
  const { data, index } = props;
  const { product_grade_name, package_details, product_name, hs_code, net_wt_of_packages, gross_wt_of_packages } = data;

  const [itemColumns, itemData] = useMemo(() => {
    const coloumns: any = [
      {
        Header: 'Product Name',
        accessor: 'productName'
      },
      {
        Header: 'Packaging Details',
        accessor: 'packageDetails'
      },
      {
        Header: 'Net Weight',
        accessor: 'netWeight'
      },
      {
        Header: 'Gross Weight',
        accessor: 'grossWeight'
      }
    ];

    const rows: any = [
      {
        productName: (
          <>
            <Typography variant="p">{product_name}</Typography>
            <div className={css.productTable}>
              <Typography variant="label">
                Product Grade Name: {sanitizeValue(product_grade_name, 'Not Available')}
              </Typography>
              <Typography variant="label">
                HS Code: {sanitizeValue(hs_code, 'Not Available')}
              </Typography>
            </div>
          </>
        ),
        packageDetails: sanitizeValue(package_details, 'Not Available'),
        netWeight: sanitizeValue(net_wt_of_packages, 'Not Available'),
        grossWeight: sanitizeValue(gross_wt_of_packages, 'Not Available')
      }
    ];

    return [coloumns, rows];
  }, []);

  return (
    <div>
      <DataGrid columns={itemColumns} data={itemData} tableStyle={{ width: '100%' }} />
    </div>
  );
};

export default memo(OrderItemPreview);
