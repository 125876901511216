import { ReactNode, useContext, KeyboardEvent } from 'react';
import { AccordionContext } from './accordion';
import { ReactComponent as ArrowDown } from '@assets/images/chevron-down.svg';

interface AccordionHeaderProps {
  children?: ReactNode;
  className?: string;
}

const AccordionHeader = (props: AccordionHeaderProps) => {
  const { children, className } = props;
  const accordionConsumer = useContext(AccordionContext);

  if (!accordionConsumer)
    throw new Error('Context is not initialized, Wrap this component with Accordion');

  const { open, disabled, changeAccordionState } = accordionConsumer;

  const disabledClass = disabled
    ? 'accordion-header-wrapper accordion-disabled'
    : 'accordion-header-wrapper';
  const chevronClass = open ? 'chevron-rotate' : '';

  const handleClick = () => {
    changeAccordionState(!open);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter') changeAccordionState(!open);
  };

  return (
    <div
      className={`${disabledClass} ${className}`}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={disabled ? -1 : 0}
      role="button"
      aria-expanded={open}
      aria-disabled={disabled}>
      <div className="accordion-header-content">{children}</div>
      <div className={`accordion-chevron-wrapper ${chevronClass}`}>
        <ArrowDown />
      </div>
    </div>
  );
};

export default AccordionHeader;
