import React, { useEffect, useState } from 'react';
import css from './index.module.scss';
import { getReadableOrderIdList, getTaskTypeList } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { Controller, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { Button, IconNode, Typography } from '@components/base';
import Images from '@assets/images';
import { AutoComplete, InputDatePicker, SelectLabel } from '@components/common';
import { ISelect } from '@helpers/types';

interface IReadableOrderId {
  order_id: string;
  readable_order_id: string;
}

interface IFormType {
  startTaskDate: Date | null;
  endTaskDate: Date | null;
  orderId: IReadableOrderId[];
  taskType: ISelect[];
}

interface IFilterDrawerProps {
  onApply: (data: any) => Promise<void>;
  onClose: () => void;
  value: IFormType | null;
}

const FilterDrawer = (props: IFilterDrawerProps) => {
  const { onApply, onClose, value } = props;
  const [readableOrderIdList, setReadableOrderIdList] = useState([]);
  const [taskTypeList, setTaskTypeList] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty, isSubmitting }
  } = useForm<IFormType>({
    defaultValues: {
      startTaskDate: null,
      endTaskDate: null,
      orderId: [],
      taskType: []
    }
  });

  const getOrderIdList = async () => {
    const response = await getReadableOrderIdList();
    if (response.success) {
      setReadableOrderIdList(response.data.results);
    } else {
      notify({
        message: response.error ?? 'Unable To Fetch Order List',
        severity: 'error'
      });
    }
  };

  const getTaskTypes = async () => {
    const response = await getTaskTypeList();
    if (response.success) {
      setTaskTypeList(response.data);
    } else {
      notify({
        message: response.error ?? 'Unable To Fetch Task Type List',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    if (value) {
      const transformedValue = {
        startTaskDate: value.startTaskDate ? new Date(value.startTaskDate) : null,
        endTaskDate: value.endTaskDate ? new Date(value.endTaskDate) : null,
        orderId: value.orderId || [],
        taskType: value.taskType || []
      };

      reset(transformedValue, { keepDirty: false });
    }
    getOrderIdList();
    getTaskTypes();
  }, []);

  const handleOrderIdChange = async (event: any, param: string) => {
    const response = await getReadableOrderIdList(param ?? '');
    if (response.success) {
      setReadableOrderIdList(response.data.results);
    } else {
      notify({
        message: response.error ?? 'Unable To Fetch Order List',
        severity: 'error'
      });
    }
  };

  const handleFormSubmit: SubmitHandler<IFormType> = async (data) => {
    onApply?.(data);
  };

  const onReset = () => {
    const defaultValues = {
      startTaskDate: null,
      endTaskDate: null,
      orderId: [],
      taskType: []
    };
    reset(defaultValues, { keepDirty: false });
  };
  return (
    <>
      <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h3">Filter</Typography>
          <div className={css.actionWrapper}>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Applying Filters' : 'Apply Filters'}
            </Button>
            <Button variant="outlined-secondary" onClick={onReset} disabled={isSubmitting}>
              Reset
            </Button>
            <IconNode src={Images.close} alt="filter icon" onClick={onClose} />
          </div>
        </div>
        <div className={css.mainSection}>
          <div className={css.autoCompleteWrapper}>
            <Controller
              name="orderId"
              control={control}
              render={({ field, fieldState }) => {
                const onOrderSelection = (event: any, param: any) => {
                  if (param) {
                    setValue('orderId', param);
                  } else {
                    setValue('orderId', []);
                  }
                };
                return (
                  <AutoComplete
                    {...field}
                    label="Order Id"
                    multiple
                    options={readableOrderIdList}
                    onInputChange={(event: any, param: string) => handleOrderIdChange(event, param)}
                    onInputSelection={onOrderSelection}
                    keyOption="readable_order_id"
                    getOptionValue={(option: IReadableOrderId) => option.readable_order_id}
                    onSelect={(option: any) => {
                      field.onChange(option);
                    }}
                    placeholder="Search Order"
                    width="auto"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </div>
          <Controller
            name="taskType"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isClearable
                label="Task Type"
                options={taskTypeList}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <div className={css.orderWrapper}>
            <Controller
              name={`startTaskDate`}
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="Task Start Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.dateWrapper}
                />
              )}
            />
            <Controller
              name={`endTaskDate`}
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="Task End Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.dateWrapper}
                />
              )}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default FilterDrawer;
