/* eslint-disable @typescript-eslint/no-var-requires */
import { Typography } from '@components/base';
import { IItemDetail, PerformaInvoiceStates } from '@helpers/types/pi';
import { generateReadablePackingDetails, getCommaSepratedValue } from '@helpers/utils';
import css from '../index.module.scss';
import { useEffect, useState } from 'react';
import { fetchAmountInWords } from '@services/util.service';

interface IItem {
  itemData: PerformaInvoiceStates;
}

const Item = (props: IItem) => {
  const { itemData } = props;
  const { itemInfo, itemDetailsInfo } = itemData;
  const { items, currency, unit_of_weight } = itemInfo;
  const customItems = itemDetailsInfo?.custom_items;

  const [totalAmountInWords, setTotalAmountInWords] = useState('');

  const totalAmount = items.reduce(
    (previousValue: number, item: any) =>
      Number(item.quantity_mt) * Number(item.rate_mt_usd) + previousValue,
    0
  );

  const total_amount = customItems
    ? customItems.reduce(
        (previousValue: number, item: IItemDetail) =>
          Number(item.custom_item_amount) + previousValue,
        0
      )
    : 0;

  const display_total_amount = Number(totalAmount) + total_amount;

  useEffect(() => {
    (async () => {
      if (display_total_amount > 0) {
        const response = await fetchAmountInWords({
          amount: display_total_amount.toString(),
          currency_type: currency
        });
        if (response?.success) {
          setTotalAmountInWords(response.data.amount_in_words);
        }
      }
    })();
  }, [display_total_amount]);

  return (
    <div className={css.boxWrapper}>
      <div className={css.itemDetailsContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Item Details</Typography>
        </div>
        <div className={css.fieldTable}>
          <div className={css.tableHeader}>
            <Typography>S.No.</Typography>
            <Typography>Item Details</Typography>
            <Typography>Qty. in {unit_of_weight}</Typography>
            <Typography>Price/Unit</Typography>
            <Typography>Amount</Typography>
          </div>
          {items?.map((val: any, index: number) => {
            return (
              <div className={css.tableContent} key={index}>
                <Typography>{index + 1}</Typography>
                <div>
                  <Typography>{val.product_name}</Typography>
                  <div className={css.secondaryText}>
                    {/* {`${val.product_grade_name}\n${val.product_grade_description}\n${val.product_grade_packaging_details}`} */}
                    {val.order_item_description_pi}
                    {val?.country_of_origin_display_value && (
                      <div className={css.secondaryText}>
                        Country of Origin: {''}
                        {val.country_of_origin_display_value}{' '}
                      </div>
                    )}
                    {val?.package && (
                      <div className={css.secondaryText}>
                        Packaging Details: {''}
                        {generateReadablePackingDetails(val.package)?.join(', ')}{' '}
                      </div>
                    )}
                    <>
                      <br />
                      <Typography variant="label">
                        Pre-Shipment Sample Required:{' '}
                        {val?.is_pre_shipment_sample_required ? 'Yes' : 'No'}
                      </Typography>
                    </>
                  </div>
                </div>
                <Typography>{getCommaSepratedValue(val.quantity_pi as string) ?? ''}</Typography>
                <Typography>
                  {currency} {getCommaSepratedValue(val.rate_pi as string) ?? ''}
                </Typography>
                <Typography>
                  {currency} {getCommaSepratedValue(val.total_amount as string) ?? ''}
                </Typography>
              </div>
            );
          })}
          {customItems?.map((val: any, index: number) => {
            return (
              <div className={css.tableContent} key={index}>
                <Typography>{items.length + index + 1}</Typography>
                <div>
                  <Typography>{val.custom_item_name}</Typography>
                  <div className={css.secondaryText}>{val.custom_item_description} </div>
                </div>
                <Typography>{val.custom_item_quantity ?? ''}</Typography>
                <Typography>{getCommaSepratedValue(val.custom_item_rate) ?? ''}</Typography>
                <Typography>
                  {getCommaSepratedValue(String(Number(val.custom_item_amount)))}
                </Typography>
              </div>
            );
          })}
          <div className={css.totalContainer}>
            <Typography variant="h4">Total</Typography>
            <Typography variant="h4">
              {currency} {getCommaSepratedValue(String(display_total_amount))}
            </Typography>
          </div>
        </div>

        <div className={css.invoiceWords}>
          <Typography variant="label">Invoice Amount in Words</Typography>
          <Typography variant="body">{totalAmountInWords}</Typography>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Total Amount</Typography>
            <Typography variant="body">
              {currency} {getCommaSepratedValue(String(display_total_amount)) ?? ''}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
