import React from 'react';
import css from './index.module.scss';
import { Button, Typography } from '@components/base';
import { BoxContainer } from '@components/common';
import InvoicePreview from './invoice-preview';
import PartyPreview from './parties-preview';
import OrderItemPreview from './order-preview';
import RemarksPreview from './remarks-preview';

const POPreview = (props: any) => {
  const {
    isRectify,
    rectifyRemarks,
    onCancelClick,
    onBackClick,
    onFormSubmit,
    purchaseOrderData,
    purchaser,
    supplier,
    remarksInfo,
    orderItems,
    handleTabState
  } = props;
  return (
    <div className={css.mainWrapper}>
      {isRectify && (
        <div className={css.rectifyContainer}>
          <Typography>Reasons to reject this Order</Typography>
          <ol className={css.marginsOl}>
            {rectifyRemarks?.split('.\n').map((val: any, index: any) => {
              return (
                <div key={index}>
                  <li>
                    <Typography key={index}>{val}</Typography>
                  </li>
                </div>
              );
            })}
          </ol>
        </div>
      )}
      <BoxContainer title="Preview">
        <InvoicePreview purchaseOrderData={purchaseOrderData} />
        <PartyPreview
          purchaser={purchaser}
          supplier={supplier}
          purchaseOrderData={purchaseOrderData}
        />
        <OrderItemPreview purchaseOrderData={purchaseOrderData} handleTabState={handleTabState} />
        <RemarksPreview handleTabState={handleTabState} remarksInfo={remarksInfo} />
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button variant="outlined-secondary" onClick={onBackClick}>
            Back
          </Button>
          <Button type="submit" onClick={onFormSubmit}>
            Send for approval and generate PO
          </Button>
        </div>
      </div>
    </div>
  );
};

export default POPreview;
