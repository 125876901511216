import * as yup from 'yup';

export const batchValidationSchema = yup.object().shape({
  id: yup.string().notRequired(),
  batch_number: yup.string().required('Batch Number is required'),
  manufacturing_date: yup.date().required('Manufacturing Date is required'),
  expiry_date: yup
    .date()
    .required('Expiry Date is required')
    .min(yup.ref('manufacturing_date'), 'Expiry Date must be later than Manufacturing Date')
});

export const addBatchNumberLabelGenerationSchema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Name is required'),
      order_item_id: yup.string().required('Order Item Id is required'),
      batch: yup.array().of(batchValidationSchema).required('Batch is required')
    })
  )
});

export const packingDocumentDataSchema = yup.object().shape({
  package_details: yup.mixed().required('Package details is required'),
  batch_number: batchValidationSchema.required('Batch Number is required'),
  start_package_no: yup
    .number()
    .integer('Number should be an integer')
    .typeError('Should be a valid number')
    .required('Drum Start Number is Required'),
  end_package_no: yup
    .number()
    .integer('Number should be an integer')
    .typeError('Should be a valid number')
    .required('Drum end Number is Required'),
  net_wt: yup
    .number()
    .positive('Number should be positive')
    .typeError('Should be a valid number')
    .required('Net weight is required'),
  no_of_packages: yup
    .number()
    .integer('Number should be an integer')
    .typeError('Should be a valid number')
    .required('Number of packages is required'),
  gross_wt: yup
    .number()
    .positive('Number should be positive')
    .typeError('Should be a valid number')
    .required('Gross weight is required')
});

export const packingListItemSchema = yup.object().shape({
  name: yup.string().trim().required('Name is required'),
  order_item_id: yup.string().trim().required('Order item ID is required'),
  handling_and_storage: yup.string().required('Handling and Storage is required'),
  packaging_document_data: yup
    .array()
    .of(packingDocumentDataSchema)
    .required('Packaging document data is required')
});

export const packingListSchema = yup.object().shape({
  items: yup.array().of(packingListItemSchema).required('Items is required')
});
