import { Modal } from '@components/base';
import { Button } from '@components/base';
import css from './index.module.scss';
import { Typography } from '@components/base';

interface DeletePromptProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: (data: any) => void;
  message?: string;
}

function DeletePrompt(props: DeletePromptProps) {
  const { open, onClose, onConfirm, message = 'Are you sure you want to delete' } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <Typography>{message}</Typography>
        <div className={css.buttonWrapper}>
          <Button variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
          <Button variant="outlined-secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeletePrompt;
