import { Button } from '@components/base';
import css from './index.module.scss';
import ItemDetailContainer from './item-detail-container';
import { useFormContext } from 'react-hook-form';
import { ProductInfo } from '@helpers/types/product';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';

interface ItemDetailFormProps {
  onFormSubmit: (data: any) => void;
  onCancelClick?: () => void;
  onBackClick?: () => void;
  productList: ProductInfo[];
  rectifyRemarks?: string;
  countryOptions?: Array<{ label: any; value: any }>;
  filterCountryOptions?: any;
}

const ItemDetailForm = (props: ItemDetailFormProps) => {
  const {
    onFormSubmit,
    onCancelClick,
    onBackClick,
    productList,
    rectifyRemarks,
    countryOptions,
    filterCountryOptions
  } = props;

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext();

  useBeforeUnloadAndNavigate();

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <ItemDetailContainer
        productList={productList}
        rectifyRemarks={rectifyRemarks}
        countryOptions={countryOptions}
        filterCountryOptions={filterCountryOptions}
      />
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div>
          <Button variant="outlined-secondary" onClick={onBackClick} className={css.nextBtn}>
            Back
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save and Proceed
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ItemDetailForm;
