import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { CLIENT_ROUTES } from './routes';
import { useAppSelector } from '@store/index';

function PrivateRoute() {
  const location = useLocation();
  const reduxUserState = useAppSelector((state) => state.user);
  const { accessToken } = reduxUserState;
  if (!accessToken)
    return <Navigate to={`${CLIENT_ROUTES.login}`} state={{ from: location }} replace />;

  return <Outlet />;
}
export default PrivateRoute;
